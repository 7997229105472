import { LanguageCode } from './ILanguage'
import { BatchUser } from './IUser'
import { LocalisedSponsorContent } from './localisation'

export enum MonetisationCurrency {
  BITS = 'TWITCH_BITS',
  USD = 'STRIPE_USD',
}

export interface PointsSource {
  streamID: string
  after?: Date
}

export interface PaymentOption {
  currency?: MonetisationCurrency
  cost: number
  bonusPoints?: number
  id: string
  availableUntil?: string
}

export interface PaymentOptionPost {
  currency: MonetisationCurrency
  cost: number
  bonusPoints?: number
  availableUntil?: string
}

export interface LocalisableLeaderboardContent {
  title: string
  banner: LocalisedSponsorContent
}

export type LocalisedLeaderboardContent = {
  [Property in keyof LanguageCode]: LocalisableLeaderboardContent
}

export type LeaderboardLocalisableContent = LocalisedLeaderboardContent & {
  languages: LanguageCode[]
}

export interface Leaderboard {
  id: string
  name: string
  pointsSources: PointsSource[]
  paymentOptions: PaymentOption[]
  content: LeaderboardLocalisableContent
  paymentRequired: boolean
  finalised: boolean
}

enum RankingMethod {
  RANKED = 'RANKED',
  PERCENTILE = 'PERCENTILE',
  NOT_PRESENT = 'NOT_PRESENT',
  REQUIRES_PAYMENT = 'REQUIRES_PAYMENT',
}

export interface RankingItem {
  displayName: string
  method: RankingMethod
  points: number
  profilePicture?: string
  userId: string
  position?: number
  rank?: number
  percentile?: number
}

export interface Standing extends RankingItem, BatchUser {}

export interface UseGetStreamLeaderboards {
  streamId: string
  limit?: number
  filter?: string
}
