import {
  VoteableAnswerInterface,
  VoteableLifecycle,
} from '@interfaces/Voteable'
import { BuffType, tr } from '../constants/other'

type ValidBuffType = Exclude<BuffType, BuffType.Unspecified>
export type ValidPollAnswerInterfaces = Exclude<
  VoteableAnswerInterface,
  VoteableAnswerInterface.ANSWER_INTERFACE_UNSPECIFIED
>

type NameTypeMapping = Record<VoteableLifecycle, string>
type NumericTypeMapping = Record<VoteableLifecycle, ValidBuffType>
type AnswerInterfaceMapToBuffType = Record<
  ValidPollAnswerInterfaces,
  ValidBuffType
>

interface MapResponse {
  name?: string
  id?: BuffType
}

export const nameTypeMapping: NameTypeMapping = {
  [VoteableLifecycle.VOTEABLE_LIFECYCLE_QUIZ]: tr({
    id: 'category.quiz',
  }),
  [VoteableLifecycle.VOTEABLE_LIFECYCLE_POLL]: tr({
    id: 'category.poll',
  }),
  [VoteableLifecycle.VOTEABLE_LIFECYCLE_PREDICTION]: tr({
    id: 'category.prediction',
  }),
  [VoteableLifecycle.VOTEABLE_LIFECYCLE_POPULAR_VOTE]: tr({
    id: 'category.vote',
  }),
}

export const numericTypeMapping: NumericTypeMapping = {
  [VoteableLifecycle.VOTEABLE_LIFECYCLE_QUIZ]: BuffType.Quiz,
  [VoteableLifecycle.VOTEABLE_LIFECYCLE_POLL]: BuffType.Poll,
  [VoteableLifecycle.VOTEABLE_LIFECYCLE_PREDICTION]: BuffType.Prediction,
  [VoteableLifecycle.VOTEABLE_LIFECYCLE_POPULAR_VOTE]: BuffType.Vote,
}

export const answerInterfaceMapToBuffType: AnswerInterfaceMapToBuffType = {
  [VoteableAnswerInterface.ANSWER_INTERFACE_PICK_ONE]: BuffType.Poll,
  [VoteableAnswerInterface.ANSWER_INTERFACE_DISCRETE_SLIDER]:
    BuffType.EmojiSlider,
  [VoteableAnswerInterface.ANSWER_INTERFACE_STAR_SELECT]: BuffType.StarRating,
}

interface GetMetadataFromBuffTypeArgs {
  lifecycle?: VoteableLifecycle
  answerInterface?: VoteableAnswerInterface
}

export const getMetadataFromBuffType = ({
  lifecycle,
  answerInterface,
}: GetMetadataFromBuffTypeArgs): MapResponse => {
  if (!lifecycle || !answerInterface) return { name: undefined, id: undefined }

  // For non-POLL we return directly the metadata
  if (lifecycle !== VoteableLifecycle.VOTEABLE_LIFECYCLE_POLL) {
    return {
      name: nameTypeMapping[lifecycle],
      id: numericTypeMapping[lifecycle],
    }
  }

  // If it is a POLL, it might be different things (pickOne-star-slider)
  const pollPossibleTypes = {
    [BuffType.Poll]: {
      name: tr({ id: 'category.poll' }),
      id: BuffType.Poll,
    },
    [BuffType.EmojiSlider]: {
      name: tr({ id: 'category.emojiSlider' }),
      id: BuffType.EmojiSlider,
    },
    [BuffType.StarRating]: {
      name: tr({ id: 'category.starRating' }),
      id: BuffType.StarRating,
    },
  }

  // Ignoring this as the dynamic assignment on top breaks the TS check but all cases are covered
  // @ts-ignore
  return pollPossibleTypes[answerInterfaceMapToBuffType[answerInterface]]
}
