import React, {
  FC,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useClientConfig } from '@services/requests/client-config'
import { Platform } from '@interfaces/IStreamConfig'

export interface PlatformContextProps {
  platforms: string[]
  includesWeb: boolean
  includesTwitch: boolean
}

const PlatformContext = createContext<PlatformContextProps>(
  {} as PlatformContextProps
)

const { Provider } = PlatformContext

const PlatformProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [platforms, setPlatforms] = useState<string[]>([])
  const [includesWeb, setIncludesWeb] = useState<boolean>(false)
  const [includesTwitch, setIncludesTwitch] = useState<boolean>(false)
  const { getPlatforms } = useClientConfig()

  const fetchPlatforms = useCallback(async () => {
    const { platforms } = await getPlatforms()
    const isWebIncluded = platforms.indexOf(Platform.WEB) > -1
    const isTwitchIncluded = platforms.indexOf(Platform.TWITCH) > -1

    setPlatforms(platforms)
    setIncludesWeb(isWebIncluded)
    setIncludesTwitch(isTwitchIncluded)
  }, [getPlatforms])

  useEffect(() => {
    if (!platforms.length) fetchPlatforms()
  }, [fetchPlatforms, platforms.length])

  return (
    <Provider value={{ platforms, includesWeb, includesTwitch }}>
      {children}
    </Provider>
  )
}

export { PlatformContext, PlatformProvider }
