import React, { FC, MouseEventHandler, ReactNode } from 'react'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import './TooltipPill.css'

interface TooltipPillProps {
  text: ReactNode | string
  showTooltip?: boolean
  className?: string
  'data-testid'?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const TooltipPill: FC<React.PropsWithChildren<TooltipPillProps>> = ({
  text,
  showTooltip,
  children,
  className = '',
  'data-testid': testId = '',
  onClick,
}) => {
  const renderButton = () => (
    <button
      data-testid={testId}
      onClick={onClick}
      className={`tw-tooltip ${className} bg-[#efefef]`}
    >
      {text}
    </button>
  )

  return (
    <>
      {showTooltip ? (
        <Tooltip placement="bottom">
          <TooltipTrigger data-testid="tooltip-pill-trigger">
            {renderButton()}
          </TooltipTrigger>
          <TooltipContent data-testid="tooltip-pill-content">
            {children ?? null}
          </TooltipContent>
        </Tooltip>
      ) : (
        renderButton()
      )}
    </>
  )
}

export default TooltipPill
