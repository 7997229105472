import React, { FC, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Tabs from '@components/molecules/Tabs'
import { DEFAULT_SECOND_SCREEN_DELAY_TIME, tr } from '@constants/other'
import { IRouteParams } from '@interfaces/RouteParams'
import TabPanel from '@components/molecules/TabPanel'
import { Vod, VodFormValues } from '@interfaces/VodV2'
import {
  NewConfig,
  PositionConfig,
  StreamConfigThemeFormValues,
} from '@interfaces/IStreamConfig'
import { FormikHelpers } from 'formik'
import { useMutation } from 'react-query'
import { useVodClient } from '@services/requests/vodV2'
import { useToast } from '@utils/hooks/useToast'
import { queryClient } from '@utils/reactQuery/client'
import VodForm from '@components/organisms/VodForm'
import Positioning from '@components/organisms/Positioning'
import ThemeSelection from '@components/organisms/ThemeSelection'
import AdvancedSettingsForm, {
  AdvancedSettingsFormValues,
} from '@components/organisms/AdvancedSettingsForm'
import ViewLeaderboard from '@components/organisms/ViewLeaderboard'
import LeaderboardDetailsForm, {
  LeaderboardDetailsFormValues,
  LeaderboardDetailsFormVariant,
  getInitialValues,
} from '@components/organisms/LeaderboardDetailsForm'

interface VodDetailsTabsProps {
  vod?: Vod
}

const VodDetailsTabs: FC<VodDetailsTabsProps> = ({ vod }) => {
  const params = useParams<IRouteParams>()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { id: vodId } = params
  const defaultLink = !vodId ? '/vod/new' : `/vod/${vodId}`
  const tabs = !vodId
    ? [{ text: tr({ id: 'vod.detailsTab' }), href: defaultLink }]
    : [
        { text: tr({ id: 'vod.detailsTab' }), href: defaultLink },
        {
          text: tr({ id: 'vod.appearanceTab' }),
          href: `${defaultLink}/appearance`,
        },
        {
          text: tr({ id: 'vod.advancedSettingsTab' }),
          href: `${defaultLink}/advanced-settings`,
        },
        {
          text: tr({ id: 'vod.leaderboardTab' }),
          href: `${defaultLink}/leaderboard`,
        },
        {
          text: tr({ id: 'vod.leaderboardSettingsTab' }),
          href: `${defaultLink}/leaderboard-settings`,
        },
      ]

  const { addErrorToast } = useToast()

  const {
    createVod,
    updateVod,
    deleteVod,
    updateVodLeaderboard,
    useGetVodLeaderboard,
    useGetVodLeaderboardStandings,
  } = useVodClient()

  const { data: leaderboard } = useGetVodLeaderboard(
    vod?.id ?? '',
    vod?.leaderboardId ?? '',
    {
      enabled: !!vod?.leaderboardId && !!vod?.id,
      onError: (err) => {
        addErrorToast(err)
      },
    }
  )

  const { data: standingsData } = useGetVodLeaderboardStandings(
    vod?.id ?? '',
    vod?.leaderboardId ?? ''
  )

  const { mutate: createVodMutation } = useMutation(createVod, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['vods'])
      navigate(`/vod/${data.id}`)
    },
    onError: (err) => {
      addErrorToast(err)
    },
  })

  const { mutate: updateVodMutation } = useMutation(updateVod, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['vods'])

      // If no-revalidate header is set, do not invalidate the query because it causes infinite loop of re-renders in AdvancedSettingsForm.
      // TODO: Check with real api if this header is needed
      if (variables.headers['no-revalidate']) return
      queryClient.invalidateQueries(['vod', vodId])
    },
    onError: (err) => {
      addErrorToast(err)
    },
  })

  const { mutate: deleteVodMutation } = useMutation(deleteVod, {
    onSuccess: () => {
      queryClient.invalidateQueries(['vods'])
      navigate(`/vod/new`)
    },
    onError: (err) => {
      addErrorToast(err)
    },
  })

  const { mutateAsync: updateVodLeaderboardMutation } = useMutation(
    updateVodLeaderboard,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'vod',
          vodId,
          'leaderboard',
          vod?.leaderboardId,
        ])
      },
      onError: (err) => {
        addErrorToast(err)
      },
    }
  )
  const panelClassName = '!mt-4 !py-6'

  const handleVodFormSubmit = (
    values: VodFormValues,
    _: FormikHelpers<VodFormValues>
  ) => {
    const { languages, ...rest } = values
    const selectedLanguages = languages
      .filter((lang) => lang.selected)
      ?.map((lang) => lang.iso6391)
    const request = {
      ...rest,
      languages: selectedLanguages,
    }

    if (vodId) {
      updateVodMutation({ id: vodId, ...vod, ...request })
    } else {
      createVodMutation(request)
    }
  }

  const handleVodDelete = (id: string) => {
    deleteVodMutation(id)
  }

  const handleRefetch = () => {
    queryClient.invalidateQueries(['vod', vodId])
  }

  const availableLanguages = vod?.languages
    ? vod?.languages?.map((lang) => lang)
    : []

  const leaderboardInitialValues = useMemo(() => {
    if (!availableLanguages) return undefined
    if (!leaderboard) return undefined
    return getInitialValues(availableLanguages, leaderboard)
  }, [leaderboard, availableLanguages])

  const handleUpdateStreamPositioning = async (
    values: Partial<PositionConfig>
  ) => {
    const excludedPositionConfig = vod?.positionConfig?.filter(
      (config) => config.platform !== values.platform
    )
    updateVodMutation({
      id: vodId,
      ...vod,
      positionConfig: [...(excludedPositionConfig || []), values],
    })
  }

  const handleUpdateTheme = async (values: StreamConfigThemeFormValues) => {
    updateVodMutation({
      id: vodId,
      ...vod,
      config: {
        ...vod?.config,
        theme: values.theme,
      },
    })
  }

  const handleUpdateSettings = async (values: NewConfig) => {
    updateVodMutation({
      id: vodId,
      ...vod,
      config: {
        ...vod?.config,
        ...values,
      },
      headers: {
        'no-revalidate': 'true',
      },
    })
  }

  const handleUpdateLeaderboardSettings = async (
    values: LeaderboardDetailsFormValues,
    helpers: FormikHelpers<LeaderboardDetailsFormValues>
  ) => {
    // TODO: Leaderboard migration
    // const request = transformLeaderboardDetailFormValuesIntoRequestBody(
    //   // leaderboard?.gameIds ?? [],
    //   [],
    //   values
    // )
    // helpers.setSubmitting(true)
    // try {
    //   await updateVodLeaderboardMutation({
    //     ...leaderboard,
    //     ...request,
    //     vodId: vodId,
    //     leaderboardId: leaderboard?.id,
    //   })
    // } finally {
    //   helpers.setSubmitting(false)
    // }
  }

  const vodAdvancedSettings = useMemo(() => {
    if (!vod?.config) return null
    return {
      ...vod?.config,
      secondScreenAppDelay: DEFAULT_SECOND_SCREEN_DELAY_TIME,
    } as AdvancedSettingsFormValues
  }, [vod?.config])

  const tabIndex = tabs.findIndex((tab) => pathname === tab.href)

  return (
    <Tabs tabs={tabs} selectedIndex={tabIndex} className="!px-9">
      <TabPanel className={panelClassName}>
        <VodForm
          vod={vod}
          handleSubmit={handleVodFormSubmit}
          handleDelete={handleVodDelete}
          title={vod?.name}
          providerId={vod?.providerId}
          bonusTimePoints={vod?.maxBonusTimePoints}
          currentLanguages={vod?.languages}
        />
      </TabPanel>
      <TabPanel className={panelClassName}>
        <div className="flex flex-col gap-y-4">
          <Positioning
            positioningConfig={vod?.positionConfig ?? []}
            onRefetch={handleRefetch}
            onSubmit={handleUpdateStreamPositioning}
          />
          <ThemeSelection onSubmit={handleUpdateTheme} config={vod?.config} />
        </div>
      </TabPanel>
      <TabPanel className={panelClassName}>
        {!!vodAdvancedSettings && (
          <AdvancedSettingsForm
            showBuffConfigSection={false}
            showTimeSyncSection={false}
            onSubmit={handleUpdateSettings}
            initialValues={vodAdvancedSettings}
          />
        )}
      </TabPanel>
      <TabPanel className={panelClassName}>
        <ViewLeaderboard
          id={leaderboard?.id ?? ''}
          streamId={vodId ?? ''}
          queryKey={[
            'vods',
            vodId,
            'leaderboards',
            vod?.leaderboardId,
            'standings',
          ]}
        />
      </TabPanel>
      <TabPanel className={panelClassName}>
        {!!leaderboardInitialValues ? (
          <LeaderboardDetailsForm
            variant={LeaderboardDetailsFormVariant.EDIT}
            availableLanguages={availableLanguages}
            // @ts-ignore
            initialValues={leaderboardInitialValues}
            onSubmit={handleUpdateLeaderboardSettings}
          />
        ) : null}
      </TabPanel>
    </Tabs>
  )
}

export default VodDetailsTabs
