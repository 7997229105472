import { FormikHelpers } from 'formik'
import { LocalisedSponsorContent } from '@interfaces/localisation'
import { LanguageCode } from '@interfaces/ILanguage'

export enum LeaderboardDetailsFormVariant {
  EDIT = 'edit',
  NEW = 'new',
}

export interface LocalisableLeaderboardContent {
  title: string
  banner: Partial<Record<LanguageCode, LocalisedSponsorContent>>
  showBanner?: boolean
}

export type LocalisedLeaderboardContent = {
  [Property in keyof LanguageCode]: LocalisableLeaderboardContent
}

type LeaderboardLanguages = {
  languages: LanguageCode[]
}

export interface LeaderboardContent
  extends LocalisedLeaderboardContent,
    LeaderboardLanguages {}

export interface LeaderboardDetailsFormValues {
  name: string
  content: LeaderboardContent
}

export interface LeaderboardDetailsFormProps {
  /**
   * Variant of the form to show
   */
  variant: LeaderboardDetailsFormVariant

  /**
   * Selection ID for test purposes
   * @default leaderboard-details-form
   */
  'data-testid'?: string

  /**
   * The forms initial values
   */
  initialValues: LeaderboardDetailsFormValues

  /**
   * Handler called when form is submitted
   */
  onSubmit?: (
    values: LeaderboardDetailsFormValues,
    helpers: FormikHelpers<LeaderboardDetailsFormValues>
  ) => Promise<void> | void

  /**
   * An array of language enums the form supports
   */
  availableLanguages: LanguageCode[]

  /**
   * Handler called when the secondary action is clicked
   */
  onSecondaryActionClick?: () => void
}

export type LanguageFormValue<T = string> = Partial<Record<LanguageCode, T>>
