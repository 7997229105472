import React, { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'

interface ContentWithSidePanelLayoutProps {
  /**
   * Title to display in side panel
   */
  sidePanelTitle: string

  /**
   * A node that renders next to the title
   */
  sidePanelAction?: ReactNode

  /**
   * Content
   */
  sidePanelContent: ReactNode

  /**
   * Children
   */
  children: ReactNode
}

const ContentWithSidePanelLayout: ForwardRefRenderFunction<
  HTMLDivElement,
  ContentWithSidePanelLayoutProps
> = ({ children, sidePanelTitle, sidePanelAction, sidePanelContent }, ref) => {
  return (
    <div className="flex h-full">
      {/* Side Panel */}
      <div className="flex flex-col w-1/5 flex-shrink-0 px-2 overflow-y-auto bg-white-secondary">
        <div className="flex justify-between items-center mb-4 pt-3">
          <h1 className="text-xl mb-0 pl-2 !font-bold">{sidePanelTitle}</h1>
          {sidePanelAction}
        </div>
        {sidePanelContent}
        <div ref={ref} className="h-1" />
      </div>
      {/* Content */}
      {children}
    </div>
  )
}

const ContentWithSidePanelLayoutWithRef = forwardRef(ContentWithSidePanelLayout)
export default ContentWithSidePanelLayoutWithRef
