import React, { FC, useEffect, useState } from 'react'
import NameModalForm from '@components/organisms/NameModalForm'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { useToast } from '@utils/hooks/useToast'
import {
  AnnouncementTemplate,
  AnnouncementTemplateCreate,
} from '@interfaces/Template'
import { tr } from '@constants/other'
import schema from './schema'
import { TemplateAnnouncementConfirmationProps } from './types'
import {
  useCreateAnnouncementTemplate,
  useUpdateAnnouncementsTemplate,
} from '@services/requests/templates'

const TemplateAnnouncementConfirmation: FC<
  React.PropsWithChildren<TemplateAnnouncementConfirmationProps>
> = ({ show, payload, mode, id, onSuccessCallback, onClose }) => {
  const [visible, setVisible] = useState(show)
  const [templateName, setTemplateName] = useState<string>('')
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const { addErrorToast } = useToast()
  const { mutateAsync: mutateCreate } = useCreateAnnouncementTemplate()
  const { mutateAsync: mutateUpdate } = useUpdateAnnouncementsTemplate(id ?? '')

  const handleClose = () => {
    setVisible(false)
    onClose && onClose()
  }

  useEffect(() => {
    if (!payload) return
    setTemplateName(payload.templateName)
    setIsButtonDisabled(!payload.templateName ? true : false)
  }, [payload])

  const updateAnnouncementTemplate = async () => {
    if (!id) return
    const data = {
      ...(payload as AnnouncementTemplate),
      templateName: templateName,
      id,
    }

    try {
      await mutateUpdate(data)
      onSuccessCallback && onSuccessCallback()
      handleClose()
    } catch (err) {
      addErrorToast(err)
    }
  }

  const createAnnouncementTemplate = async () => {
    try {
      const data = {
        ...payload,
        templateName: templateName,
      } as AnnouncementTemplateCreate

      await mutateCreate(data)

      onSuccessCallback && onSuccessCallback()
      handleClose()
    } catch (err) {
      addErrorToast(err)
    }
  }

  useEffect(() => {
    setVisible(show)
  }, [show, setVisible])

  return (
    <ConfirmationModal
      show={visible}
      disabled={isButtonDisabled}
      onClose={handleClose}
      onSuccess={
        mode === 'update'
          ? updateAnnouncementTemplate
          : createAnnouncementTemplate
      }
      data-testid="create-template-announcement-confirmation"
      title={tr({ id: 'template.save' })}
      successBtnText={tr({
        id: 'template.save',
      })}
    >
      <NameModalForm
        nameKey="templateName"
        nameValue={templateName}
        validationSchema={schema}
        updateName={(name: string) => setTemplateName(name)}
        updateDisabled={(disableValue: boolean) =>
          setIsButtonDisabled(disableValue)
        }
      />
    </ConfirmationModal>
  )
}

export default TemplateAnnouncementConfirmation
