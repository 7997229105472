import React, { useContext, useMemo, useState } from 'react'
import { WelcomeBuffContext } from '@services/providers/WelcomeBuffProvider'
import BuffForm, { welcomeBuffTypesArray } from '@components/organisms/BuffForm'
import {
  ICreateWelcomeVoteable,
  CreateVoteableForm,
} from '@interfaces/Voteable'
import { useToast } from '@utils/hooks/useToast'
import Alert from '@components/atoms/Alert'
import { getWelcomeBuffInitialValues } from '@utils/buffForm/welcomeBuff'
import { getSchemaWithoutLang } from '@utils/buffForm/schema'
import WelcomeBuffConfirmation from '@components/molecules/WelcomeBuffConfirmation'
import { formatBuffDataToSubmit } from '@utils/formatBuffDataToSubmit'
import { tr } from '@constants/other'
import { LanguageCode } from '@interfaces/ILanguage'
import { WelcomeBuffFormProps } from './types'

const WelcomeBuffForm = ({
  availableLanguages,
  authors,
  onBackClick,
}: WelcomeBuffFormProps) => {
  const [welcomeBuffPayload, setWelcomeBuffPayload] =
    useState<ICreateWelcomeVoteable | null>(null)

  const { addToast } = useToast()
  const {
    setSelectedWelcomeBuff,
    refetchWelcomeBuffs,
    selectedWelcomeBuff,
    isEditMode,
  } = useContext(WelcomeBuffContext)

  const firstLang = availableLanguages[0]

  const initialValues = useMemo(() => {
    return getWelcomeBuffInitialValues({
      template: selectedWelcomeBuff ?? undefined,
      languages: [firstLang],
    })
  }, [selectedWelcomeBuff, firstLang])

  const getSchema = getSchemaWithoutLang({
    isWelcomeBuff: true,
  })

  const handleSubmit = (
    values: CreateVoteableForm,
    _: unknown,
    languages: LanguageCode[]
  ) => {
    const welcomeBuff = {
      ...formatBuffDataToSubmit(
        values,
        true,
        languages,
        true,
        values.participationPoints
      ),
      name: '',
    }
    setWelcomeBuffPayload(welcomeBuff)
  }

  return (
    <>
      {isEditMode && !!selectedWelcomeBuff && (
        <Alert
          className="mt-3"
          text={tr(
            { id: 'welcomeBuff.editWelcomeBuffAlertText' },
            { welcomeBuffName: selectedWelcomeBuff?.name }
          )}
          action={tr({ id: 'welcomeBuff.returnToList' })}
          actionCallback={() => {
            onBackClick && onBackClick()
          }}
          actionClassName="font-bold underline"
        />
      )}
      <BuffForm
        displayParticipationPoints
        buffTypes={welcomeBuffTypesArray}
        initialValues={initialValues}
        authors={authors}
        isTimeSyncStream={false}
        availableLanguages={availableLanguages}
        onSubmit={handleSubmit}
        getSchema={getSchema}
        onReset={(values, resetForm) => {
          const blankInitialValues = getWelcomeBuffInitialValues({
            languages: [firstLang],
          })
          resetForm(blankInitialValues)
        }}
      />

      <WelcomeBuffConfirmation
        payload={welcomeBuffPayload}
        show={Boolean(welcomeBuffPayload)}
        onSuccessCallback={() => {
          refetchWelcomeBuffs()
          setSelectedWelcomeBuff(null)
          addToast({
            msg: tr({ id: 'welcomeBuff.confirmation' }),
            type: 'success',
          })
          onBackClick && onBackClick()
        }}
        onClose={() => setWelcomeBuffPayload(null)}
      />
    </>
  )
}

export default WelcomeBuffForm
