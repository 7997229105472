import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useLive } from '@services/requests/live'
import { tr } from '@constants/other'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import Button from '@components/atoms/Button'
import TextInput from '@components/atoms/TextInput'
import { useToast } from '@utils/hooks/useToast'
import { queryClient } from '@utils/reactQuery/client'
import { ReactComponent as TickIcon } from '@assets/answer-tick.svg'
import { IStream } from '@interfaces/IStream'
import { IRouteParams } from '@interfaces/RouteParams'
import { PointsSource } from '@interfaces/Leaderboard'
import LeaderboardStreamSectionAllTab from './LeaderboardStreamSectionAllTab'
import LeaderboardStreamSectionSelectedTab from './LeaderboardStreamSectionSelectedTab'
import { LeaderboardStreamsProps } from './types'

const LeaderboardStreams = (props: LeaderboardStreamsProps) => {
  const { id: streamId, leaderboardId } = useParams<IRouteParams>()
  const {
    useGetStreamLeaderboardById,
    addPointSourceToLeaderboard,
    removePointSourceToLeaderboard,
  } = useLive()

  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedStreamIds, setSelectedStreamIds] = useState<string[]>([])

  const { addToast, addErrorToast } = useToast()

  const { mutateAsync: mutateAddPointsSource, isLoading: isLoadingAdd } =
    useMutation((pointsSources: PointsSource[]) =>
      addPointSourceToLeaderboard(leaderboardId, streamId, pointsSources)
    )

  const { mutateAsync: mutateRemovePointsSource, isLoading: isLoadingRemove } =
    useMutation((pointsSources: string[]) =>
      removePointSourceToLeaderboard(leaderboardId, streamId, pointsSources)
    )

  const preparedSearchValue = searchValue?.length >= 3 ? searchValue : undefined

  const { data: leaderboard, isLoading } = useGetStreamLeaderboardById(
    streamId,
    leaderboardId
  )

  const streamIds = useMemo(
    () => leaderboard?.pointsSources?.map((source) => source.streamID) ?? [],
    [leaderboard]
  )

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleSave = async () => {
    const streamIdsToAdd = selectedStreamIds.filter(
      (id) => !streamIds.includes(id)
    )
    const streamIdsToRemove = streamIds.filter(
      (id) => !selectedStreamIds.includes(id)
    )

    const promises = []

    if (streamIdsToAdd.length) {
      const pointSourcesToAdd: PointsSource[] = streamIdsToAdd.map((id) => ({
        streamID: id,
      }))
      promises.push(mutateAddPointsSource(pointSourcesToAdd))
    }

    if (streamIdsToRemove.length) {
      promises.push(mutateRemovePointsSource(streamIdsToRemove))
    }

    if (!promises.length) return

    try {
      await Promise.all(promises)
      addToast({
        msg: tr({ id: 'leaderboard.updatedLeaderboard' }),
        type: 'success',
        image: <TickIcon className="mr-3 w-8 h-8" />,
      })
      setTimeout(() => {
        // Refresh after 200ms because BE may return old values for a short time
        queryClient.invalidateQueries(['leaderboard', leaderboardId])
      }, 200)
    } catch (error) {
      addErrorToast(error)
      setSelectedStreamIds(streamIds)
      setTimeout(() => {
        // Refresh after 200ms because BE may return old values for a short time
        queryClient.invalidateQueries(['leaderboard', leaderboardId])
      }, 200)
    }
  }

  const handleCancel = () => {
    setSelectedStreamIds(streamIds)
  }

  const handleChange = (stream: IStream, checked: boolean) => {
    if (checked) {
      setSelectedStreamIds((streamsIds) => {
        return [...streamsIds, stream.id]
      })
    } else {
      setSelectedStreamIds((streams) => {
        const newArray = [...streams]
        const index = newArray.findIndex((id) => id === stream.id)
        if (index !== -1) {
          newArray.splice(index, 1)
        }

        return newArray
      })
    }
  }

  useEffect(() => {
    if (!streamIds) {
      return
    }
    setSelectedStreamIds(streamIds)
  }, [streamIds])

  const loading = isLoadingAdd || isLoadingRemove || isLoading

  const tabs = [
    {
      id: '0',
      text: tr({ id: 'generic.all' }),
      name: 'all',
    },
    {
      id: '1',
      text: (
        <div className="flex">
          {tr({ id: 'generic.selected' })}
          {selectedStreamIds.length ? (
            <span className="rounded-full flex items-center justify-center w-5 h-5 bg-[#0090FE] text-white text-[12px] ml-1">
              {selectedStreamIds.length}
            </span>
          ) : null}
        </div>
      ),
      name: 'selected',
    },
  ]

  return (
    <div>
      <p className="text-[#586069] text-[16px]">
        {tr({ id: 'leaderboard.leaderboardAwardSubtitle' })}
      </p>
      <div className="flex flex-col p-4 bg-white-background rounded border border-[#E1E4E8]">
        <header className="flex items-center">
          <div className="mr-2 flex flex-col w-full items-center flex-grow justify-between">
            <Tabs
              className="w-full"
              tabs={tabs}
              headerChildren={
                <TextInput
                  placeholder={tr({ id: 'generic.search' })}
                  className="mb-2"
                  value={searchValue}
                  onChange={handleInputChange}
                  inputContainerClasses="!min-h-0"
                  customInputStyle={{
                    backgroundImage: `url("/assets/svg/search.svg")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '20px 20px',
                    backgroundPosition: '2% center',
                    paddingLeft: 25,
                    width: '300px',
                  }}
                />
              }
            >
              <TabPanel>
                <LeaderboardStreamSectionAllTab
                  onChange={handleChange}
                  selectedStreams={selectedStreamIds}
                  filter={preparedSearchValue}
                />
              </TabPanel>
              <TabPanel>
                <LeaderboardStreamSectionSelectedTab
                  onChange={handleChange}
                  selectedStreams={selectedStreamIds}
                  filter={preparedSearchValue}
                />
              </TabPanel>
            </Tabs>
          </div>
        </header>
      </div>

      <div className="flex items-center justify-end gap-x-4 mt-4">
        <Button
          disabled={loading}
          variant="secondary"
          className="min-w-[82px]"
          onClick={handleCancel}
        >
          {tr({ id: 'generic.cancel' })}
        </Button>
        <Button
          disabled={loading}
          variant="primary"
          className="min-w-[82px]"
          onClick={handleSave}
        >
          {tr({ id: 'generic.update' })}
        </Button>
      </div>
    </div>
  )
}

export default LeaderboardStreams
