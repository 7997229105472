import React, { FC, Fragment, ReactElement, ChangeEvent } from 'react'
import { Link } from 'react-router-dom'
import { GetStreamsResponse, IStream } from '@interfaces/IStream'
import SearchBox from '@components/molecules/SearchBox'
import StreamItem from '@components/molecules/StreamItem'
import { getStreamStatus } from '@utils/stream'
import { useInfiniteScroll } from '@utils/hooks/useInfiniteScroll'
import { tr } from '@constants/other'
import ScrollableSidebar from '@components/molecules/ScrollableSidebar'

interface IProps {
  selectedId?: string
  setSelectedStream: (stream: IStream) => void
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  fetchNextPage: any
  hasNextPage?: boolean
  paginatedData?: GetStreamsResponse[]
}

const StreamSidebar: FC<React.PropsWithChildren<IProps>> = ({
  selectedId,
  setSelectedStream,
  search,
  setSearch,
  paginatedData,
  fetchNextPage,
  hasNextPage,
  isLoading,
}): ReactElement => {
  const { triggerRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: fetchNextPage,
  })

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const handleReset = () => {
    setSearch('')
  }

  return (
    <>
      <SearchBox
        value={search}
        onChange={handleInputChange}
        onReset={handleReset}
        placeholder={tr({ id: 'stream.searchList' })}
        className="mb-2"
      />

      <ScrollableSidebar
        triggerRef={triggerRef}
        isLoading={isLoading}
        isEmpty={!paginatedData?.[0]?.streams?.length}
        emptyText={tr({ id: 'stream.noStreams' })}
      >
        {paginatedData?.map((page) => {
          return page.streams.map((stream: IStream) => {
            return (
              <Fragment key={stream.id}>
                <Link
                  to={`/streams/${stream.id}/${stream.gameIds[0]}`}
                  onClick={() => {
                    setSelectedStream(stream)
                  }}
                >
                  <StreamItem
                    stream={stream}
                    isActive={selectedId === stream.id}
                    status={getStreamStatus(stream)}
                  />
                </Link>
              </Fragment>
            )
          })
        })}
      </ScrollableSidebar>
    </>
  )
}

export default StreamSidebar
