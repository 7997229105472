import React from 'react'
import { Popover } from '@headlessui/react'
import ColorPicker, { ColorPickerType } from '@components/molecules/ColorPicker'
import { ReactComponent as Bucket } from '@assets/color-picker-bucket.svg'
import { ColorPickerInputProps } from './types'

const ColorPickerInput = ({
  type,
  color,
  onChange,
  onChangeAll,
  className = '',
}: ColorPickerInputProps) => {
  const buttonContent =
    type === ColorPickerType.BACKGROUND ? (
      <Bucket className="block" stroke="#767676" />
    ) : (
      <span className="w-[19px] h-[19px] font-semibold block text-[#767676]">
        A
      </span>
    )

  const buttonColorStyle = {
    backgroundColor: color.length ? color : '#D8E1EC',
    border: 'solid 1px #767676',
  }

  return (
    <Popover className={className}>
      <>
        <Popover.Button
          className="text-center h-auto rounded pt-[2px] px-[9px] pb-[7px] bg-transparent"
          style={{
            border: 'solid 1px lightgray',
          }}
        >
          {buttonContent}

          <div
            className="rounded-full w-full h-[5px] mt-[2px]"
            style={buttonColorStyle}
          />
        </Popover.Button>

        <Popover.Panel>
          <div className="relative">
            <div>
              <div>
                <ColorPicker
                  color={color}
                  type={type}
                  onChange={onChange}
                  onChangeAll={onChangeAll}
                />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </>
    </Popover>
  )
}

export default ColorPickerInput
