import { StandingWithMetadata } from '@interfaces/ILeaderboard'
import { Leaderboard } from '@interfaces/Leaderboard'
import { GetVodResponse, Vod, VodContent } from '@interfaces/VodV2'
import useClient, { Service } from '@utils/hooks/useClient'
import prepareLeaderboardData from '@utils/prepareLeaderboardData'
import { getPaginatedUrl, getQueryUrl } from '@utils/url'
import { UseQueryOptions, useQuery } from 'react-query'

/*****************************************
 * GET requests
 ******************************************/

export const useVodClient = () => {
  const { client } = useClient(Service.VOD, true)

  const getVods = async (
    pageSize: number = 50,
    nextParam: string = '',
    query?: string
  ): Promise<GetVodResponse> => {
    const urlWithQuery = getQueryUrl('/vods', query)
    const urlWithPagination = getPaginatedUrl(urlWithQuery, pageSize, nextParam)
    const response = await client.get(urlWithPagination)

    // TODO: use proper structure when using real apis
    return {
      vods: response.data?.vods,
      nextPage: response.data.nextPageToken,
    }
  }

  const getVod = async (id: string): Promise<Vod> => {
    const response = await client.get(`/vods/${id}`)
    return response.data
  }

  const getVodContent = async (id: string): Promise<VodContent[]> => {
    const response = await client.get(`/vods/${id}/contents`)
    return response.data
  }

  const getVodContentEntity = async ({
    vodId,
    entityId,
  }: {
    vodId: string
    entityId: string
  }): Promise<VodContent> => {
    const response = await client.get(`/vods/${vodId}/contents/${entityId}`)
    return response.data
  }

  const createVod = async (request: any): Promise<Vod> => {
    const response = await client.post(`/vods`, request)
    return response.data
  }

  const updateVod = async (request: any): Promise<Vod> => {
    const { id, headers, ...rest } = request
    delete request.id
    const response = await client.patch(`/vods/${id}`, rest, headers)
    return response.data
  }

  const deleteVod = async (id: string): Promise<void> => {
    return client.delete(`/vods/${id}`)
  }

  const deleteVodContent = async ({
    vodId,
    entityId,
  }: {
    vodId: string
    entityId: string
  }): Promise<void> => {
    return client.delete(`/vods/${vodId}/content/${entityId}`)
  }

  const getVodLeaderboard = async (vodId: string, leaderboardId: string) => {
    const response = await client.get<Leaderboard>(
      `/vods/${vodId}/leaderboards/${leaderboardId}`
    )

    return response.data
  }

  const getVodLeaderboardStandings = async (
    vodId: string,
    leaderboardId: string
  ) => {
    const response = await client.get(
      `/vods/${vodId}/leaderboards/${leaderboardId}/standings`
    )

    return response.data
  }

  const updateVodLeaderboard = async (request: any): Promise<Leaderboard> => {
    const { vodId, leaderboardId, ...rest } = request
    const response = await client.put(
      `/vods/${vodId}/leaderboards/${leaderboardId}`,
      rest
    )
    return response.data
  }

  const useGetVod = (
    id?: string,
    options?: Omit<UseQueryOptions<Vod>, 'queryKey' | 'queryFn'>
  ) => {
    return useQuery<Vod>(['vod', id], () => getVod(id ?? ''), options)
  }

  const useGetVodContent = (
    id?: string,
    options?: Omit<UseQueryOptions<VodContent[]>, 'queryKey' | 'queryFn'>
  ) => {
    return useQuery<VodContent[]>(
      ['vod', id, 'content'],
      () => getVodContent(id ?? ''),
      options
    )
  }

  const useGetVodContentEntity = (
    vodId?: string,
    entityId?: string,
    options?: Omit<UseQueryOptions<VodContent>, 'queryKey' | 'queryFn'>
  ) => {
    return useQuery<VodContent>(
      ['vod', vodId, 'content', entityId],
      () =>
        getVodContentEntity({ vodId: vodId ?? '', entityId: entityId ?? '' }),
      options
    )
  }

  const getDecoratedStandings = async (
    vodId: string,
    leaderboardId: string
  ) => {
    const standings = await getVodLeaderboardStandings(vodId, leaderboardId)
    return prepareLeaderboardData(standings)
  }

  const useGetVodLeaderboard = (
    id?: string,
    leaderboardId?: string,
    options?: Omit<UseQueryOptions<Leaderboard>, 'queryKey' | 'queryFn'>
  ) => {
    return useQuery<Leaderboard>(
      ['vod', id, 'leaderboard', leaderboardId],
      () => getVodLeaderboard(id ?? '', leaderboardId ?? ''),
      options
    )
  }

  const useGetVodLeaderboardStandings = (
    vodId: string,
    leaderboardId: string
  ) => {
    return useQuery<StandingWithMetadata[]>(
      ['vods', vodId, 'leaderboards', leaderboardId, 'standings'],
      () => {
        return getDecoratedStandings(vodId, leaderboardId)
      },
      {
        enabled: !!leaderboardId && !!vodId,
      }
    )
  }

  return {
    getVod,
    getVods,
    createVod,
    updateVod,
    deleteVod,
    deleteVodContent,
    getVodLeaderboard,
    getVodLeaderboardStandings,
    updateVodLeaderboard,
    useGetVod,
    useGetVodContent,
    useGetVodContentEntity,
    useGetVodLeaderboard,
    useGetVodLeaderboardStandings,
  }
}
