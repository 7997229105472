import { useEffect } from 'react'
import { cloneDeep } from 'lodash'
import Sortable from 'sortablejs'
import {
  LiveReactionAsset,
  LiveReactionsFormValues,
} from '@interfaces/Reactions'

interface ReorderAnswersProps<T extends LiveReactionsFormValues> {
  values: T
  onChange: (values: LiveReactionAsset[]) => void
}

const ReorderReactions = <T extends LiveReactionsFormValues>({
  values,
  onChange,
}: ReorderAnswersProps<T>) => {
  useEffect(() => {
    let sortable: Sortable
    const list = document.getElementById('reactions-list')
    if (list) {
      sortable = new Sortable(list, {
        animation: 350,
        handle: '.reaction-handle',
        swapThreshold: 0.65,
        ghostClass: 'sortable-ghost',
        onMove: (e) => {
          if (e?.related) {
            return !e.related?.classList?.contains?.('locked')
          }
        },
        onEnd: (e) => {
          if (e.oldIndex == null || e.newIndex == null) {
            onChange(values.availableReactions)
            return
          }

          const item = values.availableReactions[e.oldIndex]
          const answers = cloneDeep(values.availableReactions)
          answers.splice(e.oldIndex, 1)
          answers.splice(e.newIndex, 0, item)
          onChange(answers)
        },
      })
    }
    return () => sortable?.destroy?.()
  }, [values, onChange])

  return null
}

export default ReorderReactions
