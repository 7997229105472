import React from 'react'
import { EmojiItem } from '@interfaces/Reactions'

const EmojiStatsItem = ({ imageUrl, percent, count }: Partial<EmojiItem>) => {
  const roundedPercent = Math.round(percent || 0)
  return (
    <div className="flex items-center mb-3">
      <div className="mr-2">
        <img
          className="w-6 h-6 object-cover aspect-square"
          data-testid="emoji-item-img"
          src={imageUrl}
        />
      </div>
      <div className="flex w-full">
        {Number(percent) > 0 && (
          <div
            data-testid="emoji-item-percent"
            className={`flex items-center text-white justify-end text-xs rounded bg-[#007BFF] h-[20px] pr-1`}
            // Add extra 15px to width to make sure the text is visible
            style={{ width: `calc(${roundedPercent}% + 15px)` }}
          >
            {roundedPercent >= 1 && <span>{`${roundedPercent}%`}</span>}
          </div>
        )}

        <div
          data-testid="emoji-item-count"
          className="text-sm text-[#586069] px-2"
        >
          {count}
        </div>
      </div>
    </div>
  )
}

export default EmojiStatsItem
