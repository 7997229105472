import React, {
  createContext,
  FC,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'
import { IOnboard } from '@interfaces/IOnboard'
import { useInfiniteScrollPagination } from '@utils/hooks/useInfiniteScrollPagination'
import { getWelcomeBuffs } from '@services/requests/onboarding'

interface WelcomeBuffContextProps {
  selectedWelcomeBuff: IOnboard | null
  setSelectedWelcomeBuff: (buff: IOnboard | null) => void
  welcomeBuffs: IOnboard[]
  welcomeBuffsTriggerRef: (node?: Element | null) => void
  refetchWelcomeBuffs: () => void
  isEditMode: boolean
  setIsEditMode: Dispatch<SetStateAction<boolean>>
}

export const WelcomeBuffContext = createContext<WelcomeBuffContextProps>(
  {} as WelcomeBuffContextProps
)

export const WelcomeBuffProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [selectedWelcomeBuff, setSelectedWelcomeBuff] =
    useState<IOnboard | null>(null)

  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const onError = useCallback((error: any) => {
    console.error(error)
  }, [])

  const fetchWelcomeBuffData = useCallback(async () => {
    const response = await getWelcomeBuffs()
    const items = response.data.welcomeBuff
    return { items }
  }, [])

  const {
    items: welcomeBuffs,
    triggerRef: welcomeBuffsTriggerRef,
    refetch: refetchWelcomeBuffs,
  } = useInfiniteScrollPagination({
    onError,
    fetchData: fetchWelcomeBuffData,
  })

  return (
    <WelcomeBuffContext.Provider
      value={{
        selectedWelcomeBuff,
        setSelectedWelcomeBuff,
        welcomeBuffs,
        welcomeBuffsTriggerRef,
        refetchWelcomeBuffs,
        isEditMode,
        setIsEditMode,
      }}
    >
      {children}
    </WelcomeBuffContext.Provider>
  )
}
