import React, { useContext } from 'react'
import { ErrorContext } from '@services/providers/ErrorProvider'
import ErrorModal from '@components/organisms/ErrorModal'
import { ErrorModalWrapperProps } from './types'

const ErrorModalWrapper = (props: ErrorModalWrapperProps) => {
  const { modalError, setModalError } = useContext(ErrorContext)

  if (!modalError) return null

  const { title, description } = modalError

  return (
    <ErrorModal
      title={title}
      desc={description}
      show={true}
      onClose={() => setModalError(undefined)}
    />
  )
}

export default ErrorModalWrapper
