import { Platform, PositionConfig } from '@interfaces/IStreamConfig'

export enum PositioningTabs {
  WebSdk = 0,
  Android = 1,
  Ios = 2,
  Twitch = 3,
  TizenTV = 4,
  AndroidTV = 5,
}

export interface IPositioningProps {
  /**
   * PositionConfig data
   */
  positioningConfig: PositionConfig[]

  /**
   * Function to be called when we change platform
   */
  onRefetch: () => void

  /**
   * Function to be called when we submit form
   */
  onSubmit: (values: Partial<PositionConfig>) => Promise<void>
}

export interface PlatformTabItem {
  type: Platform
  title: string
  contentTestId: string
  tabTestId: string
}

export interface PlatformTab {
  [key: number]: PlatformTabItem
}
