export enum ContrastPillVariant {
  ADD = 'add',
  COLOR = 'color',
}

export interface ContrastPillProps {
  variant: ContrastPillVariant
  color?: string

  /**
   * If button is showing active state
   * @default false
   */
  isActive?: boolean

  /**
   * Handler called when pill is clicked
   */
  onClick: () => void

  /**
   * Display a red border round the pill when colour is not valid
   */
  displayWarning?: boolean

  // isDefault?: boolean
  // setActive: (active: number) => void
}
