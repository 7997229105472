import React, { MouseEvent, ReactNode, useState } from 'react'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import ConditionalWrapper from '@components/atoms/ConditionalWrapper'
import { TruncatedTooltipTextProps } from './types'

/**
 * Show tooltip for truncated text showing full text in tooltip
 * @param {TruncatedTooltipTextProps} props
 * @return {ReactNode}
 */
function TruncatedTooltipText({
  children,
  'data-testid': testId = 'truncated-tooltip-text',
  ...rest
}: TruncatedTooltipTextProps) {
  const [show, setShow] = useState(false)

  const handleMouseOver = (e: MouseEvent<HTMLDivElement>) => {
    const element = e.target as HTMLDivElement
    const isTruncated = element?.offsetWidth < element?.scrollWidth
    setShow(isTruncated)
  }

  return (
    <div onMouseOver={handleMouseOver} data-testid={testId} {...rest}>
      <ConditionalWrapper
        wrapper={(children: ReactNode) => (
          <Tooltip placement="bottom">
            <TooltipTrigger>{children}</TooltipTrigger>
            <TooltipContent>{children}</TooltipContent>
          </Tooltip>
        )}
        condition={show}
      >
        <span className="truncate text-base block">{children}</span>
      </ConditionalWrapper>
    </div>
  )
}

export default TruncatedTooltipText
