import React, { FC, useContext, useState } from 'react'
import Button, { ButtonVariant } from '@components/atoms/Button'
import { BuffPageActionMode, BuffPageType } from '@interfaces/BuffPage'
import { BuffPageActiveTab } from '@pages/BuffPage'
import { tr } from '@constants/other'
import { BuffFormContext } from '@services/providers/BuffFormProvider'
import { TemplateContext } from '@services/providers/TemplateProvider'
import { TemplateTypeTabs } from '@components/organisms/TemplateList/types'
import ConfirmationModal from '../ConfirmationModal'
import {
  useDeleteAnnouncementTemplate,
  useDeleteVoteableTemplate,
} from '@services/requests/templates'
import { useToast } from '@utils/hooks/useToast'

export interface Props {
  templatesActiveTab: TemplateTypeTabs
  setMainActiveTab: (tab: BuffPageActiveTab) => void
}

const TemplateActionButtons: FC<Props> = ({
  templatesActiveTab,
  setMainActiveTab,
}) => {
  const { addErrorToast } = useToast()
  const { setActionMode, isUpdateActionMode, setType } =
    useContext(BuffFormContext)
  const {
    selectedTemplate,
    setSelectedTemplate,
    selectedAnnouncementTemplate,
    setSelectedAnnouncementTemplate,
    currentTemplateIdSelected,
  } = useContext(TemplateContext)
  const [showDelete, setShowDelete] = useState<boolean>(false)

  const { mutateAsync: voteableDeleteMutate } = useDeleteVoteableTemplate(
    currentTemplateIdSelected
  )

  const { mutateAsync: announcementDeleteMutate } =
    useDeleteAnnouncementTemplate(currentTemplateIdSelected)

  const handleLoadTemplate = () => {
    templatesActiveTab === TemplateTypeTabs.Announcements ||
    templatesActiveTab === TemplateTypeTabs.GlobalAnnouncements
      ? setMainActiveTab(BuffPageActiveTab.AnnouncementListView)
      : setMainActiveTab(BuffPageActiveTab.BuffView)
    setActionMode(BuffPageActionMode.Load)
  }

  const deleteTemplate = async () => {
    try {
      if (
        (templatesActiveTab === TemplateTypeTabs.Buffs ||
          templatesActiveTab === TemplateTypeTabs.GlobalBuffs) &&
        selectedTemplate?.id
      ) {
        await voteableDeleteMutate()
        setSelectedTemplate(null)
      }

      if (
        (templatesActiveTab === TemplateTypeTabs.Announcements ||
          templatesActiveTab === TemplateTypeTabs.GlobalAnnouncements) &&
        selectedAnnouncementTemplate?.id
      ) {
        await announcementDeleteMutate()
        setSelectedAnnouncementTemplate(null)
      }
    } catch (error) {
      addErrorToast(error)
    }
  }

  return (
    <div
      data-testid="template-action-buttons-wrapper"
      className="flex flex-col items-center gap-2 py-3"
    >
      {!isUpdateActionMode && (
        <>
          <div className="w-full">
            <Button
              role="form-action"
              size="small"
              form="buff-form"
              data-testid="load-template"
              className="w-full"
              type="button"
              disabled={!currentTemplateIdSelected}
              onClick={handleLoadTemplate}
            >
              {tr({ id: 'template.load' })}
            </Button>
          </div>
          <div className="flex w-full gap-2">
            <Button
              role="form-action"
              size="small"
              form="buff-form"
              data-testid="edit-template"
              variant="secondary"
              className="w-full"
              type="button"
              disabled={!currentTemplateIdSelected}
              onClick={() => setActionMode(BuffPageActionMode.Update)}
            >
              {tr({ id: 'template.edit' })}
            </Button>
            <Button
              role="form-action"
              size="small"
              data-testid="delete-template"
              form="buff-form"
              variant="danger"
              className="w-full"
              type="button"
              disabled={!currentTemplateIdSelected}
              onClick={() => setShowDelete(true)}
            >
              {tr({ id: 'template.delete' })}
            </Button>
          </div>
        </>
      )}
      {/* Saves TEMPLATE in UPDATE mode */}
      {isUpdateActionMode && (
        <>
          <Button
            role="form-action"
            size="small"
            form="buff-form"
            className="py-1 w-full mb-2"
            type="submit"
            disabled={!currentTemplateIdSelected}
            onClick={() => setType(BuffPageType.Template)}
          >
            {tr({ id: 'template.save' })}
          </Button>
        </>
      )}
      <ConfirmationModal
        show={showDelete}
        onClose={() => setShowDelete(false)}
        onSuccess={deleteTemplate}
        subtext={tr({ id: 'template.confirmDelete' })}
        successBtnText={tr({ id: 'template.confirmDeleteButton' })}
        variant={ButtonVariant.Danger}
      />
    </div>
  )
}

export default TemplateActionButtons
