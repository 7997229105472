import { get } from 'lodash'
import { FormikErrors, FormikTouched } from 'formik'
import { LanguageCode } from '@interfaces/ILanguage'

/**
 * Gets languages from a lodash object path
 * @param {string} key
 * @return {string | undefined}
 */
export const getLangsFromObjectPath = (key: string) => {
  const langs = Object.values(LanguageCode) as string[]
  return key.split('.').find((part) => {
    return langs.includes(part)
  })
}

/**
 * Gets fields which have errors as lodash object paths
 * @param {FormikErrors} errors
 * @param {FormikTouched} touched
 * @param {string} fieldName
 * @param {boolean} skipTouchedCheck
 * @return {string[]}
 */
export const getErrorFields = <T>(
  errors: FormikErrors<T>,
  touched: FormikTouched<T>,
  fieldName?: string,
  skipTouchedCheck?: boolean
): string[] => {
  const fieldValue = fieldName ? get(errors, fieldName) : errors
  if (fieldValue) {
    const fieldsWithErrors = Object.entries(fieldValue).reduce(
      (ret, [key, error]) => {
        const fieldKey = fieldName ? `${fieldName}.${key}` : key
        if (typeof error === 'object') {
          const errorFields = getErrorFields(
            errors,
            touched,
            fieldKey,
            skipTouchedCheck
          )
          return [...ret, ...errorFields]
        }

        const touchedField = get(touched, fieldKey)
        if (Boolean(error) && (touchedField || skipTouchedCheck)) {
          ret.push(fieldKey)
        }
        return ret
      },
      [] as string[]
    )

    return fieldsWithErrors
  }

  return []
}
