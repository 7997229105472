export enum PubNubEventType {
  /**
   * Event fired when voteable is created
   */
  VOTEABLE_CREATE = 'games:voteable.create',

  /**
   * Event fired when voting opens on voteable
   */
  VOTEABLE_OPEN = 'games:voteable.open',

  /**
   * Event fired when voting is closed on voteable
   */
  VOTEABLE_CLOSE = 'games:voteable.close',

  /**
   * Event fired when voteable resolves
   */
  VOTEABLE_RESOLVE = 'games:voteable.resolve',

  /**
   * Event fired when announcement is created
   */
  ANNOUNCEMENT_CREATE = 'games:announcement.create',

  /**
   * TODO: Rename to something else
   */
  ANNOUNCE_WINNER = 'games:game.announce_winner',

  welcomeBuff = '__queue-welcome-buff-hack__',
}
