import React, { useEffect, useMemo, useState } from 'react'
import { tr } from '@constants/other'
import { Form, Formik } from 'formik'
import TextInput from '@components/atoms/TextInput'
import Button from '@components/atoms/Button'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'
import { useToast } from '@utils/hooks/useToast'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { lazy } from 'yup'
import { LanguageCode } from '@interfaces/ILanguage'
import { Theme } from '@interfaces/IStreamConfig'
import LanguageTabContent from '@components/molecules/LanguageTabContent'
import { cloneDeep, get, merge } from 'lodash'
import deepOmit from '@utils/deepOmit'
import { getErrorFields, getLangsFromObjectPath } from '@utils/form'
import { PreviewProviderDataSource } from '@services/providers/PreviewProvider'
import { QR } from '@components/organisms/QrCodePreview/types'
import { useMutation, useQuery } from 'react-query'
import { LanguageTextLines } from '@interfaces/ClientConfig'
import { queryClient } from '@utils/reactQuery/client'
import { useClientConfig } from '@services/requests/client-config'
import { getSchema } from './schema'
import { TVSettingsFormValues, TvSettingsProps } from './types'

const TvSettings = (_: TvSettingsProps) => {
  const { getLanguages, getTVConfig, updateTVConfig } = useClientConfig()
  const { addToast, addErrorToast } = useToast()

  const { data: tvConfig } = useQuery('tvConfig', getTVConfig)
  const { data: locales } = useQuery('locales', getLanguages)
  const { mutate: mutateUpdateTVConfig, isLoading } = useMutation(
    updateTVConfig,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tvConfig')
        addToast({
          msg: tr({ id: 'configuration.tvSettingsUpdated' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
      },
      onError: (err) => {
        addErrorToast(err)
      },
    }
  )

  // @ts-ignore
  const initialValues: TVSettingsFormValues = useMemo(() => {
    if (!tvConfig || !locales) {
      return {
        url: '',
        text: {
          languages: [LanguageCode.en],
          [LanguageCode.en]: {
            line1: '',
            line2: '',
            line3: '',
          },
        },
      }
    }

    const { languages, ...rest } = tvConfig.qrCode.text

    const transformedLanguages =
      languages.filter((lang) => locales.enabled.includes(lang)).length > 0
        ? tvConfig.qrCode.text.languages.filter((lang) =>
            locales.enabled.includes(lang)
          )
        : [locales.default]

    const transformedData = {
      url: tvConfig.qrCode.url,
      text: {
        languages: transformedLanguages,
      },
    }

    const transformedTexts: LanguageTextLines = {}

    Object.keys(rest).forEach((key) => {
      if (locales?.enabled.includes(key as LanguageCode)) {
        transformedTexts[key] = rest[key]
      }
    })

    if (Object.keys(transformedTexts).length === 0) {
      transformedTexts[locales.default] = {
        line1: '',
        line2: '',
        line3: '',
      }
    }

    transformedData.text = {
      ...transformedData.text,
      ...transformedTexts,
    }

    return transformedData
  }, [tvConfig, locales])

  const [activeLanguage, setActiveLanguage] = useState<LanguageCode>(
    initialValues.text?.languages?.[0]
  )
  const [selectedLanguages, setSelectedLanguages] = useState<LanguageCode[]>([
    initialValues.text?.languages?.[0],
  ])

  const availableLanguages = useMemo(() => {
    if (!locales) return []
    return locales.enabled
  }, [locales])

  useEffect(() => {
    if (!initialValues) return

    setActiveLanguage(initialValues.text.languages[0])
    setSelectedLanguages(initialValues.text.languages)
  }, [initialValues])

  const handleSubmit = (values: TVSettingsFormValues) => {
    mutateUpdateTVConfig(values)
  }

  const textLanguageKey = `text.[${activeLanguage}]`

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange
        validateOnBlur
        enableReinitialize
        validationSchema={() =>
          lazy((values: TVSettingsFormValues) => {
            return getSchema(values.text.languages || [])
          })
        }
      >
        {({ values, errors, touched, handleChange, handleBlur, setValues }) => {
          const tabErrors: Partial<Record<LanguageCode, boolean>> = {}

          const errorFields = getErrorFields(errors, touched)
          const errorLangs = [
            ...new Set(errorFields.map(getLangsFromObjectPath)),
          ]

          errorLangs.forEach((lang) => {
            const language = lang as LanguageCode
            tabErrors[language] = true
          })

          const descriptions = [
            values?.text[activeLanguage]?.line1,
            values?.text[activeLanguage]?.line2,
            values?.text[activeLanguage]?.line3,
          ].filter((desc) => !!desc)

          const qrPreviewValues: QR = {
            url: 'https://www.sportbuff.com/2ndscreenapp',
            descriptions,
          }

          return (
            <Form>
              <fieldset
                className="p-4 rounded-lg mb-4 bg-white-background"
                data-testid="qr-code-url"
              >
                <TextInput
                  id="url"
                  name="url"
                  label={
                    <div className="flex items-center gap-x-2">
                      <span>
                        {tr({
                          id: 'configuration.qrCodeUrl',
                        })}
                      </span>
                      <Tooltip placement="right">
                        <TooltipTrigger>
                          <div className={`flex flex-row w-fit`}>
                            <InfoCircleIcon className="w-4 h-4" />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <span>
                            {tr({
                              id: 'configuration.qrCodeUrlDescription',
                            })}
                          </span>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                  }
                  placeholder="https://www.example.com"
                  value={values.url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.url)}
                  errorLabel={errors.url}
                  touched={touched.url}
                  data-testid="tv-settings-form__url"
                />
              </fieldset>

              <LanguageTabContent
                activeLanguage={activeLanguage}
                selectedLanguages={selectedLanguages}
                availableLanguages={availableLanguages}
                errors={tabErrors}
                onLanguageChange={(lang) => setActiveLanguage(lang)}
                onSelectedLanguagesChange={(langs) => {
                  const removedLangs = selectedLanguages.filter(
                    (lang) => !langs.includes(lang)
                  )
                  const addedLangs = langs.filter(
                    (lang) => !selectedLanguages.includes(lang)
                  )
                  let newValues = cloneDeep(values)
                  newValues.text.languages = langs

                  if (removedLangs.length !== 0) {
                    newValues = deepOmit(newValues, removedLangs)
                  }

                  if (addedLangs.length !== 0) {
                    const valuesToMerge = {
                      text: {
                        languages: newValues.text.languages,
                      },
                    }

                    addedLangs.forEach((lang) => {
                      // @ts-ignore
                      valuesToMerge.text[lang] = {
                        line1: '',
                        line2: '',
                        line3: '',
                      }
                    })

                    newValues = merge(newValues, valuesToMerge)
                  }

                  setValues(newValues)
                  setSelectedLanguages(langs)
                }}
              >
                <div className="rounded-tr rounded-br rounded-bl bg-blue-100 bg-white-background">
                  <p className="mb-1">
                    {tr({ id: 'configuration.qrCodeText' })}
                  </p>
                  <fieldset className="flex flex-col space-y-2">
                    <TextInput
                      className="max-w-[300px]"
                      id={`${textLanguageKey}.line1`}
                      placeholder={tr({ id: 'configuration.text1Placeholder' })}
                      value={get(values, `${textLanguageKey}.line1`)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(get(errors, `${textLanguageKey}.line1`))}
                      errorLabel={get(errors, `${textLanguageKey}.line1`)}
                      touched={Boolean(
                        get(touched, `${textLanguageKey}.line1`)
                      )}
                      data-testid={`${textLanguageKey}_line1`}
                    />
                    <TextInput
                      className="max-w-[300px]"
                      id={`${textLanguageKey}.line2`}
                      placeholder={tr({ id: 'configuration.text2Placeholder' })}
                      value={get(values, `${textLanguageKey}.line2`)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(get(errors, `${textLanguageKey}.line2`))}
                      errorLabel={get(errors, `${textLanguageKey}.line2`)}
                      touched={Boolean(
                        get(touched, `${textLanguageKey}.line2`)
                      )}
                      data-testid={`${textLanguageKey}_line2`}
                    />
                    <TextInput
                      className="max-w-[300px]"
                      id={`${textLanguageKey}.line3`}
                      placeholder={tr({ id: 'configuration.text3Placeholder' })}
                      value={get(values, `${textLanguageKey}.line3`)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(get(errors, `${textLanguageKey}.line3`))}
                      errorLabel={get(errors, `${textLanguageKey}.line3`)}
                      touched={Boolean(
                        get(touched, `${textLanguageKey}.line3`)
                      )}
                      data-testid={`${textLanguageKey}_line3`}
                    />
                  </fieldset>
                </div>
              </LanguageTabContent>

              <div className="flex justify-end">
                <Button type="submit" disabled={isLoading}>
                  {tr({
                    id: 'generic.update',
                  })}
                </Button>
              </div>
              <PreviewProviderDataSource
                theme={Theme.LIGHT}
                qr={qrPreviewValues}
                activeLanguage={activeLanguage}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default TvSettings
