import React, {
  useState,
  useRef,
  ChangeEvent,
  KeyboardEvent,
  FormEvent,
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
} from 'react'
import { PasscodeInputProps, PasscodeInputRef } from './types'

const DEFAULT_CODE_LENGTH = 6

const PasscodeInput = forwardRef<
  PasscodeInputRef,
  PropsWithChildren<PasscodeInputProps>
>(
  (
    {
      error,
      onSubmit,
      onChange,
      initialValue,
      regex = /[^0-9]/g,
      codeLength = DEFAULT_CODE_LENGTH,
      loading = false,
    },
    ref
  ) => {
    const inputElementLoop: string[] = Array(codeLength).fill('')
    const [inputValues, setInputValues] = useState<string[]>(() => {
      if (initialValue && initialValue.length === codeLength) {
        return initialValue.split('')
      }

      return [...inputElementLoop]
    })
    const inputRefs = useRef<(HTMLInputElement | null)[]>([])
    const formRef = useRef<HTMLFormElement>(null)

    const validCodeInput = inputValues.every((val) => val.trim() !== '')

    const handleSubmit = (event: FormEvent) => {
      event.preventDefault()

      if (!validCodeInput) return

      onSubmit && onSubmit(inputValues.join(''))
    }

    const handleReset = (event: FormEvent) => {
      event.preventDefault()
      setInputValues([...inputElementLoop])

      onChange && onChange(inputElementLoop.join(''))

      const target = inputRefs.current[0]
      target?.focus()
      target?.select()
    }

    const handleChangeWrapper = (index: number) => {
      return (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value

        const isBlackListedChar = (char: string) => {
          const blacklistedChars = [' ']
          return blacklistedChars.includes(char)
        }

        const formattedValue = value
          ?.replace(regex, '')
          .split('')
          .filter((char) => {
            return !isBlackListedChar(char)
          })
          .join('')

        if (formattedValue.length === 0) {
          return
        }

        if (formattedValue.length > 1) {
          const maxInputsToAddTo = codeLength - index
          const inputsToAddTo = Math.min(
            formattedValue.length,
            maxInputsToAddTo
          )

          const newArray = [...inputValues]
          for (let i = 0; i < inputsToAddTo; i++) {
            newArray[index + i] = formattedValue[i]
          }
          setInputValues(newArray)

          const code = newArray.join('').trim()

          onChange && onChange(code)

          const nextInputIndex = Math.min(index + inputsToAddTo, codeLength - 1)
          const target = inputRefs.current[nextInputIndex]
          target?.focus()
          target?.select()

          const isValid = newArray.every((val) => val.trim() !== '')
          isValid && onSubmit?.(code)
          return
        }

        const newArray = [...inputValues]
        newArray[index] = formattedValue
        setInputValues(newArray)

        const code = newArray.join('').trim()
        onChange && onChange(code)

        // can't move focus as no next element
        if (index === codeLength - 1) {
          const isValid = newArray.every((val) => val.trim() !== '')
          isValid && onSubmit?.(code)
          return
        }

        const nextInputIndex = index + 1
        inputRefs.current[nextInputIndex]?.focus()
      }
    }

    const handleKeyDownWrapper = (index: number) => {
      return (event: KeyboardEvent) => {
        switch (event.key) {
          case 'Backspace': {
            event.preventDefault()

            const newArray = [...inputValues]
            newArray[index] = ''
            setInputValues(newArray)

            onChange && onChange(newArray.join('').trim())

            // can't move focus as no prev element
            if (index === 0) return

            const prevInputIndex = index - 1
            const target = inputRefs.current[prevInputIndex]
            target?.focus()
            target?.select()

            return
          }

          case 'ArrowLeft': {
            event.preventDefault()

            // can't move focus as no prev element
            if (index === 0) return

            const prevInputIndex = index - 1
            const target = inputRefs.current[prevInputIndex]
            target?.focus()
            target?.select()

            return
          }

          case 'ArrowRight': {
            event.preventDefault()
            // can't move focus as no prev element
            if (index === codeLength - 1) return

            const nextInputIndex = index + 1
            const target = inputRefs.current[nextInputIndex]
            target?.focus()
            target?.select()

            return
          }

          case 'Enter': {
            event.preventDefault()

            if (!validCodeInput) return

            const code = inputValues.join('').trim()
            onSubmit?.(code)

            return
          }

          case 'ArrowUp':
            event.preventDefault()
            return

          case 'ArrowDown':
            event.preventDefault()
            return
        }
      }
    }

    useImperativeHandle(ref, () => ({
      clear: () => {
        setInputValues([...inputElementLoop])

        onChange && onChange(inputElementLoop.join(''))

        const target = inputRefs.current[0]

        setTimeout(() => {
          target?.focus()
        }, 100)
      },
    }))

    return (
      <form
        ref={formRef}
        data-testid="passcode-input"
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        <div className="flex">
          {inputElementLoop.map((_, i) => {
            const value = inputValues[i] ?? ''
            let statusBarBg = ''

            if (error) {
              statusBarBg = 'bg-danger'
            } else if (value.trim()) {
              statusBarBg = 'bg-primary'
            } else {
              statusBarBg = 'bg-white'
            }

            const inputBorder = error ? 'border-danger' : 'border-gray'

            return (
              <div key={i} className="mx-1 my-2.5 w-8">
                <input
                  type="text"
                  ref={(el) => (inputRefs.current[i] = el)}
                  value={value}
                  autoFocus={i === 0}
                  onChange={handleChangeWrapper(i)}
                  onKeyDown={handleKeyDownWrapper(i)}
                  className={`text-md w-full p-0 text-center text-[#000] focus:ring-primary py-2 border ${inputBorder} focus:border-primary mb-1.5 rounded`}
                  disabled={loading}
                  data-testid={`passcode-input__input-${i}`}
                />

                <div className={`h-[5px] rounded-sm ${statusBarBg}`} />
              </div>
            )
          })}
        </div>
      </form>
    )
  }
)

PasscodeInput.displayName = 'PasscodeInput'

export default PasscodeInput
