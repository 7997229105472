import React, { FC, ReactNode } from 'react'
import { tr } from '@constants/other'
import prettifyNumber from '@utils/prettifyNumber'
import Image from '@components/atoms/Image'

interface BuffAnswerStatsProps {
  showPhotoUrl?: boolean
  correct?: boolean
  photoUrl?: string
  title: string
  percentage: number
  totalCount: number
  className?: string
  firstElement?: ReactNode
}

const BuffAnswerStats: FC<React.PropsWithChildren<BuffAnswerStatsProps>> = ({
  showPhotoUrl = false,
  photoUrl,
  title,
  percentage,
  totalCount,
  correct,
  firstElement,
  className = '',
}) => {
  return (
    <tr className={className}>
      {!!firstElement && <td className="text-sm pb-2">{firstElement}</td>}
      {showPhotoUrl && !firstElement && (
        <td>
          <div className="flex">
            <div className="relative h-6 w-6 flex-shrink-0 mr-2">
              <Image
                className="w-[22px] h-[22px]"
                src={photoUrl}
                fallbackComponent={
                  <img
                    src="/assets/icon-image.png"
                    alt={tr({ id: 'buff.answerImg' })}
                    width={22}
                    height={22}
                  />
                }
                alt={`answer ${title}`}
              />
              {correct && (
                <img
                  src="/assets/svg/icon-check-rounded.svg"
                  className="absolute"
                  width={13}
                  height={13}
                  style={{ top: -3, right: 13 }}
                  alt={tr({ id: 'buff.correctImg' })}
                />
              )}
            </div>
          </div>
        </td>
      )}
      <td className="pr-2 pb-2 text-left sb-break-words">
        <span className="line-clamp-2 ">{title}</span>
      </td>
      {/* Percentage Bar */}
      <td className="whitespace-nowrap w-full pb-2">
        <div
          style={{
            width: '100%',
            background: 'black',
            height: '8px',
            borderRadius: '5px',
          }}
        >
          <div
            className="transition-all"
            style={{
              width: `${percentage}%`,
              background: 'var(--primary)',
              height: '8px',
              borderRadius: '5px',
            }}
          ></div>
        </div>
      </td>
      {/* Percentage number */}
      <td style={{ fontWeight: 600 }} className="px-2 pb-2 text-right">
        {Math.round(percentage * 100) / 100}%
      </td>
      {/* Votes Counted */}
      <td className="pb-2 text-right" style={{ fontWeight: 300 }}>
        {prettifyNumber(totalCount)}
      </td>
    </tr>
  )
}

export default BuffAnswerStats
