import { Locales } from '@interfaces/ClientConfig'
import { ILanguage, LanguageCode } from '@interfaces/ILanguage'
import { useClientConfig } from '@services/requests/client-config'
import { LanguagesMap } from '@utils/language'
import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react'

export interface LanguageContextProps {
  languages: ILanguage[]
  clientLanguages: Locales
}

const LanguageContext = createContext<LanguageContextProps>(
  {} as LanguageContextProps
)
const { Provider } = LanguageContext

const LanguageProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { getLanguages } = useClientConfig()
  const [languages, setLanguages] = useState<ILanguage[]>([])
  const [clientLanguages, setClientLanguages] = useState<Locales>({
    default: LanguageCode.en,
    enabled: [LanguageCode.en],
  })

  const fetchLanguages = useCallback(async () => {
    const data = Object.keys(LanguagesMap).map((lang) => {
      return LanguagesMap[lang as LanguageCode]
    })
    const clientConfigLanguages = await getLanguages()
    const availableLanguages = data.filter(
      (lang: ILanguage) =>
        clientConfigLanguages.enabled.indexOf(lang.iso6391) > -1
    )
    setClientLanguages(clientConfigLanguages)
    setLanguages(availableLanguages)
  }, [getLanguages])

  useEffect(() => {
    if (languages.length) return

    fetchLanguages()
  }, [fetchLanguages, languages])

  return <Provider value={{ languages, clientLanguages }}>{children}</Provider>
}

export { LanguageContext, LanguageProvider }
