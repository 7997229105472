import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import {} from 'react-chartjs-2'
import { IRouteParams } from '@interfaces/RouteParams'
import { useAuth } from '@services/requests/auth-v2'
import generateChartData from '@utils/generateChartDate'
import { Activity, ActivityData } from '@interfaces/Auth-v2'
import { format } from 'date-fns'
import AreaChart from '@components/molecules/AreaChart'
import SelectInput from '@components/atoms/SelectInput'
import {
  platformOptions,
  tr,
  trCheck,
  userGroupOptions,
} from '@constants/other'
import TextInput from '@components/atoms/TextInput'
import schema from './schema'

const now = new Date('07 May 2024')
const now2 = new Date()
const mockData: Activity[] = [
  {
    timestamp: now.toISOString(),
    data: [
      {
        sdkPlatform: 'android',
        loggedIn: true,
        count: 1,
      },
      {
        sdkPlatform: 'android',
        loggedIn: false,
        count: 2,
      },
      {
        sdkPlatform: 'web',
        loggedIn: true,
        count: 3,
      },
    ],
  },
  {
    timestamp: now2.toISOString(),
    data: [
      {
        sdkPlatform: 'android',
        loggedIn: true,
        count: 6,
      },
      {
        sdkPlatform: 'android',
        loggedIn: false,
        count: 0,
      },
      {
        sdkPlatform: 'web',
        loggedIn: true,
        count: 4,
      },
    ],
  },
]

const StreamMetrics = () => {
  const { id: streamId = '' } = useParams<IRouteParams>()
  const { useGetActivityByStreamId, useGetActivitySummaryByStreamId } =
    useAuth()
  const [platform, setPlatform] = useState('all')
  const [userGroup, setUserGroup] = useState('all')
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [dateFilter, setDateFilter] = useState({
    startDate: '',
    endDate: '',
  })
  const [errors, setErrors] = useState<Record<string, string> | undefined>({})
  const { data: activity } = useGetActivityByStreamId(
    streamId,
    startDate,
    endDate
  )
  const { data: activitySummary } = useGetActivitySummaryByStreamId(streamId)
  const maxStartDate = format(new Date(), 'yyyy-MM-dd')

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  )

  const chartData: { [ts: string]: any } = useMemo(() => {
    if (!activity) return []

    return generateChartData(activity, platform, userGroup)
  }, [activity, platform, userGroup])
  const timeseries = Object.keys(chartData).map((timestamp) => {
    return format(new Date(timestamp), 'hh:mm')
  })

  const totalActivitySummary =
    activitySummary?.reduce((acc: number, curr: ActivityData) => {
      acc += curr.count
      return acc
    }, 0) ?? 0
  let peakConcurrency = 0

  Object.keys(chartData).forEach((ts) => {
    if (chartData[ts].userGroups.all.platforms.all > peakConcurrency) {
      peakConcurrency = chartData[ts].userGroups.all.platforms.all
    }
  })

  const generatedData = {
    labels: timeseries,
    datasets: [
      {
        fill: true,
        label: 'All',
        data: Object.keys(chartData).map((ts) => {
          return chartData[ts].userGroups[userGroup].platforms[platform]
        }),
        borderColor: 'rgb(0, 123, 255)',
        backgroundColor: 'rgba(0, 123, 255, 0.5)',
      },
    ],
  }

  useEffect(() => {
    ;(async () => {
      try {
        await schema.validate({ startDate, endDate }, { abortEarly: false })
        setDateFilter({ startDate, endDate })
        setErrors({})
      } catch (err) {
        const errors: Record<string, string> = {}
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message
        })
        endDate && setErrors(errors)
      }
    })()
  }, [startDate, endDate])

  return (
    <>
      <div className="w-full">
        <div className="flex w-full gap-4">
          <TextInput
            containerClasses="w-1/2"
            type="datetime-local"
            pattern="\d{4}-\d{2}-\d{2}"
            id="startDate"
            max={maxStartDate}
            label={<span>{tr({ id: 'generic.startDate' })}</span>}
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value)
            }}
            error={!!errors?.startDate}
            errorLabel={errors?.startDate}
            touched={!!startDate}
            placeholder="DD/MM/YYYY"
            inputContainerClasses={`w-full ${
              !startDate ? 'text-[#959DA5]' : 'text-black'
            }`}
          />

          <TextInput
            containerClasses="w-1/2"
            type="datetime-local"
            pattern="\d{4}-\d{2}-\d{2}"
            id="endDate"
            max={maxStartDate}
            label={<span>{tr({ id: 'generic.endDate' })}</span>}
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value)
            }}
            placeholder="DD/MM/YYYY"
            inputContainerClasses={`w-full ${
              !endDate ? 'text-[#959DA5]' : 'text-black'
            }`}
          />
        </div>
        <div className="flex gap-4">
          <SelectInput
            containerClasses="w-full"
            label={
              <label className="font-semibold">
                {tr({ id: 'stream.userGroups' })}
              </label>
            }
            value={userGroup}
            id="userGroups"
            name="userGroups"
            onChange={(ev) => setUserGroup(ev.target.value)}
            options={userGroupOptions}
          />
          <SelectInput
            containerClasses="w-full"
            label={
              <label className="font-semibold">
                {tr({ id: 'stream.platform' })}
              </label>
            }
            value={platform}
            id="platform"
            name="platform"
            onChange={(ev) => setPlatform(ev.target.value)}
            options={platformOptions}
          />
        </div>
        <div className="flex py-4 px-10 gap-6">
          <div className="flex flex-col items-center">
            <div>{tr({ id: 'stream.metricsTotalActivity' })}</div>
            <div className="text-3xl font-bold">{totalActivitySummary}</div>
          </div>
          <div className="flex flex-col items-center">
            <div>{tr({ id: 'stream.metricsPeak' })}</div>
            <div className="text-3xl font-bold">{peakConcurrency}</div>
          </div>
        </div>
      </div>
      <AreaChart data={generatedData} />
    </>
  )
}

export default StreamMetrics
