import React, { FC, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form, FormikHelpers } from 'formik'
import Col from '@components/atoms/Col'
import { PlatformContext } from '@services/providers/PlatformProvider'
import { tr } from '@constants/other'
import { ILanguage, LanguageCode } from '@interfaces/ILanguage'
import ISelectedLanguage from '@interfaces/ISelectedLanguage'
import IStreamFormValues from '@interfaces/IStreamFormValues'
import TextInput from '@components/atoms/TextInput'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { IRouteParams } from '@interfaces/RouteParams'
import LanguagePickerInput from '@components/molecules/LanguagePickerInput'
import Button, { ButtonVariant } from '@components/atoms/Button'
import MultiInput from '@components/molecules/MultiInput'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { Schema } from './schema'
import useLanguageStore from '@services/stores/useLanguageStore'

interface IProps {
  title?: string
  providerId?: string
  bonusTimePoints?: number
  currentStream?: string
  currentLanguages?: LanguageCode[] | null
  twitchChannelIds?: string[]
  showBuffDetails?: boolean
  handleSubmit: (
    values: IStreamFormValues,
    helpers: FormikHelpers<IStreamFormValues>
  ) => void
  handleDelete?: (id: string) => void
}

const StreamForm: FC<React.PropsWithChildren<IProps>> = ({
  title,
  providerId,
  bonusTimePoints = 50,
  currentStream,
  currentLanguages,
  twitchChannelIds,
  showBuffDetails = true,
  handleSubmit,
  handleDelete,
}) => {
  const { id: streamId } = useParams<IRouteParams>()

  const [languages, setLanguages] = useState<ISelectedLanguage[]>([])
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const { allLanguages: storeLanguages } = useLanguageStore()

  const { includesTwitch } = useContext(PlatformContext)
  const disabledLanguagePicker = Boolean(currentStream)

  useEffect(() => {
    if (!storeLanguages) return

    const languages: ISelectedLanguage[] = []

    storeLanguages.forEach((language: ILanguage) => {
      if (
        currentLanguages?.find(
          (lang: LanguageCode) => lang === language.iso6391
        )
      ) {
        languages.push({ ...language, selected: true })
      } else {
        languages.push({ ...language, selected: false })
      }
    })
    setLanguages(languages.sort((a, b) => a.id - b.id))
  }, [storeLanguages, currentLanguages])

  const onSubmit = (
    values: IStreamFormValues,
    helpers: FormikHelpers<IStreamFormValues>
  ) => {
    handleSubmit(values, helpers)
  }

  return (
    <>
      <Formik
        initialValues={{
          title: title ?? '',
          providerId: providerId ?? '',
          bonusTimePoints: bonusTimePoints ?? 0,
          twitchChannelIds: twitchChannelIds ?? [''],
          languages: languages,
        }}
        enableReinitialize
        validationSchema={Schema}
        validateOnBlur
        onSubmit={(
          values: IStreamFormValues,
          helpers: FormikHelpers<IStreamFormValues>
        ) => {
          onSubmit(values, helpers)
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            <div
              data-testid="stream-details"
              className="py-3 px-7 rounded flex flex-col border border-t"
              style={{ backgroundColor: 'var(--white-background)' }}
            >
              <div className="w-full">
                <h3 className="font-bold mb-3">
                  {tr({ id: 'stream.details' })}
                </h3>
                <div className="flex">
                  <Col sm={4} className="pl-0 min-w-[296px]">
                    <TextInput
                      id="title"
                      label={tr({ id: 'qaForm.name' })}
                      className={'w-full px-2 py-1 rounded'}
                      placeholder={tr({ id: 'stream.streamTitle' })}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.title)}
                      errorLabel={errors.title}
                      touched={touched.title}
                      data-testid="stream-form__name"
                    />
                  </Col>
                  <Col sm={4}>
                    <TextInput
                      id="providerId"
                      label={
                        <div className="flex items-center gap-x-2 cursor-pointer">
                          <span>{tr({ id: 'stream.providerId' })}</span>
                          <Tooltip placement="right">
                            <TooltipTrigger>
                              <InfoCircleIcon className="w-4 h-4" />
                            </TooltipTrigger>
                            <TooltipContent>
                              {tr({
                                id: 'stream.providerIdDesc',
                              })}
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      }
                      style={{ border: '1px solid lightgrey' }}
                      className={'w-full px-2 py-1 rounded'}
                      placeholder={tr({ id: 'generic.id' })}
                      value={values.providerId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.providerId)}
                      errorLabel={errors.providerId}
                      touched={touched.providerId}
                      data-testid="stream-form__provider-id"
                    />
                  </Col>
                </div>
                {includesTwitch && (
                  <div className="flex justify-between mb-3">
                    <Col className="pl-0" sm={5}>
                      <MultiInput
                        label={tr({ id: 'stream.twitchChannelId' })}
                        placeholder={tr({ id: 'stream.twitchChannelId' })}
                        name="twitchChannelIds"
                        data-testid="multi-input-twitchChannelIds"
                      />
                    </Col>
                  </div>
                )}

                <div className="flex flex-col">
                  <LanguagePickerInput
                    id="languages"
                    value={values.languages}
                    error={Boolean(errors.languages)}
                    label={tr({
                      id: 'stream.supportedLanguages',
                    })}
                    touched={touched.languages}
                    disabled={disabledLanguagePicker}
                    onChange={(langs) => {
                      setFieldValue('languages', langs)
                    }}
                  />
                </div>
              </div>

              {showBuffDetails && (
                <div className="w-full">
                  <h3 className="font-bold mt-3 mb-3">
                    {tr({ id: 'buff.details' })}
                  </h3>
                  <div>
                    <TextInput
                      id="bonusTimePoints"
                      label={
                        <div className="flex items-center gap-x-2 cursor-pointer">
                          <span>{tr({ id: 'stream.bonusTimePoints' })}</span>
                          <Tooltip placement="right">
                            <TooltipTrigger>
                              <InfoCircleIcon className="w-4 h-4" />
                            </TooltipTrigger>
                            <TooltipContent>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: tr({
                                    id: 'stream.bonusTimePointsDesc',
                                  }),
                                }}
                              />
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      }
                      type={'number'}
                      min={50}
                      max={250}
                      style={{
                        border: '1px solid lightgrey',
                      }}
                      className={'px-2 py-1 rounded w-44'}
                      value={values.bonusTimePoints}
                      onChange={handleChange}
                      error={Boolean(errors.bonusTimePoints)}
                      errorLabel={errors.bonusTimePoints}
                      touched={touched.bonusTimePoints}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex mx-9 relative">
              <div
                className={`flex w-full absolute -bottom-28 mx-auto  ${
                  streamId ? 'justify-between' : 'justify-end'
                }`}
              >
                {streamId && (
                  <Button
                    size="small"
                    variant="secondary"
                    className="delete-btn w-[11rem]"
                    type="button"
                    onClick={() => setShowDelete(true)}
                    data-testid="stream-form__delete"
                  >
                    {tr({ id: 'stream.deleteStream' })}
                  </Button>
                )}
                <Button
                  size="small"
                  className="w-[11rem]"
                  variant="primary"
                  type="submit"
                  data-testid="stream-form__submit"
                  disabled={isSubmitting}
                >
                  {currentStream
                    ? tr({ id: 'stream.updateStream' })
                    : tr({ id: 'stream.addStream' })}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        show={showDelete}
        onClose={() => setShowDelete(false)}
        onSuccess={() => {
          if (handleDelete && currentStream) handleDelete(currentStream)
        }}
        title={tr({ id: 'stream.deleteStreamConfirmationTitle' })}
        successBtnText={tr({ id: 'stream.deleteStream' })}
        variant={ButtonVariant.Danger}
        subtext={tr({ id: 'stream.confirmDelete' })}
      />
    </>
  )
}

export default StreamForm
