import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

interface IInfiniteScrollProps {
  /**
   * If there is an api request loading more data
   */
  isLoading: boolean

  /**
   * If there is another page of results to load
   */
  hasNextPage?: boolean

  /**
   * Handler that should be called when more data should be loaded
   */
  onLoadMore: () => void
}

interface IInfiniteScrollReturn {
  /**
   * Ref function to be added to a trigger element in a list
   */
  triggerRef: (node?: Element | null) => void
}

export const useInfiniteScroll = ({
  isLoading,
  hasNextPage,
  onLoadMore,
}: IInfiniteScrollProps): IInfiniteScrollReturn => {
  const [triggerRef, inView] = useInView({
    rootMargin: '100px',
  })

  const shouldLoadMore = !isLoading && inView && hasNextPage

  useEffect(() => {
    if (shouldLoadMore) {
      onLoadMore()
    }
  }, [onLoadMore, shouldLoadMore])

  return { triggerRef }
}
