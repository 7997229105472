import { AxiosResponse } from 'axios'
import { client } from '@services/requests/client'
import { CreateVoteable, GetVoteablesResponse } from '@interfaces/Voteable'
import { Engagement, EngagementResponse } from '@interfaces/Engagement'
import { transformRawEngagementToEngagement } from '@utils/buff'
import { getPaginatedUrl } from '@utils/url'

export interface IVoteableParams {
  gameId: string
  voteable?: CreateVoteable
  pageSize?: number
  nextParam?: string
}

export interface IResolveVoteableParams {
  gameId: string
  voteableId: string
  correctAnswers: string[]
}

export interface ResendVoteableParams {
  gameId: string
  voteableId: string
}

export const getVoteablesByGameId = async (
  p: IVoteableParams
): Promise<GetVoteablesResponse> => {
  const url = getPaginatedUrl(
    `/games/${p.gameId}/voteables`,
    p.pageSize ?? 50,
    p.nextParam ?? ''
  )

  return client.get(url).then((res) => ({
    voteables: res.data.voteables,
    nextPage: res.data.pagination.next,
  }))
}

export const getVoteableEngagement = async (
  gameId: string,
  voteableId: string
): Promise<Engagement> => {
  const url = `/games/${gameId}/voteables/${voteableId}/stats`
  const response: AxiosResponse<EngagementResponse> = await client.get(url)
  return transformRawEngagementToEngagement(response.data.engagement)
}

export const resolveVoteable = async (
  p: IResolveVoteableParams
): Promise<void> => {
  const url = `/games/${p.gameId}/voteables/${p.voteableId}/resolve`
  return client.post(url, { correctAnswers: p.correctAnswers })
}

export const createVoteable = async (p: IVoteableParams): Promise<void> => {
  const url = `/games/${p.gameId}/voteables`
  await client.post(url, p.voteable)
  return Promise.resolve()
}

export const resendVoteableLastState = async (
  p: ResendVoteableParams
): Promise<void> => {
  const url = `/games/${p.gameId}/voteables/${p.voteableId}/resend-last-event`
  return client.post(url)
}
