import React, { FC } from 'react'
import LeaderboardItem from '@components/molecules/LeaderboardItem'
import LeaderboardSkeleton from '@components/molecules/LeaderboardSkeleton'
import DelayRender from '@components/atoms/DelayRender'
import { tr } from '@constants/other'
import { Standing } from '@interfaces/Leaderboard'

interface ILeaderboardListProps {
  leaderboard: Standing[] | null

  /**
   * If the list is being displayed in modal
   * @default false
   */
  displayInModal?: boolean

  /**
   * Handler called when user moderation button is clicked
   */
  onModerationClick?: (user: Standing, locked: boolean) => void
}

const LeaderboardList: FC<React.PropsWithChildren<ILeaderboardListProps>> = ({
  leaderboard,
  onModerationClick,
  displayInModal = false,
}) => {
  const tableConfig = {
    columnSizes: ['10%', '25%', '25%', '30%', '10%'],
    headers: [
      tr({ id: 'leaderboard.rank' }),
      tr({ id: 'leaderboard.name' }),
      tr({ id: 'leaderboard.email' }),
      tr({ id: 'leaderboard.opaqueId' }),
      tr({ id: 'leaderboard.points' }),
      '',
    ],
    loadingRowSizes: displayInModal
      ? ['30%', '55%', '30%', '40%']
      : ['30%', '55%', '30%', '40%', '50%', '35%', '60%'],
  }
  // Loading State is delayed to prevent flickering if there is a Leaderboard
  if (!leaderboard) {
    return (
      <DelayRender>
        <LeaderboardSkeleton
          message={tr({ id: 'leaderboard.noData' })}
          rowSizes={tableConfig.loadingRowSizes}
          displayInModal={displayInModal}
        />
      </DelayRender>
    )
  }

  return (
    <div
      className={`align-middle inline-block min-w-full px-4 overflow-y-auto mt-3`}
    >
      <table className="auto min-w-full">
        {/* Table Column Sizes */}

        {/* Leaderboard Headers */}
        <thead
          style={{
            boxShadow: `inset 0 -1px 0 #cecece`,
            borderBottom: '1px solid #E1E4E8',
          }}
          className="sticky top-0"
        >
          <tr>
            {tableConfig.headers.map((headerName) => {
              const width = headerName === '' ? '40px' : undefined
              return (
                <th
                  key={headerName}
                  className={`pt-4 pb-2 text-sm font-bold text-gray-500 last:text-right px-1`}
                  style={{ width }}
                >
                  {headerName}
                </th>
              )
            })}
          </tr>
        </thead>

        {/* Leaderboard Scrollable List */}
        <tbody className="scrollable-container">
          {leaderboard.map((user) => (
            <LeaderboardItem
              key={user?.userId}
              user={user}
              onModerationClick={onModerationClick}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LeaderboardList
