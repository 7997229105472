import {
  AssetClass,
  UploadableAsset,
  UploadableAssetMetadata,
} from '@interfaces/Assets'
import { trimFileNameExtension } from '@utils/asset'
import axios from 'axios'
import { uniqueId } from 'lodash'
import { isFileSizeCorrect } from './isFileSizeCorrect'

const generateUploadableAssetMeta = (image: File, type?: AssetClass) => {
  const uploadableAssetMeta: UploadableAssetMetadata = {
    filename: trimFileNameExtension(image.name),
    title: '',
    class: type,
    description: '',
    altText: '',
  }
  return uploadableAssetMeta
}

export const generateUploadableAsset = (
  image: File,
  hasSizeError?: boolean,
  type?: AssetClass,
  accept?: string
) => {
  const uploadableAsset: UploadableAsset = {
    id: uniqueId(),
    meta: generateUploadableAssetMeta(image, type),
    image,
    progress: 0,
    sizeLoaded: 0,
    sizeTotal: image.size,
    status:
      hasSizeError || !isFileSizeCorrect(image.size) ? 'error' : 'pending',
    sizeError: hasSizeError || !isFileSizeCorrect(image.size),
    cancelRequest: axios.CancelToken.source(),
    accept: accept,
  }
  return uploadableAsset
}
