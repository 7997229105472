import { useMutation } from 'react-query'

import { CreateAuthor, Author } from '@interfaces/Author'

import { createAuthor } from '@services/requests/author'
import { queryClient } from '@utils/reactQuery/client'

/**
 *
 * @return {Author}
 */
export default function useCreateAuthor() {
  return useMutation(
    (values: CreateAuthor): Promise<Author> => createAuthor(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('authors')
      },
    }
  )
}
