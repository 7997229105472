import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { Theme } from '@interfaces/IStreamConfig'
import { getClientThemes } from '@services/requests/theme'

const clientName = process.env.ACCOUNT_NAME ?? ''

type ThemeObj = {
  key: string
  value: string
}

const defaultTheme: ThemeObj = {
  key: 'SportBuff',
  value: 'SportBuff',
}

export const useTheme = (value?: string) => {
  const key = ['themes']
  const { data } = useQuery(key, () => getClientThemes(clientName))

  const themeOptions = useMemo(() => {
    if (!data) return [defaultTheme]
    return data.map((theme: any) => ({
      key: theme.theme.name,
      value: theme.theme.name,
    }))
  }, [data]) as ThemeObj[]

  const theme = (themeOptions.find((t) => t.key === value)?.key ??
    themeOptions[0]?.key) as Theme

  return {
    theme,
    themeOptions,
  }
}
