import EmojiStatsItem from '@components/atoms/EmojiStatsItem'
import { tr } from '@constants/other'
import { EmojiItem } from '@interfaces/Reactions'
import React, { Fragment } from 'react'

const EmojiStatsList = ({ emojis }: { emojis: EmojiItem[] }) => {
  const totalCount = emojis.reduce((acc, curr) => (acc += curr.count), 0)

  return (
    <>
      <div>
        <div className="text-[#586069] mb-6">
          {tr({ id: 'generic.totalReactions' })} <strong>{totalCount}</strong>
        </div>
      </div>
      {emojis.map((emoji) => {
        const { id, imageUrl, count, percent } = emoji
        return (
          <Fragment key={id}>
            <EmojiStatsItem
              percent={percent}
              imageUrl={imageUrl}
              count={count}
            />
          </Fragment>
        )
      })}
    </>
  )
}

export default EmojiStatsList
