import { Platform } from '@interfaces/IStreamConfig'

export const PLATFORMS = [
  {
    name: 'Web SDK',
    value: Platform.WEB,
  },
  {
    name: 'Android',
    value: Platform.ANDROID,
  },
  {
    name: 'IOS',
    value: Platform.IOS,
  },
  {
    name: 'Twitch',
    value: Platform.TWITCH,
  },
  {
    name: 'Tizen/WebOS',
    value: Platform.TizenTV,
  },
  {
    name: 'Android TV',
    value: Platform.AndroidTV,
  },
]
