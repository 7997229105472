import React, {
  FC,
  useState,
  useContext,
  useEffect,
  useCallback,
  Fragment,
} from 'react'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import { useParams } from 'react-router-dom'
import { BuffContext, IRouteParams } from '@services/providers/BuffProvider'
import BuffHistoryList from '@components/organisms/BuffHistoryList'
import { BuffPageActiveTab } from '@pages/BuffPage'
import { tr } from '@constants/other'
import { PubNubEventType } from '@interfaces/Pubnub'
import ScrollableContainer from '@components/atoms/ScrollableContainer'
import { MessagingContext } from '@services/providers/MessagingProvider'
import EmptyStateWithIcon from '@components/molecules/EmptyStateWithIcon'
import { ReactComponent as SearchIcon } from '@assets/search.svg'
import './BuffPageAside.styles.scss'

export enum SelectedAsideTab {
  Preview = 'PREVIEW_BUFF',
  UnresolvedBuffs = 'UNRESOLVED_BUFFS',
  BuffHistory = 'BUFF_HISTORY',
}

interface IProps {
  setMainActiveTab: (tab: BuffPageActiveTab) => void
}

const pubNubMsgsToRefetch = [
  PubNubEventType.ANNOUNCEMENT_CREATE,
  PubNubEventType.VOTEABLE_CREATE,
  PubNubEventType.VOTEABLE_CLOSE,
  PubNubEventType.VOTEABLE_RESOLVE,
]

const clientName = process.env.ACCOUNT_NAME ?? ''

const Aside: FC<React.PropsWithChildren<IProps>> = ({ setMainActiveTab }) => {
  const { client: messagingClient } = useContext(MessagingContext)

  const { gameId } = useParams<IRouteParams>()

  const {
    state,
    voteablesTriggerRef,
    voteablesData,
    refetchBuffs,
    isFetchingBuffs,
  } = useContext(BuffContext)
  const [activeTab, setActiveTab] = useState<SelectedAsideTab>(
    SelectedAsideTab.BuffHistory
  )

  const hasData = !!voteablesData?.pages[0]?.content?.length

  const channel = gameId
    ? `broadcaster.${clientName}.games.${gameId}`
    : undefined

  const handleMessage = useCallback(
    (messageEvent: any) => {
      const message = messageEvent.data
      if (pubNubMsgsToRefetch.includes(message.name)) {
        refetchBuffs()
      }
    },
    [refetchBuffs]
  )

  const tabs = [
    {
      text: tr({ id: 'buffHistory.buffHistory' }),
      id: '1',
    },
    {
      text: `${tr({ id: 'generic.unresolved' })} (${
        state.unresolvedBuffs.length
      })`,
      id: '2',
    },
  ]

  const tabOrder = [
    SelectedAsideTab.BuffHistory,
    SelectedAsideTab.UnresolvedBuffs,
  ]

  const tabIndex = tabOrder.indexOf(activeTab)

  useEffect(() => {
    if (!channel || !messagingClient.current) return

    const channelSubscription = messagingClient.current
      .newSubscription(channel)
      .on('publication', function (message) {
        handleMessage(message)
      })

    channelSubscription.subscribe()
    return () => {
      messagingClient.current?.removeSubscription(channelSubscription)
    }
  }, [channel, handleMessage, messagingClient])

  return (
    <div data-testid="buff-page-aside">
      <Tabs
        renderBorder={false}
        tabs={tabs}
        selectedIndex={tabIndex}
        onTabChange={(index) => {
          const tabName = tabOrder[index]
          setActiveTab(tabName)
        }}
      >
        <TabPanel>
          <div className="flex flex-col pt-4">
            <div className="bg-gray rounded">
              <ScrollableContainer
                heightOverflow="245px"
                className="default-scroll px-2 py-2"
              >
                {!hasData && !isFetchingBuffs && (
                  <EmptyStateWithIcon
                    containerClasses="!h-auto mt-12"
                    textClasses="text-[#959DA5]"
                    iconClasses="h-[64px] w-[64px]"
                    text="No Buffs to show"
                    icon={<SearchIcon className="w-8" />}
                  />
                )}

                {voteablesData?.pages.map((page, index) => {
                  const buffs = page?.content?.map((content) => content?.entity)
                  return (
                    <Fragment key={index.toString()}>
                      {page?.content && (
                        <div>
                          <BuffHistoryList
                            setMainActiveTab={setMainActiveTab}
                            buffs={buffs}
                            isHistory={true}
                          />
                        </div>
                      )}
                    </Fragment>
                  )
                })}
                <div ref={voteablesTriggerRef} className="h-1" />
              </ScrollableContainer>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="flex flex-col pt-4">
            <div className="bg-gray rounded">
              <ScrollableContainer
                heightOverflow="300px"
                className="default-scroll px-2 py-2"
              >
                {!state.unresolvedBuffs.length && !isFetchingBuffs && (
                  <EmptyStateWithIcon
                    containerClasses="!h-auto mt-12"
                    textClasses="text-[#959DA5]"
                    iconClasses="h-[64px] w-[64px]"
                    text="No Buff to show"
                    icon={<SearchIcon className="w-8" />}
                  />
                )}
                {state.unresolvedBuffs.length > 0 && (
                  <div>
                    <BuffHistoryList
                      setMainActiveTab={setMainActiveTab}
                      buffs={state.unresolvedBuffs}
                      showResolve
                    />
                  </div>
                )}
              </ScrollableContainer>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default Aside
