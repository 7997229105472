import { AnnouncementTemplate, Template } from '@interfaces/Template'

export enum TemplateTypeTabs {
  Buffs = 0,
  Announcements = 2,
  GlobalBuffs = 1,
  GlobalAnnouncements = 3,
}

export interface TemplateListProps {
  templates: (Template | AnnouncementTemplate)[]
  type: TemplateTypeTabs
  selected?:
    | (Template | AnnouncementTemplate)
    | (Template | AnnouncementTemplate)[]
  onClick: (template: Template | AnnouncementTemplate) => void
  onDoubleClick: (template: Template | AnnouncementTemplate) => void
}
