import {
  Leaderboard,
  RankingItem,
  PointsSource,
  UseGetStreamLeaderboards,
  PaymentOption,
  Standing,
  PaymentOptionPost,
} from '@interfaces/Leaderboard'
import {
  EmojiItem,
  LiveReactions,
  LiveReactionsMetric,
} from '@interfaces/Reactions'
import useClient, { Service } from '@utils/hooks/useClient'
import { useInfiniteQuery, useQuery } from 'react-query'
import { BatchUser } from '@interfaces/IUser'
import { getUsers } from './users'

interface RemovePaymentItem {
  paymentItems: string[]
  streamId: string
  leaderboardId: string
}

export interface ExpirePaymentItem {
  at?: string
  index?: number
  streamId: string
  leaderboardId: string
}

interface ToggleLeaderboardMonetisation {
  body: { paymentRequired: boolean }
  streamId: string
  leaderboardId: string
}

export const useLive = () => {
  const { client } = useClient(Service.LIVE)

  const getStreamLeaderboards = async (streamId: string) => {
    const data = await client.get<Leaderboard[]>(
      `/streams/${streamId}/leaderboards`
    )

    return data.data
  }

  const getStreamLeaderboardById = async (
    streamId: string,
    leaderboardId: string
  ) => {
    const data = await client.get<Leaderboard>(
      `/streams/${streamId}/leaderboards/${leaderboardId}`
    )

    return data.data
  }

  const getStreamLeaderboardStandingsById = async (
    streamId: string,
    leaderboardId: string
  ) => {
    const data = await client.get<RankingItem[]>(
      `/streams/${streamId}/leaderboards/${leaderboardId}/standings`
    )

    const userIds = data.data.map((standing) => standing.userId)
    let usersById: Record<string, BatchUser> = {}
    if (userIds.length > 0) {
      const usersMetadata = await getUsers(userIds)
      usersById = usersMetadata.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.id]: curr,
        }
      }, {})
    }

    const standings = data.data?.map((standing) => {
      return {
        ...(usersById[standing.userId] || {}),
        ...standing,
      }
    }) as Standing[]

    return standings
  }

  const createLeaderboard = async (payload: any) => {
    const { streamId, ...rest } = payload
    if (!streamId) return null

    const data = await client.post<Leaderboard>(
      `streams/${streamId}/leaderboards`,
      rest
    )

    return data.data
  }

  const updateLeaderboard = async (payload: any) => {
    const { streamId, leaderboardId, ...rest } = payload
    if (!streamId) return null

    const data = await client.put<Leaderboard>(
      `streams/${streamId}/leaderboards/${leaderboardId}`,
      rest
    )

    return data.data
  }

  const deleteLeaderboard = async (
    leaderboardId?: string,
    streamId?: string
  ) => {
    if (!streamId || !leaderboardId) return null

    return await client.delete(
      `streams/${streamId}/leaderboards/${leaderboardId}`
    )
  }

  const addPointSourceToLeaderboard = async (
    leaderboardId?: string,
    streamId?: string,
    pointsSource?: PointsSource[]
  ) => {
    return await client.post(
      `streams/${streamId}/leaderboards/${leaderboardId}/add-points-sources`,
      pointsSource
    )
  }

  const removePointSourceToLeaderboard = async (
    leaderboardId?: string,
    streamId?: string,
    pointsSource?: string[]
  ) => {
    return await client.post(
      `streams/${streamId}/leaderboards/${leaderboardId}/remove-points-sources`,
      pointsSource
    )
  }

  const addPaymentOptionToLeaderboard = async (
    leaderboardId?: string,
    streamId?: string,
    paymentOption?: PaymentOptionPost
  ) => {
    return await client.post(
      `streams/${streamId}/leaderboards/${leaderboardId}/add-payment-option`,
      [paymentOption]
    )
  }

  const removeLeaderboardPaymentOption = async (payload: RemovePaymentItem) => {
    const { streamId, leaderboardId, paymentItems } = payload

    const data = await client.post<Leaderboard>(
      `streams/${streamId}/leaderboards/${leaderboardId}/remove-payment-option`,
      paymentItems
    )

    return data.data
  }

  const expirePaymentItems = async (payload: ExpirePaymentItem) => {
    const { streamId, leaderboardId, ...rest } = payload

    const data = await client.post<Leaderboard>(
      `streams/${streamId}/leaderboards/${leaderboardId}/expire-payment-option`,
      rest
    )

    return data.data
  }

  const toggleLeaderboardMonetisation = async (
    payload: ToggleLeaderboardMonetisation
  ) => {
    const { streamId, leaderboardId, body } = payload

    const data = await client.post<Leaderboard>(
      `streams/${streamId}/leaderboards/${leaderboardId}/require-payment`,
      body
    )

    return data.data
  }

  const reorderLeaderboards = async (payload: {
    streamId: string
    leaderboardIds: string[]
  }) => {
    const { streamId, leaderboardIds } = payload
    if (!streamId) return null

    const data = await client.post<Leaderboard>(
      `streams/${streamId}/leaderboards/reorder`,
      leaderboardIds
    )

    return data.data
  }

  const finaliseScores = async (payload: {
    streamId: string
    leaderboardId: string
  }) => {
    const { streamId, leaderboardId } = payload
    if (!streamId) return null

    const data = await client.post<Leaderboard>(
      `streams/${streamId}/leaderboards/${leaderboardId}/finalise-scores?announceWinners=true`
    )

    return data.data
  }

  const useGetStreamLeaderboards = (params: UseGetStreamLeaderboards) => {
    const filter = params.filter || undefined
    return useInfiniteQuery(
      ['leaderboards', params.streamId, filter],
      ({ pageParam = '' }) => getStreamLeaderboards(params.streamId),
      // {
      //   getNextPageParam: (lastPage) => {
      //     if (!lastPage?.nextPage?.length) return undefined
      //     return lastPage?.nextPage
      //   },
      // }
      { enabled: !!params.streamId }
    )
  }

  const useGetStreamLeaderboardById = (
    streamId?: string,
    leaderboardId?: string
  ) => {
    return useQuery(
      ['leaderboard', leaderboardId],
      () =>
        getStreamLeaderboardById(streamId as string, leaderboardId as string),
      { enabled: !!leaderboardId && !!streamId }
    )
  }

  const useGetStreamLeaderboardStandingsById = (
    streamId?: string,
    leaderboardId?: string
  ) => {
    return useQuery(
      ['leaderboard', leaderboardId, 'standings'],
      () =>
        getStreamLeaderboardStandingsById(
          streamId as string,
          leaderboardId as string
        ),
      { enabled: !!leaderboardId && !!streamId }
    )
  }

  const getStreamReactions = async (streamId: string) => {
    const data = await client.get<LiveReactions>(
      `/streams/${streamId}/reactions`
    )

    return data.data
  }

  const getStreamReactionsSent = async (
    streamId: string,
    startDate?: string,
    endDate?: string
  ) => {
    let url = `/streams/${streamId}/reactions/sent?`

    if (startDate) {
      url += `startTime=${startDate}&`
    }

    if (endDate) {
      url += `endTime=${endDate}&`
    }

    url = url.slice(0, -1)

    const data = await client.get<LiveReactionsMetric[]>(url)

    const emojis = data.data || []
    const totalCount = emojis.reduce((acc, curr) => (acc += curr.count), 0)

    // calculate each emoji's percentage
    const emojisWithPercentage = emojis.map((emoji) => {
      const percent = (emoji.count * 100) / totalCount
      return {
        ...emoji,
        percent,
      }
    }) as EmojiItem[]

    return emojisWithPercentage
  }

  const updateStreamReactions = async (payload: {
    streamId: string
    request: LiveReactions
  }) => {
    const { streamId, request } = payload
    const data = await client.put<LiveReactions>(
      `/streams/${streamId}/reactions`,
      request
    )

    return data.data
  }

  const useGetStreamReactions = (streamId: string) => {
    return useQuery(
      ['stream-reactions', streamId],
      () => getStreamReactions(streamId as string),
      { enabled: !!streamId }
    )
  }

  const useGetStreamReactionsList = (
    streamId: string,
    startDate?: string,
    endDate?: string
  ) => {
    return useQuery(
      ['stream-reactions-sent', streamId, startDate, endDate],
      () => {
        const modifiedStartDate = startDate ? `${startDate}T00:00:00Z` : ''
        const modifiedEndDate = endDate ? `${endDate}T23:59:59Z` : ''
        return getStreamReactionsSent(
          streamId,
          modifiedStartDate,
          modifiedEndDate
        )
      },
      { enabled: !!streamId }
    )
  }

  return {
    useGetStreamLeaderboards,
    useGetStreamLeaderboardById,
    useGetStreamLeaderboardStandingsById,
    createLeaderboard,
    updateLeaderboard,
    deleteLeaderboard,
    expirePaymentItems,
    toggleLeaderboardMonetisation,
    addPointSourceToLeaderboard,
    removePointSourceToLeaderboard,
    addPaymentOptionToLeaderboard,
    removeLeaderboardPaymentOption,
    reorderLeaderboards,
    finaliseScores,
    useGetStreamReactions,
    useGetStreamReactionsList,
    updateStreamReactions,
  }
}
