import React from 'react'
import { useFormikContext } from 'formik'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import TextInput from '@components/atoms/TextInput'
import { tr } from '@constants/other'
import Col from '@components/atoms/Col'
import Row from '@components/atoms/Row'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'
import { format } from 'date-fns'
import { LeaderboardFormProps, LeaderboardFormValues } from './types'

const LeaderboardForm = ({
  'data-testid': testId = 'leaderboard-vip-form',
}: LeaderboardFormProps) => {
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<LeaderboardFormValues>()

  const minExpiryDate = format(new Date(), 'yyyy-MM-dd')

  return (
    <div data-testid={testId} className="flex flex-col gap-y-3 px-1">
      <div>
        <Row>
          <Col sm={7}>
            <fieldset>
              <TextInput
                type="date"
                pattern="\d{4}-\d{2}-\d{2}"
                min={minExpiryDate}
                id="expiryDate"
                label={
                  <div className="flex items-center gap-x-2">
                    <span>{tr({ id: 'leaderboard.expiryDate' })}</span>

                    <Tooltip placement="right">
                      <TooltipTrigger>
                        <div className="flex">
                          <InfoCircleIcon className="w-4 h-4" />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <div className="max-w-[320px]">
                          <p className="mb-0">
                            {tr({
                              id: 'leaderboard.expiryDateDescription',
                            })}
                          </p>
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                }
                value={values.expiryDate}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.expiryDate}
                error={!!errors.expiryDate}
                placeholder="DD/MM/YYYY"
                inputContainerClasses={
                  !values.expiryDate ? 'text-[#959DA5]' : 'text-black'
                }
              />
            </fieldset>
          </Col>
          <Col sm={5}>
            <fieldset>
              <TextInput
                type="time"
                id="expiryTime"
                label={
                  <div className="flex items-center gap-x-2">
                    <span>{tr({ id: 'leaderboard.expiryTime' })}</span>
                  </div>
                }
                value={values.expiryTime}
                touched={touched.expiryTime}
                error={!!errors.expiryDate && !!values.expiryTime}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="21:30"
                inputContainerClasses={
                  !values.expiryTime ? 'text-[#959DA5]' : 'text-black'
                }
              />
            </fieldset>
          </Col>
        </Row>
        {!!errors?.expiryDate && !!touched?.expiryDate && (
          <span className="text-[#CB2431] font-italic text-sm">
            {errors.expiryDate}
          </span>
        )}
      </div>
    </div>
  )
}

export default LeaderboardForm
