import { ApiResponse, HttpMethod } from '@constants/other'
import { IGame, IGameResponse, UpdateGame } from '@interfaces/games'
import ApiService from './ApiService'

export interface IGameService {
  getGameById: (gameId: string) => Promise<ApiResponse<IGameResponse>>
  endGameById: (gameId: string) => Promise<ApiResponse<IGameResponse>>
  updateGameById: (
    gameId: string,
    game: Partial<UpdateGame>
  ) => Promise<ApiResponse<IGameResponse>>
}

/**
 * Game service
 */
export default class GameService
  extends ApiService<IGame>
  implements IGameService
{
  /**
   * @param {string} gameId
   * @return {Promise}
   */
  async getGameById(gameId: string): Promise<ApiResponse<IGameResponse>> {
    return await this.instance.request({
      method: HttpMethod.Get,
      url: `/games/${gameId}`,
    })
  }

  /**
   * TODO: Desc
   * @param {string} gameId
   * @param {UpdateGame} game
   * @return {Promise}
   */
  async updateGameById(
    gameId: string,
    game: Partial<UpdateGame>
  ): Promise<ApiResponse<IGameResponse>> {
    return this.instance.patch(`/games/${gameId}`, game)
  }

  /**
   * End a game by passing the Game ID
   * @param {string} gameId
   * @return {Promise}
   */
  async endGameById(gameId: string): Promise<ApiResponse<IGameResponse>> {
    return this.instance.post(`/games/${gameId}/end`)
  }
}
