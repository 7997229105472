import { AxiosResponse } from 'axios'
import { client } from '@services/requests/client'
import { CreateStream, IStream, GetStreamsResponse } from '@interfaces/IStream'
import { getFilterUrl, getPaginatedUrl, getQueryUrl } from '@utils/url'
import { UseQueryOptions } from 'react-query'
import { QueryKey } from '@utils/reactQuery/types'
import { useFetch } from '@utils/reactQuery/useFetch'

export const createStream = async (stream: CreateStream): Promise<IStream> => {
  const response = await client.post('/streams', stream)
  return response.data.stream
}

export const updateStream = async (
  id: string,
  stream: Partial<CreateStream>
): Promise<IStream> => {
  const response = await client.patch(`/streams/${id}`, stream)
  return response.data.stream
}

export const startStream = async (id: string): Promise<AxiosResponse> => {
  const response = await client.post(`/streams/${id}/start`)
  return response
}

export const endStream = async (id: string): Promise<AxiosResponse> => {
  const response = await client.post(`/streams/${id}/end`)
  return response
}

export const getStreamById = async (id: string): Promise<IStream> => {
  const response = await client.get(`/streams/${id}`)
  return response.data.stream
}

export const getStreams = async (
  pageSize: number = 50,
  nextParam: string = '',
  query?: string,
  filter?: string
): Promise<GetStreamsResponse> => {
  const urlWithQuery = getQueryUrl('/streams', query)
  const urlWithFilter = getFilterUrl(urlWithQuery, filter)
  const urlWithPagination = getPaginatedUrl(urlWithFilter, pageSize, nextParam)

  const response = await client.get(urlWithPagination)

  const streams = response.data.streams
  const nextPage = response.data.pagination.next

  return {
    streams,
    nextPage,
  }
}

export const deleteStream = async (
  streamId: string
): Promise<AxiosResponse> => {
  const response = await client.delete(`/streams/${streamId}`)
  return response
}

/**
 * Attaches a leaderboard to a stream
 * @param {string} leaderboardId Id of the leaderboard
 * @param {string} streamId Id of the stream
 * @return {Promise<AxiosResponse>}
 */
export const attachLeaderboardToStream = (
  leaderboardId: string,
  streamId: string
) => {
  return client.post(`/streams/${streamId}/attach-leaderboard`, {
    leaderboardId,
  })
}

/**
 * Detaches a leaderboard from a stream
 * @param {string} leaderboardId Id of the leaderboard
 * @param {string} streamId Id of the stream
 * @return {Promise<AxiosResponse>}
 */
export const detachLeaderboardFromStream = (
  leaderboardId: string,
  streamId: string
) => {
  return client.post(`/streams/${streamId}/detach-leaderboard`, {
    leaderboardId,
  })
}

interface GetStreamParams<T> {
  streamId: string
  options?: UseQueryOptions<T, Error, T, QueryKey>
}

export const useGetStream = <T = { stream: IStream }>({
  streamId,
  options,
}: GetStreamParams<T>) =>
  useFetch({
    url: `/streams/${streamId}`,
    config: options,
  })
