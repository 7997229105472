import React, { FC } from 'react'

import { ISwitchProps } from './types'

import './Switch.css'

const Switch: FC<React.PropsWithChildren<ISwitchProps>> = ({
  isChecked,
  text,
  name,
  id,
  textClassname,
  className = '',
  textPosition = 'left',
  disabled = false,
  onToggle,
  'data-testid': testId = 'switch',
}) => {
  return (
    <label data-testid={testId} className={`switch text-base ${className}`}>
      {text && textPosition === 'left' && (
        <span className={`font-normal ${textClassname}`}>{text}</span>
      )}
      <input
        disabled={disabled}
        id={id}
        name={name}
        onChange={onToggle}
        type="checkbox"
        checked={isChecked}
        className="appearance-none focus:outline-none focus:ring-0 focus:ring-offset-0"
      />
      {text && textPosition === 'right' && (
        <span className={`font-normal ${textClassname}`}>{text}</span>
      )}
    </label>
  )
}

export default Switch
