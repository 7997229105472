import { CreateVoteableAuthorTemplateForm } from '@interfaces/Voteable'
import React, {
  FC,
  SelectHTMLAttributes,
  CSSProperties,
  ChangeEvent,
} from 'react'

interface SelectAuthorInputProps
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  label?: string
  touched?: boolean
  error?: boolean
  errorLabel?: string | undefined
  options: CreateVoteableAuthorTemplateForm[]
  unSelectedContent?: string
  onChange?: (value?: CreateVoteableAuthorTemplateForm) => void
}

const SelectAuthorInput: FC<
  React.PropsWithChildren<SelectAuthorInputProps>
> = ({
  options,
  label,
  error,
  errorLabel,
  name,
  value,
  touched,
  onChange,
  className = '',
  ...rest
}) => {
  const selectStyles = {
    minHeight: '36px',
    borderRadius: '5px',
    paddingX: '10px',
    border: error && touched ? 'solid 2px var(--red)' : '',
  }

  const errorStyles: CSSProperties = {
    color: 'var(--red)',
    fontStyle: 'italic',
    fontSize: '12px',
    marginLeft: '6px',
    minHeight: '18px',
    display: 'block',
  }

  const handleAuthorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!onChange) return

    const selectedAuthorValue = e.currentTarget.value

    // If selectedAuthorValue is empty string we return early
    if (!selectedAuthorValue) {
      return onChange({
        key: selectedAuthorValue,
        value: selectedAuthorValue,
      })
    }

    const opt = options.find((option) => option?.key == selectedAuthorValue)
    onChange(opt)
  }

  return (
    <div className={`form-group ${error && 'has-error'}`}>
      <div>
        {label && (
          <label className="form-check-label pb-1" htmlFor={name}>
            {label}
          </label>
        )}
        <div>
          <select
            className={`form-control ${className}`}
            value={value}
            onChange={handleAuthorChange}
            style={selectStyles}
            {...rest}
          >
            <option key="" value="">
              Choose a template
            </option>
            {/* TODO: I think we should move away from the native select and use a primitives solution like headless/radix */}
            <option disabled>______</option>
            {options?.map((o) => {
              return (
                <option key={o.key} value={o.key}>
                  {o.value}
                </option>
              )
            })}
          </select>
          {error && touched && <span style={errorStyles}>{errorLabel}</span>}
        </div>
      </div>
    </div>
  )
}

export default SelectAuthorInput
