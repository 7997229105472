import React, {
  FC,
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import { Author } from '@interfaces/Author'
import { ReactComponent as CurvedArrowSVG } from '@assets/curved-arrow.svg'
import { ReactComponent as IconPlusSVG } from '@assets/icon-plus.svg'
import { tr, BuffType } from '@constants/other'
import { WelcomeBuffContext } from '@services/providers/WelcomeBuffProvider'
import { TemplateItemWelcomeBuff } from '@components/molecules/TemplateItem'
import WelcomeBuffForm from '@components/organisms/WelcomeBuffForm'
import { useToast } from '@utils/hooks/useToast'
import { LanguageContext } from '@services/providers/LanguageProvider'
import { getAuthors } from '@services/requests/author'
import Button from '@components/atoms/Button'

interface WelcomeBuffSection {
  displayWelcomeBuffForm: boolean
  setDisplayWelcomeBuffForm: Dispatch<SetStateAction<boolean>>
}

const WelcomeBuffSection: FC<React.PropsWithChildren<WelcomeBuffSection>> = ({
  displayWelcomeBuffForm,
  setDisplayWelcomeBuffForm,
}) => {
  const [authors, setAuthors] = useState<Author[]>([])
  const { clientLanguages } = useContext(LanguageContext)

  const { addErrorToast } = useToast()
  const {
    setSelectedWelcomeBuff,
    refetchWelcomeBuffs,
    setIsEditMode,
    welcomeBuffs,
    isEditMode,
  } = useContext(WelcomeBuffContext)

  useEffect(() => {
    const getAllAuthors = async () => {
      try {
        const { authors } = await getAuthors(30, '')
        setAuthors(authors ?? [])
      } catch (error) {
        addErrorToast(error)
      }
    }

    getAllAuthors()
  }, [addErrorToast])

  const handleCreateWelcomeBuff = () => {
    setSelectedWelcomeBuff(null)
    setDisplayWelcomeBuffForm(true)
    setIsEditMode(false)
  }

  const listContainer = welcomeBuffs?.length
    ? 'flex flex-col'
    : 'flex flex-col w-80 text-center relative items-center'

  const container = welcomeBuffs?.length
    ? ''
    : 'flex flex-col flex-1 items-center justify-center'

  if (displayWelcomeBuffForm && authors) {
    return (
      <WelcomeBuffForm
        authors={authors}
        availableLanguages={clientLanguages.enabled}
        onBackClick={() => {
          setDisplayWelcomeBuffForm(false)
          setIsEditMode(false)
          setSelectedWelcomeBuff(null)
        }}
      />
    )
  }

  return (
    <div>
      <div className="flex items-center mt-4 mb-3">
        <Button
          variant="neutral"
          size="no-size"
          onClick={handleCreateWelcomeBuff}
          startElement={
            <div className="flex p-1.5 mr-2 bg-primary rounded text-white">
              <IconPlusSVG className="w-6 h-6" />
            </div>
          }
        >
          {tr({ id: 'stream.management.addWelcomeBuff' })}
        </Button>
      </div>
      <div className="flex flex-col min-h-[254px] p-4 bg-[#f1f4f8] mt-6 relative rounded-lg">
        <div className={container}>
          <div className={listContainer}>
            {!welcomeBuffs?.length && (
              <>
                {tr({ id: 'stream.management.welcomeBuffLoadingText' })}
                <div
                  className="absolute -top-6 -left-2 -translate-x-full"
                  style={{ transform: 'translateX(-100%) rotate(40deg)' }}
                >
                  <CurvedArrowSVG />
                </div>
              </>
            )}

            {!!welcomeBuffs?.length &&
              welcomeBuffs?.map((templateObj, index) => {
                const template = templateObj

                return (
                  <TemplateItemWelcomeBuff
                    key={`template-${templateObj.welcomeBuffId}-${index}`}
                    id={templateObj.welcomeBuffId}
                    question={template.question?.localisations?.[0]?.text}
                    type={BuffType.WelcomeBuff}
                    name={template.name}
                    answers={template.answers}
                    languages={template.localisations}
                    onClick={() => {
                      setSelectedWelcomeBuff(template)
                    }}
                    onDoubleClick={() => {
                      setSelectedWelcomeBuff(template)
                      setDisplayWelcomeBuffForm(true)
                      setIsEditMode(true)
                    }}
                  />
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeBuffSection
