export enum Role {
  AccountOwner = 'account-owner',
  Admin = 'admin-user',
  ContentUser = 'content-user',
}

export interface TeamMember {
  id: string
  firstName?: string
  lastName?: string
  email: string
  expiryDate: Date
  roles: Role[]
}
