import React, { FC, ChangeEvent } from 'react'
import { get, cloneDeep, set } from 'lodash'
import { BuffType, tr } from '@constants/other'
import ImageFieldButton from '@components/atoms/ImageFieldButton'
import TextInput from '@components/atoms/TextInput'
import ColorPickerInput from '@components/organisms/ColorPickerInput'
import { ColorPickerType } from '@components/molecules/ColorPicker'
import { ReactComponent as CopySVG } from '@assets/copy.svg'
import { ReactComponent as ReorderSVG } from '@assets/reorder.svg'
import { BuffFormAnswerProps } from './types'

const BuffFormAnswer: FC<React.PropsWithChildren<BuffFormAnswerProps>> = ({
  name,
  answer,
  lang,
  dir,
  type,
  showBits,
  displayPoints,
  onChange,
  errors,
  touched,
  onRemove,
  onAddPhoto,
  onImageRemove,
  onBlur,
  onAllColourChange,
  onCopyPoint,
}) => {
  const titleKey = `localisations.${lang}.text`
  const pointsKey = 'points'
  const bgColorKey = `localisations.${lang}.backgroundColor`
  const textColorKey = `localisations.${lang}.textColor`
  const bitsKey = `paymentOptions.0.cost`

  const image = get(answer, `localisations.${lang}.imageUrl`)
  const title = get(answer, titleKey)
  const bgColor = get(answer, bgColorKey) ?? ''
  const textColor = get(answer, textColorKey) ?? ''
  const bits = get(answer, bitsKey)
  const titleError = get(errors, titleKey)
  const pointsError = get(errors, pointsKey)
  const bitsError = get(errors, bitsKey)
  const titleTouched = get(touched, titleKey)
  const pointsTouched = get(touched, pointsKey)
  const bitsTouched = get(touched, bitsKey)

  const showPointsInput =
    displayPoints && type !== BuffType.Poll && type !== BuffType.EmojiSlider
  const showCorrectCheckbox = type === BuffType.Quiz
  const showColor = type !== BuffType.EmojiSlider

  const buttonStyle = {
    border: 0,
    background: 'transparent',
    outline: 'none',
  }

  const deleteIconStyle = {
    width: '24px',
    height: '24px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    display: 'inline-block',
    backgroundImage: 'url(/assets/icon-close.png)',
  }

  const handleBackgroundColorChange = (color: string) => {
    const newAnswer = cloneDeep(answer)
    set(newAnswer, bgColorKey, color)
    onChange && onChange(newAnswer)
  }

  const handleTextColorChange = (color: string) => {
    const newAnswer = cloneDeep(answer)
    set(newAnswer, textColorKey, color)
    onChange && onChange(newAnswer)
  }

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newAnswer = cloneDeep(answer)
    set(newAnswer, titleKey, e.currentTarget.value)
    onChange && onChange(newAnswer)
  }

  const handleBitsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newAnswer = cloneDeep(answer)
    set(newAnswer, bitsKey, parseInt(e.currentTarget.value, 10))
    onChange && onChange(newAnswer)
  }

  const handleChangeAllColours = (type: ColorPickerType) => {
    return (color: string) => {
      onAllColourChange && onAllColourChange(type, color)
    }
  }

  return (
    <div
      data-testid="buff-answer"
      className="flex items-center clone-component-item my-2 pr-3"
    >
      <div className="p-3">
        <button
          type="button"
          data-testid={`${name}-delete`}
          className="flex items-center"
          style={buttonStyle}
          onClick={() => onRemove && onRemove(name)}
        >
          <i style={deleteIconStyle} />
        </button>
      </div>
      <div className="w-full bg-white p-3 flex justify-start items-center rounded w-full relative group">
        <div className="buff-answer-handle cursor-pointer absolute left-0 hidden group-hover:!flex">
          <ReorderSVG className="w-5 h-8 text-[#959DA5]" />
        </div>
        <div className="flex items-center mr-3">
          <div>
            <ImageFieldButton
              className="mr-3 ml-2"
              altText={tr({ id: 'buff.answerImg' })}
              image={image}
              onClick={onAddPhoto}
              onImageRemove={onImageRemove}
              tooltip={
                <div className="flex justify-between items-center flex-col w-full py-2">
                  <p className="font-medium mb-0">
                    {tr({
                      id: 'generic.minImageSize',
                    })}
                  </p>
                  <p className="mb-0 text-sm text-[#F3F4F5]">
                    {tr(
                      {
                        id: 'generic.ratio',
                      },
                      { x: 29, y: 21 }
                    )}
                  </p>
                  <p className="mb-0 text-sm text-[#F3F4F5]">522 x 378 px</p>
                </div>
              }
            />
          </div>
          <div className="mt-2" style={{ width: '258px' }}>
            <TextInput
              id={`${name}${titleKey}`}
              label={tr({ id: 'buff.buffAnswer' })}
              placeholder={tr({ id: 'buff.answerInput' })}
              value={title}
              onChange={handleTitleChange}
              onBlur={onBlur}
              error={Boolean(titleError)}
              errorLabel={titleError}
              touched={titleTouched}
              data-testid="buff-answer__title"
              dir={dir}
            />
          </div>
        </div>
        {showColor && (
          <>
            <div className="mr-3 flex" style={{ marginTop: '27px' }}>
              <ColorPickerInput
                color={textColor}
                type={ColorPickerType.TEXT}
                onChange={handleTextColorChange}
                onChangeAll={handleChangeAllColours(ColorPickerType.TEXT)}
              />
            </div>
            <div className="mr-3 flex" style={{ marginTop: '27px' }}>
              <ColorPickerInput
                color={bgColor}
                className="mr-1"
                type={ColorPickerType.BACKGROUND}
                onChange={handleBackgroundColorChange}
                onChangeAll={handleChangeAllColours(ColorPickerType.BACKGROUND)}
              />
            </div>
          </>
        )}

        {showPointsInput && (
          <div className="mt-2 w-[120px] flex flex-row items-center group">
            <TextInput
              id={`${name}.points`}
              label={tr({ id: 'buff.points' })}
              type={'number'}
              min={0}
              value={answer.points}
              error={Boolean(pointsError)}
              errorLabel={pointsError}
              touched={pointsTouched}
              data-testid="buff-answer__points"
              onChange={(e) => {
                onChange &&
                  onChange({
                    ...answer,
                    points: parseInt(e.currentTarget.value, 10),
                  })
              }}
              onBlur={onBlur}
            />
            <button
              type="button"
              className="bg-transparent ml-1 border-none text-[#212121] mt-3 invisible group-hover:!visible"
              onClick={() =>
                onCopyPoint &&
                answer.points != null &&
                onCopyPoint(answer.points)
              }
            >
              <CopySVG className="w-5 h-4" />
            </button>
          </div>
        )}
        {showBits && (
          <div className="mt-2 ml-2" style={{ width: '100px' }}>
            <TextInput
              id={`${name}.bits`}
              label={tr({ id: 'buff.twitchBits' })}
              type={'number'}
              min={0}
              value={bits}
              error={Boolean(bitsError)}
              errorLabel={bitsError}
              touched={bitsTouched}
              data-testid="buff-answer__bits"
              onChange={handleBitsChange}
              onBlur={onBlur}
              customInputStyle={{
                backgroundImage: `url("/assets/svg/buff-bits.svg")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '15px 15px',
                backgroundPosition: 'right center',
              }}
            />
          </div>
        )}
        {showCorrectCheckbox && (
          <div>
            <div className="flex items-center ml-5 buff-answer-checkbox">
              <TextInput
                id={`${name}.correct`}
                type={'checkbox'}
                style={{ marginTop: '2px' }}
                className={'form-check-input light-mode'}
                checked={answer.correct}
                data-testid="buff-answer__is-correct"
                onBlur={onBlur}
                onChange={(e) => {
                  onChange &&
                    onChange({
                      ...answer,
                      correct: e.currentTarget.checked,
                    })
                }}
              />
              <label
                className="font-normal mt-1 ml-3 mb-0 mr-0"
                htmlFor="correct"
              >
                {tr({ id: 'buff.correct' })}
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default BuffFormAnswer
