import React, { FC, useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import { IStream } from '@interfaces/IStream'
import { tr } from '@constants/other'
import ReplayBuffForm from '@components/organisms/ReplayBuffForm'
import RightAside from '@components/organisms/StreamReplayAside'
import { getStreamById } from '@services/requests/stream'
import { IRouteParams } from '@services/providers/BuffProvider'
import { useToast } from '@utils/hooks/useToast'
import { VodContext } from '@services/providers/VodProvider'
import { useGetVods, useGetVodVoteables } from '@services/requests/vod'
import Breadcrumb from '@components/atoms/Breadcrumb'
import BreadcrumbItem from '@components/atoms/Breadcrumb/BreadcrumbItem'
import Container from '@components/atoms/Container'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'

export enum BuffPageActiveTab {
  BuffView = 'BUFF_VIEW',
  TemplateListView = 'TEMPLATE_LIST_VIEW',
  PinnedListView = 'PINNED_LIST_VIEW',
}

const StreamReplayPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { selectedVodVoteable, setSelectedVodVoteable, setCurrentVodId } =
    useContext(VodContext)
  const [stream, setStream] = useState<IStream>()
  const [activeLeftTab, setActiveLeftTab] = useState<string>(
    BuffPageActiveTab.BuffView
  )
  const { addErrorToast } = useToast()
  const { id: streamId = '', gameId = '' } = useParams<IRouteParams>()

  const { data: vods } = useGetVods({
    streamId,
    options: { refetchOnWindowFocus: false },
  })

  const vodId = vods?.vods?.[0]?.id

  const {
    data: voteables,
    refetch,
    isLoading,
  } = useGetVodVoteables({
    vodId: vodId ?? '', // we can pass an empty string since it's not going to be used to fetch because enabled is false
    options: {
      enabled: Boolean(vodId),
      refetchOnWindowFocus: false,
    },
  })

  const handleFormSubmit = async () => {
    try {
      const response = await refetch()
      const newVoteable = response?.data?.voteables?.find(
        (v) => v.id === selectedVodVoteable?.id
      )
      if (!newVoteable) return
      setSelectedVodVoteable({ ...newVoteable })
    } catch (err) {}
  }

  useEffect(() => {
    setCurrentVodId(vodId ?? null)
  }, [vodId, setCurrentVodId])

  useEffect(() => {
    const getStream = async (id: string) => {
      if (!id) return

      try {
        const stream = await getStreamById(id)
        setStream(stream)
      } catch (error) {
        addErrorToast(error)
      }
    }

    getStream(streamId)
  }, [addErrorToast, streamId])

  const buffSectionStyle = {
    boxShadow: '0 5px 15px 0 rgba(9, 14, 37, 0.1)',
  }

  return (
    <>
      <Container>
        <Row className="pl-2">
          <Col sm={8}>
            <div className="flex flex-col">
              <Breadcrumb>
                <BreadcrumbItem href={`/streams/${streamId}/${gameId}`}>
                  {tr({ id: 'stream.stream' })} - {stream?.title}
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  {tr({ id: 'stream.hasNowEnded' })}
                </BreadcrumbItem>
              </Breadcrumb>
              <div className="flex justify-between items-center mb-3">
                <h2 className="h3 font-bold mb-0">
                  {tr({ id: 'generic.sendingBuffs' })} {stream?.title}
                </h2>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pl-2">
          <Col sm={8}>
            {selectedVodVoteable && (
              <div
                style={buffSectionStyle}
                className="mt-1 mb-2 px-4 py-3 bg-white"
              >
                <Tabs
                  selectedIndex={0}
                  tabs={[
                    {
                      text: tr({ id: 'vod.adjustTimings' }),
                    },
                  ]}
                >
                  <TabPanel>
                    <ReplayBuffForm
                      buff={selectedVodVoteable}
                      onUpdate={handleFormSubmit}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            )}
          </Col>
          <Col sm={4}>
            <div style={buffSectionStyle} className="mt-1 p-3 bg-white">
              {/* Aside section */}
              <RightAside
                isLoading={isLoading}
                voteables={voteables}
                mainActiveTab={activeLeftTab}
                setMainActiveTab={setActiveLeftTab}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default StreamReplayPage
