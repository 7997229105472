import React, { useState, ChangeEvent } from 'react'
import { tr } from '@constants/other'
import { AnnouncementTemplate, Template } from '@interfaces/Template'
import TextInput from '@components/atoms/TextInput'
import Button from '@components/atoms/Button'
import { useDebounceValue } from '@utils/hooks/useDebounceValue'
import { useInfiniteScroll } from '@utils/hooks/useInfiniteScroll'
import TemplateList from '@components/organisms/TemplateList'
import { TemplateTypeTabs } from '@components/organisms/TemplateList/types'
import { TemplateSectionProps } from './types'

const TemplateSection = <T,>({
  onClick,
  onDoubleClick,
  query,
  selected,
  templateType,
  showSearchAll,
  searchAll: searchAllProp = false,
  streamFilter = '',
  searchPlaceholder = tr({ id: 'generic.search' }),
}: TemplateSectionProps<T>) => {
  const [search, setSearch] = useState('')
  const [searchAll, setSearchAll] = useState(false)

  const handleDoubleClick = (template: Template | AnnouncementTemplate) => {
    onDoubleClick(template)
  }

  const handleClick = (template: Template | AnnouncementTemplate) => {
    onClick(template)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const parsedSearch = search.length < 3 ? '' : search

  const debouncedSearch = useDebounceValue(parsedSearch)

  const filterQuery =
    searchAllProp || (searchAll && !!debouncedSearch)
      ? ''
      : `stream_id:${encodeURIComponent(streamFilter)}`

  const { data, fetchNextPage, hasNextPage, isLoading } = query(
    30,
    debouncedSearch,
    filterQuery
  )

  const { triggerRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: fetchNextPage,
  })

  const templates =
    data && data?.pages
      ? data.pages.flatMap((page: any) => {
          return page.templates
        })
      : []

  const panelClasses =
    'max-h-[450px] overflow-y-auto default-scroll p-1 pr-3 mt-4'

  return (
    <div data-testid="template-list-wrapper">
      <div className="flex flex-row items-center w-full mb-4 mt-3 border-y-1 border-x-0 border-solid border-[#E8EAF7] text-black -mx-4 px-3 py-2">
        <TextInput
          key={`search`}
          placeholder={searchPlaceholder}
          value={search}
          onChange={handleChange}
          inputContainerClasses="w-[280px] !min-h-0"
          customInputStyle={{
            backgroundImage: `url("/assets/svg/search.svg")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '20px 20px',
            backgroundPosition: '1.5% center',
            paddingLeft: 30,
          }}
        />

        <div className="flex flex-row ml-3">
          {showSearchAll && (
            <div className="inline-flex items-center">
              <Button
                onClick={() => setSearchAll((value) => !value)}
                variant="neutral"
              >
                <div className="flex items-center">
                  <span className="inline-flex items-center h-6 w-6 border-none p-0 mr-2">
                    {searchAll ? (
                      <img
                        src="/assets/svg/icon-check.svg"
                        alt="Sponsor Buffs checkbox checked"
                      />
                    ) : (
                      <div className="h-6 w-6 border rounded bg-white" />
                    )}
                  </span>
                  <span className="text-[#7D7D7D]">
                    {tr({
                      id:
                        templateType === TemplateTypeTabs.Buffs ||
                        templateType === TemplateTypeTabs.GlobalBuffs
                          ? 'template.searchAllBuffs'
                          : 'template.searchAllAnnouncements',
                    })}
                  </span>
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className={panelClasses}>
        <TemplateList
          templates={templates}
          selected={selected}
          type={templateType}
          onClick={handleClick}
          onDoubleClick={handleDoubleClick}
        />

        <div className="h-1" ref={triggerRef} />
      </div>
    </div>
  )
}

export default TemplateSection
