import React, { FC } from 'react'

import Image from '@components/atoms/Image'

import { ReactComponent as ProfilePlaceholder } from '@assets/profile-placeholder.svg'
import { tr } from '@constants/other'
import Pill from '@components/atoms/Pill'
import { getStreamStatusPillColor } from '@utils/stream'
import { StreamStatus } from '@interfaces/IStream'
import { VodStatus } from '@interfaces/VodV2'

interface SidebarItemProps {
  displayName: string
  profilePicture?: string
  status?: StreamStatus | VodStatus
  isActive: boolean
}

const SidebarItem: FC<React.PropsWithChildren<SidebarItemProps>> = ({
  displayName,
  profilePicture,
  status,
  isActive,
}) => {
  const bgColor = isActive ? 'bg-primary' : 'transparent'
  const textColor = isActive ? 'text-white' : 'text-black'
  const pillColor = getStreamStatusPillColor(status)

  return (
    <div
      data-testid="wrapper"
      className={`flex px-2 py-1 items-center rounded author-item ${bgColor} ${textColor}  ${
        isActive && 'active'
      }`}
    >
      <div className="flex items-center justify-between w-full rounded">
        {profilePicture && (
          <Image
            data-testid="author-item__image"
            className="rounded mr-3 w-[36px] h-[36px]"
            src={profilePicture}
            fallbackComponent={
              <ProfilePlaceholder className="rounded mr-3 w-[36px] h-[36px]" />
            }
            alt={tr({ id: 'generic.image' })}
          />
        )}
        <div
          className="text-left w-[216px] overflow-hidden text-ellipsis whitespace-nowrap"
          title={displayName.length > 24 ? displayName : undefined}
          data-testid="desc"
        >
          {displayName}
        </div>
        {status && (
          <div className="right">
            <Pill text={status ?? ''} color={pillColor} />
          </div>
        )}
      </div>
    </div>
  )
}

export default SidebarItem
