import React, { FC } from 'react'
import { ReactComponent as TrashSVG } from '@assets/trash.svg'
import { LanguageTabProps } from './types'

const LanguageTab: FC<React.PropsWithChildren<LanguageTabProps>> = ({
  lang,
  isSelected,
  hasError,
  onClick,
  onDeleteClick,
  'data-testid': testId = 'language-tab',
}) => {
  const colorStyle = isSelected
    ? 'bg-[#007bff] text-white'
    : 'bg-blue-100 text-[#767676]'

  const displayDelete = isSelected && onDeleteClick

  const buttonPadding = displayDelete ? 'px-2 py-1' : 'px-3.5 py-1'

  return (
    <li className="relative" data-testid={testId}>
      <div className={`${buttonPadding} ${colorStyle} rounded-t-lg flex`}>
        <button
          data-testid={`${testId}__button`}
          onClick={() => onClick && onClick(lang.iso6391)}
          className={`${colorStyle} bg-transparent cursor-pointer flex items-center  relative border-none`}
          type="button"
        >
          <span className="select-none">{lang.name}</span>
        </button>

        {displayDelete && (
          <button
            data-testid={`${testId}__delete-button`}
            className="border-none p-0 bg-transparent flex items-center"
            type="button"
            onClick={() => onDeleteClick && onDeleteClick(lang)}
          >
            <TrashSVG className="bg-transparent text-white w-3 h-3" />
          </button>
        )}

        {hasError && (
          <span
            className="w-[8px] h-[8px] ml-1 rounded-full bg-red"
            data-testid={`${testId}__error`}
          />
        )}
      </div>
    </li>
  )
}

export default LanguageTab
