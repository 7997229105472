import React, { FC } from 'react'

interface ILeaderboardSkeletonProps {
  /*
   * Array of string percentages that will set the number of loading placeholders and their width
   */
  rowSizes: string[]

  /*
   * Text that will be shown at the top of the leaderboard skeleton
   */
  message: string

  displayInModal?: boolean
}

const LeaderboardSkeleton: FC<
  React.PropsWithChildren<ILeaderboardSkeletonProps>
> = ({ rowSizes, message }) => {
  return (
    <div className={`min-w-full mt-4`}>
      <div
        className="flex justify-center items-center text-center bg-[#f1f4f8] h-[200px] mb-7"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <div className="space-y-4 mb-7">
        {rowSizes.map((rowSize, i) => (
          <div
            key={`skeleton-${i}`}
            className="flex items-center justify-between animate-pulse"
          >
            <div className="h-9 bg-[#f1f4f8]" style={{ width: rowSize }} />
            <div className="h-5 w-28 ml-5 bg-[#f1f4f8]" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default LeaderboardSkeleton
