/**
 * A helper that checks to see if the image type of a file matches image/*
 * @param {File} file
 * @param {string} accept
 * @return {boolean}
 */
export const isFileTypeSupported = (file: File, accept?: string): boolean => {
  if (!accept) return false
  const acceptList = accept?.split(',').map((item) => item.trim())
  const fileType: string = file.type

  return isMimeTypeAllowed(fileType, acceptList)
}

const isMimeTypeAllowed = (
  fileType: string,
  allowedMimeTypes: string[]
): boolean => {
  // Check for wildcard mime type or specific mime type
  if (allowedMimeTypes.includes(fileType) || allowedMimeTypes.includes('*/*')) {
    return true
  }

  // Check for wildcard file extension
  const wildcardAccept = allowedMimeTypes.find((mimeType) =>
    mimeType.includes('*')
  )
  if (!!wildcardAccept) {
    const wildcardType = wildcardAccept.split('/')[0]
    const fileExtensionType = getFileTypeFromMimeType(fileType)
    if (
      wildcardType &&
      fileExtensionType &&
      wildcardType === fileExtensionType
    ) {
      return true
    }
  }

  return false
}

const getFileTypeFromMimeType = (mimeType: string): string | null => {
  const parts: string[] = mimeType.split('/')
  return parts[0]
}

export const AcceptImageTypeExceptGift =
  'image/jpeg, image/png, image/svg+xml, image/webp'

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/**
 * A helper that trims the extension off an file name/*
 * @param {string} name
 * @return {string}
 */
export const trimFileNameExtension = (name: string): string => {
  if (!name) return ''
  return name.replace(/\.[^/.]+$/, '')
}
