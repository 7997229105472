import { BuffType } from '@constants/other'
import {
  LocalisedContent,
  LocalisedVoteableContent,
  LocalisedFormVoteableContent,
  LocalisedSponsorContent,
} from '@interfaces/localisation'
import { Author } from './Author'
import { LanguageCode } from './ILanguage'

export enum VoteableLifecycle {
  VOTEABLE_LIFECYCLE_POLL = 'VOTEABLE_LIFECYCLE_POLL',
  VOTEABLE_LIFECYCLE_QUIZ = 'VOTEABLE_LIFECYCLE_QUIZ',
  VOTEABLE_LIFECYCLE_PREDICTION = 'VOTEABLE_LIFECYCLE_PREDICTION',
  VOTEABLE_LIFECYCLE_POPULAR_VOTE = 'VOTEABLE_LIFECYCLE_POPULAR_VOTE',
}

export enum VoteableLayout {
  LAYOUT_UNSPECIFIED = 'LAYOUT_UNSPECIFIED',
  LAYOUT_VERTICAL = 'VOTEABLE_LAYOUT_VERTICAL',
  LAYOUT_HORIZONTAL = 'VOTEABLE_LAYOUT_HORIZONTAL',
}

export enum VoteableAnswerInterface {
  ANSWER_INTERFACE_UNSPECIFIED = 'ANSWER_INTERFACE_UNSPECIFIED',
  ANSWER_INTERFACE_PICK_ONE = 'ANSWER_INTERFACE_PICK_ONE',
  ANSWER_INTERFACE_DISCRETE_SLIDER = 'ANSWER_INTERFACE_DISCRETE_SLIDER',
  ANSWER_INTERFACE_STAR_SELECT = 'ANSWER_INTERFACE_STAR_SELECT',
}

export enum VoteableTransactionType {
  TRANSACTION_TYPE_UNSPECIFIED = 'TRANSACTION_TYPE_UNSPECIFIED',
  TRANSACTION_TYPE_TWITCH_BITS = 'TRANSACTION_TYPE_TWITCH_BITS',
}

export interface VoteableAnswerPaymentOption {
  transactionType: VoteableTransactionType

  /**
   * Docs suggest this is a string: TODO: check
   */
  cost: number
}

export interface CreateVoteableAnswer {
  id?: string
  localisations: LocalisedVoteableContent[]
  paymentOptions?: VoteableAnswerPaymentOption[]

  // Docs suggest this is a string. TODO: Check
  points?: number

  /**
   * Boolean indicating if the answer is correct
   */
  correct?: boolean
}

export interface CreateVoteableAuthorTemplateForm {
  key: string
  value: string
  data?: Author
}
export interface CreateVoteableAuthorForm {
  imageUrl: string
  text: string
}

export interface CreateVoteableAnswerForm {
  id: string
  localisations: Partial<Record<LanguageCode, LocalisedFormVoteableContent>>
  paymentOptions?: VoteableAnswerPaymentOption[]

  points?: number

  /**
   * Boolean indicating if the answer is correct
   */
  correct?: boolean
}

export type VoteableAnswer = CreateVoteableAnswer & {
  id: string
}

export interface CreateVoteable<T = CreateVoteableAnswer> {
  localisations: LanguageCode[]
  /**
   * Lifecycle of the Voteable
   */
  lifecycle?: VoteableLifecycle

  /**
   * date timestamp of when the Voteable should be shown
   */
  opensAt?: string

  /**
   * date timestamp when Voteable should be hidden
   */
  closesAt?: string

  /**
   * date timestamp when Voteable should be resolved
   */
  resolvesAt?: string

  /**
   * The display mode the Voteable should display in
   */
  layout: VoteableLayout

  /**
   * The display mode of the answers options
   */
  answerInterface: VoteableAnswerInterface

  /**
   * Author of the Voteable
   */
  author: {
    localisations: LocalisedContent[]
  }
  question: {
    localisations: LocalisedVoteableContent[]
  }
  answers: T[]
  sponsor?: {
    localisations: LocalisedSponsorContent[]
  } | null
  visible?: boolean
  /**
   * Participation points, generated between the game minParticipationPoints and maxParticipationPoints
   * if it is a welcomeBuff then it's defaulted to 1
   */
  participationPoints?: number
  voteDurationSeconds?: number
  summaryDurationSeconds?: number
  resultsDurationSeconds?: number
  openDurationSeconds?: number
}

export interface ICreateWelcomeVoteable extends CreateVoteable {
  name: string
}
export interface VoteableUi {
  lifecycle: VoteableLifecycle
  answerInterface: VoteableAnswerInterface
}

export interface CreateVoteableForm<T = CreateVoteableAnswerForm> {
  /**
   * Type of buff to be transformed to lifecycle, layout and interface
   */
  type: BuffType

  /**
   * Duration that the user is allowed to vote in
   */
  voteDurationSeconds?: number

  /**
   * Duration to display the summary
   */
  summaryDurationSeconds?: number

  /**
   * Duration to display the results
   */
  resultsDurationSeconds?: number

  /**
   * Duration to display for welcome buff
   */
  displayDurationSeconds?: number

  schedule?: string
  expiresIn?: string

  /**
   * The display mode the Voteable should display in
   */
  layout: VoteableLayout

  /**
   * Author of the Voteable
   */
  authorId?: string

  /**
   * Author of the Voteable
   */
  author: Partial<Record<LanguageCode, CreateVoteableAuthorForm>>

  /**
   * Author template
   */
  authorTemplate?: CreateVoteableAuthorTemplateForm

  /**
   * Question of the Voteable
   */
  question: Partial<Record<LanguageCode, string>>

  /**
   * Answers of the Voteable
   */
  answers: T[]

  /**
   * Participation points, generated between the game minParticipationPoints and maxParticipationPoints
   * if it is a welcomeBuff then it's defaulted to 1
   */
  participationPoints?: number

  /**
   * Sponsor data of the Voteable
   */
  sponsor: Partial<Record<LanguageCode, LocalisedSponsorContent>>

  /**
   * Flag to include or not the sponsor
   */
  showSponsor: Partial<Record<LanguageCode, boolean>>

  /**
   * Flag to toggle visibility of twitch bits inputs
   */
  enableTwitchBits?: boolean

  /**
   * Languages of voteable
   */
  localisations?: LanguageCode[]
}

export type Voteable = CreateVoteable<VoteableAnswer> & {
  /**
   * Id of the Voteable
   */
  voteableId: string

  /**
   * Id of the game Voteable is attached to
   */
  gameId: string

  // Docs suggest this is a string. TODO: Check
  participationPoints: number
  visible?: boolean
}

export interface GetVoteablesResponse {
  voteables: Voteable[]
  nextPage: string
}
