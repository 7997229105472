import * as axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import snakecaseKeys from 'snakecase-keys'
import {
  vPaginationSchema,
  vResourceIdSchema,
  DEFAULT_PAG_LIMIT,
  DEFAULT_PAG_OFFSET,
} from '../constants/form'
import { ApiResponse, BUFFUP_REFRESH_TOKEN, HttpMethod } from '@constants/other'
import { BUFFUP_DASHBOARD_TOKEN } from '@constants/other'
import IApiStatus from '@interfaces/IApiStatus'
import IEnvironment from '@interfaces/IEnvironment'
import IResponse from '@interfaces/IResponse'
import IService from '@interfaces/IService'

const defaultTransformers = () => {
  const { transformRequest } = axios.default.defaults
  if (!transformRequest) {
    return []
  } else if (transformRequest instanceof Array) {
    return transformRequest
  } else {
    return [transformRequest]
  }
}

const toSnakecaseTransform = (
  data: Record<string, unknown> | readonly unknown[]
) => {
  return data ? snakecaseKeys(data) : data
}

interface IApiService {
  getRoot: () => Promise<ApiResponse<IApiStatus>>
}

export interface ApiServiceOptions {
  /**
   * Base url of the apis
   */
  baseUrl?: string
}

/**
 * API Service
 */
class ApiService<T> implements IService<T>, IApiService {
  resource: string
  checkToRefreshToken: (token: string | null) => void

  readonly instance: axios.AxiosInstance
  readonly instanceUpload: axios.AxiosInstance
  private readonly defaultContentType = 'application/json; charset=utf-8'

  /**
   * @param {string} resource
   * @param {ApiServiceOptions} options
   */
  constructor(resource: string, options: ApiServiceOptions = {}) {
    this.resource = resource

    let baseURL = options.baseUrl

    if (!baseURL) {
      if ((process.env as Partial<IEnvironment>).REACT_APP_ENV === 'local') {
        baseURL = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`
      } else {
        baseURL = `${process.env.REACT_APP_API_HOST}`
      }
    }

    const instance = axios.default.create({
      baseURL,
      responseType: 'json',
      // Automatic conversion of any request payload keys into snake_case
      transformRequest: [...defaultTransformers()],
      // Automatic conversion of any response payload keys into camelCase
      transformResponse: (data: unknown) => {
        let transformData
        try {
          transformData = JSON.parse(data as string)
        } catch (err) {
          transformData = data
        }
        return transformData
          ? camelcaseKeys(transformData, { deep: true })
          : transformData
      },
    })

    instance.interceptors.request.use(
      (config) => {
        if (config?.headers) {
          config.headers.Authorization = `Bearer ${this.getToken()}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    const instanceUpload = axios.default.create({
      baseURL,
      headers: {
        crossdomain: 'true',
        accept: this.defaultContentType,
        contentType: 'multipart/form-data',
      },
      responseType: 'json',
      transformResponse: (data: unknown) => {
        let transformData
        try {
          transformData = JSON.parse(data as string)
        } catch {
          transformData = data
        }
        return transformData
          ? camelcaseKeys(transformData, { deep: true })
          : transformData
      },
    })

    instanceUpload.interceptors.request.use((config) => {
      if (config?.headers) {
        config.headers.Authorization = `Bearer ${this.getToken()}`
      }

      return config
    })

    this.checkToRefreshToken = (token: string | null) => {
      if (!token) return

      // // Get the token decoded to have access its expiry time
      // const decoded: JwtPayload = jwtDecode(token)
      // // Get time now in unix timestamp
      // const now = Date.now().valueOf() / 1000

      // // TODO: Bring back commented logic once our token will have expiration
      // // if (!decoded?.exp) return console.error('Exp. missing from decoded token')
      // if (!decoded?.exp) return

      // /* Check if the token isn't expired and it's life before expiry is smaller than 20 minutes.
      //           If yes refresh token and update it in local storage */
      // if (decoded.exp > now && decoded.exp < now + 60 * 30) {
      //   instance
      //     .post(`${process.env.REACT_APP_API_HOST}/refresh-token`)
      //     .then((res) => {
      //       localStorage.setItem(BUFFUP_DASHBOARD_TOKEN, res.data.token)
      //     })
      // } else if (decoded.exp < now) {
      //   // TODO: check
      // }
    }
    this.instance = instance
    this.instanceUpload = instanceUpload
  }

  /**
   * Gets the auth token stored in localStorage
   * @return {string | null}
   */
  getToken(): string | null {
    return localStorage.getItem(BUFFUP_DASHBOARD_TOKEN)
  }

  /**
   * @return {void}
   */
  clearCredentials = (): void => {
    localStorage.removeItem(BUFFUP_REFRESH_TOKEN)
    localStorage.removeItem(BUFFUP_DASHBOARD_TOKEN)
  }

  /**
   * @param {number} limit
   * @param {number} offset
   * @return {Promise<ApiResponse<T[]>>}
   */
  async getAll(
    limit: number = DEFAULT_PAG_LIMIT,
    offset: number = DEFAULT_PAG_OFFSET
  ): Promise<ApiResponse<IResponse<T[]>>> {
    await vPaginationSchema.validateAsync({ limit, offset })
    this.checkToRefreshToken(this.getToken())
    const url = encodeURI(`/${this.resource}/?page=${offset}&size=${limit}`)

    // const url = `/${this.resource}`

    return await this.instance.request({
      method: HttpMethod.Get,
      url,
    })
  }

  /**
   * @param {string} id
   * @return {Promise<ApiResponse<IResponse<T>>>}
   */
  async getOne(id: string | number): Promise<ApiResponse<T>> {
    await vResourceIdSchema.validateAsync(id)
    this.checkToRefreshToken(this.getToken())

    return await this.instance.request({
      method: HttpMethod.Get,
      url: `/${this.resource}/${id}`,
    })
  }

  /**
   * @param {Partial<T>} data
   * @return {Promise<ApiResponse<IResponse<T>>>}
   */
  create(data: Partial<T>): Promise<ApiResponse<IResponse<T>>> {
    this.checkToRefreshToken(this.getToken())

    return this.instance.request({
      data,
      method: HttpMethod.Post,
      url: `/${this.resource}`,
    })
  }

  /**
   * @param {string} id
   * @param {Partial<T>} data
   * @return {Promise<ApiResponse<IResponse<T>>>}
   */
  async update(
    id: string | number,
    data: Partial<T>
  ): Promise<ApiResponse<IResponse<T>>> {
    await vResourceIdSchema.validateAsync(id)
    this.checkToRefreshToken(this.getToken())

    return await this.instance.request({
      data,
      method: HttpMethod.Patch,
      url: `/${this.resource}/${id}`,
    })
  }

  /**
   * @param {string} id
   * @return {Promise<ApiResponse<void>>}
   */
  async trash(id: string | number): Promise<ApiResponse<void>> {
    await vResourceIdSchema.validateAsync(id)
    this.checkToRefreshToken(this.getToken())

    return await this.instance.request({
      method: HttpMethod.Delete,
      url: `/${this.resource}/${id}`,
    })
  }

  /**
   * @return {Promise<ApiResponse<IApiStatus>> }
   */
  getRoot(): Promise<ApiResponse<IApiStatus>> {
    this.checkToRefreshToken(this.getToken())

    return this.instance.request({
      method: HttpMethod.Get,
      url: `/`,
    })
  }
}

export default ApiService
