import { AnchorHTMLAttributes, ButtonHTMLAttributes, ReactNode } from 'react'

interface BaseProps {
  type?: 'button' | 'reset' | 'submit'
  href?: string
  children: ReactNode
  'data-testid'?: string
  shape?: 'pill' | 'round' | 'circle' | 'square'
  size?: 'mini' | 'small' | 'medium' | 'large' | 'no-size'
  variant?: 'primary' | 'secondary' | 'danger' | 'neutral' | 'success'
  buttonType?: 'button' | 'link'
  startElement?: ReactNode
  endElement?: ReactNode
  className?: string
  disabled?: boolean
}

export enum ButtonShape {
  Pill = 'pill',
  Round = 'round',
  Circle = 'circle',
  Square = 'square',
}

export enum ButtonSize {
  Mini = 'mini',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  NoSize = 'no-size',
}

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  Success = 'success',
  Neutral = 'neutral',
}

export enum ButtonType {
  Button = 'button',
  Link = 'link',
}

export type ButtonProps = BaseProps & ButtonHTMLAttributes<HTMLButtonElement>

export type LinkButtonProps = BaseProps &
  AnchorHTMLAttributes<HTMLAnchorElement>
