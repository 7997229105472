import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { getErrorFields } from '@utils/form'

const AutoPatchTouched = () => {
  const { errors, setFieldTouched, isSubmitting, isValidating } =
    useFormikContext()

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const fieldsWithErrors = getErrorFields(errors, {}, undefined, true)
      fieldsWithErrors.forEach((field) => {
        setFieldTouched(field, true, false)
      })
    }
  }, [errors, isSubmitting, isValidating, setFieldTouched])
  return null
}

export default AutoPatchTouched
