import React, { FC, useState } from 'react'
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  useReactTable,
} from '@tanstack/react-table'
import { DataTableProps } from './types'

const DataTable: FC<React.PropsWithChildren<DataTableProps>> = ({
  tableData,
  columns,
  initialSorting = [],
  classes,
}) => {
  const [sorting, setSorting] = useState<SortingState>(initialSorting)
  const {
    container: _containerClasses,
    header: _headerClasses,
    row: _rowClasses,
    table: _tableClasses,
  } = classes ?? {}

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <div className={`p-2 ${_containerClasses}`}>
      <table
        className={`auto min-w-full border-separate border-spacing-y-px ${_tableClasses}`}
      >
        <thead
          style={{
            boxShadow: `inset 0 -1px 0 #cecece`,
            borderBottom: '1px solid #D1D5DA',
          }}
          className={`sticky top-0 bg-white ${_headerClasses}`}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`pb-2 text-sm font-bold text-gray-500 last:text-right px-1 ${
                      (header.getContext().column.columnDef.meta as any)
                        ?.headerClassname
                    }`}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' ↑',
                          desc: ' ↓',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className="scrollable-container">
          {table.getRowModel().rows.map((row) => {
            const firstCell = row.getVisibleCells()?.[0]
            const rowClassname = (
              firstCell.getContext().cell.column.columnDef.meta as any
            )?.rowClassname
            return (
              <tr
                className={`hover:bg-[#F2F3F4] ${_rowClasses} ${rowClassname}`}
                key={row.id}
              >
                {row.getVisibleCells().map((cell, index) => {
                  return (
                    <td
                      key={cell.id}
                      className={`text-xs py-2 px-1 ${
                        (cell.getContext().cell.column.columnDef.meta as any)
                          ?.className
                      } `}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  )
}

export default DataTable
