import React, { useState, useEffect } from 'react'
import { DelayedProps } from './types'

const DelayRender = ({ children, waitBeforeShow = 250 }: DelayedProps) => {
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true)
    }, waitBeforeShow)
    return () => clearTimeout(timer)
  }, [waitBeforeShow])

  return isShown ? <>{children}</> : null
}

export default DelayRender
