import React from 'react'
import { useSpecificStreams } from '@utils/hooks/useSpecificSteams'
import { IStream } from '@interfaces/IStream'
import EmptyStateWithIcon from '@components/molecules/EmptyStateWithIcon'
import StreamList from '@components/organisms/StreamList'
import { ReactComponent as SearchIcon } from '@assets/search.svg'
import { LeaderboardStreamsTabProps } from './types'

const LeaderboardStreamSectionSelectedTab = ({
  onChange,
  selectedStreams,
  filter,
  disabled,
}: LeaderboardStreamsTabProps) => {
  const results = useSpecificStreams(selectedStreams)
  const isLoading = results.some((query) => query.isLoading)

  let streams = results
    .map((query) => query.data)
    .filter((stream) => stream !== undefined) as IStream[]

  if (filter) {
    streams = streams.filter(
      (stream) => stream.title.toLowerCase().indexOf(filter.toLowerCase()) > -1
    )
  }

  if (isLoading) {
    return (
      <EmptyStateWithIcon
        text="Loading..."
        icon={<SearchIcon className="w-10" />}
      />
    )
  }

  if (!streams.length && !!selectedStreams.length) {
    return (
      <EmptyStateWithIcon
        text="No stream found"
        icon={<SearchIcon className="w-10" />}
      />
    )
  }

  if (!selectedStreams.length) {
    return (
      <EmptyStateWithIcon
        text="No stream attached"
        icon={<SearchIcon className="w-10" />}
      />
    )
  }

  return (
    <StreamList
      streams={streams}
      onChange={onChange}
      selectedStreamsIds={selectedStreams}
      disabled={disabled}
    />
  )
}

export default LeaderboardStreamSectionSelectedTab
