import { CreateAnnouncement } from '@interfaces/Announcement'
import IService from '@interfaces/IService'
import { ApiResponse, HttpMethod } from '@constants/other'
import ApiService from './ApiService'

export interface IAnnouncementParams {
  gameId: string
  announcement?: CreateAnnouncement
}

export interface IAnnouncementService extends IService<CreateAnnouncement> {
  send: (p: IAnnouncementParams) => Promise<ApiResponse<any>>
}

/**
 * Announcement service
 */
export default class AnnouncementService
  extends ApiService<CreateAnnouncement>
  implements IAnnouncementService, IService<CreateAnnouncement>
{
  /**
   * @param {IVoteableParams} p
   * @return {Promise<any>}
   */
  async send(p: IAnnouncementParams): Promise<any> {
    this.checkToRefreshToken(this.getToken())
    const url = `/games/${p.gameId}/announcements`
    await this.instance.request({
      method: HttpMethod.Post,
      url,
      data: p.announcement,
    })

    return Promise.resolve()
  }
}
