// @ts-nocheck
import { keyBy, transform, isObject } from 'lodash'

// Omits/deletes the provided keys from an object
// Ex: const obj = { a: 1, b: { a: 2, b: 3 } }
// deepOmit(obj, 'a') --> { b: { b: 3 } }
const deepOmit = <T>(obj, keysToOmit): T => {
  const keysToOmitIndex = keyBy(
    Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]
  )

  const omitFromObject = (obj) => {
    return transform(obj, (result, value, key) => {
      if (key in keysToOmitIndex) return
      result[key] = isObject(value) ? omitFromObject(value) : value
    })
  }

  return omitFromObject(obj)
}

export default deepOmit
