import React, { FC } from 'react'
import { tr } from '@constants/other'
import { formatPercentage } from '@utils/leaderboard'
import Image from '@components/atoms/Image'
import UserModerationLock from '@components/molecules/UserModerationLock'
import { ReactComponent as ProfilePlaceholder } from '@assets/profile-placeholder.svg'
import { Standing } from '@interfaces/Leaderboard'

interface ILeaderboardItemProps {
  user: Standing
  /**
   * Handler called when moderation button is clicked
   */
  onModerationClick?: (user: Standing, locked: boolean) => void
}

const LeaderboardItem: FC<React.PropsWithChildren<ILeaderboardItemProps>> = ({
  user,
  onModerationClick,
}) => {
  const { position, points, userId } = user
  const rank =
    position ??
    tr(
      { id: 'leaderboard.percentageText' },
      { percentage: formatPercentage(points ?? 0) }
    )

  return (
    <tr key={userId}>
      {/* Rank */}
      <td className="text-xs font-semibold">{rank}</td>
      {/* Avatar/Name */}
      <td className="flex items-center">
        <div className="inline-flex h-10 w-6 mr-2.5 items-center">
          <Image
            className="h-6 w-6 rounded-full"
            src={user?.profilePicture}
            fallbackComponent={<ProfilePlaceholder />}
            alt="Image of user's avatar"
          />
        </div>

        <span className="text-xs py-1 truncate">{user?.displayName}</span>
      </td>
      <td>
        <span className="text-xs py-1 truncate">{user?.email}</span>
      </td>

      <td>
        <span className="text-xs py-1 truncate">{user?.opaqueID}</span>
      </td>
      {/* Points */}
      <td className="text-xs font-semibold">{points ?? 0}</td>
      <td>
        <UserModerationLock
          onClick={(newLocked) =>
            onModerationClick && onModerationClick(user, newLocked)
          }
          locked={user.moderated}
        />
      </td>
    </tr>
  )
}

export default LeaderboardItem
