import { tr } from '../constants/other'

export const gettingStartedTipsLocalStorageKey = 'getting-started-tips'

export enum Color {
  Default = '#f4f4f4',
  Primary = '#0090fe',
  Success = '#00a65a',
  Info = '#00c0ef',
  Danger = '#dd4b39',
  Warning = '#f39c12',
}

export enum PillColor {
  Gray = 'gray',
  Red = 'red',
  Orange = 'orange',
  DarkGray = 'darkGray',
}

export const closeVotingBulletPoints = [
  tr({ id: 'stream.endThisStreamBulletPoint1' }),
  tr({ id: 'stream.endThisStreamBulletPoint2' }),
  tr({ id: 'stream.endThisStreamBulletPoint3' }),
]
