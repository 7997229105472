import { SortBy, SortDirection } from '@interfaces/Sorting'

/**
 * Builds the correct URL based on specified params, for an initial call,
 * only pageSize is needed, for the follow up calls, only nextParam is needed
 * as pageSize is inherited from the initial call
 * @param {string} path
 * @param {number} pageSize
 * @param {string} nextParam
 * @param {boolean} usePaginationPrefix
 * @param {SortDirection} order
 * @param {SortBy} field
 * @return {string} new URL
 */
export const getPaginatedUrl = (
  path: string,
  pageSize: number,
  nextParam: string,
  usePaginationPrefix: boolean = false,
  order: SortDirection = SortDirection.Desc,
  field?: SortBy
): string => {
  const url = new URL(path, 'http://localhost')

  const searchParams = new URLSearchParams(url.search)

  if (!nextParam.length) {
    searchParams.append('pagination.pageSize', pageSize.toString())
  }
  if (nextParam.length) {
    searchParams.append(
      usePaginationPrefix ? 'pagination.next' : 'next',
      nextParam
    )
  }

  if (field) {
    searchParams.append('pagination.field', field)
    searchParams.append('pagination.order', order)
  }

  const queryString = searchParams.toString()
  if (queryString) {
    return `${url.pathname}?${queryString}`
  }

  return `${url.pathname}`
}

export const getQueryUrl = (path: string, query?: string) => {
  if (!query) return path

  const url = new URL(path, 'http://localhost')

  const searchParams = new URLSearchParams(url.search)

  searchParams.append('query', query)

  const queryString = searchParams.toString()
  if (queryString) {
    return `${url.pathname}?${queryString}`
  }

  return `${url.pathname}`
}

export const getFilterUrl = (path: string, filter?: string) => {
  if (!filter) return path

  const url = new URL(path, 'http://localhost')

  const searchParams = new URLSearchParams(url.search)

  searchParams.append('filter', filter)

  const queryString = searchParams.toString()
  if (queryString) {
    return `${url.pathname}?${queryString}`
  }

  return `${url.pathname}`
}
