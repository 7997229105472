import React, { FC, useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { tr } from '@constants/other'
import Container from '@components/atoms/Container'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import StreamDefaults from '@components/organisms/StreamDefaults'
import WelcomeBuffSection from '@components/organisms/WelcomeBuffSection'
import { WelcomeBuffContext } from '@services/providers/WelcomeBuffProvider'
import { TemplateContext } from '@services/providers/TemplateProvider'
import GlobalTemplatesList from '@pages/StreamManagement/GlobalTemplatesList'
import {
  useDeleteAnnouncementTemplate,
  useDeleteVoteableTemplate,
} from '@services/requests/templates'
import { useToast } from '@utils/hooks/useToast'
import Aside from './Aside'

export enum StreamManagementActiveTab {
  WelcomeBuff = 'WELCOME_BUFF',
  GlobalTemplates = 'GLOBAL_TEMPLATES',
  StreamDefaults = 'STREAM_DEFAULTS',
}

const StreamManagement: FC = () => {
  const navigate = useNavigate()
  const { state, pathname } = useLocation()
  const initialTab = state?.tab || StreamManagementActiveTab.WelcomeBuff
  const { selectedWelcomeBuff, setSelectedWelcomeBuff, setIsEditMode } =
    useContext(WelcomeBuffContext)
  const { resetSelectedTemplates, currentTemplateIdSelected } =
    useContext(TemplateContext)
  const { addErrorToast } = useToast()

  const [displayWelcomeBuffForm, setDisplayWelcomeBuffForm] =
    useState<boolean>(false)
  const [activeLeftTab, setActiveLeftTab] =
    useState<StreamManagementActiveTab>(initialTab)

  const buffSectionStyle = {
    boxShadow: '0 5px 15px 0 rgba(9, 14, 37, 0.1)',
  }

  const { mutateAsync: deleteVoteableMutate } = useDeleteVoteableTemplate(
    currentTemplateIdSelected
  )

  const { mutateAsync: deleteAnnouncementMutate } =
    useDeleteAnnouncementTemplate(currentTemplateIdSelected)

  const showBuffPreview =
    activeLeftTab !== StreamManagementActiveTab.StreamDefaults

  const showEditButton = !displayWelcomeBuffForm && selectedWelcomeBuff
  const asideBtnText = showEditButton
    ? tr({ id: 'stream.management.editWelcomeBuff' })
    : tr({ id: 'stream.management.saveWelcomeBuff' })

  const asideOnClick = showEditButton
    ? () => {
        setDisplayWelcomeBuffForm(true)
        setIsEditMode(true)
      }
    : undefined

  useEffect(() => {
    resetSelectedTemplates()
    setSelectedWelcomeBuff(null)
    setDisplayWelcomeBuffForm(false)
  }, [activeLeftTab, resetSelectedTemplates, setSelectedWelcomeBuff])

  useEffect(() => {
    if (pathname?.includes('global-templates')) {
      setActiveLeftTab(StreamManagementActiveTab.GlobalTemplates)
    }

    if (pathname?.includes('stream-defaults')) {
      setActiveLeftTab(StreamManagementActiveTab.StreamDefaults)
    }

    if (pathname?.includes('welcome-buff')) {
      setActiveLeftTab(StreamManagementActiveTab.WelcomeBuff)
    }

    if (pathname === '/stream-management') {
      setDisplayWelcomeBuffForm(false)
    }
  }, [pathname])

  const handleEditTemplate = (id: string) => {
    if (pathname.includes('buff')) {
      navigate(`/stream-management/global-templates/buff/${id}`)
    }

    if (pathname.includes('announcements')) {
      navigate(`/stream-management/global-templates/announcements/${id}`)
    }
  }

  const handleDeleteTemplate = async () => {
    if (!currentTemplateIdSelected) return

    if (pathname.includes('buff')) {
      try {
        await deleteVoteableMutate()
        resetSelectedTemplates()
      } catch (e) {
        addErrorToast(e)
      }
    }

    if (pathname.includes('announcements')) {
      try {
        await deleteAnnouncementMutate()
        resetSelectedTemplates()
      } catch (e) {
        addErrorToast(e)
      }
    }
  }

  const path = '/stream-management'

  const components = {
    'welcome-buff': {
      title: tr({ id: 'stream.management.welcomeBuff' }),
      component: (
        <>
          <WelcomeBuffSection
            displayWelcomeBuffForm={displayWelcomeBuffForm}
            setDisplayWelcomeBuffForm={setDisplayWelcomeBuffForm}
          />
        </>
      ),
    },
    'global-templates': {
      title: tr({ id: 'stream.management.globalTemplates' }),
      component: (
        <>
          <GlobalTemplatesList />
        </>
      ),
    },
    'stream-defaults': {
      title: tr({ id: 'stream.management.streamDefaults' }),
      component: (
        <>
          <StreamDefaults />
        </>
      ),
    },
  }

  const componentEntries = Object.entries(components)

  const tabs = componentEntries.map(([slug, { title }]) => {
    return {
      text: title,
      href: `${path}/${slug}`,
    }
  })

  const tabIndex = componentEntries.findIndex(([slug]) => {
    return pathname.includes(slug)
  })

  return (
    <>
      <Container>
        <Row className="pl-2">
          <Col sm={8}>
            <div className="flex flex-col my-4">
              <h1 className="h3 font-bold mb-2 mr-2.5">
                {tr({ id: 'stream.management.title' })}
              </h1>
              <span className="text-[1.125rem]">
                {tr({ id: 'stream.management.description' })}
              </span>
            </div>
          </Col>
        </Row>

        <Row className="pl-2">
          <Col sm={8}>
            <div
              style={buffSectionStyle}
              className="mt-1 mb-2 px-4 py-3 bg-white"
            >
              <Routes>
                <Route
                  path={`/`}
                  element={<Navigate to={`${path}/welcome-buff`} />}
                />
                <Route
                  path={`/global-templates`}
                  element={
                    <Navigate to={`/stream-management/global-templates/buff`} />
                  }
                />
                <Route
                  path={`/*`}
                  element={
                    <div>
                      <Tabs tabs={tabs} selectedIndex={tabIndex}>
                        {componentEntries.map(([slug, { component }]) => {
                          return (
                            <TabPanel key={slug} className="!mt-4 !py-2">
                              {component}
                            </TabPanel>
                          )
                        })}
                      </Tabs>
                    </div>
                  }
                />
              </Routes>
            </div>
          </Col>
          {showBuffPreview && (
            <Col sm={4}>
              <div>
                <Aside
                  activeTab={activeLeftTab}
                  displayWelcomeBuffForm={displayWelcomeBuffForm}
                  deleteCallback={() => {
                    setSelectedWelcomeBuff(null)
                    setDisplayWelcomeBuffForm(false)
                  }}
                  onEditTemplate={handleEditTemplate}
                  onDeleteTemplate={handleDeleteTemplate}
                  submitCallback={() => setDisplayWelcomeBuffForm(false)}
                  onClick={asideOnClick}
                  btnText={asideBtnText}
                />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}

export default StreamManagement
