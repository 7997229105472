import { object, number, string } from 'yup'
import { tr } from '@constants/other'
import { isBefore, isToday, parseISO } from 'date-fns'

const schema = object().shape({
  cost: number()
    .typeError(tr({ id: 'validation.required' }))
    .required(tr({ id: 'validation.required' }))
    .min(1, tr({ id: 'validation.min1Bits' })),
  currency: string().required(tr({ id: 'validation.required' })),
  expiryTime: string().required(tr({ id: 'validation.required' })),
  expiryDate: string()
    .test(
      'is-before',
      tr({ id: 'validation.leaderboardDateTime' }),
      (value, context) => {
        const expiryTime = context?.parent?.expiryTime
        const today = new Date()
        if (!value) return false
        const date = parseISO(value)
        if (!expiryTime) {
          return isToday(date) || !isBefore(date, today)
        }
        const newDate = new Date()
        const [hours, minutes] = expiryTime.split(':')
        const [year, month, day] = value.split('-')

        newDate.setHours(Number(hours))
        newDate.setMinutes(Number(minutes))
        newDate.setFullYear(Number(year))
        newDate.setMonth(Number(month) - 1)
        newDate.setDate(Number(day))

        return newDate.getTime() > today.getTime()
      }
    )
    .required(tr({ id: 'validation.required' })),
})

export default schema
