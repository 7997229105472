import React, {
  FC,
  MouseEvent,
  ChangeEvent,
  useRef,
  MutableRefObject,
} from 'react'
import { PatternFormat } from 'react-number-format-new'
import TextInput from '@components/atoms/TextInput'
import { tr } from '@constants/other'
import {
  convertPatternInputToSeconds,
  convertSecondsToPatternInput,
  MAX_VALUE_S,
} from '@utils/time/input'
import Button from '@components/atoms/Button'
import { IFormattedTimeInput } from './types'

const FormattedTimeInput: FC<React.PropsWithChildren<IFormattedTimeInput>> = ({
  id,
  value = 0,
  pattern = '#:##:##:##',
  onReset,
  onChange,
  showButtons = false,
  'data-testid': testId = 'formatted-time-input',
  ...rest
}) => {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>

  const buttons = [
    {
      value: 1,
      isDisabled: value ? MAX_VALUE_S - 1 < value : false,
    },
    {
      value: 5,
      isDisabled: value ? MAX_VALUE_S - 5 < value : false,
    },
    {
      value: 10,
      isDisabled: value ? MAX_VALUE_S - 10 < value : false,
    },
    {
      value: 30,
      isDisabled: value ? MAX_VALUE_S - 30 < value : false,
    },
  ]

  const fiveSecondButtonDisabled = value ? MAX_VALUE_S - 5 < value : false

  const tenSecondButtonDisabled = value ? MAX_VALUE_S - 10 < value : false
  const thirtySecondButtonDisabled = value ? MAX_VALUE_S - 30 < value : false

  const addTime = (e: MouseEvent<HTMLButtonElement>) => {
    const secondsToAddString = e?.currentTarget?.getAttribute('data-value')
    const secondsToAdd = secondsToAddString
      ? parseInt(secondsToAddString, 10)
      : 0

    const addedTime = value + secondsToAdd

    onChange && onChange(addedTime)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const number = convertPatternInputToSeconds(e.currentTarget.value)
    onChange && onChange(number)
  }

  const textInputProps: any = {
    id,
    'data-testid': `${testId}__input`,
    ...rest,
  }

  const buttonClasses = 'mr-2 time-input !w-[36px] !h-[36px]'

  return (
    <div className="flex flex-1 w-full">
      <div className="w-full">
        <PatternFormat
          value={convertSecondsToPatternInput(value, pattern)}
          allowEmptyFormatting
          customInput={TextInput}
          onChange={handleChange}
          format={pattern}
          mask="-"
          inputRef={inputRef}
          {...textInputProps}
        />
      </div>

      {showButtons && (
        <div className="flex flex-col">
          <div className="flex justify-end">
            <Button
              className="lowercase"
              buttonType="link"
              size="mini"
              variant="primary"
              onClick={onReset}
            >
              {tr({ id: 'generic.reset' })}
            </Button>
          </div>

          <div className="flex ml-2">
            {buttons.map((button, index) => (
              <Button
                key={index.toString()}
                variant="secondary"
                className={buttonClasses}
                data-value={button.value}
                onClick={addTime}
                disabled={button.isDisabled}
                size="small"
              >
                {`+${button.value}`}
              </Button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default FormattedTimeInput
