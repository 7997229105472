import React, { FC, ReactNode } from 'react'
import { tr } from '@constants/other'
import { Color } from '@constants/ui'

interface IProps {
  color: Color
  image: string | ReactNode
  text: string

  show: boolean

  /**
   * Selection ID for test purposes.
   */
  'data-testid'?: string
}

const CustomToaster: FC<React.PropsWithChildren<IProps>> = ({
  color,
  image,
  text,
}): JSX.Element => {
  const toastHeaderStyle = {
    borderLeft: `8px solid ${color}`,
  }

  const textStyle = {
    flexGrow: 4,
    fontSize: '1rem',
  }

  return (
    <div className="relative bg-white">
      <div style={{ padding: 0 }}>
        <div
          style={toastHeaderStyle}
          className="w-full flex justify-between items-start p-3 rounded"
        >
          {!!image &&
            (typeof image === 'string' ? (
              <img
                data-testid="toast-status-image"
                src={image}
                className="rounded mr-3 w-8 h-8"
                alt={tr({ id: 'toast.logo' })}
              />
            ) : (
              image
            ))}

          <div
            data-testid="toast-text"
            className="sb-break-words flex min-h-[32px] items-center"
            style={textStyle}
          >
            {text}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomToaster
