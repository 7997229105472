import React, { FC } from 'react'
import { closeVotingBulletPoints } from '@constants/ui'
import Alert from '@components/atoms/Alert'
import { tr } from '@constants/other'
import { ReactComponent as InfoCircleIcon } from '@assets/info_circle.svg'
import { useLive } from '@services/requests/live'
import useGameContent from '@utils/hooks/useGameContent'
import { EndStreamModalContentProps } from './types'
import { isVoteable } from '@utils/voteables'
import { Voteable, VoteableLifecycle } from '@interfaces/Voteable'

const EndStreamModalContent: FC<EndStreamModalContentProps> = ({
  streamId,
  gameId,
}): JSX.Element => {
  const { useGetStreamLeaderboards } = useLive()

  const { data: leaderboards } = useGetStreamLeaderboards({
    streamId: streamId ?? '',
  })
  const { data: buffs } = useGameContent(gameId ?? '', 100)

  const allLeaderboards = leaderboards
    ? leaderboards.pages.flatMap((page) => {
        return page
      })
    : []
  const hasActiveLeaderboard = allLeaderboards?.some((l) => !l.finalised)

  const unresolvedBuffs: Voteable[] = []
  buffs?.pages?.forEach((page) => {
    page?.content?.forEach((content) => {
      if (
        isVoteable(content.entity) &&
        !content.entity?.resolvesAt &&
        content.entity.lifecycle ===
          VoteableLifecycle.VOTEABLE_LIFECYCLE_PREDICTION
      )
        unresolvedBuffs.push(content.entity)
    })
  })

  const hasUnresolvedBuffs = unresolvedBuffs.length > 0

  return (
    <div className="flex flex-col gap-y-4">
      <ul className="flex flex-col pl-4">
        {closeVotingBulletPoints.map((point) => (
          <li key={point}>{point}</li>
        ))}
      </ul>
      {!!hasActiveLeaderboard && (
        <Alert
          iconComponent={
            <InfoCircleIcon className="w-5 h-5 mr-2 flex-shrink-0 text-[#586069]" />
          }
          className="!py-2.5 bg-[#F2F4F8]"
          text={tr({ id: 'stream.endStreamActiveLeaderboard' })}
        />
      )}
      {!!hasUnresolvedBuffs && (
        <Alert
          className="!py-2.5"
          text={tr({ id: 'stream.endThisStreamAlert' })}
        />
      )}
    </div>
  )
}

export default EndStreamModalContent
