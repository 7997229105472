import React, { FC, useEffect, useRef, useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { tr } from '@constants/other'
import Container from '@components/atoms/Container'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import { BuffPageActiveTab } from '@pages/BuffPage'
import AnnouncementForm, {
  getInitialValues as getAnnouncementInitialValues,
} from '@components/organisms/AnnouncementForm'
import TemplateBuffForm from '@components/organisms/TemplateBuffForm'
import TemplateAnnouncementConfirmation from '@components/molecules/TemplateAnnouncementConfirmation'
import { useToast } from '@utils/hooks/useToast'
import {
  AnnouncementTemplate,
  AnnouncementTemplateCreate,
} from '@interfaces/Template'
import { TemplateTypeTabs } from '@components/organisms/TemplateList/types'
import { FormikHelpers, FormikProps } from 'formik'
import { Author } from '@interfaces/Author'
import {
  AnnouncementFormValues,
  CreateAnnouncement,
} from '@interfaces/Announcement'
import { getAuthors } from '@services/requests/author'
import { LanguageCode } from '@interfaces/ILanguage'
import { formatAnnouncementDataToSubmit } from '@utils/formatAnnouncementDataToSubmit'
import { IRouteParams } from '@interfaces/RouteParams'
import { useGetStream } from '@services/requests/stream'
import Breadcrumb from '@components/atoms/Breadcrumb'
import BreadcrumbItem from '@components/atoms/Breadcrumb/BreadcrumbItem'
import TemplateAside from '@components/molecules/TemplateAside'
import { useStreamConfig } from '@utils/hooks/useStreams'

const EMPTY_LANGUAGE_ARRAY: LanguageCode[] = []

const NewTemplate: FC<React.PropsWithChildren<unknown>> = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { id = '', gameId = '' } = useParams<IRouteParams>()
  const path = `/streams/${id}/${gameId}/play/templates`

  const { addErrorToast } = useToast()

  const { data: streamData } = useGetStream({
    streamId: id,
    options: { enabled: !!id },
  })
  const stream = streamData?.stream

  const { data: streamConfig } = useStreamConfig(id ?? '', {
    enabled: !!id,
  })

  const initialLanguages = stream
    ? [stream?.languages[0]]
    : EMPTY_LANGUAGE_ARRAY

  const [announcementTemplatePayload, setAnnouncementTemplatePayload] =
    useState<AnnouncementTemplateCreate | AnnouncementTemplate | null>(null)
  const [showSetTemplateNameModal, setShowSetTemplateNameModal] =
    useState(false)

  const [authors, setAuthors] = useState<Author[]>([])
  const announcementFormRef = useRef<FormikProps<AnnouncementFormValues>>(null)

  const lastSlug = pathname.split('/').pop() ?? ''

  const announcementInitialValues =
    getAnnouncementInitialValues(initialLanguages)

  useEffect(() => {
    const getAllAuthors = async () => {
      try {
        const { authors } = await getAuthors(30, '')
        setAuthors(authors ?? [])
      } catch (error) {
        addErrorToast(error)
      }
    }

    getAllAuthors()
  }, [addErrorToast])

  const showAnnouncementTemplateModal = async (values: CreateAnnouncement) => {
    try {
      const template: AnnouncementTemplateCreate = {
        ...values,
        templateName: '',
        streamId: id,
      }
      setAnnouncementTemplatePayload(template)
      setShowSetTemplateNameModal(true)
    } catch (error) {
      addErrorToast(error)
    }
  }

  const handleAnnouncementSubmit = async (
    values: AnnouncementFormValues,
    helpers: FormikHelpers<AnnouncementFormValues>,
    languages?: LanguageCode[]
  ) => {
    if (!values) return
    if (!languages) return

    const formBody = formatAnnouncementDataToSubmit(values, initialLanguages)
    showAnnouncementTemplateModal(formBody)
    return
  }

  const handleTemplateSaveSuccess = () => {
    if (pathname?.includes('buff')) {
      navigate(`/streams/${id}/${gameId}/play`, {
        state: {
          initialTab: BuffPageActiveTab.TemplateListView,
          initialTemplateTab: TemplateTypeTabs.Buffs,
        },
      })
    } else {
      navigate(`/streams/${id}/${gameId}/play`, {
        state: {
          initialTab: BuffPageActiveTab.TemplateListView,
          initialTemplateTab: TemplateTypeTabs.Announcements,
        },
      })
    }
  }

  const components = {
    buff: {
      title: tr({ id: 'template.buffTemplate' }),
      component: (
        <>
          <TemplateBuffForm
            availableLanguages={stream?.languages ?? EMPTY_LANGUAGE_ARRAY}
            authors={authors}
            stream={stream}
            theme={streamConfig?.config?.theme}
            onSuccess={() => {
              navigate(`/streams/${id}/${gameId}/play`, {
                state: {
                  initialTab: BuffPageActiveTab.TemplateListView,
                  initialTemplateTab: TemplateTypeTabs.Buffs,
                },
              })
            }}
          />
        </>
      ),
    },
    announcements: {
      title: tr({ id: 'template.announcementTemplate' }),
      component: (
        <>
          <AnnouncementForm
            ref={announcementFormRef}
            authors={authors}
            theme={streamConfig?.config?.theme}
            isTemplate
            languages={stream?.languages ?? EMPTY_LANGUAGE_ARRAY}
            initialValues={announcementInitialValues}
            onSubmit={handleAnnouncementSubmit}
          />
          <TemplateAnnouncementConfirmation
            id={id}
            mode={'create'}
            payload={announcementTemplatePayload}
            show={showSetTemplateNameModal}
            onClose={() => setShowSetTemplateNameModal(false)}
            onSuccessCallback={handleTemplateSaveSuccess}
          />
        </>
      ),
    },
  }

  const containerClasses = 'mt-1 mb-2 px-4 py-3 bg-white shadow-card'

  const tabs = Object.entries(components).map(([slug, { title }]) => {
    return {
      text: title,
      href: `${path}/${slug}`,
    }
  })

  const tabsIndex = Object.keys(components).indexOf(lastSlug)

  const initialTemplateTab = pathname?.includes('buff')
    ? TemplateTypeTabs.Buffs
    : TemplateTypeTabs.Announcements

  if (!initialLanguages?.length) return null
  if (!stream) return null

  return (
    <>
      <Container>
        <Row className="pl-2">
          <Col sm={8}>
            <div className="flex flex-col">
              <div className="flex h-6 items-center mt-3 mb-4">
                <Breadcrumb>
                  <BreadcrumbItem
                    href={{
                      pathname: `/streams/${id}/${gameId}/play`,
                      state: {
                        initialTab: BuffPageActiveTab.TemplateListView,
                        initialTemplateTab,
                      },
                    }}
                  >
                    {tr({ id: 'stream.stream' })} - {stream?.title}
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    {tr({ id: 'template.newTemplate' })}
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="flex justify-between items-center mb-3">
                <div className="flex">
                  <h2 className="h3 font-bold mb-0 mr-2.5">
                    {tr({ id: 'template.newTemplate' })}
                  </h2>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pl-2">
          <Routes>
            <Route path={`/`} element={<Navigate to={`${path}/buff`} />} />

            <Route
              path={`/*`}
              element={
                <Col sm={8}>
                  <div className={containerClasses}>
                    <Tabs tabs={tabs} selectedIndex={tabsIndex}>
                      {Object.entries(components).map(
                        ([slug, { component }]) => {
                          return <TabPanel key={slug}>{component}</TabPanel>
                        }
                      )}
                    </Tabs>
                  </div>
                </Col>
              }
            />
          </Routes>
          <Col sm={4}>
            <TemplateAside theme={streamConfig?.config?.theme} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default NewTemplate
