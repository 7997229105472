import React from 'react'
import { Author } from '@interfaces/Author'
import { tr } from '@constants/other'
import Image from '@components/atoms/Image'
import { ReactComponent as ProfilePlaceholder } from '@assets/profile-placeholder.svg'

interface AuthorItemProps {
  author: Author
  isActive: boolean
}

const AuthorItem: React.FC<React.PropsWithChildren<AuthorItemProps>> = ({
  author,
  isActive,
}): JSX.Element => {
  const { displayName, profilePicture } = author

  const bgColor = isActive ? 'bg-primary' : 'transparent'
  const textColor = isActive ? 'text-white' : 'text-black'

  return (
    <div
      data-testid="wrapper"
      className={`flex px-2 py-1 items-center rounded author-item ${bgColor} ${textColor}  ${
        isActive && 'active'
      }`}
    >
      <div className="flex items-center rounded">
        <Image
          data-testid="author-item__image"
          className="rounded mr-3 w-[36px] h-[36px]"
          src={profilePicture}
          fallbackComponent={
            <ProfilePlaceholder className="rounded mr-3 w-[36px] h-[36px]" />
          }
          alt={tr({ id: 'author.image' })}
        />
        <div
          className="text-left w-[216px] overflow-hidden text-ellipsis whitespace-nowrap"
          title={displayName.length > 24 ? displayName : undefined}
          data-testid="desc"
        >
          {displayName}
        </div>
      </div>
    </div>
  )
}

export default AuthorItem
