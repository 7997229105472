import React, { ChangeEvent, FocusEvent } from 'react'
import { get } from 'lodash'
import { tr } from '@constants/other'
import TextInput from '@components/atoms/TextInput'
import ImageFieldWithButtons from '@components/molecules/ImageFieldWithButtons'
import Switch from '@components/atoms/Switch'

import { BannerFormSectionProps } from './types'

const BannerFormSection = <T,>({
  title,
  values,
  errors,
  touched,
  activeLanguage,
  setFieldValue,
  setFieldTouched,
  textInputWritingMode,
  onAddSponsorImageClick,
  imageSize,
  className = '',
  sponsorKey,
}: BannerFormSectionProps<T>) => {
  const sponsorLinkTargetKey = `${sponsorKey}.banner.linkTarget`
  const sponsorLinkTargetValue = get(values, sponsorLinkTargetKey) ?? ''
  const sponsorLinkTargetTouched = get(touched, sponsorLinkTargetKey)
  const sponsorLinkTargetError = get(errors, sponsorLinkTargetKey)

  const imageUrlKey = `${sponsorKey}.banner.imageUrl`
  const imageUrlValue = get(values, imageUrlKey)
  const imageUrlError = get(errors, imageUrlKey)
  const imageUrlTouched = get(touched, imageUrlKey)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFieldValue(name, value)
  }

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    setFieldTouched(event.target.name, true)
  }

  const showSponsorSection = Boolean(get(values, `${sponsorKey}.showBanner`))

  return (
    <fieldset className={`${className}`}>
      <hr className="my-4" />
      <div className="flex items-center gap-x-4">
        <label
          className="text-base !m-0 mr-8 font-bold"
          htmlFor={`${sponsorKey}.showBanner`}
        >
          {tr({
            id: 'qaForm.sponsor.title',
          })}
        </label>
        <Switch
          className="m-0"
          textClassname="text-sm uppercase"
          textPosition="right"
          name="enableSponsorLeaderboard"
          id={`${sponsorKey}.showBanner`}
          isChecked={showSponsorSection}
          onToggle={(e) => {
            setFieldValue(`${sponsorKey}.showBanner`, e.target.checked)
          }}
          data-testid="sponsor-form-section__toggle"
        />
      </div>

      {showSponsorSection && (
        <>
          <div className="flex flex-col justify-between pt-4 px-2">
            {title && <h3 className="font-semibold">{title}</h3>}
            <div className="flex justify-between">
              <div className="space-y-3">
                <TextInput
                  id={sponsorLinkTargetKey}
                  name={sponsorLinkTargetKey}
                  label={tr({
                    id: 'qaForm.sponsor.onClickLabel',
                  })}
                  placeholder="https://www.example.com"
                  value={sponsorLinkTargetValue}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  error={Boolean(sponsorLinkTargetError)}
                  errorLabel={sponsorLinkTargetError}
                  touched={sponsorLinkTargetTouched}
                  data-testid="sponsor-form-section__link-target"
                  dir={textInputWritingMode}
                />
              </div>
              <div className="flex flex-col relative">
                <p className="mb-1">
                  {tr({
                    id: 'qaForm.sponsor.sponsoredImage',
                  })}
                </p>
                <ImageFieldWithButtons
                  imageSize={imageSize}
                  url={imageUrlValue}
                  error={imageUrlError}
                  touched={imageUrlTouched}
                  onAdd={() => {
                    onAddSponsorImageClick && onAddSponsorImageClick()
                  }}
                  onRemove={() => {
                    setFieldTouched(imageUrlKey, true, false)
                    // Timeout here to touched value is set before validation happens
                    setTimeout(() => {
                      setFieldValue(imageUrlKey, '')
                    }, 0)
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <hr className="my-4" />
    </fieldset>
  )
}

export default BannerFormSection
