import React, { FC } from 'react'
import Breadcrumb from '@components/atoms/Breadcrumb'
import BreadcrumbItem from '@components/atoms/Breadcrumb/BreadcrumbItem'
import ScrollableContainer from '@components/atoms/ScrollableContainer/ScrollableContainer'
import { tr } from '@constants/other'
import { Vod } from '@interfaces/VodV2'
import { useNavigate, useParams } from 'react-router-dom'
import { IRouteParams } from '@interfaces/RouteParams'
import { useVodClient } from '@services/requests/vodV2'
import Button from '@components/atoms/Button'
import VodDetailsTabs from '@components/organisms/VodDetailsTabs/VodDetailsTabs'

interface VodDetailsProps {
  vod?: Vod
}

const VodDetails: FC<React.PropsWithChildren<VodDetailsProps>> = () => {
  const params = useParams<IRouteParams>()
  const { id: vodId } = params
  const { useGetVod } = useVodClient()
  const navigate = useNavigate()

  const { data: vod } = useGetVod(vodId, { enabled: !!vodId })

  const handleViewBuffs = () => {
    navigate(`/vod/${vodId}/edit`)
  }

  return (
    <ScrollableContainer>
      <div className="flex items-center flex-1 mt-2 mx-auto w-[998px]">
        <Breadcrumb>
          <BreadcrumbItem>{tr({ id: 'generic.vod' })}</BreadcrumbItem>
          <BreadcrumbItem active>
            {!vodId ? tr({ id: 'vod.newVod' }) : vod?.id}
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div
        data-testid="vod-details-container"
        className="flex flex-col pb-5 items-center w-full h-full bg-white-background"
      >
        <div className="h-full">
          <div className="flex justify-between items-center mt-4 mb-2 mx-2">
            <h3 className="h3">
              {!!vodId ? vod?.name : tr({ id: 'vod.newVodAdd' })}
            </h3>
            {!!vodId && (
              <Button variant="primary" type="button" onClick={handleViewBuffs}>
                {tr({ id: 'vod.viewBuffs' })}
              </Button>
            )}
          </div>
          <div
            data-testid="vod-details-card"
            className="flex flex-col pt-4 mx-2 mt-2 rounded bg-white min-w-[998px] min-h-[320px] shadow-card"
          >
            <VodDetailsTabs vod={vod} />
          </div>
        </div>
      </div>
    </ScrollableContainer>
  )
}

export default VodDetails
