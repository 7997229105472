import React from 'react'
import TextInput from '@components/atoms/TextInput'
import { tr } from '@constants/other'
import { ReactComponent as SearchClear } from '@assets/search-clear.svg'
import { SearchBoxProps } from './types'

const SearchBox = ({
  placeholder = tr({ id: 'generic.search' }),
  onChange,
  onReset,
  value,
  className = '',
}: SearchBoxProps) => {
  return (
    <div className={`relative ${className}`}>
      <TextInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        customInputStyle={{
          backgroundImage: `url("/assets/svg/search.svg")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '20px 20px',
          backgroundPosition: '2% center',
          paddingLeft: 30,
          paddingRight: 40,
          minHeight: '0',
        }}
      />

      {value && (
        <button
          type="button"
          onClick={onReset}
          className="bg-transparent border-none p-0 absolute right-2 top-2/4 leading-none"
          style={{
            transform: 'translateY(-50%) translateY(-5px) ',
          }}
        >
          <SearchClear />
        </button>
      )}
    </div>
  )
}

export default SearchBox
