import React, { FC, useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { IRouteParams } from '@services/providers/BuffProvider'
import { useToast } from '@utils/hooks/useToast'
import { NewConfig } from '@interfaces/IStreamConfig'
import AdvancedSettingsForm, {
  AdvancedSettingsFormValues,
} from '@components/organisms/AdvancedSettingsForm'
import { msToHHMMSS } from '@utils/time'
import {
  getStreamConfig,
  updateStreamConfig,
} from '@services/requests/stream-config'
import { queryClient } from '@utils/reactQuery/client'

const AdvancedSettings: FC<React.PropsWithChildren<unknown>> = () => {
  const { addErrorToast } = useToast()
  const [advancedConfig, setAdvancedConfig] =
    useState<AdvancedSettingsFormValues>()
  const { id: streamId = '' } = useParams<IRouteParams>()
  const allowGamePointsRef = useRef<boolean>(false)

  const handleSubmit = async (values: NewConfig) => {
    try {
      await updateStreamConfig({
        streamId: streamId,
        payload: {
          config: values,
        },
      })

      if (values?.allowGamePoints !== allowGamePointsRef.current) {
        queryClient.invalidateQueries(['stream-config', streamId])
      }
      allowGamePointsRef.current = values?.allowGamePoints
    } catch (error) {
      addErrorToast(error)
      console.error(error)
    }
  }

  useEffect(() => {
    const fetchStreamConfig = async () => {
      try {
        const result = await getStreamConfig(streamId)
        const config = result.config
        setAdvancedConfig({
          ...config,
          secondScreenAppDelay: msToHHMMSS(
            Number(config.secondScreenAppDelay || 0) * 1000
          ),
        })
        allowGamePointsRef.current = config.allowGamePoints
      } catch (error) {
        addErrorToast(error)
        console.error(error)
      }
    }

    fetchStreamConfig()
  }, [addErrorToast, streamId])

  return (
    <div data-testid="advanced-settings">
      {advancedConfig && (
        <AdvancedSettingsForm
          onSubmit={handleSubmit}
          initialValues={advancedConfig}
        />
      )}
    </div>
  )
}

export default AdvancedSettings
