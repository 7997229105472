import React, { useEffect, useState } from 'react'

const Metrics = () => {
  const client = process.env.REACT_APP_ENV
  const [embedId, setEmbedId] = useState<string | null>(null)
  const URL = `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${client}/amp.json`

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(URL)
      const data = await response.json()

      setEmbedId(data.embedID)
    }

    fetchData()
  }, [URL])

  if (!embedId) return null

  return (
    <div className="w-full h-full">
      <iframe
        className="bg-gray-200"
        src={`https://analytics.amplitude.com/share/embed/${embedId}`}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  )
}

export default Metrics
