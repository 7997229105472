import React, { useContext, useState } from 'react'
import { ReactComponent as PersonIcon } from '@assets/person.svg'
import { ReactComponent as StarIcon } from '@assets/star.svg'
import { ReactComponent as StarFilledIcon } from '@assets/star-filled.svg'
import { ReactComponent as MenuIcon } from '@assets/menu.svg'
import { ReactComponent as PinIcon } from '@assets/pin.svg'
import { ReactComponent as UnpinIcon } from '@assets/unpin.svg'
import { ReactComponent as TrashIcon } from '@assets/delete.svg'
import { ReactComponent as BanUserIcon } from '@assets/ban-user.svg'
import Button, { ButtonVariant } from '@components/atoms/Button'
import { Menu } from '@headlessui/react'
import { tr } from '@constants/other'
import ConfirmationModal from '../ConfirmationModal'
import { Alignment } from '../ConfirmationModal/types'
import { MessageItemProps } from './types'
import BanUserModal from '../BanUserModal'
import { useChat } from '@services/requests/chat'
import { IRouteParams } from '@interfaces/RouteParams'
import { useParams } from 'react-router-dom'
import { useToast } from '@utils/hooks/useToast'

const MessageItem = ({
  id,
  message,
  name,
  time,
  avatar,
  userId,
  timestamp,
  onStarMessage,
  onUnstarMessage,
  onPinMessage,
  onUnpinMessage,
  onDeleteMessage,
  isStarred,
  isPinned,
  isDeleted,
}: MessageItemProps) => {
  const [messageToPin, setMessageToPin] = useState<string | undefined>()
  const [showBanModal, setShowBanModal] = useState(false)
  const { id: streamId = '' } = useParams<IRouteParams>()
  const { useBanUser } = useChat()
  const { addErrorToast } = useToast()
  const { mutateAsync: banUser } = useBanUser()
  const text = message?.replace(/\\n/g, '\n')

  const handleStarMessage = () => {
    if (isStarred) {
      onUnstarMessage?.(timestamp)
      return
    }
    onStarMessage?.(timestamp)
  }

  const handlePinMessage = () => {
    onPinMessage?.(timestamp)
    setMessageToPin(undefined)
  }

  const handleUnpinMessage = () => {
    onUnpinMessage?.()
  }

  const handleBanUser = async () => {
    try {
      if (!userId) throw new Error('No user found')

      await banUser({
        streamID: streamId,
        userID: userId,
      })
    } catch (error) {
      addErrorToast(error)
    }
  }

  let dropdownItems = [
    {
      onClick: () => {
        isPinned ? handleUnpinMessage() : setMessageToPin(id)
      },
      label: (
        <div className="flex items-center flex-row gap-x-2">
          {isPinned ? (
            <UnpinIcon className="w-5 h-5" />
          ) : (
            <PinIcon className="w-5 h-5" />
          )}
          <span>
            {tr({
              id: isPinned
                ? 'globalChat.unpinMessage'
                : 'globalChat.pinMessage',
            })}
          </span>
        </div>
      ),
    },
    {
      onClick: () => setShowBanModal(true),
      label: (
        <div className="flex items-center flex-row gap-x-2">
          <BanUserIcon className="w-5 h-5" />
          <span>{tr({ id: 'globalChat.banUser' })}</span>
        </div>
      ),
    },
  ]

  if (!isDeleted) {
    dropdownItems = dropdownItems.concat([
      {
        onClick: () => onDeleteMessage?.(timestamp),
        label: (
          <div className="flex items-center flex-row gap-x-2">
            <TrashIcon className="w-5 h-5" />
            <span>{tr({ id: 'globalChat.deleteMessage' })}</span>
          </div>
        ),
      },
    ])
  }

  return (
    <div
      className="relative flex flex-row justify-between items-center gap-x-4 w-full"
      data-testid="message-item"
    >
      <div className="flex flex-row gap-x-2">
        <div
          data-testid="message-item-avatar"
          className="w-9 h-9 rounded-full flex items-center justify-center flex-shrink-0 border-[2px] border-solid border-[#717985] bg-white"
        >
          {avatar ? (
            <img
              src={avatar}
              alt="avatar"
              className="w-full h-full object-contain rounded-full"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-[#D1D6DE] rounded-full">
              <PersonIcon className="w-5 h-5 text-[#323A46]" />
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <p
              className="sb-break-words line-clamp-1 font-bold text-xs"
              data-testid="message-item-name"
            >
              {name}
            </p>
            {!!time && (
              <span
                data-testid="message-item-time"
                className="ms-2 text-floatingPanel-muted-text font-semibold flex-shrink-0 text-[10px] text-[#717985]"
              >
                {time}
              </span>
            )}
          </div>
          <div>
            <p
              data-testid="message-item-text"
              className="sb-break-words text-sm whitespace-pre-line text-[#191D23]"
            >
              {text}
            </p>
          </div>
        </div>
      </div>
      <div
        data-testid="message-item-actions"
        className="flex-shrink-0 flex gap-x-2 items-center"
      >
        <Button
          onClick={handleStarMessage}
          variant="neutral"
          size="mini"
          data-testid="message-item-star-button"
        >
          {isStarred ? (
            <StarFilledIcon
              data-testid="star-filled-icon"
              className="h-5 w-5 text-[#959DA5]"
            />
          ) : (
            <StarIcon
              data-testid="star-icon"
              className="h-5 w-5 text-[#959DA5]"
            />
          )}
        </Button>
        <Menu as="div" className="relative h-5">
          <Menu.Button className="">
            <MenuIcon className="w-5 h-5 text-black" />
          </Menu.Button>
          <Menu.Items
            as="div"
            className="absolute right-0 -top-[125px] z-30 bg-dark-background w-52 focus:outline-none rounded shadow-lg bg-white"
          >
            {dropdownItems.map((item, index) => (
              <Menu.Item
                key={index.toString()}
                as={'div'}
                className="!text-black hover:!text-white hover:!bg-[#007bff] hover:rounded-sm py-2 px-4 border-1 cursor-pointer"
                onClick={item.onClick}
              >
                {item.label}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
      </div>

      <ConfirmationModal
        onClose={() => setMessageToPin(undefined)}
        onSuccess={handlePinMessage}
        title={tr({ id: 'globalChat.pinMessageConfirmation' })}
        successBtnText={tr({
          id: 'globalChat.pinMessage',
        })}
        subtext={tr({ id: 'globalChat.pinMessageDescription' })}
        show={!!messageToPin}
        data-testid="pin-message-modal"
        variant={ButtonVariant.Primary}
        alignment={Alignment.END}
      />

      <BanUserModal
        show={showBanModal}
        onClose={() => setShowBanModal(false)}
        onCancel={() => setShowBanModal(false)}
        onSuccess={handleBanUser}
      />
    </div>
  )
}

export default MessageItem
