import React, { FC } from 'react'
import StreamLeaderboardItem from '../StreamLeaderboardItem'
import ReorderLeaderboards from './ReorderLeaderboards'
import { StreamLeaderboardsListProps } from './types'

const StreamLeaderboardsList: FC<
  React.PropsWithChildren<StreamLeaderboardsListProps>
> = ({ streamId, leaderboards, onItemClick, onReorder }) => {
  const allLeaderboards = leaderboards
    ? leaderboards.pages.flatMap((page) => {
        return page
      })
    : []

  return (
    <div
      data-testid="stream-leaderboard-list__wrapper"
      className="mt-4 rounded"
    >
      <ReorderLeaderboards
        values={allLeaderboards ?? []}
        onChange={onReorder}
      />
      <div id="stream-leaderboards-list" className="flex flex-col mx-auto">
        {leaderboards?.pages.map((page) => {
          return page?.map((leaderboard) => {
            const isMonetised = leaderboard.paymentOptions?.some(
              (po) => !!po.currency && po.cost > 0
            )

            const isStreamAttached = leaderboard.pointsSources?.some(
              (x) => !!x.streamID
            )

            return (
              <StreamLeaderboardItem
                key={leaderboard.id}
                name={leaderboard.name}
                leaderboardId={leaderboard.id}
                streamId={streamId}
                isMonetised={isMonetised}
                isStreamAttached={isStreamAttached}
                isFinalised={leaderboard.finalised}
                onClick={onItemClick}
              />
            )
          })
        })}
      </div>
    </div>
  )
}

export default StreamLeaderboardsList
