import { BatchUser, User } from '@interfaces/IUser'
import { useMutation, useQueryClient, UseQueryOptions } from 'react-query'
import { QueryKey } from '@utils/reactQuery/types'
import { useFetch } from '@utils/reactQuery/useFetch'
import { InviteUser } from '@components/molecules/InviteUserModal'
import { Role } from '@pages/TeamMembers/types'
import { client } from './client'

export const useGetUser = <T = User>(
  params?: UseQueryOptions<T, Error, T, QueryKey>
) => {
  return useFetch<T>({
    url: `/users/me`,
    config: params,
  })
}

export const useGetPrivilegedUsers = <T = { users: User[] }>(
  params?: UseQueryOptions<T, Error, T, QueryKey>
) => {
  return useFetch<T>({
    url: `/users/privileged?pagination.pageSize=1000`,
    key: 'users/privileged',
    config: params,
  })
}

export const useGetUserRoles = <T = { roles: Role[] }>(
  id?: string,
  params?: UseQueryOptions<T, Error, T, QueryKey>
) => {
  return useFetch<T>({
    url: `/users/${id}/roles`,
    config: params,
  })
}

export const useUpdateUser = () => {
  return useMutation((data: Partial<InviteUser>) =>
    client.patch(`/users/${data?.id}`, {
      firstName: data?.firstName,
      lastName: data?.lastName,
    })
  )
}

export const useUpdateUserRole = () => {
  return useMutation((data: Partial<InviteUser>) =>
    client.put(`/users/${data?.id}/roles`, {
      roles: [data?.role],
    })
  )
}

export const useDeleteUserRoles = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (data: Partial<InviteUser>) => client.delete(`/users/${data?.id}/roles`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users/privileged'])
      },
    }
  )
}

export const getUsers = async (userIds: string[]): Promise<BatchUser[]> => {
  const response = await client.get(
    `/users?ids=${userIds.join(',')}&profile=private`
  )

  const users = response.data
  return users
}
