import React, { FC, RefObject } from 'react'
import { Link } from 'react-router-dom'

import { NewAuthorRef } from '@components/organisms/AuthorDetails'
import { NewStreamRef } from '@components/organisms/StreamDetails'
import { ReactComponent as AddCross } from '@assets/add-new-cross.svg'

interface SidebarActionProps {
  path: string
  sidebarRef: RefObject<NewAuthorRef | NewStreamRef> | null
}

const SidebarAction: FC<React.PropsWithChildren<SidebarActionProps>> = ({
  path,
  sidebarRef,
}) => {
  return (
    <Link
      onClick={() => sidebarRef?.current?.clear()}
      to={`/${path}/new`}
      className="w-9 h-9 rounded bg-primary flex items-center justify-center mx-[13px] hover:bg-[#0090fe]"
    >
      <AddCross style={{ fill: 'white' }} className="w-6 h-6" />
    </Link>
  )
}

export default SidebarAction
