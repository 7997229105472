import { useMutation, useQuery } from 'react-query'
import useClient, { Service } from '@utils/hooks/useClient'
import {
  BanUserPayload,
  BannedUser,
  ChatMetrics,
  ChatModeration,
  ChatSettings,
  ModeratorProfile,
  StreamChatFormValues,
} from '@interfaces/Chat'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Chat } from '@pubnub/chat'
import PubNub, { ParsedGrantToken } from 'pubnub'

export const useChat = () => {
  const { client } = useClient(Service.LIVE)

  const getStreamChatSettings = async (streamId: string) => {
    const data = await client.get<ChatSettings>(`/streams/${streamId}/chat`)

    return data.data
  }

  const getPubnubToken = async (streamId: string) => {
    const data = await client.post<{ token: string }>(
      `/streams/${streamId}/chat/token-exchange`
    )

    return data.data.token
  }

  const updateChatSettings = async (
    streamId: string,
    payload: StreamChatFormValues
  ) => {
    const data = await client.patch(`/streams/${streamId}/chat`, payload)

    return data
  }

  const getChatModeration = async (): Promise<ChatModeration> => {
    const { data } = await client.get('/chat/moderation')

    return data
  }

  const updateChatModeration = async (payload: ChatModeration) => {
    const { data } = await client.put('/chat/moderation', payload)

    return data
  }

  const banUser = async (payload: BanUserPayload) => {
    const { data } = await client.post('/chat/bans', payload)

    return data
  }

  const unbanUser = async (banId: string) => {
    const { data } = await client.delete(`/chat/bans/${banId}`)

    return data
  }

  const getBannedUsers = async (): Promise<BannedUser[]> => {
    const { data } = await client.get(`/chat/bans`)

    return data
  }

  const getModeratorProfile = async (): Promise<ModeratorProfile> => {
    const { data } = await client.get(`/chat/moderator`)

    return data
  }

  const updateModeratorProfile = async (
    payload: ModeratorProfile
  ): Promise<ModeratorProfile> => {
    const { data } = await client.put(`/chat/moderator`, payload)

    return data
  }

  const deleteChatMessage = async (
    streamId: string,
    timeToken: string
  ): Promise<void> => {
    const { data } = await client.delete(
      `/streams/${streamId}/chat/messages/${timeToken}`
    )

    return data
  }

  const getChatMetrics = async (streamId: string): Promise<ChatMetrics> => {
    const { data } = await client.get(`/streams/${streamId}/chat/metrics`)

    return data
  }

  const useStreamChat = (streamId?: string, userId?: string) => {
    const ENABLE_GLOBAL_CHAT = process.env.GLOBAL_CHAT === 'true'
    const [chat, setChat] = useState<Chat>()
    const [pubnubParsedToken, setPubnubParsedToken] =
      useState<ParsedGrantToken>()
    const [pubnubClient, setPubnubClient] = useState<PubNub>()
    const [isInitializedChat, setIsInitializedChat] = useState(false)
    const { mutateAsync: mutatePubnubToken } = useMutatePubnubToken()
    const timeoutRef = useRef<number>()

    const initChat = useCallback(
      async (timeoutMS: number, streamId: string, userId: string) => {
        timeoutRef?.current && clearTimeout(timeoutRef.current)
        timeoutRef.current = window.setTimeout(async () => {
          const token = await mutatePubnubToken(streamId)
          const { pubnub } = await getStreamChatSettings(streamId)

          const pnChat = await Chat.init({
            publishKey: pubnub.pubKey,
            subscribeKey: pubnub.subKey,
            authKey: token,
            userId: userId,
          })

          const parsedToken = pnChat.sdk.parseToken(token)
          const expireTimeMS = parsedToken.ttl * 60 * 1000
          // Refresh token after 80% of expire time has passed
          const refreshTokenTime = expireTimeMS * 0.8
          initChat(refreshTokenTime, streamId, userId)

          setChat(pnChat)
          setPubnubParsedToken(parsedToken)
          setPubnubClient(pnChat.sdk)
          setIsInitializedChat(true)
        }, timeoutMS)
      },
      [mutatePubnubToken]
    )

    useEffect(() => {
      if (!userId || !streamId || !ENABLE_GLOBAL_CHAT) return

      initChat(0, streamId, userId)
    }, [ENABLE_GLOBAL_CHAT, initChat, streamId, userId])

    return {
      initChat,
      chat,
      isInitializedChat,
      pubnubParsedToken,
      pubnubClient,
    }
  }

  const useGetStreamChatSettings = (streamId: string) => {
    return useQuery(
      ['stream-chat', streamId],
      () => getStreamChatSettings(streamId as string),
      { enabled: !!streamId }
    )
  }

  const useMutatePubnubToken = () => {
    return useMutation((streamId: string) => {
      return getPubnubToken(streamId)
    })
  }

  const useGetModeration = () => {
    return useQuery(['chat-moderation'], () => getChatModeration(), {})
  }

  const useUpdateModeration = () => {
    return useMutation((data: ChatModeration) => updateChatModeration(data))
  }

  const useBanUser = () => {
    return useMutation((data: BanUserPayload) => banUser(data))
  }

  const useUnbanUser = () => {
    return useMutation((banId: string) => unbanUser(banId))
  }

  const useGetBannedUsers = () => {
    return useQuery(['banned-users'], () => getBannedUsers())
  }

  const useGetModeratorProfile = () => {
    return useQuery(['chat-moderator'], () => getModeratorProfile(), {})
  }

  const useUpdateModerationProfile = () => {
    return useMutation((data: ModeratorProfile) => updateModeratorProfile(data))
  }

  const useDeleteChatMessage = () => {
    return useMutation((data: { streamId: string; timetoken: string }) =>
      deleteChatMessage(data.streamId, data.timetoken)
    )
  }

  const useGetChatMetrics = (streamId: string) => {
    return useQuery(
      ['chat-metrics', streamId],
      () => getChatMetrics(streamId),
      {}
    )
  }

  return {
    getStreamChatSettings,
    getPubnubToken,
    updateChatSettings,
    useStreamChat,
    useGetStreamChatSettings,
    useMutatePubnubToken,
    useGetModeration,
    useUpdateModeration,
    useBanUser,
    useUnbanUser,
    useGetBannedUsers,
    useGetModeratorProfile,
    useUpdateModerationProfile,
    useDeleteChatMessage,
    useGetChatMetrics,
  }
}
