import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  Ref,
} from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import {
  ButtonProps,
  ButtonShape,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  LinkButtonProps,
} from './types'

const btnPaddingPill = {
  [ButtonSize.Mini]: 'py-1.5 px-4',
  [ButtonSize.Small]: 'py-2.5 px-5',
  [ButtonSize.Medium]: 'py-3.5 px-7',
  [ButtonSize.Large]: 'py-4 px-9',
  [ButtonSize.NoSize]: 'p-0',
}

const btnPadding = {
  [ButtonSize.Mini]: 'py-1.5 px-2',
  [ButtonSize.Small]: 'py-2.5 px-3',
  [ButtonSize.Medium]: 'py-3.5 px-4',
  [ButtonSize.Large]: 'py-4 px-5',
  [ButtonSize.NoSize]: 'p-0',
}

const btnHeight = {
  [ButtonSize.Mini]: 'h-7',
  [ButtonSize.Small]: 'h-8',
  [ButtonSize.Medium]: 'h-10',
  [ButtonSize.Large]: 'h-[60px]',
  [ButtonSize.NoSize]: 'h-auto',
}

const btnWidth = {
  [ButtonSize.Mini]: 'w-7',
  [ButtonSize.Small]: 'w-10',
  [ButtonSize.Medium]: 'w-[52px]',
  [ButtonSize.Large]: 'w-[60px]',
  [ButtonSize.NoSize]: 'w-auto',
}

const btnFontSize = {
  [ButtonSize.Mini]: 'text-xs',
  [ButtonSize.Small]: 'text-base',
  [ButtonSize.Medium]: 'text-base',
  [ButtonSize.Large]: 'text-lg',
  [ButtonSize.NoSize]: 'text-base',
}

const btnVariant = ({ disabled }: { disabled: boolean }) => {
  return {
    [ButtonType.Button]: {
      [ButtonVariant.Primary]: clsx(
        'bg-[#007bff] text-white',
        !disabled && 'hover:bg-[#0060c7]'
      ),
      [ButtonVariant.Secondary]: clsx(
        'text-[#212529] bg-[#D8E1EC]',
        !disabled && 'hover:bg-[#becddf]'
      ),
      [ButtonVariant.Danger]: clsx(
        'bg-[#AB0101] text-white',
        !disabled && 'hover:bg-[#9a0101]'
      ),
      [ButtonVariant.Success]: clsx(
        'bg-[#28a745] text-white',
        !disabled && 'hover:bg-[#218838]'
      ),
      [ButtonVariant.Neutral]: clsx('bg-transparent', disabled && 'opacity-60'),
    },
    [ButtonType.Link]: {
      [ButtonVariant.Primary]: clsx(
        'bg-transparent text-[#007bff]',
        !disabled && 'hover:text-[#0060c7]'
      ),
      [ButtonVariant.Secondary]: clsx(
        'bg-transparent text-[#212529]',
        !disabled && 'hover:text-[#becddf]'
      ),
      [ButtonVariant.Danger]: clsx(
        'bg-transparent text-[#AB0101]',
        !disabled && 'hover:text-[#9a0101]'
      ),
      [ButtonVariant.Success]: clsx(
        'bg-transparent text-[#28a745]',
        !disabled && 'hover:text-[#218838]'
      ),
      [ButtonVariant.Neutral]: clsx(
        'bg-transparent text-black',
        disabled && 'opacity-60'
      ),
    },
  }
}

const btnRadius = {
  [ButtonShape.Square]: '',
  [ButtonShape.Round]: 'rounded',
  [ButtonShape.Pill]: 'rounded-full',
  [ButtonShape.Circle]: 'rounded-full',
}

const animation = 'motion-safe:transition-colors duration-200'

const Button = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps | LinkButtonProps
>(
  (
    {
      children,
      endElement,
      startElement,
      className = '',
      type = 'button',
      size = ButtonSize.Medium,
      shape = ButtonShape.Round,
      variant = ButtonVariant.Primary,
      buttonType = ButtonType.Button,
      disabled = false,
      'data-testid': testId = 'button',
      href,
      ...rest
    },
    ref
  ) => {
    // TODO: Investigate theme, reduced motion mode and rtl
    const btnStyle = clsx(
      '',
      btnRadius[shape],
      btnFontSize[size],
      btnVariant({ disabled })[buttonType][variant],
      btnHeight[size],
      animation,
      disabled &&
        'text-opacity-60 bg-opacity-50 bg-hover:opacity-50 cursor-not-allowed',
      shape === ButtonShape.Pill
        ? btnPaddingPill[size]
        : shape !== ButtonShape.Circle
        ? btnPadding[size]
        : btnWidth[size]
    )

    const isLink = typeof href !== 'undefined'

    const commonProps = {
      type,
      className: clsx(
        '!inline-flex whitespace-nowrap items-center justify-center border-none',
        btnStyle,
        className
      ),
      'data-testid': testId,
      href,
    }

    if (isLink) {
      const linkProps = {
        className: !disabled
          ? commonProps.className
          : `${commonProps.className} pointer-events-none`,
      }

      return (
        <Link
          ref={ref as Ref<HTMLAnchorElement> | undefined}
          {...commonProps}
          {...linkProps}
          {...(rest as AnchorHTMLAttributes<HTMLAnchorElement>)}
          to={href ?? ''}
        >
          {startElement}
          <span>{children}</span>
          {endElement}
        </Link>
      )
    }

    const buttonProps = {
      disabled,
    }

    return (
      <button
        ref={ref as Ref<HTMLButtonElement> | undefined}
        {...commonProps}
        {...buttonProps}
        {...(rest as ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        {startElement}
        <span>{children}</span>
        {endElement}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
