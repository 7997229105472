import React, { FC, useCallback, useContext, useState } from 'react'
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import FileInput from '@components/atoms/FileInput'
import ImageIcon from '@components/atoms/ImageIcon'
import UploadingAssetList from '@components/organisms/UploadingAssetList'
import { tr } from '@constants/other'
import { LanguageCode } from '@interfaces/ILanguage'
import { isFileTypeSupported } from '@utils/asset'
import { ReactComponent as RetryIcon } from '@assets/retry.svg'
import { ReactComponent as CloudIcon } from '@assets/cloud.svg'
import { uploadAsset } from '@services/requests/assets'
import {
  AssetContext,
  AssetManagerView,
} from '@services/providers/AssetProvider'
import { queryClient } from '@utils/reactQuery/client'
import { AssetActionTypes } from '@services/reducers/assetReducer'
import Button from '@components/atoms/Button'
import Modal from '@components/molecules/Modal'
import { AssetClass, UploadableAsset } from '@interfaces/Assets'
import { ReactComponent as InfoIcon } from '@assets/info_circle.svg'
import { generateUploadableAsset } from '../utils/generateUploadableAsset'
import ManageAssetList from '../ManageAssetList'
import { isFileSizeCorrect } from '../utils/isFileSizeCorrect'
import { UploadAssetsProps } from './types'
import { convertBytesToMB } from '../utils/convertByteToMb'

const INITIAL_UPLOADING_COUNT = 5

const UploadAssets: FC<React.PropsWithChildren<UploadAssetsProps>> = ({
  onClose,
  type,
  accept = 'image/*',
}) => {
  const { state, dispatch } = useContext(AssetContext)
  const [isUploadingView, setIsUploadingView] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [singleFileSizeError, setSingleFileSizeError] = useState<string | null>(
    null
  )

  const uploadableAssets = state.uploadableAssets.filter((asset) => {
    return !asset.sizeError && isFileTypeSupported(asset?.image, asset?.accept)
  })

  const uploadedCount = uploadableAssets.reduce((acc, asset) => {
    return asset.status == 'success' ? acc + 1 : acc
  }, 0)

  const failedCount = (
    !isUploadingView ? state.uploadableAssets : uploadableAssets
  ).reduce((acc, asset) => {
    return asset.status === 'error' ||
      !isFileTypeSupported(asset?.image, asset?.accept)
      ? acc + 1
      : acc
  }, 0)

  const imageCount = !isUploadingView
    ? state.uploadableAssets.length
    : uploadableAssets.length
  const hasImages = Boolean(imageCount)
  const disableUploadButton = uploadableAssets.length === 0
  const areUploadsCompleted = imageCount == failedCount + uploadedCount
  const uploadingAssetsCount = state.uploadableAssets.filter(
    (asset) => asset.status === 'uploading'
  )?.length

  const handleCancel = useCallback(() => {
    dispatch({
      type: AssetActionTypes.ChangeView,
      payload: AssetManagerView.AssetList,
    })
    dispatch({
      type: AssetActionTypes.Update,
      payload: [],
    })
  }, [dispatch])

  const handleUploadProgress = useCallback(
    (filename: any) => (progress: AxiosProgressEvent) => {
      const percentage = Math.floor(
        (progress.loaded * 100) / Number(progress.total || 1)
      )

      dispatch({
        type: AssetActionTypes.UpdateProgress,
        payload: {
          filename,
          progress: percentage,
          sizeTotal: progress.total,
          sizeLoaded: progress.loaded,
        },
      })
    },
    [dispatch]
  )

  const processUpload = useCallback(
    (uploadFile: UploadableAsset) => {
      const form: FormData = new FormData()

      const regex = /[^\w]|_/g
      const fileName = uploadFile.meta.filename.replaceAll(regex, ' ')

      form.append('files', uploadFile.image)
      form.append(
        'meta',
        JSON.stringify({
          filename: fileName,
          title: fileName,
          class: type,
          description: uploadFile?.meta?.description || '',
          altText: {
            [LanguageCode.en]: fileName,
          },
        })
      )

      const config: AxiosRequestConfig = {
        onUploadProgress: handleUploadProgress(uploadFile.meta.filename),
        cancelToken: uploadFile.cancelRequest.token,
      }

      dispatch({
        type: AssetActionTypes.UpdateProgress,
        payload: {
          filename: uploadFile?.meta?.filename,
          status: 'uploading',
        },
      })

      return uploadAsset(form, config)
        .then(() => {
          // Upload completed
          dispatch({
            type: AssetActionTypes.UpdateProgress,
            payload: {
              filename: uploadFile.meta.filename,
              status: 'success',
            },
          })
          queryClient.invalidateQueries(`assets-${type || 'all'}`)
        })
        .catch(() => {
          // Upload failed
          dispatch({
            type: AssetActionTypes.UpdateProgress,
            payload: {
              filename: uploadFile.meta.filename,
              status: 'error',
            },
          })
        })
    },
    [dispatch, handleUploadProgress, type]
  )

  const handleUpload = useCallback(() => {
    setIsUploadingView(true)
    let index = INITIAL_UPLOADING_COUNT - 1

    const upload = async (asset: UploadableAsset) => {
      try {
        await processUpload(asset)
      } finally {
        index += 1
        const nextAsset = uploadableAssets[index]
        if (nextAsset && nextAsset.status === 'pending') {
          upload(nextAsset)
        }
      }
    }

    const initialAssets = [...uploadableAssets].slice(
      0,
      INITIAL_UPLOADING_COUNT
    )

    initialAssets?.forEach(async (asset) => {
      upload(asset)
    })
  }, [dispatch, uploadableAssets, processUpload])

  const handleRetryFailed = useCallback(() => {
    setIsUploadingView(true)
    let index = INITIAL_UPLOADING_COUNT - 1

    const upload = async (asset: UploadableAsset) => {
      try {
        await processUpload(asset)
      } finally {
        index += 1
        const nextAsset = failedAssets[index]
        if (nextAsset && nextAsset.status === 'error') {
          upload(nextAsset)
        }
      }
    }

    const failedAssets = uploadableAssets.filter(
      (asset) => asset.status === 'error'
    )

    const initialFailedAssets = [...failedAssets].slice(
      0,
      INITIAL_UPLOADING_COUNT
    )

    initialFailedAssets?.forEach((asset) => {
      upload(asset)
    })
  }, [dispatch, uploadableAssets, processUpload])

  const handleUploadOne = useCallback(
    (id: string) => {
      setIsUploadingView(true)

      const assetToUpload = uploadableAssets?.find((asset) => asset.id === id)
      if (!assetToUpload) return

      processUpload(assetToUpload)
    },
    [dispatch, uploadableAssets, processUpload]
  )

  const handleDragEnter = useCallback(() => {
    setIsDragging(true)
  }, [])

  const handleDragExit = useCallback(() => {
    setIsDragging(false)
  }, [])

  const bgColor = isDragging ? 'bg-[#E8F2FF]' : 'bg-white'
  const cloudFillColor = isDragging ? 'fill-[#007BFF]' : 'fill-[#717A85]'

  const handleAddFile = useCallback(
    (files: File[]) => {
      let size = 0
      setSingleFileSizeError(null)

      if (files?.length === 1 && !isFileSizeCorrect(files[0].size)) {
        setSingleFileSizeError(tr({ id: 'assetManager.singleMaxSize' }))
        return
      }

      setIsDragging(false)
      dispatch({
        type: AssetActionTypes.Update,
        payload: files.map((file) => {
          const fileSize = isFileTypeSupported(file, accept)
            ? isFileSizeCorrect(file.size)
              ? convertBytesToMB(file.size)
              : 0
            : 0
          size += fileSize
          return generateUploadableAsset(file, size > 40, type, accept)
        }),
      })
    },
    [dispatch]
  )

  const showRetryButton =
    hasImages && isUploadingView && failedCount > 0 && !uploadingAssetsCount

  return (
    <>
      <Modal.Body className="!overflow-y-auto">
        <div className="flex flex-col justify-center rounded bg-gray">
          {!hasImages && (
            <div className="flex flex-col justify-center w-full text-center p-4">
              <div
                className={`group p-4 w-full relative ${bgColor} hover:!bg-[#E8F2FF] border-[1px] border-dashed border-[#959DA5] rounded transition-colors`}
              >
                <FileInput
                  onDragEnter={handleDragEnter}
                  onDragExit={handleDragExit}
                  onAddFile={handleAddFile}
                  accept={type === AssetClass.BuffSound ? '.mp3' : accept}
                />
                <div className="flex justify-center">
                  <CloudIcon
                    className={`${cloudFillColor} group-hover:fill-[#007BFF]`}
                  />
                </div>
                <p className="mt-2 mb3.5 text-black">
                  {tr({ id: 'assetManager.drag' })}
                </p>
                <p className="text-[#959DA5] text-sm mb-0">
                  {tr({
                    id:
                      type === AssetClass.BuffSound
                        ? 'assetManager.maxUploadSizeSound'
                        : 'assetManager.maxUploadSize',
                  })}
                </p>
              </div>
              {!!singleFileSizeError && (
                <div className="text-red flex items-center text-sm gap-x-2 mt-2">
                  <InfoIcon className="w-4 h-4" />
                  <p className="m-0">{singleFileSizeError}</p>
                </div>
              )}
            </div>
          )}
          {hasImages && !isUploadingView && (
            <div>
              <ManageAssetList type={type} />
            </div>
          )}
          {hasImages && isUploadingView && (
            <div className="px-4 py-2 default-scroll overflow-y-scroll scroll-auto h-[40em] max-h-[calc(100vh-300px)] rounded border border-[#E1E4E8]">
              <UploadingAssetList onRetry={handleUploadOne} />
            </div>
          )}
        </div>
      </Modal.Body>
      {hasImages && (
        <div className="flex justify-between py-3 px-3">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center">
              <ImageIcon
                className="mr-2"
                width={24}
                height={24}
                fill="#586069"
              />
              <span className="text-sm">
                {!isUploadingView && (
                  <span className="text-[#586069]">
                    {`${imageCount} ${tr({
                      id: 'assetManager.items',
                    })}`}
                    {Boolean(failedCount) && (
                      <span className="ml-2 text-[#DC2626]">
                        {tr(
                          {
                            id: 'assetManager.failedCount',
                          },
                          {
                            filesFailedCount: failedCount,
                          }
                        )}
                      </span>
                    )}
                  </span>
                )}

                {isUploadingView && (
                  <>
                    <span className="text-[#586069]">
                      {areUploadsCompleted
                        ? tr(
                            {
                              id: 'assetManager.completedCount',
                            },
                            {
                              filesCompletedCount: uploadedCount,
                              filesToUploadCount: imageCount,
                            }
                          )
                        : tr(
                            {
                              id: 'assetManager.uploadedCount',
                            },
                            {
                              filesUploadedCount: uploadedCount,
                              filesToUploadCount: imageCount,
                            }
                          )}
                    </span>
                    {areUploadsCompleted && Boolean(failedCount) && (
                      <span className="ml-2 text-[#DC2626]">
                        {tr(
                          {
                            id: 'assetManager.failedCount',
                          },
                          {
                            filesFailedCount: failedCount,
                          }
                        )}
                      </span>
                    )}
                  </>
                )}
              </span>
            </div>
            <div className="flex items-center gap-x-2">
              {!isUploadingView && (
                <>
                  <Button variant="secondary" onClick={handleCancel}>
                    {tr({ id: 'generic.cancel' })}
                  </Button>
                  <Button
                    className="ml-2"
                    variant="primary"
                    disabled={disableUploadButton}
                    onClick={handleUpload}
                  >
                    {tr({ id: 'generic.upload' })}
                  </Button>
                </>
              )}
              {showRetryButton && (
                <Button variant="secondary" onClick={handleRetryFailed}>
                  <RetryIcon className="w-6 h-6 mr-2" />
                  <span>{tr({ id: 'assetManager.retryFailed' })}</span>
                </Button>
              )}
              {isUploadingView && (
                <Button className="ml-2" variant="primary" onClick={onClose}>
                  {tr({ id: 'generic.close' })}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UploadAssets
