import React, { FC, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'

interface AutoSubmitFormProps {
  /**
   * If component should set touched on any errors
   * @default false
   */
  addTouchedOnError?: boolean
}

const AutoSubmitForm = ({ addTouchedOnError = false }: AutoSubmitFormProps) => {
  const { submitForm, values, errors, setFieldTouched } = useFormikContext()
  const timeoutRef = useRef<number>()
  const initialRef = useRef<boolean>(false)

  useEffect(() => {
    if (!initialRef.current) {
      initialRef.current = true
      return
    }
    const clearTimeout = () => {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }
    clearTimeout()
    if (Object.values(errors).length !== 0) {
      if (addTouchedOnError) {
        const keys = Object.keys(errors)
        keys.forEach((key) => setFieldTouched(key, true, false))
      }
      return
    }

    timeoutRef.current = window.setTimeout(() => {
      submitForm()
    }, 1000)

    return clearTimeout
  }, [values, errors, submitForm, setFieldTouched, addTouchedOnError])

  return <></>
}

export default AutoSubmitForm
