import { LanguageCode } from '@interfaces/ILanguage'

const formatLocalisationsBasedOnAuthor = (
  author: Record<LanguageCode, any>
): LanguageCode[] => {
  return Object.keys(author).map((langKey) => {
    return langKey as LanguageCode
  })
}

export default formatLocalisationsBasedOnAuthor
