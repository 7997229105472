import { LanguageCode } from '@interfaces/ILanguage'
import {
  LocalisedSponsorContent,
  LocalisedContent,
} from '@interfaces/localisation'
import { CreateLeaderboardRequest } from '@interfaces/ILeaderboard'
import { parseLocalisationObjectToArray } from '@utils/localisation'
import { LeaderboardDetailsFormValues } from '@components/organisms/LeaderboardDetailsForm'

// TODO: Leaderboard migration
// export const transformLeaderboardDetailFormValuesIntoRequestBody = (
//   gameIds: string[],
//   values: LeaderboardDetailsFormValues
// ): CreateLeaderboardRequest => {
//   let sponsor: CreateLeaderboardRequest['sponsor'] = { localisations: [] }

//   const title: CreateLeaderboardRequest['title'] = {
//     localisations: [],
//   }

//   const langsWithSponsor = Object.entries(values.showSponsor)
//     .filter(([key, value]) => {
//       return value === true
//     })
//     .map(([key]) => key) as LanguageCode[]

//   if (langsWithSponsor.length !== 0) {
//     const newSponsor: Partial<Record<LanguageCode, LocalisedSponsorContent>> =
//       {}
//     sponsor = {
//       localisations: [],
//     }

//     langsWithSponsor.forEach((lang) => {
//       if (!newSponsor[lang]) {
//         newSponsor[lang] = {
//           text: '',
//           localisation: lang,
//         }
//       }

//       const obj = newSponsor[lang]
//       if (!obj) return

//       // TODO: Leaderboard migration
//       // obj.imageUrl = values.sponsor[lang]?.imageUrl
//       // obj.linkTarget = values.sponsor[lang]?.linkTarget
//     })

//     sponsor.localisations =
//       parseLocalisationObjectToArray<LocalisedSponsorContent>(newSponsor)
//   }

//   const newTitle: Partial<Record<LanguageCode, LocalisedContent>> = {}

//   Object.entries(values.leaderboardTitle).forEach(([key, value]) => {
//     const lang = key as LanguageCode
//     newTitle[lang] = {
//       text: value,
//       localisation: lang,
//     }
//   })

//   title.localisations = parseLocalisationObjectToArray(newTitle)

//   return {
//     gameIds,
//     title,
//     sponsor,
//     name: values.name,
//     autoGenerated: false,
//   }
// }

/**
 * Round a percentage to 1 decimal place max
 * @param {number}percentile
 * @return {number}
 */
export const formatPercentage = (percentile: number) => {
  return Math.round(percentile * 10) / 10
}
