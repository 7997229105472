interface OldLanguage {
  code: string
  id: number
  title: string
}
interface OldLanguageMap {
  [key: string]: OldLanguage
}

export const languagesMap: OldLanguageMap = {
  LANGUAGE_ENGLISH: { code: 'en', id: 1, title: 'English' },
  LANGUAGE_PORTUGUESE: { code: 'pt', id: 2, title: 'Português' },
  LANGUAGE_FRENCH: { code: 'fr', id: 6, title: 'Français' },
  LANGUAGE_SPANISH: { code: 'es', id: 99, title: 'Spanish' },
}
