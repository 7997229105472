import React, { FC, useCallback, useEffect, useState } from 'react'
import Positioning from '@components/organisms/Positioning'
import WelcomeBuffSelection from '@components/organisms/WelcomeBuffSelection'
import ThemeSelection from '@components/organisms/ThemeSelection'
import { useToast } from '@utils/hooks/useToast'
import { useParams } from 'react-router-dom'
import { IRouteParams } from '@services/providers/BuffProvider'
import {
  PositionConfig,
  StreamConfig,
  StreamConfigThemeFormValues,
} from '@interfaces/IStreamConfig'
import {
  getStreamConfigDefault,
  getStreamConfig,
  updateStreamConfig,
  updateStreamConfigDefault,
} from '@services/requests/stream-config'
import { IAppearanceProps } from './types'

const Appearance: FC<IAppearanceProps> = ({ isStreamConfigDefault }) => {
  const { id: streamId = '' } = useParams<IRouteParams>()
  const { addErrorToast } = useToast()
  const [streamConfig, setStreamConfig] = useState<StreamConfig>()
  const [positioningConfig, setPositioningConfig] = useState<PositionConfig[]>(
    []
  )

  const fetchStreamConfig = useCallback(async () => {
    try {
      const streamConfigResponse = await getStreamConfig(streamId)
      if (!streamConfigResponse) {
        return
      }
      const posConfig = streamConfigResponse.positionConfig
      setStreamConfig(streamConfigResponse)
      setPositioningConfig(posConfig)
    } catch (error) {
      addErrorToast(error)
      console.error(error)
    }
  }, [streamId, addErrorToast])

  const fetchStreamConfigDefaults = useCallback(async () => {
    try {
      const streamConfigResponse = await getStreamConfigDefault()
      if (!streamConfigResponse) {
        return
      }

      const posConfig = streamConfigResponse.positionConfig
      setPositioningConfig(posConfig)
      setStreamConfig(streamConfigResponse)
    } catch (error) {
      addErrorToast(error)
      console.error(error)
    }
  }, [addErrorToast])

  const handleRefetch = () => {
    isStreamConfigDefault ? fetchStreamConfigDefaults() : fetchStreamConfig()
  }

  useEffect(() => {
    isStreamConfigDefault ? fetchStreamConfigDefaults() : fetchStreamConfig()
  }, [
    addErrorToast,
    isStreamConfigDefault,
    streamId,
    fetchStreamConfigDefaults,
    fetchStreamConfig,
  ])

  const handleSubmit = async (values: StreamConfigThemeFormValues) => {
    try {
      if (isStreamConfigDefault) {
        await updateStreamConfigDefault({
          payload: {
            config: {
              theme: values.theme,
            },
          },
        })
        return
      }

      await updateStreamConfig({
        streamId: streamId,
        payload: {
          config: {
            theme: values.theme,
          },
        },
      })
    } catch (error) {
      addErrorToast(error)
      console.error(error)
    }
  }

  const handleUpdateStreamPositioning = async (
    values: Partial<PositionConfig>
  ) => {
    try {
      if (isStreamConfigDefault) {
        await updateStreamConfigDefault({
          payload: {
            positionConfig: [values as PositionConfig],
          },
        })
        return
      }

      await updateStreamConfig({
        streamId: streamId,
        payload: {
          positionConfig: [values as PositionConfig],
        },
      })
    } catch (error) {
      addErrorToast(error)
      console.error(error)
    }
  }

  return (
    <div data-testid="appearance">
      <div className="mb-4">
        <WelcomeBuffSelection
          welcomeBuffId={streamConfig?.config?.welcomeBuffId}
        />
      </div>
      <div className="mb-4">
        <Positioning
          positioningConfig={positioningConfig}
          onRefetch={handleRefetch}
          onSubmit={handleUpdateStreamPositioning}
        />
      </div>
      <div>
        <ThemeSelection onSubmit={handleSubmit} config={streamConfig?.config} />
      </div>
    </div>
  )
}

export default Appearance
