import { LanguageCode } from '@interfaces/ILanguage'
import { LocalisedContent } from '@interfaces/localisation'
import sortLocalisableContent from './sortLocalisableContent'

const formatBuffQuestionToSubmit = (
  question: Partial<Record<LanguageCode, string>>,
  streamLanguages: LanguageCode[] = []
): { localisations: LocalisedContent[] } => {
  const arr: LocalisedContent[] = []

  Object.keys(question).forEach((questionStr) => {
    const questionLang = questionStr as LanguageCode
    arr.push({
      text: question[questionLang] ?? '',
      localisation: questionLang,
    })
  })

  return {
    localisations: sortLocalisableContent(arr, streamLanguages),
  }
}

export default formatBuffQuestionToSubmit
