import React, { FC } from 'react'
import { PillColor } from '../../../constants/ui'

interface IProps {
  text: string
  color: PillColor

  /**
   * Optional class name to add
   */
  className?: string
}

const pillRed = {
  backgroundColor: 'var(--red)',
  color: 'white',
  fontWeight: 600,
}

const pillOrange = {
  backgroundColor: 'var(--orange)',
  color: 'white',
  fontWeight: 600,
}

const pillGray = {
  backgroundColor: 'var(--gray)',
  color: 'black',
  fontWeight: 400,
}

const pillDarkGray = {
  backgroundColor: '#444',
  color: 'white',
  fontWeight: 400,
}

const Pill: FC<React.PropsWithChildren<IProps>> = ({
  text,
  color,
  className = '',
}) => {
  const pillColor =
    color === PillColor.Red
      ? pillRed
      : color === PillColor.Orange
      ? pillOrange
      : color === PillColor.DarkGray
      ? pillDarkGray
      : pillGray

  return (
    <div
      data-testid="pill"
      className={`inline-block text-xs font-bold leading-none text-center whitespace-nowrap rounded min-w-[58px] p-2 uppercase ${className}`}
      style={{ ...pillColor }}
    >
      {text}
    </div>
  )
}

export default Pill
