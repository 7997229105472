import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Tab } from '@headlessui/react'
import { TabStyle, TabsProps } from './types'

const Tabs = ({
  children,
  tabs,
  selectedIndex,
  onTabChange,
  className = '',
  headerChildren,
  tabStyle = TabStyle.default,
  renderBorder = true,
}: TabsProps) => {
  return (
    <div className={`${className}`}>
      <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
        <div className={`flex justify-between items-center relative`}>
          {tabStyle === TabStyle.default && renderBorder && (
            <div className="absolute w-full h-[1.5px] bg-[#D1D5DA] bottom-0 left-0" />
          )}
          <Tab.List className="shrink-0">
            {tabs.map(({ text, href, disabled = false, id }, i) => {
              const key = id ?? 'tab' + i
              return (
                <Tab key={key} as={Fragment}>
                  {({ selected }) => {
                    let className = ''
                    const marginClassName = i + 1 === tabs.length ? '' : '!mr-6'

                    if (tabStyle === TabStyle.default) {
                      const fontClassName = selected ? 'font-bold' : ''
                      const borderColorClassName = selected
                        ? 'border-b-[#007bff]'
                        : 'border-b-transparent'
                      className = `inline-block relative px-0 pt-0 border-t-0 border-r-0 border-l-0 bg-transparent border-solid border-b-4 !pb-3 text-black z-10 ${marginClassName} ${borderColorClassName} ${fontClassName} focus:outline-0 hover:text-[#0056b3] disabled:text-[#6c757d]`
                    } else if (tabStyle === TabStyle.buttons) {
                      const bgClassName = selected
                        ? 'bg-[#CCD3FF]'
                        : 'bg-transparent'

                      const textClassName = selected
                        ? 'text-[#4C5687]'
                        : 'text-[#7D7D7D]'

                      const fontWeightClassName = selected
                        ? 'font-semibold'
                        : 'font-medium'
                      className = `inline-block !px-4 !py-1 text-sm whitespace-nowrap rounded-md focus:outline-none ${marginClassName} ${fontWeightClassName} ${textClassName} ${bgClassName}`
                    }

                    const sharedProps = {
                      className,
                    }

                    if (href) {
                      return (
                        <Link {...sharedProps} to={href}>
                          {text}
                        </Link>
                      )
                    } else {
                      return (
                        <button
                          {...sharedProps}
                          type="button"
                          disabled={disabled}
                        >
                          {text}
                        </button>
                      )
                    }
                  }}
                </Tab>
              )
            })}
          </Tab.List>
          {headerChildren}
        </div>
        <Tab.Panels>{children}</Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default Tabs
