import { MutableRefObject, ReactNode } from 'react'

export interface ModalProps {
  /**
   * Boolean to indicate if the modal is open
   */
  isOpen: boolean

  /**
   * Handler called when the modal should be closed
   */
  onClose?: () => void

  /**
   * Children to render in the component
   */
  children?: ReactNode

  /**
   * Custom className
   */
  className?: string

  /**
   * Custom content className
   */
  contentClassName?: string

  /**
   * Title to display at the top of the modal
   */
  title?: ReactNode

  /**
   * Size of the modal
   * @default ModalSize.SMALL
   */
  size?: ModalSize

  /**
   * Boolean to indicate whether to show/hide the header
   * @default false
   */
  hideHeader?: boolean

  /**
   * Boolean to indicate if the close button should be shown
   * @default true
   */
  displayCloseButton?: boolean

  /**
   * Ref that will be passed to the initial focusable element
   */
  initialFocusRef?: MutableRefObject<HTMLElement | null>

  /**
   * Selection ID for test purposes
   */
  'data-testid'?: string
}

export interface ModalBodyProps {
  /**
   * Custom className
   */
  className?: string

  /**
   * Children to render in the component
   */
  children?: ReactNode
}

export enum ModalSize {
  LARGE = 'large',
  SMALL = 'small',
}
