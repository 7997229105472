import React, { FC } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { tr } from '@constants/other'
import ScrollableContainer from '@components/atoms/ScrollableContainer'
import Breadcrumb from '@components/atoms/Breadcrumb'
import BreadcrumbItem from '@components/atoms/Breadcrumb/BreadcrumbItem'
import Button from '@components/atoms/Button'
import { IRouteParams } from '@interfaces/RouteParams'
import { ReactComponent as CopySVG } from '@assets/copy.svg'
import { ReactComponent as ExternalLinkSVG } from '@assets/external_link.svg'
import { copy } from '@utils/copy'

const accountName = process.env.ACCOUNT_NAME

const StreamDetails: FC<React.PropsWithChildren<unknown>> = (): JSX.Element => {
  const { id } = useParams<IRouteParams>()
  return (
    <ScrollableContainer>
      <div
        className="flex items-center flex-1 mx-auto mb-0 mt-2 w-[998px] gap-x-3"
        data-testid="stream-details"
      >
        <Breadcrumb>
          <BreadcrumbItem>{tr({ id: 'generic.streams' })}</BreadcrumbItem>
          <BreadcrumbItem active>
            {!id ? tr({ id: 'stream.newStream' }) : id}
          </BreadcrumbItem>
        </Breadcrumb>

        {!!id && (
          <div className="flex items-center gap-x-2">
            <Button
              startElement={<CopySVG className="w-4 h-4 mr-1" />}
              variant="secondary"
              size="mini"
              onClick={() => copy(id)}
            >
              {tr({ id: 'generic.copyID' })}
            </Button>

            <Button
              target="_blank"
              href={`https://play.sportbuff.com?clientName=${accountName}&streamId=${id}`}
              startElement={<ExternalLinkSVG className="w-4 h-4 mr-1" />}
              variant="secondary"
              size="mini"
              className="hover:text-inherit"
            >
              {tr({ id: 'generic.openWebPreview' })}
            </Button>
          </div>
        )}
      </div>

      <Outlet />
    </ScrollableContainer>
  )
}

export default StreamDetails
