import React, { FC } from 'react'
import { tr } from '@constants/other'
import AuthorForm, { AuthorFormValues } from '@components/organisms/AuthorForm'
import ScrollableContainer from '@components/atoms/ScrollableContainer'
import { Author, CreateAuthor } from '@interfaces/Author'

interface IProps {
  author?: Author
  handleSubmit: (values: CreateAuthor) => Promise<void>
  handleDelete?: (id: string) => void
}

const AuthorDetails: FC<React.PropsWithChildren<IProps>> = ({
  author,
  handleSubmit,
  handleDelete,
}): JSX.Element => {
  const formInitialValues: AuthorFormValues = {
    displayName: author?.displayName ?? '',
    profilePicture: author?.profilePicture ?? '',
  }

  return (
    <ScrollableContainer>
      <div
        data-testid="author-details-container"
        className="flex flex-col p-5 items-center w-full"
        style={{ backgroundColor: 'var(--white-background)' }}
      >
        <div>
          <h3 className="h3 ml-2 font-normal">
            {author
              ? `${tr({ id: 'generic.edit' })}`
              : tr({ id: 'author.newAuthor' })}
          </h3>
          <div
            data-testid="author-details-card"
            className="flex flex-col p-4 m-2 rounded"
            style={{
              backgroundColor: 'white',
              boxShadow: '0 5px 15px 0 rgba(9, 14, 37, 0.1)',
              minWidth: 900,
            }}
          >
            <AuthorForm
              initialValues={formInitialValues}
              selectedAuthor={author?.id}
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
            />
          </div>
        </div>
      </div>
    </ScrollableContainer>
  )
}

export default AuthorDetails
