import React, { createContext, ReactNode, useCallback } from 'react'
import { v4 } from 'uuid'
import { Color } from '@constants/ui'
import { ToastContainer, toast as toastify } from 'react-toastify'
import CustomToaster from '@components/molecules/CustomToaster'
import { getErrorMessage } from '@utils/error'
import { tr } from '@constants/other'
import Button from '@components/atoms/Button'

import 'react-toastify/dist/ReactToastify.css'

type CreateToast = Omit<Toast, 'id'>

export interface Toast {
  msg: string
  type: 'error' | 'success' | 'warning'
  image?: ReactNode
  duration?: number
  id: string
}

const colorMap = {
  error: Color.Danger,
  warning: Color.Warning,
  success: Color.Success,
} as const

interface ToastContextProps {
  addToast: (toast: CreateToast) => string
  addErrorToast: (error: unknown) => void
  removeToast: (toastId: string) => void
}

interface ToastProviderProps {
  children: ReactNode
}

export const ToastContext = createContext<ToastContextProps>(
  {} as ToastContextProps
)

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const sendToast = (toast: Toast) => {
    const defaultImage =
      toast.type === 'error'
        ? '/assets/icon-error-circle.png'
        : '/assets/icon-send.png'
    const props = {
      text: toast.msg,
      color: colorMap[toast.type],
      show: true,
      image: toast.image ?? defaultImage,
      duration: toast?.duration ?? 7000,
    }

    toastify(<CustomToaster {...props} key={toast.id} />, {
      position: 'bottom-left',
      hideProgressBar: true,
      className: 'p-0 relative',
      bodyClassName: 'p-0',
    })
  }

  const addToast = useCallback((toast: CreateToast) => {
    const id = v4()
    const newToast = { ...toast, id }

    sendToast(newToast)

    return id
  }, [])

  const addErrorToast = useCallback((error: unknown) => {
    const msg = getErrorMessage(error)
    const id = v4()
    const toast: Toast = { msg, type: 'error', id }

    sendToast(toast)

    return id
  }, [])

  const removeToast = (id: string) => {
    toastify.dismiss(id)
  }

  const props = {
    addToast,
    addErrorToast,
    removeToast,
  }

  return (
    <ToastContext.Provider value={props}>
      {children}
      <ToastContainer
        closeButton={() => (
          <Button className="absolute right-0 !p-2" variant="neutral">
            <img
              data-testid="close-image"
              width={26}
              height={26}
              src="/assets/icon-close.png"
              srcSet="/assets/icon-close@2x.png 2x,
         /assets/icon-close@3x.png 3x"
              alt={tr({ id: 'toast.close' })}
            />
          </Button>
        )}
      />
    </ToastContext.Provider>
  )
}
