import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import { languagesMap } from './languagesMap'
import { LanguageCode } from '@interfaces/ILanguage'
import { LocalisedContent } from '@interfaces/localisation'

interface GetLocalisationsArgs<T> {
  localisations: T[]
  languages: LanguageCode[]
}

interface GetLocalisedContentArgs<T> {
  localisations: T[]
  language: LanguageCode
}

const getLocalisations = <T extends LocalisedContent>({
  localisations,
  languages,
}: GetLocalisationsArgs<T>): T | undefined => {
  if (!localisations || !languages) return
  const locals = find(localisations, (localisation) => {
    return languages.includes(localisation.localisation)
  })

  if (locals) return locals

  return sortBy(localisations, (l) => languagesMap[l.localisation]?.id)?.[0]
}

export const getLocalisedContent = <T extends LocalisedContent>({
  localisations,
  language,
}: GetLocalisedContentArgs<T>): T | undefined => {
  if (!localisations || !language) return
  const content = find(localisations, (localisation) => {
    return localisation.localisation === language
  })

  return content
}

export default getLocalisations
