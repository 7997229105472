import React, { FC, useMemo, MouseEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IRouteParams } from '@interfaces/RouteParams'
import { useVodClient } from '@services/requests/vodV2'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'
import Container from '@components/atoms/Container'
import { BuffType, tr } from '@constants/other'
import Breadcrumb from '@components/atoms/Breadcrumb'
import BreadcrumbItem from '@components/atoms/Breadcrumb/BreadcrumbItem'
import Button from '@components/atoms/Button/Button'
import { ReactComponent as PlaySVG } from '@assets/play.svg'
import { ReactComponent as EmptyVodBuffsSVG } from '@assets/empty-vod-buffs.svg'
import { ReactComponent as EditSVG } from '@assets/edit.svg'
import { ReactComponent as TrashSVG } from '@assets/trash.svg'
import VodPreview from '@components/molecules/VodPreview'
import { DataTable } from '@components/molecules/DataTable'
import TooltipPill from '@components/molecules/TooltipPill'
import TruncatedTooltipText from '@components/atoms/TruncatedTooltipText'
import { ColumnDef } from '@tanstack/react-table'
import { VodContent } from '@interfaces/VodV2'
import { msToHHMMSS } from '@utils/time'
import { isAnnouncement } from '@utils/voteables'
import { getMetadataFromBuffType } from '@utils/buffTypeMappings'
import { queryClient } from '@utils/reactQuery/client'
import { useMutation } from 'react-query'
import { useToast } from '@utils/hooks/useToast'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { ButtonVariant } from '@components/atoms/Button'
import { Alignment } from '@components/molecules/ConfirmationModal/types'
import { Voteable } from '@interfaces/Voteable'

const VodEdit: FC<React.PropsWithChildren> = () => {
  const params = useParams<IRouteParams>()
  const navigate = useNavigate()
  const { id: vodId } = params

  const [selected, setSelected] = useState<VodContent>()
  const [vodContentToDelete, setVodContentToDelete] = useState<VodContent>()

  const { addErrorToast } = useToast()

  const { useGetVod, useGetVodContent, deleteVodContent } = useVodClient()

  const { data: vod } = useGetVod(vodId, { enabled: !!vodId })
  const { data: vodContent } = useGetVodContent(vodId, {
    enabled: !!vodId,
  })

  const { mutate: deleteVodContentMutation } = useMutation(deleteVodContent, {
    onSuccess: () => {
      queryClient.invalidateQueries(['vod', vodId, 'content'])
    },
    onError: (err) => {
      addErrorToast(err)
    },
  })

  const handleImportBuff = () => {
    navigate(`/vod/${vodId}/import`)
  }

  const handlePublishVod = () => {}

  const membersColumns = useMemo<ColumnDef<VodContent>[]>(() => {
    const handleDelete = (
      e: MouseEvent<HTMLButtonElement>,
      vod: VodContent
    ) => {
      setVodContentToDelete(vod)
      e.stopPropagation()
    }

    const handleEdit = (e: MouseEvent<HTMLButtonElement>, vod: VodContent) => {
      e.stopPropagation()
      if (vod.contentType === 'ANNOUNCEMENT') {
        navigate(`/vod/${vodId}/announcements/${vod.id}`)
      } else {
        navigate(`/vod/${vodId}/buff/${vod.id}`)
      }
    }

    const handleClick = (vod: VodContent) => {
      setSelected(vod)
    }

    return [
      {
        accessorKey: 'no',
        header: 'No',
        cell: (info) => {
          // TODO: Hack to fix lint issue, we have to fix this once we have real API
          const buff = info.row.original as unknown as Voteable
          const { id: buffType } = !isAnnouncement(buff)
            ? getMetadataFromBuffType({
                lifecycle: buff.lifecycle,
                answerInterface: buff.answerInterface,
              })
            : {
                id: BuffType.Announcement,
              }

          return (
            <div
              onClick={() => handleClick(info.row.original)}
              className="flex items-center justify-center gap-x-1 relative"
            >
              <div
                className="w-[2px] h-[36px] absolute left-0"
                style={{ backgroundColor: `var(--buff-type-${buffType})` }}
              />
              <span className="text-base">{info.row.index + 1}</span>
            </div>
          )
        },
        meta: {
          headerClassname: 'text-center',
          rowClassname: selected ? '!bg-[#E3F0FF]' : '',
        },
      },
      {
        accessorKey: 'name',
        header: 'Question',
        enableSorting: false,
        cell: (info) => {
          const language = info.row.original.languages[0]
          const question =
            info.row.original.localisations[language]?.content.text
          return (
            <>
              <div
                onClick={() => handleClick(info.row.original)}
                className="flex items-center gap-x-1"
              >
                <TruncatedTooltipText className="max-w-[250px]">
                  {`${question || ''}`}
                </TruncatedTooltipText>
              </div>
            </>
          )
        },
      },
      {
        accessorKey: 'publish',
        header: 'Publish time',
        enableSorting: false,
        cell: (info) => (
          <>
            <div
              onClick={() => handleClick(info.row.original)}
              className="flex items-center gap-x-1"
            >
              <span className="text-base">
                {msToHHMMSS(info.row.original.showAt * 1000)}
              </span>
            </div>
          </>
        ),
      },
      {
        accessorKey: 'expiry',
        header: 'Expiry time',
        enableSorting: false,
        cell: (info) => (
          <>
            <div
              onClick={() => handleClick(info.row.original)}
              className="flex items-center gap-x-1"
            >
              <span className="text-base">
                {msToHHMMSS(
                  (info.row.original.showAt + info.row.original.showFor) * 1000
                )}
              </span>
            </div>
          </>
        ),
      },
      {
        accessorKey: 'resolve',
        header: 'Resolve time',
        enableSorting: false,
        cell: (info) => (
          <>
            <div
              onClick={() => handleClick(info.row.original)}
              className="flex items-center gap-x-1"
            ></div>
          </>
        ),
      },
      {
        accessorKey: 'actions',
        header: '',
        enableSorting: false,
        cell: (info) => {
          const vod = info.row.original

          const { name } = !isAnnouncement(vod)
            ? getMetadataFromBuffType({
                lifecycle: (vod as any).lifecycle,
                answerInterface: (vod as any).answerInterface,
              })
            : {
                name: tr({
                  id: 'category.announcement',
                }),
              }

          return (
            <div className="flex items-center justify-center gap-2">
              <TooltipPill
                showTooltip
                className="rounded-sm bg-transparent text-[#00f] hover:!bg-[#F3F4F5] p-1.5"
                text={<EditSVG className="text-[#007BFF] w-4 h-4" />}
                onClick={(e) => handleEdit(e, vod)}
              >
                <span>
                  {tr({ id: 'generic.edit' })} - {name}
                </span>
              </TooltipPill>
              <TooltipPill
                showTooltip
                className="rounded-sm bg-transparent text-[#00f] hover:!bg-[#F3F4F5] p-1.5"
                text={<TrashSVG className="text-[#007BFF] w-4 h-4" />}
                onClick={(e) => handleDelete(e, vod)}
              >
                {tr({ id: 'generic.delete' })}
              </TooltipPill>
            </div>
          )
        },
      },
    ]
  }, [vodContent, selected])

  return (
    <>
      <Container>
        <Row className="pl-2">
          <Col sm={12}>
            <div className="flex flex-col">
              <div className="flex h-6 items-center mt-3 mb-4">
                <Breadcrumb>
                  <BreadcrumbItem href={`/vod/${vodId}`}>
                    {tr({ id: 'generic.vod' })} - {vod?.name}
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    {tr({ id: 'vod.editVod' })}
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between items-center">
                  <h2 className="font-bold mr-2.5">
                    {tr({ id: 'generic.edit' })} - {vod?.name}
                  </h2>
                  <Button
                    onClick={handlePublishVod}
                    disabled
                    variant="success"
                    startElement={
                      <PlaySVG className="text-white h-6 w-6 -ml-2 mr-2" />
                    }
                  >
                    {tr({ id: 'vod.publishVod' })}
                  </Button>
                </div>
                <p>{tr({ id: 'vod.editYourBuffs' })}</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pl-2">
          <Col sm={7}>
            <div className="mt-1 mb-2 p-3 bg-white rounded min-h-[500px]">
              <div className="flex items-center gap-x-4 mb-4">
                <Button href={`/vod/${vodId}/buff/new`} variant="primary">
                  {tr({ id: 'vod.createBuff' })}
                </Button>
                <Button variant="secondary" onClick={handleImportBuff}>
                  {tr({ id: 'vod.importBuff' })}
                </Button>
              </div>

              {!vodContent?.length && (
                <div className="flex flex-col items-center justify-center text-[#586069] mt-32">
                  <EmptyVodBuffsSVG />
                  <p className="font-semibold my-1">
                    {tr({ id: 'vod.noBuffsAdded' })}
                  </p>
                  <p>{tr({ id: 'vod.noBuffsAddedDescription' })}</p>
                </div>
              )}

              {!!vodContent?.length && (
                <DataTable
                  tableData={vodContent ?? []}
                  columns={membersColumns}
                />
              )}
            </div>
          </Col>
          <Col sm={5}>
            <div className="mt-1 p-3 bg-white sticky top-6 rounded">
              <VodPreview vod={vod} selected={selected} />
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmationModal
        show={!!vodContentToDelete}
        onClose={() => setVodContentToDelete(undefined)}
        onSuccess={() => {
          if (!!vodContentToDelete) {
            deleteVodContentMutation({
              vodId: vodId ?? '',
              entityId: vodContentToDelete.id,
            })
          }
        }}
        variant={ButtonVariant.Danger}
        alignment={Alignment.END}
        title={tr({ id: 'vod.deleteVODContentTitle' })}
        subtext={tr({ id: 'vod.deleteVODContentSubtitle' })}
        successBtnText={tr({ id: 'vod.deleteVODContentConfirm' })}
      />
    </>
  )
}

export default VodEdit
