import React from 'react'
import { tr } from '@constants/other'
import { ReactComponent as StatsIcon } from '@assets/stats.svg'
import { ReactComponent as ChatIcon } from '@assets/chat-icon-filled.svg'
import { ReactComponent as ChatAvgIcon } from '@assets/chat-icon-avg.svg'
import { ReactComponent as PersonIcon } from '@assets/person.svg'
import Button from '@components/atoms/Button'
import EmptyStateWithIcon from '@components/molecules/EmptyStateWithIcon'
import { queryClient } from '@utils/reactQuery/client'
import { useParams } from 'react-router-dom'
import { IRouteParams } from '@interfaces/RouteParams'
import { useChat } from '@services/requests/chat'
import MetricsCard from '@components/atoms/MetricsCard'

const ChatMetrics = () => {
  const { id: streamId = '' } = useParams<IRouteParams>()
  const { useGetChatMetrics } = useChat()
  const { data: chatMetrics } = useGetChatMetrics(streamId)
  const averageMessagesPerUser = chatMetrics?.total?.messages
    ? Math.round(chatMetrics.total.messages / chatMetrics.total.users)
    : 0

  const refreshMetrics = () => {
    queryClient.invalidateQueries(['chat-metrics', streamId])
  }

  return (
    <div className="p-4 rounded-lg bg-white-background">
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <h1 className="m-0 text-xl font-bold">
            {tr({ id: 'generic.metrics' })}
          </h1>

          <div className="flex items-center gap-x-3">
            <Button type="button" variant="secondary" onClick={refreshMetrics}>
              {tr({ id: 'generic.refresh' })}
            </Button>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          {!!chatMetrics?.total?.users && (
            <MetricsCard
              value={chatMetrics.total.users}
              text={tr({ id: 'globalChat.totalUsers' })}
              icon={<PersonIcon className="w-10 h-10 text-[#007bff]" />}
            />
          )}
          {!!chatMetrics?.total?.messages && (
            <MetricsCard
              value={chatMetrics.total.messages}
              text={tr({ id: 'globalChat.totalMessages' })}
              icon={<ChatIcon className="w-10 h-10 mt-2 text-[#007bff]" />}
            />
          )}
          {!!averageMessagesPerUser && (
            <MetricsCard
              value={averageMessagesPerUser}
              text={tr({ id: 'globalChat.averageMessages' })}
              icon={
                <ChatAvgIcon
                  strokeWidth={'2'}
                  className="w-10 h-10 mt-2 text-[#007bff]"
                />
              }
            />
          )}
        </div>
        {!chatMetrics?.total?.messages && (
          <EmptyStateWithIcon
            containerClasses="!h-[350px] mt-10"
            icon={<StatsIcon className="w-10 text-white" />}
            text={tr({ id: 'generic.emptyMetrics' })}
          />
        )}
      </div>
    </div>
  )
}

export default ChatMetrics
