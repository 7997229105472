import React, { createContext, FC, useState } from 'react'
import { AxiosError } from 'axios'
import { NamedException } from '@utils/error'

interface ModalError {
  title: string
  description: string
}

interface ErrorContextProps {
  error: unknown | AxiosError | NamedException | null
  setError: (error: unknown | AxiosError | NamedException | null) => void
  apiError: unknown | AxiosError | null
  setApiError: (error: unknown | AxiosError | null) => void

  modalError?: ModalError
  setModalError: (error?: ModalError) => void

  successToast: string
  setSuccessToast: (toast: string) => void
}

const ErrorContext = createContext<ErrorContextProps>({} as ErrorContextProps)
const { Provider } = ErrorContext

const ErrorProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [error, setError] = useState<
    unknown | AxiosError | NamedException | null
  >(null)
  const [apiError, setApiError] = useState<unknown | AxiosError | null>(null)

  const [modalError, setModalError] = useState<ModalError>()
  const [successToast, setSuccessToast] = useState<string>('')

  return (
    <Provider
      value={{
        modalError,
        setModalError,
        error,
        setError,
        apiError,
        setApiError,
        successToast,
        setSuccessToast,
      }}
    >
      {children}
    </Provider>
  )
}

export { ErrorContext, ErrorProvider }
