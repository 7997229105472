export enum ReactionsPosition {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
}

export interface LiveReactionsFormValues {
  enabled: boolean
  direction: ReactionsPosition
  availableReactions: LiveReactionAsset[]
}

export interface LiveReactionAsset {
  id: string
  imageUrl: string
}

export interface LiveReactions {
  enabled: boolean
  direction: ReactionsPosition
  availableReactions: LiveReactionAsset[]
}

export interface LiveReactionsMetric extends LiveReactionAsset {
  count: number
}

export interface EmojiItem extends LiveReactionsMetric {
  percent: number
}
