import { client } from '@services/requests/client'
import { BatchUser } from '@interfaces/IUser'
import { BUFFUP_DASHBOARD_TOKEN, BUFFUP_REFRESH_TOKEN } from '@constants/other'
import { QueryKey } from '@utils/reactQuery/types'
import { UseQueryOptions } from 'react-query/types/react/types'
import { useFetch } from '@utils/reactQuery/useFetch'
import { Role } from '@pages/TeamMembers/types'
import { useMutation, useQueryClient } from 'react-query'
import { InviteUser } from '@components/molecules/InviteUserModal'

export interface Invitation {
  email: string
  role: Role
  firstName: string
  lastName: string
  expiresAt: string
  token: string
}

interface InvitationResponse {
  invitations: Invitation[]
}

/**
 * Send OTP to given email
 * @param {string} email
 * @return {Promise<void>}
 */
export const sendOTP = async (email: string): Promise<void> => {
  const response = await client.post('/auth/otp/email', {
    email: email?.trim(),
  })

  return response?.data
}

/**
 * Login with otp & email
 * @param {string} otp
 * @param {string} email
 * @return {Promise<string>}
 */
export const loginWithOTP = async (
  otp: string,
  email: string
): Promise<string> => {
  const { data } = await client.post('/auth/login?scope=dashboard', {
    otp,
    email: email?.trim(),
  })

  localStorage.setItem(BUFFUP_REFRESH_TOKEN, data.refreshToken)
  const token = await refreshJwtToken()

  return token
}

/**
 * Refresh token
 */
export const refreshJwtToken = async () => {
  const refreshToken = localStorage.getItem(BUFFUP_REFRESH_TOKEN)

  if (!refreshToken) {
    window.dispatchEvent(new Event('expiredRefreshToken'))
    return
  }

  try {
    const { data } = await client.post(
      '/auth/token-exchange',
      {
        refreshToken,
      },
      {
        headers: {
          // TODO: Why this complaining?
          // @ts-ignore
          bypass401: true,
        },
      }
    )

    if (!data.token) {
      localStorage.removeItem(BUFFUP_DASHBOARD_TOKEN)
      localStorage.removeItem(BUFFUP_REFRESH_TOKEN)
    }

    localStorage.setItem(BUFFUP_DASHBOARD_TOKEN, data?.token)

    return data?.token
  } catch (error) {
    localStorage.removeItem(BUFFUP_DASHBOARD_TOKEN)
    localStorage.removeItem(BUFFUP_REFRESH_TOKEN)
    throw error
  }
}

/**
 * Fetch pubnub token
 */
export const getPubnubToken = async () => {
  const response = await client.post(`/friend-groups/pubnub/token-exchange`)

  return response.data?.token
}

export const moderateUser = async (userId: string) => {
  const response = await client.patch<BatchUser>(`/users/${userId}/moderate`)
  return response.data
}

export const unModerateUser = (userId: string) => {
  return client.delete(`/users/${userId}/moderate`)
}

export const useGetInvites = <T = InvitationResponse>(
  params?: UseQueryOptions<T, Error, T, QueryKey>
) => {
  return useFetch<T>({
    url: `/auth/invitations?pagination.pageSize=1000`,
    key: 'auth/invitations',
    config: params,
  })
}

export const useInviteUser = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (data: InviteUser) => client.post(`/auth/invitations`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/invitations'])
      },
    }
  )
}

export const useResendInvite = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (token: string) => client.post(`/auth/invitations/${token}/resend`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/invitations'])
      },
    }
  )
}

export const useDeleteInvite = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (token: string) => client.delete(`/auth/invitations/${token}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/invitations'])
      },
    }
  )
}
