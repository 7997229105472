import * as Yup from 'yup'
import { tr } from '@constants/other'
import { hmsStringToSeconds } from '@utils/time'

export const schema = Yup.object().shape({
  openedAtSeconds: Yup.string()
    .required(tr({ id: 'validation.required' }))
    .matches(/\d{3}:\d{2}:\d{2}/, tr({ id: 'validation.expiryHHMMSSFormat' }))
    .test('min-number', tr({ id: 'validation.notLessThanZero' }), (value) => {
      if (!value) return false
      const opensAt = hmsStringToSeconds(value)
      return opensAt > 0
    }),
  closedAtSeconds: Yup.string()
    .required(tr({ id: 'validation.required' }))
    .matches(/\d{3}:\d{2}:\d{2}/, tr({ id: 'validation.expiryHHMMSSFormat' }))
    .test(
      'is-bigger-than-publish',
      tr({ id: 'validation.publishExpiryGap' }),
      (value, context) => {
        if (!value) return false
        const { openedAtSeconds } = context.parent
        const minClosedAt = hmsStringToSeconds(value)
        return minClosedAt >= hmsStringToSeconds(openedAtSeconds)
      }
    ),
})

export const schemaWithResolveTime = Yup.object()
  .shape({
    resolvedAtSeconds: Yup.string()
      .required(tr({ id: 'validation.required' }))
      .matches(/\d{3}:\d{2}:\d{2}/, tr({ id: 'validation.expiryHHMMSSFormat' }))
      .test(
        'is-bigger-than-expire',
        tr({ id: 'validation.resolvedExpiryGap' }),
        (value, context) => {
          if (!value) return false
          const { closedAtSeconds } = context.parent
          const minResolvedAt = hmsStringToSeconds(value)
          return minResolvedAt >= hmsStringToSeconds(closedAtSeconds)
        }
      ),
  })
  .concat(schema)
