import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { DEFAULT_LOCALE } from '@constants/other'
import { flattenMessages, getLocale, localMessages } from './locales'
import { IntlProvider } from 'react-intl'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/react'
import { MessagingProvider } from '@services/providers/MessagingProvider'
import { PubnubProvider } from '@services/providers/PubnubProvider'
import { queryClient } from '@utils/reactQuery/client'
import Routes from './Routes'

import 'bootstrap/dist/css/bootstrap.min.css'
import './resources/css/main.css'
import './resources/sass/main.scss'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

const locale: string = getLocale(localMessages, DEFAULT_LOCALE)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <IntlProvider
    locale={locale}
    messages={flattenMessages(localMessages[locale]) as any}
  >
    <QueryClientProvider client={queryClient}>
      <PubnubProvider>
        <MessagingProvider>
          <Sentry.ErrorBoundary>
            <Router>
              <Routes />
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
          </Sentry.ErrorBoundary>
        </MessagingProvider>
      </PubnubProvider>
    </QueryClientProvider>
  </IntlProvider>
)
