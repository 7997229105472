import { tr } from '@constants/other'
import { Message } from '@pubnub/chat'
import Pubnub from 'pubnub'

export const softDeleteMessage = async (
  pubnub: Pubnub,
  channel: string,
  messageTimetoken: string
) => {
  const response = await pubnub.addMessageAction({
    channel,
    messageTimetoken,
    action: {
      type: 'deleted',
      value: '.',
    },
  })
  if (response) {
    return response.data
  }
  throw new Error(tr({ id: 'globalChat.failedToRemoveMessage' }))
}

export const starMessage = async (
  pubnub: Pubnub,
  channel: string,
  messageTimetoken: string
) => {
  const response = await pubnub.addMessageAction({
    channel,
    messageTimetoken,
    action: {
      type: 'starred',
      value: 'data',
    },
  })
  if (response) {
    return response.data
  }
  throw new Error(tr({ id: 'globalChat.failedToStarMessage' }))
}

export const addMessageAction = async (
  pubnub: Pubnub,
  channel: string,
  messageTimetoken: string,
  data: any
) => {
  const response = await pubnub.addMessageAction({
    channel,
    messageTimetoken,
    action: data,
  })
  if (response) {
    return response.data
  }
  throw new Error(tr({ id: 'globalChat.failedToStarMessage' }))
}

export const removeMessageAction = async (
  pubnub: Pubnub,
  channel: string,
  messageTimetoken: string,
  actionTimetoken: string
) => {
  const response = await pubnub.removeMessageAction({
    channel,
    messageTimetoken,
    actionTimetoken: actionTimetoken,
  })
  if (response) {
    return response.data
  }
  throw new Error(tr({ id: 'errors.5009' }))
}

export const removeMessageActions = async (
  pubnub: Pubnub,
  channel: string,
  message: Message,
  key: string
) => {
  try {
    const actions = Object.keys(message?.actions?.[key] || {})
    actions.forEach(async (actionKey) => {
      const action = message?.actions?.[key]?.[actionKey]?.[0]?.actionTimetoken
      if (action) {
        await removeMessageAction(
          pubnub,
          channel,
          message.timetoken,
          String(action)
        )
      }
    })
  } catch (err) {}
}

export const unstarMessage = async (
  pubnub: Pubnub,
  channel: string,
  messageTimetoken: string,
  actionTimetoken: string
) => {
  const response = await pubnub.removeMessageAction({
    channel,
    messageTimetoken,
    actionTimetoken,
  })
  if (response) {
    return response.data
  }
  throw new Error(tr({ id: 'globalChat.failedToUnstarMessage' }))
}
