import React, { ChangeEvent, FC } from 'react'
import { Asset, AssetClass } from '@interfaces/Assets'
import TextInput from '@components/atoms/TextInput'
import { tr } from '@constants/other'
import Image from '@components/atoms/Image'
import { ReactComponent as PlaceholderSVG } from '@assets/placeholder.svg'
import { ReactComponent as MusicNoteSVG } from '@assets/music-note-filled.svg'

interface UpdateAssetItemProps {
  asset: Asset
  title?: string
  onInputUpdate: (
    evt: ChangeEvent<HTMLInputElement>,
    id: string,
    inputName: string
  ) => void
}

const UpdateAssetItem: FC<React.PropsWithChildren<UpdateAssetItemProps>> = ({
  asset,
  title = tr({ id: 'assetManager.imageName' }),
  onInputUpdate,
}) => {
  const assetImageUrl = asset?.publicURL + asset?.fileName

  return (
    <div className="my-2 p-4 flex relative rounded bg-white">
      <div className="flex">
        <div className="mr-3">
          {asset?.class === AssetClass.BuffSound ? (
            <div className="bg-[#F3F4F5] rounded w-[68px] h-[76px] flex items-center justify-center">
              <MusicNoteSVG className="w-[90%] text-[#A1A1A1]" />
            </div>
          ) : (
            <Image
              alt="asset"
              className="rounded w-[68px] h-[76px]"
              src={assetImageUrl}
              fallbackComponent={
                <PlaceholderSVG className="rounded w-[68px] h-[76px]" />
              }
            />
          )}
        </div>
        <div>
          <TextInput
            label={title}
            value={asset?.title}
            onChange={(e) => onInputUpdate(e, asset.id, 'title')}
            labelClasses="font-semibold"
          />
        </div>
        <div className="ml-4">
          <TextInput
            label={tr({ id: 'assetManager.description' })}
            value={asset?.description ?? ''}
            onChange={(e) => onInputUpdate(e, asset.id, 'description')}
            labelClasses="font-semibold"
          />
        </div>
      </div>
    </div>
  )
}

export default UpdateAssetItem
