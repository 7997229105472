import { Announcement } from '@interfaces/Announcement'
import { AnnouncementTemplate, Template } from '@interfaces/Template'
import { VodVoteable } from '@interfaces/Vod'
import { Voteable } from '@interfaces/Voteable'

export const isVoteable = (buff: unknown): buff is Voteable => {
  return (buff as Voteable)?.voteableId !== undefined
}

export const isVodVoteable = (
  buff: Voteable | VodVoteable | Announcement
): buff is VodVoteable => {
  return (buff as VodVoteable).id !== undefined
}

export const isAnnouncement = (buff: unknown): buff is Announcement => {
  return (buff as Announcement)?.announcementId !== undefined
}

export const isTemplateVoteable = (buff: unknown): buff is Template => {
  return (buff as Template)?.answerInterface !== undefined
}

export const getBuffId = (
  buff: Voteable | Announcement | VodVoteable
): string => {
  if (isVoteable(buff)) return buff.voteableId
  if (isAnnouncement(buff)) return buff.announcementId
  if (isVodVoteable(buff)) return buff.id
  return ''
}
