import React from 'react'
import { ReactComponent as EditSVG } from '@assets/edit.svg'
import { ReactComponent as ClockSVG } from '@assets/clock.svg'
import { ReactComponent as BitsSVG } from '@assets/bits.svg'
import { tr } from '@constants/other'
import { MonetisationCurrency } from '@interfaces/Leaderboard'
import TooltipPill from '@components/molecules/TooltipPill'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { format, utcToZonedTime } from 'date-fns-tz'
import { PaymentItemProps } from './types'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const PaymentItem = ({
  'data-testid': testId = 'payment-item',
  payment,
  className = '',
  onEdit,
}: PaymentItemProps) => {
  const costPrefix =
    payment.currency === MonetisationCurrency.BITS ? (
      <BitsSVG className="w-4 h-4" />
    ) : null
  const cost =
    payment.currency === MonetisationCurrency.BITS
      ? payment.cost
      : formatter.format(payment.cost)

  const dateInUserTimezone = payment?.availableUntil
    ? new Date(payment?.availableUntil)
    : undefined

  const formattedDate = dateInUserTimezone
    ? format(dateInUserTimezone, 'yyyy/MM/dd - HH:mm')
    : '-'

  const dateNowMS = new Date().getTime()
  const availableUntilMS = payment?.availableUntil
    ? new Date(payment?.availableUntil).getTime()
    : undefined

  const isExpired = availableUntilMS ? dateNowMS > availableUntilMS : false

  return (
    <div
      className={`flex flex-col gap-y-4 border border-[#E1E4E8] min-h-[100px] justify-between px-3 pb-3 pt-2 rounded bg-white ${className}`}
      data-testid={testId}
    >
      <div className="flex items-center justify-between">
        <div
          className={`flex items-center ${
            isExpired ? 'text-[#959DA5]' : 'text-black'
          } font-semibold gap-x-1`}
        >
          {costPrefix}
          <p data-testid={`${testId}__cost`} className="lowercase m-0">
            {cost}
          </p>
        </div>

        {isExpired ? (
          <TooltipPill
            className={`px-1.5 flex-shrink-0 bg-[#FCF1F0] text-[#B42318] font-semibold`}
            text={tr({ id: 'generic.expired' })}
          />
        ) : (
          <TooltipPill
            data-testid="payment-item__edit-button"
            showTooltip
            className="h-9 w-9 rounded-sm bg-[#fff] hover:bg-[#EBEBEB]"
            text={<EditSVG className="text-[#007BFF] w-4 h-4" />}
            onClick={() => onEdit(payment)}
          >
            {tr({ id: 'leaderboard.editExpiryDate' })}
          </TooltipPill>
        )}
      </div>

      <div className="flex items-center justify-between">
        <p
          data-testid={`${testId}__bonus-points`}
          className={`lowercase m-0  ${
            !isExpired ? 'text-[#717A85]' : 'text-[#959DA5]'
          }`}
        >
          {payment.bonusPoints || 0} {tr({ id: 'leaderboard.points' })}
        </p>
        {!!payment?.availableUntil && (
          <Tooltip placement="bottom">
            <TooltipTrigger>
              <div
                className={`flex items-center ${
                  isExpired ? 'text-[#959DA5]' : 'text-[#586069]'
                }  gap-x-1 text-sm`}
              >
                <ClockSVG className="w-4 h-4" />
                <span data-testid={`${testId}__expiry-date`}>
                  {formattedDate}
                </span>
              </div>
            </TooltipTrigger>
            {isExpired ? undefined : (
              <TooltipContent>
                <span>
                  {tr(
                    { id: 'leaderboard.paymentWillExpire' },
                    {
                      value: format(
                        utcToZonedTime(payment?.availableUntil, 'UTC'),
                        'dd MMM yyyy, HH:mm',
                        { timeZone: 'UTC' }
                      ),
                    }
                  )}
                </span>
              </TooltipContent>
            )}
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default PaymentItem
