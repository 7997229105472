import React, { FC, MouseEvent, ReactElement, ReactNode } from 'react'
import { ReactComponent as TrashSVG } from '@assets/trash.svg'
import ConditionalWrapper from '@components/atoms/ConditionalWrapper'
import Image from '@components/atoms/Image'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { ImageFieldButtonProps } from './types'
import './ImageFieldButton.styles.scss'

const ImageFieldButton: FC<React.PropsWithChildren<ImageFieldButtonProps>> = ({
  image,
  onClick,
  onImageRemove,
  altText,
  error,
  touched,
  tooltip,
  onBlur,
  id,
  className = '',
  'data-testid': testId = 'image-field-button',
}) => {
  const errorBorderStyles =
    touched && error ? { border: '2px solid var(--red)' } : { border: 'none' }

  const handleImageRemove = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    onImageRemove && onImageRemove()
  }

  return (
    <div className="group relative">
      <ConditionalWrapper
        condition={!image && !!tooltip}
        wrapper={(children: ReactNode) => (
          <Tooltip placement="bottom">
            <TooltipTrigger>{children}</TooltipTrigger>
            <TooltipContent>{tooltip}</TooltipContent>
          </Tooltip>
        )}
      >
        <button
          className={`image-field-button p-0 rounded bg-transparent ${className}`}
          onClick={onClick}
          type="button"
          data-testid={testId}
          onBlur={onBlur}
          id={id}
          style={errorBorderStyles}
        >
          <Image
            data-testid={`${testId}__image`}
            className="w-[68px] h-[60px]"
            src={image}
            fallbackComponent={
              <div className="image-field-button__placeholder-container relative flex justify-center items-center">
                <img
                  src="/assets/icon-image.png"
                  alt={altText}
                  data-testid={`${testId}__image-placeholder`}
                />
              </div>
            }
            alt={altText}
          />
        </button>
      </ConditionalWrapper>
      {image && onImageRemove && (
        <button
          onClick={handleImageRemove}
          className="hidden group-hover:flex items-center justify-center border-none absolute bottom-0 left-0 ml-1 mb-1 rounded-full w-6 h-6 bg-white"
        >
          <span className="flex items-center justify-center">
            <TrashSVG className="w-4 h-4 text-danger" />
          </span>
        </button>
      )}
    </div>
  )
}

export default ImageFieldButton
