import React, { FC, Fragment } from 'react'
import TemplateItem from '@components/molecules/TemplateItem'
import { BuffType, tr } from '@constants/other'
import { AnnouncementTemplate, Template } from '@interfaces/Template'
import { getMetadataFromBuffType } from '@utils/buffTypeMappings'
import { ReactComponent as SearchSVG } from '@assets/search.svg'
import { ReactComponent as CheckBox } from '@assets/checkmark.svg'
import getLocalisations from '@utils/getLocalisation'
import { TemplateListProps, TemplateTypeTabs } from './types'

const NoTemplate = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-12 h-12 items-center justify-center flex bg-[#959DA5] rounded-full">
        <SearchSVG className="w-6 h-6" color="white" />
      </div>
      <span className="font-bold text-[#959DA5] mt-2">
        {tr({ id: 'template.noTemplates' })}
      </span>
    </div>
  )
}

const TemplateList: FC<React.PropsWithChildren<TemplateListProps>> = ({
  templates,
  type: templateType,
  selected,
  onDoubleClick,
  onClick,
}) => {
  const handleDoubleClick = (template: Template | AnnouncementTemplate) => {
    onDoubleClick(template)
  }

  const handleClick = (template: Template | AnnouncementTemplate) => {
    onClick(template)
  }

  return (
    <div className="gap-y-2 flex flex-col" data-testid="template-list-wrapper">
      {!!templates?.length ? (
        templates?.map((template) => {
          const isSelected = Array.isArray(selected)
            ? !!selected?.find((t) => t.id === template.id)
            : selected?.id === template?.id
          const checkboxBgColor = isSelected ? 'bg-primary' : 'bg-transparent'
          const activeLanguage = template?.localisations?.[0]
          if (
            templateType === TemplateTypeTabs.Announcements ||
            templateType === TemplateTypeTabs.GlobalAnnouncements
          ) {
            const announcementTemplate = template as AnnouncementTemplate

            const content = getLocalisations({
              localisations: announcementTemplate?.content?.localisations,
              languages: [activeLanguage],
            })

            return (
              <div
                className="flex gap-x-2 items-center w-full"
                key={announcementTemplate.id}
              >
                {Array.isArray(selected) && (
                  <button
                    type="button"
                    onClick={() => handleClick(announcementTemplate)}
                    className={`flex text-white flex-shrink-0 left-1 top-1 justify-center items-center h-7 w-7 border-[1px] border-solid !border-[#959DA5] p-0 rounded ${checkboxBgColor}`}
                  >
                    {isSelected ? <CheckBox width="80%" /> : <div />}
                  </button>
                )}
                <TemplateItem
                  id={announcementTemplate.id}
                  type={BuffType.Announcement}
                  key={`template-${announcementTemplate.id}`}
                  question={content?.text ?? ''}
                  title={content?.titleText}
                  name={announcementTemplate.templateName}
                  answers={[]}
                  languages={announcementTemplate.localisations}
                  onClick={() => handleClick(announcementTemplate)}
                  onDoubleClick={() => handleDoubleClick(announcementTemplate)}
                  selected={isSelected}
                  activeLanguage={activeLanguage}
                />
              </div>
            )
          }

          const buffTemplate = template as Template

          const { id: type } = getMetadataFromBuffType({
            lifecycle: buffTemplate.lifecycle,
            answerInterface: buffTemplate.answerInterface,
          })

          const questionContent = getLocalisations({
            localisations: buffTemplate?.question?.localisations,
            languages: [activeLanguage],
          })
          return (
            <div className="flex gap-x-2 items-center" key={buffTemplate.id}>
              {Array.isArray(selected) && (
                <button
                  type="button"
                  onClick={() => handleClick(buffTemplate)}
                  className={`flex text-white flex-shrink-0 left-1 top-1 justify-center items-center h-7 w-7 border-[1px] border-solid !border-[#959DA5] p-0 rounded ${checkboxBgColor}`}
                >
                  {isSelected ? <CheckBox width="80%" /> : <div />}
                </button>
              )}
              <TemplateItem
                id={buffTemplate.id}
                type={type}
                key={`template-${buffTemplate.id}`}
                question={questionContent?.text ?? ''}
                name={buffTemplate.templateName}
                answers={
                  type === BuffType?.StarRating ? [] : buffTemplate.answers
                }
                languages={buffTemplate.localisations}
                onClick={() => handleClick(buffTemplate)}
                onDoubleClick={() => handleDoubleClick(buffTemplate)}
                selected={isSelected}
                activeLanguage={activeLanguage}
              />
            </div>
          )
        })
      ) : (
        <NoTemplate />
      )}
    </div>
  )
}

export default TemplateList
