import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface SectionLayoutProps {
  title: ReactNode | string
  backLink?: string | null
  children: ReactNode
  containerClassname?: string
  childrenClassName?: string
  header?: ReactNode
  className?: string
}

const SectionLayout: FC<SectionLayoutProps> = ({
  title,
  backLink = null,
  children,
  header,
  containerClassname = '',
  childrenClassName = '',
  className = '',
}) => {
  return (
    <div
      style={{ width: '998px', margin: '0 auto' }}
      className={`flex items-center flex-1 mt-2 ${className}`}
    >
      <div className={`flex flex-col ${containerClassname}`}>
        <div className="flex items-center justify-between gap-x-4 mr-2">
          <div className="flex items-center">
            {backLink && (
              <div className="flex items-center">
                <Link
                  to={backLink}
                  className="text-[20px] inline-flex items-center m-2 p-1 border border-[#959DA5] rounded"
                >
                  <img
                    className="m-1 w-4 h-4 text-[#586069]"
                    src={'/assets/svg/icon-arrow-left.svg'}
                  />
                </Link>
              </div>
            )}
            <div className="flex justify-between mt-2">
              <h3 className="h3 ml-2 font-normal">{title}</h3>
            </div>
          </div>

          {header && header}
        </div>

        <div
          data-testid="stream-details-card"
          className={`flex flex-col mx-2 mt-2 rounded bg-white ${childrenClassName}`}
          style={{
            boxShadow: '0 5px 15px 0 rgba(9, 14, 37, 0.1)',
            minWidth: '998px',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default SectionLayout
