import axios from 'axios'
import { refreshJwtToken } from '@services/requests/auth'
import { BUFFUP_DASHBOARD_TOKEN, BUFFUP_REFRESH_TOKEN } from '@constants/other'

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
})
const accountName = process.env.ACCOUNT_NAME

client.defaults.headers.common = {
  'X-Buffup-Broadcaster-ID': accountName,
}

client.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem(BUFFUP_DASHBOARD_TOKEN)
    if (request?.headers && !!token) {
      request.headers.Authorization = `Bearer ${token}`
    }

    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

client.interceptors.response.use(undefined, async (error) => {
  const config = error?.config

  if (error?.response?.status === 401 && !config?.sent) {
    config.sent = true

    if (error?.config?.headers?.bypass401) {
      localStorage.removeItem(BUFFUP_DASHBOARD_TOKEN)
      localStorage.removeItem(BUFFUP_REFRESH_TOKEN)
      window.dispatchEvent(new Event('expiredRefreshToken'))

      return Promise.reject(error)
    }

    const jwtToken = await refreshJwtToken()

    if (jwtToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${jwtToken}`,
      }
    }

    return client(config)
  }
  return Promise.reject(error)
})
