import React from 'react'

const NAVBAR_HEIGHT = '60px'

interface IProps {
  auto?: boolean
  heightOverflow?: string
  className?: string
  elementRef?: any
}

const ScrollableContainer: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  auto = false,
  heightOverflow,
  className = '',
  elementRef,
}) => {
  const heightToSubstract = heightOverflow || NAVBAR_HEIGHT

  return (
    <div
      id="contained"
      className={`scrollable-container ${className}`}
      style={{
        height: auto ? 'auto' : `calc(100vh - ${heightToSubstract})`,
      }}
      ref={elementRef}
    >
      {children}
    </div>
  )
}

export default ScrollableContainer
