import React, { FC, useCallback, useEffect, useState } from 'react'
import { Action, tr } from '@constants/other'
import { CreateStream, IStream, StreamSource } from '@interfaces/IStream'
import {
  updateStream,
  startStream,
  endStream,
  getStreamById,
  deleteStream,
  getStreams,
} from '@services/requests/stream'
import { useToast } from '@utils/hooks/useToast'
import { useNavigate, useParams } from 'react-router-dom'
import { IRouteParams } from '@interfaces/RouteParams'
import { queryClient } from '@utils/reactQuery/client'
import StreamHeaderButtonGroup from '@components/molecules/StreamHeaderButtonGroup'
import StreamDetailsTabs from '@components/organisms/StreamDetailsTabs'
import { useGetGame } from '@services/requests/games'
import IStreamFormValues from '@interfaces/IStreamFormValues'
import { FormikHelpers } from 'formik'
import { gameService } from '@services/index'
import useCreateStream from '@utils/hooks/useCreateStream'
import { usePreviousValue } from '@utils/hooks/usePreviousValue'
import DuplicateProviderIdModal from '@components/organisms/DuplicateProviderIdModal'
import { useQuery } from 'react-query'
import { getStreamConfig } from '@services/requests/stream-config'

const DEFAULT_MAX_PARTICIPATION_POINTS = 0

const StreamConfig: FC = () => {
  const navigate = useNavigate()
  const { id: streamId = '', gameId = '' } = useParams<IRouteParams>()
  const { addErrorToast, addToast } = useToast()
  const [stream, setStream] = useState<IStream>()
  const [duplicateProviderStream, setDuplicateProviderStream] =
    useState<IStream>()

  const prevDuplicateProviderStream = usePreviousValue(duplicateProviderStream)

  const duplicateProviderStreamTitle =
    duplicateProviderStream?.title ?? prevDuplicateProviderStream?.title

  const { data: gameData } = useGetGame({
    gameId,
    options: { enabled: !!gameId },
  })
  const { data: streamConfigData } = useQuery(
    ['stream-config', streamId],
    () => getStreamConfig(streamId),
    { enabled: !!streamId }
  )
  const { mutateAsync: createStreamMutation } = useCreateStream()

  const getStream = useCallback(
    async (id: string) => {
      try {
        const stream = await getStreamById(id)
        setStream(stream)
      } catch (error) {
        console.error(error)
        addErrorToast(error)
      }
    },
    [addErrorToast]
  )

  useEffect(() => {
    if (streamId) getStream(streamId)
  }, [streamId, getStream])

  const handleProviderError = async (providerId: string) => {
    try {
      const streams = await getStreams(
        1,
        '',
        '',
        `source:web;source_id:${providerId}`
      )
      const stream = streams?.streams?.[0]
      setDuplicateProviderStream(stream)
    } catch (error) {
      addErrorToast(error)
    }
  }

  const handlePlayStop = async (streamId: string, isStatusLive: boolean) => {
    try {
      if (isStatusLive) {
        await endStream(streamId)
        queryClient.invalidateQueries('streams')
        queryClient.invalidateQueries(['streams-infinite'])
        addToast({
          msg: tr({ id: `stream.${Action.Stop}` }),
          type: 'success',
          image: '/assets/icon-check-blue.png',
        })
      } else {
        await startStream(streamId)
        queryClient.invalidateQueries('streams')
        queryClient.invalidateQueries(['streams-infinite'])
        addToast({
          msg: tr({ id: `stream.${Action.Start}` }),
          type: 'success',
          image: '/assets/icon-check-blue.png',
        })
      }

      await Promise.all([getStream(streamId)])
    } catch (error) {
      console.error(error)
      addErrorToast(error?.response?.data)
    }
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteStream(id)
      addToast({
        msg: tr({ id: `stream.${Action.Delete}` }),
        type: 'success',
        image: '/assets/icon-check-blue.png',
      })

      setStream(undefined)

      // TODO: refactor
      // queryClient.invalidateQueries(['streams-infinite', debouncedSearch])
      navigate('/streams')
    } catch (error) {
      console.error(error)
      addErrorToast(error)
    }
  }

  const handleSubmit = async (
    values: IStreamFormValues,
    helpers: FormikHelpers<IStreamFormValues>
  ) => {
    /**
     * Checks to see if points have changed from defaults
     * @param {number} max
     * @return {boolean}
     */
    const havePointsChanged = (max: number): boolean => {
      const maxValue =
        gameData?.game?.maxParticipationPoints ??
        DEFAULT_MAX_PARTICIPATION_POINTS
      if (max !== maxValue) return true
      return false
    }

    const { title, providerId, bonusTimePoints, twitchChannelIds, languages } =
      values

    const body: CreateStream = {
      title,
      languages: languages.filter((l) => !!l.selected).map((l) => l.iso6391),
      streamSources: [],
    }

    const hasTwitchChannelIds = twitchChannelIds?.filter(
      (provider) => !!provider.trim()
    ).length

    if (providerId && !hasTwitchChannelIds) {
      body.streamSources = [
        {
          source: StreamSource.WEB,
          sourceId: providerId.trim(),
        },
      ]
    }

    if (!providerId && !!hasTwitchChannelIds) {
      body.streamSources = twitchChannelIds
        ?.filter((provider) => !!provider.trim())
        .map((provider) => ({
          source: StreamSource.TWITCH,
          sourceId: provider,
        }))
    }

    const updateGame = async (
      gameId: string,
      maxParticipationPoints: number
    ) => {
      await gameService.updateGameById(gameId, {
        maxParticipationPoints:
          maxParticipationPoints || DEFAULT_MAX_PARTICIPATION_POINTS,
      })
    }

    try {
      let stream: IStream
      if (streamId && streamId?.length > 0) {
        stream = await updateStream(streamId, body)

        const gameId = stream.gameIds[0]
        if (havePointsChanged(bonusTimePoints)) {
          await updateGame(gameId, bonusTimePoints)
          queryClient.invalidateQueries(`/games/${gameId}`)
        }

        queryClient.invalidateQueries('streams')
        queryClient.invalidateQueries(['streams-infinite'])

        addToast({
          msg: tr({ id: `stream.${Action.Update}` }),
          type: 'success',
          image: '/assets/icon-check-blue.png',
        })
      } else {
        stream = await createStreamMutation(body)

        const gameId = stream.gameIds[0]
        await updateGame(gameId, bonusTimePoints)

        addToast({
          msg: tr({ id: `stream.${Action.Create}` }),
          type: 'success',
          image: '/assets/icon-check-blue.png',
        })
      }
      setStream(stream)
      navigate(`/streams/${stream.id}/${stream.gameIds[0]}`)
    } catch (error) {
      if (error.response?.status === 409) {
        const hasTwitchChannelIds = twitchChannelIds?.filter(
          (provider) => !!provider.trim()
        ).length

        if (!!values.providerId) {
          handleProviderError(values.providerId)
        } else if (hasTwitchChannelIds) {
          addErrorToast(tr({ id: `stream.usedTwitchChannelIdMessage` }))
        } else {
          addErrorToast(error)
        }
      } else {
        console.error(error)
        addErrorToast(error)
      }
    } finally {
      helpers.setSubmitting(false)
    }
  }

  useEffect(() => {
    if (!streamId) {
      setStream(undefined)
    }
  }, [streamId])

  return (
    <div
      data-testid="stream-details-container"
      className="flex flex-col pb-5 items-center w-full h-full"
      style={{ backgroundColor: 'var(--white-background)' }}
    >
      <div className="h-full">
        <div className="flex justify-between">
          <h3 className="h3 mt-2 ml-2 font-normal">
            {stream
              ? tr({ id: 'stream.editStream' }, { value: stream.title })
              : tr({ id: 'stream.newStreamAdd' })}
          </h3>
          <StreamHeaderButtonGroup
            handlePlayStop={handlePlayStop}
            stream={stream}
          />
        </div>
        <div
          data-testid="stream-details-card"
          className="flex flex-col pt-4 mx-2 mt-2 rounded"
          style={{
            backgroundColor: 'white',
            boxShadow: '0 5px 15px 0 rgba(9, 14, 37, 0.1)',
            minWidth: '998px',
          }}
        >
          <StreamDetailsTabs
            title={stream?.title}
            providerId={stream?.streamSources?.web[0]?.sourceId}
            bonusTimePoints={gameData?.game?.maxParticipationPoints}
            leaderboardIds={stream?.leaderboardIds}
            twitchChannelIds={
              stream?.streamSources?.twitch?.length
                ? stream?.streamSources?.twitch?.map((item) => item.sourceId)
                : ['']
            }
            currentStream={stream?.id}
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
            currentLanguages={stream?.languages}
            streamConfig={streamConfigData}
          />
        </div>
      </div>

      <DuplicateProviderIdModal
        show={!!duplicateProviderStream}
        onClose={() => setDuplicateProviderStream(undefined)}
      >
        <p>
          {tr(
            { id: 'stream.usedProviderIdMessage' },
            {
              value: (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${window.location.origin}/streams/${duplicateProviderStream?.id}/${duplicateProviderStream?.gameIds?.[0]}`}
                >
                  {duplicateProviderStreamTitle}
                </a>
              ),
            }
          )}
        </p>
      </DuplicateProviderIdModal>
    </div>
  )
}

export default StreamConfig
