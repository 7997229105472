import React, { useEffect, useMemo, useState } from 'react'
import { tr } from '@constants/other'
import { Form, Formik } from 'formik'
import TextInput from '@components/atoms/TextInput'
import Button from '@components/atoms/Button'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'
import { useToast } from '@utils/hooks/useToast'
import { LanguageCode } from '@interfaces/ILanguage'
import LanguageTabContent from '@components/molecules/LanguageTabContent'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { cloneDeep, get, merge } from 'lodash'
import deepOmit from '@utils/deepOmit'
import { getErrorFields, getLangsFromObjectPath } from '@utils/form'
import { useMutation, useQuery } from 'react-query'
import { queryClient } from '@utils/reactQuery/client'
import { useClientConfig } from '@services/requests/client-config'
import buffTooltipBackgroundImage from '@assets/signup-tooltips/buff.png'
import leaderboardTooltipBackgroundImage from '@assets/signup-tooltips/leaderboard.png'
import friendsTooltipBackgroundImage from '@assets/signup-tooltips/friends.png'
import profileTooltipBackgroundImage from '@assets/signup-tooltips/profile.png'
import settingsTooltipBackgroundImage from '@assets/signup-tooltips/settings.png'
import reactionsTooltipBackgroundImage from '@assets/signup-tooltips/reactions.png'
import { SignUpTexts } from '@interfaces/ClientConfig'
import { SignupConfigProps, SignupConfigFormValues } from './types'
import { schema } from './schema'

const SignupConfig = (_: SignupConfigProps) => {
  const { getSignUp, getLanguages, updateSignUp } = useClientConfig()
  const { addToast, addErrorToast } = useToast()

  const { data: signUp } = useQuery(['signup'], getSignUp)
  const { data: locales } = useQuery('locales', getLanguages)

  const { mutate: mutateUpdateSignupTexts, isLoading } = useMutation(
    updateSignUp,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['signup'])
        addToast({
          msg: tr({ id: 'configuration.signupSettingsUpdated' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
      },
      onError: (err) => {
        addErrorToast(err)
      },
    }
  )

  const initialValues: SignupConfigFormValues = useMemo(() => {
    if (!locales || !signUp)
      return {
        termsOfService: '',
        privacyPolicy: '',
        languages: [LanguageCode.en],
        localisations: {
          [LanguageCode.en]: {
            buffInfoPill: '',
            leaderboardBanner: '',
            settingsTab: '',
            profileTab: '',
            friendsTab: '',
            reactionsTab: '',
          },
        },
      }

    const filteredLanguages = signUp.languages.filter((lang) =>
      locales.enabled.includes(lang)
    )
    const transformedLanguages =
      filteredLanguages.length > 0 ? filteredLanguages : [locales.default]

    const transformedLocalisations: Partial<Record<LanguageCode, SignUpTexts>> =
      {}

    Object.keys(signUp.localisations).forEach((key) => {
      if (locales?.enabled.includes(key as LanguageCode)) {
        transformedLocalisations[key as keyof typeof LanguageCode] =
          // @ts-ignore
          signUp.localisations[key]
      }
    })

    const transformedData = {
      ...signUp,
      languages: transformedLanguages,
      localisations: transformedLocalisations,
    }

    return transformedData
  }, [locales, signUp])

  const [activeLanguage, setActiveLanguage] = useState<LanguageCode>(
    initialValues?.languages?.[0]
  )
  const [selectedLanguages, setSelectedLanguages] = useState<LanguageCode[]>([
    initialValues?.languages?.[0],
  ])

  const availableLanguages = useMemo(() => {
    if (!locales) return []
    return locales.enabled
  }, [locales])

  useEffect(() => {
    if (!initialValues) return

    setActiveLanguage(initialValues.languages[0])
    setSelectedLanguages(initialValues.languages)
  }, [initialValues])

  const handleSubmit = (values: SignupConfigFormValues) => {
    mutateUpdateSignupTexts(values)
  }

  const textLanguageKey = `localisations.[${activeLanguage}]`

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange
        validateOnBlur
        enableReinitialize
        validationSchema={schema}
      >
        {({ values, errors, touched, handleChange, handleBlur, setValues }) => {
          const tabErrors: Partial<Record<LanguageCode, boolean>> = {}

          const errorFields = getErrorFields(errors, touched)
          const errorLangs = [
            ...new Set(errorFields.map(getLangsFromObjectPath)),
          ]

          errorLangs.forEach((lang) => {
            const language = lang as LanguageCode
            tabErrors[language] = true
          })

          return (
            <Form>
              <div>
                <h6 className="font-semibold mb-3">
                  {tr({ id: 'configuration.signUp.signUpBanner' })}
                </h6>
                <LanguageTabContent
                  activeLanguage={activeLanguage}
                  selectedLanguages={selectedLanguages}
                  availableLanguages={availableLanguages}
                  errors={tabErrors}
                  onLanguageChange={(lang) => setActiveLanguage(lang)}
                  onSelectedLanguagesChange={(langs) => {
                    const removedLangs = selectedLanguages.filter(
                      (lang) => !langs.includes(lang)
                    )
                    const addedLangs = langs.filter(
                      (lang) => !selectedLanguages.includes(lang)
                    )
                    let newValues = cloneDeep(values)
                    newValues.languages = langs

                    if (removedLangs.length !== 0) {
                      newValues = deepOmit(newValues, removedLangs)
                    }

                    if (addedLangs.length !== 0) {
                      const valuesToMerge = {
                        localisations: {} as Partial<{
                          [key in LanguageCode]: SignUpTexts
                        }>,
                      }

                      addedLangs.forEach((lang) => {
                        valuesToMerge.localisations[lang] = {
                          buffInfoPill: '',
                          leaderboardBanner: '',
                          settingsTab: '',
                          profileTab: '',
                          friendsTab: '',
                          reactionsTab: '',
                        }
                      })

                      newValues = merge(newValues, valuesToMerge)
                    }

                    setValues(newValues)
                    setSelectedLanguages(langs)
                  }}
                >
                  <div className="rounded-tr rounded-br rounded-bl bg-blue-100 bg-white-background">
                    <fieldset className="flex flex-col space-y-2">
                      <TextInput
                        label={
                          <div className="flex items-center gap-x-2">
                            <p className="m-0">
                              {tr({ id: 'configuration.buff' })}
                            </p>
                            <Tooltip placement="right">
                              <TooltipTrigger>
                                <div className={`flex flex-row w-fit`}>
                                  <InfoCircleIcon className="w-4 h-4" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent className="bg-white p-3 border-[#d0d7de] border-solid border-[1px] rounded text-black">
                                <div className="max-w-[340px] flex flex-col">
                                  <span className="font-semibold mb-2 text-sm text-black">
                                    {tr({
                                      id: 'configuration.signUp.buffTooltip',
                                    })}
                                  </span>
                                  <img
                                    className="w-[200px]"
                                    src={buffTooltipBackgroundImage}
                                    alt="buff-signup-text"
                                  />
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </div>
                        }
                        id={`${textLanguageKey}.buffInfoPill`}
                        value={get(values, `${textLanguageKey}.buffInfoPill`)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          get(errors, `${textLanguageKey}.buffInfoPill`)
                        )}
                        errorLabel={get(
                          errors,
                          `${textLanguageKey}.buffInfoPill`
                        )}
                        touched={Boolean(
                          get(touched, `${textLanguageKey}.buffInfoPill`)
                        )}
                        data-testid={`${textLanguageKey}_buffInfoPill`}
                      />

                      <TextInput
                        label={
                          <div className="flex items-center gap-x-2">
                            <p className="m-0">
                              {tr({ id: 'configuration.leaderboard' })}
                            </p>
                            <Tooltip placement="right">
                              <TooltipTrigger>
                                <div className={`flex flex-row w-fit`}>
                                  <InfoCircleIcon className="w-4 h-4" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent className="bg-white p-3 border-[#d0d7de] border-solid border-[1px] rounded text-black">
                                <div className="max-w-[340px] flex flex-col">
                                  <span className="font-semibold mb-2 text-sm text-black">
                                    {tr({
                                      id: 'configuration.signUp.leaderboardTooltip',
                                    })}
                                  </span>
                                  <img
                                    className="w-[200px]"
                                    src={leaderboardTooltipBackgroundImage}
                                    alt="buff-signup-text"
                                  />
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </div>
                        }
                        id={`${textLanguageKey}.leaderboardBanner`}
                        value={get(
                          values,
                          `${textLanguageKey}.leaderboardBanner`
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          get(errors, `${textLanguageKey}.leaderboardBanner`)
                        )}
                        errorLabel={get(
                          errors,
                          `${textLanguageKey}.leaderboardBanner`
                        )}
                        touched={Boolean(
                          get(touched, `${textLanguageKey}.leaderboardBanner`)
                        )}
                        data-testid={`${textLanguageKey}_leaderboardBanner`}
                      />

                      <TextInput
                        label={
                          <div className="flex items-center gap-x-2">
                            <p className="m-0">
                              {tr({ id: 'configuration.friends' })}
                            </p>
                            <Tooltip placement="right">
                              <TooltipTrigger>
                                <div className={`flex flex-row w-fit`}>
                                  <InfoCircleIcon className="w-4 h-4" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent className="bg-white p-3 border-[#d0d7de] border-solid border-[1px] rounded text-black">
                                <div className="max-w-[340px] flex flex-col">
                                  <span className="font-semibold mb-2 text-sm text-black">
                                    {tr({
                                      id: 'configuration.signUp.friendsTooltip',
                                    })}
                                  </span>
                                  <img
                                    className="w-[200px]"
                                    src={friendsTooltipBackgroundImage}
                                    alt="buff-signup-text"
                                  />
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </div>
                        }
                        id={`${textLanguageKey}.friendsTab`}
                        value={get(values, `${textLanguageKey}.friendsTab`)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          get(errors, `${textLanguageKey}.friendsTab`)
                        )}
                        errorLabel={get(
                          errors,
                          `${textLanguageKey}.friendsTab`
                        )}
                        touched={Boolean(
                          get(touched, `${textLanguageKey}.friendsTab`)
                        )}
                        data-testid={`${textLanguageKey}_friendsTab`}
                      />

                      <TextInput
                        label={
                          <div className="flex items-center gap-x-2">
                            <p className="m-0">
                              {tr({ id: 'configuration.profile' })}
                            </p>
                            <Tooltip placement="right">
                              <TooltipTrigger>
                                <div className={`flex flex-row w-fit`}>
                                  <InfoCircleIcon className="w-4 h-4" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent className="bg-white p-3 border-[#d0d7de] border-solid border-[1px] rounded text-black">
                                <div className="max-w-[340px] flex flex-col">
                                  <span className="font-semibold mb-2 text-sm text-black">
                                    {tr({
                                      id: 'configuration.signUp.profileTooltip',
                                    })}
                                  </span>
                                  <img
                                    className="w-[200px]"
                                    src={profileTooltipBackgroundImage}
                                    alt="buff-signup-text"
                                  />
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </div>
                        }
                        id={`${textLanguageKey}.profileTab`}
                        value={get(values, `${textLanguageKey}.profileTab`)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          get(errors, `${textLanguageKey}.profileTab`)
                        )}
                        errorLabel={get(
                          errors,
                          `${textLanguageKey}.profileTab`
                        )}
                        touched={Boolean(
                          get(touched, `${textLanguageKey}.profileTab`)
                        )}
                        data-testid={`${textLanguageKey}_profileTab`}
                      />

                      <TextInput
                        label={
                          <div className="flex items-center gap-x-2">
                            <p className="m-0">
                              {tr({ id: 'generic.settings' })}
                            </p>
                            <Tooltip placement="right">
                              <TooltipTrigger>
                                <div className={`flex flex-row w-fit`}>
                                  <InfoCircleIcon className="w-4 h-4" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent className="bg-white p-3 border-[#d0d7de] border-solid border-[1px] rounded text-black">
                                <div className="max-w-[340px] flex flex-col">
                                  <span className="font-semibold mb-2 text-sm text-black">
                                    {tr({
                                      id: 'configuration.signUp.settingsTooltip',
                                    })}
                                  </span>
                                  <img
                                    className="w-[200px]"
                                    src={settingsTooltipBackgroundImage}
                                    alt="buff-signup-text"
                                  />
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </div>
                        }
                        id={`${textLanguageKey}.settingsTab`}
                        value={get(values, `${textLanguageKey}.settingsTab`)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          get(errors, `${textLanguageKey}.settingsTab`)
                        )}
                        errorLabel={get(
                          errors,
                          `${textLanguageKey}.settingsTab`
                        )}
                        touched={Boolean(
                          get(touched, `${textLanguageKey}.settingsTab`)
                        )}
                        data-testid={`${textLanguageKey}_settingsTab`}
                      />

                      <TextInput
                        label={
                          <div className="flex items-center gap-x-2">
                            <p className="m-0">
                              {tr({ id: 'configuration.signUp.reactions' })}
                            </p>
                            <Tooltip placement="right">
                              <TooltipTrigger>
                                <div className={`flex flex-row w-fit`}>
                                  <InfoCircleIcon className="w-4 h-4" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent className="bg-white p-3 border-[#d0d7de] border-solid border-[1px] rounded text-black">
                                <div className="max-w-[340px] flex flex-col">
                                  <span className="font-semibold mb-2 text-sm text-black">
                                    {tr({
                                      id: 'configuration.signUp.reactionsTooltip',
                                    })}
                                  </span>
                                  <img
                                    className="w-[200px]"
                                    src={reactionsTooltipBackgroundImage}
                                    alt="buff-signup-text"
                                  />
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </div>
                        }
                        id={`${textLanguageKey}.reactionsTab`}
                        value={get(values, `${textLanguageKey}.reactionsTab`)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          get(errors, `${textLanguageKey}.reactionsTab`)
                        )}
                        errorLabel={get(
                          errors,
                          `${textLanguageKey}.reactionsTab`
                        )}
                        touched={Boolean(
                          get(touched, `${textLanguageKey}.reactionsTab`)
                        )}
                        data-testid={`${textLanguageKey}reactionsTab`}
                      />
                    </fieldset>
                  </div>
                </LanguageTabContent>
              </div>

              <div className="h-[1px] w-full my-4 border-solid border-[1px] border-[#E1E4E8]" />

              <div>
                <h6 className="font-semibold">
                  {tr({ id: 'configuration.signUp.termsBanner' })}
                </h6>
                <fieldset
                  className="p-4 rounded-lg mb-4 bg-white-background"
                  data-testid="terms-policy-section"
                >
                  <div className="flex flex-col gap-y-4">
                    {/* Terms URL */}
                    <TextInput
                      label={
                        <div className="flex items-center gap-x-2">
                          <p className="m-0">
                            {tr({ id: 'configuration.signUp.termsUrl' })}
                          </p>
                          <Tooltip placement="right">
                            <TooltipTrigger>
                              <div className={`flex flex-row w-fit`}>
                                <InfoCircleIcon className="w-4 h-4" />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <span>
                                {tr({
                                  id: 'configuration.signUp.termsTooltip',
                                })}
                              </span>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      }
                      className="max-w-[50%]"
                      id="termsOfService"
                      value={get(values, 'termsOfService')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(get(errors, 'termsOfService'))}
                      errorLabel={get(errors, 'termsOfService')}
                      touched={Boolean(get(touched, 'termsOfService'))}
                      data-testid="termsOfService"
                    />

                    {/* Policy URL */}
                    <TextInput
                      label={
                        <div className="flex items-center gap-x-2">
                          <p className="m-0">
                            {tr({ id: 'configuration.signUp.policyUrl' })}
                          </p>
                          <Tooltip placement="right">
                            <TooltipTrigger>
                              <div className={`flex flex-row w-fit`}>
                                <InfoCircleIcon className="w-4 h-4" />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <span>
                                {tr({
                                  id: 'configuration.signUp.policyTooltip',
                                })}
                              </span>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      }
                      className="max-w-[50%]"
                      id="privacyPolicy"
                      value={get(values, 'privacyPolicy')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(get(errors, 'privacyPolicy'))}
                      errorLabel={get(errors, 'privacyPolicy')}
                      touched={Boolean(get(touched, 'privacyPolicy'))}
                      data-testid="privacyPolicy"
                    />
                  </div>
                </fieldset>
              </div>

              <div className="flex justify-end">
                <Button disabled={isLoading} type="submit">
                  {tr({
                    id: 'generic.update',
                  })}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default SignupConfig
