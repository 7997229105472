import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
} from 'react'
import {
  AssetContext,
  AssetManagerView,
} from '@services/providers/AssetProvider'
import UploadableAssetItem from '@components/molecules/UploadableAssetItem'
import { Asset, AssetClass } from '@interfaces/Assets'
import Button from '@components/atoms/Button'
import { tr } from '@constants/other'
import Image from '@interfaces/Image'
import findIndex from 'lodash/findIndex'
import { useUpdateAssetMetadata } from '@services/requests/assets'
import { AssetActionTypes } from '@services/reducers/assetReducer'
import UpdateAssetItem from '../UpdateAssetItem'

interface ManageAssetListProps {
  type: AssetClass
  selectedAssets?: Asset[]
  setSelectedAsset?: Dispatch<SetStateAction<Image[]>>
}

const ManageAssetList: FC<React.PropsWithChildren<ManageAssetListProps>> = ({
  type,
  selectedAssets,
  setSelectedAsset,
}) => {
  const updateAssetMetadata = useUpdateAssetMetadata(type)
  const { state, dispatch } = useContext(AssetContext)

  const handleInputUpdate = (
    evt: ChangeEvent<HTMLInputElement>,
    id: string,
    inputName: string
  ) => {
    if (!setSelectedAsset) return
    setSelectedAsset((prevAssets) => {
      const assetIdx = findIndex(prevAssets, ['id', id])
      const newAssets = [...prevAssets]
      newAssets[assetIdx] = {
        ...newAssets[assetIdx],
        [inputName]: evt.target.value,
      }
      return newAssets
    })
  }

  const handleUpdateAssets = async () => {
    selectedAssets?.map((asset) => {
      const regex = /[^\w]|_/g
      const fileName = asset.title.replaceAll(regex, ' ')
      updateAssetMetadata.mutate({
        id: asset.id,
        payload: {
          title: fileName,
          description: asset.description,
        },
      })
    })
  }

  return (
    <>
      <div className="px-4 py-2 bg-gray default-scroll overflow-y-scroll scroll-auto max-h-[calc(100vh-300px)] rounded border border-[#E1E4E8]">
        {selectedAssets
          ? selectedAssets?.map((asset) => {
              return (
                <div key={asset.id}>
                  <UpdateAssetItem
                    title={
                      type === AssetClass.BuffSound
                        ? tr({ id: 'assetManager.soundName' })
                        : tr({ id: 'assetManager.imageName' })
                    }
                    onInputUpdate={handleInputUpdate}
                    asset={asset}
                  />
                </div>
              )
            })
          : state.uploadableAssets?.map((asset) => {
              return (
                <div key={asset.id}>
                  <UploadableAssetItem asset={asset} />
                </div>
              )
            })}
      </div>
      {Boolean(selectedAssets) && (
        <div className="flex justify-between py-3 px-3">
          <div className="flex w-full items-center justify-end">
            <Button
              onClick={async () => {
                await handleUpdateAssets()

                setSelectedAsset && setSelectedAsset([])

                dispatch({
                  type: AssetActionTypes.ChangeView,
                  payload: AssetManagerView.AssetList,
                })
              }}
            >
              {tr({ id: 'generic.update' })}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default ManageAssetList
