import { QueryFunctionContext } from 'react-query/types/core/types'
import { api } from '@services/requests/api'
import { QueryKey } from './types'

export const fetcher = <T>({
  queryKey,
  pageParam,
}: QueryFunctionContext<QueryKey>): Promise<T> => {
  const [url, params] = queryKey
  return api.get<T>(url, { params: { ...params, pageParam } }).then((res) => {
    return res.data
  })
}
