import { useQuery, UseQueryOptions } from 'react-query'
import { fetcher } from './fetcher'
import { QueryKey } from './types'

export const useFetch = <T>({
  url,
  params,
  config,
  key,
}: {
  url: string
  params?: object
  config?: UseQueryOptions<T, Error, T, QueryKey>
  key?: string
}) => {
  return useQuery<T, Error, T, QueryKey>(
    key ? [key, params] : [url, params],
    ({ queryKey }) => fetcher({ queryKey, meta: config?.meta }),
    {
      enabled: !!url,
      ...config,
    }
  )
}
