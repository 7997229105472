import { BuffPageActionMode, BuffPageType } from '@interfaces/BuffPage'
import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react'

export interface BuffFormContextProps {
  actionMode: BuffPageActionMode
  setActionMode: Dispatch<SetStateAction<BuffPageActionMode>>
  type: BuffPageType
  setType: Dispatch<SetStateAction<BuffPageType>>
  resetBuffFormContext: () => void
  isUpdateActionMode: boolean
  isCreateActionMode: boolean
  isTemplateType: boolean
  isWelcomeBuffType: boolean
  isLoadActionMode: boolean
  isSubmitting: boolean
  setIsSubmitting: (value: boolean) => void
}

export const BuffFormContext = createContext<BuffFormContextProps>(
  {} as BuffFormContextProps
)

export const BuffFormProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [type, setType] = useState<BuffPageType>(BuffPageType.Voteable)
  const [actionMode, setActionMode] = useState<BuffPageActionMode>(
    BuffPageActionMode.Create
  )
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const resetBuffFormContext = useCallback(() => {
    setType(BuffPageType.Voteable)
    setActionMode(BuffPageActionMode.Create)
  }, [])

  const isUpdateActionMode = useMemo(
    () => actionMode === BuffPageActionMode.Update,
    [actionMode]
  )
  const isCreateActionMode = useMemo(
    () => actionMode === BuffPageActionMode.Create,
    [actionMode]
  )
  const isLoadActionMode = useMemo(
    () => actionMode === BuffPageActionMode.Load,
    [actionMode]
  )

  const isTemplateType = useMemo(() => type === BuffPageType.Template, [type])
  const isWelcomeBuffType = useMemo(
    () => type === BuffPageType.WelcomeBuff,
    [type]
  )

  return (
    <BuffFormContext.Provider
      value={{
        actionMode,
        setActionMode,
        type,
        setType,
        resetBuffFormContext,
        isUpdateActionMode,
        isCreateActionMode,
        isLoadActionMode,
        isTemplateType,
        isWelcomeBuffType,
        isSubmitting,
        setIsSubmitting,
      }}
    >
      {children}
    </BuffFormContext.Provider>
  )
}
