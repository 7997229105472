import { Voteable } from '@interfaces/Voteable'
import { Announcement } from '@interfaces/Announcement'
import { IOnboard } from '@interfaces/IOnboard'
import { AnnouncementTemplate, Template } from '@interfaces/Template'

export enum BuffStatus {
  Scheduled = 'scheduled',
  Published = 'published',
  Expired = 'expired',
  Resolved = 'resolved',
}

export type PreviewBuff =
  | Voteable
  | Announcement
  | AnnouncementTemplate
  | IOnboard
  | Template
