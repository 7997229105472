import React from 'react'
import { tr } from '@constants/other'
import Modal from '@components/molecules/Modal'
import { ReactComponent as WarningSVG } from '@assets/warning_gray.svg'
import Button from '@components/atoms/Button'
import { ErrorModalProps } from './types'

const ErrorModal = ({
  show,
  onClose,
  children,
  title,
  desc,
  'data-testid': testId = '',
}: ErrorModalProps) => {
  const titleProp = (
    <div className="flex items-center">
      <WarningSVG className="flex-shrink-0 w-10 !mr-2" />
      {title}
    </div>
  )
  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      data-testid={testId}
      title={titleProp}
      displayCloseButton={false}
    >
      <Modal.Body>
        <div>
          <p className="whitespace-pre mt-2 mb-0">{desc}</p>
          {children}
        </div>
      </Modal.Body>
      <div className="flex justify-end">
        <Button
          variant="secondary"
          data-testid={`${testId}__cancel`}
          onClick={onClose}
        >
          {tr({ id: 'generic.close' })}
        </Button>
      </div>
    </Modal>
  )
}

export default ErrorModal
