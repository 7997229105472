import React, { useContext, useMemo, useState } from 'react'
import { TemplateContext } from '@services/providers/TemplateProvider'
import { BuffFormContext } from '@services/providers/BuffFormProvider'
import TemplateBuffConfirmation from '@components/molecules/TemplateBuffConfirmation'
import BuffForm from '@components/organisms/BuffForm'
import Alert from '@components/atoms/Alert'
import { useToast } from '@utils/hooks/useToast'
import { Template, TemplateCreate } from '@interfaces/Template'
import { getTemplateInitialValues } from '@utils/buffForm/template'
import { getSchemaWithoutLang } from '@utils/buffForm/schema'
import { CreateVoteableForm } from '@interfaces/Voteable'
import { BuffPageActionMode } from '@interfaces/BuffPage'
import { formatBuffDataToSubmit } from '@utils/formatBuffDataToSubmit'
import { tr } from '@constants/other'
import { TemplateBuffFormProps } from './types'

/**
 * Buff form to create new & edit existing templates
 * @param {TemplateBuffFormProps} args
 * @return {JSX.Element}
 */
const TemplateBuffForm = ({
  authors,
  availableLanguages,
  stream,
  theme,
  onSuccess,
}: TemplateBuffFormProps) => {
  const [templatePayload, setTemplatePayload] = useState<
    Template | TemplateCreate | null
  >(null)
  const { selectedTemplate } = useContext(TemplateContext)
  const { setActionMode } = useContext(BuffFormContext)
  const { addToast } = useToast()
  const firstLang = availableLanguages[0]

  const initialValues = useMemo(() => {
    return getTemplateInitialValues({
      template: selectedTemplate ?? undefined,
      languages: [firstLang],
    })
  }, [selectedTemplate, firstLang])

  const getSchema = getSchemaWithoutLang({ isTemplate: true })

  const handleSubmit = (values: CreateVoteableForm) => {
    const formattedValues = formatBuffDataToSubmit(
      values,
      false,
      [],
      true,
      0,
      false,
      true
    )
    setTemplatePayload({
      ...formattedValues,
      templateName: selectedTemplate?.templateName ?? '',
      streamId: selectedTemplate?.streamId ?? stream?.id,
    })
  }

  return (
    <>
      {Boolean(selectedTemplate) && (
        <Alert
          className="mt-3"
          text={tr(
            { id: 'template.editTemplateAlertText' },
            { templateName: selectedTemplate?.templateName }
          )}
          action={tr({ id: 'welcomeBuff.returnToList' })}
          actionCallback={() => {
            setActionMode(BuffPageActionMode.Load)
          }}
          actionClassName="font-bold underline"
        />
      )}
      <BuffForm
        initialValues={initialValues}
        authors={authors}
        getSchema={getSchema}
        availableLanguages={availableLanguages}
        onSubmit={handleSubmit}
        theme={theme}
        onReset={(values, resetForm) => {
          const blankInitialValues = getTemplateInitialValues({
            languages: [firstLang],
          })
          resetForm(blankInitialValues)
        }}
      />

      <TemplateBuffConfirmation
        mode={selectedTemplate ? 'update' : 'create'}
        id={selectedTemplate?.id}
        payload={templatePayload}
        show={Boolean(templatePayload)}
        onClose={() => setTemplatePayload(null)}
        onSuccessCallback={() => {
          addToast({
            msg: tr({ id: 'template.saveTemplateConfirmation' }),
            type: 'success',
          })
          onSuccess?.()
        }}
      />
    </>
  )
}

export default TemplateBuffForm
