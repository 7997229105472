import React, { FC } from 'react'
import { EmptyStateWithIconProps } from './types'

const EmptyStateWithIcon: FC<
  React.PropsWithChildren<EmptyStateWithIconProps>
> = ({
  icon,
  text,
  textClasses = '',
  containerClasses = '',
  iconClasses = '',
  children,
}) => {
  return (
    <div
      className={`relative flex flex-1 justify-center items-center ${containerClasses}`}
      style={{ height: '500px' }}
    >
      <div className="flex flex-col items-center">
        <div
          className={`flex justify-center items-center rounded-full h-[74px] w-[74px] bg-[#959DA5] mb-3 text-white ${iconClasses}`}
        >
          {icon}
        </div>
        <p className={`h5 mb-3 ${textClasses}`}>{text}</p>
        {children}
      </div>
    </div>
  )
}

export default EmptyStateWithIcon
