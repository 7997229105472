import React, { forwardRef, PropsWithChildren } from 'react'
import { RowProps } from './types'

const Row = forwardRef<HTMLDivElement, PropsWithChildren<RowProps>>(
  ({ className = '', 'data-testid': testId = 'row', children }, ref) => {
    return (
      <div
        data-testid={testId}
        className={`flex flex-wrap -mx-[15px] ${className}`}
        ref={ref}
      >
        {children}
      </div>
    )
  }
)

Row.displayName = 'Row'

export default Row
