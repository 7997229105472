import React, { FC, useContext, useState } from 'react'
import Button from '@components/atoms/Button'
import { BuffPageType } from '@interfaces/BuffPage'
import { BuffPageActiveTab } from '@pages/BuffPage'
import { tr } from '@constants/other'
import { BuffFormContext } from '@services/providers/BuffFormProvider'
import Alert from '@components/atoms/Alert'

export interface Props {
  isStreamNotStarted: boolean
  mainActiveTab: BuffPageActiveTab
}

const FormActionButtons: FC<Props> = ({
  isStreamNotStarted,
  mainActiveTab,
}) => {
  const { isSubmitting, setType } = useContext(BuffFormContext)

  return (
    <div data-testid="form-action-buttons-wrapper" className="flex flex-col">
      {isStreamNotStarted && (
        <div data-testid="steam-not-started-alert" className="my-2">
          <Alert
            className="w-full py-1.5"
            text={tr({ id: 'stream.saveTemplateBeforeStreamStarts' })}
          />
        </div>
      )}
      <div className="flex items-center gap-2 my-2">
        <div className="flex w-full justify-end">
          <Button
            size="small"
            form="buff-form"
            variant="secondary"
            className="w-full"
            type="submit"
            disabled={isSubmitting}
            onClick={() => {
              setType(BuffPageType.Template)
            }}
          >
            {tr({ id: 'template.saveAs' })}
          </Button>
        </div>
        <div className="flex w-full justify-end">
          <Button
            size="small"
            form="buff-form"
            className="w-full"
            type="submit"
            disabled={isSubmitting || isStreamNotStarted}
            data-testid="buff-page-aside__submit-form"
            onClick={() => {
              setType(BuffPageType.Voteable)
            }}
          >
            {mainActiveTab === BuffPageActiveTab.AnnouncementListView
              ? tr({ id: 'generic.sendAnnouncement' })
              : tr({ id: 'generic.sendBuff' })}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FormActionButtons
