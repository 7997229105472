import { InfiniteData } from 'react-query'

export const flattenInfiniteData = <T, S>(
  key: string,
  data?: InfiniteData<T>
) => {
  return data && data?.pages
    ? data.pages.flatMap((page: any) => {
        return page[key] as S
      })
    : []
}
