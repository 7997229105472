import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { AnnouncementTemplate, Template } from '@interfaces/Template'

export interface TemplateContextProps {
  currentTemplateIdSelected: string
  selectedTemplate: Template | null
  setSelectedTemplate: (template: Template | null) => void
  selectedAnnouncementTemplate: AnnouncementTemplate | null
  setSelectedAnnouncementTemplate: (
    template: AnnouncementTemplate | null
  ) => void
  resetSelectedTemplates: () => void
}

export const TemplateContext = createContext<TemplateContextProps>(
  {} as TemplateContextProps
)

export const TemplateProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [currentTemplateIdSelected, setCurrentTemplateIdSelected] =
    useState<string>('')
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  )
  const [selectedAnnouncementTemplate, setSelectedAnnouncementTemplate] =
    useState<AnnouncementTemplate | null>(null)

  const resetSelectedTemplates = useCallback(() => {
    setSelectedTemplate(null)
    setSelectedAnnouncementTemplate(null)
    setCurrentTemplateIdSelected('')
  }, [])

  useEffect(() => {
    setCurrentTemplateIdSelected(selectedTemplate?.id || '')
  }, [selectedTemplate])

  useEffect(() => {
    setCurrentTemplateIdSelected(selectedAnnouncementTemplate?.id || '')
  }, [selectedAnnouncementTemplate])

  return (
    <TemplateContext.Provider
      value={{
        selectedTemplate,
        setSelectedTemplate,
        selectedAnnouncementTemplate,
        setSelectedAnnouncementTemplate,
        resetSelectedTemplates,
        currentTemplateIdSelected,
      }}
    >
      {children}
    </TemplateContext.Provider>
  )
}
