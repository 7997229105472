import React, { FC, useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { tr } from '@constants/other'
import { IStream, StreamStatus } from '@interfaces/IStream'
import { getStreamStatus } from '@utils/stream'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { endGameById, useGetGame } from '@services/requests/games'
import { useToast } from '@utils/hooks/useToast'
import Button from '@components/atoms/Button'
import { Alignment } from '../ConfirmationModal/types'
import EndStreamModalContent from '../EndStreamModalContent'

interface IProps {
  stream?: IStream
  handlePlayStop: (streamId: string, isLive: boolean) => Promise<void>
  handleCreateVOD?: (streamId: string) => void
}

const StreamHeaderButtonGroup: FC<React.PropsWithChildren<IProps>> = ({
  stream,
  handlePlayStop,
  handleCreateVOD,
}): JSX.Element => {
  const { addErrorToast } = useToast()
  const status = useMemo(() => getStreamStatus(stream), [stream])
  const isStatusLive = status === StreamStatus.live
  const isStatusEnding = status === StreamStatus.ending
  const isStatusVod = status === StreamStatus.vod
  const isStatusInactive = status === StreamStatus.inactive
  const [isGameEnded, setIsGameEnded] = useState(false)
  const [showEndStreamModal, setShowEndStreamModal] = useState(false)
  const { gameId, id: streamId } = useParams()

  const { data: gameData } = useGetGame({
    gameId: gameId ?? '',
    options: { enabled: !!gameId },
  })

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const navigate = useNavigate()

  const displayPlayStopButton = status && !isStatusVod

  useEffect(() => {
    setIsGameEnded(gameData?.game?.ended ?? false)
  }, [gameData?.game?.ended])

  const handleSubmit = async () => {
    if (!stream || !stream.id) return
    setIsSubmitting(true)
    await handlePlayStop(stream.id, isStatusEnding || isStatusLive)
    setIsSubmitting(false)
  }

  const handlePlayStopClick = async () => {
    if (isStatusLive && !stream?.endedAt) {
      setShowEndStreamModal(true)
      return
    }
    handleSubmit()
  }

  const handleSuccess = async () => {
    try {
      if (!gameId) return
      const { data } = await endGameById(gameId)
      setIsGameEnded(data?.game?.ended ?? false)
    } catch (error) {
      addErrorToast(error)
    } finally {
      setShowEndStreamModal(false)
    }
  }

  const startVodMode = async () => {
    if (!streamId) return
    handleCreateVOD?.(streamId)
  }

  const displayButtonGroup = () => {
    const startStopText =
      status && isStatusLive
        ? tr({ id: 'stream.stopStream' })
        : tr({ id: 'stream.startStream' })

    let startStopImage =
      status && isStatusLive
        ? '/assets/images/button-stop-buff-white.png'
        : '/assets/images/button-play-buff-white.png'

    let buttonText =
      status && isStatusInactive
        ? tr({ id: 'generic.prepareBuffs' })
        : isStatusVod
        ? tr({ id: 'replay.editBuffs' })
        : tr({ id: 'generic.sendBuffs' })

    // Leaving the structure like this because probably in the future we will add icons to each
    const rightButtonImage =
      status && isStatusInactive
        ? ''
        : isStatusVod
        ? ''
        : '/assets/svg/icon-send.svg'

    if (!status) {
      startStopImage = '/assets/images/button-play-buff-white.png'
      buttonText = tr({ id: 'generic.prepareBuffs' })
    }

    return (
      <>
        {isGameEnded && !stream?.endedAt && stream?.liveAt && (
          <Button
            onClick={startVodMode}
            variant="success"
            data-testid="stream-header__button-create-vod"
            startElement={
              <img
                src="/assets/images/button-play-buff-white.png"
                className="-ml-4 mr-1"
              />
            }
          >
            {tr({ id: 'stream.createVOD' })}
          </Button>
        )}
        {status && !isStatusEnding && displayPlayStopButton && !isGameEnded && (
          <Button
            disabled={isSubmitting}
            onClick={handlePlayStopClick}
            variant={isStatusLive ? 'danger' : 'success'}
            data-testid="stream-header__button-start-stop"
            startElement={<img src={startStopImage} className="-ml-4 mr-2" />}
          >
            {startStopText}
          </Button>
        )}
        {!status && (
          <>
            <Button
              disabled
              className="cursor-not-allowed"
              variant="secondary"
              data-testid="stream-header__button-start-stop"
            >
              <img src={startStopImage} className="-mx-4" />
            </Button>
            <Button
              className="mx-2 cursor-not-allowed"
              disabled
              variant="secondary"
              data-testid="stream-header__button-prepare-buff"
            >
              {buttonText}
            </Button>
          </>
        )}

        {status && (
          <Button
            className="mx-2"
            variant="primary"
            disabled={!status}
            onClick={() => {
              isStatusVod
                ? navigate(`/streams/${streamId}/${gameId}/replay`)
                : navigate(`/streams/${streamId}/${gameId}/play`)
            }}
            startElement={
              rightButtonImage && (
                <img src={rightButtonImage} className="-ml-2 mr-2 h-6" />
              )
            }
          >
            <span>{buttonText}</span>
          </Button>
        )}
        <ConfirmationModal
          onClose={() => setShowEndStreamModal(false)}
          onSuccess={handleSuccess}
          title={tr({ id: 'stream.endThisStream' })}
          successBtnText={tr({ id: 'stream.endThisStreamBtnConfirmation' })}
          subtext={tr({ id: 'stream.endThisStreamSubtext' })}
          show={showEndStreamModal}
          data-testid="end-stream-modal"
          alignment={Alignment.END}
        >
          {showEndStreamModal && (
            <EndStreamModalContent streamId={streamId} gameId={gameId} />
          )}
        </ConfirmationModal>
      </>
    )
  }

  return (
    <div className="flex justify-end mb-2 stream-header-button-group">
      {displayButtonGroup()}
    </div>
  )
}

export default StreamHeaderButtonGroup
