import { IOnboard, IOnboardResponse } from '@interfaces/IOnboard'
import { CreateVoteable } from '@interfaces/Voteable'
import { client } from '@services/requests/client'
import { ApiResponse } from '../../constants/other'

/**
 * @param {ICreateVoteable} payload
 * @return {Promise<ApiResponse<IOnboardResponse<IOnboard>>>}
 */
export const createWelcomeBuff = (
  payload: CreateVoteable
): Promise<ApiResponse<IOnboardResponse<IOnboard>>> => {
  return client.post('/onboarding/welcome-buff', payload)
}

/**
 * @return {Promise<ApiResponse<IOnboardResponse<IOnboard[]>>>}
 */
export const getWelcomeBuffs = (): Promise<
  ApiResponse<IOnboardResponse<IOnboard[]>>
> => {
  return client.get('/onboarding/welcome-buff')
}

/**
 * @param {ICreateVoteable} payload
 * @param {string} welcomeBuffId
 * @return {Promise<ApiResponse<IOnboardResponse<IOnboard>>>}
 */
export const updateWelcomeBuff = (
  payload: Partial<CreateVoteable>,
  welcomeBuffId: string
): Promise<ApiResponse<IOnboardResponse<IOnboard>>> => {
  return client.patch(`/onboarding/welcome-buff/${welcomeBuffId}`, payload)
}

/**
 * @param {string} welcomeBuffId
 * @return {Promise<ApiResponse<IOnboardResponse<IOnboard>>>}
 */
export const deleteWelcomeBuff = (
  welcomeBuffId: string
): Promise<ApiResponse<IOnboardResponse<IOnboard>>> => {
  return client.delete(`/onboarding/welcome-buff/${welcomeBuffId}`)
}
