import React, {
  ReactNode,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
} from 'react'
import Postmate from 'postmate'
import { PreviewBuff } from '@interfaces/buff'
import { isAnnouncement } from '@utils/voteables'
import { LanguageCode } from '@interfaces/ILanguage'
import { Theme } from '@interfaces/IStreamConfig'
import backgroundImage from '@assets/buff-preview-background.png'
import { QR } from '@components/organisms/QrCodePreview/types'
import { useTheme } from '@utils/hooks/useTheme'

export interface PreviewContextProps {
  setBuff: Dispatch<SetStateAction<PreviewBuff | null>>
  setQR: Dispatch<SetStateAction<QR | null>>
  setPreviewElement: Dispatch<SetStateAction<HTMLElement | null>>
  setActiveLanguage: Dispatch<SetStateAction<LanguageCode | undefined>>
  setTheme: Dispatch<SetStateAction<Theme>>
}

export interface PreviewProviderProps {
  children?: ReactNode
}

const renameKey = (obj: unknown, keyName: string, newKeyName: string): any => {
  if (Array.isArray(obj)) {
    return obj.map((obj) => {
      return renameKey(obj, keyName, newKeyName)
    })
  } else if (typeof obj === 'object' && obj !== null) {
    const clone = JSON.parse(JSON.stringify(obj))
    const parts = Object.entries(clone)
    parts.forEach(([key, value]) => {
      let newValue = value
      if (typeof value === 'object' && value !== null) {
        newValue = renameKey(value, keyName, newKeyName)
      }
      if (key === keyName) {
        clone[newKeyName] = newValue
        delete clone[key]
      } else {
        clone[key] = newValue
      }
    })

    return clone
  }

  return obj
}

export const PreviewContext = createContext<PreviewContextProps>(
  {} as PreviewContextProps
)

export const PreviewProvider = ({ children }: PreviewProviderProps) => {
  const [buff, setBuff] = useState<PreviewBuff | null>(null)
  const [QR, setQR] = useState<QR | null>(null)
  const [activeLanguage, setActiveLanguage] = useState<LanguageCode>()
  const [previewElement, setPreviewElement] = useState<HTMLElement | null>(null)
  const [theme, setTheme] = useState<Theme>(Theme.LIGHT)
  const postmateRef = useRef<Postmate>()

  const props = {
    setBuff,
    setQR,
    setPreviewElement,
    setActiveLanguage,
    setTheme,
  }

  useEffect(() => {
    if (previewElement) {
      const handshake = new Postmate({
        container: previewElement,
        url: '/preview.html',
      })

      postmateRef.current = handshake

      return () => {
        handshake.then((child) => child.destroy())
        postmateRef.current = undefined
      }
    }
  }, [previewElement])

  useEffect(() => {
    if (!buff) return
    if (previewElement && postmateRef.current) {
      postmateRef.current.then((child) => {
        let buffType = ''
        const data: any = buff ? { ...buff } : null

        if (data !== null) {
          if (isAnnouncement(data)) {
            buffType = 'games:announcement.create'
          } else if (data.content) {
            data.announcementId = 'ID'
            buffType = 'games:announcement.create'
          } else {
            buffType = 'games:voteable.open'
            if (!data.voteableId) {
              data.voteableId = 'ID'
            }
          }
        }

        child.call('setBuff', {
          data,
          buffType,
          lang: activeLanguage,
          theme,
        })
      })
    }
  }, [buff, postmateRef, previewElement, activeLanguage, theme])

  useEffect(() => {
    if (previewElement && postmateRef.current) {
      postmateRef.current.then((child) => {
        child.call('setQR', {
          data: QR,
          lang: activeLanguage,
          theme,
        })
      })
    }
  }, [QR, postmateRef, previewElement, activeLanguage, theme])

  return (
    <PreviewContext.Provider value={props}>{children}</PreviewContext.Provider>
  )
}

export const PreviewProviderDataSource = (props: {
  buff?: PreviewBuff
  qr?: QR
  activeLanguage: LanguageCode
  theme?: Theme
}) => {
  const { theme } = useTheme(props.theme)
  const { setBuff, setQR, setActiveLanguage, setTheme } =
    useContext(PreviewContext)

  useEffect(() => {
    if (!props.buff) return
    const transformedBuff = renameKey(props.buff, 'imageURL', 'imageUrl')

    // Templates can have unspecified layout which breaks the preview so default to horizontal
    if (transformedBuff.layout === 'VOTEABLE_LAYOUT_UNSPECIFIED') {
      transformedBuff.layout = 'VOTEABLE_LAYOUT_HORIZONTAL'
    }
    setBuff(transformedBuff)
    return () => setBuff(null)
  }, [props.buff, setBuff])

  useEffect(() => {
    if (!props.qr) return

    setQR(props.qr)

    return () => setQR(null)
  }, [props.qr, setQR])

  useEffect(() => {
    setActiveLanguage(props.activeLanguage)
  }, [props.activeLanguage, setActiveLanguage])

  useEffect(() => {
    if (theme) setTheme(theme)
  }, [theme, setTheme])

  return null
}

export const BuffPreviewProviderRenderer = (props: { className?: string }) => {
  const { setPreviewElement } = useContext(PreviewContext)
  return (
    <div
      ref={setPreviewElement}
      className={`h-[250px] rounded w-full bg-[#717A85] [&>*]:w-full [&>*]:h-[110%] [&>*]:border-none ${props.className}`}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    />
  )
}

export const QRPreviewProviderRenderer = () => {
  const { setPreviewElement } = useContext(PreviewContext)
  return (
    <div
      ref={setPreviewElement}
      className="h-[250px] w-full bg-[#717A85] [&>*]:w-auto [&>*]:h-auto [&>*]:border-none rounded overflow-hidden"
    />
  )
}
