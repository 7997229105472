import React, { FC, useContext, useEffect, useState } from 'react'

import NameModalForm from '@components/organisms/NameModalForm'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { useToast } from '@utils/hooks/useToast'
import { WelcomeBuffContext } from '@services/providers/WelcomeBuffProvider'
import { tr } from '@constants/other'
import { ICreateWelcomeVoteable } from '@interfaces/Voteable'
import {
  createWelcomeBuff,
  updateWelcomeBuff,
} from '@services/requests/onboarding'
import schema from './schema'
import { WelcomeBuffConfirmationProps } from './types'

const WelcomeBuffConfirmation: FC<
  React.PropsWithChildren<WelcomeBuffConfirmationProps>
> = ({ show, payload, onSuccessCallback, onClose }) => {
  const [visible, setVisible] = useState(show)
  const [welcomeBuffName, setWelcomeBuffName] = useState<string>('')
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const { selectedWelcomeBuff } = useContext(WelcomeBuffContext)
  const { addErrorToast } = useToast()
  const isTemplateMode = !!selectedWelcomeBuff

  useEffect(() => {
    if (!selectedWelcomeBuff) return
    setWelcomeBuffName(selectedWelcomeBuff.name)
    setIsButtonDisabled(false)
  }, [selectedWelcomeBuff])

  const onCreateWelcomeBuff = async () => {
    try {
      const data = {
        ...payload,
        name: welcomeBuffName,
      }

      await createWelcomeBuff(data as ICreateWelcomeVoteable)
      onSuccessCallback && onSuccessCallback()
      handleClose()
    } catch (err) {
      addErrorToast(err)
    }
  }

  const onUpdateWelcomeBuff = async () => {
    if (!selectedWelcomeBuff) return
    try {
      const data = {
        ...payload,
        name: welcomeBuffName,
      }

      await updateWelcomeBuff(
        data as ICreateWelcomeVoteable,
        selectedWelcomeBuff.welcomeBuffId
      )
      onSuccessCallback && onSuccessCallback()
      handleClose()
    } catch (err) {
      addErrorToast(err)
    }
  }

  const onSuccess = () => {
    isTemplateMode ? onUpdateWelcomeBuff() : onCreateWelcomeBuff()
  }

  const handleClose = () => {
    setVisible(false)
    onClose && onClose()
  }

  useEffect(() => {
    setVisible(show)
  }, [show, setVisible])

  return (
    <>
      <ConfirmationModal
        show={visible}
        onClose={handleClose}
        onSuccess={onSuccess}
        disabled={isButtonDisabled}
        data-testid="create-welcome-buff-confirmation"
        title={tr({ id: 'welcomeBuff.confirmationTitle' })}
        successBtnText={tr({
          id: 'welcomeBuff.saveWelcomeBuff',
        })}
      >
        <NameModalForm
          nameKey="name"
          nameValue={welcomeBuffName}
          validationSchema={schema}
          updateName={(name: string) => setWelcomeBuffName(name)}
          updateDisabled={(disableValue: boolean) =>
            setIsButtonDisabled(disableValue)
          }
        />
      </ConfirmationModal>
    </>
  )
}

export default WelcomeBuffConfirmation
