import { useEffect } from 'react'
import { cloneDeep } from 'lodash'
import Sortable from 'sortablejs'
import { Leaderboard } from '@interfaces/Leaderboard'

interface ReorderLeaderboardsProps {
  values: Leaderboard[]
  onChange: (values: Leaderboard[]) => void
}

const ReorderLeaderboards = ({
  values,
  onChange,
}: ReorderLeaderboardsProps) => {
  useEffect(() => {
    let sortable: Sortable
    const list = document.getElementById('stream-leaderboards-list')
    if (list) {
      sortable = new Sortable(list, {
        animation: 350,
        handle: '.leaderboard-handle',
        swapThreshold: 0.65,
        ghostClass: 'sortable-ghost',
        onMove: (e) => {
          if (e?.related) {
            return !e.related?.classList?.contains?.('locked')
          }
        },
        onEnd: (e) => {
          if (e.oldIndex == null || e.newIndex == null) {
            onChange(values)
            return
          }

          const item = values[e.oldIndex]
          const answers = cloneDeep(values)
          answers.splice(e.oldIndex, 1)
          answers.splice(e.newIndex, 0, item)
          onChange(answers)
        },
      })
    }
    return () => sortable?.destroy?.()
  }, [values, onChange])

  return null
}

export default ReorderLeaderboards
