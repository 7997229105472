import React, { FC, useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useQuery } from 'react-query'
import { tr } from '@constants/other'
import Container from '@components/atoms/Container'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import { useGetUser, useGetUserRoles } from '@services/requests/users'
import { Role } from '@pages/TeamMembers/types'
import TvSettings from '@components/organisms/TvSettings'
import ClientLanguages from '@components/organisms/ClientLanguages'
import Sounds from '@components/organisms/Sounds'
import Avatars from '@components/organisms/Avatars'
import SignupConfig from '@components/organisms/SignupConfig'
import PlatformConfig from '@components/organisms/PlatformConfig'
import UiElementsConfig from '@components/organisms/UiElementsConfig'
import ClientChatConfig from '@components/molecules/Chat/ClientChatConfig'
import { useClientConfig } from '@services/requests/client-config'
import { Platform } from '@interfaces/IStreamConfig'
import Aside from './components/Aside'

const ENABLE_GLOBAL_CHAT = process.env.GLOBAL_CHAT === 'true'

const ClientConfig: FC = () => {
  const navigate = useNavigate()
  const { getPlatforms } = useClientConfig()

  const { pathname } = useLocation()
  const { data: platforms } = useQuery(['platforms'], getPlatforms)
  const { data: userData } = useGetUser()
  const { data: roleData } = useGetUserRoles(userData?.id, {
    enabled: !!userData?.id,
  })

  const ENABLE_TV_TAB =
    platforms?.platforms?.includes(Platform.AndroidTV) ||
    platforms?.platforms?.includes(Platform.TizenTV)

  const path = '/client-config'

  useEffect(() => {
    if (!roleData) return
    // If role does not include AccountOwner or Admin, redirect to home
    if (
      !(
        roleData?.roles.includes(Role.AccountOwner) ||
        roleData?.roles.includes(Role.Admin)
      )
    ) {
      navigate('/')
    }
  }, [navigate, roleData])

  const components = {
    platforms: {
      title: tr({ id: 'configuration.platforms' }),
      component: <PlatformConfig />,
    },
    language: {
      title: tr({ id: 'configuration.language' }),
      component: <ClientLanguages />,
    },
    ...(ENABLE_TV_TAB && {
      'tv-settings': {
        title: tr({ id: 'configuration.tvSettings' }),
        component: <TvSettings />,
      },
    }),
    sdk: {
      title: tr({ id: 'configuration.sdk' }),
      component: <UiElementsConfig />,
    },
    signup: {
      title: tr({ id: 'configuration.signup' }),
      component: <SignupConfig />,
    },
    sounds: {
      title: tr({ id: 'configuration.sounds' }),
      component: <Sounds />,
    },
    avatars: {
      title: tr({ id: 'configuration.avatars' }),
      component: <Avatars />,
    },
    ...(ENABLE_GLOBAL_CHAT && {
      'global-chat': {
        title: tr({ id: 'globalChat.chat' }),
        component: <ClientChatConfig />,
      },
    }),
  }

  const componentEntries = Object.entries(components)

  const tabs = componentEntries
    .filter(([slug]) => !!slug)
    .map(([slug, { title }]) => {
      return {
        text: title,
        href: `${path}/${slug}`,
      }
    })

  const tabIndex = componentEntries.findIndex(([slug]) => {
    return pathname.includes(slug)
  })

  const showBuffPreview = tabIndex === 2 && ENABLE_TV_TAB

  const initialPath = `${path}/platforms`

  return (
    <>
      <Container>
        <Row className="pl-2">
          <Col sm={8}>
            <div className="flex flex-col my-4">
              <h1 className="h3 font-bold mb-2 mr-2.5">
                {tr({ id: 'configuration.title' })}
              </h1>
              <span className="text-[1.125rem]">
                {tr({ id: 'configuration.description' })}
              </span>
            </div>
          </Col>
        </Row>

        <Row className="pl-2">
          <Col sm={8}>
            <div className="mt-1 mb-8 px-4 py-3 bg-white rounded">
              <Routes>
                <Route path={`/`} element={<Navigate to={initialPath} />} />
                <Route
                  path={`/*`}
                  element={
                    <div>
                      <Tabs tabs={tabs} selectedIndex={tabIndex}>
                        {componentEntries.map(([slug, { component }]) => {
                          return (
                            <TabPanel key={slug} className="!mt-4 !pt-6 !pb-2">
                              {component}
                            </TabPanel>
                          )
                        })}
                      </Tabs>
                    </div>
                  }
                />
              </Routes>
            </div>
          </Col>
          {showBuffPreview && (
            <Col sm={4}>
              <div className="mt-1 p-3 bg-white sticky top-6 shadow-card rounded  ">
                <Aside />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}

export default ClientConfig
