import { LanguageCode } from '@interfaces/ILanguage'
import { LocalisedContent } from '@interfaces/localisation'

export const parseLocalisationObjectToArray = <T extends LocalisedContent>(
  localisations: Partial<Record<LanguageCode, T>>
): T[] => {
  const arr: T[] = []

  Object.keys(localisations).forEach((langStr) => {
    const lang = langStr as LanguageCode
    const obj = localisations[lang]
    if (obj) {
      arr.push({
        ...obj,
        localisation: lang,
      })
    }
  })

  return arr
}
