import React, { useContext, useState } from 'react'
import { ReactComponent as Expand } from '@assets/expand.svg'
import { ReactComponent as Chevron } from '@assets/chevron.svg'
import { tr } from '@constants/other'
import {
  BuffPreviewProviderRenderer,
  PreviewProviderDataSource,
} from '@services/providers/PreviewProvider'
import { TemplateContext } from '@services/providers/TemplateProvider'
import { LanguageCode } from '@interfaces/ILanguage'
import { Theme } from '@interfaces/IStreamConfig'
import { WelcomeBuffContext } from '@services/providers/WelcomeBuffProvider'

const BuffPreviewWidget = ({
  theme,
  showEmpty = true,
}: {
  theme?: Theme
  showEmpty?: boolean
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const { selectedTemplate, selectedAnnouncementTemplate } =
    useContext(TemplateContext)
  const { selectedWelcomeBuff } = useContext(WelcomeBuffContext)
  const buffPreviewValues =
    selectedTemplate ?? selectedAnnouncementTemplate ?? selectedWelcomeBuff
  const activeLanguage = buffPreviewValues?.localisations[0] ?? LanguageCode.en

  let sizeClass = 'w-full'

  const nonExpandedBoxShadow = '0px 5px 15px 0px #090E2518'
  const expandedBoxShadow =
    '0px 25.6px 57.6px 0px #191C1F38, 0px 4.8px 14.4px 0px #0000002E'
  const buffSectionStyle = {
    boxShadow: !isExpanded ? nonExpandedBoxShadow : expandedBoxShadow,
  }

  if (isExpanded) {
    sizeClass = 'w-[150%] h-[389px] absolute top-0 right-0 z-10'
  }

  return (
    <div className="relative">
      {isExpanded && (
        <div className={`h-[320px] w-full mt-1 p-3 bg-transparent`}></div>
      )}
      <div
        className={`${sizeClass} rounded-md p-3 bg-white`}
        style={buffSectionStyle}
      >
        <header
          className={`flex w-full justify-between font-bold select-none ${
            isOpen ? 'mb-2' : ''
          }`}
        >
          <div className="pb-2" onClick={() => setIsOpen((val) => !val)}>
            {tr({ id: 'generic.buffPreview' })}
            <Chevron
              className={`h-6 w-6 ${isExpanded ? 'hidden' : 'inline-block'}`}
              style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
            />
          </div>
          <div>
            <div onClick={() => setIsExpanded((val) => !val)}>
              <Expand className="h-6 w-6" />
            </div>
          </div>
        </header>
        {(isOpen || isExpanded) && (
          <section className={`h-full`}>
            {(buffPreviewValues != null ||
              (buffPreviewValues == null && showEmpty)) && (
              <BuffPreviewProviderRenderer
                className={isExpanded ? 'h-[325px]' : 'h-[255px]'}
              />
            )}
          </section>
        )}
        {buffPreviewValues !== null && (
          <PreviewProviderDataSource
            theme={theme}
            buff={buffPreviewValues}
            activeLanguage={activeLanguage}
          />
        )}
      </div>
    </div>
  )
}

export default BuffPreviewWidget
