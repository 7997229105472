import React, {
  useState,
  useContext,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useMemo,
  Fragment,
  useEffect,
} from 'react'
import { get } from 'lodash'
import {
  Formik,
  Form,
  FormikProps,
  FormikHelpers,
  FormikErrors,
  FormikTouched,
} from 'formik'
import { tr } from '@constants/other'
import TextInput from '@components/atoms/TextInput'
import ImageFieldButton from '@components/atoms/ImageFieldButton'
import AssetManager from '@components/organisms/AssetManager'
import DurationInput from '@components/molecules/DurationInput'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { ILanguage, LanguageCode } from '@interfaces/ILanguage'
import { ReactComponent as AddSVG } from '@assets/add.svg'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'
import {
  LocalisedFormValue,
  LocalisedAuthorFormValue,
  LocalisedSponsorFormValue,
} from '@interfaces/localisation'
import {
  AnnouncementFormValues,
  VodAnnouncementFormValues,
} from '@interfaces/Announcement'
import preventFormSubmitOnEnter from '@utils/preventFormSubmitOnEnter'
import { Menu, Transition } from '@headlessui/react'
import deepOmit from '@utils/deepOmit'
import LanguageTab from '@components/atoms/LanguageTab'
import SelectAuthorInput from '@components/atoms/SelectAuthorInput'
import { getLanguagesMetadata } from '@utils/language'
import AutoPatchTouched from '@components/atoms/AutoPatchTouched'
import getAnnouncementTemplateInitialValues from '@utils/announcementForm/getAnnouncementTemplateInitialValues'
import Alert from '@components/atoms/Alert'
import Container from '@components/atoms/Container'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'
import { TemplateContext } from '@services/providers/TemplateProvider'
import { BuffFormContext } from '@services/providers/BuffFormProvider'
import SponsorFormSection from '@components/molecules/SponsorFormSection'
import { AnnouncementTemplate } from '@interfaces/Template'
import { CreateVoteableAuthorTemplateForm } from '@interfaces/Voteable'
import { AssetClass } from '@interfaces/Assets'
import Button from '@components/atoms/Button'
import { Announcement } from '@interfaces/Announcement'
import { PreviewProviderDataSource } from '@services/providers/PreviewProvider'
import { formatAnnouncementDataToSubmit } from '@utils/formatAnnouncementDataToSubmit'
import { Theme } from '@interfaces/IStreamConfig'
import { AcceptImageTypeExceptGift } from '@utils/asset'
import { BUFF_MAX_DURATION, BUFF_MIN_DURATION } from '@constants/form'
import { getSchema } from './schema'
import { AnnouncementFormProps, ValuesStateMap } from './types'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@components/molecules/Tooltip'
import FormattedInput from '@components/molecules/FormattedInput'
import './AnnouncementForm.styles.scss'

export const getInitialValues = (
  languages: LanguageCode[]
): AnnouncementFormValues => {
  const titleText: LocalisedFormValue = {}
  const text: LocalisedFormValue = {}
  const imageGroupId: LocalisedFormValue = {}
  const imageUrl: LocalisedFormValue = {}
  const buttonText: LocalisedFormValue = {}
  const linkUrl: LocalisedFormValue = {}
  const author: LocalisedAuthorFormValue = {}
  const sponsor: LocalisedSponsorFormValue = {}
  const showSponsor: Partial<Record<LanguageCode, boolean>> = {}

  const authorTemplate = {
    key: '',
    value: '',
  }

  languages.forEach((lang) => {
    titleText[lang] = ''
    text[lang] = ''
    imageGroupId[lang] = ''
    imageUrl[lang] = ''
    buttonText[lang] = ''
    linkUrl[lang] = ''
    author[lang] = {
      text: '',
      imageUrl: '',
    }
    sponsor[lang] = {
      text: '',
      linkTarget: '',
      imageUrl: '',
      imageAltText: '',
      localisation: lang,
    }

    showSponsor[lang] = false
  })

  return {
    author,
    authorTemplate,
    duration: DEFAULT_DURATION,
    titleText,
    text,
    imageGroupId,
    imageUrl,
    sponsor,
    buttonText,
    linkUrl,
    showSponsor,
  }
}

const DEFAULT_DURATION = 15

export const getVodInitialValues = (
  languages: LanguageCode[]
): VodAnnouncementFormValues | undefined => {
  const initialValues = getInitialValues(languages)
  return {
    ...initialValues,
    showAt: DEFAULT_DURATION,
    showFor: DEFAULT_DURATION,
  }
}

const AnnouncementFormWithRef: ForwardRefRenderFunction<
  FormikProps<AnnouncementFormValues> | null,
  AnnouncementFormProps
> = (
  {
    authors,
    languages: availableLanguages = undefined,
    initialValues,
    theme = Theme.LIGHT,
    isGlobalTemplate,
    template,
    type = 'live',
    onSubmit,
    returnCallback,
  },
  ref
) => {
  const [isAssetManagerOpen, setIsAssetManagerOpen] = useState(false)
  const [selectedAssetManagerType, setSelectedAssetManagerType] = useState(
    AssetClass.AnnouncementTile
  )

  const { resetBuffFormContext, isUpdateActionMode, isLoadActionMode } =
    useContext(BuffFormContext)
  const { selectedAnnouncementTemplate } = useContext(TemplateContext)

  const [showReset, setShowReset] = useState<boolean>(false)
  const [initialValuesLocal, setInitialValuesLocal] = useState<
    AnnouncementFormValues | undefined
  >(initialValues)
  const [filteredLanguages, setFilteredLanguages] = useState<ILanguage[]>()
  const [hiddenLanguages, setHiddenLanguages] = useState<ILanguage[]>()
  const [languageTabSelected, setLanguageTabSelected] = useState<LanguageCode>()

  const activeLanguage = languageTabSelected
  const filteredLanguageCodes = useMemo(() => {
    return filteredLanguages?.map((fLang) => fLang.iso6391) || []
  }, [filteredLanguages])

  const shouldPrefillFields = useMemo(
    () =>
      (Boolean(selectedAnnouncementTemplate) && isUpdateActionMode) ||
      (Boolean(selectedAnnouncementTemplate) && isLoadActionMode),
    [selectedAnnouncementTemplate, isUpdateActionMode, isLoadActionMode]
  )

  const authorsDropdownOptions = useMemo(() => {
    return authors.map((author) => {
      return {
        key: author.id,
        value: author.displayName,
        data: author,
      } as CreateVoteableAuthorTemplateForm
    })
  }, [authors])

  const schema = useMemo(
    () => getSchema(filteredLanguageCodes),
    [filteredLanguageCodes]
  )

  const textInputWritingMode =
    activeLanguage === LanguageCode.ar ? 'rtl' : undefined

  const openAssetManagerByType = (type: AssetClass) => {
    setSelectedAssetManagerType(type)
    setIsAssetManagerOpen(true)
  }

  const removeLanguage = useCallback((language: ILanguage) => {
    setFilteredLanguages((langs) =>
      langs?.filter((lang) => lang.id !== language.id)
    )
    setHiddenLanguages((langs) => langs && [...langs, language])
  }, [])

  const addLanguage = async (lang: ILanguage) => {
    if (!hiddenLanguages) return
    const newHiddenLanguages = hiddenLanguages.filter((l) => {
      return l.iso6391 !== lang.iso6391
    })
    setHiddenLanguages(newHiddenLanguages ? newHiddenLanguages : [])
    await setFilteredLanguages((currentLangs) => {
      if (!currentLangs) return
      return [...currentLangs, lang]
    })
    setLanguageTabSelected(lang.iso6391)
  }

  const addAllLanguages = () => {
    setHiddenLanguages([])
    setFilteredLanguages((currentLangs) => {
      if (!currentLangs || !hiddenLanguages) return
      return [...currentLangs, ...hiddenLanguages]
    })
  }

  // TODO: I think we should move this functionality in the future to tab Routes
  // Resets when the form unmounts
  useEffect(() => {
    return () => {
      resetBuffFormContext()
    }
  }, [resetBuffFormContext])

  useEffect(() => {
    if (!availableLanguages) return

    if (shouldPrefillFields && selectedAnnouncementTemplate?.localisations) {
      setInitialValuesLocal(
        getAnnouncementTemplateInitialValues(
          selectedAnnouncementTemplate as AnnouncementTemplate,
          authors,
          selectedAnnouncementTemplate?.localisations
        )
      )

      const selectedTemplateLangs = getLanguagesMetadata(
        selectedAnnouncementTemplate?.localisations
      )
      const allSupportedLangs = getLanguagesMetadata(availableLanguages)
      const languagesToHide = allSupportedLangs.filter((l) => {
        return !selectedAnnouncementTemplate?.localisations?.includes(l.iso6391)
      })

      setFilteredLanguages(selectedTemplateLangs)
      setHiddenLanguages(languagesToHide)
      return
    }

    if (!initialValues?.localisations) {
      setFilteredLanguages(() => {
        const langs = getLanguagesMetadata(availableLanguages)
        const [defaultLanguage, ...languagesToHide] = langs
        setHiddenLanguages([...languagesToHide])
        return [defaultLanguage]
      })
    }
  }, [
    availableLanguages,
    authors,
    selectedAnnouncementTemplate,
    shouldPrefillFields,
    initialValues?.localisations,
  ])

  useEffect(() => {
    if (!availableLanguages || !initialValues?.localisations) return

    const selectedTemplateLangs = getLanguagesMetadata(
      initialValues?.localisations
    )
    const allSupportedLangs = getLanguagesMetadata(availableLanguages)

    const languagesToHide = allSupportedLangs.filter((l) => {
      return !initialValues?.localisations?.includes(l.iso6391)
    })

    setFilteredLanguages(selectedTemplateLangs)
    setHiddenLanguages(languagesToHide)
  }, [initialValues?.localisations, availableLanguages])

  useEffect(() => {
    if (!filteredLanguages) return
    setLanguageTabSelected(filteredLanguages?.[0]?.iso6391 || [])
  }, [filteredLanguages])

  const generateValuesState = (
    languages: LanguageCode[],
    errors: FormikErrors<AnnouncementFormValues>,
    touched: FormikTouched<AnnouncementFormValues>
  ): Map<LanguageCode, ValuesStateMap> => {
    const values = new Map<LanguageCode, ValuesStateMap>()

    languages.forEach((lang) => {
      const titleKey = `titleText.[${lang}]`
      const titleError = get(errors, titleKey)
      const titleTouched = get(touched, titleKey)

      const textKey = `text.[${lang}]`
      const textError = get(errors, textKey)
      const textTouched = get(touched, textKey)

      const buttonTextKey = `buttonText.[${lang}]`
      const buttonTextError = get(errors, buttonTextKey)
      const buttonTextTouched = get(touched, buttonTextKey)

      const linkUrlKey = `linkUrl.[${lang}]`
      const linkUrlError = get(errors, linkUrlKey)
      const linkUrlTouched = get(touched, linkUrlKey)

      const authorKey = `author.[${lang}]`
      const authorError = get(errors, authorKey)
      const authorTouched = get(touched, authorKey)

      const sponsorKey = `sponsor.[${lang}]`
      const sponsorError = get(errors, sponsorKey)
      const sponsorTouched = get(touched, sponsorKey)

      const showLangError =
        (Boolean(buttonTextTouched) && Boolean(buttonTextError)) ||
        (Boolean(linkUrlTouched) && Boolean(linkUrlError)) ||
        (Boolean(textTouched) && Boolean(textError)) ||
        (Boolean(titleTouched) && Boolean(titleError)) ||
        (Boolean(sponsorTouched) && Boolean(sponsorError))

      values.set(lang, {
        showError: showLangError,
        titleKey,
        titleError,
        titleTouched,
        textKey,
        textError,
        textTouched,
        buttonTextKey,
        buttonTextError,
        buttonTextTouched,
        linkUrlKey,
        linkUrlError,
        linkUrlTouched,
        authorKey,
        authorError,
        authorTouched,
        sponsorKey,
        sponsorError,
        sponsorTouched,
      })
    })
    return values
  }

  const syncAuthorTemplateToAuthorFields = (
    authorTemplate: CreateVoteableAuthorTemplateForm,
    setFieldValue: FormikHelpers<unknown>['setFieldValue']
  ) => {
    if (!filteredLanguages) return
    filteredLanguages.forEach((l) => {
      setFieldValue(`author.[${l.iso6391}]`, {
        text: authorTemplate?.value?.trim() || '',
        imageUrl: authorTemplate?.data?.profilePicture || '',
      })
    })
  }

  const generateAuthorWhenLanguageAdded = (
    langs: ILanguage[],
    values: AnnouncementFormValues,
    setFieldValue: FormikHelpers<unknown>['setFieldValue']
  ) => {
    if (!filteredLanguages) return
    if (!values?.authorTemplate?.key) return

    langs.forEach((l) => {
      setFieldValue(`author.[${l.iso6391}]`, {
        text: values?.authorTemplate?.value?.trim() || '',
        imageUrl: values?.authorTemplate?.data?.profilePicture || '',
      })
    })
  }

  if (!activeLanguage || !initialValuesLocal) return <></>

  return (
    <Formik
      innerRef={ref}
      initialValues={initialValuesLocal}
      validateOnBlur
      enableReinitialize
      validationSchema={schema}
      onSubmit={(
        values: AnnouncementFormValues,
        helpers: FormikHelpers<AnnouncementFormValues>
      ) => onSubmit(values, helpers, filteredLanguageCodes)}
    >
      {(props) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          resetForm,
          setValues,
          setFieldTouched,
        } = props
        const valuesState = generateValuesState(
          filteredLanguageCodes,
          errors,
          touched
        )

        const removeLanguageForm = (language: ILanguage) => {
          setValues(deepOmit(values, language.iso6391))
        }

        const submitValues = formatAnnouncementDataToSubmit(
          values,
          filteredLanguageCodes
        )

        const buffPreviewValues: Announcement = {
          ...submitValues,
          announcementId: 'ID',
          gameId: 'ID',
        }

        return (
          <>
            {isUpdateActionMode && shouldPrefillFields && (
              <Alert
                className="mt-3"
                text={tr(
                  { id: 'template.editTemplateAlertText' },
                  { templateName: selectedAnnouncementTemplate?.templateName }
                )}
                action={tr({ id: 'welcomeBuff.returnToList' })}
                actionCallback={() => {
                  resetBuffFormContext()
                  returnCallback?.()
                }}
                actionClassName="font-bold underline"
              />
            )}
            {isGlobalTemplate && !!template && (
              <Alert
                className="mt-3"
                text={tr(
                  { id: 'template.editGlobalTemplateAlertText' },
                  { templateName: template?.templateName }
                )}
                action={tr({ id: 'welcomeBuff.returnToList' })}
                actionCallback={() => {
                  resetBuffFormContext()
                  returnCallback?.()
                }}
                actionClassName="font-bold underline"
              />
            )}
            <Form onKeyDown={preventFormSubmitOnEnter} id="buff-form">
              <AutoPatchTouched />

              {type !== 'vod' && (
                <Button
                  size="small"
                  className="absolute announcement-form__reset"
                  type="button"
                  onClick={() => setShowReset(true)}
                >
                  {tr({ id: 'generic.reset' })}
                </Button>
              )}

              <ConfirmationModal
                show={showReset}
                successBtnText={tr({ id: 'qaForm.startAgain' })}
                onClose={() => setShowReset(false)}
                onSuccess={() => {
                  const languages = filteredLanguageCodes
                  resetForm({ values: getInitialValues(languages) })
                  setShowReset(false)
                }}
                subtext={tr({ id: 'stream.deleteStreamConfirmationText' })}
                title={tr({ id: 'stream.deleteStreamConfirmationTitle' })}
              />
              <div
                data-testid="buff-details"
                className="py-3 my-3 rounded flex flex-col"
                style={{ backgroundColor: 'var(--white-background)' }}
              >
                <div className="w-full">
                  <Container>
                    <Row className={type === 'vod' ? 'flex-col' : ''}>
                      <Col sm={4}>
                        {/* Announcement Author Template */}
                        <SelectAuthorInput
                          id="authorTemplate"
                          label={tr({ id: 'qaForm.authorTemplate' })}
                          data-testid={`authorTemplate[${activeLanguage}]`}
                          style={{ border: '1px solid lightgrey' }}
                          className={'w-full p-1 rounded'}
                          value={values?.authorTemplate?.key}
                          options={authorsDropdownOptions}
                          onBlur={handleBlur}
                          onChange={(authorTemplate) => {
                            if (!authorTemplate) return
                            setFieldValue('authorTemplate', authorTemplate)
                            syncAuthorTemplateToAuthorFields(
                              authorTemplate,
                              setFieldValue
                            )
                          }}
                        />
                      </Col>

                      {type === 'vod' && (
                        <Col sm={8}>
                          <FormattedInput
                            id="publishTime"
                            label={
                              <div className="flex items-center gap-x-1">
                                <span>{tr({ id: 'qaForm.publishTime' })}</span>
                                <Tooltip placement="right">
                                  <TooltipTrigger>
                                    <div className="flex items-center gap-x-2 cursor-pointer">
                                      <InfoCircleIcon className="w-4 h-4" />
                                    </div>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <span>
                                      {tr({
                                        id: 'qaForm.announcementPublishTimeDesc',
                                      })}
                                    </span>
                                  </TooltipContent>
                                </Tooltip>
                              </div>
                            }
                            showButtons={true}
                            className={'w-100 px-2 py-1 rounded'}
                            value={1000}
                            onBlur={handleBlur}
                            onChange={(val) => setFieldValue('expiresIn', val)}
                            onReset={() => {}}
                            // error={Boolean(errors.expiresIn)}
                            // errorLabel={errors.expiresIn}
                            // touched={touched.expiresIn}
                          />
                        </Col>
                      )}

                      <Col sm={4}>
                        {type === 'vod' ? (
                          <FormattedInput
                            pattern="##:##"
                            id="duration"
                            label={tr({ id: 'qaForm.duration' })}
                            data-testid={`duration[${activeLanguage}]`}
                            showButtons={true}
                            className={'w-100 px-2 py-1 rounded'}
                            value={values.duration}
                            min={BUFF_MIN_DURATION}
                            max={BUFF_MAX_DURATION}
                            onChange={(duration) => {
                              setFieldValue('duration', duration)
                            }}
                            onReset={() =>
                              setFieldValue('duration', DEFAULT_DURATION)
                            }
                            onBlur={handleBlur}
                            error={Boolean(errors.duration)}
                            errorLabel={errors.duration}
                            touched={touched.duration}
                          />
                        ) : (
                          <DurationInput
                            id="duration"
                            label={tr({ id: 'qaForm.duration' })}
                            data-testid={`duration[${activeLanguage}]`}
                            showButtons={true}
                            className={'w-100 px-2 py-1 rounded'}
                            value={values.duration}
                            min={BUFF_MIN_DURATION}
                            max={BUFF_MAX_DURATION}
                            onChange={(duration) => {
                              setFieldValue('duration', duration)
                            }}
                            handleReset={() =>
                              setFieldValue('duration', DEFAULT_DURATION)
                            }
                            onBlur={handleBlur}
                            error={Boolean(errors.duration)}
                            errorLabel={errors.duration}
                            touched={touched.duration}
                          />
                        )}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              {filteredLanguages && (
                <div className="flex">
                  <ul className="inline-flex m-0 list-none p-0 space-x-0.5 overflow-scroll hide-scrollbar">
                    {filteredLanguages.map((lang) => (
                      <LanguageTab
                        key={lang.id}
                        lang={lang}
                        hasError={valuesState.get(lang.iso6391)?.showError}
                        onClick={setLanguageTabSelected}
                        isSelected={lang.iso6391 === languageTabSelected}
                        onDeleteClick={
                          filteredLanguages.length > 1
                            ? (l) => {
                                removeLanguage(l)
                                removeLanguageForm(l)
                              }
                            : undefined
                        }
                      />
                    ))}
                  </ul>
                  {/* Menu */}
                  {Boolean(hiddenLanguages?.length) && (
                    <Menu as="div" className="relative">
                      <Menu.Button className="bg-[#e6e9f4] rounded-t-lg border-none inline-flex items-center h-full">
                        <div className="language__tab--triangle mt-1 mr-1" />
                        <AddSVG />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute -left-2 w-40 origin-top-right bg-white rounded-sm shadow p-3 space-y-1 z-10">
                          {hiddenLanguages?.map((hiddenLanguage) => (
                            <Menu.Item
                              key={hiddenLanguage?.id}
                              as="button"
                              onClick={() => {
                                generateAuthorWhenLanguageAdded(
                                  [hiddenLanguage],
                                  values,
                                  setFieldValue
                                )
                                addLanguage(hiddenLanguage)
                              }}
                              className={`bg-transparent border-none flex p-0 hover:font-semibold`}
                            >
                              {hiddenLanguage?.name}
                            </Menu.Item>
                          ))}

                          <hr className="mt-2" />
                          <Menu.Item
                            as="button"
                            className="bg-transparent flex p-0 border-none text-blue-600"
                            onClick={() => {
                              if (hiddenLanguages) {
                                generateAuthorWhenLanguageAdded(
                                  hiddenLanguages,
                                  values,
                                  setFieldValue
                                )
                              }
                              addAllLanguages()
                            }}
                          >
                            {tr({ id: 'qaForm.addAllLanguages' })}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              )}
              <div
                data-testid="buff-details"
                className="py-3 mb-3 rounded flex flex-col"
                style={{ backgroundColor: 'var(--white-background)' }}
              >
                <div className="px-3">
                  {/* Announcement Author */}
                  <div
                    style={{ borderBottom: '1px solid #E1E4E8' }}
                    className="flex items-center mr-3 pb-2 mb-3"
                  >
                    <div>
                      <ImageFieldButton
                        className="mr-3"
                        altText={tr({ id: 'buff.answerImg' })}
                        image={values?.author?.[activeLanguage]?.imageUrl}
                        onClick={() =>
                          openAssetManagerByType(AssetClass.Author)
                        }
                        onBlur={handleBlur}
                        onImageRemove={() => {
                          setFieldValue(
                            `${
                              valuesState.get(activeLanguage)?.authorKey
                            }.imageUrl`,
                            ''
                          )
                        }}
                        tooltip={
                          <div className="flex items-center justify-between flex-col w-full py-2">
                            <p className="font-medium mb-0">
                              {tr({
                                id: 'generic.minImageSize',
                              })}
                            </p>
                            <p className="mb-0 text-sm text-[#F3F4F5]">
                              {tr(
                                {
                                  id: 'generic.ratio',
                                },
                                { x: 4, y: 5 }
                              )}
                            </p>
                            <p className="mb-0 text-sm text-[#F3F4F5]">
                              400 x 500 px
                            </p>
                          </div>
                        }
                        error={Boolean(
                          valuesState.get(activeLanguage)?.authorError?.imageUrl
                        )}
                        touched={
                          valuesState.get(activeLanguage)?.authorTouched
                            ?.imageUrl
                        }
                      />
                    </div>
                    <div className="mt-2" style={{ width: '258px' }}>
                      <TextInput
                        key={`${
                          valuesState.get(activeLanguage)?.authorKey
                        }.text`}
                        id={`${
                          valuesState.get(activeLanguage)?.authorKey
                        }.text`}
                        label={tr({ id: 'author.authorName' })}
                        placeholder={tr({ id: 'author.authorNameText' })}
                        value={values?.author?.[activeLanguage]?.text || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        data-testid={`author[${activeLanguage}]`}
                        error={Boolean(
                          valuesState.get(activeLanguage)?.authorError?.text
                        )}
                        errorLabel={
                          valuesState.get(activeLanguage)?.authorError?.text
                        }
                        touched={
                          valuesState.get(activeLanguage)?.authorTouched?.text
                        }
                        dir={textInputWritingMode}
                      />
                    </div>
                  </div>
                  {/* Announcement title */}
                  <TextInput
                    key={`titleText[${activeLanguage}]`}
                    id={`titleText[${activeLanguage}]`}
                    data-testid={`titleText[${activeLanguage}]`}
                    label={tr({ id: 'announcements.title' })}
                    placeholder={tr({ id: 'announcements.titlePlaceholder' })}
                    value={values.titleText[activeLanguage]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(valuesState.get(activeLanguage)?.titleError)}
                    errorLabel={valuesState.get(activeLanguage)?.titleError}
                    touched={valuesState.get(activeLanguage)?.titleTouched}
                    dir={textInputWritingMode}
                  />
                </div>

                <div className="px-3">
                  <TextInput
                    key={`text[${activeLanguage}]`}
                    id={`text[${activeLanguage}]`}
                    data-testid={`text[${activeLanguage}]`}
                    label={tr({ id: 'announcements.text' })}
                    placeholder={tr({ id: 'announcements.textPlaceholder' })}
                    value={values.text[activeLanguage]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(valuesState.get(activeLanguage)?.textError)}
                    errorLabel={valuesState.get(activeLanguage)?.textError}
                    touched={valuesState.get(activeLanguage)?.textTouched}
                    dir={textInputWritingMode}
                  />
                </div>
              </div>

              <div
                className="flex flex-col p-3 rounded"
                style={{ backgroundColor: 'var(--white-background)' }}
              >
                <div className="flex my-3">
                  <ImageFieldButton
                    image={values.imageUrl[activeLanguage]}
                    onClick={() =>
                      openAssetManagerByType(AssetClass.AnnouncementTile)
                    }
                    onImageRemove={() =>
                      setFieldValue(`imageUrl[${activeLanguage}]`, '')
                    }
                  />
                  <div className="px-3 announcement-form__input-container">
                    <TextInput
                      key={`buttonText[${activeLanguage}]`}
                      id={`buttonText[${activeLanguage}]`}
                      data-testid={`buttonText[${activeLanguage}]`}
                      label={
                        <>
                          {tr({ id: 'announcements.buttonText' })}{' '}
                          <small>({tr({ id: 'generic.optional' })})</small>
                        </>
                      }
                      value={values.buttonText[activeLanguage]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        valuesState.get(activeLanguage)?.buttonTextError
                      )}
                      errorLabel={
                        valuesState.get(activeLanguage)?.buttonTextError
                      }
                      touched={
                        valuesState.get(activeLanguage)?.buttonTextTouched
                      }
                      dir={textInputWritingMode}
                    />
                  </div>
                  <div className="flex px-3 announcement-form__input-container">
                    <TextInput
                      key={`linkUrl[${activeLanguage}]`}
                      id={`linkUrl[${activeLanguage}]`}
                      data-testid={`linkUrl[${activeLanguage}]`}
                      label={
                        <>
                          {tr({ id: 'announcements.linkUrl' })}{' '}
                          <small>({tr({ id: 'generic.optional' })})</small>
                        </>
                      }
                      value={values.linkUrl[activeLanguage]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        valuesState.get(activeLanguage)?.linkUrlError
                      )}
                      errorLabel={valuesState.get(activeLanguage)?.linkUrlError}
                      touched={valuesState.get(activeLanguage)?.linkUrlTouched}
                      dir={textInputWritingMode}
                      placeholder="https://www.example.com"
                    />
                  </div>
                </div>
                <SponsorFormSection
                  activeLanguage={activeLanguage}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                  values={values}
                  errors={errors}
                  textInputWritingMode={textInputWritingMode}
                  onAddSponsorImageClick={() => {
                    openAssetManagerByType(AssetClass.VoteableSponsor)
                  }}
                  title={tr({
                    id: 'qaForm.sponsor.buffSponsorship',
                  })}
                />
              </div>
              {isAssetManagerOpen && (
                <AssetManager
                  title={
                    selectedAssetManagerType === AssetClass.AnnouncementTile
                      ? tr({
                          id: 'assetManager.assetAnnouncementModalListTitle',
                        })
                      : selectedAssetManagerType === AssetClass.VoteableSponsor
                      ? tr({ id: 'assetManager.assetSponsorModalListTitle' })
                      : tr({
                          id: 'assetManager.assetAuthorModalListTitle',
                        })
                  }
                  type={selectedAssetManagerType}
                  isOpen={isAssetManagerOpen}
                  onClose={() => setIsAssetManagerOpen(false)}
                  accept={
                    selectedAssetManagerType === AssetClass.Author
                      ? AcceptImageTypeExceptGift
                      : 'image/*'
                  }
                  onSelect={([image]) => {
                    const imageUrl = image.publicURL + image.fileName

                    if (selectedAssetManagerType === AssetClass.Author) {
                      setFieldValue(
                        `author[${activeLanguage}].imageUrl`,
                        imageUrl
                      )
                    }
                    if (
                      selectedAssetManagerType === AssetClass.AnnouncementTile
                    )
                      setFieldValue(`imageUrl[${activeLanguage}]`, imageUrl)

                    if (selectedAssetManagerType === AssetClass.VoteableSponsor)
                      setFieldValue(
                        `sponsor[${activeLanguage}].imageUrl`,
                        imageUrl
                      )
                  }}
                />
              )}
              <PreviewProviderDataSource
                buff={buffPreviewValues}
                theme={theme}
                activeLanguage={activeLanguage}
              />
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

const AnnouncementForm = forwardRef(AnnouncementFormWithRef)

export default AnnouncementForm
