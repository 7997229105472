import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { ReactComponent as IconPlusSVG } from '@assets/icon-plus.svg'
import { ReactComponent as AppListSVG } from '@assets/app-list.svg'
import { ReactComponent as SearchIcon } from '@assets/search.svg'
import { ReactComponent as DismissSVG } from '@assets/dismiss_circle.svg'
import { ReactComponent as TickSVG } from '@assets/answer-tick.svg'
import { useLive } from '@services/requests/live'
import { IRouteParams } from '@interfaces/RouteParams'
import { PaymentOption } from '@interfaces/Leaderboard'
import PaymentItem from '@components/atoms/PaymentItem'
import Button, { ButtonVariant } from '@components/atoms/Button'
import Switch from '@components/atoms/Switch'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { Alignment } from '@components/molecules/ConfirmationModal/types'
import EmptyStateWithIcon from '@components/molecules/EmptyStateWithIcon'
import VipLeaderboardModal from '@components/molecules/VipLeaderboardModal'
import EditVipLeaderboardModal from '@components/molecules/VipLeaderboardModal/EditVipLeaderboardModal'
import { useToast } from '@utils/hooks/useToast'
import { queryClient } from '@utils/reactQuery/client'
import { tr } from '@constants/other'
import { LeaderboardMonetisationProps } from './types'

const enabledText = tr({ id: 'generic.enabled' })
const disabledText = tr({ id: 'generic.disabled' })

const LeaderboardMonetisation = (props: LeaderboardMonetisationProps) => {
  const [showMonetisationModal, setShowMonetisationModal] = useState(false)
  const { id: streamId, leaderboardId } = useParams<IRouteParams>()
  const {
    useGetStreamLeaderboardById,
    expirePaymentItems,
    toggleLeaderboardMonetisation,
  } = useLive()
  const [selectedPaymentItem, setSelectedPaymentItem] =
    useState<PaymentOption>()
  const [showEditPaymentItem, setShowEditPaymentItem] = useState<boolean>(false)
  const [showExpireModal, setShowExpireModal] = useState<boolean>(false)

  const { addToast, addErrorToast } = useToast()
  const { data: leaderboard, isLoading } = useGetStreamLeaderboardById(
    streamId,
    leaderboardId
  )

  const hasPaymentOptions = !!leaderboard?.paymentOptions?.length
  const hasActivePaymentOptions =
    hasPaymentOptions &&
    !!leaderboard?.paymentOptions?.find((paymentItem) => {
      return (
        paymentItem.availableUntil &&
        new Date(paymentItem.availableUntil) >= new Date()
      )
    })

  const { mutate: mutateExpirePaymentItems, isLoading: isExpiringPayment } =
    useMutation(expirePaymentItems, {
      onSuccess: () => {
        queryClient.invalidateQueries(['leaderboard', leaderboardId])
        addToast({
          msg: tr({ id: 'leaderboard.paymentItemsExpired' }),
          type: 'success',
          image: <TickSVG className="mr-3 w-8 h-8" />,
        })
        handleCloseExpirePaymentItemsModal()
      },
      onError: (err) => {
        addErrorToast(err)
      },
    })

  const {
    mutate: mutateToggleMonetisation,
    isLoading: isTogglingMonetisation,
  } = useMutation(toggleLeaderboardMonetisation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['leaderboard', leaderboardId])
    },
    onError: (err) => {
      addErrorToast(err)
    },
  })

  const handleEditPaymentItemClick = (paymentItem: PaymentOption) => {
    setSelectedPaymentItem(paymentItem)
    setShowEditPaymentItem(true)
  }

  const handleCloseEditPaymentItemModal = () => {
    setShowEditPaymentItem(false)
    setSelectedPaymentItem(undefined)
  }

  const handleCloseExpirePaymentItemsModal = () => {
    setShowExpireModal(false)
  }

  const handleExpirePaymentItems = () => {
    if (!streamId || !leaderboardId) return
    mutateExpirePaymentItems({
      streamId,
      leaderboardId,
    })
  }

  const handleToggleMonetisation = () => {
    if (!streamId || !leaderboardId) return
    mutateToggleMonetisation({
      streamId,
      leaderboardId,
      body: { paymentRequired: !leaderboard?.paymentRequired },
    })
  }

  return (
    <div>
      <div className="flex flex-col p-4 bg-white-background">
        <div>
          <div className="flex items-start">
            <div className="flex mr-4">
              <label
                className="flex mb-0 mr-2 items-center text-[1.125rem] font-bold"
                htmlFor="paymentRequired"
              >
                {tr({ id: 'leaderboard.monetisation' })}
              </label>
            </div>
            <Switch
              textClassname="text-sm uppercase"
              textPosition="right"
              disabled={isTogglingMonetisation || !!leaderboard?.finalised}
              name="paymentRequired"
              id="paymentRequired"
              text={!!leaderboard?.paymentRequired ? enabledText : disabledText}
              isChecked={!!leaderboard?.paymentRequired}
              onToggle={handleToggleMonetisation}
            />
          </div>
          <p className="text-[#586069] m-0">
            {tr({ id: 'leaderboard.monetisationOnDesc' })}
          </p>
        </div>

        {!!leaderboard?.paymentRequired && (
          <div>
            <div className="w-full h-[1px] bg-[#D1D5DA] my-5" />
            <header className="flex items-center justify-between mb-4">
              <div className="m-0 text-[1.125rem] font-bold">
                {tr({ id: 'leaderboard.leaderboardPaymentItems' })}
              </div>
              <div className="flex items-center gap-x-3">
                {!isLoading && (
                  <Button
                    disabled={!hasActivePaymentOptions}
                    variant="secondary"
                    onClick={() => setShowExpireModal(true)}
                    startElement={
                      <div className="flex mr-2">
                        <DismissSVG className="w-5 h-5" />
                      </div>
                    }
                  >
                    {tr({ id: 'leaderboard.expirePaymentItems' })}
                  </Button>
                )}
                {!isLoading && (
                  <Button
                    disabled={hasActivePaymentOptions}
                    onClick={() => setShowMonetisationModal(true)}
                    startElement={
                      <div className="flex mr-2 text-white">
                        <IconPlusSVG className="w-5 h-5" />
                      </div>
                    }
                  >
                    {tr({ id: 'leaderboard.addPaymentItem' })}
                  </Button>
                )}
              </div>
            </header>
            <section>
              {isLoading ? (
                <EmptyStateWithIcon
                  containerClasses="!h-[250px]"
                  icon={<SearchIcon className="w-10 text-white" />}
                  text={tr({ id: 'generic.loading' })}
                />
              ) : hasPaymentOptions ? (
                <div className="grid grid-cols-[minmax(32%,_1fr)_minmax(32%,_1fr)_minmax(32%,_1fr)] gap-4">
                  {leaderboard?.paymentOptions?.map((paymentItem, index) => (
                    <PaymentItem
                      key={index.toString()}
                      payment={paymentItem}
                      onEdit={handleEditPaymentItemClick}
                    />
                  ))}
                </div>
              ) : (
                <EmptyStateWithIcon
                  containerClasses="!h-[250px]"
                  icon={<AppListSVG className="w-10 text-white" />}
                  text={tr({ id: 'leaderboard.noPaymentItems' })}
                />
              )}
            </section>
          </div>
        )}
      </div>

      <VipLeaderboardModal
        leaderboardId={leaderboardId}
        streamId={streamId}
        show={showMonetisationModal}
        onClose={() => setShowMonetisationModal(false)}
      />

      {showEditPaymentItem && (
        <EditVipLeaderboardModal
          streamId={streamId}
          leaderboard={leaderboard}
          paymentItem={selectedPaymentItem}
          show={showEditPaymentItem}
          onClose={handleCloseEditPaymentItemModal}
        />
      )}

      <ConfirmationModal
        onCancel={handleCloseExpirePaymentItemsModal}
        onSuccess={handleExpirePaymentItems}
        onClose={() => {}}
        title={`${tr({ id: 'leaderboard.expirePaymentItems' })}?`}
        successBtnText={tr({ id: 'leaderboard.expirePaymentItems' })}
        subtext={tr({ id: 'leaderboard.expirePaymentItemsDesc' })}
        show={showExpireModal}
        data-testid="expire-payment-items-modal"
        variant={ButtonVariant.Danger}
        alignment={Alignment.END}
        disabled={isExpiringPayment}
      />
    </div>
  )
}

export default LeaderboardMonetisation
