import React, { FC, useContext, useMemo, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import SelectInput from '@components/atoms/SelectInput'
import { IRouteParams } from '@services/providers/BuffProvider'
import { tr } from '@constants/other'
import { useToast } from '@utils/hooks/useToast'
import { Formik, Form, FormikProps } from 'formik'
import { IWelcomeBuffSelectionProps } from './types'
import { NewConfig } from '@interfaces/IStreamConfig'
import { WelcomeBuffContext } from '@services/providers/WelcomeBuffProvider'
import { IOnboard as WelcomeBuff } from '@interfaces/IOnboard'
import AutoSubmitForm from '@components/atoms/AutoSubmitForm'
import {
  updateStreamConfig,
  updateStreamConfigDefault,
} from '@services/requests/stream-config'

type Onboard = Pick<NewConfig, 'welcomeBuffId'>

const WelcomeBuffSelection: FC<
  React.PropsWithChildren<IWelcomeBuffSelectionProps>
> = ({ isStreamConfigDefault = false, welcomeBuffId }) => {
  const { welcomeBuffs } = useContext(WelcomeBuffContext)
  const { id: streamId } = useParams<IRouteParams>()
  const { addErrorToast } = useToast()
  const formRef = useRef<FormikProps<Onboard>>(null)

  const transformedWelcomeBuffs = useMemo(() => {
    const buffs: { value: string; key: string }[] = [
      { value: tr({ id: 'appearance.welcomeBuffNone' }), key: '' },
    ]
    welcomeBuffs?.forEach((buff: WelcomeBuff) => {
      buffs.push({ value: buff.name, key: buff.welcomeBuffId })
    })
    return buffs
  }, [welcomeBuffs])

  const handleSubmit = async (values: Onboard) => {
    try {
      if (isStreamConfigDefault) {
        await updateStreamConfigDefault({
          payload: {
            config: values,
          },
        })
        return
      }

      await updateStreamConfig({
        streamId: streamId,
        payload: {
          config: values,
        },
      })
    } catch (error) {
      addErrorToast(error)
      console.error(error)
    }
  }

  return (
    <div
      className="p-4 rounded-lg"
      style={{ backgroundColor: 'var(--white-background)' }}
      data-testid="welcome-buff-selection"
    >
      {
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            welcomeBuffId: welcomeBuffId ?? '',
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange }) => {
            return (
              <Form>
                <fieldset
                  className="rounded-lg"
                  style={{ backgroundColor: 'var(--white-background)' }}
                  data-testid="welcome-buff-settings"
                >
                  <legend className="m-0 text-[1.125rem] font-bold float-left">
                    {tr({ id: 'appearance.onboarding' })}
                  </legend>

                  <div className="clear-both pt-4">
                    <div className="flex items-center">
                      <SelectInput
                        label={tr({ id: 'appearance.welcomeBuff' })}
                        value={values.welcomeBuffId ?? ''}
                        id="welcomeBuffId"
                        name="welcomeBuffId"
                        data-testid="welcome_buff-select"
                        onChange={handleChange}
                        options={transformedWelcomeBuffs}
                      />
                    </div>
                    <Link
                      className="text-primary"
                      style={{ textDecoration: 'underline' }}
                      to={`/stream-management`}
                    >
                      {tr({ id: 'appearance.manageWelcomeBuff' })}
                    </Link>
                  </div>
                </fieldset>
                <AutoSubmitForm />
              </Form>
            )
          }}
        </Formik>
      }
    </div>
  )
}

export default WelcomeBuffSelection
