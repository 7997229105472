import React, { FC, useState, useEffect, useCallback, useRef } from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import AuthorDetails from '@components/organisms/AuthorDetails'
import { Author, CreateAuthor } from '@interfaces/Author'
import { Action, tr } from '@constants/other'
import { useToast } from '@utils/hooks/useToast'
import useAuthors from '@utils/hooks/useAuthors'
import useCreateAuthor from '@utils/hooks/useCreateAuthor'
import useUpdateAuthor from '@utils/hooks/useEditAuthor'
import { deleteAuthor, getAuthorById } from '@services/requests/author'
import AuthorSidebar from '@components/organisms/AuthorSidebar'
import ContentWithSidePanelLayout from '@components/layouts/ContentWithSidePanelLayout'
import { NewAuthorRef } from '@components/organisms/AuthorDetails'
import SidebarAction from '@components/SidebarAction'
import EmptyState from '@components/molecules/EmptyState'

type IRouteParams = {
  id: string
}

const AuthorsPage: FC<React.PropsWithChildren<unknown>> = (): JSX.Element => {
  const { addToast, addErrorToast } = useToast()
  const [selectedAuthor, setSelectedAuthor] = useState<Author>()

  const { id = '' } = useParams<IRouteParams>()

  const navigate = useNavigate()
  const { mutateAsync: createAuthorMutation } = useCreateAuthor()
  const { mutateAsync: updateAuthorMutation } = useUpdateAuthor(id)
  const newAuthorRef = useRef<NewAuthorRef>(null)
  const { data, fetchNextPage, hasNextPage, isLoading, refetch } = useAuthors()
  const displayEmptyState = !isLoading && !data?.pages.length

  const getAuthor = useCallback(
    async (id: string) => {
      try {
        const author = await getAuthorById(id)
        setSelectedAuthor(author)
      } catch (error) {
        console.error(error)
        addErrorToast(error)
      }
    },
    [addErrorToast]
  )

  useEffect(() => {
    if (id) getAuthor(id)
  }, [id, getAuthor])

  const handleSubmit = async (values: CreateAuthor) => {
    try {
      const author = id
        ? await updateAuthorMutation(values)
        : await createAuthorMutation(values)

      const action = id ? Action.Update : Action.Create

      addToast({ msg: tr({ id: `author.${action}` }), type: 'success' })
      setSelectedAuthor(author)
      navigate(`/authors/${author.id}`)
    } catch (error) {
      console.error(error)
      addErrorToast(error)
    }
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteAuthor(id)
      addToast({ msg: tr({ id: `author.${Action.Delete}` }), type: 'success' })
      setSelectedAuthor(undefined)
      navigate('/authors')
      refetch()
    } catch (error) {
      console.error(error)
      addErrorToast(error)
    }
  }

  return (
    <ContentWithSidePanelLayout
      sidePanelTitle={tr({ id: 'generic.authors' })}
      sidePanelAction={
        <SidebarAction path={'authors'} sidebarRef={newAuthorRef} />
      }
      sidePanelContent={
        <div className="flex flex-1 flex-col">
          <AuthorSidebar
            paginatedData={data?.pages}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
            selectedId={selectedAuthor?.id}
            setSelectedAuthor={setSelectedAuthor}
          />
        </div>
      }
    >
      {displayEmptyState && (
        <EmptyState
          title={tr({ id: 'author.emptyStateTitle' })}
          data-testid="authors-page__empty-state"
        />
      )}
      <Routes>
        <Route
          path="new"
          element={<AuthorDetails handleSubmit={handleSubmit} />}
        />
        <Route
          path=":id"
          element={
            <AuthorDetails
              author={selectedAuthor}
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
            />
          }
        />
      </Routes>
    </ContentWithSidePanelLayout>
  )
}

export default AuthorsPage
