import React from 'react'
import { tr } from '@constants/other'
import { ReactComponent as PlaceholderSVG } from '@assets/placeholder.svg'

interface EmptyAssetListViewProps {
  title?: string
  subtitle?: string
  icon?: React.ReactNode
}

const EmptyAssetListView = ({
  title,
  subtitle,
  icon,
}: EmptyAssetListViewProps) => {
  return (
    <>
      <div className="pb-3 py-3 pt-0 mx-3">
        <div className="h-[calc(100vh-400px)]">
          <div className="flex flex-col justify-center items-center h-full mt-4 py-4 bg-gray rounded">
            <div className="w-12 h-12 items-center justify-center flex bg-[#959DA5] rounded-full">
              {!!icon ? (
                icon
              ) : (
                <PlaceholderSVG className="w-6 h-6" color="white" />
              )}
            </div>
            <span className="font-semibold text-[#586069] mt-4 text-lg">
              {title ?? tr({ id: 'assetManager.noAssets' })}
            </span>
            <span className="text-[#717A85] mt-2 text-sm">
              {subtitle ?? tr({ id: 'assetManager.uploadNew' })}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmptyAssetListView
