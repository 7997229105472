import React, { useMemo, useState } from 'react'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { Tab } from '@headlessui/react'
import { tr } from '@constants/other'
import Button from '@components/atoms/Button'
import AutoPatchTouched from '@components/atoms/AutoPatchTouched'
import { StreamChatFormValues } from '@interfaces/Chat'
import Switch from '@components/atoms/Switch'
import SelectInput from '@components/atoms/SelectInput'
import TextInput from '@components/atoms/TextInput'
import AssetManager from '@components/organisms/AssetManager'
import { Orientation, Platform, Position } from '@interfaces/IStreamConfig'
import { useChat } from '@services/requests/chat'
import { useToast } from '@utils/hooks/useToast'
import { IRouteParams } from '@interfaces/RouteParams'
import { queryClient } from '@utils/reactQuery/client'
import { ReactComponent as TickSVG } from '@assets/answer-tick.svg'
import { ReactComponent as CameraIcon } from '@assets/camera.svg'
import { ReactComponent as PersonIcon } from '@assets/person.svg'

import { schema } from './schema'
import { AssetClass } from '@interfaces/Assets'

const defaultInitialValues: StreamChatFormValues = {
  name: '',
  enabled: false,
  slowMode: 0,
  visible: true,
  position: [
    {
      platform: Platform.WEB,
      orientationLandscape: {
        hAlign: Position.LEFT,
        vAlign: Position.BOTTOM,
        hOffset: 10,
        vOffset: 10,
      },
      orientationPortrait: {
        hAlign: Position.LEFT,
        vAlign: Position.BOTTOM,
        hOffset: 10,
        vOffset: 10,
      },
    },
  ],
  moderator: {
    profilePicture: '',
    displayName: '',
  },
}

const tabs = {
  [Orientation.orientationLandscape]: {
    type: Orientation.orientationLandscape,
    title: tr({ id: 'generic.landscape' }),
  },
  [Orientation.orientationPortrait]: {
    type: Orientation.orientationPortrait,
    title: tr({ id: 'generic.portrait' }),
  },
}

const slowModeOptions = [
  { key: 0, value: 'OFF (default)' },
  { key: 3, value: '3 seconds' },
  { key: 5, value: '5 seconds' },
  { key: 10, value: '10 seconds' },
  { key: 30, value: '30 seconds' },
  { key: 60, value: '60 seconds' },
  { key: 120, value: '120 seconds' },
]

const LEFT = 'Left'
const RIGHT = 'Right'
const TOP = 'Top'
const BOTTOM = 'Bottom'

const positionXOptions = [
  {
    key: Position.LEFT,
    value: LEFT,
  },
  {
    key: Position.RIGHT,
    value: RIGHT,
  },
]

const positionYOptions = [
  {
    key: Position.TOP,
    value: TOP,
  },
  {
    key: Position.BOTTOM,
    value: BOTTOM,
  },
]

const ChatSettings = () => {
  const { id: streamId = '' } = useParams<IRouteParams>()
  const { addErrorToast, addToast } = useToast()
  const { updateChatSettings, useGetStreamChatSettings } = useChat()
  const { data: chatSettings } = useGetStreamChatSettings(streamId)
  const [isAssetManagerOpen, setIsAssetManagerOpen] = useState(false)

  const handleSubmit = async (values: StreamChatFormValues) => {
    try {
      if (!streamId) throw new Error('Internal error')

      if (values.position) {
        values.position = values.position.map((position) => {
          return { ...position, platform: Platform.WEB }
        })
      }

      await updateChatSettings(streamId, {
        ...values,
        slowMode: parseInt(values.slowMode as unknown as string, 10),
      })
      queryClient.invalidateQueries(['stream-chat', streamId])
      queryClient.invalidateQueries(['chat-moderator'])
      addToast({
        msg: tr({ id: 'globalChat.settingsUpdated' }),
        type: 'success',
        image: <TickSVG className="mr-3 w-8 h-8" />,
      })
    } catch (error) {
      addErrorToast(error)
    }
  }

  // const handleTabChange = (position: number): void => {
  //   setSelectedTab(position)
  // }

  const initialValues = useMemo(() => {
    if (!chatSettings) return defaultInitialValues
    return chatSettings
  }, [chatSettings])

  return (
    <div className="p-4 rounded-lg bg-white-background">
      <div className="flex flex-col">
        <Formik
          initialValues={initialValues}
          validateOnChange
          validateOnBlur
          validateOnMount
          validationSchema={schema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              isValid,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              errors,
              touched,
            } = props

            return (
              <Form className="flex flex-col gap-y-6 px-1.5">
                <div>
                  <div className="flex flex-col gap-y-1">
                    <label className="font-semibold">
                      {tr({ id: 'globalChat.moderatorInfo' })}
                    </label>
                    <div className="flex items-center gap-x-4">
                      <button
                        onClick={(e) => {
                          setIsAssetManagerOpen(true)
                          e.preventDefault()
                        }}
                        className="w-14 h-14 rounded-full flex items-center justify-center border-[1px] border-solid border-[#A3ACB5] relative outline-none"
                      >
                        <div className="absolute -right-1 -bottom-1 bg-[#08274F] rounded-full flex items-center justify-center w-6 h-6">
                          <CameraIcon className="w-4 h-4 text-white" />
                        </div>
                        {values.moderator?.profilePicture ? (
                          <img
                            src={values.moderator.profilePicture}
                            alt="avatar"
                            className="w-full h-full object-cover rounded-full"
                          />
                        ) : (
                          <div className="w-full h-full flex items-center justify-center bg-[#D1D6DE] rounded-full">
                            <PersonIcon className="w-8 h-8 text-[#323A46]" />
                          </div>
                        )}
                      </button>
                      <TextInput
                        id="moderator.displayName"
                        name="moderator.displayName"
                        type="text"
                        label={
                          <label className="font-semibold">
                            {tr({ id: 'globalChat.moderatorName' })}
                          </label>
                        }
                        value={values.moderator?.displayName}
                        placeholder=""
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <TextInput
                      id="name"
                      name="name"
                      type="text"
                      min={0}
                      label={
                        <label className="font-semibold">
                          {tr({ id: 'globalChat.name' })}
                        </label>
                      }
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors?.name)}
                      errorLabel={errors?.name}
                    />
                  </div>
                  <div className="flex items-center">
                    <SelectInput
                      containerClasses="w-full"
                      label={
                        <label className="font-semibold">
                          {tr({ id: 'globalChat.slowMode' })}
                        </label>
                      }
                      value={values.slowMode}
                      id="slowMode"
                      name="slowMode"
                      onChange={handleChange}
                      options={slowModeOptions}
                    />
                  </div>

                  <div className="h-[1px] w-full my-3 bg-[#D1D5DA]" />

                  <Tab.Group>
                    <div className="flex items-center justify-between mb-2">
                      <h1 className="m-0 text-base font-semibold">
                        {`${tr({
                          id: 'globalChat.chatPanelPosition',
                        })} (${tr({ id: 'generic.web' })})`}
                      </h1>
                      <Tab.List className="flex items-center space-x-1 bg-white rounded-3xl px-2">
                        {Object.values(tabs).map((platform) => (
                          <Tab
                            key={platform.type}
                            className={({ selected }) =>
                              `whitespace-nowrap border-none py-1 my-1 w-full text-sm font-medium text-[#5c5c5c] rounded-3xl px-2.5 ${
                                selected
                                  ? 'bg-[#0090fe] text-white'
                                  : 'bg-transparent'
                              }`
                            }
                          >
                            {platform.title}
                          </Tab>
                        ))}
                      </Tab.List>
                    </div>
                    <Tab.Panels>
                      {Object.values(tabs).map((platform, idx) => {
                        const orientation = platform.type
                        const fieldErrors = (
                          errors.position as unknown as Record<string, any>
                        )?.[0]?.[orientation]
                        const fieldTouched = (
                          touched.position as unknown as Record<string, any>
                        )?.[0]?.[orientation]
                        return (
                          <Tab.Panel key={idx}>
                            <>
                              {/* Position */}
                              <div>
                                <div className="flex items-center justify-between w-full space-x-4">
                                  <SelectInput
                                    containerClasses="w-1/2"
                                    id={`position[0].${orientation}.vAlign`}
                                    name={`position[0].${orientation}.vAlign`}
                                    value={
                                      values.position?.[0]?.[orientation]
                                        ?.vAlign
                                    }
                                    options={positionYOptions}
                                    onChange={handleChange}
                                    error={Boolean(fieldErrors?.vAlign)}
                                    errorLabel={fieldErrors?.vAlign}
                                    touched={fieldTouched?.vAlign}
                                  />
                                  <SelectInput
                                    containerClasses="w-1/2"
                                    id={`position[0].${orientation}.hAlign`}
                                    name={`position[0].${orientation}.hAlign`}
                                    value={
                                      values.position?.[0]?.[orientation]
                                        ?.hAlign
                                    }
                                    options={positionXOptions}
                                    onChange={handleChange}
                                    error={Boolean(fieldErrors?.hAlign)}
                                    errorLabel={fieldErrors?.hAlign}
                                    touched={fieldTouched?.hAlign}
                                  />
                                </div>
                              </div>
                              {/* Offsets */}
                              <div>
                                <div className="inline-flex space-x-4 w-full">
                                  {/* Vertical offset */}
                                  <div className="flex flex-col w-1/2">
                                    <h2 className="text-base m-0">
                                      {tr({
                                        id: 'appearance.verticalOffset',
                                      })}
                                    </h2>
                                    <span className="text-[0.688rem] mb-2">
                                      {tr({
                                        id: 'appearance.minimumHorizontalPercentage',
                                      })}
                                    </span>
                                    <div className="inline-flex items-baseline">
                                      <div className="w-3/6 ">
                                        <TextInput
                                          id={`position[0].${orientation}.vOffset`}
                                          name={`position[0].${orientation}.vOffset`}
                                          type="number"
                                          min={0}
                                          value={
                                            values.position?.[0]?.[orientation]
                                              ?.vOffset
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          error={Boolean(fieldErrors?.vOffset)}
                                          errorLabel={fieldErrors?.vOffset}
                                          touched={fieldTouched?.vOffset}
                                        />
                                      </div>
                                      <div className="ml-2 mb-2.5">%</div>
                                    </div>
                                  </div>

                                  {/* Horizontal offset */}
                                  <div className="flex flex-col w-1/2">
                                    <h2 className="text-base m-0">
                                      {tr({
                                        id: 'appearance.horizontalOffset',
                                      })}
                                    </h2>
                                    <span className="text-[0.688rem] mb-2">
                                      {tr({
                                        id: 'appearance.minimumHorizontalPercentage',
                                      })}
                                    </span>
                                    <div className="inline-flex items-baseline">
                                      <div className="w-3/6">
                                        <TextInput
                                          id={`position[0].${orientation}.hOffset`}
                                          name={`position[0].${orientation}.hOffset`}
                                          type="number"
                                          min={0}
                                          value={
                                            values.position?.[0]?.[orientation]
                                              ?.hOffset
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          error={Boolean(fieldErrors?.hOffset)}
                                          errorLabel={fieldErrors?.hOffset}
                                          touched={fieldTouched?.hOffset}
                                        />
                                      </div>
                                      <div className="ml-2 mb-2.5">%</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          </Tab.Panel>
                        )
                      })}
                    </Tab.Panels>
                  </Tab.Group>

                  <div className="h-[1px] w-full my-3 bg-[#D1D5DA]" />

                  <div className="flex flex-col items-start gap-y-1">
                    <div className="flex items-center gap-x-1">
                      <label className="font-semibold">
                        {tr({ id: 'globalChat.chatPanelVisibility' })}
                      </label>
                    </div>
                    <Switch
                      textClassname="text-sm uppercase"
                      textPosition="right"
                      name="visible"
                      id="visible"
                      isChecked={values.visible}
                      onToggle={handleChange}
                    />
                  </div>
                </div>
                <div className="flex gap-4 items-center justify-end">
                  <Button disabled={!isValid} variant="primary" type="submit">
                    {tr({ id: 'generic.applyChanges' })}
                  </Button>
                </div>

                {isAssetManagerOpen && (
                  <AssetManager
                    accept="image/*"
                    title={tr({
                      id: 'assetManager.assetModeratorModalListTitle',
                    })}
                    type={AssetClass.Moderator}
                    isOpen={isAssetManagerOpen}
                    onClose={() => setIsAssetManagerOpen(false)}
                    onSelect={([image]) => {
                      const imageUrl = image.publicURL + image.fileName
                      setFieldValue('moderator.profilePicture', imageUrl)
                    }}
                  />
                )}

                <AutoPatchTouched />
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default ChatSettings
