import { LanguageCode } from '@interfaces/ILanguage'
import { CreateVoteableAuthorForm } from '@interfaces/Voteable'
import { LocalisedContent } from '@interfaces/localisation'
import sortLocalisableContent from './sortLocalisableContent'

const formatBuffAuthorToSubmit = (
  author: Partial<Record<LanguageCode, CreateVoteableAuthorForm>>,
  streamLanguages: LanguageCode[] = []
): { localisations: LocalisedContent[] } => {
  const arr: LocalisedContent[] = []
  Object.keys(author).forEach((langKey) => {
    const lang = langKey as LanguageCode
    arr.push({
      text: author?.[lang]?.text || '',
      localisation: lang,
      imageUrl: author?.[lang]?.imageUrl,
      imageAltText: 'Author image',
    })
  })

  return {
    localisations: sortLocalisableContent(arr, streamLanguages),
  }
}

export default formatBuffAuthorToSubmit
