import React, { FC, Fragment } from 'react'
import Button from '@components/atoms/Button'
import { Formik, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import TextInput from '@components/atoms/TextInput'
import { tr } from '@constants/other'
import BuffAnswerStats from '@components/molecules/BuffAnswerStats'
import getLocalisations from '@utils/getLocalisation'
import { LanguageCode } from '@interfaces/ILanguage'
import { ResolveBuffFormProps } from './types'

const tableConfig = { columnSizes: ['10%', '30%', 'auto', '10%', '10%'] }

export const ResolvePredictionForm: FC<
  React.PropsWithChildren<ResolveBuffFormProps>
> = ({ buff, engagement, onSubmit }) => {
  const { answers } = buff

  const handleSubmit = (values: Record<string, boolean>) => {
    const response: string[] = []

    Object.keys(values).forEach((key) => {
      if (!!values[key]) {
        response.push(key)
      }
    })

    if (onSubmit) {
      return onSubmit({
        gameId: buff.gameId,
        voteableId: buff.voteableId,
        correctAnswers: response,
      })
    }
  }

  const Schema = Yup.object().shape({})

  const initialValues: Record<string, boolean> = {}

  answers.forEach((answer) => (initialValues[answer.id] = false))

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, dirty, isSubmitting }) => (
          <Form>
            <table className="min-w-full table-fixed mb-2.5">
              <colgroup>
                {tableConfig.columnSizes.map((colSize, i) => (
                  <col key={i} span={1} style={{ width: colSize }} />
                ))}
              </colgroup>
              <tbody>
                <FieldArray
                  name="answers"
                  render={() => {
                    return answers.map((localisedAnswer) => {
                      const answer = getLocalisations({
                        localisations: localisedAnswer?.localisations,
                        languages: [LanguageCode.en],
                      })
                      const answerId = localisedAnswer?.id
                      if (!answerId) return
                      const answerEngagement = engagement?.perAnswer?.[answerId]
                      return (
                        <Fragment key={answerId}>
                          <BuffAnswerStats
                            firstElement={
                              <TextInput
                                inputContainerClasses="mr-2"
                                id={answerId}
                                name="answer"
                                type="checkbox"
                                checked={values[answerId]}
                                className={'w-full dark-mode'}
                                placeholder={tr({
                                  id: 'signIn.emailPlaceholder',
                                })}
                                onChange={(e) => {
                                  setFieldValue(answerId, e.target.checked)
                                }}
                              />
                            }
                            title={answer?.text ?? ''}
                            percentage={answerEngagement?.percentage ?? 0}
                            totalCount={answerEngagement?.votesCounted ?? 0}
                          />
                        </Fragment>
                      )
                    })
                  }}
                />
              </tbody>
            </table>
            <Button
              className="w-full"
              type="submit"
              disabled={!dirty || isSubmitting}
            >
              {tr({ id: 'generic.resolve' })}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ResolvePredictionForm
