import React, { FC } from 'react'
import { Formik } from 'formik'
import { tr } from '@constants/other'
import TextInput from '@components/atoms/TextInput'

interface NameModalFormProps {
  nameKey: string
  nameValue: string
  validationSchema: unknown
  updateName: (name: string) => void
  updateDisabled: (disableValue: boolean) => void
}

const NameModalForm: FC<React.PropsWithChildren<NameModalFormProps>> = ({
  nameKey,
  nameValue,
  updateName,
  updateDisabled,
  validationSchema,
}) => {
  return (
    <Formik
      initialValues={{ [nameKey]: nameValue }}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {(props) => {
        const { values, errors, touched, handleChange, handleBlur } = props

        return (
          <TextInput
            id={nameKey}
            label={tr({ id: 'template.name' })}
            value={values[nameKey]}
            error={Boolean(errors[nameKey])}
            errorLabel={errors[nameKey]}
            touched={touched[nameKey]}
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e)
              updateName(e.target.value)
              updateDisabled(e.target.value.length === 0)
            }}
          />
        )
      }}
    </Formik>
  )
}

export default NameModalForm
