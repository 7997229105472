import React, { FC, Fragment } from 'react'
import TextInput from '@components/atoms/TextInput'
import { BuffType, tr } from '@constants/other'
import { VodVoteable } from '@interfaces/Vod'
import { LanguageCode } from '@interfaces/ILanguage'
import getLocalisations from '@utils/getLocalisation'
import { getMetadataFromBuffType } from '@utils/buffTypeMappings'
import AnswerPreview from './AnswerPreview'
import ImageFieldButton from '@components/atoms/ImageFieldButton'

interface AnswersListPreviewProps {
  buff: VodVoteable
  activeLanguage?: LanguageCode
}

const AnswersListPreview: FC<
  React.PropsWithChildren<AnswersListPreviewProps>
> = ({ buff, activeLanguage }) => {
  if (!activeLanguage) return <></>

  const questionContent = getLocalisations({
    localisations: buff?.question?.localisations,
    languages: [activeLanguage],
  })

  const authorContent = getLocalisations({
    localisations: buff?.author?.localisations,
    languages: [activeLanguage],
  })

  const { id: buffType } = getMetadataFromBuffType({
    lifecycle: buff?.lifecycle,
    answerInterface: buff?.answerInterface,
  })

  return (
    <div>
      <div
        data-testid="buff-details"
        className="py-3 mb-3 rounded flex flex-col"
        style={{ backgroundColor: 'var(--white-background)' }}
      >
        <div
          style={{ borderBottom: '1px solid #E1E4E8' }}
          className="flex items-center mx-3 pb-2 mb-3"
        >
          <div>
            <ImageFieldButton
              className="mr-3"
              altText={authorContent?.imageAltText}
              image={authorContent?.imageUrl}
            />
          </div>
          <div className="mt-2" style={{ width: '258px' }}>
            <TextInput
              id="author"
              label={tr({ id: 'author.authorName' })}
              placeholder={authorContent?.text}
              disabled
            />
          </div>
        </div>

        {/* Question */}
        <div className="px-3">
          <TextInput
            id="question"
            label={tr({ id: 'qaForm.questionLabel' })}
            placeholder={questionContent?.text}
            disabled
          />
        </div>

        {buffType !== BuffType.StarRating && (
          <div className="mx-3">
            {buff.answers.map((localisedAnswer) => {
              return (
                <Fragment key={localisedAnswer?.id}>
                  <AnswerPreview
                    activeLanguage={activeLanguage}
                    answer={localisedAnswer}
                    type={buffType}
                    showCorrect={buffType === BuffType.Quiz}
                  />
                </Fragment>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default AnswersListPreview
