import React, {
  createContext,
  useReducer,
  FC,
  Dispatch,
  SetStateAction,
} from 'react'
import { assetReducer, AssetActions } from '@services/reducers/assetReducer'
import { UploadableAsset } from '@interfaces/Assets'

export type AssetProviderState = {
  view: AssetManagerView
  uploadableAssets: UploadableAsset[]
  setUploadableAssets: SetStateAction<UploadableAsset[]>
}

export enum AssetManagerView {
  AssetList = 'AssetList',
  UploadAssets = 'UploadAssets',
  ManageAssets = 'ManageAssets',
}

interface AssetContextProps {
  state: AssetProviderState
  dispatch: Dispatch<AssetActions>
}

const initialState: AssetProviderState = {
  uploadableAssets: [],
  setUploadableAssets: () => [],
  view: AssetManagerView.AssetList,
}
const AssetContext = createContext<AssetContextProps>({} as AssetContextProps)
const { Provider } = AssetContext

const AssetProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(assetReducer, initialState)

  return (
    <Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </Provider>
  )
}

export { AssetContext, AssetProvider }
