import React, { FC, Fragment, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import AuthorItem from '@components/molecules/AuthorItem'
import { useInfiniteScroll } from '@utils/hooks/useInfiniteScroll'
import { Author, GetAuthorResponse } from '@interfaces/Author'
import ScrollableSidebar from '@components/molecules/ScrollableSidebar'
import { tr } from '@constants/other'

interface IProps {
  selectedId?: string
  setSelectedAuthor: (author: Author) => void
  isLoading: boolean
  fetchNextPage: any
  hasNextPage?: boolean
  paginatedData?: GetAuthorResponse[]
}

const AuthorSidebar: FC<React.PropsWithChildren<IProps>> = ({
  selectedId,
  setSelectedAuthor,
  paginatedData,
  fetchNextPage,
  hasNextPage,
  isLoading,
}): ReactElement => {
  const { triggerRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: fetchNextPage,
  })

  return (
    <ScrollableSidebar
      triggerRef={triggerRef}
      isLoading={isLoading}
      isEmpty={!paginatedData?.[0]?.authors?.length}
      emptyText={tr({ id: 'author.emptyStateTitle' })}
    >
      {paginatedData?.map((page) => {
        return page.authors.map((author: Author) => {
          return (
            <Fragment key={author.id}>
              <Link
                to={`/authors/${author.id}`}
                onClick={() => {
                  setSelectedAuthor(author)
                }}
              >
                <AuthorItem
                  author={author}
                  isActive={selectedId === author.id}
                />
              </Link>
            </Fragment>
          )
        })
      })}
    </ScrollableSidebar>
  )
}

export default AuthorSidebar
