import { create } from 'zustand'
import axios from 'axios'
import { ILanguage, LanguageCode } from '@interfaces/ILanguage'
import { useClientConfig } from '@services/requests/client-config'
import { Locales } from '@interfaces/ClientConfig'
import { LanguagesFormValues } from '@components/organisms/ClientLanguages'

interface LanguageStore {
  allLanguages: ILanguage[]
  clientLanguages: Locales
  fetchLanguages: () => Promise<void>
  updateClientLanguages: (newLanguages: LanguagesFormValues) => void
  isFetching: boolean
}

const useLanguageStore = create<LanguageStore>((set) => {
  const { getLanguages, updateLanguages } = useClientConfig()

  const fetchLanguages = async () => {
    try {
      set({ isFetching: true })
      const { data: allLanguages } = await axios.get<ILanguage[]>(
        `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${process.env.ACCOUNT_NAME}/languages.json`
      )
      const clientLanguages = await getLanguages()

      set({
        allLanguages,
        clientLanguages,
        isFetching: false,
      })
    } catch (error) {
      console.error('Error fetching languages:', error)
      set({ isFetching: false })
    }
  }

  const updateClientLanguages = async (payload: LanguagesFormValues) => {
    try {
      set({
        clientLanguages: {
          default: payload.default,
          enabled: payload.enabled.map((enabled) => enabled.iso6391),
        },
        isFetching: true,
      })
      const { data } = await updateLanguages(payload)
      set({ clientLanguages: data, isFetching: false })
    } catch (error) {
      set({ isFetching: false })
    }
  }

  // Fetch languages automatically when the store is initialized
  fetchLanguages()

  return {
    allLanguages: [],
    clientLanguages: {
      default: LanguageCode.en,
      enabled: [],
    },
    fetchLanguages,
    updateClientLanguages,
    isFetching: true, // Set initial fetching state to true until the languages are fetched
  }
})

export default useLanguageStore
