import { KeyboardEvent } from 'react'

/**
 * Prevents a form submitting when enter key is pressed
 * should be used on keydown event
 * @param {KeyboardEvent} keyEvent
 */
const preventFormSubmitOnEnter = (
  keyEvent: KeyboardEvent<HTMLFormElement>
): void => {
  if (keyEvent.key === 'Enter') {
    keyEvent.preventDefault()
  }
}

export default preventFormSubmitOnEnter
