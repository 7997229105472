import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useVodClient } from '@services/requests/vodV2'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'
import Container from '@components/atoms/Container'
import { tr } from '@constants/other'
import Breadcrumb from '@components/atoms/Breadcrumb'
import BreadcrumbItem from '@components/atoms/Breadcrumb/BreadcrumbItem'
import Button from '@components/atoms/Button/Button'
import SectionLayout from '@components/layouts/SectionLayout'
import { ReactComponent as SyncSVG } from '@assets/sync.svg'
import BuffPreviewWidget from '@components/molecules/BuffPreviewWidget'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import useAuthors from '@utils/hooks/useAuthors'
import {
  AnnouncementFormValues,
  CreateAnnouncement,
} from '@interfaces/Announcement'
import { FormikHelpers, FormikProps } from 'formik'
import AnnouncementForm, {
  getInitialValues as getAnnouncementInitialValues,
} from '@components/organisms/AnnouncementForm'
import { LanguageCode } from '@interfaces/ILanguage'
import { formatAnnouncementDataToSubmit } from '@utils/formatAnnouncementDataToSubmit'
import {
  AnnouncementTemplate,
  AnnouncementTemplateCreate,
  Template,
  TemplateCreate,
} from '@interfaces/Template'
import { useToast } from '@utils/hooks/useToast'
import BuffForm from '@components/organisms/BuffForm'
import { getTemplateInitialValues } from '@utils/buffForm/template'
import { formatBuffDataToSubmit } from '@utils/formatBuffDataToSubmit'
import { CreateVoteableForm } from '@interfaces/Voteable'
import { getSchemaWithoutLang } from '@utils/buffForm/schema'

const VodNew: FC<React.PropsWithChildren> = () => {
  const params = useParams<{ id: string; entityId?: string }>()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { id: vodId, entityId } = params

  const path = `/vod/${vodId}`

  const { useGetVod, useGetVodContentEntity } = useVodClient()
  const announcementFormRef = useRef<FormikProps<AnnouncementFormValues>>(null)

  const { data: vod } = useGetVod(vodId, { enabled: !!vodId })
  const { data: vodEntity } = useGetVodContentEntity(vodId, entityId, {
    enabled: !!vodId && !!entityId,
  })
  const { data: authorsPaginated } = useAuthors(50)
  const { addErrorToast } = useToast()

  // TODO: We will need to convert vod entity to buff or announcement form values

  const authors = authorsPaginated?.pages?.[0]?.authors ?? []
  const languages = [LanguageCode.en]

  const [announcementTemplatePayload, setAnnouncementTemplatePayload] =
    useState<AnnouncementTemplateCreate | AnnouncementTemplate | null>(null)
  const [showSetTemplateNameModal, setShowSetTemplateNameModal] =
    useState(false)

  const [templatePayload, setTemplatePayload] = useState<
    TemplateCreate | Template | null
  >(null)

  const [announcementInitialValues, setAnnouncementInitialValues] =
    useState<AnnouncementFormValues>()

  const firstLang = languages[0]
  const initialValues = useMemo(() => {
    return getTemplateInitialValues({
      template: undefined,
      languages: [firstLang],
    })
  }, [firstLang])

  const getSchema = getSchemaWithoutLang({
    isTemplate: true,
  })

  const handleAnnouncementSubmit = async (
    values: AnnouncementFormValues,
    helpers: FormikHelpers<AnnouncementFormValues>,
    languages?: LanguageCode[]
  ) => {
    if (!values) return
    if (!languages) return

    const formBody = formatAnnouncementDataToSubmit(values, languages)
    showAnnouncementTemplateModal(formBody)
    return
  }

  const handleBuffSubmit = (
    values: CreateVoteableForm,
    helpers: FormikHelpers<CreateVoteableForm>,
    languages: LanguageCode[]
  ) => {
    setTemplatePayload({
      ...formatBuffDataToSubmit(values, false, languages, true, 0, false, true),
      templateName: '',
    })
  }

  const showAnnouncementTemplateModal = async (values: CreateAnnouncement) => {
    try {
      const template: AnnouncementTemplateCreate = {
        ...values,
        templateName: '',
      }
      setAnnouncementTemplatePayload(template)
      setShowSetTemplateNameModal(true)
    } catch (error) {
      addErrorToast(error)
    }
  }

  useEffect(() => {
    setAnnouncementInitialValues(getAnnouncementInitialValues([]))
  }, [])

  const components = {
    buff: {
      title: tr({ id: 'buff.buff' }),
      component: (
        <>
          {/* {Boolean(template) && (
            <Alert
              className="mt-3"
              text={tr(
                { id: 'template.editGlobalTemplateAlertText' },
                { templateName: template?.templateName }
              )}
              action={tr({ id: 'welcomeBuff.returnToList' })}
              actionCallback={() => {
                navigate('/stream-management/global-templates/buff')
              }}
              actionClassName="font-bold underline"
            />
          )} */}
          <BuffForm
            initialValues={initialValues}
            authors={authors}
            isTimeSyncStream={false}
            availableLanguages={languages}
            type="vod"
            getSchema={getSchema}
            onSubmit={handleBuffSubmit}
            onReset={(values, resetForm) => {
              const blankInitialValues = getTemplateInitialValues({
                languages: [firstLang],
              })
              resetForm(blankInitialValues)
            }}
          />

          {/* <TemplateBuffConfirmation
            id={id}
            mode={id ? 'update' : 'create'}
            payload={templatePayload}
            show={Boolean(templatePayload)}
            onClose={() => setTemplatePayload(null)}
            onSuccessCallback={handleTemplateSaveSuccess}
          /> */}
        </>
      ),
    },
    announcements: {
      title: tr({ id: 'announcements.announcement' }),
      component: (
        <>
          {announcementInitialValues ? (
            <AnnouncementForm
              ref={announcementFormRef}
              authors={authors}
              languages={languages}
              initialValues={announcementInitialValues}
              template={undefined}
              returnCallback={() => navigate(`/vod/${vodId}/edit`)}
              type="vod"
              onSubmit={handleAnnouncementSubmit}
            />
          ) : null}
          {/* <TemplateAnnouncementConfirmation
            id={id}
            mode={id ? 'update' : 'create'}
            payload={announcementTemplatePayload}
            show={showSetTemplateNameModal}
            onClose={() => setShowSetTemplateNameModal(false)}
            onSuccessCallback={handleTemplateSaveSuccess}
          /> */}
        </>
      ),
    },
  }

  const tabs = Object.entries(components).map(([slug, { title }]) => {
    return {
      text: title,
      href: `${path}/${slug}/new`,
    }
  })

  const returnTab = pathname?.includes('buff') ? 'buff' : 'announcements'
  const tabsIndex = Object.keys(components).indexOf(returnTab)
  const buttonText = entityId
    ? returnTab === 'buff'
      ? 'vod.updateBuff'
      : 'vod.updateAnnouncement'
    : returnTab === 'buff'
    ? 'vod.createBuff'
    : 'vod.createAnnouncement'

  return (
    <Container>
      <Row className="pl-2 w-full">
        <Col sm={12}>
          <div className="flex h-6 items-center mt-3 mb-4 w-full">
            <Breadcrumb>
              <BreadcrumbItem href={`/vod/${vodId}/edit`}>
                {tr({ id: 'generic.vod' })} - {vod?.name}
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {tr({ id: 'vod.createBuff' })}
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Col>
      </Row>

      <SectionLayout
        className="!w-full m-0"
        childrenClassName="!p-0 bg-transparent shadow-none"
        containerClassname="mb-10 w-full"
        title={`${tr({ id: 'vod.createBuffs' })} - ${vod?.name}`}
        backLink={`/vod/${vodId}/edit`}
        header={
          <Button
            startElement={
              <div className="flex mr-2">
                <SyncSVG className="w-5 h-5" />
              </div>
            }
            type="button"
            variant="secondary"
            onClick={() => {}}
          >
            {tr({ id: 'generic.resetBuff' })}
          </Button>
        }
      >
        <Row className="pl-2">
          <Routes>
            <Route
              path={`/*`}
              element={
                <Col sm={8}>
                  <div className="p-3 bg-white rounded min-h-[500px] shadow-card">
                    <Tabs selectedIndex={tabsIndex} tabs={tabs}>
                      {Object.entries(components).map(
                        ([slug, { component }]) => {
                          return <TabPanel key={slug}>{component}</TabPanel>
                        }
                      )}
                    </Tabs>
                  </div>
                </Col>
              }
            />
          </Routes>
          <Col sm={4}>
            <div className="flex flex-col gap-y-4">
              <BuffPreviewWidget />
              <Button variant="primary" onClick={() => {}}>
                {tr({ id: buttonText })}
              </Button>
            </div>
          </Col>
        </Row>
      </SectionLayout>
    </Container>
  )
}

export default VodNew
