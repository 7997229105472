import { LanguageCode } from '@interfaces/ILanguage'
import { Leaderboard } from '@interfaces/Leaderboard'
import {
  LeaderboardContent,
  LeaderboardFormValues,
  LocalisedLeaderboardContent,
} from '../LeaderboardFormTab/types'

export const getInitialValues = (
  languages: LanguageCode[],
  leaderboard?: Leaderboard
) => {
  const localisedContent = {} as LocalisedLeaderboardContent
  languages.forEach((lang) => {
    const { languages, ...rest } = leaderboard?.content || {}
    const content = rest as LocalisedLeaderboardContent
    const langContent =
      content?.[lang as unknown as keyof LocalisedLeaderboardContent]
    // @ts-ignore
    localisedContent[lang as LanguageCode] = {
      title: langContent?.title ?? '',
      banner: langContent?.banner ?? undefined,
      showBanner: !!langContent?.banner?.imageUrl,
    }
  })

  const initialValues: LeaderboardFormValues = {
    name: leaderboard?.name ?? '',
    content: {
      languages,
      ...localisedContent,
    } as LeaderboardContent,
  }

  return initialValues
}
