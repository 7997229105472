import React, { FC } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import { tr } from '@constants/other'
import { IRouteParams } from '@interfaces/RouteParams'
import ViewLeaderboard from '@components/organisms/ViewLeaderboard'
import LeaderboardFormTab from '@components/organisms/LeaderboardFormTab'
import LeaderboardMonetisation from '@components/molecules/LeaderboardMonetisation'
import LeaderboardStreams from '@components/molecules/LeaderboardStreams'

const StreamLeaderboardDetailsTabs: FC<unknown> = () => {
  const { pathname } = useLocation()
  const { id: streamId, gameId, leaderboardId } = useParams<IRouteParams>()

  const path = !leaderboardId
    ? `/streams/${streamId}/${gameId}/leaderboard/new`
    : `/streams/${streamId}/${gameId}/leaderboard/${leaderboardId}`

  const tabs = [
    {
      text: tr({ id: 'leaderboard.view' }),
      name: 'standings',
      href: `${path}/standings`,
    },
    {
      text: tr({ id: 'generic.streams' }),
      name: 'stream-list',
      href: `${path}/stream-list`,
    },
    {
      text: tr({ id: 'generic.settings' }),
      name: 'settings',
      href: `${path}/settings`,
    },
    {
      text: tr({ id: 'leaderboard.monetisation' }),
      name: 'monetisation',
      href: `${path}/monetisation`,
    },
  ]

  const tabIndex = tabs.findIndex((tab) => {
    return pathname.includes(tab.name)
  })

  const panelClassName = '!mt-4 !py-6'

  return (
    <div className="flex flex-col px-4">
      {leaderboardId ? (
        <Tabs tabs={tabs} selectedIndex={tabIndex}>
          <TabPanel className={panelClassName}>
            <ViewLeaderboard
              id={leaderboardId}
              streamId={streamId || ''}
              queryKey={['leaderboard', leaderboardId, 'standings']}
            />
          </TabPanel>
          <TabPanel className={panelClassName}>
            <LeaderboardStreams />
          </TabPanel>
          <TabPanel className={panelClassName}>
            <LeaderboardFormTab leaderboard={undefined} />
          </TabPanel>
          <TabPanel className={panelClassName}>
            <LeaderboardMonetisation />
          </TabPanel>
        </Tabs>
      ) : (
        <LeaderboardFormTab leaderboard={undefined} />
      )}
    </div>
  )
}

export default StreamLeaderboardDetailsTabs
