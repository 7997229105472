import { string, object } from 'yup'
import { isValidURL } from '@utils/validation'
import { tr } from '@constants/other'

export const schema = object().shape({
  termsOfService: string().test(
    ...isValidURL(tr({ id: 'validation.validUrl' }))
  ),
  privacyPolicy: string().test(
    ...isValidURL(tr({ id: 'validation.validUrl' }))
  ),
})
