import React, { FC, ReactNode } from 'react'

interface MetricsCardProps {
  value: number
  text: string
  icon?: ReactNode
}

const MetricsCard: FC<MetricsCardProps> = ({ value, icon, text }) => {
  return (
    <div className="flex bg-white m-2 rounded shadow-md w-[18em] px-0 py-3">
      {icon && (
        <div className="flex items-center justify-center mr-2">
          <div className="flex items-center justify-center ps-4 rounded-full ">
            {icon}
          </div>
        </div>
      )}
      <div className="flex flex-col w-full items-center">
        <div className="font-bold text-sm px-2 py-1">{text}</div>
        <div className="font-bold text-4xl">{value}</div>
      </div>
    </div>
  )
}

export default MetricsCard
