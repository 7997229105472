import { NumberSchema } from '@hapi/joi'
import * as Joi from '@hapi/joi'
import { tr, BuffPriority, BuffType } from './other'

export const FIELD_ID = 'id'
export const FIELD_NAME = 'name'
export const FIELD_AUTHOR_ID = 'authorId'
export const FIELD_QUESTION = 'question'
export const FIELD_LANGUAGE = 'language'
export const FIELD_ANSWERS = 'answers'
export const FIELD_TYPE = 'type'
export const QA_FIELD_EXPIRES_HOURS = 'expiresHours'
export const QA_FIELD_EXPIRES_MINUTES = 'expiresMinutes'
export const QA_FIELD_EXPIRES_SECONDS = 'expiresSeconds'
export const QA_FIELD_SCHEDULE_HOURS = 'scheduleHours'
export const QA_FIELD_SCHEDULE_MINUTES = 'scheduleMinutes'
export const FIELD_SCHEDULE_SECONDS = 'scheduleSeconds'
export const QA_FIELD_DELAY = 'delay'
export const FIELD_TAGS = 'tags'
export const FIELD_PUBLIC = 'public'
export const FIELD_PRIORITY = 'priority'
export const FIELD_DURATION = 'duration' // time to show
export const QA_FIELD_ANSWER_ID = 'id'
export const FIELD_ANSWER_TITLE = 'title'
export const QA_FIELD_ANSWER_POINTS = 'points'
export const FIELD_ANSWER_IMAGE = 'image'
export const FIELD_ANSWER_IMAGE_GROUP_ID = 'imageGroupId'
export const QA_FIELD_ANSWER_CORRECT = 'correct'
export const FIELD_BGCOLOR = 'bgColor'
export const FIELD_FGCOLOR = 'fgColor'
export const FIELD_QA_BGCOLOR = 'bg_color'
export const FIELD_QA_FGCOLOR = 'fg_color'
export const FIELD_ISACTIVE = 'published'
export const FIELD_PLAY_AT = 'playAt'
export const FIELD_RESOLVE_AT = 'resolveAt'
export const FIELD_EXPIRE_AT = 'expireAt'
export const FIELD_EXPIRES_AT = 'expiresAt'

export const BUFF_DEFAULT_DESCRIPTION = 'Description'
export const BUFF_DEFAULT_SUBTITLE = 'Subtitle'
export const BUFF_DEFAULT_LINK = 'https://sportbuff.com/'
export const BUFF_DEFAULT_TYPE = BuffType.Prediction
export const BUFF_DEFAULT_DURATION = 15 // timetoshow
export const BUFF_MINIMUM_DURATION = 3 // timetoshow
export const BUFF_DEFAULT_PRIORITY = BuffPriority.High
export const DEFAULT_ANSWER_POINTS = 0

export const DEFAULT_ANSWER_TITLE = undefined
export const QA_DEFAULT_EXPIRE_SECONDS = 30
export const QA_DEFAULT_ANSWER_MAX_POINTS = 300
export const DEFAULT_ISACTIVE = true

// Author
export const AUTHOR_DISPLAY_NAME = 'displayName'
export const AUTHOR_PROFILE_PICTURE = 'profilePicture'

// SignIn
export const SIGNIN_EMAIL = 'email'
export const SIGNIN_PASSWORD = 'password'

// Stream Fields
export const CLIENT_ID = 'clientId'
export const ID = 'id'
export const USER_ID = 'userId'

export const FIELD_TITLE = 'title'
export const FIELD_SPORT = 'sport'
export const STREAM_PUBLISHED = 'published'
export const FIELD_SUBTITLE = 'subtitle'
export const FIELD_DESCRIPTION = 'description'
export const FIELD_PROVIDER_ID = 'providerId'
export const STREAM_TAGS = 'tags'
export const FIELD_LIVEAT = 'liveAt'
export const STREAM_LIVEDAT = 'livedAt'
export const STREAM_STOPPEDAT = 'stoppedAt'
export const STREAM_ENDAT = 'endAt'

export const FIELD_MAX_POINTS = 'bonusTimePoints'

export const FIELD_DURATION_DAYS = 'durationDays'
export const FIELD_DURATION_HOURS = 'durationHours'
export const FIELD_DURATION_MINUTES = 'durationMinutes'
export const FIELD_URL = 'url'

// Client Fields
export const CLIENT_DESCRIPTION = 'description'

// Replays

export const REPLAY_FIELD_BUFFS = 'buffReports'

// Manager
export const MANAGER_FIRST_NAME = 'firstName'
export const MANAGER_LAST_NAME = 'lastName'
export const MANAGER_EMAIL = 'email'

// Froms
export const QA_FORM = 'qaForm'
export const STREAM_CREATE_FORM = 'streamCreateForm'
export const STREAM_EDIT_FORM = 'streamEditForm'
export const RESOLVE_FORM = 'resolveForm'
export const SIGNIN_FORM = 'signInForm'
export const REPLAY_FORM = 'replayForm'
export const CLIENT_CREATE_FORM = 'clientCreateForm'
export const AUTHOR_CREATE_FORM = 'authorCreateForm'
export const AUTHOR_EDIT_FORM = 'authorEditForm'
export const CLIENT_EDIT_FORM = 'clientEditFrom'
export const MANAGER_EDIT_FORM = 'managerEditForm'

export const MAX_ANSWERS = 5

export const V_STREAM_MIN_MINUTES = 0
export const V_STREAM_MAX_MINUTES = 59
export const V_STREAM_MIN_HOURS = 0
export const V_STREAM_MAX_HOURS = 23
export const V_STREAM_MIN_DAYS = 0
export const V_STREAM_MAX_DAYS = 100
export const V_BUFF_POINTS_MIN = 10
export const V_BUFF_POINTS_MAX = 50
export const V_BUFF_TIMETOSHOW_MIN = 5
export const V_BUFF_TIMETOSHOW_MAX = 15
export const V_BUFF_ANSWER_COLOR_LENGTH = 7

export const DEFAULT_PAG_LIMIT = 100
export const DEFAULT_PAG_OFFSET = 0

const vEmail = Joi.string()
  .email({ tlds: { allow: false } })
  .label(tr({ id: 'generic.email' }))

const vPassword = Joi.string()
  .min(8)
  .label(tr({ id: 'generic.password' }))

export const vResourceIdSchema = Joi.alternatives(
  Joi.string(),
  Joi.number()
).required()

// *** AUTH ***

export const vSignInSchema = Joi.object({
  email: vEmail.required(),
  password: vPassword.required(),
})

export const vPaginationSchema = Joi.object({
  limit: Joi.number().optional().label('Limit'),
  offset: Joi.number().optional().label('Offset'),
}).optional()

export const vBuffType: NumberSchema = Joi.number()
  .integer()
  .allow(BuffType.Prediction, BuffType.Quiz, BuffType.Poll)

export const vBuffPriority: NumberSchema = Joi.number()
  .integer()
  .allow(
    BuffPriority.Critical,
    BuffPriority.High,
    BuffPriority.Normal,
    BuffPriority.Low
  )

export const vTags = Joi.array()
  .optional()
  .items(Joi.string())
  .label(tr({ id: 'generic.tags' }))

export const vImageGroupUuid = Joi.string().uuid({ version: 'uuidv1' })

export const vStreamTitle = Joi.string()
  .required()
  .min(5)
  .max(140)
  .label(tr({ id: 'stream.title' }))

export const vDescription = Joi.string()
  .optional()
  .min(2)
  .max(140)
  .label('Description')

export const vSubtitle = Joi.string()
  .optional()
  .min(2)
  .max(140)
  .label('Subtitle')

export const vArrayNumbersSchema = Joi.array().items(Joi.number())

export const vHexColor = Joi.string()
  .regex(/^#[A-Fa-f0-9]{6}/)
  .label('Color')

export const vClientIdSchema = vResourceIdSchema.required().label('Client ID')
export const vStreamIdSchema = vResourceIdSchema.required().label('Stream ID')
export const vBuffIdSchema = vResourceIdSchema.required().label('Buff ID')

export const vImageTypeSchema = Joi.required().label('Image Type')

/**
 * The minimum number of seconds gap that should be between the duration & expiry of a buff
 */
export const BUFF_EXPIRY_DURATION_MIN_GAP = 20

/**
 * The minimum number of seconds gap that should be between the resolve & expiry
 */
export const BUFF_RESOLVE_GAP_TO_EXPIRY = 5

/**
 * The minimum number of seconds gap that should be between the duration & expiry of a buff when in time sync stream
 */
export const BUFF_EXPIRY_DURATION_MIN_GAP_TIME_SYNC = 60

export const DEFAULT_EXPIRY_TIME = '000:00:35'

export const DEFAULT_EXPIRY_TIME_TIME_SYNC = '000:01:15'

/**
 * The minimum number of seconds gap the show buff animation should take
 */
export const BUFF_ANIMATION_GAP = 2

/**
 * The min & max value of bits answer can get
 */
export const MIN_BITS = 25
export const MAX_BITS = 10000

export const AUTHOR_LOCAL_STORAGE_KEY = 'BUFF_FORM_SELECTED_AUTHOR'

export const BUFF_MAX_DURATION = 300
export const BUFF_MIN_DURATION = 6
