import React, { ChangeEvent, FC, useRef } from 'react'
import { FileInputProps } from './types'

const FileInput: FC<React.PropsWithChildren<FileInputProps>> = ({
  onAddFile,
  onDragEnter,
  onDragExit,
  accept = 'image/*',
  'data-testid': testId = 'file-input',
}) => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : []
    onAddFile(files)
  }

  const handleDragEnter = () => {
    onDragEnter?.()
  }

  const handleDragLeave = () => {
    onDragExit?.()
  }

  return (
    <>
      <input
        className="absolute inset-0 w-full opacity-0"
        type="file"
        multiple
        accept={accept}
        ref={hiddenFileInput}
        onDragLeave={handleDragLeave}
        onDragEnter={handleDragEnter}
        onChange={handleChange}
        data-testid={testId}
      />
    </>
  )
}

export default FileInput
