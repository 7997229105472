import React, { FC } from 'react'
import Button from '@components/atoms/Button'
import { ReactComponent as PlusIcon } from '@assets/icon-plus-filled.svg'
import { ReactComponent as TickIcon } from '@assets/tick-filled.svg'

interface IProps {
  name: string
  active: boolean
  setActive: (active: boolean) => void

  /**
   * If the button should be disabled
   * @default false
   */
  disabled?: boolean
}

const LanguageItem: FC<React.PropsWithChildren<IProps>> = ({
  name,
  active,
  setActive,
  disabled = false,
}): JSX.Element => {
  const languageContStyles = {
    width: '145px',
    backgroundColor: 'white',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.12)',
    border: `solid 1px ${active ? 'var(--primary)' : '#E1E4E8'}`,
  }

  return (
    <div data-testid="language-item">
      <Button
        variant="neutral"
        onClick={() => setActive(!active)}
        disabled={disabled}
        className={`!p-0 !w-[145px]`}
      >
        <div
          style={languageContStyles}
          className={`flex justify-between items-center px-2 py-1 my-1 rounded ${
            active ? '' : 'hover:!border-[#959DA5]'
          }`}
        >
          <p className={`text-[14px] mb-0 text-black`}>{name}</p>
          {active && (
            <div className="flex text-primary">
              <TickIcon className="w-4 h-4" />
            </div>
          )}
          {!active && (
            <div className="flex text-[#959DA5]">
              <PlusIcon className="w-4 h-4" />
            </div>
          )}
        </div>
      </Button>
    </div>
  )
}

export default LanguageItem
