import React, { FC } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { tr } from '@constants/other'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import { IRouteParams } from '@interfaces/RouteParams'
import Appearance from '@components/organisms/Appearance'
import StreamForm from '@components/organisms/StreamForm'
import AdvancedSettings from '@components/organisms/AdvancedSettings'
import LiveReactions from '@components/molecules/LiveReactions'
import StreamLeaderboards from '../StreamLeaderboards'
import { IStreamDetailsProps } from './types'
import StreamChatTab from '../StreamChatTab'
import StreamMetrics from '../StreamMetrics'

const emptyArray: any[] = []

/**
 * Leaderboard id does not get picked up by useParams so using this regex to pull it out from pathname
 */
const regex = /\/streams\/([\w-]+)\/([\w-]+)\/([\w-]+)\/([\w-]+)/

const ENABLE_GLOBAL_CHAT = process.env.GLOBAL_CHAT === 'true'
const ENABLE_HEARTBEAT_METRICS = process.env.HEARTBEAT_METRICS === 'true'

const StreamDetailsTabs: FC<React.PropsWithChildren<IStreamDetailsProps>> = ({
  leaderboardIds,
  streamConfig,
  ...rest
}) => {
  const { pathname } = useLocation()
  const params = useParams<IRouteParams>()

  const urlPath = pathname.match(regex)
  const [_, __, ___, ____, leaderboardId] = urlPath ?? emptyArray

  const { id: streamId = '', gameId = '' } = params

  const defaultLink = !streamId
    ? '/streams/new'
    : `/streams/${streamId}/${gameId}/stream-details`

  let tabs = [
    {
      text: tr({ id: 'stream.detailsTab' }),
      name: 'stream-details',
      href: defaultLink,
    },
  ]

  if (Boolean(streamId)) {
    tabs = tabs.concat([
      {
        text: tr({ id: 'stream.appearance' }),
        name: 'appearance',
        href: `/streams/${streamId}/${gameId}/appearance`,
      },
      {
        text: tr({ id: 'stream.advancedSettings' }),
        name: 'advanced-settings',
        href: `/streams/${streamId}/${gameId}/advanced-settings`,
      },
      {
        text: tr({ id: 'leaderboard.leaderboard' }),
        name: 'leaderboard-list',
        href: `/streams/${streamId}/${gameId}/leaderboard-list`,
      },
      {
        text: tr({ id: 'liveReactions.reactions' }),
        name: 'reactions',
        href: `/streams/${streamId}/${gameId}/reactions`,
      },
    ])

    if (Boolean(ENABLE_HEARTBEAT_METRICS)) {
      tabs = tabs.concat([
        {
          text: tr({ id: 'stream.metrics' }),
          name: 'metrics',
          href: `/streams/${streamId}/${gameId}/metrics`,
        },
      ])
    }

    if (Boolean(ENABLE_GLOBAL_CHAT)) {
      tabs = tabs.concat([
        {
          text: tr({ id: 'globalChat.chat' }),
          name: 'global-chat',
          href: `/streams/${streamId}/${gameId}/global-chat`,
        },
      ])
    }
  }

  const tabIndex = tabs.findIndex((tab) => {
    return pathname.includes(`/${tab.name}`)
  })

  const showBuffDetails =
    !streamConfig || !!streamConfig?.config?.allowGamePoints

  const panelClassName = '!mt-4 !py-6'

  return (
    <Tabs tabs={tabs} selectedIndex={tabIndex} className="!px-9">
      <TabPanel className={panelClassName}>
        <StreamForm {...rest} showBuffDetails={showBuffDetails} />
      </TabPanel>
      <TabPanel className={panelClassName}>
        <Appearance />
      </TabPanel>
      <TabPanel className={panelClassName}>
        <AdvancedSettings />
      </TabPanel>
      <TabPanel className={panelClassName}>
        <StreamLeaderboards
          leaderboardIds={leaderboardIds}
          activeLeaderboardId={leaderboardId || null}
          streamId={streamId}
          gameId={gameId}
        />
      </TabPanel>
      <TabPanel className={panelClassName}>
        <LiveReactions />
      </TabPanel>
      <TabPanel className={panelClassName}>
        <StreamMetrics />
      </TabPanel>
      <TabPanel className={panelClassName}>
        <StreamChatTab />
      </TabPanel>
    </Tabs>
  )
}

export default StreamDetailsTabs
