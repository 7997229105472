import { ButtonVariant } from '@components/atoms/Button'

export enum Alignment {
  END = 'end',
  BETWEEN = 'space-between',
}

export interface IConfirmationModalProps {
  /**
   * Shows/hides the modal
   */
  show: boolean

  /**
   * Callback than runs when the modal closes
   */
  onClose: () => void

  /**
   * Callback than runs on cancel
   */
  onCancel?: () => void

  /**
   * Callback than runs when click the primary modal button
   */
  onSuccess?: () => void

  /**
   * Title of the modal
   */
  title?: string

  /**
   * Btn success text
   */
  successBtnText?: string

  /**
   * Btn cancel text
   */
  cancelBtnText?: string

  /**
   * Subtext of the modal
   */
  subtext?: string

  /**
   * Variant of the btn
   */
  variant?: ButtonVariant

  /**
   * Disabled the primary button
   *
   * @default false
   */
  disabled?: boolean

  /**
   * Selection ID for test purposes.
   *
   * @default 'confirmation-modal'
   */
  'data-testid'?: string

  /**
   * Variant of buttons alignment
   */
  alignment?: Alignment
}
