import React, { forwardRef, PropsWithChildren } from 'react'
import { ColProps } from './types'

const Col = forwardRef<HTMLDivElement, PropsWithChildren<ColProps>>(
  (
    { className = '', 'data-testid': testId = 'col', children, ...sizeProps },
    ref
  ) => {
    const hasSize =
      Object.entries(sizeProps).filter(([_, value]) => value > 0).length > 0

    const generateResponsiveClasses = () => {
      let classes = ''

      classes = Object.entries(sizeProps)
        .filter(([_, value]) => value > 0)
        .map(([key, value]) => {
          return `${key}:basis-[calc((${value}/12)*100%)] ${key}:max-w-[calc((${value}/12)*100%)]`
        })
        .join(' ')

      if (hasSize) {
        classes += ' grow-0'
      }

      return classes
    }

    const sizeClasses = generateResponsiveClasses()

    return (
      <div
        data-testid={testId}
        className={`relative w-full px-[15px] shrink-0 max-w-[100%] grow basis-0 ${sizeClasses} ${className}`}
        ref={ref}
      >
        {children}
      </div>
    )
  }
)

Col.displayName = 'Col'

export default Col
