import React, { FC, useState, useEffect, useCallback } from 'react'
import { useToast } from '@utils/hooks/useToast'
import { NewConfig, PositionConfig } from '@interfaces/IStreamConfig'
import AdvancedSettingsForm, {
  AdvancedSettingsFormValues,
} from '@components/organisms/AdvancedSettingsForm'
import Positioning from '@components/organisms/Positioning'
import { msToHHMMSS } from '@utils/time'
import {
  getStreamConfigDefault,
  updateStreamConfigDefault,
} from '@services/requests/stream-config'

const StreamDefaults: FC<React.PropsWithChildren<unknown>> = () => {
  const { addErrorToast } = useToast()
  const [advancedConfig, setAdvancedConfig] =
    useState<AdvancedSettingsFormValues>()
  const [positioningConfig, setPositioningConfig] = useState<PositionConfig[]>(
    []
  )

  const handleSubmit = async (values: NewConfig) => {
    try {
      await updateStreamConfigDefault({
        payload: {
          config: values,
        },
      })
    } catch (error) {
      addErrorToast(error)
      console.error(error)
    }
  }

  const getStreamConfig = useCallback(async () => {
    try {
      const result = await getStreamConfigDefault()
      const config = result.config
      setAdvancedConfig({
        ...config,
        secondScreenAppDelay: msToHHMMSS(
          Number(config.secondScreenAppDelay || 0) * 1000
        ),
      })
      const posConfig = result.positionConfig
      setPositioningConfig(posConfig)
    } catch (error) {
      addErrorToast(error)
      console.error(error)
    }
  }, [getStreamConfigDefault, addErrorToast])

  const handleUpdateStreamPositioning = async (
    values: Partial<PositionConfig>
  ) => {
    try {
      await updateStreamConfigDefault({
        payload: {
          positionConfig: [values as PositionConfig],
        },
      })
    } catch (error) {
      addErrorToast(error)
      console.error(error)
    }
  }

  useEffect(() => {
    getStreamConfig()
  }, [getStreamConfig])

  return (
    <div className="mt-4" data-testid="stream-defaults">
      <div className="mb-4">
        <Positioning
          positioningConfig={positioningConfig}
          onRefetch={getStreamConfig}
          onSubmit={handleUpdateStreamPositioning}
        />
      </div>

      {advancedConfig && (
        <AdvancedSettingsForm
          onSubmit={handleSubmit}
          initialValues={advancedConfig}
        />
      )}
    </div>
  )
}

export default StreamDefaults
