import React, { FC, Fragment, ReactElement } from 'react'
import { GetVodResponse, Vod, VodStatus } from '@interfaces/VodV2'
import { useInfiniteScroll } from '@utils/hooks/useInfiniteScroll'
import ScrollableSidebar from '@components/molecules/ScrollableSidebar'
import { tr } from '@constants/other'
import { Link } from 'react-router-dom'
import SidebarItem from '@components/molecules/SidebarItem/SidebarItem'

interface SidebarProps {
  selectedId?: string
  isLoading: boolean
  fetchNextPage: any
  hasNextPage?: boolean
  paginatedData?: GetVodResponse[]
}

const VodSidebar: FC<React.PropsWithChildren<SidebarProps>> = ({
  selectedId,
  isLoading,
  fetchNextPage,
  hasNextPage,
  paginatedData,
}): ReactElement => {
  const { triggerRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: fetchNextPage,
  })

  return (
    <ScrollableSidebar
      triggerRef={triggerRef}
      isLoading={isLoading}
      isEmpty={!paginatedData?.[0]?.vods?.length}
      emptyText={tr({ id: 'vod.emptyStateTitle' })}
    >
      {paginatedData?.map((page) => {
        return page.vods.map((vod: Vod) => {
          return (
            <Fragment key={vod.id}>
              <Link to={`/vod/${vod.id}`}>
                <SidebarItem
                  displayName={vod.name}
                  isActive={selectedId === vod.id}
                  status={
                    vod.published ? VodStatus.published : VodStatus.editing
                  }
                />
              </Link>
            </Fragment>
          )
        })
      })}
    </ScrollableSidebar>
  )
}

export default VodSidebar
