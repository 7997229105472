import React, { FC } from 'react'
import { ReactComponent as Info } from '@assets/info_circle.svg'
import { InfoTextProps } from './types'

const InfoText: FC<React.PropsWithChildren<InfoTextProps>> = ({
  text,
  className,
  textClassName,
  iconClassName,
  Icon = Info,
  color = 'danger',
  'data-testid': testId = 'info-text',
}) => {
  if (!text) return null

  const colorVariable = `var(--${color})`

  return (
    <div data-testid={testId} className={`flex mt-1 ${className}`}>
      <Icon
        className={`flex-shrink-0 w-4 h-4 mr-1 ${iconClassName}`}
        data-testid={`${testId}_icon`}
        color={colorVariable}
      />
      <span
        data-testid={`${testId}_text`}
        style={{ color: colorVariable }}
        className={`text-sm sb-break-words line-clamp-2 -mt-1 ${textClassName}`}
      >
        {text}
      </span>
    </div>
  )
}

export default InfoText
