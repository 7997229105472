import React, { FC, useEffect, useRef, useState } from 'react'
import Modal from '@components/molecules/Modal'
import { ReactComponent as PlaySVG } from '@assets/play-filled.svg'
import { ReactComponent as PauseSVG } from '@assets/pause.svg'
import { ReactComponent as DismissSVG } from '@assets/dismiss.svg'
import { PlayAudioProps } from './types'

const PlayAudio: FC<PlayAudioProps> = ({
  show,
  file,
  onClose,
  'data-testid': testId = 'play-audio-modal',
}) => {
  const audioHandler = useRef<HTMLAudioElement>()
  const progressBarRef = useRef<HTMLInputElement>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [duration, setDuration] = useState<number>(0)
  const handleClose = () => {
    handlePause()
    onClose()
  }

  const checkIfPlaying = () => {
    return (
      audioHandler.current &&
      audioHandler.current?.duration > 0 &&
      !audioHandler.current?.paused
    )
  }

  const handlePlay = () => {
    if (checkIfPlaying()) {
      audioHandler.current?.pause()
    }

    if (file && audioHandler.current) {
      const soundPromise = audioHandler.current.play()

      if (soundPromise !== undefined) {
        setIsPlaying(true)
        soundPromise.catch(() => {
          handlePause()
        })
      }
    }
  }

  const handleStart = () => {
    setIsPlaying(true)
  }

  const handleEnd = () => {
    setIsPlaying(false)
  }

  const handleLoadMeta = () => {
    const seconds = audioHandler.current?.duration
    setDuration(seconds ?? 0)
  }

  const handlePause = () => {
    setIsPlaying(false)
    if (checkIfPlaying()) {
      audioHandler.current?.pause()
    }
  }

  const handleTimeUpdate = () => {
    if (progressBarRef.current) {
      progressBarRef.current.value = String(
        Math.ceil(audioHandler.current?.currentTime || 0)
      )
    }
  }

  const formatTime = (time: number) => {
    if (time && !isNaN(time)) {
      const minutes = Math.floor(time / 60)
      const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
      const seconds = Math.ceil(time % 60)
      const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
      return `${formatMinutes}:${formatSeconds}`
    }
    return '00:00'
  }

  const handleProgressChange = () => {
    if (audioHandler.current && progressBarRef.current) {
      audioHandler.current.currentTime = Number(progressBarRef.current.value)
    }
  }

  useEffect(() => {
    if (!show) {
      setIsPlaying(false)
    }
  }, [show])

  useEffect(() => {
    if (show && !!file) {
      audioHandler.current = new Audio()
      audioHandler.current.src = file?.publicURL + file?.fileName
      audioHandler.current.load()
      audioHandler.current.addEventListener('play', handleStart)
      audioHandler.current.addEventListener('timeupdate', handleTimeUpdate)
      audioHandler.current.addEventListener('pause', handleEnd)
      audioHandler.current.addEventListener('ended', handleEnd)
      audioHandler.current.addEventListener('loadedmetadata', handleLoadMeta)
    }

    return () => {
      audioHandler.current?.removeEventListener('play', handleStart)
      audioHandler.current?.removeEventListener('pause', handleEnd)
      audioHandler.current?.removeEventListener('ended', handleEnd)
      audioHandler.current?.removeEventListener(
        'loadedmetadata',
        handleLoadMeta
      )
      audioHandler.current?.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [show, file])

  return (
    <Modal
      isOpen={show}
      onClose={handleClose}
      data-testid={testId}
      displayCloseButton={false}
      hideHeader
      contentClassName="!bg-[#595858] !px-4 !py-4"
    >
      <Modal.Body className="flex flex-row items-center justify-between space-x-4">
        <div>
          {isPlaying && (
            <PauseSVG
              onClick={handlePause}
              className="text-white w-6 h-6 cursor-pointer flex-shrink-0"
            />
          )}
          {!isPlaying && (
            <PlaySVG
              onClick={handlePlay}
              className="text-white w-6 h-6 cursor-pointer flex-shrink-0"
            />
          )}
        </div>
        <div className="w-full flex flex-col">
          <span className="text-white">{file?.title}</span>
          <span className="text-[#969696] text-sm">{formatTime(duration)}</span>
          <div className="flex w-full mt-2">
            <input
              ref={progressBarRef}
              className="w-full"
              type="range"
              step={0.01}
              max={Math.ceil(duration) || 1}
              defaultValue="0"
              onChange={handleProgressChange}
            />
          </div>
        </div>
        <div>
          <DismissSVG
            onClick={handleClose}
            className="text-white w-6 h-6 cursor-pointer flex-shrink-0"
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PlayAudio
