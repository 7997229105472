import * as Yup from 'yup'
import { tr } from '@constants/other'
import { Position } from '@interfaces/IStreamConfig'

const leaderboardCommonSchema = {
  hOffset: Yup.number()
    .integer()
    .typeError(tr({ id: 'validation.required' }))
    .required(tr({ id: 'validation.required' }))
    .min(0, tr({ id: 'validation.leaderboardHorizontalOffset.min' }))
    .max(60, tr({ id: 'validation.leaderboardHorizontalOffset.max' })),
  vOffset: Yup.number()
    .integer()
    .typeError(tr({ id: 'validation.required' }))
    .required(tr({ id: 'validation.required' }))
    .min(0, tr({ id: 'validation.leaderboardVerticalOffset.min' }))
    .max(60, tr({ id: 'validation.leaderboardVerticalOffset.max' })),
  hAlign: Yup.mixed<Position>().oneOf(Object.values(Position)),
  vAlign: Yup.mixed<Position>().oneOf(Object.values(Position)),
}

const voteableCommonSchema = {
  hOffset: Yup.number()
    .integer()
    .typeError(tr({ id: 'validation.required' }))
    .required(tr({ id: 'validation.required' }))
    .min(0, tr({ id: 'validation.voteableHorizontalOffset.min' }))
    .max(60, tr({ id: 'validation.voteableHorizontalOffset.max' })),
  vOffset: Yup.number()
    .integer()
    .typeError(tr({ id: 'validation.required' }))
    .required(tr({ id: 'validation.required' }))
    .min(0, tr({ id: 'validation.voteableVerticalOffset.min' }))
    .max(60, tr({ id: 'validation.voteableVerticalOffset.max' })),
  hAlign: Yup.mixed<Position>().oneOf(Object.values(Position)),
  vAlign: Yup.mixed<Position>().oneOf(Object.values(Position)),
}

export const positioningSchema = (isTV: boolean) => {
  const portraitSchema = Yup.object().shape({
    orientationPortrait: Yup.object().shape({
      leaderboard: Yup.object().shape({
        ...leaderboardCommonSchema,
      }),
      voteable: Yup.object().shape({
        ...voteableCommonSchema,
        hOffset: Yup.number().integer().nullable(true),
      }),
    }),
  })

  return Yup.object()
    .shape({
      orientationLandscape: Yup.object().shape({
        leaderboard: Yup.object().shape({
          ...leaderboardCommonSchema,
        }),
        voteable: Yup.object().shape({
          ...voteableCommonSchema,
        }),
      }),
    })
    .concat(!isTV ? portraitSchema : Yup.object())
}
