import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { tr } from '@constants/other'
import { ReactComponent as MusicNoteSVG } from '@assets/music-note.svg'
import Modal, { ModalSize } from '@components/molecules/Modal'
import AssetList from '@components/organisms/AssetManager/AssetList'
import Header from '@components/organisms/AssetManager/Header'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import UploadAssets from '@components/organisms/AssetManager/UploadAssets'
import EmptyAssetListView from '@components/organisms/AssetManager/EmptyAssetListView'
import {
  useDeteleAssets,
  useDeteleMultipleAssets,
  useGetAssets,
} from '@services/requests/assets'
import {
  AssetContext,
  AssetManagerView,
  AssetProvider,
} from '@services/providers/AssetProvider'
import Image from '@interfaces/Image'
import { useInfiniteScroll } from '@utils/hooks/useInfiniteScroll'
import { ReactComponent as SearchSVG } from '@assets/search.svg'
import { AssetManagerProps } from './types'
import ManageAssetList from './ManageAssetList'
import { useEffect } from 'react'
import { ButtonVariant } from '@components/atoms/Button'
import { Alignment } from '@components/molecules/ConfirmationModal/types'
import { AssetActionTypes } from '@services/reducers/assetReducer'
import { AssetComponentMode } from '@interfaces/Assets'
import { AssetClass } from '@interfaces/Assets'
import PlayAudio from '@components/molecules/PlayAudio/PlayAudio'

const AssetManager: FC<React.PropsWithChildren<AssetManagerProps>> = ({
  title,
  deleteBtnTitle,
  deleteBtnSubtitle,
  noAssetsTitle,
  noAssetsSubtitle,
  showAttachBtn,
  type,
  mode = AssetComponentMode.Modal,
  onClose,
  onSelect,
  onDelete,
  isOpen = false,
  accept,
  pageSize = 30,
  maxAttachableSize = 1,
  maxAttachableSizeErrorMessage,
}) => {
  const { state, dispatch } = useContext(AssetContext)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showExitModal, setShowExitModal] = useState(false)
  const [showPlayAudioModal, setShowPlayAudioModal] = useState(false)
  const [selectedAsset, setSelectedAsset] = useState<Image[]>([])
  const [search, setSearch] = useState<string>('')

  const deteleAssets = useDeteleAssets(selectedAsset?.[0]?.id, type)
  const deteleMultipleAssets = useDeteleMultipleAssets(type)
  const {
    data: assetsData,
    fetchNextPage,
    hasNextPage,
    refetch,
    isLoading,
    isRefetching,
    isFetchingNextPage,
  } = useGetAssets({ filter: type, search, limit: pageSize })

  const focusRef = useRef<HTMLInputElement>(null)

  const { triggerRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: fetchNextPage,
  })

  const handleMultiImageSelection = useCallback(
    (item: Image, isMulti?: boolean) => {
      if (isMulti) {
        const index = selectedAsset?.findIndex((image) => image.id === item.id)
        if (index !== -1) {
          setSelectedAsset([
            ...selectedAsset.slice(0, index),
            ...selectedAsset.slice(index + 1),
          ])
          return
        }
        setSelectedAsset([...selectedAsset, item])
      } else {
        setSelectedAsset([item])
      }
    },
    [selectedAsset]
  )

  const handleAttachImage = useCallback(
    (image: Image | Image[]) => {
      onSelect(Array.isArray(image) ? image : [image])
      onClose()
    },
    [onSelect, onClose]
  )

  const handlePlaySound = useCallback((image: Image) => {
    setShowPlayAudioModal(true)
  }, [])

  useEffect(() => {
    refetch()
  }, [search, refetch])

  const modalVisibilityModifier = showDeleteModal ? 'opacity-0' : ''
  const hasAssets = Boolean(assetsData?.pages?.[0]?.assets?.length)
  const modalTitle = useMemo(() => {
    const assetListTitle =
      title ||
      tr({
        id: 'assetManager.assetModalListTitle',
      })
    const titles = {
      [AssetManagerView.AssetList]: assetListTitle,
      [AssetManagerView.ManageAssets]: tr({
        id: 'assetManager.assetModalManageTitle',
      }),
      [AssetManagerView.UploadAssets]: tr({
        id: 'assetManager.assetModalUploadTitle',
      }),
    }
    return titles[state.view] || ''
  }, [state.view, title])

  // Cancels the pending uploads when confirming closing the modal
  const handleCancel = async () => {
    state.uploadableAssets?.forEach((asset) => {
      if (asset.status == 'uploading') asset.cancelRequest?.cancel()
    })
  }

  // Shows confirmation modal to close the assetManager if there's pending uploads
  const handleClose = () => {
    if (!Boolean(state.uploadableAssets.length)) onClose()

    const assetsUploading = state.uploadableAssets?.find((asset) => {
      return asset.status == 'uploading'
    })

    if (assetsUploading) return setShowExitModal(true)
    handleInternalClose()
  }

  const handleInternalClose = () => {
    onClose()
    dispatch({
      type: AssetActionTypes.ChangeView,
      payload: AssetManagerView.AssetList,
    })
    dispatch({
      type: AssetActionTypes.Update,
      payload: [],
    })
  }

  const refetching = !isFetchingNextPage && isRefetching

  return (
    <Fragment>
      {mode === AssetComponentMode.List && (
        <div>
          <Header
            withFilters
            ref={focusRef}
            className="mb-2"
            title={title ?? modalTitle}
            onSearch={(text) => {
              setSearch(text)
              if (text !== search) {
                setSelectedAsset([])
              }
            }}
            onClick={() => setSelectedAsset([])}
          />

          {/* Loading List View */}
          {refetching && (
            <div className="flex flex-col items-center justify-center m-4 h-[15em] bg-gray rounded">
              <span className="font-bold text-[#959DA5] mt-2">
                {tr({ id: 'generic.loading' })}
              </span>
            </div>
          )}

          {/* List Assets View */}
          {!refetching && hasAssets && (
            <AssetList
              type={type}
              paginatedAssets={assetsData}
              selectedAssets={selectedAsset}
              onClose={onClose}
              onSelect={handleMultiImageSelection}
              showAttachBtn={showAttachBtn}
              showPlaySoundBtn={type === AssetClass.BuffSound}
              onPlaySound={handlePlaySound}
              onAttachAsset={handleAttachImage}
              openDeleteModal={setShowDeleteModal}
              triggerRef={triggerRef}
              maxAttachableSize={maxAttachableSize}
              maxAttachableSizeErrorMessage={maxAttachableSizeErrorMessage}
            />
          )}

          {/* Empty List View */}
          {!refetching && !hasAssets && !search && (
            <EmptyAssetListView
              title={noAssetsTitle}
              subtitle={noAssetsSubtitle}
              icon={
                type === AssetClass.BuffSound && (
                  <MusicNoteSVG className="w-6 h-6" color="white" />
                )
              }
            />
          )}

          {/* Empty Search List View */}
          {!refetching && !hasAssets && Boolean(search) && (
            <div className="flex flex-col items-center justify-center m-4 h-[15em] bg-gray rounded">
              <div className="w-12 h-12 items-center justify-center flex bg-[#959DA5] rounded-full">
                <SearchSVG className="w-6 h-6" color="white" />
              </div>
              <span className="font-bold text-[#959DA5] mt-2">
                {tr({ id: 'assetManager.noSearchAssets' })}
              </span>
            </div>
          )}

          <Modal
            initialFocusRef={focusRef}
            isOpen={state?.view !== AssetManagerView.AssetList}
            onClose={handleClose}
            className={modalVisibilityModifier}
            size={ModalSize.LARGE}
          >
            <div>
              {/* Upload View */}
              {state?.view === AssetManagerView.UploadAssets && (
                <UploadAssets
                  type={type}
                  onClose={handleInternalClose}
                  accept={accept}
                />
              )}

              {/* Manage View */}
              {state?.view === AssetManagerView.ManageAssets && (
                <div className="mx-4">
                  <ManageAssetList
                    type={type}
                    setSelectedAsset={setSelectedAsset}
                    selectedAssets={selectedAsset}
                  />
                </div>
              )}
            </div>

            {/* Confirmation Modal to exit the modal with uploads in progress  */}
            <ConfirmationModal
              alignment={Alignment.END}
              show={showExitModal}
              onClose={() => setShowExitModal(false)}
              successBtnText={tr({ id: 'assetManager.continueUploads' })}
              cancelBtnText={tr({ id: 'assetManager.cancelUploads' })}
              title={tr({
                id: 'assetManager.cancelRemainingUploadsModalTitle',
              })}
              subtext={tr({
                id: 'assetManager.cancelRemainingUploadsModalText',
              })}
              onCancel={async () => {
                await handleCancel()
                onClose()
              }}
            />
          </Modal>

          {/* Confirmation Modal to delete an asset  */}
          <ConfirmationModal
            alignment={Alignment.END}
            variant={ButtonVariant.Danger}
            show={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            successBtnText={tr({ id: 'generic.delete' })}
            title={deleteBtnTitle || tr({ id: 'assetManager.deleteAsset' })}
            subtext={
              deleteBtnSubtitle || tr({ id: 'assetManager.confirmDelete' })
            }
            onSuccess={() => {
              onDelete && onDelete()
              if (selectedAsset.length === 1) deteleAssets.mutate()
              if (selectedAsset.length > 1) {
                const idsToDelete = selectedAsset.map((asset) => asset.id)
                deteleMultipleAssets.mutate({
                  ids: idsToDelete,
                })
              }
              setSelectedAsset([])
            }}
          />

          <PlayAudio
            show={showPlayAudioModal}
            onClose={() => setShowPlayAudioModal(false)}
            file={selectedAsset?.[0]}
          />
        </div>
      )}

      {mode === AssetComponentMode.Modal && (
        <Modal
          initialFocusRef={focusRef}
          isOpen={isOpen}
          onClose={handleClose}
          className={modalVisibilityModifier}
          size={ModalSize.LARGE}
        >
          <div>
            <Header
              ref={focusRef}
              className="!mb-2"
              title={modalTitle}
              onSearch={(text) => {
                setSearch(text)
                if (text !== search) {
                  setSelectedAsset([])
                }
              }}
              onClick={() => setSelectedAsset([])}
            />

            {/* Loading List View */}
            {state?.view === AssetManagerView.AssetList && refetching && (
              <div className="flex flex-col items-center justify-center m-4 h-[15em] bg-gray rounded">
                <span className="font-bold text-[#959DA5] mt-2">
                  {tr({ id: 'generic.loading' })}
                </span>
              </div>
            )}

            {/* List Assets View */}
            {!refetching &&
              hasAssets &&
              state?.view === AssetManagerView.AssetList && (
                <AssetList
                  type={type}
                  paginatedAssets={assetsData}
                  selectedAssets={selectedAsset}
                  onClose={onClose}
                  onSelect={handleMultiImageSelection}
                  showAttachBtn={showAttachBtn}
                  onAttachAsset={handleAttachImage}
                  showPlaySoundBtn={type === AssetClass.BuffSound}
                  onPlaySound={handlePlaySound}
                  openDeleteModal={setShowDeleteModal}
                  triggerRef={triggerRef}
                  maxAttachableSize={maxAttachableSize}
                  maxAttachableSizeErrorMessage={maxAttachableSizeErrorMessage}
                />
              )}

            {/* Empty List View */}
            {state?.view === AssetManagerView.AssetList &&
              !refetching &&
              !hasAssets &&
              !search && (
                <EmptyAssetListView
                  title={noAssetsTitle}
                  subtitle={noAssetsSubtitle}
                  icon={
                    type === AssetClass.BuffSound && (
                      <MusicNoteSVG className="w-6 h-6" color="white" />
                    )
                  }
                />
              )}

            {/* Empty Search List View */}
            {state?.view === AssetManagerView.AssetList &&
              !refetching &&
              !hasAssets &&
              Boolean(search) && (
                <div className="flex flex-col items-center justify-center m-4 h-[15em] bg-gray rounded">
                  <div className="w-12 h-12 items-center justify-center flex bg-[#959DA5] rounded-full">
                    <SearchSVG className="w-6 h-6" color="white" />
                  </div>
                  <span className="font-bold text-[#959DA5] mt-2">
                    {tr({ id: 'assetManager.noSearchAssets' })}
                  </span>
                </div>
              )}

            {/* Upload View */}
            {state?.view === AssetManagerView.UploadAssets && (
              <UploadAssets type={type} onClose={onClose} accept={accept} />
            )}

            {/* Manage View */}
            {state?.view === AssetManagerView.ManageAssets && (
              <div className="mx-4">
                <ManageAssetList
                  type={type}
                  setSelectedAsset={setSelectedAsset}
                  selectedAssets={selectedAsset}
                />
              </div>
            )}
          </div>

          {/* Confirmation Modal to delete an asset  */}
          <ConfirmationModal
            alignment={Alignment.END}
            variant={ButtonVariant.Danger}
            show={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            successBtnText={tr({ id: 'generic.delete' })}
            title={deleteBtnTitle || tr({ id: 'assetManager.deleteAsset' })}
            subtext={
              deleteBtnSubtitle || tr({ id: 'assetManager.confirmDelete' })
            }
            onSuccess={() => {
              onDelete && onDelete()
              if (selectedAsset.length === 1) deteleAssets.mutate()
              if (selectedAsset.length > 1) {
                const idsToDelete = selectedAsset.map((asset) => asset.id)
                deteleMultipleAssets.mutate({
                  ids: idsToDelete,
                })
              }
              setSelectedAsset([])
            }}
          />

          {/* Confirmation Modal to exit the modal with uploads in progress  */}
          <ConfirmationModal
            alignment={Alignment.END}
            show={showExitModal}
            onClose={() => setShowExitModal(false)}
            successBtnText={tr({ id: 'assetManager.continueUploads' })}
            cancelBtnText={tr({ id: 'assetManager.cancelUploads' })}
            title={tr({ id: 'assetManager.cancelRemainingUploadsModalTitle' })}
            subtext={tr({ id: 'assetManager.cancelRemainingUploadsModalText' })}
            onCancel={async () => {
              await handleCancel()
              onClose()
            }}
          />
        </Modal>
      )}
    </Fragment>
  )
}

const AssetManagerWithProvider: FC<
  React.PropsWithChildren<AssetManagerProps>
> = (props) => {
  return (
    <AssetProvider>
      <AssetManager {...props} />
    </AssetProvider>
  )
}

export default AssetManagerWithProvider
