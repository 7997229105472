import { Announcement } from '@interfaces/Announcement'
import { Voteable, VoteableLifecycle } from '@interfaces/Voteable'
import { isVoteable } from '@utils/voteables'
import { BuffState, BuffView } from '../providers/BuffProvider'

export enum BuffActionTypes {
  LoadBuff = 'LOAD_BUFF',
  Update = 'UPDATE_BUFFS',
  UpdateTemplates = 'UPDATE_TEMPLATES',
  ChangeView = 'CHANGE_VIEW',
  SetIsTemplate = 'SET_IS_TEMPLATE',
  SetIsFormSubmitting = 'SET_IS_FORM_SUBMITTING',
}

type ActionMap<M extends Record<string, unknown>> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

type BuffPayload = {
  [BuffActionTypes.LoadBuff]: Voteable
  [BuffActionTypes.Update]: {
    buffs: (Voteable | Announcement)[]
  }
  [BuffActionTypes.UpdateTemplates]: {
    templates: Voteable[]
  }
  [BuffActionTypes.ChangeView]: BuffView
  [BuffActionTypes.SetIsTemplate]: {
    isTemplate: boolean
    templateName: string
  }
  [BuffActionTypes.SetIsFormSubmitting]: boolean
}

export type BuffActions = ActionMap<BuffPayload>[keyof ActionMap<BuffPayload>]

export const buffReducer = (state: BuffState, action: BuffActions) => {
  switch (action.type) {
    case BuffActionTypes.LoadBuff:
      return {
        ...state,
        selectedBuff: action.payload,
      }
    case BuffActionTypes.Update:
      /**
       * Type 1: Prediction
       * Status 4: Resolved
       * Unresolved are buffs that are predictions and their status is not "Resolved"
       *
       */
      const unresolvedBuffs = action.payload.buffs?.filter((buff) => {
        return (
          isVoteable(buff) &&
          !buff?.resolvesAt &&
          buff.lifecycle === VoteableLifecycle.VOTEABLE_LIFECYCLE_PREDICTION
        )
      }) as Voteable[]

      return {
        ...state,
        buffs: action.payload.buffs,
        unresolvedBuffs,
      }
    case BuffActionTypes.UpdateTemplates:
      return {
        ...state,
        templates: action.payload.templates,
      }
    case BuffActionTypes.ChangeView:
      return {
        ...state,
        view: action.payload,
      }
    case BuffActionTypes.SetIsTemplate:
      return {
        ...state,
        isTemplate: action.payload.isTemplate,
        templateName: action.payload.templateName,
      }

    case BuffActionTypes.SetIsFormSubmitting:
      return {
        ...state,
        isFormSubmitting: action.payload,
      }

    default:
      return state
  }
}
