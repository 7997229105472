import React, { createContext, useEffect, useState } from 'react'
import PubNub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'
import { Chat } from '@pubnub/chat'
import { useGetUser } from '@services/requests/users'
import { useChat } from '@services/requests/chat'

export interface PubnubContextProps {
  pubnub?: PubNub
  chat?: Chat
  isInitializedChat?: boolean
  setStream: (streamId: string) => void
}

export interface PubnubProviderProps {
  children?: React.ReactNode
}

export const PubnubContext = createContext<PubnubContextProps>(
  {} as PubnubContextProps
)

export const PubnubProvider = ({ children }: PubnubProviderProps) => {
  const [streamId, setStreamId] = useState<string>()

  const { data: userData } = useGetUser()
  const { useStreamChat } = useChat()
  const userId = userData?.id
  const { initChat, chat, pubnubClient, isInitializedChat } = useStreamChat(
    streamId,
    userId
  )

  useEffect(() => {
    if (!chat?.sdk || !streamId || !userId) return

    const listener = {
      status: (statusEvent: any) => {
        if (
          statusEvent?.error &&
          statusEvent?.operation === 'PNSubscribeOperation' &&
          statusEvent?.errorData?.message === 'Token is expired.'
        ) {
          initChat(0, streamId, userId)
        }
      },
      messageAction: (actionEvent: any) => {
        console.log('message', actionEvent)
      },
    }

    chat.sdk.addListener(listener)

    return () => {
      chat.sdk.removeListener(listener)
    }
  }, [chat?.sdk, initChat, streamId, userId])

  const setStream = (streamId?: string) => {
    setStreamId(streamId)
  }

  return (
    <PubnubContext.Provider
      value={{ pubnub: pubnubClient, isInitializedChat, chat, setStream }}
    >
      {!!pubnubClient ? (
        <PubNubProvider client={pubnubClient}>{children}</PubNubProvider>
      ) : (
        <>{children}</>
      )}
    </PubnubContext.Provider>
  )
}
