import React, { FC, MouseEvent, useState } from 'react'
import { tr } from '@constants/other'
import { ReactComponent as WarningSVG } from '@assets/warning.svg'
import DraggableDots from '@components/atoms/DraggableDots'
import Button, { ButtonSize } from '@components/atoms/Button'
import { useMutation } from 'react-query'
import { useLive } from '@services/requests/live'
import { queryClient } from '@utils/reactQuery/client'
import { useToast } from '@utils/hooks/useToast'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import TooltipPill from '../TooltipPill'
import ConfirmationModal from '../ConfirmationModal'
import { Alignment } from '../ConfirmationModal/types'
import { StreamLeaderboardItemProps } from './types'

const StreamLeaderboardItem: FC<
  React.PropsWithChildren<StreamLeaderboardItemProps>
> = ({
  leaderboardId,
  streamId,
  isStreamAttached,
  name,
  isMonetised,
  isFinalised,
  onClick,
}) => {
  const { finaliseScores } = useLive()
  const { addToast, addErrorToast } = useToast()
  const [showWinnersModal, setShowWinnersModal] = useState(false)
  const showAnnounceWinners = process.env.LEADERBOARD_WINNERS === 'true'
  const handleClick = () => {
    onClick && onClick(leaderboardId)
  }

  const { mutate: finaliseLeaderboardMutate } = useMutation(finaliseScores, {
    onSuccess: () => {
      addToast({
        msg: tr({ id: 'leaderboard.winners.leaderboardAnnounced' }),
        type: 'success',
        image: <Tick className="mr-3 w-8 h-8" />,
      })
      queryClient.invalidateQueries(['leaderboards', streamId])
    },
    onError: (err) => {
      addErrorToast(err)
    },
  })

  const handleAnnounceWinners = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setShowWinnersModal(true)
  }

  const handleClose = () => {
    setShowWinnersModal(false)
  }

  const handleConfirmAnnounceWinners = () => {
    finaliseLeaderboardMutate({ streamId, leaderboardId })
  }

  return (
    <div className="relative mb-3">
      <button
        type="button"
        className="flex flex-col w-full bg-white pl-2 py-3 rounded shadow border-none text-left"
        onClick={handleClick}
      >
        <div className="flex w-full justify-between h-[24px]">
          <div className="flex items-center">
            <DraggableDots className="leaderboard-handle" />
            <div className="font-bold">{name}</div>
          </div>
          <div className="flex flex-row items-center gap-x-2">
            {!isStreamAttached && (
              <TooltipPill
                className={`px-2 flex-shrink-0 bg-[#FCF6E3] text-[#B54708]`}
                text={
                  <div className="flex items-center gap-x-1">
                    <WarningSVG className="w-3 h-3" />
                    <span>{tr({ id: 'leaderboard.noStreamAttached' })}</span>
                  </div>
                }
              />
            )}

            {isMonetised && (
              <TooltipPill
                className={`px-2 flex-shrink-0 bg-[#ECFDF3] text-[#027A48]`}
                text={tr({ id: 'leaderboard.monetised' })}
              />
            )}

            {isFinalised && (
              <TooltipPill
                data-testid="winners-announced-pill"
                className={`px-2 flex-shrink-0 bg-[#EFF8FF] text-[#175CD3]`}
                text={
                  <span>
                    {tr({ id: 'leaderboard.winners.winnersAnnounced' })}
                  </span>
                }
              />
            )}
            {showAnnounceWinners && (
              <Button
                type="button"
                variant={isFinalised ? 'secondary' : 'primary'}
                size={ButtonSize.Mini}
                className="py-3 font-semibold ml-2"
                onClick={handleAnnounceWinners}
                data-testid="show-winners-btn"
              >
                {tr({
                  id: isFinalised
                    ? 'leaderboard.winners.reannounce'
                    : 'leaderboard.winners.confirm',
                })}
              </Button>
            )}

            <img
              className="mr-2 ml-3 w-6 h-6 flex-shrink-0 text-[#586069]"
              width={12}
              src={'/assets/svg/icon-arrow-right.svg'}
            />
          </div>
        </div>
      </button>
      <ConfirmationModal
        onCancel={handleClose}
        onSuccess={handleConfirmAnnounceWinners}
        onClose={handleClose}
        title={`${tr({
          id: !isFinalised
            ? 'leaderboard.winners.title'
            : 'leaderboard.winners.reannounceTitle',
        })}`}
        successBtnText={tr({
          id: !isFinalised
            ? 'leaderboard.winners.confirm'
            : 'leaderboard.winners.reannounce',
        })}
        subtext={tr({
          id: !isFinalised
            ? 'leaderboard.winners.text'
            : 'leaderboard.winners.reannounceText',
        })}
        show={showWinnersModal}
        data-testid="show-winners-modal"
        alignment={Alignment.END}
      />
    </div>
  )
}

export default StreamLeaderboardItem
