import { tr } from '@constants/other'
import { Standing } from '@interfaces/Leaderboard'
import { formatPercentage } from '@utils/leaderboard'

const escapeCSV = (value: string) => {
  if (value?.includes(',')) {
    return `"${value.replace(/"/g, '""')}"`
  }
  return value
}

export const generateLeaderboardCSV = (rows: Standing[]) => {
  const headers = [
    tr({ id: 'leaderboard.rank' }),
    tr({ id: 'leaderboard.name' }),
    tr({ id: 'leaderboard.email' }),
    tr({ id: 'leaderboard.opaqueId' }),
    tr({ id: 'leaderboard.points' }),
  ]

  let csvFile = headers.join(',') + '\n'

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    const rank =
      row?.position ??
      tr(
        { id: 'leaderboard.percentageText' },
        { percentage: formatPercentage(row?.points ?? 0) }
      )
    const name = escapeCSV(row.displayName) ?? ''
    const email = row.email ?? ''
    const points = row.points ?? 0
    const opaqueID = row.opaqueID ?? ''

    const csvRow = `${rank},${name},${email},${opaqueID},${points}\n`

    csvFile += csvRow
  }

  return csvFile
}
