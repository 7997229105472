import { useState, useEffect } from 'react'

/**
 * @param {Function} singleClickCallback
 * @param {Function} doubleClickCallback
 * @param {number} delay
 * @return {Function}
 */
function useSingleAndDoubleClick(
  singleClickCallback: () => void,
  doubleClickCallback: () => void,
  delay = 250
) {
  const [click, setClick] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (click === 1) singleClickCallback()
      setClick(0)
    }, delay)

    if (click === 2) {
      doubleClickCallback()
      setClick(0)
    }

    return () => clearTimeout(timer)
  }, [click, delay, singleClickCallback, doubleClickCallback])

  return () => setClick((prev) => prev + 1)
}

export default useSingleAndDoubleClick
