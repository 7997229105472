import React, { FC } from 'react'
import Skeleton, { SkeletonColour } from '@components/atoms/Skeleton'

interface IProps {
  /**
   * Title to display in the component
   */
  title: string

  /**
   * Selection ID for test purposes.
   */
  'data-testid'?: string
}

const EmptyState: FC<React.PropsWithChildren<IProps>> = ({
  title,
  'data-testid': testId,
}) => {
  const skeletonArray = new Array(6).fill(null)

  return (
    <div
      className="flex flex-col p-5 items-center w-full"
      style={{ backgroundColor: 'var(--white-background)' }}
      data-testid={testId}
    >
      <div className="relative">
        <img
          src="/assets/svg/curved-arrow.svg"
          className="absolute"
          style={{ bottom: 'calc(100% + 5px)' }}
          alt=""
        />
        <h3 className="h3 ml-2 font-normal">{title}</h3>
        <div
          className="flex p-4 pb-5 m-2 rounded flex-wrap"
          style={{
            backgroundColor: 'white',
            boxShadow: '0 5px 15px 0 rgba(9, 14, 37, 0.1)',
            minWidth: 900,
          }}
        >
          {skeletonArray.map((_x, index) => {
            return (
              <div key={index} className="w-50 p-4 mb-4">
                <Skeleton colour={SkeletonColour.GREY} className="h3" />
                <Skeleton colour={SkeletonColour.GREY} className="w-50" />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default EmptyState
