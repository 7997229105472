export interface NewConfig {
  hideMenu: boolean
  hideProfilePicture: boolean
  allowProfileEdit: boolean
  playVoteableSound: boolean
  voteableSound: string
  theme?: Theme
  timeSync: boolean
  welcomeBuffId: string | null
  secondScreenAppDelay: number
  renderSportBuffUI: boolean
  useLiveVoting: boolean
  allowGamePoints: boolean
}

export enum Platform {
  WEB = 'WEB',
  TWITCH = 'TWITCH',
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  TizenTV = 'TV_TIZEN_WEBOS',
  AndroidTV = 'TV_ANDROID',
}

export enum Position {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export enum Theme {
  LIGHT = 'THEME_LIGHT',
  DARK = 'THEME_DARK',
  QATAR2022 = 'THEME_QATAR2022',
  KAN = 'THEME_KAN',
  KAN_QATAR2022 = 'THEME_KAN_QATAR2022',
  CARACOL = 'THEME_CARACOL',
  NUBANK = 'THEME_NUBANK',
  RUGBY = 'THEME_RUGBYWC',
  EUROLEAGUE = 'THEME_EUROLEAGUE',
  ONEK = 'THEME_1K',
  FWWC = 'THEME_FWWC',
  DIRECTV = 'THEME_DIRECTV',
}

export enum Orientation {
  orientationLandscape = 'orientationLandscape',
  orientationPortrait = 'orientationPortrait',
}

export interface Positioning {
  hAlign: Position
  hOffset: number
  vAlign: Position
  vOffset: number
}

export interface ElementsPositioning {
  leaderboard: Positioning
  voteable: Positioning
}

export interface PositionConfig {
  platform: Platform
  [Orientation.orientationLandscape]: ElementsPositioning
  [Orientation.orientationPortrait]: ElementsPositioning
  draggableLeaderboard: boolean
}

export interface StreamConfig {
  streamId: string
  config: NewConfig
  positionConfig: PositionConfig[]
}

export interface StreamConfigUpdate extends Omit<StreamConfig, 'config'> {
  config: Partial<NewConfig>
}

export interface StreamConfigThemeFormValues {
  theme?: Theme
}
