import { string, object, AnySchema } from 'yup'
import { isValidURL } from '@utils/validation'
import { tr } from '@constants/other'

export const getSchema = (languages: string[]) => {
  const text: Partial<Record<string, AnySchema>> = ({} = {})

  languages.forEach((lang) => {
    const schemaObject = {
      line1: string().required(tr({ id: 'validation.required' })),
    }

    text[lang] = object().shape(schemaObject)
  })

  return object().shape({
    url: string()
      // .required(tr({ id: 'validation.required' }))
      .test(...isValidURL(tr({ id: 'sponsor.linkTargetError' }))),
    text: object().shape(text as any),
  })
}
