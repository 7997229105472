import React, { useMemo } from 'react'
import { tr } from '@constants/other'
import { Form, Formik } from 'formik'
import Button from '@components/atoms/Button'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import { useToast } from '@utils/hooks/useToast'
import { useMutation, useQuery } from 'react-query'
import { queryClient } from '@utils/reactQuery/client'
import { useClientConfig } from '@services/requests/client-config'
import LanguageItem from '@components/atoms/LanguageItem'
import { PLATFORMS } from '@constants/platforms'
import { PlatformConfigProps, PlatformFormValues } from './types'
import { Schema } from './schema'

const PlatformConfig = (_: PlatformConfigProps) => {
  const { getPlatforms, updatePlatforms } = useClientConfig()
  const { addToast, addErrorToast } = useToast()

  const { data: platforms } = useQuery(['platforms'], getPlatforms)

  const { mutate: mutateUpdatePlatforms, isLoading } = useMutation(
    updatePlatforms,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['platforms'])
        addToast({
          msg: tr({ id: 'configuration.platformSettingsUpdated' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
      },
      onError: (err) => {
        addErrorToast(err)
      },
    }
  )

  const initialValues: PlatformFormValues = useMemo(() => {
    if (!platforms) {
      return {
        platforms: [],
      }
    }
    return platforms
  }, [platforms])

  const handleSubmit = (values: PlatformFormValues) => {
    mutateUpdatePlatforms(values)
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange
        validateOnBlur
        validateOnMount
        enableReinitialize
        validationSchema={Schema}
      >
        {({ values, isValid, setValues }) => {
          return (
            <Form>
              <fieldset
                className="p-4 rounded-lg mb-4 bg-white-background"
                data-testid="qr-code-url"
              >
                <h3 className="mb-3">
                  {tr({ id: 'configuration.supportedPlatforms' })}
                </h3>

                <div className="flex items-center flex-wrap gap-x-4 gap-y-1">
                  {PLATFORMS.map((platform) => {
                    return (
                      <LanguageItem
                        key={platform.value}
                        name={platform.name}
                        active={values.platforms.includes(platform.value)}
                        setActive={(active) => {
                          if (active) {
                            setValues({
                              platforms: [...values.platforms, platform.value],
                            })
                          } else {
                            setValues({
                              platforms: values.platforms.filter(
                                (p) => p !== platform.value
                              ),
                            })
                          }
                        }}
                      />
                    )
                  })}
                </div>
              </fieldset>

              <div className="flex justify-end">
                <Button type="submit" disabled={isLoading || !isValid}>
                  {tr({
                    id: 'generic.update',
                  })}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default PlatformConfig
