import { LanguageCode } from '@interfaces/ILanguage'
import { LocalisedContent } from '@interfaces/localisation'

/**
 * This function orders an array of unordered localisable content based on the order of a second array
 * @param {LocalisedContent[]} localisableContent - Unordered array
 * @param {LanguageCode[]} languages - Ordered array
 * @return {LocalisedContent[]}
 */
const sortLocalisableContent = <T extends LocalisedContent>(
  localisableContent: T[],
  languages: LanguageCode[]
): T[] => {
  if (!languages) return localisableContent

  const orderedContent = [...localisableContent]

  return orderedContent.sort(
    (a, b) =>
      languages.indexOf(a.localisation) - languages.indexOf(b.localisation)
  )
}

export default sortLocalisableContent
