import React, { FC, useState } from 'react'
import Button, { ButtonVariant } from '@components/atoms/Button'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextInput from '@components/atoms/TextInput'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import AssetManager from '@components/organisms/AssetManager'
import { tr } from '@constants/other'
import Image from '@components/atoms/Image'
import { ReactComponent as PlaceholderSVG } from '@assets/placeholder.svg'
import { AssetClass } from '@interfaces/Assets'
import { CreateAuthor } from '@interfaces/Author'
import { AuthorFormValues } from './types'
import { AcceptImageTypeExceptGift } from '@utils/asset'

type IRouteParams = {
  id: string
}

interface AuthorFormProps {
  initialValues?: AuthorFormValues
  selectedAuthor?: string
  handleSubmit: (values: CreateAuthor) => Promise<void>
  handleDelete?: (id: string) => void
}

const AuthorForm: FC<React.PropsWithChildren<AuthorFormProps>> = ({
  selectedAuthor,
  handleSubmit,
  handleDelete,
  initialValues = {
    displayName: '',
    profilePicture: '',
  },
}) => {
  const [isAssetManagerOpen, setIsAssetManagerOpen] = useState(false)

  const [showDelete, setShowDelete] = useState<boolean>(false)
  const { id: authorId } = useParams<IRouteParams>()

  const schema = Yup.object().shape({
    displayName: Yup.string().required(tr({ id: 'validation.displayName' })),
    profilePicture: Yup.string().required(
      tr({ id: 'validation.profilePicture' })
    ),
  })

  return (
    <div data-testid="author-form">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <p className="mx-3 mt-1 mb-2">
              {selectedAuthor
                ? tr({ id: 'author.authorUpdateDesc' })
                : tr({ id: 'author.authorCreateDesc' })}
            </p>
            <div className="p-3 m-3 rounded flex flex-col bg-white-background">
              <h3 className="font-bold mb-3">{tr({ id: 'author.details' })}</h3>
              <div className="flex justify-between">
                <div className="w-50">
                  <TextInput
                    id="displayName"
                    label={tr({ id: 'author.displayName' })}
                    style={{ border: '1px solid lightgrey' }}
                    className={'w-full px-2 py-1 rounded'}
                    placeholder={tr({ id: 'author.displayName' })}
                    value={values.displayName}
                    onChange={handleChange}
                    error={Boolean(errors.displayName)}
                    errorLabel={errors.displayName}
                    touched={touched.displayName}
                    data-testid="author-form__display-name"
                  />
                </div>
                <div className="flex flex-col relative">
                  <p className="mt-0 mb-1">
                    {tr({
                      id: 'author.authorImage',
                    })}
                  </p>
                  <div className="flex flex-col items-center">
                    <div className="rounded border-[1px] border-dashed border-[#717A85] w-[204px] h-[255px]">
                      {values.profilePicture && (
                        <div
                          style={{
                            backgroundImage: `url(/assets/image_placeholder.png)`,
                          }}
                          className="relative bg-cover no-repeat w-full h-full"
                        >
                          <Image
                            className="absolute inset-0 w-full h-full rounded object-cover"
                            src={values.profilePicture}
                            fallbackComponent={
                              <PlaceholderSVG className="absolute inset-0 w-full h-full rounded object-cover" />
                            }
                            alt={tr({ id: 'author.image' })}
                          />
                        </div>
                      )}
                      {!values.profilePicture && (
                        <div className="flex text-center items-center w-full h-full bg-white text-[12px] justify-center rounded">
                          <div className="flex flex-col items-center">
                            {errors.profilePicture &&
                              touched.profilePicture && (
                                <>
                                  <div className="m-2 text-white text-[1.3em] rounded-xl w-[24px] h-[24px] bg-red">
                                    !
                                  </div>
                                  <div
                                    className="mb-2 italic text-red"
                                    data-testid="author-form__photo-error"
                                  >
                                    {errors.profilePicture}
                                  </div>
                                </>
                              )}

                            <p className="font-bold mb-1">
                              {tr({ id: 'generic.minImageSize' })}
                            </p>
                            <p className="mb-0 font-semibold text-[#586069]">
                              {tr(
                                {
                                  id: 'generic.ratio',
                                },
                                { x: 4, y: 5 }
                              )}
                            </p>
                            <p className="mb-0 text-[#586069]">400 x 500 px</p>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex justify-between mt-3 mb-4 w-[204px] gap-3">
                      <Button
                        className="w-50"
                        type="button"
                        size="small"
                        onClick={() => setIsAssetManagerOpen(true)}
                        data-testid="author-form__add-replace-photo"
                      >
                        {selectedAuthor
                          ? tr({ id: 'generic.replace' })
                          : tr({ id: 'generic.add' })}
                      </Button>
                      <Button
                        className="w-50"
                        size="small"
                        variant="secondary"
                        data-testid="author=form__delete-photo"
                        type="button"
                        onClick={() => {
                          setFieldValue('profilePicture', '')
                        }}
                      >
                        {tr({ id: 'generic.delete' })}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`flex mx-3 ${
                authorId ? 'justify-between' : 'justify-end'
              }`}
            >
              {authorId && (
                <Button
                  size="small"
                  className="delete-btn w-[11rem]"
                  variant="secondary"
                  type="button"
                  onClick={() => setShowDelete(true)}
                  data-testid="author-form__delete"
                >
                  {tr({ id: 'author.authorDelete' })}
                </Button>
              )}
              <Button
                size="small"
                className="w-[11rem]"
                type="submit"
                disabled={isSubmitting}
                data-testid="author-form__submit"
              >
                {selectedAuthor
                  ? tr({ id: 'author.authorUpdate' })
                  : tr({ id: 'author.authorCreate' })}
              </Button>
            </div>

            {isAssetManagerOpen && (
              <AssetManager
                accept={AcceptImageTypeExceptGift}
                title={tr({ id: 'assetManager.assetAuthorModalListTitle' })}
                type={AssetClass.Author}
                isOpen={isAssetManagerOpen}
                onClose={() => setIsAssetManagerOpen(false)}
                onSelect={([image]) => {
                  const imageUrl = image.publicURL + image.fileName
                  setFieldValue('profilePicture', imageUrl)
                }}
              />
            )}
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        show={showDelete}
        onClose={() => setShowDelete(false)}
        onSuccess={() => {
          if (handleDelete && selectedAuthor) handleDelete(selectedAuthor)
        }}
        subtext={tr({ id: 'author.confirmDelete' })}
        successBtnText={tr({ id: 'author.authorDelete' })}
        variant={ButtonVariant.Danger}
      />
    </div>
  )
}

export default AuthorForm
