import React, { FC, useEffect, useRef, useState, useContext } from 'react'
import PasscodeInput, {
  PasscodeInputRef,
} from '@components/atoms/PasscodeInput'
import { loginWithOTP, sendOTP } from '@services/requests/auth'
import InfoText from '@components/atoms/InfoText'
import { ErrorContext } from '@services/providers/ErrorProvider'
import { tr } from '@constants/other'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import { ReactComponent as BackIcon } from '@assets/arrow_left.svg'
import { PasscodeProps } from './types'

const SHOW_EMAIL_SENT_MS = 5000

const Passcode: FC<React.PropsWithChildren<PasscodeProps>> = ({
  email,
  onSuccess,
  onGoBack,
}) => {
  const { setModalError } = useContext(ErrorContext)
  const [loadingOTP, setLoadingOTP] = useState(false)
  const [loadingResend, setLoadingResend] = useState(false)
  const [error, setError] = useState('')
  const [showEmailSend, setShowEmailSend] = useState(false)

  const emailSentRef = useRef<number>()
  const passcodeInputRef = useRef<PasscodeInputRef>(null)

  const resetError = () => setError('')

  const setEmailSendTimeout = () => {
    setShowEmailSend(true)
    emailSentRef?.current && clearTimeout(emailSentRef.current)
    emailSentRef.current = window.setTimeout(() => {
      setShowEmailSend(false)
    }, SHOW_EMAIL_SENT_MS)
  }

  const handleSubmit = async (code: string) => {
    try {
      resetError()
      setShowEmailSend(false)
      setLoadingOTP(true)

      const token = await loginWithOTP(code, email)
      onSuccess(token)
    } catch (err) {
      const statusCode = err?.response?.data?.error?.code

      let errMessage = tr({ id: 'errors.5009' })

      switch (statusCode) {
        case 401:
          errMessage = tr({ id: 'errors.invalidPasscode' })
          break

        case 403:
          setModalError({
            title: 'Unauthorized Access',
            description:
              'Your account does not have access to this page.\nPlease switch or upgrade your account.',
          })
          return
      }

      setError(errMessage)
    } finally {
      setLoadingOTP(false)
    }
  }

  const handleResend = async () => {
    try {
      resetError()
      setLoadingResend(true)
      await sendOTP(email)
      passcodeInputRef?.current?.clear()
      setEmailSendTimeout()
    } catch (err) {
      const errMessage = tr({ id: 'errors.5009' })
      setError(errMessage)
    } finally {
      setLoadingResend(false)
    }
  }

  const handleGoBack = () => {
    onGoBack()
  }

  useEffect(() => {
    return () => {
      emailSentRef?.current && clearTimeout(emailSentRef.current)
    }
  }, [])

  const loading = loadingOTP || loadingResend
  const disableResend = loading || showEmailSend
  const resendOpacity = disableResend ? 'opacity-60' : 'opacity-100'

  return (
    <div
      data-testid="passcode"
      className="flex flex-col items-center min-h-[75px]"
    >
      <div className="flex items-center justify-between mb-2">
        <button
          disabled={loading}
          data-testid="passcode__back-button"
          className="bg-transparent relative outline-none border-none h-8 w-8 flex-shrink-0"
          onClick={handleGoBack}
        >
          <BackIcon className="w-full" />
        </button>
        <h4 className="font-md m-0">
          {tr({ id: 'signIn.continueWithEmail' })}
        </h4>
        <div className="w-8" />
      </div>
      <div className="flex flex-col flex-1 items-center">
        <p className="font-bold text-md mb-2">
          {tr({ id: 'signIn.enterPasscode' })}
        </p>
        <p className="text-sm text-center mb-1 sb-break-words">
          {tr({ id: 'signIn.sentOTP' }, { value: email })}
        </p>
        <PasscodeInput
          ref={passcodeInputRef}
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
        />
        {loadingOTP && (
          <p className="text-sm text-primary">
            {tr({ id: 'signIn.checkingPasscode' })}
          </p>
        )}
        <InfoText text={error} />
        <InfoText
          color="primary"
          Icon={Tick}
          text={showEmailSend ? tr({ id: 'signIn.emailSent' }) : undefined}
        />
        <button
          disabled={disableResend}
          className="text-center mt-2 outline-none border-none bg-transparent"
          onClick={handleResend}
        >
          <span
            className={`text-primary underline font-bold text-sm ${resendOpacity}`}
          >
            {tr({ id: 'signIn.resendPasscode' })}
          </span>
        </button>
      </div>
    </div>
  )
}

export default Passcode
