import React from 'react'
import { useInfiniteScroll } from '@utils/hooks/useInfiniteScroll'
import { useInfiniteStreams } from '@utils/hooks/useStreams'
import EmptyStateWithIcon from '@components/molecules/EmptyStateWithIcon'
import StreamList from '@components/organisms/StreamList'
import { ReactComponent as SearchIcon } from '@assets/search.svg'
import { LeaderboardStreamsTabProps } from './types'

const LeaderboardStreamSectionAllTab = ({
  onChange,
  selectedStreams,
  filter,
  disabled,
}: LeaderboardStreamsTabProps) => {
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteStreams(
    10,
    filter,
    undefined,
    'leaderboard-streams-infinite'
  )

  const { triggerRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: fetchNextPage,
  })

  if (isLoading) {
    return (
      <EmptyStateWithIcon
        text="Loading..."
        icon={<SearchIcon className="w-10" />}
      />
    )
  }

  const streams = data?.pages
    ? data.pages.flatMap((page) => {
        return page.streams
      })
    : []

  if (streams.length === 0) {
    return (
      <EmptyStateWithIcon
        text="No stream found"
        icon={<SearchIcon className="w-10" />}
      />
    )
  }

  return (
    <StreamList
      triggerRef={triggerRef}
      streams={streams}
      onChange={onChange}
      selectedStreamsIds={selectedStreams}
      disabled={disabled}
    />
  )
}

export default LeaderboardStreamSectionAllTab
