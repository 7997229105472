import React, { FC } from 'react'

import { tr } from '../constants/other'

const NotFoundPage: FC = () => {
  return (
    <div>
      <div className="login-box flex w-full h-screen justify-center items-center">
        <div
          title={tr({ id: 'notFound.notFound' })}
          className="flex flex-col justify-center"
        >
          <p className="login-box-msg">
            {tr({ id: 'notFound.notFoundMessage' })}
          </p>
          <a href="/" className="sb-btn !text-white !bg-primary">
            {tr({ id: 'generic.goBack' })}
          </a>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
