import React, { FC, useRef } from 'react'
import {
  MessagePayload,
  MessageRendererProps,
} from '@pubnub/react-chat-components'
import dateDiffInDays from '@utils/dateDiffInDays'
import MessageItem from '@components/molecules/MessageItem'
import { Message } from '@pubnub/chat'
import { ChannelType } from '../../StreamChatConfig'

interface ChatMessageItemProps {
  props: MessageRendererProps
  channelType: ChannelType
  pinMessage?: Message
  onPinMessage?: (id?: string) => void
  onUnpinMessage?: () => void
  onDeleteMessage?: (id?: string) => void
  onStarMessage?: (id?: string) => void
  onUnstarMessage?: (id?: string) => void
}

const ChatMessageItem: FC<ChatMessageItemProps> = ({
  props,
  channelType,
  pinMessage,
  onPinMessage,
  onUnpinMessage,
  onDeleteMessage,
  onStarMessage,
  onUnstarMessage,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const messagePayload = props?.message?.message as MessagePayload
  const isDeleted =
    props.message?.actions?.deleted?.data?.some(
      (action: { uuid: string; actionTimetoken: string | number }) =>
        action.uuid === 'chat-svc'
    ) ?? false

  if (isDeleted) {
    const div = document.getElementById(messagePayload.id)
    const parentDiv = div?.parentElement
    parentDiv?.classList.remove('pn-msg')
  }

  if (props.message?.message?.file) return <></>
  if (!messagePayload?.text) return <></>

  const text = messagePayload.text.replace(/\\n/g, '\n')
  let messageTimestamp = props.message.timetoken
  const isStarredChannel = props.message.channel?.endsWith('.starred')
  let diffDays = 0
  let formattedDateTime = ''
  let formattedTime = ''

  if (isStarredChannel) {
    messageTimestamp =
      (props.message.actions &&
        Object.keys(props.message.actions.connected)[0]) ??
      0
  }

  if (!!messageTimestamp) {
    const messageDate = new Date(Number(messageTimestamp) / 10000)
    const today = new Date()
    diffDays = dateDiffInDays(messageDate, today)
    formattedDateTime = new Intl.DateTimeFormat(navigator.language, {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(messageDate)
    formattedTime = new Intl.DateTimeFormat(navigator.language, {
      timeStyle: 'short',
    }).format(messageDate)
  }

  if (isDeleted) {
    return <div id={messagePayload.id}></div>
  }

  return (
    <div ref={ref} className="w-full">
      <MessageItem
        key={messagePayload.id}
        id={messagePayload.id}
        userId={props.user?.id}
        message={text}
        timestamp={String(props.message?.timetoken)}
        time={diffDays > 1 ? formattedDateTime : formattedTime}
        name={messagePayload?.sender?.name ?? ''}
        avatar={messagePayload?.sender?.profileUrl ?? ''}
        onPinMessage={onPinMessage}
        onUnpinMessage={onUnpinMessage}
        onDeleteMessage={onDeleteMessage}
        onStarMessage={onStarMessage}
        onUnstarMessage={onUnstarMessage}
        isStarred={
          !!props.message?.actions?.starred?.data ||
          channelType === ChannelType.StarredMessages
        }
        isDeleted={isDeleted}
        isPinned={
          !!pinMessage && pinMessage?.timetoken === props.message?.timetoken
        }
      />
    </div>
  )
}

export default ChatMessageItem
