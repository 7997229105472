import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Formik, Form, FormikProps, FormikHelpers } from 'formik'
import { tr } from '@constants/other'
import AnswersListPreview from './AnswersListPreview'
import { ReactComponent as LinkIcon } from '@assets/link.svg'
import { VodVoteable } from '@interfaces/Vod'
import { ILanguage, LanguageCode } from '@interfaces/ILanguage'
import { schema, schemaWithResolveTime } from './schema'
import { usePostVodVoteableTimeshift } from '@services/requests/vod'
import { VodContext } from '@services/providers/VodProvider'
import LanguageTab from '@components/atoms/LanguageTab'
import { getLanguagesMetadata } from '@utils/language'
import Switch from '@components/atoms/Switch'
import { useToggle } from '@utils/hooks/useToggle'
import isNil from 'lodash/isNil'
import { hmsStringToSeconds, msToHHMMSS } from '@utils/time'
import TimeInput from '@components/molecules/TimeInput'
import { useToast } from '@utils/hooks/useToast'
import Button from '@components/atoms/Button'
import Col from '@components/atoms/Col'

interface ReplayBuffFormProps {
  buff: VodVoteable | null
  onUpdate?: () => void
}

interface ReplayBuffFormValues {
  openedAtSeconds?: string
  closedAtSeconds?: string
  resolvedAtSeconds?: string
}

const ReplayBuffForm: FC<React.PropsWithChildren<ReplayBuffFormProps>> = ({
  buff,
  onUpdate,
}) => {
  const [isLinkTimings, toggleIsLinkTimings] = useToggle(false)
  const [filteredLanguages, setFilteredLanguages] = useState<ILanguage[]>()
  const [activeLanguage, setActiveLanguage] = useState<LanguageCode>()

  const { addErrorToast } = useToast()
  const { currentVodId } = useContext(VodContext)
  const updateVoteableTimeshift = usePostVodVoteableTimeshift(currentVodId)

  const formRef = useRef<FormikProps<ReplayBuffFormValues> | null>(null)

  const DEFAULT_TIME = '000:00:00'

  const initialValues: ReplayBuffFormValues = useMemo(() => {
    return {
      openedAtSeconds: buff?.openedAtSeconds
        ? msToHHMMSS(buff.openedAtSeconds * 1000)
        : DEFAULT_TIME,
      closedAtSeconds: buff?.closedAtSeconds
        ? msToHHMMSS(buff.closedAtSeconds * 1000)
        : DEFAULT_TIME,
      resolvedAtSeconds: buff?.resolvedAtSeconds
        ? msToHHMMSS(buff.resolvedAtSeconds * 1000)
        : undefined,
    }
  }, [buff])

  // Gets the localisations on load
  useEffect(() => {
    if (!buff) return
    const buffLangs = getLanguagesMetadata(buff.localisations)
    setFilteredLanguages(buffLangs)
  }, [buff])

  // Auto selects the 1st localisation on load
  useEffect(() => {
    if (!filteredLanguages) return
    setActiveLanguage(filteredLanguages?.[0]?.iso6391 || [])
  }, [filteredLanguages])

  useEffect(() => {
    if (!initialValues) return
    if (!formRef?.current) return

    formRef.current?.setValues?.(initialValues)
  }, [initialValues])

  if (!buff) return <></>

  const getValidationSchema = () => {
    if (buff?.resolvedAtSeconds) return schemaWithResolveTime
    return schema
  }

  const handleSubmit = async (
    values: ReplayBuffFormValues,
    helpers: FormikHelpers<ReplayBuffFormValues>
  ) => {
    if (!updateVoteableTimeshift) return
    if (!buff?.id || !currentVodId) return
    if (isNil(values?.openedAtSeconds) || isNil(values?.closedAtSeconds)) return
    if (
      isNil(initialValues?.openedAtSeconds) ||
      isNil(initialValues?.closedAtSeconds)
    ) {
      return
    }

    try {
      await updateVoteableTimeshift.mutateAsync({
        voteableIds: [buff.id],
        opensAtDelta:
          hmsStringToSeconds(values.openedAtSeconds) -
          hmsStringToSeconds(initialValues.openedAtSeconds),
        closesAtDelta:
          hmsStringToSeconds(values.closedAtSeconds) -
          hmsStringToSeconds(initialValues.closedAtSeconds),
        resolvesAtDelta:
          initialValues?.resolvedAtSeconds && values?.resolvedAtSeconds
            ? hmsStringToSeconds(values.resolvedAtSeconds) -
              hmsStringToSeconds(initialValues.resolvedAtSeconds)
            : undefined,
      })
    } catch (error) {
      addErrorToast({
        ...error?.response?.data,
        statusCode: error?.response?.data?.code,
        id: tr({ id: 'vod.timingError' }),
      })
    } finally {
      helpers.setSubmitting(false)
      onUpdate?.()
    }
  }

  /**
   * Returned function handles updating the duration fields in the form based in a custom way rather than use formik's default
   *
   * @param {FormikProps} props Formik props
   * @return {Function} Function that handles duration updates
   */
  const handleCustomUpdate = ({
    setFieldValue,
    values,
  }: FormikProps<ReplayBuffFormValues>) => {
    return (openedAt: string) => {
      if (!values.openedAtSeconds) return

      const prevOpenedAtSeconds =
        hmsStringToSeconds(values.openedAtSeconds) || 0
      const deltaValue = hmsStringToSeconds(openedAt) - prevOpenedAtSeconds

      setFieldValue('openedAtSeconds', openedAt)

      if (!isLinkTimings) return

      if (!isNil(values?.closedAtSeconds)) {
        setFieldValue(
          'closedAtSeconds',
          msToHHMMSS(
            (deltaValue + hmsStringToSeconds(values.closedAtSeconds)) * 1000
          )
        )
      } else {
        setFieldValue('closedAtSeconds', DEFAULT_TIME)
      }

      if (!isNil(values?.resolvedAtSeconds)) {
        setFieldValue(
          'resolvedAtSeconds',
          msToHHMMSS(
            (deltaValue + hmsStringToSeconds(values.resolvedAtSeconds)) * 1000
          )
        )
      } else {
        setFieldValue('resolvedAtSeconds', DEFAULT_TIME)
      }
    }
  }

  if (!initialValues) return <></>

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={getValidationSchema()}
        validateOnBlur
        validateOnChange
        onSubmit={(
          values: ReplayBuffFormValues,
          helpers: FormikHelpers<ReplayBuffFormValues>
        ) => handleSubmit(values, helpers)}
      >
        {(props: FormikProps<ReplayBuffFormValues>) => {
          const {
            values,
            errors,
            setFieldValue,
            isSubmitting,
            handleBlur,
            dirty,
            isValid,
          } = props

          return (
            <Form>
              {/* Timings */}
              <div className="mt-3 mb-4 py-3 bg-white-background">
                {/* Time Inputs row */}
                <div className="flex">
                  <Col sm={3}>
                    {/* Opened At */}
                    <TimeInput
                      id="openedAtSeconds"
                      name="openedAtSeconds"
                      label={tr({ id: 'qaForm.newPublishTime' })}
                      className={
                        'w-full px-2 py-1 rounded border-1 border-lightgray'
                      }
                      value={values.openedAtSeconds}
                      onBlur={handleBlur}
                      onChange={handleCustomUpdate(props)}
                      onReset={() =>
                        setFieldValue('openedAtSeconds', DEFAULT_TIME)
                      }
                      error={Boolean(errors.openedAtSeconds)}
                      errorLabel={errors.openedAtSeconds}
                      touched
                    />
                  </Col>
                  <div className="mt-3">{isLinkTimings && <LinkIcon />}</div>
                  <Col sm={3}>
                    {/* Closed At */}
                    <TimeInput
                      id="closedAtSeconds"
                      name="closedAtSeconds"
                      label={tr({ id: 'qaForm.newExpiryTime' })}
                      className={
                        'w-full px-2 py-1 rounded border-1 border-lightgray'
                      }
                      value={values.closedAtSeconds}
                      onBlur={handleBlur}
                      onChange={(val) => setFieldValue('closedAtSeconds', val)}
                      onReset={() =>
                        setFieldValue('closedAtSeconds', DEFAULT_TIME)
                      }
                      error={Boolean(errors.closedAtSeconds)}
                      errorLabel={errors.closedAtSeconds}
                      touched
                      disabled={isLinkTimings}
                    />
                  </Col>
                  {Boolean(buff?.resolvedAtSeconds) && (
                    <>
                      <div className="mt-3">
                        {isLinkTimings && <LinkIcon />}
                      </div>
                      {/* Resolved At */}
                      <Col sm={3}>
                        <TimeInput
                          id="resolvedAtSeconds"
                          name="resolvedAtSeconds"
                          label={tr({ id: 'qaForm.newResolvedTime' })}
                          className={
                            'w-full px-2 py-1 rounded border-1 border-lightgray'
                          }
                          value={values.resolvedAtSeconds}
                          onBlur={handleBlur}
                          onChange={(val) =>
                            setFieldValue('resolvedAtSeconds', val)
                          }
                          onReset={() =>
                            setFieldValue('resolvedAtSeconds', DEFAULT_TIME)
                          }
                          error={Boolean(errors.resolvedAtSeconds)}
                          errorLabel={errors.resolvedAtSeconds}
                          touched
                          disabled={isLinkTimings}
                        />
                      </Col>
                    </>
                  )}
                </div>

                {/* Link & Submit row */}
                <div className="flex justify-between">
                  <Col>
                    <div className="flex items-center mt-1">
                      <Switch
                        textClassname="font-semibold"
                        textPosition="left"
                        name="linkTimings"
                        id="linkTimings"
                        text={tr({ id: 'vod.linkTimings' })}
                        isChecked={isLinkTimings}
                        onToggle={toggleIsLinkTimings}
                      />
                    </div>
                  </Col>
                  <Col className="flex justify-end mt-20 text-right">
                    <Button
                      variant="secondary"
                      size="small"
                      className="mr-2 w-[11rem]"
                      disabled={isSubmitting || !dirty}
                      type="reset"
                    >
                      {tr({ id: 'generic.reset' })}
                    </Button>
                    <Button
                      size="small"
                      className="w-[11rem]"
                      disabled={isSubmitting || !dirty || !isValid}
                      type="submit"
                    >
                      {tr({ id: 'qaForm.updateTimings' })}
                    </Button>
                  </Col>
                </div>
              </div>

              {/* Answers */}
              {filteredLanguages && (
                <div className="flex">
                  <ul className="inline-flex m-0 list-none p-0 space-x-0.5 overflow-scroll hide-scrollbar">
                    {filteredLanguages?.map((lang) => (
                      <LanguageTab
                        key={lang.id}
                        lang={lang}
                        onClick={setActiveLanguage}
                        isSelected={lang.iso6391 === activeLanguage}
                      />
                    ))}
                  </ul>
                </div>
              )}

              {/* Answers preview */}
              {buff?.answers && (
                <AnswersListPreview
                  activeLanguage={activeLanguage}
                  buff={buff}
                />
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default ReplayBuffForm
