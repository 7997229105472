import { AxiosRequestConfig } from 'axios'
import { LanguagesFormValues } from '@components/organisms/ClientLanguages'
import { PlatformFormValues } from '@components/organisms/PlatformConfig'
import { SignupConfigFormValues } from '@components/organisms/SignupConfig'
import { TVSettingsFormValues } from '@components/organisms/TvSettings'
import {
  Locales,
  Platforms,
  QRText,
  RawLocales,
  RawQRText,
  RawSignUpConfig,
  SignUpTexts,
  UIElements,
} from '@interfaces/ClientConfig'
import { RawTVClientConfig, TVClientConfig } from '@interfaces/ClientConfig'
import { LanguageCode } from '@interfaces/ILanguage'
import useClient, { Service } from '@utils/hooks/useClient'

export const useClientConfig = () => {
  const { client } = useClient(Service.CONFIG)

  const getLanguages = async () => {
    const data = await client.get<RawLocales>(`/locales`)

    const transformedData: Locales = {
      default: data.data.default,
      enabled: data.data.enabled,
    }

    return transformedData
  }

  const getTVConfig = async () => {
    const data = await client.get<RawTVClientConfig>(`/tv`)

    const { languages, ...texts } = data.data.qrCode.text

    const transformedTexts: Partial<QRText> = {}

    Object.keys(texts).forEach((key) => {
      transformedTexts[key] = texts[key]
    })

    // @ts-ignore
    const text: QRText = {
      languages: languages,
      ...transformedTexts,
    }

    const transformedData: TVClientConfig = {
      qrCode: {
        ...data.data.qrCode,
        text,
      },
    }

    return transformedData
  }

  const getPlatforms = async () => {
    const data = await client.get<Platforms>(`/platforms`)

    return data.data
  }

  const getSignUp = async () => {
    const data = await client.get<RawSignUpConfig>(`/signup`)

    const transformedLanguages = data.data.languages.map((lang) => lang)

    const transformedLocalisations: Partial<Record<LanguageCode, SignUpTexts>> =
      {}

    Object.keys(data.data.localisations).forEach((key) => {
      transformedLocalisations[key as LanguageCode] =
        data.data.localisations[key as keyof typeof LanguageCode]
    })

    const transformedData: SignupConfigFormValues = {
      ...data.data,
      languages: transformedLanguages,
      localisations: transformedLocalisations,
    }

    return transformedData
  }

  const getUiElements = async () => {
    const data = await client.get<UIElements>(`/sdk`)

    return data.data
  }

  const updateLanguages = (
    data: LanguagesFormValues
  ): Promise<AxiosRequestConfig<Locales>> => {
    const transformedData: RawLocales = {
      default: data.default,
      enabled: data.enabled
        .filter((lang) => lang.selected)
        .map((lang) => lang.iso6391),
    }

    return client.put(`/locales`, transformedData)
  }

  const updateTVConfig = (data: TVSettingsFormValues) => {
    const { languages, ...texts } = data.text

    const transformedLanguages = data.text.languages.map((lang) => lang)

    const transformedTexts: Partial<RawQRText> = {}

    Object.keys(texts).forEach((key) => {
      transformedTexts[key] = texts[key]
    })

    // @ts-ignore
    const text: RawQRText = {
      languages: transformedLanguages,
      ...transformedTexts,
    }

    const transformedData: RawTVClientConfig = {
      qrCode: {
        ...data,
        text,
      },
    }

    return client.put(`/tv`, transformedData)
  }

  const updatePlatforms = (data: PlatformFormValues) => {
    return client.put(`/platforms`, data)
  }

  const updateUiElements = (data: UIElements) => {
    return client.put(`/sdk`, data)
  }

  const updateSignUp = (data: SignupConfigFormValues) => {
    const transformedLanguages = data.languages.map((lang) => lang)

    const transformedLocalisations: Partial<Record<LanguageCode, SignUpTexts>> =
      {}

    Object.keys(data.localisations).forEach((key) => {
      transformedLocalisations[key as LanguageCode] =
        data.localisations[key as keyof typeof LanguageCode]
    })

    const transformedData: RawSignUpConfig = {
      ...data,
      languages: transformedLanguages,
      localisations: transformedLocalisations,
    }

    return client.put(`/signup`, transformedData)
  }

  return {
    getLanguages,
    getTVConfig,
    getPlatforms,
    getUiElements,
    getSignUp,
    updateLanguages,
    updateTVConfig,
    updatePlatforms,
    updateUiElements,
    updateSignUp,
  }
}
