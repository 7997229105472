import React, { FC, useContext, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useParams } from 'react-router-dom'
import { useVodClient } from '@services/requests/vodV2'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'
import Container from '@components/atoms/Container'
import { tr } from '@constants/other'
import Breadcrumb from '@components/atoms/Breadcrumb'
import BreadcrumbItem from '@components/atoms/Breadcrumb/BreadcrumbItem'
import Button from '@components/atoms/Button/Button'
import SectionLayout from '@components/layouts/SectionLayout'
import BuffPreviewWidget from '@components/molecules/BuffPreviewWidget'
import Tabs, { TabStyle } from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import TemplateSection from '@components/organisms/TemplateSection'
import {
  useAnnouncementTemplates,
  useVoteableTemplates,
} from '@services/requests/templates'
import { TemplateTypeTabs } from '@components/organisms/TemplateList/types'
import { TemplateContext } from '@services/providers/TemplateProvider'
import {
  AnnouncementTemplate,
  GetAnnouncementTemplatesResponse,
  GetVoteablesTemplatesResponse,
  Template,
} from '@interfaces/Template'
import { isTemplateVoteable } from '@utils/voteables'

const VodImport: FC<React.PropsWithChildren> = () => {
  const [selectedTemplates, setSelectedTemplates] = useState<
    (Template | AnnouncementTemplate)[]
  >([])
  const params = useParams<{ id: string; entityId?: string }>()
  const { pathname } = useLocation()

  const { id: vodId } = params

  const path = `/vod/${vodId}/import`

  const {
    setSelectedTemplate,
    setSelectedAnnouncementTemplate,
    resetSelectedTemplates,
  } = useContext(TemplateContext)

  const { useGetVod } = useVodClient()

  const { data: vod } = useGetVod(vodId, { enabled: !!vodId })

  const handleToggleTemplate = (template: Template | AnnouncementTemplate) => {
    const index = selectedTemplates?.findIndex((t) => t.id === template.id)
    if (index === -1) {
      setSelectedTemplates((value) => [...value, template])
    } else {
      setSelectedTemplates(
        selectedTemplates.filter((t) => t.id !== template.id)
      )
    }
  }

  const components = {
    buff: {
      title: tr({ id: 'template.buffTemplates' }),
      component: (
        <>
          <TemplateSection<GetVoteablesTemplatesResponse>
            key="buffs"
            searchAll={true}
            onClick={handleToggleTemplate}
            onDoubleClick={() => {}}
            query={useVoteableTemplates}
            templateType={TemplateTypeTabs.Buffs}
            selected={selectedTemplates}
            searchPlaceholder={tr({ id: 'generic.search' })}
          />
        </>
      ),
    },
    announcements: {
      title: tr({ id: 'template.announcementTemplates' }),
      component: (
        <>
          <TemplateSection<GetAnnouncementTemplatesResponse>
            key="announcements"
            searchAll
            onClick={handleToggleTemplate}
            onDoubleClick={() => {}}
            query={useAnnouncementTemplates}
            templateType={TemplateTypeTabs.Announcements}
            selected={selectedTemplates}
            searchPlaceholder={tr({ id: 'generic.search' })}
          />
        </>
      ),
    },
  }

  const tabs = Object.entries(components).map(([slug, { title }]) => {
    return {
      text: title,
      href: `${path}/${slug}`,
    }
  })

  const lastSlug = pathname?.includes('announcements')
    ? 'announcements'
    : 'buff'
  const tabsIndex = Object.keys(components).indexOf(lastSlug)

  useEffect(() => {
    resetSelectedTemplates()
    setSelectedTemplates([])
  }, [lastSlug, resetSelectedTemplates])

  useEffect(() => {
    const lastItem = selectedTemplates?.[selectedTemplates?.length - 1]
    if (lastItem) {
      if (isTemplateVoteable(lastItem)) {
        setSelectedTemplate(lastItem)
      } else {
        setSelectedAnnouncementTemplate(lastItem)
      }
    } else {
      resetSelectedTemplates()
    }
  }, [selectedTemplates])

  return (
    <Container>
      <Row className="pl-2 w-full">
        <Col sm={12}>
          <div className="flex h-6 items-center mt-3 mb-4 w-full">
            <Breadcrumb>
              <BreadcrumbItem href={`/vod/${vodId}/edit`}>
                {tr({ id: 'generic.vod' })} - {vod?.name}
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {tr({ id: 'vod.importBuffs' })}
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Col>
      </Row>

      <SectionLayout
        className="!w-full m-0"
        childrenClassName="!p-0 bg-transparent shadow-none"
        containerClassname="mb-10 w-full"
        title={`${tr({ id: 'vod.importBuffs' })} - ${vod?.name}`}
        backLink={`/vod/${vodId}/edit`}
      >
        <Row className="pl-2">
          <Routes>
            <Route
              path={`/*`}
              element={
                <Col sm={8}>
                  <div className="p-3 bg-white rounded min-h-[500px] shadow-card flex flex-col">
                    <div className="flex flex-col bg-[#f1f4f8] relative rounded-lg p-3 flex-1">
                      <Tabs
                        tabs={tabs}
                        selectedIndex={tabsIndex}
                        tabStyle={TabStyle.buttons}
                      >
                        {Object.entries(components).map(
                          ([slug, { component }]) => {
                            return <TabPanel key={slug}>{component}</TabPanel>
                          }
                        )}
                      </Tabs>
                    </div>
                  </div>
                </Col>
              }
            />
          </Routes>
          <Col sm={4}>
            <div className="flex flex-col gap-y-4">
              <BuffPreviewWidget showEmpty={false} />
              <Button
                disabled={!selectedTemplates?.length}
                variant="primary"
                onClick={() => {}}
              >
                {tr({
                  id:
                    selectedTemplates?.length > 1
                      ? 'vod.importBuffs'
                      : 'vod.importBuff',
                })}
              </Button>
            </div>
          </Col>
        </Row>
      </SectionLayout>
    </Container>
  )
}

export default VodImport
