import { useMutation } from 'react-query'

import { CreateAuthor, Author } from '@interfaces/Author'

import { updateAuthor } from '@services/requests/author'
import { queryClient } from '@utils/reactQuery/client'

/**
 *  @param {string} id
 *  @return {Author}
 */
export default function useUpdateAuthor(id: string) {
  return useMutation(
    (values: Partial<CreateAuthor>): Promise<Author> =>
      updateAuthor(id, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('authors')
      },
    }
  )
}
