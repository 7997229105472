import React, {
  FC,
  MouseEvent,
  ChangeEvent,
  useRef,
  MutableRefObject,
} from 'react'
import NumberFormat from 'react-number-format'
import TextInput from '@components/atoms/TextInput'
import { tr } from '@constants/other'
import {
  convertSecondsToTimeInputValue,
  convertTimeInputValueToSeconds,
  MAX_VALUE_S,
} from '@utils/time/input'
import Button from '@components/atoms/Button'
import { ITimeInput } from './types'

const TimeInput: FC<React.PropsWithChildren<ITimeInput>> = ({
  id,
  value = '000:00:00',
  onReset,
  onChange,
  showButtons = false,
  'data-testid': testId = 'time-input',
  ...rest
}) => {
  const timeRegex = /(\d+):(\d+):(\d+)/
  const result = timeRegex.exec(value)
  // eslint-disable-next-line
  const [_, hoursGroup, minutesGroup, secondsGroup] = result ?? []
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>

  const valueInSeconds = convertTimeInputValueToSeconds(value)

  const overMaxDisplayTime =
    secondsGroup && minutesGroup && parseInt(hoursGroup) > 999

  const fiveSecondButtonDisabled = valueInSeconds
    ? MAX_VALUE_S - 5 < valueInSeconds
    : false

  const tenSecondButtonDisabled = valueInSeconds
    ? MAX_VALUE_S - 10 < valueInSeconds
    : false
  const thirtySecondButtonDisabled = valueInSeconds
    ? MAX_VALUE_S - 30 < valueInSeconds
    : false

  const addTime = (e: MouseEvent<HTMLButtonElement>) => {
    const secondsToAddString = e?.currentTarget?.getAttribute('data-value')
    const secondsToAdd = secondsToAddString
      ? parseInt(secondsToAddString, 10)
      : 0

    const addTimeRegex = /\d{3}:\d{2}:\d{2}/
    const preparedValue = addTimeRegex.test(value) ? value : '000:00:00'

    const timeInSeconds = convertTimeInputValueToSeconds(preparedValue)
    const addedTime = (timeInSeconds || 0) + secondsToAdd

    const formattedTime = convertSecondsToTimeInputValue(addedTime)

    onChange && onChange(formattedTime)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.currentTarget.value)
  }

  const timeFormat = (numberValue: string) => {
    let timeString = numberValue

    const maxFirstDigit = 9
    const valueHasChanged = value.replace(/:/g, '') !== numberValue

    if (overMaxDisplayTime && !valueHasChanged) {
      return '---:--:--'
    }

    // Happens after the user enters a value when the dashes are shown
    if (
      overMaxDisplayTime &&
      valueHasChanged &&
      inputRef.current &&
      numberValue.length === 1
    ) {
      const { selectionStart } = inputRef.current

      const getIndexToReplace = (n: number) => {
        return n - Math.floor((n - 1) / 3 + 1)
      }
      const getDigits = (length: number) => {
        return Array(length).fill('0').join('')
      }

      // Find where to place the inputted number based on the users' cursor position
      const indexToReplace = getIndexToReplace(selectionStart ?? 0)

      const endLength = 6 - indexToReplace
      const startLength = indexToReplace

      timeString = getDigits(startLength) + numberValue + getDigits(endLength)
    }

    /**
     * Checks to see if the first digit is valid input. Anything over 5
     * should not be allowed when handling time
     *
     * @param {string}time
     * @return {boolean} If the first digit falls into allowed range
     */
    const checkFirstDigit = (time: string): boolean => {
      const firstDigit = parseInt(time[0], 10)
      return firstDigit <= maxFirstDigit
    }

    const limit = (value: number, limit: number) => {
      if (value > limit) return 0
      return value
    }

    /**
     * A function used to return the value of either hours, minutes or seconds from the string
     * If the max digit is above what is allowed it will mutate the time string and place as zero in front of it
     *
     * @param {number} start
     * @param {number} length
     * @param {boolean} skipFirstDigitCheck
     * @return {number}
     */
    const getTime = (
      start: number,
      length: number = 2,
      skipFirstDigitCheck: boolean = false
    ) => {
      const time = timeString.substr(start, length).padEnd(length, '0')
      if (skipFirstDigitCheck || checkFirstDigit(time)) {
        return parseInt(time, 10)
      } else {
        const stringStart = timeString.substr(0, start)
        const endString = timeString.substr(start)
        timeString = `${stringStart}0${endString}`
        const newTime = timeString.substr(start, length).padEnd(length, '0')
        return parseInt(newTime, 10)
      }
    }

    const hours = getTime(0, 3, true)
    const minutes = getTime(3)
    const seconds = getTime(5)

    let hasFilteredHoursOut = false

    const parts = [hours, minutes, seconds]
      .filter((num, i) => {
        const isNan = Number.isNaN(num)
        if (isNan && i === 0) {
          hasFilteredHoursOut = true
        }
        return !isNan
      })
      .map((num, i) => {
        // Hours aren't limited here
        if (i === 0 && !hasFilteredHoursOut) return num

        return limit(num, 59)
      })
      .map((num, i) => {
        const padLength = i === 0 && !hasFilteredHoursOut ? 3 : 2
        return num.toString().padStart(padLength, '0')
      })

    return parts.join(':')
  }

  const textInputProps: any = {
    id,
    'data-testid': `${testId}__input`,
    ...rest,
  }

  const buttonClasses = 'mr-2 time-input !w-[36px] !h-[36px]'

  return (
    <div className="flex flex-1 w-full">
      <div className="w-full">
        <NumberFormat
          value={value}
          customInput={TextInput}
          onChange={handleChange}
          format={timeFormat}
          inputRef={inputRef}
          type="tel"
          mask={['H', 'H', 'H', 'M', 'M', 'S', 'S']}
          {...textInputProps}
        />
      </div>

      {showButtons && (
        <div className="flex flex-col">
          <div className="flex justify-end">
            <Button
              className="lowercase"
              buttonType="link"
              size="mini"
              variant="primary"
              onClick={onReset}
            >
              {tr({ id: 'generic.reset' })}
            </Button>
          </div>
          <div className="flex ml-2">
            <Button
              size="small"
              variant="secondary"
              className={buttonClasses}
              data-value={1}
              onClick={addTime}
              disabled={fiveSecondButtonDisabled}
            >
              +1
            </Button>
            <Button
              size="small"
              variant="secondary"
              className={buttonClasses}
              data-value={5}
              onClick={addTime}
              disabled={fiveSecondButtonDisabled}
            >
              +5
            </Button>
            <Button
              size="small"
              variant="secondary"
              className={buttonClasses}
              data-value={10}
              onClick={addTime}
              disabled={tenSecondButtonDisabled}
            >
              +10
            </Button>
            <Button
              size="small"
              variant="secondary"
              className={buttonClasses}
              data-value={30}
              onClick={addTime}
              disabled={thirtySecondButtonDisabled}
            >
              +30
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default TimeInput
