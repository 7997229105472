import React, { FC, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { useMutation, useQuery } from 'react-query'
import { noop } from 'lodash'
import { tr } from '@constants/other'
import Button from '@components/atoms/Button'
import Modal, { ModalSize } from '@components/molecules/Modal'
import AutoPatchTouched from '@components/atoms/AutoPatchTouched'
import { IOptions } from '@components/atoms/SelectInput/SelectInput'
import { useClientConfig } from '@services/requests/client-config'
import { useLive } from '@services/requests/live'
import { Platform } from '@interfaces/IStreamConfig'
import {
  MonetisationCurrency,
  PaymentOptionPost,
} from '@interfaces/Leaderboard'
import { queryClient } from '@utils/reactQuery/client'
import { useToast } from '@utils/hooks/useToast'
import { formatInTimeZone } from '@utils/date'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import LeaderboardForm from './Form'
import schema from './schema'
import { LeaderboardFormValues, VipLeaderboardModalProps } from './types'

const initialValues: LeaderboardFormValues = {
  currency: MonetisationCurrency.BITS,
  cost: 0,
  bonusPoints: 0,
  expiryDate: '',
  expiryTime: '',
}

const VipLeaderboardModal: FC<
  React.PropsWithChildren<VipLeaderboardModalProps>
> = (props) => {
  const {
    show,
    onClose,
    leaderboardId,
    streamId,
    'data-testid': testId = 'vip-leaderboard-modal',
  } = props

  const { addToast, addErrorToast } = useToast()
  const { getPlatforms } = useClientConfig()
  const { addPaymentOptionToLeaderboard } = useLive()

  const { data: platforms } = useQuery('platforms', getPlatforms)

  const { mutate: mutateAddPaymentItem, isLoading: isCreating } = useMutation(
    (paymentItem: PaymentOptionPost) =>
      addPaymentOptionToLeaderboard(leaderboardId, streamId, paymentItem),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['leaderboard', leaderboardId])
        addToast({
          msg: tr({ id: 'leaderboard.addedPaymentItem' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
        onClose()
      },
      onError: (err) => {
        addErrorToast(err)
      },
    }
  )

  const handleSubmit = (values: LeaderboardFormValues) => {
    const { currency, cost, bonusPoints, expiryDate, expiryTime } = values
    const date = new Date(`${expiryDate} ${expiryTime}`)

    const dateFormat = formatInTimeZone(date.toISOString(), 'yyyy-MM-dd', 'UTC')
    const timeFormat = formatInTimeZone(date.toISOString(), 'HH:mm:ss', 'UTC')
    const formattedDate = `${dateFormat}T${timeFormat}Z`
    const payload: PaymentOptionPost = {
      currency,
      cost,
      bonusPoints,
      availableUntil: formattedDate,
    }
    mutateAddPaymentItem(payload)
  }

  const availablePlatforms = platforms?.platforms

  const monetisationOptions: IOptions[] = useMemo(() => {
    const options: IOptions[] = [
      {
        key: '',
        value: tr({ id: 'leaderboard.selectCurrency' }),
        hidden: true,
      },
    ]

    // TODO: Bring back when USD payment is available
    // const platformsWithoutTwitch = availablePlatforms?.filter(
    //   (platform) => platform !== Platform.TWITCH
    // )

    // if (!!platformsWithoutTwitch?.length) {
    //   options.push({
    //     key: MonetisationCurrency.USD,
    //     value: tr({
    //       id: `leaderboard.monetisationOptions.${MonetisationCurrency.USD}`,
    //     }),
    //   })
    // }

    if (availablePlatforms?.includes(Platform.TWITCH)) {
      options.push({
        key: MonetisationCurrency.BITS,
        value: tr({
          id: `leaderboard.monetisationOptions.${MonetisationCurrency.BITS}`,
        }),
      })
    }

    return options
  }, [availablePlatforms])

  return (
    <Modal
      size={ModalSize.SMALL}
      isOpen={show}
      data-testid={testId}
      title={tr({ id: 'leaderboard.addPaymentItem' })}
      displayCloseButton={false}
      onClose={noop}
    >
      <Modal.Body className="mt-2 overflow-x-hidden !max-h-[calc(100vh-220px)]">
        <Formik
          initialValues={initialValues}
          validateOnChange
          validateOnBlur
          validateOnMount
          validationSchema={schema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {(props) => {
            const { isValid } = props

            return (
              <Form className="flex flex-col gap-y-6">
                <LeaderboardForm monetisationOptions={monetisationOptions} />
                <div className="flex gap-4 items-center justify-end">
                  <Button
                    variant="secondary"
                    data-testid={`${testId}__cancel`}
                    onClick={onClose}
                    disabled={isCreating}
                  >
                    {tr({ id: 'generic.cancel' })}
                  </Button>
                  <Button
                    disabled={!isValid || isCreating}
                    variant="primary"
                    data-testid={`${testId}__action`}
                    type="submit"
                  >
                    {tr({ id: 'leaderboard.addPaymentItem' })}
                  </Button>
                </div>
                <AutoPatchTouched />
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default VipLeaderboardModal
