export enum BuffPageActionMode {
  Create = 'CREATE',
  Update = 'UPDATE',
  Load = 'LOAD',
}
export enum BuffPageType {
  WelcomeBuff = 'WELCOME_BUFF',
  Template = 'TEMPLATE',
  Voteable = 'VOTEABLE',
}
