import { useMutation } from 'react-query'

import { CreateStream, IStream } from '@interfaces/IStream'

import { createStream } from '@services/requests/stream'
import { queryClient } from '@utils/reactQuery/client'

/**
 *
 * @return {IStream}
 */
export default function useCreateStream() {
  return useMutation(
    (values: CreateStream): Promise<IStream> => createStream(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('streams')
        queryClient.invalidateQueries(['streams-infinite'])
      },
    }
  )
}
