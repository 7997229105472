import { Author } from '@interfaces/Author'
import { LanguageCode } from '@interfaces/ILanguage'
import { AnnouncementTemplate } from '@interfaces/Template'
import { AnnouncementFormValues } from '@interfaces/Announcement'
import {
  LocalisedAuthorFormValue,
  LocalisedFormValue,
  LocalisedSponsorContent,
  LocalisedSponsorFormValue,
} from '@interfaces/localisation'
import getLocalisations from '@utils/getLocalisation'

const getAnnouncementTemplateInitialValues = (
  template: AnnouncementTemplate,
  authors: Author[],
  languages: LanguageCode[]
): AnnouncementFormValues => {
  const titleText: LocalisedFormValue = {}
  const text: LocalisedFormValue = {}
  const imageGroupId: LocalisedFormValue = {}
  const imageUrl: LocalisedFormValue = {}
  const buttonText: LocalisedFormValue = {}
  const linkUrl: LocalisedFormValue = {}
  const author: LocalisedAuthorFormValue = {}
  const sponsor: LocalisedSponsorFormValue = {}
  const showSponsor: Partial<Record<LanguageCode, boolean>> = {}

  languages.forEach((lang) => {
    const values = getLocalisations({
      localisations: template.content.localisations,
      languages: [lang],
    })

    titleText[lang] = values?.titleText ?? ''
    text[lang] = values?.text ?? ''
    imageUrl[lang] = values?.imageUrl ?? values?.imageURL ?? ''
    buttonText[lang] = values?.buttonText ?? ''
    linkUrl[lang] = values?.linkUrl ?? ''
    sponsor[lang] = {
      text: '',
      linkTarget: '',
      imageUrl: '',
      imageAltText: '',
      localisation: lang,
    }
  })

  template?.author?.localisations.forEach((localisedAuthor) => {
    author[localisedAuthor.localisation] = {
      text: localisedAuthor.text,
      imageUrl: localisedAuthor.imageUrl || localisedAuthor.imageURL || '',
    }
  })

  template?.sponsor?.localisations?.forEach(
    (localisedSponsor: LocalisedSponsorContent) => {
      const { linkTarget } = localisedSponsor
      const imageUrl = localisedSponsor.imageUrl || localisedSponsor.imageURL
      const hasSponsor = Boolean(imageUrl) || Boolean(linkTarget)
      sponsor[localisedSponsor.localisation] = {
        imageUrl,
        linkTarget,
        imageAltText: localisedSponsor.imageAltText,
        localisation: localisedSponsor.localisation,
        text: localisedSponsor.text,
      }
      showSponsor[localisedSponsor.localisation] = hasSponsor
    }
  )

  const DEFAULT_DURATION = 15

  return {
    author,
    localisations: template?.localisations,
    duration: template?.displayDurationSeconds || DEFAULT_DURATION,
    titleText,
    text,
    imageGroupId,
    imageUrl,
    buttonText,
    linkUrl,
    sponsor,
    showSponsor,
  }
}

export default getAnnouncementTemplateInitialValues
