import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import LanguageTab from '@components/atoms/LanguageTab'
import { ReactComponent as AddSVG } from '@assets/add.svg'
import { tr } from '@constants/other'
import { LanguageCode } from '@interfaces/ILanguage'
import { LanguagesMap } from '@utils/language'
import { LanguageTabContentProps } from './types'
import './LanguageTabContent.css'

const LanguageTabContent = ({
  errors,
  children,
  availableLanguages,
  selectedLanguages,
  activeLanguage,
  onLanguageChange,
  onSelectedLanguagesChange,
  className = '',
  'data-testid': testId = 'language-tab-content',
}: LanguageTabContentProps) => {
  const activeLanguages = selectedLanguages
    .filter((lang) => !!lang)
    .map((langCode) => {
      return LanguagesMap[langCode]
    })

  // TODO: We can remove !!lang check when we will have real vods api
  const unselectedLanguages = availableLanguages.filter((lang) => {
    return !!lang && !selectedLanguages.includes(lang)
  })

  const handleDeleteLanguage = (langCode: LanguageCode) => {
    if (activeLanguages.length === 1) return undefined
    return () => {
      const langs = [...selectedLanguages]
      langs.splice(langs.indexOf(langCode), 1)
      onSelectedLanguagesChange && onSelectedLanguagesChange(langs)

      if (activeLanguage === langCode) {
        onLanguageChange && onLanguageChange(langs[0])
      }
    }
  }

  return (
    <div className={className} data-testid={testId}>
      <div className="flex">
        <ul
          className="inline-flex m-0 list-none p-0 space-x-0.5 overflow-scroll hide-scrollbar"
          data-testid={`${testId}__list`}
        >
          {activeLanguages.map((lang) => (
            <LanguageTab
              key={lang.id}
              lang={lang}
              hasError={errors?.[lang.iso6391] === true}
              onClick={() => onLanguageChange && onLanguageChange(lang.iso6391)}
              isSelected={lang.iso6391 === activeLanguage}
              onDeleteClick={handleDeleteLanguage(lang.iso6391)}
              data-testid={`language-tab--${lang.iso6391}`}
            />
          ))}
        </ul>
        {unselectedLanguages.length !== 0 && (
          <Menu as="div" className="relative">
            <Menu.Button
              className="bg-[#e6e9f4] rounded-t-lg border-none inline-flex items-center h-full px-1"
              data-testid={`${testId}__add-lang-menu`}
            >
              <div className="language__tab--triangle mt-1 mr-1" />
              <AddSVG />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute -left-2 w-40 origin-top-right bg-white rounded-sm shadow p-3 space-y-1 z-10">
                {unselectedLanguages?.map((lang) => (
                  <Menu.Item
                    key={LanguagesMap[lang].id}
                    as="button"
                    type="button"
                    data-testid={`${testId}__add-lang-${lang}`}
                    onClick={() => {
                      const langs = [...selectedLanguages, lang]
                      onSelectedLanguagesChange &&
                        onSelectedLanguagesChange(langs)
                    }}
                    className={`bg-transparent border-none flex p-0 hover:font-semibold`}
                  >
                    {LanguagesMap[lang].name}
                  </Menu.Item>
                ))}

                <hr className="mt-2" />
                <Menu.Item
                  as="button"
                  type="button"
                  className="bg-transparent flex p-0 border-none text-blue-600"
                  onClick={() => {
                    onSelectedLanguagesChange &&
                      onSelectedLanguagesChange(availableLanguages)
                  }}
                >
                  {tr({ id: 'qaForm.addAllLanguages' })}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
      <div
        style={{ backgroundColor: 'var(--white-background)' }}
        className="p-6 mb-3"
      >
        {children}
      </div>
    </div>
  )
}

export default LanguageTabContent
