import { BuffType } from '@constants/other'
import {
  LocalisedFormValue,
  LocalisedSponsorFormValue,
  LocalisedAuthorFormValue,
} from '@interfaces/localisation'
import { LanguageCode } from '@interfaces/ILanguage'
import createDefaultAnswer from '@utils/createDefaultAnswer'
import {
  DEFAULT_EXPIRY_TIME,
  DEFAULT_EXPIRY_TIME_TIME_SYNC,
} from '@constants/form'
import { VoteableLayout, CreateVoteableForm } from '@interfaces/Voteable'

const DEFAULT_DURATION = 15
const DEFAULT_SCHEDULE_SEND = '000:00:00'

interface GetInitialValuesArgs {
  languages: LanguageCode[]
  isTimeSyncStream?: boolean
  isWelcomeBuff?: boolean
  answerCount?: number
  buffType?: BuffType
}

export const getBlankInitialValues = ({
  languages,
  isTimeSyncStream = false,
  isWelcomeBuff = false,
  answerCount = 2,
  buffType = BuffType.Prediction,
}: GetInitialValuesArgs) => {
  const question: LocalisedFormValue = {}
  const author: LocalisedAuthorFormValue = {}
  const sponsor: LocalisedSponsorFormValue = {}
  const showSponsor: Partial<Record<LanguageCode, boolean>> = {}

  const authorTemplate = {
    key: '',
    value: '',
  }

  const defaultExpiry = isTimeSyncStream
    ? DEFAULT_EXPIRY_TIME_TIME_SYNC
    : DEFAULT_EXPIRY_TIME

  const answers = new Array(answerCount).fill(null).map((_, index) => {
    return createDefaultAnswer(languages, index)
  })

  languages?.forEach((lang) => {
    question[lang] = ''
    author[lang] = {
      text: '',
      imageUrl: '',
    }

    sponsor[lang] = {
      text: '',
      linkTarget: '',
      imageUrl: '',
      imageAltText: '',
      localisation: lang,
    }
    showSponsor[lang] = false
  })

  const displayTimeKey = isWelcomeBuff
    ? 'displayDurationSeconds'
    : 'voteDurationSeconds'

  const type = isWelcomeBuff ? BuffType.Poll : buffType

  const initialValues: CreateVoteableForm = {
    author,
    answers,
    sponsor,
    question,
    showSponsor,
    authorTemplate,
    type,
    expiresIn: defaultExpiry,
    schedule: DEFAULT_SCHEDULE_SEND,
    layout: VoteableLayout.LAYOUT_HORIZONTAL,
    enableTwitchBits: false,
    localisations: languages,
    [displayTimeKey]: DEFAULT_DURATION,
    resultsDurationSeconds: 8,
    ...(isWelcomeBuff && { participationPoints: 0 }),
  }

  return initialValues
}
