import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export const useGenericMutation = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined
) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, T | S>(func, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([url, params])

      const previousData = queryClient.getQueryData([url, params])

      queryClient.setQueryData<T>([url, params], (oldData) => {
        return updater ? updater(oldData as T, data as S) : (data as T)
      })

      return previousData
    },
    onError: (err, _, context) => {
      queryClient.setQueryData([url, params], context)
    },
    onSettled: () => {
      queryClient.invalidateQueries([url, params])
    },
  })
}
