import React from 'react'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { ReactComponent as PadlockLocked } from '@assets/padlock-locked.svg'
import { ReactComponent as PadlockUnlocked } from '@assets/padlock-unlocked.svg'

import { UserModerationLockProps } from './types'

const UserModerationLock = ({
  onClick,
  locked = false,
  'data-testid': testId,
}: UserModerationLockProps) => {
  return (
    <Tooltip placement="bottom">
      <TooltipTrigger>
        <button
          type="button"
          className="border-none p-2 leading-none rounded bg-transparent hover:!bg-gray transition-all"
          data-testid={testId}
          onClick={() => onClick && onClick(!locked)}
        >
          {locked ? <PadlockLocked /> : <PadlockUnlocked />}
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <span>
          {locked
            ? 'Remove moderation and unlock user'
            : 'Moderate name and lock user'}
        </span>
      </TooltipContent>
    </Tooltip>
  )
}

export default UserModerationLock
