import * as log from 'loglevel'
import IEnvironment from '../interfaces/IEnvironment'

const logger = log.getLogger('LOGGER')
logger.setDefaultLevel(
  (process.env as Partial<IEnvironment>).REACT_APP_ENV === 'production'
    ? log.levels.SILENT
    : log.levels.DEBUG
)

export default logger
