import {
  DEFAULT_PAG_LIMIT,
  DEFAULT_PAG_OFFSET,
  vPaginationSchema,
  vResourceIdSchema,
} from '../constants/form'
import { ApiResponse, HttpMethod } from '../constants/other'
import IResponse from '../interfaces/IResponse'
import IService from '../interfaces/IService'
import ILeaderboard, {
  Leaderboard,
  Standings,
} from '../interfaces/ILeaderboard'
import ApiService from './ApiService'

export interface IStreamLeaderboardParams {
  id?: string
  offset?: number
  limit?: number
}

export interface UpdateLeaderboardParams {
  leaderboardId: string
  payload: unknown
}

export interface LeaderboardResponse {
  leaderboard: Leaderboard
}

interface StandingsResponse {
  standings: Standings
  pagination: {
    nextPageToken: string
  }
}

export interface ILeaderboardService extends IService<ILeaderboard> {
  getLeaderboard: ({}: IStreamLeaderboardParams) => Promise<
    ApiResponse<LeaderboardResponse>
  >
  getStandings: ({}: IStreamLeaderboardParams) => Promise<
    ApiResponse<StandingsResponse>
  >
  getClientLeaderboard: ({}: IStreamLeaderboardParams) => Promise<
    ApiResponse<IResponse<ILeaderboard>>
  >
  downloadLeaderboard: (
    streamId?: number,
    limit?: number
  ) => Promise<ApiResponse<Blob>>

  resetStreamLeaderboard: (streamId: number) => Promise<ApiResponse<any>>
  resetGlobalLeaderboard: () => Promise<ApiResponse<any>>
  updateStreamLeaderboard: ({}: UpdateLeaderboardParams) => Promise<
    ApiResponse<any>
  >
}

/**
 * Leaderboard service
 */
export default class LeaderboardService
  extends ApiService<ILeaderboard>
  implements ILeaderboardService, IService<ILeaderboard>
{
  /**
   * Gets the leaderboard
   * @param {IStreamLeaderboardParams} p
   * @return {Promise<ApiResponse<LeaderboardResponse>>}
   */
  async getLeaderboard({
    id,
  }: IStreamLeaderboardParams): Promise<ApiResponse<LeaderboardResponse>> {
    this.checkToRefreshToken(this.getToken())
    return await this.instance.request({
      method: HttpMethod.Get,
      url: `${this.resource}/${id}`,
    })
  }

  /**
   * Gets the standings
   * @param {IStreamLeaderboardParams} p
   * @return {Promise<ApiResponse<StandingsResponse>>}
   */
  async getStandings({
    id,
  }: IStreamLeaderboardParams): Promise<ApiResponse<StandingsResponse>> {
    this.checkToRefreshToken(this.getToken())
    return await this.instance.request({
      method: HttpMethod.Get,
      url: `${this.resource}/${id}/standings`,
    })
  }

  /**
   * Gets the leaderboard of the client
   * @param {IStreamLeaderboardParams} p
   * @return {Promise<ApiResponse<IResponse<ILeaderboard>>>}
   */
  async getClientLeaderboard({
    limit = DEFAULT_PAG_LIMIT,
    offset = DEFAULT_PAG_OFFSET,
  }: IStreamLeaderboardParams): Promise<ApiResponse<IResponse<ILeaderboard>>> {
    await vPaginationSchema.validateAsync({ limit, offset })
    this.checkToRefreshToken(this.getToken())

    return await this.instance.request({
      method: HttpMethod.Get,
      url: `${this.resource}?page=${offset}&size=${limit}`,
    })
  }

  /**
   * Updates the stream leaderboard
   * @param {IStreamLeaderboardParams} p
   * @return {Promise<ApiResponse<IResponse<ILeaderboard>>>}
   */
  async updateStreamLeaderboard({
    leaderboardId,
    payload,
  }: UpdateLeaderboardParams): Promise<ApiResponse<IResponse<any>>> {
    // await vPaginationSchema.validateAsync({ limit, offset })
    this.checkToRefreshToken(this.getToken())

    return await this.instance.request({
      method: HttpMethod.Patch,
      url: `${this.resource}/${leaderboardId}`,
      data: payload,
    })
  }

  /**
   * Downloads the leaderboard of the given stream
   * @param {number} streamId
   * @param {number} size
   * @return {Promise<ApiResponse<Blob>>}
   */
  async downloadLeaderboard(
    streamId?: number,
    size?: number
  ): Promise<ApiResponse<Blob>> {
    this.checkToRefreshToken(this.getToken())

    let url = `${this.resource}/download?`

    if (streamId) {
      url += `stream_id=${streamId}&`
    }

    if (size) {
      url += `size=${size}`
    }

    return await this.instance.request({
      method: HttpMethod.Get,
      url: url,
      responseType: 'blob',
      transformResponse: [],
    })
  }

  /**
   * Resets a stream leaderboard
   * @param {number} streamId Id of the leaderboard
   * @return {Promise<ApiResponse>}
   */
  async resetStreamLeaderboard(streamId: number): Promise<ApiResponse<any>> {
    await vResourceIdSchema.validateAsync(streamId)
    this.checkToRefreshToken(this.getToken())

    const url = `/${this.resource}/${streamId}`
    return this.instance.delete(url)
  }

  /**
   * Resets a global leaderboard
   * @return {Promise<ApiResponse>}
   */
  async resetGlobalLeaderboard(): Promise<ApiResponse<any>> {
    this.checkToRefreshToken(this.getToken())

    const url = `/${this.resource}/global`
    return this.instance.delete(url)
  }
}
