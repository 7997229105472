import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tab } from '@headlessui/react'
import { BUFFUP_DASHBOARD_TOKEN } from '@constants/other'
import TopNav from '@components/layouts/TopNav'
import { AUTHOR_LOCAL_STORAGE_KEY } from '@constants/form'
import ChooseAuth from '@components/organisms/ChooseAuth'
import EmailLogin from '@components/organisms/EmailLogin'
import Passcode from '@components/organisms/Passcode'

const SignInPage: FC = () => {
  const navigate = useNavigate()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [email, setEmail] = useState('')

  const handleEmailLogin = () => {
    setSelectedIndex(1)
  }

  const handleGoBack = () => {
    setSelectedIndex((preState) => {
      return preState - 1
    })
  }

  const handleOTPSuccess = (email: string) => {
    setEmail(email)
    setSelectedIndex(2)
  }

  const handleLoginSuccess = (token: string) => {
    localStorage.setItem(BUFFUP_DASHBOARD_TOKEN, token)
    localStorage.setItem('lastLogin', Date.now().toString())
    navigate('/')
  }

  useEffect(() => {
    localStorage.removeItem(AUTHOR_LOCAL_STORAGE_KEY)
  }, [])

  return (
    <div className="p-0 mx-auto w-full h-[100vh]">
      <TopNav hideSettings />
      <div className="flex justify-center items-center h-75">
        <div className="flex flex-col p-4 rounded w-[350px] bg-white-secondary">
          <Tab.Group selectedIndex={selectedIndex}>
            <Tab.List className="hidden">
              <Tab key={1}></Tab>
              <Tab key={2}></Tab>
              <Tab key={3}></Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel key={1}>
                <ChooseAuth
                  onContinue={handleEmailLogin}
                  onOIDCSuccess={handleLoginSuccess}
                />
              </Tab.Panel>
              <Tab.Panel key={2}>
                <EmailLogin
                  onSuccess={handleOTPSuccess}
                  onGoBack={handleGoBack}
                />
              </Tab.Panel>
              <Tab.Panel key={3}>
                <Passcode
                  email={email}
                  onSuccess={handleLoginSuccess}
                  onGoBack={handleGoBack}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  )
}

export default SignInPage
