import * as Yup from 'yup'

export const Schema = Yup.object().shape({
  platforms: Yup.array()
    .min(1)
    .test('one-selected-platform', '', (value) => {
      return (value as string[])?.some((lang) => !!lang)
    })
    .required(),
})
