import React from 'react'
import { Link } from 'react-router-dom'
import { tr } from '@constants/other'

interface IProps {
  highlight: string
  title: string
  subtitle: string
  href: string
  isDisabled: boolean

  /**
   * If the step has already been completed
   *
   * @default false
   */
  isComplete?: boolean
}

const LinkCard: React.FC<React.PropsWithChildren<IProps>> = ({
  highlight,
  title,
  subtitle,
  href,
  isDisabled,
  isComplete = false,
}): JSX.Element => {
  const textBlack = {
    color: 'black',
  }

  const linkcardWrapper = {
    boxShadow: '0 5px 15px 0 rgba(9, 14, 37, 0.1)',
  }

  const getIcon = () => {
    if (isComplete) {
      return {
        src: '/assets/svg/icon-check.svg',
        alt: '',
        'data-testid': 'link-card__icon-check',
      }
    }

    if (isDisabled) {
      return {
        src: '/assets/svg/icon-lock.svg',
        alt: '',
        'data-testid': 'link-card__icon-lock',
      }
    }

    return {
      src: '/assets/svg/icon-back.svg',
      alt: tr({ id: 'generic.goToIcon' }),
      'data-testid': 'link-card__icon-back',
    }
  }

  const iconProps = getIcon()

  const displayCard = (
    <div
      data-testid="wrapper"
      className="w-full px-4 py-2 rounded bg-white flex items-center mb-2"
      style={linkcardWrapper}
    >
      <h1
        data-testid="highlight"
        className="h1 font-bold mb-0 mr-3"
        style={{ ...textBlack, fontSize: '50px' }}
      >
        {highlight}
      </h1>
      <div className="flex flex-col self-center mr-auto">
        <p data-testid="title" className="font-bold mb-1" style={textBlack}>
          {title}
        </p>
        <p data-testid="subtitle" style={textBlack} className="mb-0">
          {subtitle}
        </p>
      </div>
      <div>
        {/* eslint-disable-next-line */}
        <img {...iconProps} />
      </div>
    </div>
  )

  return isDisabled ? (
    displayCard
  ) : (
    <Link
      to={href}
      style={{ textDecoration: 'none' }}
      className="flex w-full"
      data-testid="link-card__link"
    >
      {displayCard}
    </Link>
  )
}

export default LinkCard
