import React, { useState, useEffect, useCallback } from 'react'
import { tr } from '@constants/other'
import { getStreams as fetchStreams } from '@services/requests/stream'
import { IStream } from '@interfaces/IStream'
import { useToast } from '@utils/hooks/useToast'
import LinkCard from '@components/molecules/LinkCard'
import { getVoteablesByGameId } from '@services/requests/voteable'
import { getAuthors } from '@services/requests/author'
import Container from '@components/atoms/Container'
import Row from '@components/atoms/Row'

const GettingStartedSection: React.FC<
  React.PropsWithChildren<unknown>
> = (): JSX.Element => {
  const [isAuthorsEmpty, setIsAuthorsEmpty] = useState(true)
  const [firstStream, setFirstStream] = useState<IStream>()
  const [isBuffsEmpty, setIsBuffsEmpty] = useState(true)
  const { addErrorToast } = useToast()

  const isStreamsEmpty = firstStream === undefined

  const getAuthorsData = useCallback(async () => {
    try {
      const { authors } = await getAuthors(1, '')
      setIsAuthorsEmpty(!authors.length)
    } catch (error) {
      addErrorToast(error)
    }
  }, [addErrorToast])

  const getStreams = useCallback(async () => {
    try {
      const { streams } = await fetchStreams(1)
      const firstStream = streams?.length ? streams[0] : undefined
      setFirstStream(firstStream)
      return firstStream
    } catch (error) {
      addErrorToast(error)
    }
  }, [addErrorToast])

  const getBuffs = useCallback(
    async (gameId: string) => {
      try {
        const response = await getVoteablesByGameId({
          gameId,
        })
        const boolean = !Boolean(response?.voteables?.length)
        setIsBuffsEmpty(boolean)
      } catch (error) {
        addErrorToast(error)
      }
    },
    [addErrorToast]
  )

  const runGettingStartedChecks = useCallback(async () => {
    getAuthorsData()
    const stream = await getStreams()
    if (stream) getBuffs(stream.gameIds[0])
  }, [getAuthorsData, getStreams, getBuffs])

  const buffCardLink = firstStream ? `/streams/${firstStream.id}/play` : ''

  useEffect(() => {
    runGettingStartedChecks()
  }, [runGettingStartedChecks])

  return (
    <Container size="md">
      <Row>
        <LinkCard
          highlight="1"
          title={tr({ id: 'gettingStarted.authorTitle' })}
          subtitle={tr({ id: 'gettingStarted.authorSubtitle' })}
          href="/authors"
          isDisabled={!isAuthorsEmpty}
          isComplete={!isAuthorsEmpty}
        />
        <LinkCard
          highlight="2"
          title={tr({ id: 'gettingStarted.streamTitle' })}
          subtitle={tr({ id: 'gettingStarted.streamSubtitle' })}
          href="/streams"
          isDisabled={!(!isAuthorsEmpty && isStreamsEmpty)}
          isComplete={!isStreamsEmpty}
        />
        <LinkCard
          highlight="3"
          title={tr({ id: 'gettingStarted.buffTitle' })}
          subtitle={tr({ id: 'gettingStarted.buffSubtitle' })}
          href={buffCardLink}
          isDisabled={!(!isAuthorsEmpty && !isStreamsEmpty && isBuffsEmpty)}
          isComplete={!isBuffsEmpty}
        />
      </Row>
    </Container>
  )
}

export default GettingStartedSection
