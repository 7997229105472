import { BuffStatus } from '@interfaces/buff'
import { VodVoteable } from '@interfaces/Vod'
import { Voteable } from '@interfaces/Voteable'

/**
 * @param {Voteable} vodVoteable
 * @param {number} time
 * @return {BuffStatus}
 */
export const mapVodVoteableStatus = (
  vodVoteable: VodVoteable,
  time?: number
) => {
  if (!time) return BuffStatus.Published
  const closesAt =
    !!vodVoteable?.closedAtSeconds &&
    new Date(vodVoteable?.closedAtSeconds).getTime()
  const resolvesAt =
    !!vodVoteable?.resolvedAtSeconds &&
    new Date(vodVoteable?.resolvedAtSeconds).getTime()
  const opensAt =
    !!vodVoteable?.openedAtSeconds &&
    new Date(vodVoteable?.openedAtSeconds).getTime()

  if (resolvesAt && time >= resolvesAt) {
    return BuffStatus.Resolved
  } else if (closesAt && time >= closesAt) {
    return BuffStatus.Expired
  } else if (opensAt && time < opensAt) {
    return BuffStatus.Scheduled
  }

  return BuffStatus.Published
}

/**
 * @param {Voteable} buff
 * @param {number} time
 * @return {BuffStatus}
 */
export const mapVoteableStatus = (buff: Voteable, time?: number) => {
  if (!time) return BuffStatus.Published
  const closesAt = !!buff?.closesAt && new Date(buff?.closesAt).getTime()
  const resolvesAt = !!buff?.resolvesAt && new Date(buff?.resolvesAt).getTime()
  const opensAt = !!buff?.opensAt && new Date(buff?.opensAt).getTime()

  if (resolvesAt && time >= resolvesAt) {
    return BuffStatus.Resolved
  } else if (closesAt && time >= closesAt) {
    return BuffStatus.Expired
  } else if (opensAt && time < opensAt) {
    return BuffStatus.Scheduled
  }

  return BuffStatus.Published
}

/**
 * @param {BuffStatus} status
 * @return {object}
 */
export const mapBuffStatusForPill = (status: BuffStatus) => {
  switch (status) {
    case BuffStatus.Expired: {
      return {
        text: 'Voting closed',
        color: 'text-[#B42318]',
        bg: 'bg-[#FCF1F0]',
      }
    }

    case BuffStatus.Resolved: {
      return {
        text: 'Resolved',
        color: 'text-[#1566BD]',
        bg: 'bg-[#EAF2FA]',
      }
    }

    case BuffStatus.Scheduled: {
      return {
        text: 'Scheduled',
        color: 'text-[#027A48]',
        bg: 'bg-[#E9FAF0]',
      }
    }

    default: {
      return {
        text: 'Voting open',
        color: 'text-[#027A48]',
        bg: 'bg-[#E9FAF0]',
      }
    }
  }
}
