import React, { FC, useState, useContext, useEffect, ChangeEvent } from 'react'
import { tr } from '@constants/other'
import TextInput from '@components/atoms/TextInput'
import { AssetContext } from '@services/providers/AssetProvider'
import { AssetActionTypes } from '@services/reducers/assetReducer'
import { isFileTypeSupported } from '@utils/asset'
import { ReactComponent as DocumentError } from '@assets/document_error.svg'
import Image from '@components/atoms/Image'
import { ReactComponent as PlaceholderSVG } from '@assets/placeholder.svg'
import { ReactComponent as MusicNoteSVG } from '@assets/music-note-filled.svg'
import { AssetItemProps } from './types'
import Button from '@components/atoms/Button'
import { AssetClass } from '@interfaces/Assets'

const UploadableAssetItem: FC<React.PropsWithChildren<AssetItemProps>> = ({
  asset,
}) => {
  const [url, setUrl] = useState('')
  const { dispatch } = useContext(AssetContext)

  const isFileSupported = isFileTypeSupported(asset.image, asset?.accept)
  const border = !isFileSupported ? 'border border-red' : ''
  const assetDescriptionLabel = tr({ id: 'assetManager.description' })
  const assetNameLabel = isFileSupported
    ? asset.meta?.class === AssetClass.BuffSound
      ? tr({ id: 'assetManager.soundName' })
      : tr({ id: 'assetManager.imageName' })
    : 'File type not supported'

  useEffect(() => {
    if (!isFileSupported) return

    const reader = new FileReader()
    const handleAssetLoaded = () => {
      setUrl(reader.result as unknown as string)
    }

    reader.addEventListener('load', handleAssetLoaded)
    reader.readAsDataURL(asset.image)

    return () => {
      reader.removeEventListener('load', handleAssetLoaded)
    }
  }, [asset.image, isFileSupported])

  const handleDelete = () => {
    dispatch({ type: AssetActionTypes.Delete, payload: asset.id })
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newAsset = asset
    newAsset.meta.filename = e.target.value

    dispatch({
      type: AssetActionTypes.UpdateImage,
      payload: { id: asset.id, asset: newAsset },
    })
  }

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newAsset = asset
    newAsset.meta.description = e.target.value

    dispatch({
      type: AssetActionTypes.UpdateImage,
      payload: { id: asset.id, asset: newAsset },
    })
  }

  const showImage = isFileSupported && asset.status !== 'error'

  return (
    <div className={`my-2 p-4 flex relative rounded bg-white ${border}`}>
      <div className="flex">
        <div className="mr-3">
          {showImage ? (
            asset?.meta?.class === AssetClass.BuffSound ? (
              <div className="bg-[#F3F4F5] rounded w-[68px] h-[76px] flex items-center justify-center">
                <MusicNoteSVG className="w-[90%] text-[#A1A1A1]" />
              </div>
            ) : (
              <Image
                alt="asset"
                className="rounded w-[68px] h-[76px]"
                src={url}
                fallbackComponent={
                  <PlaceholderSVG className="rounded w-[68px] h-[76px]" />
                }
              />
            )
          ) : (
            <div className="rounded h-[76px] w-[68px] bg-[#FFEEF0] flex items-center justify-center">
              <DocumentError />
            </div>
          )}
        </div>
        {showImage ? (
          <>
            <div>
              <TextInput
                label={assetNameLabel}
                value={asset?.meta?.filename ?? ''}
                onChange={handleNameChange}
                labelClasses="font-semibold"
              />
            </div>
            <div className="ml-4">
              <TextInput
                label={assetDescriptionLabel}
                value={asset?.meta?.description ?? ''}
                onChange={handleDescriptionChange}
                labelClasses="font-semibold"
              />
            </div>
          </>
        ) : (
          <div className="flex flex-col justify-center">
            <p className="text-bold text-black my-0">{asset?.meta?.filename}</p>
            <p className="text-red text-sm my-0">
              {asset.sizeError
                ? tr({ id: 'assetManager.singleMaxSize' })
                : tr({ id: 'assetManager.uploadFailed' })}
            </p>
          </div>
        )}
      </div>
      <div className="absolute pr-2 pt-2 right-0 top-0">
        <Button
          variant="neutral"
          shape="circle"
          size="small"
          onClick={handleDelete}
        >
          <img
            data-testid="close-image"
            src="/assets/icon-close.png"
            alt={tr({ id: 'generic.delete' })}
          />
        </Button>
      </div>
    </div>
  )
}

export default UploadableAssetItem
