import { useMemo } from 'react'
import { useQueries } from 'react-query'
import { getStreamById } from '@services/requests/stream'

export const useSpecificStreams = (streamIds: string[]) => {
  const queries = useMemo(() => {
    return streamIds.map((id) => {
      return {
        queryKey: `stream.${id}`,
        queryFn: () => getStreamById(id),
      }
    })
  }, [streamIds])

  return useQueries(queries)
}
