import {
  CreateAnnouncement,
  AnnouncementFormValues,
} from '@interfaces/Announcement'
import { LanguageCode } from '@interfaces/ILanguage'
import { parseLocalisationObjectToArray } from '@utils/localisation'
import {
  LocalisedAnnouncementContent,
  LocalisedSponsorContent,
} from '@interfaces/localisation'
import formatBuffAuthorToSubmit from './formatBuffAuthorToSubmit'

const formatDataIntoContentObject = (values: AnnouncementFormValues) => {
  const contentObj: Partial<
    Record<LanguageCode, LocalisedAnnouncementContent>
  > = {}

  const format = (
    key: keyof Omit<
      AnnouncementFormValues,
      'authorId' | 'imageGroupId' | 'duration'
    >
  ) => {
    // @ts-ignore
    Object.entries(values[key]).forEach(([langStr, value]) => {
      const lang = langStr as LanguageCode
      if (!contentObj[lang]) {
        // @ts-ignore
        contentObj[lang] = {
          localisation: lang,
        }
      }
      // @ts-ignore
      if (value !== '') {
        // @ts-ignore
        contentObj[lang][key] = value
      }
    })
  }

  format('text')
  format('titleText')
  format('imageUrl')
  format('buttonText')
  format('linkUrl')

  return contentObj
}

export const formatAnnouncementDataToSubmit = (
  values: AnnouncementFormValues,
  streamLanguages: LanguageCode[] = []
): CreateAnnouncement => {
  const date = new Date().toISOString()
  const contentArray = formatDataIntoContentObject(values)

  let sponsor: CreateAnnouncement['sponsor'] | undefined = undefined
  const langsWithSponsor = Object.entries(values.showSponsor)
    .filter(([key, value]) => {
      return value === true
    })
    .map(([key]) => key) as LanguageCode[]

  if (langsWithSponsor.length !== 0) {
    const newSponsor: Partial<Record<LanguageCode, LocalisedSponsorContent>> =
      {}
    sponsor = {
      localisations: [],
    }

    langsWithSponsor.forEach((lang) => {
      if (!newSponsor[lang]) {
        newSponsor[lang] = {
          text: '',
          localisation: lang,
        }
      }

      const obj = newSponsor[lang]
      if (!obj) return

      obj.imageUrl =
        values.sponsor?.[lang]?.imageUrl || values.sponsor?.[lang]?.imageURL
      obj.linkTarget = values.sponsor?.[lang]?.linkTarget
    })

    sponsor.localisations =
      parseLocalisationObjectToArray<LocalisedSponsorContent>(newSponsor)
  }

  const body: CreateAnnouncement = {
    sponsor,
    content: {
      localisations: parseLocalisationObjectToArray(contentArray),
    },
    author: formatBuffAuthorToSubmit(values?.author, streamLanguages),
    opensAt: date,
    currentTime: date,
    displayDurationSeconds: values?.duration,
    localisations: Object.keys(contentArray)?.map(
      (lang) => lang as LanguageCode
    ),
  }

  return body
}
