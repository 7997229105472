import React, { FC, useEffect, useRef } from 'react'
import { Form, Formik, FormikProps } from 'formik'
import { useMutation } from 'react-query'
import { isEqual, noop } from 'lodash'
import { format } from 'date-fns'
import { tr } from '@constants/other'
import Button from '@components/atoms/Button'
import Modal, { ModalSize } from '@components/molecules/Modal'
import AutoPatchTouched from '@components/atoms/AutoPatchTouched'
import { ExpirePaymentItem, useLive } from '@services/requests/live'
import { queryClient } from '@utils/reactQuery/client'
import { useToast } from '@utils/hooks/useToast'
import { formatInTimeZone } from '@utils/date'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import LeaderboardForm from './Form'
import schema from './schema'
import { LeaderboardFormValues, EditVipLeaderboardModalProps } from './types'

const initialValues: LeaderboardFormValues = {
  expiryDate: undefined,
  expiryTime: undefined,
}

const EditVipLeaderboardModal: FC<
  React.PropsWithChildren<EditVipLeaderboardModalProps>
> = (props) => {
  const {
    show,
    onClose,
    leaderboard,
    paymentItem,
    streamId,
    'data-testid': testId = 'vip-leaderboard-modal',
  } = props

  const { addToast, addErrorToast } = useToast()
  const { expirePaymentItems } = useLive()

  const formRef = useRef<FormikProps<LeaderboardFormValues>>(null)

  const { mutate: mutateLeaderboard, isLoading: isCreating } = useMutation(
    (values: Partial<ExpirePaymentItem>) =>
      expirePaymentItems({
        leaderboardId: String(leaderboard?.id),
        streamId: String(streamId),
        ...values,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['leaderboard', leaderboard?.id])
        addToast({
          msg: tr({ id: 'leaderboard.paymentItemUpdated' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
        onClose()
      },
      onError: (err) => {
        addErrorToast(err)
      },
    }
  )

  const handleSubmit = (values: LeaderboardFormValues) => {
    const { expiryDate, expiryTime } = values
    const date = new Date(`${expiryDate} ${expiryTime}`)

    const dateFormat = formatInTimeZone(date.toISOString(), 'yyyy-MM-dd', 'UTC')
    const timeFormat = formatInTimeZone(date.toISOString(), 'HH:mm:ss', 'UTC')
    const formattedDate = `${dateFormat}T${timeFormat}Z`

    const index = leaderboard?.paymentOptions?.findIndex((option) =>
      isEqual(option, paymentItem)
    )

    mutateLeaderboard({ at: formattedDate, index })
  }

  useEffect(() => {
    if (formRef?.current && !!paymentItem?.availableUntil && show) {
      const dateFormat = format(
        new Date(paymentItem?.availableUntil),
        'yyyy-MM-dd'
      )
      const timeFormat = format(new Date(paymentItem?.availableUntil), 'HH:mm')
      formRef?.current?.setValues({
        expiryDate: dateFormat,
        expiryTime: timeFormat,
      })
    }
  }, [paymentItem, show])

  return (
    <Modal
      size={ModalSize.SMALL}
      isOpen={show}
      data-testid={testId}
      title={tr({ id: 'leaderboard.editExpiryDate' })}
      displayCloseButton={false}
      onClose={noop}
    >
      <Modal.Body className="overflow-x-hidden !max-h-[calc(100vh-220px)]">
        <p className="text-[#717A85]">{tr({ id: 'leaderboard.expiryInfo' })}</p>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validateOnChange
          validateOnBlur
          validateOnMount
          validationSchema={schema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {(props) => {
            const { isValid } = props

            return (
              <Form className="flex flex-col gap-y-6">
                <LeaderboardForm />
                <div className="flex gap-4 items-center justify-end">
                  <Button
                    variant="secondary"
                    data-testid={`${testId}__cancel`}
                    onClick={onClose}
                    disabled={isCreating}
                  >
                    {tr({ id: 'generic.cancel' })}
                  </Button>
                  <Button
                    disabled={!isValid || isCreating}
                    variant="primary"
                    data-testid={`${testId}__action`}
                    type="submit"
                  >
                    {tr({ id: 'leaderboard.updateExpiryDate' })}
                  </Button>
                </div>
                <AutoPatchTouched />
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default EditVipLeaderboardModal
