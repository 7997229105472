import { useInfiniteQuery } from 'react-query'

import { useVodClient } from '@services/requests/vodV2'

/**
 * @param {number} limit Number of items to return per page
 * @param {string} query query param used when requesting streams
 * @return {Vod[]}
 */
export default function useVods(limit: number = 30, query?: string) {
  const { getVods } = useVodClient()
  const key = query ? `vods?${query}` : 'vods'
  return useInfiniteQuery(
    key,
    ({ pageParam = '' }) => getVods(limit, pageParam, query),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.nextPage?.length) return undefined

        return lastPage?.nextPage
      },
    }
  )
}
