import { get } from 'lodash'
import isURL from 'validator/lib/isURL'
import { TestFunction, TestContext } from 'yup'

export const requiredIfPathPresent = (
  message: string,
  path: string
): [string, string, TestFunction] => {
  return [
    'required-if-path-present',
    message,
    (value: unknown, context: TestContext) => {
      /**
       * below is undocumented way to grab parent of parent
       * @see https://github.com/jquense/yup/issues/398#issuecomment-916693907
       *
       */
      // @ts-ignore
      const rootParent = context.from[context.from.length - 1]
      const siblingValue = get(rootParent.value, path)
      if (!value && siblingValue) return false
      return true
    },
  ]
}

export const validateURL = (value: string) => {
  return isURL(value, {
    require_protocol: true,
    protocols: ['http', 'https'],
  })
}

export const isValidURL = (
  message: string
): [string, string, TestFunction<string | undefined>] => {
  return [
    'is-valid-url',
    message,
    (value: string | undefined) => {
      if (!value) return true
      return validateURL(value)
    },
  ]
}
