export enum ColorPickerType {
  TEXT = 'text',
  BACKGROUND = 'background',
}

export interface ColorPickerProps {
  /**
   * Type of the colour picker. Used to get and store user colours
   */
  type: ColorPickerType

  /**
   * Selected colour by the user
   */
  color: string

  /**
   * Event fired when new background colour is set
   */
  onChange: (color: string) => void

  /**
   * Handler fired when apply to all or reset all is clicked
   */
  onChangeAll?: (color: string) => void
}
