import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { noop } from 'lodash'
import { ReactComponent as CloseSVG } from '@assets/icon_misc_close.svg'
import { ModalProps, ModalBodyProps, ModalSize } from './types'

const getModalWidth = (size: ModalSize) => {
  switch (size) {
    case ModalSize.SMALL:
      return 'max-w-[498px]'
    case ModalSize.LARGE:
    default:
      return 'max-w-[794px]'
  }
}

/**
 * Modal component
 * @param {ModalProps} args
 * @return {ReactNode}
 */
function Modal({
  children,
  isOpen,
  title,
  className = '',
  contentClassName = '',
  initialFocusRef,
  hideHeader = false,
  onClose = noop,
  size = ModalSize.SMALL,
  displayCloseButton = true,
  'data-testid': testId = 'modal',
}: ModalProps) {
  const modalWidth = getModalWidth(size)

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-10 ${className}`}
        data-testid={testId}
        onClose={onClose}
        initialFocus={initialFocusRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/60" aria-hidden="true" />
        </Transition.Child>
        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 flex items-center !p-6">
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center w-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* The actual dialog panel  */}
              <Dialog.Panel
                className={`mx-auto w-full ${modalWidth} rounded bg-[#ffffff] !px-6 !pb-4 ${contentClassName}`}
                data-testid={`${testId}__content`}
              >
                {!hideHeader && (
                  <div className={`flex !py-5 justify-between`}>
                    {title ? (
                      <h3 className="text-2xl font-bold m-0">{title}</h3>
                    ) : (
                      <span />
                    )}
                    {displayCloseButton && (
                      <button
                        className="flex items-center justify-center h-7 w-7 rounded-full border-none !bg-[#717A85] text-white"
                        onClick={onClose}
                        data-testid={`${testId}__close`}
                      >
                        <CloseSVG className="h-2.5" />
                      </button>
                    )}
                  </div>
                )}

                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

/**
 * A component to render a scrollable container to be used inside a Modal
 * @param {ModalBodyProps} args
 * @return {ReactNode}
 */
export const ModalBody = ({ children, className = '' }: ModalBodyProps) => {
  return (
    <div
      style={{ maxHeight: 'calc(100vh - 300px)' }}
      className={`overflow-y-auto ${className}`}
    >
      {children}
    </div>
  )
}

Modal.Body = ModalBody

export default Modal
