import React, { FC } from 'react'
import Modal from '@components/molecules/Modal'
import { tr } from '@constants/other'
import Button, { ButtonVariant } from '@components/atoms/Button'
import { Alignment, IConfirmationModalProps } from './types'

const ConfirmationModal: FC<
  React.PropsWithChildren<IConfirmationModalProps>
> = ({
  children,
  show,
  onClose,
  onCancel,
  onSuccess,
  disabled = false,
  successBtnText = '',
  cancelBtnText = tr({ id: 'generic.cancel' }),
  title = tr({ id: 'generic.sure' }),
  subtext = '',
  variant = ButtonVariant.Primary,
  'data-testid': testId = 'confirmation-modal',
  alignment = Alignment.BETWEEN,
}) => {
  const handleClick = () => {
    onSuccess && onSuccess()
    onClose && onClose()
  }

  const handleCancel = () => {
    onCancel && onCancel()
    onClose && onClose()
  }

  const buttonAlignment =
    alignment === Alignment.END ? 'justify-end' : 'justify-between'

  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      data-testid={testId}
      title={title}
      displayCloseButton={false}
    >
      <p dangerouslySetInnerHTML={{ __html: subtext }} />
      <Modal.Body>{children}</Modal.Body>
      <div className={`flex ${buttonAlignment} !pt-4 gap-4`}>
        <Button
          variant="secondary"
          type="button"
          data-testid={`${testId}__cancel`}
          onClick={handleCancel}
        >
          {cancelBtnText}
        </Button>
        <Button
          variant={variant}
          type="button"
          data-testid={`${testId}__confirm`}
          onClick={handleClick}
          disabled={disabled}
        >
          {successBtnText}
        </Button>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
