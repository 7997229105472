import React, { FC, MouseEvent } from 'react'
import { BuffType, tr } from '@constants/other'
import useSingleAndDoubleClick from '@utils/hooks/useSingleAndDoubleClick'
import { getLanguagesMetadata } from '@utils/language'
import { LanguageCode } from '@interfaces/ILanguage'
import { CreateVoteableAnswer } from '@interfaces/Voteable'
import TooltipPill from '@components/molecules/TooltipPill'
import getLocalisations from '@utils/getLocalisation'
import Image from '@components/atoms/Image'

interface IProps {
  type?: BuffType
  id: string
  languages: LanguageCode[]
  activeLanguage: LanguageCode
  name: string
  answers: CreateVoteableAnswer[]
  question: string | null
  title?: string
  onClick: () => void
  onDoubleClick: () => void
  selected: boolean
}

const TemplateItem: FC<React.PropsWithChildren<IProps>> = ({
  id,
  type,
  name,
  question,
  languages = [],
  answers,
  title,
  onClick,
  onDoubleClick,
  selected,
  activeLanguage,
}) => {
  const handleClick = useSingleAndDoubleClick(onClick, onDoubleClick)

  const typeStyles = {
    color: 'white',
    background: `var(--buff-type-${type})`,
    width: '125px',
    borderBottomRightRadius: '5px',
    fontSize: 12,
  }

  const headerStyles = {
    background: 'white',
    padding: 0,
    borderBottom: 0,
  }

  /**
   * Prevents text selection on double click
   * @see https://stackoverflow.com/a/43321596
   *
   * @param {MouseEvent} e Mouse event
   */
  const handleMouseDown = (e: MouseEvent) => {
    if (e.detail > 1) {
      e.preventDefault()
    }
  }

  const langIconsInfo = getLanguagesMetadata(languages)

  return (
    <>
      <div
        onMouseDown={handleMouseDown}
        onClick={handleClick}
        className="last:!mb-0 relative flex flex-col min-w-0 min-h-[94px] text-pretty bg-white bg-clip-border rounded w-full"
        style={{
          boxShadow: '0',
          border: 'solid 2px',
          minHeight: '94px',
          borderColor: selected ? 'var(--primary)' : 'var(--white-background)',
        }}
      >
        <div className="flex justify-between">
          <div className="flex space-x-2 items-center">
            <div className="bg-white p-0" style={headerStyles}>
              {!!type && (
                <div style={typeStyles} className="px-3 py-1 rounded-tl">
                  {tr({ id: `buff.type.${type}` })}
                </div>
              )}
            </div>
            <span className="italic text-black text-[12px]">{name}</span>
          </div>
          <div className="space-x-1 mr-8 flex">
            {langIconsInfo?.map((l) => (
              <TooltipPill
                key={`${l?.iso6391}`}
                className="ml-1 mt-1"
                showTooltip
                text={`${l?.iso6391.toUpperCase()}`}
              >
                {l?.name}
              </TooltipPill>
            ))}
          </div>
        </div>
        <div
          className="min-h-[1px] flex-auto !p-5"
          style={{ cursor: 'default', userSelect: 'none' }}
        >
          <div className="flex justify-between">
            <div className="flex flex-col justify-center">
              {!!title && (
                <span className="text-[#3C3C3C] text-[12px] line-clamp-1 break-all">
                  {title}
                </span>
              )}
              <span className="mr-4 line-clamp-1 break-all text-black">
                {question || null}
              </span>
            </div>
            <div className="flex space-x-2 last:mr-3">
              {answers?.map((answer, i) => {
                const answerObj = getLocalisations({
                  localisations: answer?.localisations,
                  languages: [activeLanguage],
                })
                const imageUrl = answerObj?.imageUrl || answerObj?.imageURL
                return (
                  <div key={`img-${i}`}>
                    <div className="flex relative items-center justify-center bg-[#d8e1ec] rounded-md w-[68px] h-[60px] overflow-hidden">
                      <Image
                        className="absolute inset-0 w-full h-full rounded object-cover"
                        src={imageUrl}
                        fallbackComponent={
                          <img
                            className="rounded"
                            width={24}
                            height={24}
                            src={'/assets/icon-image.png'}
                            alt={tr({ id: 'assetManager.assetIcon' })}
                          />
                        }
                        alt="Answer image"
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TemplateItem
