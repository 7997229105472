import { string, object } from 'yup'
import { tr } from '@constants/other'

const schema = object().shape({
  email: string()
    .required(tr({ id: 'validation.required' }))
    .email(tr({ id: 'validation.email' })),
  role: string().required(tr({ id: 'validation.required' })),
})

export default schema
