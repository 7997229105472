import React, { CSSProperties, FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { ReactComponent as SearchIcon } from '@assets/search.svg'

import ScrollableContainer from '@components/atoms/ScrollableContainer'
import EmptyStateWithIcon from '../EmptyStateWithIcon'

interface Props {
  isLoading: boolean
  isEmpty: boolean
  emptyText: string
  triggerRef: (node?: Element | null | undefined) => void
}

const ScrollableSidebar: FC<React.PropsWithChildren<Props>> = ({
  triggerRef,
  isLoading,
  isEmpty,
  emptyText,
  children,
}) => {
  const containerStyles: CSSProperties = {
    backgroundColor: 'var(--white-secondary)',
  }

  if (isLoading) {
    return (
      <div style={containerStyles}>
        {new Array(6).fill(null).map((_v, index) => {
          const isEven = index % 2 === 0
          const className = isEven ? 'w-2/3' : 'w-full'
          return <Skeleton key={index} className={`h3 mb-4 ${className}`} />
        })}
      </div>
    )
  }

  if (isEmpty) {
    return (
      <EmptyStateWithIcon
        text={emptyText}
        textClasses="text-[#959DA5] !font-semibold"
        icon={<SearchIcon className="w-10" />}
      />
    )
  }

  return (
    <div className="ref relative h-full">
      <ScrollableContainer className="inverted-scroll absolute inset-0" auto>
        <div style={containerStyles}>{children}</div>
        <div ref={triggerRef} className="h-1" />
      </ScrollableContainer>
    </div>
  )
}

export default ScrollableSidebar
