import React, { FC } from 'react'
import { BreadcrumbProps } from './types'

const Breadcrumb: FC<React.PropsWithChildren<BreadcrumbProps>> = ({
  children,
  className,
  'data-testid': testId = 'Breadcrumb',
  ...props
}) => {
  return (
    <nav
      aria-label="Breadcrumb"
      {...props}
      data-testid={testId}
      className={`text-[0.75rem] my-3 ${className}`}
    >
      <ol className="flex items-center px-0 my-0">{children}</ol>
    </nav>
  )
}

Breadcrumb.displayName = 'Breadcrumb'
export default Breadcrumb
