import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { ColumnDef } from '@tanstack/react-table'
import { tr } from '@constants/other'
import Container from '@components/atoms/Container'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import Button, { ButtonVariant } from '@components/atoms/Button'
import DelayRender from '@components/atoms/DelayRender'
import TeamMembersSkeleton from '@components/molecules/TeamMembersSkeleton'
import TooltipPill from '@components/molecules/TooltipPill'
import {
  useDeleteUserRoles,
  useGetPrivilegedUsers,
  useGetUser,
  useGetUserRoles,
} from '@services/requests/users'
import { ReactComponent as EditSVG } from '@assets/edit.svg'
import { ReactComponent as TrashSVG } from '@assets/trash.svg'
import { ReactComponent as SendSVG } from '@assets/send-filled.svg'
import { DataTable } from '@components/molecules/DataTable'
import {
  Invitation,
  useDeleteInvite,
  useGetInvites,
  useResendInvite,
} from '@services/requests/auth'
import InviteUserModal from '@components/molecules/InviteUserModal'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { useToast } from '@utils/hooks/useToast'
import EmptyStateWithIcon from '@components/molecules/EmptyStateWithIcon'
import TruncatedTooltipText from '@components/atoms/TruncatedTooltipText'
import { ReactComponent as PersonMailSVG } from '@assets/person-mail.svg'
import { ReactComponent as WarningSVG } from '@assets/warning.svg'
import { formatInTimeZone } from '@utils/date'
import { TeamMember, Role } from './types'
import { Alignment } from '@components/molecules/ConfirmationModal/types'

export enum TabKeys {
  Members,
  PendingInvite,
}

const adminRoleOptions = [
  { key: '', value: tr({ id: 'teamMembers.selectRole' }) },
  {
    key: Role.ContentUser,
    value: tr({ id: `user.roles.${Role.ContentUser}` }),
  },
]

const ownerRoleOptions = [
  { key: '', value: tr({ id: 'teamMembers.selectRole' }) },
  { key: Role.Admin, value: tr({ id: `user.roles.${Role.Admin}` }) },
  {
    key: Role.ContentUser,
    value: tr({ id: `user.roles.${Role.ContentUser}` }),
  },
]

const allRoleOptions = [
  { key: '', value: tr({ id: 'teamMembers.selectRole' }) },
  {
    key: Role.AccountOwner,
    value: tr({ id: `user.roles.${Role.AccountOwner}` }),
  },
  { key: Role.Admin, value: tr({ id: `user.roles.${Role.Admin}` }) },
  {
    key: Role.ContentUser,
    value: tr({ id: `user.roles.${Role.ContentUser}` }),
  },
]

/**
 * Get user's highest role value and discards lower ones
 * @param {Role} roles
 * @return {Role}
 */
function getUserHighestRole(roles?: Role[]) {
  if (!roles) return Role.ContentUser
  if (roles.includes(Role.AccountOwner)) return Role.AccountOwner
  if (roles.includes(Role.Admin)) return Role.Admin
  return Role.ContentUser
}

const TeamMembers: FC<React.PropsWithChildren<unknown>> = () => {
  const [activeTab, setActiveTab] = useState<number>(TabKeys.Members)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showLeaveModal, setShowLeaveModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState<TeamMember>()

  const { mutateAsync: mutateDelete } = useDeleteUserRoles()
  const { mutateAsync: mutateDeleteInvite } = useDeleteInvite()
  const { mutateAsync: mutateResendInvite } = useResendInvite()

  const { data: usersData, isLoading, isError } = useGetPrivilegedUsers()
  const {
    data: invitesData,
    isLoading: isLoadingInvites,
    isError: isErrorInvites,
  } = useGetInvites()
  const { data: userData } = useGetUser()
  const { data: roleData } = useGetUserRoles(userData?.id, {
    enabled: !!userData?.id,
  })

  const { addErrorToast, addToast } = useToast()
  const timeoutRef = useRef<number>()

  const roles = roleData?.roles
  const role = getUserHighestRole(roles)

  const showInviteButton =
    roles?.includes(Role.AccountOwner) || roles?.includes(Role.Admin)
  const showPendingTab = role !== Role.ContentUser

  const currentUser = useMemo(() => {
    if (!selectedUser) return undefined
    return {
      ...selectedUser,
      role: getUserHighestRole(selectedUser.roles),
    }
  }, [selectedUser])

  const isRoleDisabled =
    !!currentUser &&
    ((role === Role.Admin && currentUser?.role === Role.Admin) ||
      currentUser?.role === Role.AccountOwner)

  const roleOptions =
    role === Role.AccountOwner ? ownerRoleOptions : adminRoleOptions

  const options = useMemo(() => {
    if (isRoleDisabled) return allRoleOptions
    return roleOptions
  }, [isRoleDisabled, roleOptions])

  const setTabIndex = (index: number) => {
    const tabKey = tabOrder[index]
    setActiveTab(tabKey)
  }

  const clearSelectedUser = () => {
    // Added this to not have flickering effect on modals
    timeoutRef?.current && clearTimeout(timeoutRef.current)
    timeoutRef.current = window.setTimeout(() => {
      setSelectedUser(undefined)
    }, 200)
  }

  const handleInviteModalClose = () => {
    setShowInviteModal(false)
    clearSelectedUser()
  }

  const handleInviteModalOpen = () => {
    setShowInviteModal(true)
  }

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false)
    clearSelectedUser()
  }

  const handleLeaveModalClose = () => {
    setShowLeaveModal(false)
    clearSelectedUser()
  }

  const handleInviteModalSuccess = () => {
    if (!!selectedUser) {
      addToast({
        msg: tr({ id: 'teamMembers.memberUpdated' }),
        type: 'success',
        image: <Tick className="mr-3 w-8 h-8" />,
      })
    } else {
      addToast({
        msg: tr({ id: 'teamMembers.inviteSent' }),
        type: 'success',
        image: <Tick className="mr-3 w-8 h-8" />,
      })
    }
    setShowInviteModal(false)
    clearSelectedUser()
  }

  const handleEdit = (user: TeamMember) => {
    setSelectedUser(user)
    setShowInviteModal(true)
  }

  const handleDeleteRequest = async () => {
    if (!selectedUser) return
    try {
      await mutateDelete(selectedUser)

      if (selectedUser?.id === userData?.id) {
        window.dispatchEvent(new Event('expiredRefreshToken'))
      }
    } catch (err) {
      addErrorToast(err)
    } finally {
      handleDeleteModalClose()
    }
  }

  const checkInviteExpired = (user: Invitation) => {
    return new Date(user.expiresAt) < new Date()
  }

  useEffect(() => {
    return () => {
      timeoutRef?.current && clearTimeout(timeoutRef.current)
    }
  }, [])

  const inviteModalSubtitle = !selectedUser
    ? tr({ id: 'teamMembers.inviteMemberDescription' })
    : undefined

  const inviteModalTitle = !!selectedUser
    ? tr({ id: 'teamMembers.editMember' })
    : tr({ id: 'teamMembers.inviteNewMember' })

  const inviteModalActionButtonTitle = !!selectedUser
    ? tr({ id: 'generic.update' })
    : tr({ id: 'generic.invite' })

  const membersColumns = useMemo<ColumnDef<TeamMember>[]>(() => {
    const handleDelete = (user: TeamMember) => {
      setSelectedUser(user)
      if (user.id === userData?.id) {
        setShowLeaveModal(true)
      } else {
        setShowDeleteModal(true)
      }
    }

    return [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: (info) => (
          <>
            <div className="flex items-center gap-x-1">
              <TruncatedTooltipText className="max-w-[250px]">
                {`${info.row.original.firstName || ''} ${
                  info.row.original.lastName || ''
                }`}
              </TruncatedTooltipText>
              <span className="text-[#959DA5]">
                {`${
                  info.row.original.id === userData?.id
                    ? `(${tr({ id: 'generic.you' })})`
                    : ''
                }`}
              </span>
            </div>
          </>
        ),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        cell: (info) => (
          <TruncatedTooltipText className="max-w-[250px]">
            {info.getValue<string>()}
          </TruncatedTooltipText>
        ),
      },
      {
        accessorKey: 'createdAt',
        header: 'Date added',
        cell: (info) => (
          <TooltipPill
            className="bg-transparent text-base"
            showTooltip={true}
            text={
              <span>
                {format(parseISO(info.getValue<string>()), 'MMM dd, yyyy')}
              </span>
            }
          >
            <span>
              {formatInTimeZone(
                info.getValue<string>(),
                'MMM dd, yyyy, HH:mm:ss',
                'UTC'
              )}{' '}
              UTC
            </span>
          </TooltipPill>
        ),
      },
      {
        accessorKey: 'roles',
        header: 'Role',
        cell: (info) => {
          return info
            .getValue<Role[]>()
            .map((role) => tr({ id: `user.roles.${role}` }))
            .join(',')
        },
      },
      {
        accessorKey: 'actions',
        header: '',
        cell: (info) => {
          if (!role) return null
          if (role === Role.ContentUser) return null
          const user = info.row.original
          const userRoles = user?.roles
          const userRole = getUserHighestRole(userRoles)

          const showActions =
            role === Role.AccountOwner ||
            user.email === userData?.email ||
            userRole === Role.ContentUser

          const showDelete = userRole !== Role.AccountOwner

          if (!showActions) return null

          return (
            <div className="flex items-center justify-center gap-2">
              <TooltipPill
                showTooltip
                className="rounded-sm bg-transparent text-[#00f] hover:!bg-[#F3F4F5] p-1.5"
                text={<EditSVG className="text-[#007BFF] w-4 h-4" />}
                onClick={() => handleEdit(user)}
              >
                {tr({ id: 'generic.edit' })}
              </TooltipPill>

              {showDelete && (
                <TooltipPill
                  showTooltip
                  className="rounded-sm bg-transparent text-[#00f] hover:!bg-[#F3F4F5] p-1.5"
                  text={<TrashSVG className="text-[#007BFF] w-4 h-4" />}
                  onClick={() => handleDelete(user)}
                >
                  {user?.id === userData?.id
                    ? tr({ id: 'teamMembers.leaveTeam' })
                    : tr({ id: 'teamMembers.removeTeamMember' })}
                </TooltipPill>
              )}
            </div>
          )
        },
      },
    ]
  }, [role, userData?.email, userData?.id])

  const inviteColumns = useMemo<ColumnDef<Invitation>[]>(() => {
    const handleResendInvite = async (user: Invitation) => {
      try {
        await mutateResendInvite(user.token)
        addToast({
          msg: tr({ id: 'teamMembers.inviteResent' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
      } catch (err) {
        addErrorToast(err)
      }
    }

    const handleDeleteInvite = async (user: Invitation) => {
      try {
        await mutateDeleteInvite(user.token)
        addToast({
          msg: tr({ id: 'teamMembers.inviteRemoved' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
      } catch (err) {
        addErrorToast(err)
      }
    }

    const getInvitedUserTableRowColumnColor = (user: Invitation) => {
      if (checkInviteExpired(user)) return 'text-[#959DA5]'
      return 'text-black'
    }

    return [
      {
        accessorKey: 'name',
        accessorFn: (row) => `${row.firstName || ''} ${row.lastName || ''}`,
        header: 'Name',
        cell: (info) => (
          <TruncatedTooltipText
            className={`${getInvitedUserTableRowColumnColor(
              info.row.original
            )} max-w-[250px]`}
          >
            {info.getValue<string>() || ''}
          </TruncatedTooltipText>
        ),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        cell: (info) => (
          <TruncatedTooltipText
            className={`${getInvitedUserTableRowColumnColor(
              info.row.original
            )} max-w-[200px]`}
          >
            {info.getValue<string>() || ''}
          </TruncatedTooltipText>
        ),
      },
      {
        accessorKey: 'expiresAt',
        header: 'Expiry date',
        cell: (info) => (
          <TooltipPill
            className="bg-transparent text-base"
            showTooltip={true}
            text={
              <span
                className={`${getInvitedUserTableRowColumnColor(
                  info.row.original
                )}`}
              >
                {format(parseISO(info.getValue<string>()), 'MMM dd, yyyy')}
              </span>
            }
          >
            <>
              <p
                className={`mb-0 ${
                  checkInviteExpired(info.row.original) ? 'block' : 'hidden'
                }`}
              >
                {tr({ id: 'teamMembers.inviteExpired' })}
              </p>
              <span>
                {formatInTimeZone(
                  info.getValue<string>(),
                  'MMM dd, yyyy, HH:mm:ss',
                  'UTC'
                )}{' '}
                UTC
              </span>
            </>
          </TooltipPill>
        ),
      },
      {
        accessorKey: 'role',
        header: 'Role',
        cell: (info) => {
          return (
            <span
              className={`${getInvitedUserTableRowColumnColor(
                info.row.original
              )}`}
            >
              {tr({ id: `user.roles.${info.getValue<Role[]>()}` })}
            </span>
          )
        },
      },
      {
        accessorKey: 'actions',
        header: '',
        cell: (info) => {
          const user = info.row.original
          const userRole = user.role

          const showActions =
            role === Role.AccountOwner ||
            (role === Role.Admin && userRole === Role.ContentUser)

          return (
            <div className="flex items-center justify-center gap-2">
              {showActions && (
                <>
                  <TooltipPill
                    showTooltip
                    className="rounded-sm bg-transparent text-[#00f] hover:!bg-[#F3F4F5] p-1.5"
                    text={<SendSVG className="h-4 w-4 text-[#007BFF]" />}
                    onClick={() => handleResendInvite(user)}
                  >
                    {tr({ id: 'generic.resendInvite' })}
                  </TooltipPill>
                  <TooltipPill
                    showTooltip
                    className="rounded-sm bg-transparent text-[#00f] hover:!bg-[#F3F4F5] p-1.5"
                    text={<TrashSVG className="text-[#007BFF] w-4 h-4" />}
                    onClick={() => handleDeleteInvite(user)}
                  >
                    {tr({ id: 'generic.delete' })}
                  </TooltipPill>
                </>
              )}
            </div>
          )
        },
      },
    ]
  }, [mutateDeleteInvite, mutateResendInvite, role, addErrorToast])

  const components = {
    members: {
      title: tr({ id: 'teamMembers.members' }),
      component: (
        <>
          {isLoading && (
            <DelayRender>
              <TeamMembersSkeleton />
            </DelayRender>
          )}
          {!isLoading && (
            <>
              <DataTable
                tableData={usersData?.users ?? []}
                columns={membersColumns}
              />
              {isError && (
                <EmptyStateWithIcon
                  icon={<WarningSVG className="w-7 h-7 text-white" />}
                  text={tr({ id: 'generic.somethingWentWrong' })}
                  containerClasses="h-auto mt-4"
                  textClasses="text-[#959DA5]"
                />
              )}
            </>
          )}
        </>
      ),
    },
    ...(showPendingTab && {
      'pending-invite': {
        title: tr({ id: 'teamMembers.pendingInvites' }),
        component: (
          <>
            {isLoadingInvites && (
              <DelayRender>
                <TeamMembersSkeleton />
              </DelayRender>
            )}
            {!isLoadingInvites && (
              <>
                <DataTable
                  tableData={invitesData?.invitations ?? []}
                  columns={inviteColumns}
                  initialSorting={[{ id: 'expiresAt', desc: false }]}
                />
                {isErrorInvites && (
                  <EmptyStateWithIcon
                    icon={<WarningSVG className="w-7 h-7 text-white" />}
                    text={tr({ id: 'generic.somethingWentWrong' })}
                    containerClasses="h-auto mt-4"
                    textClasses="text-[#959DA5]"
                  />
                )}
                {!invitesData?.invitations?.length && (
                  <EmptyStateWithIcon
                    icon={<PersonMailSVG className="w-7 h-7 text-white" />}
                    text={tr({ id: 'teamMembers.noPendingInvites' })}
                    containerClasses="h-auto mt-4"
                    textClasses="text-[#959DA5]"
                  />
                )}
              </>
            )}
          </>
        ),
      },
    }),
  }

  const componentEntries = Object.entries(components)

  const tabs = componentEntries.map(([slug, { title }]) => {
    return {
      text: title,
    }
  })

  const tabOrder = [TabKeys.Members, TabKeys.PendingInvite]

  const tabIndex = tabOrder.indexOf(activeTab)

  const selectedUserEmail = selectedUser?.email ?? ''

  return (
    <>
      <Container>
        <Row className="pl-2">
          <Col sm={10}>
            <div className="flex justify-between my-4">
              <div className="flex flex-col">
                <h1 className="h3 font-bold mb-2 mr-2.5">
                  {tr({ id: 'teamMembers.manageTeam' })}
                </h1>
                <span className="text-[1.125rem]">
                  {tr({ id: 'teamMembers.manageTeamDescription' })}
                </span>
              </div>
              {Boolean(showInviteButton) && (
                <Button onClick={handleInviteModalOpen} className="mt-1">
                  {tr({
                    id: 'teamMembers.inviteTeamMember',
                  })}
                </Button>
              )}
            </div>
          </Col>
        </Row>

        <Row className="pl-2">
          <Col sm={10}>
            <div className="mt-1 mb-2 px-4 py-3 bg-white rounded">
              <div>
                <Tabs
                  tabs={tabs}
                  selectedIndex={tabIndex}
                  onTabChange={setTabIndex}
                >
                  {componentEntries.map(([slug, { component }]) => {
                    return (
                      <TabPanel key={slug} className="!mt-4 !py-6">
                        {component}
                      </TabPanel>
                    )
                  })}
                </Tabs>
              </div>
            </div>
          </Col>
        </Row>

        <ConfirmationModal
          alignment={Alignment.END}
          variant={ButtonVariant.Danger}
          show={showLeaveModal}
          onClose={handleLeaveModalClose}
          successBtnText={tr({ id: 'teamMembers.leaveTeam' })}
          title={`${tr({ id: 'teamMembers.leaveTeam' })}?`}
          subtext={tr({ id: 'teamMembers.leaveTeamDescription' })}
          onSuccess={handleDeleteRequest}
        />

        <ConfirmationModal
          alignment={Alignment.END}
          variant={ButtonVariant.Danger}
          show={showDeleteModal}
          onClose={handleDeleteModalClose}
          successBtnText={tr({ id: 'teamMembers.removeTeamMember' })}
          title={`${tr({ id: 'teamMembers.removeTeamMember' })}?`}
          subtext={tr(
            { id: 'teamMembers.removeTeamMemberDescription' },
            { value: selectedUserEmail }
          )}
          onSuccess={handleDeleteRequest}
        />

        <InviteUserModal
          show={showInviteModal}
          subtitle={inviteModalSubtitle}
          title={inviteModalTitle}
          actionButtonTitle={inviteModalActionButtonTitle}
          initialValues={
            selectedUser
              ? {
                  ...selectedUser,
                  role: getUserHighestRole(selectedUser.roles)!,
                }
              : undefined
          }
          onClose={handleInviteModalClose}
          onSuccess={handleInviteModalSuccess}
          inputOptions={{
            email: {
              disabled: !!selectedUser,
            },
            role: {
              disabled: isRoleDisabled,
              options,
            },
          }}
        />
      </Container>
    </>
  )
}

export default TeamMembers
