/**
 * Utils used specifically for TimeInput component
 */

const ONE_S = 1
const ONE_MINUTE_S = 60 * ONE_S
const ONE_HOUR_S = 60 * ONE_MINUTE_S
const ONE_DAY_S = 24 * ONE_HOUR_S
const ONE_MONTH_S = 30 * ONE_DAY_S

const multiplyArray = [ONE_S, ONE_MINUTE_S, ONE_HOUR_S, ONE_DAY_S, ONE_MONTH_S]
const padArray = [2, 2, 2, 1, 1]

export const MAX_VALUE_S = ONE_HOUR_S * 999 + ONE_MINUTE_S * 59 + 59

export const convertPatternInputToSeconds = (value: string): number => {
  const reversedTimeArray = value
    .split(':')
    .map((value) => parseInt(value))
    .reverse()
  return reversedTimeArray.reduce(
    (acc, curr, index) => (acc += curr ? curr * multiplyArray[index] : 0),
    0
  )
}

export const convertSecondsToPatternInput = (
  value: number,
  pattern: string
): string => {
  const limit = (value: number, max: number) => {
    return value < max ? value : max
  }

  let secondsRemaining = value

  // We limit day to 9 because pattern accepts only one digit for day
  const day = limit(Math.floor(secondsRemaining / ONE_DAY_S), 9)
  if (day > 0) {
    secondsRemaining = secondsRemaining % (day * ONE_HOUR_S)
  }

  const hours = limit(Math.floor(secondsRemaining / ONE_HOUR_S), 23)
  if (hours > 0) {
    secondsRemaining = secondsRemaining % (hours * ONE_HOUR_S)
  }

  const minutes = limit(Math.floor(secondsRemaining / ONE_MINUTE_S), 59)
  if (minutes > 0) {
    secondsRemaining = secondsRemaining % (minutes * ONE_MINUTE_S)
  }

  const reversedValueArray = pattern
    .split(':')
    .map((value) => parseInt(value))
    .reverse()
  const patternValues = [secondsRemaining, minutes, hours, day]

  let result = ''

  reversedValueArray?.forEach((value, index) => {
    const padBy = padArray[index]
    const number = patternValues[index]
    result = ':' + number.toString().padStart(padBy, '0') + result
  })

  result = result.substring(1, result.length)

  return result
}

export const convertTimeInputValueToSeconds = (
  hhhmmss: string
): number | false => {
  const regex = /^\d{3}:\d{2}:\d{2}/
  if (!regex.test(hhhmmss)) {
    return false
  }
  const [hours, minutes, seconds] = hhhmmss
    .split(':')
    .map((value) => parseInt(value))

  const hoursToSeconds = hours * ONE_HOUR_S
  const minutesToSeconds = minutes * ONE_MINUTE_S

  return hoursToSeconds + minutesToSeconds + seconds
}

export const convertSecondsToTimeInputValue = (seconds: number): string => {
  const limit = (value: number, max: number) => {
    return value < max ? value : max
  }
  if (seconds > MAX_VALUE_S) {
    return '---:--:--'
  }
  let secondsRemaining = seconds

  const hours = Math.floor(secondsRemaining / ONE_HOUR_S)
  if (hours > 0) {
    secondsRemaining = secondsRemaining % (hours * ONE_HOUR_S)
  }

  const minutes = limit(Math.floor(secondsRemaining / ONE_MINUTE_S), 59)
  if (minutes > 0) {
    secondsRemaining = secondsRemaining % (minutes * ONE_MINUTE_S)
  }

  const hoursStr = hours.toString().padStart(3, '0')
  const minutesStr = minutes.toString().padStart(2, '0')
  const secondsStr = secondsRemaining.toString().padStart(2, '0')

  return `${hoursStr}:${minutesStr}:${secondsStr}`
}
