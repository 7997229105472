import React, { forwardRef } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ChevronRight } from '@assets/chevron-right.svg'
import { BreadcrumbItemProps } from './types'

const BreadcrumbItem = forwardRef<
  HTMLAnchorElement | HTMLSpanElement,
  BreadcrumbItemProps
>(
  (
    {
      children,
      className,
      href,
      active,
      icon: Icon,
      iconClasses,
      chevronClasses,
      'data-testid': testId = 'breadcrumb-item',
      ...props
    },
    ref
  ) => {
    const isLink = typeof href !== 'undefined'

    const childComponent = (
      <>
        {!!Icon && (
          <Icon
            data-testid={`${testId}-icon`}
            className={`w-5 h-5 ${iconClasses}`}
          />
        )}
        {children}
      </>
    )

    const componentProps = {
      'data-testid': `${testId}-component`,
      className: `text-black inline-flex items-center ${
        active && '!text-[#717A85] font-bold'
      }`,
    }

    const Component = isLink ? (
      <Link
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        to={href ?? ''}
        {...componentProps}
      >
        {childComponent}
      </Link>
    ) : (
      <span
        ref={ref as React.ForwardedRef<HTMLSpanElement>}
        {...componentProps}
      >
        {childComponent}
      </span>
    )

    return (
      <li
        {...props}
        className={`flex items-center group ${className}`}
        data-testid={testId}
      >
        <ChevronRight
          data-testid={`${testId}-chevron`}
          className={`w-4 h-4 mx-1 text-[#717A85] group-first:hidden ${chevronClasses}`}
        />
        {Component}
      </li>
    )
  }
)

BreadcrumbItem.displayName = 'Breadcrumb.Item'
export default BreadcrumbItem
