import React, { FC, useState } from 'react'
import { tr } from '@constants/other'
import { IRouteParams } from '@interfaces/RouteParams'
import { useParams } from 'react-router-dom'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import StreamChatConfig from '@components/molecules/Chat/StreamChatConfig'
import ChatMetrics from '@components/molecules/Chat/ChatMetrics'
import ChatSettings from '@components/molecules/Chat/ChatSettings'

export enum TabKeys {
  Chat,
  ChatMetrics,
  Settings,
}

const StreamChatTab: FC = () => {
  const { id: streamId, gameId } = useParams<IRouteParams>()
  const [activeTab, setActiveTab] = useState<number>(TabKeys.Chat)
  const path = `/streams/${streamId}/${gameId}/global-chat`

  const tabs = [
    {
      text: tr({ id: 'globalChat.chat' }),
      name: 'chat',
      href: `${path}/chat`,
    },
    {
      text: tr({ id: 'generic.metrics' }),
      name: 'chat-metrics',
      href: `${path}/chat-metrics`,
    },
    {
      text: tr({ id: 'globalChat.settings' }),
      name: 'settings',
      href: `${path}/settings`,
    },
  ]

  const tabOrder = [TabKeys.Chat, TabKeys.ChatMetrics, TabKeys.Settings]

  const panelClassName = '!mt-4 !py-6'

  const tabIndex = tabOrder.indexOf(activeTab)

  const setTabIndex = (index: number) => {
    const tabKey = tabOrder[index]
    setActiveTab(tabKey)
  }

  return (
    <div>
      <Tabs
        tabs={tabs}
        selectedIndex={tabIndex}
        onTabChange={setTabIndex}
        renderBorder={false}
      >
        <TabPanel className={panelClassName}>
          <StreamChatConfig />
        </TabPanel>
        <TabPanel className={panelClassName}>
          <ChatMetrics />
        </TabPanel>
        <TabPanel className={panelClassName}>
          <ChatSettings />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default StreamChatTab
