import { RefObject, useRef } from 'react'
import { FormikProps } from 'formik'

const useAutoSubmitForm = <T>(form: RefObject<FormikProps<T>>) => {
  const timeoutRef = useRef<number>()

  const clearTimeout = () => {
    window.clearTimeout(timeoutRef.current)
    timeoutRef.current = undefined
  }

  const submit = () => {
    clearTimeout()

    timeoutRef.current = window.setTimeout(() => {
      form?.current?.submitForm()
    }, 1000)
  }

  return submit
}

export default useAutoSubmitForm
