import { ReactNode } from 'react'

interface Tab {
  /**
   * Text for the tab
   */
  text: string | ReactNode

  /**
   * Url of the tab. Will render an anchor if provided
   */
  href?: string

  /**
   * If the tab should be disabled
   */
  disabled?: boolean

  /**
   * Id of the tab. id will be used as the key to track the tab on for loop if given
   * If id is not given text + index will be used as a key
   * If the text is dynamic we should use id to avoid key bugs
   */
  id?: string
}

export interface TabsProps {
  /**
   * The tabs links to render
   */
  tabs: Tab[]

  /**
   * Selected tab
   */
  selectedIndex?: number

  /**
   * Handler called when tab should change
   * @param {number} index
   * @return {void}
   */
  onTabChange?: (index: number) => void

  /**
   * Children TabPanels to render
   */
  children?: ReactNode

  /**
   * Children to render in the header
   */
  headerChildren?: ReactNode

  /**
   * Whether to render tab bottom border
   */
  renderBorder?: boolean

  /**
   * Style of tabs to render
   * @default TabStyle.default
   */
  tabStyle?: TabStyle

  /**
   * Selection ID for test purposes
   */
  'data-testid'?: string

  /**
   * Custom className
   */
  className?: string
}

export enum TabStyle {
  default = 'default',
  buttons = 'buttons',
}
