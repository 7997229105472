import { AxiosResponse } from 'axios'
import { client } from '@services/requests/client'
import { getPaginatedUrl, getQueryUrl } from '@utils/url'
import { Author, CreateAuthor, GetAuthorResponse } from '@interfaces/Author'

export const getAuthorById = async (authorId: string): Promise<Author> => {
  const response = await client.get(`/authors/${authorId}`)
  return response.data
}

export const getAuthors = async (
  pageSize: number = 50,
  nextParam: string = '',
  query?: string
): Promise<GetAuthorResponse> => {
  const urlWithQuery = getQueryUrl('/authors', query)
  const urlWithPagination = getPaginatedUrl(urlWithQuery, pageSize, nextParam)

  const response = await client.get(urlWithPagination)

  const authors = response.data.authors
  const nextPage = response.data.pagination.next

  return {
    authors,
    nextPage,
  }
}

export const createAuthor = async (author: CreateAuthor): Promise<Author> => {
  const response = await client.post(`/authors`, author)
  return response.data
}

export const updateAuthor = async (
  id: string,
  author: Partial<CreateAuthor>
): Promise<Author> => {
  const response = await client.patch(`/authors/${id}`, author)
  return response.data
}

export const deleteAuthor = async (
  authorId: string
): Promise<AxiosResponse> => {
  const response = await client.delete(`/authors/${authorId}`)
  return response
}
