import React, { FC, useState } from 'react'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import { tr } from '@constants/other'
import BuffHistoryList from '@components/organisms/BuffHistoryList'
import { BuffPageActiveTab } from '@pages/BuffPage'
import ScrollableContainer from '@components/atoms/ScrollableContainer'
import { GetVodVoteablesResponse } from '@interfaces/Vod'
import EmptyStateWithIcon from '@components/molecules/EmptyStateWithIcon'
import { ReactComponent as SearchIcon } from '@assets/search.svg'

interface AsideProps {
  mainActiveTab: string
  setMainActiveTab: (tab: BuffPageActiveTab) => void
  voteables?: GetVodVoteablesResponse
  isLoading: boolean
}

const Aside: FC<React.PropsWithChildren<AsideProps>> = ({
  voteables,
  isLoading,
}) => {
  const [tabIndex, setTabIndex] = useState<number>(0)

  const tabs = [
    {
      text: tr({ id: 'buffHistory.buffHistoryReplay' }),
    },
  ]

  return (
    <>
      <Tabs
        selectedIndex={tabIndex}
        onTabChange={setTabIndex}
        tabs={tabs}
        renderBorder={false}
      >
        <TabPanel>
          <div className="flex flex-col pt-4">
            <h3 className="h3">
              {tr({ id: 'buffHistory.buffHistoryReplay' })}
            </h3>
            <div className="bg-gray rounded">
              <ScrollableContainer
                heightOverflow="300px"
                className="default-scroll px-2 py-2"
              >
                {!voteables?.voteables?.length && !isLoading && (
                  <EmptyStateWithIcon
                    containerClasses="!h-auto mt-12"
                    textClasses="text-[#959DA5]"
                    iconClasses="h-[64px] w-[64px]"
                    text="No Buff to show"
                    icon={<SearchIcon className="w-8" />}
                  />
                )}
                {voteables?.voteables && (
                  <div
                    style={{ backgroundColor: 'var(--white-background)' }}
                    className="px-2 rounded"
                  >
                    <BuffHistoryList
                      mode="replay"
                      buffs={voteables?.voteables}
                    />
                  </div>
                )}
              </ScrollableContainer>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </>
  )
}

export default React.memo(Aside)
