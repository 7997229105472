import { AxiosError, AxiosResponse } from 'axios'
import { has, isNull } from 'lodash'
import {
  createIntl,
  createIntlCache,
  IntlShape,
  MessageDescriptor,
} from 'react-intl'
import { HttpStatus } from '../HttpStatus'
import IApiError from '../interfaces/IApiError'
import IEnvironment from '../interfaces/IEnvironment'
import { flattenMessages, getLocale, localMessages } from '../locales'
import logger from '../services/Logger'

export type ApiSuccessResponse<T> = AxiosResponse<T>
export type ApiResponse<T> = AxiosResponse<T>

export const DEFAULT_MANAGER_IMAGE: string =
  '/assets/images/manager-default.png'

export const DEFAULT_LOCALE_TITLE = 'English'
export const DEFAULT_LOCALE = 'en'

export const BUFFUP_DASHBOARD_TOKEN = `bu_dashboard_token`
export const BUFFUP_REFRESH_TOKEN = 'bu_refresh_token'

export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_FORMAT: string =
  (process.env as Partial<IEnvironment>).REACT_APP_DEFAULT_DATE_FORMAT ||
  DEFAULT_DATETIME_FORMAT

export const DEFAULT_SECOND_SCREEN_DELAY_TIME = '000:00:00'

export const MAX_REACTIONS_SELECTION = 30

export enum BuffPriority {
  Low = 0,
  Normal = 1,
  High = 2,
  Critical = 3,
}

export enum BuffType {
  Unspecified = 0,
  Prediction = 1,
  Quiz = 2,
  Poll = 3,
  EmojiSlider = 4,
  Announcement = 5,
  StarRating = 6,
  WelcomeBuff = 7,
  Vote = 8,
}

/** @deprecated see interfaces/buff */
export enum BuffStatus {
  BuffStatusNone = 0,
  BuffStatusQueued = 1,
  BuffStatusPublished = 2,
  BuffStatusExpired = 3,
  BuffStatusResolved = 4,
}

export enum HttpMethod {
  Get = 'get',
  Delete = 'delete',
  Post = 'post',
  Put = 'put',
  Patch = 'patch',
}

export enum Action {
  Create = 'created',
  Update = 'updated',
  Delete = 'deleted',
  Start = 'started',
  Stop = 'stopped',
}

const getIntl = (): IntlShape => {
  const locale: string = getLocale(localMessages, DEFAULT_LOCALE)
  const messages = flattenMessages(localMessages[locale]) as any
  const cache = createIntlCache()

  return createIntl(
    {
      locale,
      messages,
    },
    cache
  )
}

type PrimitiveType = string | number | boolean | null | undefined | Date

// Custom Intl to use outside react components
export const tr = (
  msg: MessageDescriptor,
  values?: Record<string, PrimitiveType | JSX.Element>
) => getIntl().formatMessage(msg, values) as string

// Check if a specific locale code exists
export const trCheck = (msg: MessageDescriptor): boolean => {
  return !!getIntl().messages[`${msg.id}`]
}

export const handleApiErrors = (
  error: AxiosError<IApiError>
): string[] | undefined => {
  logger.error('[handleApiErrorMessages]', error.response?.data)

  if (
    has(error, 'response.data.validationsErrors') &&
    !isNull(error.response!.data.validationsErrors)
  ) {
    if (error.response?.data.statusCode === HttpStatus.unauthorized) {
      return [error.response.data.error]
    }

    return error.response!.data.validationsErrors
  }

  return undefined
}

// Converts hours, minutes, seconds into seconds
export const toSeconds = (
  hours: number,
  minutes: number,
  seconds: number
): number => {
  return +hours * 60 * 60 + +minutes * 60 + +seconds
}

export enum BuffTransportType {
  BuffQueued = 'buff_queued',
  BuffPublished = 'buff_published',
  BuffExpired = 'buff_expired',
  BuffResolved = 'buff_resolved',
  BuffVoted = 'buff_voted',
}

export enum PubNubEvent {
  published = 'published',
  expired = 'expired',
  resolved = 'resolved',
}

export enum FormDestinationType {
  Buff = 'buff',
  Template = 'template',
}

export type IdOrIndex = number

// This enum allows us to generate diferent sizes for each of the values
export enum ImageType {
  None = 0,
  BuffAnswer = 1,
  StreamLogo = 2,
  StreamBackground = 3,
  AuthorPhoto = 6,
}

export type DayHoursMinutesSecondsMiliseconds = {
  days: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

// generate the crap data.data from api/axios (used in tests)
export const genApiResponse = <T>(response: T) => ({
  data: {
    data: response,
  },
})

export type Uuid = string

export const platformOptions = [
  { key: 'all', value: 'All' },
  { key: 'android', value: 'Android' },
  { key: 'androidTV', value: 'Android TV' },
  { key: 'iOS', value: 'iOS' },
  { key: 'smartTV', value: 'Smart TV' },
  { key: 'web', value: 'Web' },
]

export const userGroupOptions = [
  { key: 'all', value: 'All' },
  { key: 'loggedIn', value: 'Logged in' },
  { key: 'anonymous', value: 'Anonymous' },
]
