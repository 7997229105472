import { cloneDeep } from 'lodash'
import {
  Engagement,
  EngagementAnswer,
  RawEngagement,
  RawEngagementAnswer,
} from '@interfaces/Engagement'

export const transformRawEngagementToEngagement = (
  originalEngagement: RawEngagement
): Engagement => {
  const clone = cloneDeep(originalEngagement)

  const perAnswer = clone.perAnswer.reduce(
    (acc: Record<string, EngagementAnswer>, content: RawEngagementAnswer) => {
      const totalVotes = originalEngagement.votesCounted
      const percentage = totalVotes
        ? ((content?.votesCounted ?? 0) / totalVotes) * 100
        : 0
      const roundedPercentage = Math.round(percentage * 10) / 10

      acc[content.answerId] = {
        ...content,
        percentage: roundedPercentage,
      }
      return acc
    },
    {}
  )

  const engagement: Engagement = {
    ...clone,
    perAnswer,
  }

  return engagement
}
