import { ILanguage, LanguageCode } from '@interfaces/ILanguage'

// Ordered by number of speakers
export const LanguagesMap: Record<LanguageCode, ILanguage> = {
  [LanguageCode.en]: {
    name: 'English',
    iso6391: LanguageCode.en,
    id: 1,
  },
  [LanguageCode.es]: {
    name: 'Español',
    iso6391: LanguageCode.es,
    id: 2,
  },
  [LanguageCode.ar]: {
    name: 'عربي',
    iso6391: LanguageCode.ar,
    id: 3,
  },
  [LanguageCode.pt]: {
    name: 'Português',
    iso6391: LanguageCode.pt,
    id: 4,
  },
  [LanguageCode.fr]: {
    name: 'Français',
    iso6391: LanguageCode.fr,
    id: 5,
  },
  [LanguageCode.de]: {
    name: 'Deutsch',
    iso6391: LanguageCode.de,
    id: 6,
  },
  [LanguageCode.it]: {
    name: 'Italiano',
    iso6391: LanguageCode.it,
    id: 7,
  },
  [LanguageCode.nl]: {
    name: 'Dutch',
    iso6391: LanguageCode.nl,
    id: 8,
  },
  [LanguageCode.id]: {
    name: 'Indonesian',
    iso6391: LanguageCode.id,
    id: 9,
  },
  [LanguageCode.pl]: {
    name: 'język polski',
    iso6391: LanguageCode.pl,
    id: 10,
  },
  [LanguageCode.hu]: {
    name: 'Magyar',
    iso6391: LanguageCode.hu,
    id: 11,
  },
  [LanguageCode.el]: {
    name: 'Ελληνικά',
    iso6391: LanguageCode.el,
    id: 12,
  },
  [LanguageCode.he]: {
    name: 'עברית',
    iso6391: LanguageCode.he,
    id: 13,
  },
  [LanguageCode.ms]: {
    name: 'هاس ملايو',
    iso6391: LanguageCode.ms,
    id: 14,
  },
  [LanguageCode.no]: {
    name: 'Norsk',
    iso6391: LanguageCode.no,
    id: 15,
  },
  [LanguageCode.bg]: {
    name: 'български',
    iso6391: LanguageCode.bg,
    id: 16,
  },
  [LanguageCode.sr]: {
    name: 'Srpski',
    iso6391: LanguageCode.sr,
    id: 17,
  },
  [LanguageCode.lb]: {
    name: 'Lëtzebuergesch',
    iso6391: LanguageCode.lb,
    id: 18,
  },
}

export const getLanguagesMetadata = (
  languages: LanguageCode[]
): ILanguage[] => {
  return languages.map((langCode) => {
    return LanguagesMap[langCode]
  })
}
