import React, { FC, useContext } from 'react'
import { AssetContext } from '@services/providers/AssetProvider'
import UploadingAsset from '@components/organisms/AssetManager/UploadingAsset'
import { isFileTypeSupported } from '@utils/asset'

interface UploadingAssetList {
  onRetry: (id: string) => void
}

const UploadingAssetList: FC<React.PropsWithChildren<UploadingAssetList>> = ({
  onRetry,
}) => {
  const { state } = useContext(AssetContext)

  const assets = state.uploadableAssets.filter(
    (asset) =>
      !asset.sizeError && isFileTypeSupported(asset.image, asset.accept)
  )

  return (
    <div>
      {Array.from(assets).map((asset) => {
        return (
          <div key={asset.id}>
            <UploadingAsset asset={asset} onRetry={onRetry} />
          </div>
        )
      })}
    </div>
  )
}

export default UploadingAssetList
