import React, { FC, useMemo } from 'react'
import { Form, Formik } from 'formik'

import { useChat } from '@services/requests/chat'
import { tr } from '@constants/other'
import Button from '@components/atoms/Button'
import TextInput from '@components/atoms/TextInput'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'
import { ClientChatFormValues } from '@interfaces/Chat'
import TextAreaInput from '@components/atoms/TextAreaInput'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import SelectInput from '@components/atoms/SelectInput'
import Switch from '@components/atoms/Switch'

interface Props {
  onSubmit: (values: ClientChatFormValues) => Promise<void>
}

const defaultInitialValues: ClientChatFormValues = {
  wordList: '',
  action: 'none',
  maskingCharacter: '*',
  moderateUrls: false,
}

const profanityFilterOptions = [
  { key: 'none', value: 'None' },
  { key: 'mask', value: 'Mask word' },
  { key: 'drop', value: 'Drop message' },
]

const TextModerationForm: FC<Props> = ({ onSubmit }) => {
  const { useGetModeration } = useChat()
  const { data: moderationData } = useGetModeration()

  const initialValues = useMemo(() => {
    return {
      ...defaultInitialValues,
      wordList: moderationData?.wordList?.join('\n') ?? '',
      action: moderationData?.action,
      maskingCharacter: moderationData?.maskingCharacter,
      moderateUrls: moderationData?.moderateUrls,
    }
  }, [
    moderationData?.action,
    moderationData?.maskingCharacter,
    moderationData?.wordList,
    moderationData?.moderateUrls,
  ]) as ClientChatFormValues

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      validateOnChange
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, setFieldValue }) => {
        return (
          <Form className="flex flex-col gap-y-4">
            <div className="p-4 rounded-lg bg-white-background">
              <fieldset className="flex flex-col gap-y-3">
                <div className="mb-3">
                  <h1 className="m-0 mb-2 text-xl font-bold">
                    {tr({ id: 'globalChat.textModeration' })}
                  </h1>
                  <p className="text-[#586069] text-sm">
                    {tr({ id: 'globalChat.textModerationDescription' })}
                  </p>
                </div>

                <div className="flex items-center">
                  <TextAreaInput
                    containerClasses="w-full min-h-[200px]"
                    id="wordList"
                    rows={8}
                    label={
                      <div className="flex items-center justify-between">
                        <label className="m-0 mr-1">
                          {tr({ id: 'globalChat.wordList' })}
                        </label>
                        <Tooltip placement="right">
                          <TooltipTrigger>
                            <div className="flex items-center gap-x-2 cursor-pointer">
                              <InfoCircleIcon className="w-4 h-4" />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <span>
                              {tr({
                                id: 'globalChat.wordListDesc',
                              })}
                            </span>
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    }
                    value={values.wordList}
                    onChange={handleChange}
                    placeholder={tr({
                      id: 'globalChat.wordListPlaceholder',
                    })}
                  />
                </div>

                <div className="flex items-center">
                  <SelectInput
                    containerClasses="w-full"
                    label={
                      <div className="flex items-center justify-between">
                        <label className="m-0 mr-1">
                          {tr({ id: 'globalChat.profanityDetection' })}
                        </label>
                        <Tooltip placement="right">
                          <TooltipTrigger>
                            <div className="flex items-center gap-x-2 cursor-pointer">
                              <InfoCircleIcon className="w-4 h-4" />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <span>
                              {tr({
                                id: 'globalChat.profanityDesc',
                              })}
                            </span>
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    }
                    value={values.action}
                    id="action"
                    name="action"
                    onChange={handleChange}
                    options={profanityFilterOptions}
                  />
                </div>

                <div className="flex items-center">
                  <TextInput
                    containerClasses="w-full"
                    id="maskingCharacter"
                    type="text"
                    label={
                      <div className="flex items-center justify-between">
                        <label className="m-0 mr-1">
                          {tr({ id: 'globalChat.setMasking' })}
                        </label>
                        <Tooltip placement="right">
                          <TooltipTrigger>
                            <div className="flex items-center gap-x-2 cursor-pointer">
                              <InfoCircleIcon className="w-4 h-4" />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <span>
                              {tr({
                                id: 'globalChat.maskingDesc',
                              })}
                            </span>
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    }
                    value={values.maskingCharacter}
                    onChange={handleChange}
                    placeholder="*"
                  />
                </div>
                <div>
                  <Switch
                    name="moderateUrls"
                    text={tr({
                      id: 'globalChat.moderateUrls',
                    })}
                    isChecked={values.moderateUrls}
                    onToggle={(e) => {
                      setFieldValue('moderateUrls', e.target.checked)
                    }}
                  />
                </div>
              </fieldset>
            </div>

            <Button className="w-fit" type="submit">
              {tr({ id: 'generic.update' })}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default TextModerationForm
