import { useEffect } from 'react'
import { cloneDeep } from 'lodash'
import Sortable from 'sortablejs'
import { ReorderAnswersFormValues, ReorderAnswersProps } from './types'

const ReorderAnswers = <T extends ReorderAnswersFormValues>({
  values,
  setFieldValue,
}: ReorderAnswersProps<T>) => {
  useEffect(() => {
    let sortable: Sortable
    const list = document.getElementById('buff-answers')
    if (list) {
      sortable = new Sortable(list, {
        animation: 350,
        handle: '.buff-answer-handle',
        swapThreshold: 0.65,
        ghostClass: 'sortable-ghost',
        onEnd: (e) => {
          if (e.oldIndex == null || e.newIndex == null) {
            setFieldValue('answers', values.answers)
            return
          }

          const item = values.answers[e.oldIndex]
          const answers = cloneDeep(values.answers)
          answers.splice(e.oldIndex, 1)
          answers.splice(e.newIndex, 0, item)
          setFieldValue('answers', answers)
        },
      })
    }
    return () => sortable?.destroy?.()
  }, [values, setFieldValue])

  return null
}

export default ReorderAnswers
