import React, { FC, useState, CSSProperties, useEffect } from 'react'
import { HexColorPicker } from 'react-colorful'
import { tr } from '@constants/other'
import ContrastPill, {
  ContrastPillVariant,
} from '@components/atoms/ContrastPill'
import Button from '@components/atoms/Button'
import { ColorPickerProps, ColorPickerType } from './types'

const MAX_USER_COLOURS = 5

const getUserStoredColours = (type: ColorPickerType) => {
  const userColorsLocalStorage = localStorage.getItem(
    `color-picker-user-colours-${type}`
  )
  try {
    if (userColorsLocalStorage) {
      const colourData = JSON.parse(userColorsLocalStorage)
      if (Array.isArray(colourData) && colourData.length <= MAX_USER_COLOURS) {
        return colourData
      }
    }
  } catch (e) {}

  // Default to white when user has not saved anything
  return ['#ffffff']
}

const hexRegex = /^(#)((?:[A-Fa-f0-9]{3}){1,2})$/

const ColorPicker: FC<React.PropsWithChildren<ColorPickerProps>> = ({
  onChange,
  type,
  color,
  onChangeAll,
}) => {
  const [userColors, setUserColors] = useState<string[]>(
    getUserStoredColours(type)
  )
  const [inputTouched, setInputTouched] = useState<boolean>(false)
  const [activePill, setActivePill] = useState<number>(() => {
    const index = userColors.findIndex((userColor) => userColor === color)
    return index === -1 ? 0 : index
  })

  const pillCount = userColors.length
  const displayAddColorButton = pillCount < MAX_USER_COLOURS
  const colorPickerValue = userColors[activePill]

  const isValidColour = hexRegex.test(colorPickerValue)

  const wrapperStyles: CSSProperties = {
    zIndex: 100,
    maxWidth: '156px',
    boxShadow: '0 5px 15px 0 rgba(9, 14, 37, 0.1)',
  }

  const handleColorChange = (color: string) => {
    setUserColors((colors) => {
      const newArray = [...colors]
      newArray[activePill] = color
      return newArray
    })
  }

  const handleApplyAll = () => {
    onChangeAll && onChangeAll(colorPickerValue)
  }

  const handleResetAll = () => {
    onChangeAll && onChangeAll('')
  }

  const inputStyles: CSSProperties = {
    fontSize: '14px',
  }

  const sharedButtonStyles = 'text-sm bg-transparent border-none py-1'

  if (
    !isValidColour &&
    ((colorPickerValue.trim() === '' && inputTouched) ||
      colorPickerValue.trim().length !== 0)
  ) {
    inputStyles.borderColor = 'red'
  }

  useEffect(() => {
    const parsedColors = userColors.filter((color) => {
      return typeof color === 'string' && color !== ''
    })
    localStorage.setItem(
      `color-picker-user-colours-${type}`,
      JSON.stringify(parsedColors)
    )
  }, [userColors, type])

  return (
    <div
      className="flex flex-col absolute pt-[8px] px-[8px] bg-white rounded"
      style={wrapperStyles}
    >
      <div>
        <HexColorPicker
          className="colorPicker"
          color={colorPickerValue}
          onChange={handleColorChange}
        />
      </div>
      <div>
        <div className="flex items-center py-2">
          <input
            style={inputStyles}
            className={`form-control mr-1`}
            value={colorPickerValue}
            onChange={(e) => {
              const value = e.target.value
              setInputTouched(true)
              setUserColors((colors) => {
                const newArray = [...colors]
                newArray[activePill] = value
                return newArray
              })
            }}
          />
          <Button
            size="small"
            disabled={!isValidColour}
            onClick={() => {
              onChange(colorPickerValue)
            }}
          >
            {tr({ id: 'generic.set' })}
          </Button>
        </div>

        <div className="pb-2 flex items-center justify-start">
          {userColors.map((color, i) => {
            return (
              <ContrastPill
                key={i}
                variant={ContrastPillVariant.COLOR}
                color={color}
                isActive={activePill === i}
                onClick={() => {
                  setInputTouched(false)
                  setActivePill(i)
                }}
              />
            )
          })}
          {displayAddColorButton && (
            <div>
              <ContrastPill
                variant={ContrastPillVariant.ADD}
                data-testid="contrast-pill-add"
                onClick={() => {
                  const newColours = [...userColors]
                  newColours.push('#ffffff')

                  setUserColors(newColours)
                  setActivePill(newColours.length - 1)
                }}
              />
            </div>
          )}
        </div>
      </div>

      <button
        className={`${sharedButtonStyles} text-[#017BFF]`}
        type="button"
        onClick={handleApplyAll}
        disabled={!isValidColour}
      >
        Apply to all
      </button>
      <button
        className={`${sharedButtonStyles} text-[#FA0301]`}
        type="button"
        style={{
          borderTop: '1px solid #E8EAF7',
        }}
        onClick={handleResetAll}
      >
        Reset all
      </button>
    </div>
  )
}

export default ColorPicker
