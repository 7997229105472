import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { noop, cloneDeep } from 'lodash'
import Button from '@components/atoms/Button'
import TextInput from '@components/atoms/TextInput'
import LanguageTabContent from '@components/molecules/LanguageTabContent'
import { LanguageContext } from '@services/providers/LanguageProvider'
import { tr } from '@constants/other'
import { LanguageCode } from '@interfaces/ILanguage'
import AssetManager from '@components/organisms/AssetManager'
import { AssetClass } from '@interfaces/Assets'
import { ImageFieldSize } from '@components/molecules/ImageFieldWithButtons'
import SponsorFormSection from '@components/molecules/SponsorFormSection'
import { getLangsFromObjectPath, getErrorFields } from '@utils/form'
import AutoPatchTouched from '@components/atoms/AutoPatchTouched'
import { getSchema } from './schema'
import {
  LeaderboardDetailsFormProps,
  LeaderboardDetailsFormValues,
  LeaderboardDetailsFormVariant,
} from './types'

const LeaderboardDetailsForm = ({
  variant,
  initialValues,
  availableLanguages,
  onSubmit = noop,
  onSecondaryActionClick,
  'data-testid': testId = 'leaderboard-details-form',
}: LeaderboardDetailsFormProps) => {
  const { clientLanguages } = useContext(LanguageContext)
  const [activeLanguage, setActiveLanguage] = useState<LanguageCode>(
    clientLanguages?.default ?? LanguageCode.en
  )
  const [selectedLanguages, setSelectedLanguages] = useState<LanguageCode[]>([
    clientLanguages?.default ?? LanguageCode.en,
  ])
  const [isAssetManagerOpen, setIsAssetManagerOpen] = useState(false)

  const validationSchema = getSchema(selectedLanguages, false)

  const primaryButtonText =
    variant === LeaderboardDetailsFormVariant.NEW
      ? 'Create'
      : tr({ id: 'generic.update' })

  const secondaryButtonText =
    variant === LeaderboardDetailsFormVariant.NEW
      ? tr({ id: 'generic.cancel' })
      : tr({ id: 'leaderboard.deleteLeaderboard' })

  const handleSubmit = (
    values: LeaderboardDetailsFormValues,
    helpers: FormikHelpers<LeaderboardDetailsFormValues>
  ) => {
    const preparedValues = cloneDeep(values)
    const unselectedLanguages = availableLanguages.filter(
      (lang) => !selectedLanguages.includes(lang)
    )

    // unselectedLanguages.forEach((lang) => {
    //   delete preparedValues.leaderboardTitle[lang]
    //   delete preparedValues.sponsor[lang]
    //   delete preparedValues.showSponsor[lang]
    // })

    // const langsToClearSponsors = Object.entries(
    //   preparedValues.showSponsor
    // ).filter(([key, value]) => {
    //   return value === false
    // })

    // langsToClearSponsors.forEach(([key]) => {
    //   const lang = key as Language
    //   delete preparedValues.sponsor[lang]
    // })

    return onSubmit(preparedValues, helpers)
  }

  useEffect(() => {
    if (!initialValues) return

    const activeLanguages = initialValues.content.languages

    setSelectedLanguages(
      activeLanguages?.length > 0
        ? activeLanguages
        : [clientLanguages?.default ?? LanguageCode.en]
    )
  }, [clientLanguages?.default, initialValues])

  useEffect(() => {
    if (!selectedLanguages) return
    if (!!activeLanguage) return

    setActiveLanguage(selectedLanguages?.[0])
  }, [selectedLanguages, activeLanguage])

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        setFieldTouched,
      }) => {
        // Only disabling on submit so user can click button to trigger errors showing
        const disableSubmitButton = isSubmitting

        const handleSelectedLanguagesChange = (langs: LanguageCode[]) => {
          setSelectedLanguages(langs)

          const removedLanguages = selectedLanguages.filter((selectedLang) => {
            return !langs.includes(selectedLang)
          })

          removedLanguages.forEach((removedLang) => {
            setFieldValue(`showSponsor.${activeLanguage}`, false)
          })
        }

        const tabErrors: Partial<Record<LanguageCode, boolean>> = {}

        const errorFields = getErrorFields(errors, touched)
        const errorLangs = [...new Set(errorFields.map(getLangsFromObjectPath))]

        errorLangs.forEach((lang) => {
          const language = lang as LanguageCode
          tabErrors[language] = true
        })

        return (
          <Form data-testid={testId} id="leaderboard-details-form">
            <div className="p-6 !mb-4 bg-white-background">
              <h4 className="h4 mb-4">
                {tr({ id: 'leaderboard.leaderboardSettings' })}
              </h4>

              <fieldset className="w-72 p-4 rounded-lg bg-white-background">
                <TextInput
                  id="leaderboardName"
                  label={tr({ id: 'leaderboard.leaderboardName' })}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.name)}
                  errorLabel={errors.name}
                  touched={touched.name}
                  placeholder={tr({ id: 'leaderboard.enterName' })}
                />
                <p className="text-[12px] m-0">
                  {tr({ id: 'leaderboard.visibleToDashboard' })}
                </p>
              </fieldset>
            </div>

            <LanguageTabContent
              availableLanguages={availableLanguages}
              activeLanguage={activeLanguage}
              selectedLanguages={selectedLanguages}
              onSelectedLanguagesChange={handleSelectedLanguagesChange}
              onLanguageChange={(lang) => setActiveLanguage(lang)}
              errors={tabErrors}
            >
              <>
                {/* <fieldset className="w-72">
                  <TextInput
                    id={`leaderboardTitle.${activeLanguage}`}
                    label={tr({ id: 'leaderboard.leaderboardTitle' })}
                    value={values.leaderboardTitle[activeLanguage]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.leaderboardTitle?.[activeLanguage])}
                    errorLabel={errors.leaderboardTitle?.[activeLanguage]}
                    touched={touched.leaderboardTitle?.[activeLanguage]}
                    placeholder={tr({ id: 'leaderboard.enterTitle' })}
                  />
                  <p className="text-[12px] m-0">
                    {tr({ id: 'leaderboard.visibleToAll' })}
                  </p>
                </fieldset>

                <SponsorFormSection
                  values={values}
                  errors={errors}
                  activeLanguage={activeLanguage}
                  touched={touched}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  onAddSponsorImageClick={() => setIsAssetManagerOpen(true)}
                  imageSize={ImageFieldSize.LEADERBOARD_SPONSOR}
                /> */}
              </>
            </LanguageTabContent>

            {isAssetManagerOpen && (
              <AssetManager
                isOpen={isAssetManagerOpen}
                type={AssetClass.LeaderboardSponsor}
                onSelect={([image]) => {
                  const imageUrl = image.publicURL + image.fileName
                  setFieldValue(`sponsor.${activeLanguage}.imageUrl`, imageUrl)
                }}
                onClose={() => setIsAssetManagerOpen(false)}
              />
            )}

            <footer className="flex pb-4 justify-end">
              {variant === LeaderboardDetailsFormVariant.NEW && (
                <Button
                  variant="secondary"
                  className="mr-4 min-w-[82px] hover:!bg-[#ab0101] hover:!text-white hover:border-none"
                  onClick={onSecondaryActionClick}
                >
                  {secondaryButtonText}
                </Button>
              )}

              <Button
                disabled={disableSubmitButton}
                type="submit"
                variant="primary"
                className="min-w-[82px]"
              >
                {primaryButtonText}
              </Button>
            </footer>
            <AutoPatchTouched />
          </Form>
        )
      }}
    </Formik>
  )
}

export default LeaderboardDetailsForm
