import React, { FC, Fragment } from 'react'

const TeamMembersSkeleton: FC<React.PropsWithChildren<unknown>> = () => {
  const row = (
    <>
      <div className="h-7 bg-[#f1f4f8]" style={{ width: '15%' }} />
      <div className="h-7 bg-[#f1f4f8]" style={{ width: '15%' }} />
      <div className="h-7 bg-[#f1f4f8]" style={{ width: '10%' }} />
      <div className="h-7 bg-[#f1f4f8]" style={{ width: '20%' }} />
      <div className="flex items-center justify-between space-x-2 w-[10%]">
        <div className="h-7 bg-[#f1f4f8]" style={{ width: '50%' }} />
        <div className="h-7 bg-[#f1f4f8]" style={{ width: '50%' }} />
      </div>
    </>
  )

  const header = (
    <>
      <div className="h-7 bg-[#f1f4f8]" style={{ width: '15%' }} />
      <div className="h-7 bg-[#f1f4f8]" style={{ width: '15%' }} />
      <div className="h-7 bg-[#f1f4f8]" style={{ width: '10%' }} />
      <div className="h-7 bg-[#f1f4f8]" style={{ width: '20%' }} />
      <div style={{ width: '10%' }} />
    </>
  )

  return (
    <div className={`min-w-full`}>
      <div className="space-y-4 mb-4">
        {Array.from(new Array(9)).map((_, i) => (
          <Fragment key={`skeleton-${i}`}>
            <div className="flex items-center justify-between animate-pulse px-4 space-x-2">
              {i === 0 ? header : row}
            </div>

            {i === 0 && <div className="w-full h-[1px] bg-[#D1D5DA]" />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default TeamMembersSkeleton
