import React, {
  createContext,
  MutableRefObject,
  useEffect,
  useRef,
} from 'react'
import { Centrifuge } from 'centrifuge'

export interface MessagingContextProps {
  /**
   * Messaging client instance to subscribe to
   */
  client: MutableRefObject<Centrifuge | undefined>
}

export interface MessagingProviderProps {
  children?: React.ReactNode
}

export const MessagingContext = createContext<MessagingContextProps>(
  {} as MessagingContextProps
)

const clientName = process.env.ACCOUNT_NAME || 'develop'

export const MessagingProvider = ({ children }: MessagingProviderProps) => {
  const clientRef = useRef<Centrifuge>()

  const centrifugoClient =
    ['develop', 'qa'].indexOf(clientName) > -1 ? clientName : 'prod'

  const centrifugoURI = `wss://centrifugo.${centrifugoClient}.buffup.net/connection/websocket`

  useEffect(() => {
    clientRef.current = new Centrifuge(centrifugoURI as string, {
      debug: true,
    })
    clientRef.current.connect()
  }, [])

  return (
    <MessagingContext.Provider value={{ client: clientRef }}>
      {children}
    </MessagingContext.Provider>
  )
}
