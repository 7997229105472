import React, { FC, useCallback, useContext, useMemo, useRef } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { PlatformContext } from '@services/providers/PlatformProvider'
import { useGetAssets } from '@services/requests/assets'
import Switch from '@components/atoms/Switch'
import SelectInput from '@components/atoms/SelectInput'
import AutoSubmitForm from '@components/atoms/AutoSubmitForm'
import { useToast } from '@utils/hooks/useToast'
import { hmsStringToSeconds } from '@utils/time'
import { flattenInfiniteData } from '@utils/flattenInfiniteData'
import { DEFAULT_SECOND_SCREEN_DELAY_TIME, tr } from '@constants/other'
import { Asset, AssetClass, GetAssetsResponse } from '@interfaces/Assets'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'

import { AdvancedSettingsFormProps, AdvancedSettingsFormValues } from './types'
import './AdvancedSettingsForm.styles.scss'

const defaultInitialValues: AdvancedSettingsFormValues = {
  hideMenu: false,
  allowProfileEdit: false,
  hideProfilePicture: false,
  playVoteableSound: false,
  voteableSound: '',
  timeSync: false,
  secondScreenAppDelay: DEFAULT_SECOND_SCREEN_DELAY_TIME,
  welcomeBuffId: '',
  renderSportBuffUI: false,
  useLiveVoting: true,
  allowGamePoints: true,
}

const AdvancedSettingsForm: FC<
  React.PropsWithChildren<AdvancedSettingsFormProps>
> = ({
  initialValues = defaultInitialValues,
  onSubmit = () => {},
  showTimeSyncSection = true,
  showBuffConfigSection = true,
}) => {
  const { includesWeb } = useContext(PlatformContext)
  const { addToast, removeToast } = useToast()
  const toastIdRef = useRef<string>()
  const enabledText = tr({ id: 'generic.enabled' })
  const disabledText = tr({ id: 'generic.disabled' })

  const { data } = useGetAssets({ filter: AssetClass.BuffSound, limit: 100 })

  const assets = flattenInfiniteData<GetAssetsResponse, Asset>('assets', data)

  const soundOptions = useMemo(() => {
    return assets?.map((asset) => {
      return {
        value: asset.title,
        key: `${asset.publicURL}${asset.fileName}`,
      }
    })
  }, [assets])

  const handleSubmit = useCallback(
    (
      values: AdvancedSettingsFormValues,
      helpers: FormikHelpers<AdvancedSettingsFormValues>
    ) => {
      onSubmit(
        {
          ...values,
          secondScreenAppDelay: hmsStringToSeconds(
            values.secondScreenAppDelay?.toString()
          ),
        },
        helpers
      )
    },
    [onSubmit]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange
      validateOnBlur
      enableReinitialize
    >
      {({ values, handleChange }) => {
        return (
          <Form className="advanced-settings-form flex flex-col space-y-4">
            {showTimeSyncSection && (
              <fieldset
                className="p-4 rounded-lg bg-white-background"
                data-testid="time-sync"
              >
                <legend className="m-0 text-[1.125rem] font-bold float-left">
                  {tr({ id: 'advancedSettings.timeSync' })}
                </legend>

                <div className="clear-both pt-4">
                  <div className="flex items-center">
                    <label className="text-base mr-8 w-60" htmlFor="timeSync">
                      {tr({ id: 'advancedSettings.buffSync' })}
                    </label>
                    <Switch
                      textClassname="text-sm uppercase"
                      textPosition="right"
                      name="timeSync"
                      id="timeSync"
                      text={values.timeSync ? enabledText : disabledText}
                      isChecked={values.timeSync}
                      onToggle={(e) => {
                        if (e.target.checked) {
                          toastIdRef.current = addToast({
                            type: 'warning',
                            msg: tr({ id: 'advancedSettings.buffSyncWarning' }),
                            image: '/assets/icon-warning.png',
                            duration: 10000,
                          })
                        } else if (toastIdRef.current) {
                          removeToast(toastIdRef.current)
                        }
                        handleChange(e)
                      }}
                    />
                  </div>
                </div>
              </fieldset>
            )}

            <fieldset
              className="p-4 rounded-lg bg-white-background"
              data-testid="visibility"
            >
              <legend className="m-0 text-[1.125rem] font-bold float-left">
                {tr({ id: 'advancedSettings.visibility' })}
              </legend>

              <div className="clear-both pt-4 flex flex-col gap-y-4">
                <div className="flex items-start">
                  <div className="flex mr-8 w-60">
                    <label
                      className="flex mb-0 mr-2 items-center"
                      htmlFor="showUi"
                    >
                      {tr({ id: 'advancedSettings.showUi' })}
                    </label>
                    <Tooltip placement="right">
                      <TooltipTrigger>
                        <div className="flex items-center gap-x-2 cursor-pointer">
                          <InfoCircleIcon className="w-4 h-4" />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <span>
                          {tr({
                            id: 'advancedSettings.showUiDesc',
                          })}
                        </span>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  <Switch
                    textClassname="text-sm uppercase"
                    textPosition="right"
                    name="renderSportBuffUI"
                    id="renderSportBuffUI"
                    text={values.renderSportBuffUI ? enabledText : disabledText}
                    isChecked={values.renderSportBuffUI}
                    onToggle={handleChange}
                  />
                </div>
                {includesWeb && (
                  <div className="flex items-start">
                    <label className="text-base mr-8 w-60" htmlFor="hideMenu">
                      {tr({ id: 'advancedSettings.autoHideMenu' })}
                      <br />
                      <small>(Web SDK only)</small>
                    </label>
                    <Switch
                      textClassname="text-sm uppercase"
                      textPosition="right"
                      name="hideMenu"
                      id="hideMenu"
                      text={values.hideMenu ? enabledText : disabledText}
                      isChecked={values.hideMenu}
                      onToggle={handleChange}
                    />
                  </div>
                )}
                <div className="flex items-center">
                  <label
                    className="text-base mr-8 w-60"
                    htmlFor="allowProfileEdit"
                  >
                    {tr({ id: 'advancedSettings.allowEditProfile' })}
                  </label>
                  <Switch
                    textClassname="text-sm uppercase"
                    textPosition="right"
                    id="allowProfileEdit"
                    name="allowProfileEdit"
                    text={values.allowProfileEdit ? enabledText : disabledText}
                    isChecked={values.allowProfileEdit}
                    onToggle={handleChange}
                  />
                </div>
              </div>
            </fieldset>

            {showBuffConfigSection && (
              <fieldset
                className="p-4 rounded-lg bg-white-background"
                data-testid="buff-config-settings"
              >
                <legend className="m-0 text-[1.125rem] font-bold float-left">
                  {tr({ id: 'advancedSettings.buffConfig' })}
                </legend>
                <div className="clear-both pt-4 flex flex-col gap-y-4">
                  <div className="flex items-start">
                    <div className="flex mr-8 w-60">
                      <label
                        className="flex mb-0 mr-2 items-center"
                        htmlFor="useLiveVoting"
                      >
                        {tr({ id: 'advancedSettings.liveVoting' })}
                      </label>
                      <Tooltip placement="bottom">
                        <TooltipTrigger>
                          <div className="flex items-center gap-x-2 cursor-pointer">
                            <InfoCircleIcon className="w-4 h-4" />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <span>
                            {tr({
                              id: 'advancedSettings.liveVotingDesc',
                            })}
                          </span>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    <Switch
                      textClassname="text-sm uppercase"
                      textPosition="right"
                      name="useLiveVoting"
                      id="useLiveVoting"
                      text={values.useLiveVoting ? enabledText : disabledText}
                      isChecked={values.useLiveVoting}
                      onToggle={handleChange}
                    />
                  </div>

                  <div className="flex items-start">
                    <div className="flex mr-8 w-60">
                      <label
                        className="flex mb-0 mr-2 items-center"
                        htmlFor="allowGamePoints"
                      >
                        {tr({ id: 'advancedSettings.gamePoints' })}
                      </label>
                      <Tooltip placement="bottom">
                        <TooltipTrigger>
                          <div className="flex items-center gap-x-2 cursor-pointer">
                            <InfoCircleIcon className="w-4 h-4" />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <span>
                            {tr({
                              id: 'advancedSettings.gamePointsDesc',
                            })}
                          </span>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    <Switch
                      textClassname="text-sm uppercase"
                      textPosition="right"
                      name="allowGamePoints"
                      id="allowGamePoints"
                      text={values.allowGamePoints ? enabledText : disabledText}
                      isChecked={values.allowGamePoints}
                      onToggle={handleChange}
                    />
                  </div>
                </div>
              </fieldset>
            )}

            <fieldset
              className="p-4 rounded-lg bg-white-background"
              data-testid="sound-settings"
            >
              <legend className="m-0 text-[1.125rem] font-bold float-left">
                {tr({ id: 'advancedSettings.sounds' })}
              </legend>

              <div className="clear-both pt-4 flex flex-col gap-y-4">
                <div className="flex items-center">
                  <label
                    className="text-base mr-8 w-60"
                    htmlFor="playVoteableSound"
                  >
                    {tr({ id: 'advancedSettings.playSounds' })}
                  </label>
                  <Switch
                    id="playVoteableSound"
                    name="playVoteableSound"
                    textClassname="text-sm uppercase"
                    textPosition="right"
                    data-testid="sound__switch"
                    text={values.playVoteableSound ? enabledText : disabledText}
                    isChecked={values.playVoteableSound}
                    onToggle={handleChange}
                  />
                </div>

                <div className="flex items-center">
                  <label
                    className="text-base mr-8 w-60"
                    htmlFor="voteableSound"
                  >
                    {tr({ id: 'advancedSettings.selectSound' })}
                  </label>
                  <SelectInput
                    value={values.voteableSound}
                    disabled={!values.playVoteableSound}
                    id="voteableSound"
                    name="voteableSound"
                    data-testid="sound__select"
                    onChange={handleChange}
                    options={soundOptions}
                  />
                </div>
              </div>
            </fieldset>

            <AutoSubmitForm addTouchedOnError={true} />
          </Form>
        )
      }}
    </Formik>
  )
}

export default AdvancedSettingsForm
