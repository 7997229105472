import cloneDeep from 'lodash/cloneDeep'
import { IOnboard } from '@interfaces/IOnboard'
import { LanguageCode } from '@interfaces/ILanguage'
import { VoteableLayout } from '@interfaces/Voteable'
import {
  CreateVoteableForm,
  CreateVoteableAnswerForm,
} from '@interfaces/Voteable'
import {
  LocalisedAuthorFormValue,
  LocalisedFormValue,
  LocalisedSponsorContent,
  LocalisedSponsorFormValue,
  LocalisedFormVoteableContent,
} from '@interfaces/localisation'
import { DEFAULT_EXPIRY_TIME } from '@constants/form'
import { getBlankInitialValues } from '@utils/buffForm/shared'
import { answerInterfaceMapToBuffType } from '@utils/buffTypeMappings'
import { v4 } from 'uuid'

const DEFAULT_DURATION = 15
const DEFAULT_SCHEDULE_SEND = '000:00:00'

interface GetWelcomeBuffInitialValuesArgs {
  template?: IOnboard
  languages: LanguageCode[]
}

export const getWelcomeBuffInitialValues = ({
  template,
  languages,
}: GetWelcomeBuffInitialValuesArgs): CreateVoteableForm => {
  const initialQuestionValue: LocalisedFormValue = {}
  const initialSponsorValue: LocalisedSponsorFormValue = {}
  const initialAuthorValue: LocalisedAuthorFormValue = {}
  const showSponsor: Partial<Record<LanguageCode, boolean>> = {}

  if (!template) {
    return getBlankInitialValues({ languages, isWelcomeBuff: true })
  }

  template?.sponsor?.localisations?.forEach(
    (localisedSponsor: LocalisedSponsorContent) => {
      const { imageUrl, linkTarget } = localisedSponsor
      initialSponsorValue[localisedSponsor.localisation] = {
        imageUrl,
        linkTarget,
        imageAltText: localisedSponsor.imageAltText,
        localisation: localisedSponsor.localisation,
        text: localisedSponsor.text,
      }

      showSponsor[localisedSponsor.localisation] =
        Boolean(imageUrl) || Boolean(linkTarget)
    }
  )

  template?.author?.localisations.forEach((localisedAuthor) => {
    initialAuthorValue[localisedAuthor.localisation] = {
      text: localisedAuthor.text,
      imageUrl: localisedAuthor.imageUrl || '',
    }
  })

  template?.question?.localisations.forEach((localisedQuestion) => {
    initialQuestionValue[localisedQuestion.localisation] =
      localisedQuestion.text
  })

  const answers: CreateVoteableAnswerForm[] = template.answers.map((answer) => {
    const answerContent: Partial<
      Record<LanguageCode, LocalisedFormVoteableContent>
    > = {}
    answer.localisations.forEach((localisedAnswer) => {
      answerContent[localisedAnswer.localisation] = {
        text: localisedAnswer.text,
        backgroundColor: localisedAnswer.backgroundColor,
        imageUrl: localisedAnswer.imageUrl,
        localisation: localisedAnswer.localisation,
        textColor: localisedAnswer.textColor,
      }
    })

    return {
      correct: answer.correct,
      id: answer.id || v4(),
      points: answer.points,
      paymentOptions: answer?.paymentOptions,
      localisations: cloneDeep(answerContent),
    }
  })

  const initialValuesFromTemplate = {
    answers,
    showSponsor,
    type: answerInterfaceMapToBuffType[
      template.answerInterface as keyof typeof answerInterfaceMapToBuffType
    ],
    layout: template?.layout ?? VoteableLayout.LAYOUT_HORIZONTAL,
    displayDurationSeconds:
      template?.displayDurationSeconds || DEFAULT_DURATION,
    expiresIn: DEFAULT_EXPIRY_TIME,
    schedule: DEFAULT_SCHEDULE_SEND,
    author: initialAuthorValue,
    question: initialQuestionValue,
    sponsor: initialSponsorValue,
    participationPoints: template?.participationPoints,
    localisations: template?.localisations,
  }

  return initialValuesFromTemplate
}
