import { number, string, object, array, boolean, AnyObjectSchema } from 'yup'
import { LanguageCode } from '@interfaces/ILanguage'
import { BuffFormType } from '@interfaces/BuffForm'
import { tr } from '@constants/other'
import {
  BUFF_EXPIRY_DURATION_MIN_GAP,
  BUFF_EXPIRY_DURATION_MIN_GAP_TIME_SYNC,
  BUFF_MAX_DURATION,
  BUFF_MIN_DURATION,
} from '@constants/form'
import { hmsStringToSeconds } from '@utils/time'
import { getSponsorFormSectionSchema } from '@components/molecules/SponsorFormSection/schema'
import { ObjectShape } from 'yup/lib/object'

const baseBuffFormSchema = (
  isWelcomeBuff: boolean,
  isTimeSyncStream: boolean = false
) => {
  const durationKey = isWelcomeBuff
    ? 'displayDurationSeconds'
    : 'voteDurationSeconds'

  const key = isTimeSyncStream
    ? 'validation.expiryDurationGapTimeSync'
    : 'validation.expiryDurationGap'

  return object().shape({
    type: number().required(tr({ id: 'validation.required' })),
    [durationKey]: number()
      .typeError(tr({ id: 'validation.required' }))
      .required(tr({ id: 'validation.required' }))
      .min(BUFF_MIN_DURATION, tr({ id: 'validation.minDuration' }))
      .max(BUFF_MAX_DURATION, tr({ id: 'validation.maxDuration' })),
    ...(!isWelcomeBuff && {
      resultsDurationSeconds: number()
        .typeError(tr({ id: 'validation.required' }))
        .required(tr({ id: 'validation.required' }))
        .min(BUFF_MIN_DURATION, tr({ id: 'validation.minDuration' }))
        .max(BUFF_MAX_DURATION, tr({ id: 'validation.maxDuration' })),
    }),
    expiresIn: string()
      .required(tr({ id: 'validation.required' }))
      .matches(/\d{3}:\d{2}:\d{2}/, tr({ id: 'validation.expiryHHMMSSFormat' }))
      .test('has-s-gap', tr({ id: key }), (value, context) => {
        if (!value) return false
        const duration = context?.parent[durationKey]
        const expiresAt = hmsStringToSeconds(value)
        const gap = isTimeSyncStream
          ? BUFF_EXPIRY_DURATION_MIN_GAP_TIME_SYNC
          : BUFF_EXPIRY_DURATION_MIN_GAP
        const minExpiry = duration + gap
        return expiresAt >= minExpiry
      }),
    schedule: string().required(tr({ id: 'validation.required' })),
  })
}

const getAnswerSchema = (languages: LanguageCode[], type: BuffFormType) => {
  const localisedContentShape: ObjectShape = {}
  languages.forEach((lang) => {
    localisedContentShape[lang] = object().shape({
      text: string().required(tr({ id: 'validation.required' })),
    })
  })

  switch (type) {
    case BuffFormType.Quiz:
      return object().shape({
        localisations: object().shape(localisedContentShape),
        points: number().typeError(tr({ id: 'validation.mustBeNumber' })),
        correct: boolean(),
        textColor: string(),
        backgroundColor: string(),
      })

    case BuffFormType.Vote:
    case BuffFormType.Poll:
    case BuffFormType.EmojiSlider:
      return object().shape({
        localisations: object().shape(localisedContentShape),
        textColor: string(),
        backgroundColor: string(),
      })

    case BuffFormType.Prediction:
      return object().shape({
        localisations: object().shape(localisedContentShape),
        points: number()
          .required(tr({ id: 'validation.mustBeNumber' }))
          .typeError(tr({ id: 'validation.mustBeNumber' }))
          .min(1, tr({ id: 'validation.min1' })),
        textColor: string(),
        backgroundColor: string(),
      })

    case BuffFormType.StarRating:
      return object().shape({}).nullable()

    default:
      throw new Error('Cannot find answer schema')
  }
}

interface GetSchemaArgs {
  languages: LanguageCode[]
  isWelcomeBuff?: boolean
  isTemplate?: boolean
  isTimeSyncStream?: boolean
}

export const getSchema = ({
  languages,
  isWelcomeBuff = false,
  isTemplate = false,
  isTimeSyncStream = false,
}: GetSchemaArgs): AnyObjectSchema => {
  const questionSchema: ObjectShape = {}
  const authorSchema: ObjectShape = {}

  languages.forEach((lang) => {
    questionSchema[lang] = string().required(tr({ id: 'validation.required' }))
  })

  const schemaWithQuestion = object()
    .shape({
      question: object().shape(questionSchema),
    })
    .concat(baseBuffFormSchema(isWelcomeBuff, isTimeSyncStream))

  languages.forEach((lang) => {
    authorSchema[lang] = object().shape({
      text: string().required(tr({ id: 'validation.required' })),
      imageUrl: string().required(tr({ id: 'validation.required' })),
    })
  })
  const schemaWithAuthor = object()
    .shape({
      author: object().shape(authorSchema),
    })
    .concat(schemaWithQuestion)

  const schema = object()
    .concat(schemaWithAuthor)
    .concat(getSponsorFormSectionSchema(languages))
    .shape({
      answers: array().when('type', (type: BuffFormType) => {
        const answerSchema = getAnswerSchema(languages, type)
        const newSchema = array()
          .of(answerSchema)
          .min(2, tr({ id: 'validation.answers.min' }))
          .max(5, tr({ id: 'validation.answers.max' }))

        if (type === BuffFormType.StarRating) {
          return answerSchema
        }

        if (type !== BuffFormType.Quiz) {
          return newSchema
        }

        return newSchema
          .test(
            'correct-answers',
            tr({ id: 'validation.answers.correct' }),
            (answers) => {
              const correctAnswers =
                answers?.filter(
                  (answer) =>
                    answer?.correct && answer?.points && answer?.points > 0
                ) || []
              return correctAnswers.length > 0
            }
          )
          .required()
      }),
    })

  if (isTemplate) {
    return object().shape({
      type: number().required(tr({ id: 'validation.required' })),
      author: object().shape(authorSchema),
      voteDurationSeconds: number()
        .typeError(tr({ id: 'validation.required' }))
        .required(tr({ id: 'validation.required' }))
        .min(BUFF_MIN_DURATION, tr({ id: 'validation.minDuration' }))
        .max(BUFF_MAX_DURATION, tr({ id: 'validation.maxDuration' })),
      resultsDurationSeconds: number()
        .typeError(tr({ id: 'validation.required' }))
        .required(tr({ id: 'validation.required' }))
        .min(BUFF_MIN_DURATION, tr({ id: 'validation.minDuration' }))
        .max(BUFF_MAX_DURATION, tr({ id: 'validation.maxDuration' })),
    })
  }

  return schema
}

export const getSchemaWithoutLang = (
  args: Omit<GetSchemaArgs, 'languages'>
) => {
  return (languages: LanguageCode[]) => {
    return getSchema({
      ...args,
      languages,
    })
  }
}
