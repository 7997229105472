/**
 * Converts a hms time string to seconds
 *
 * @param {string} time Time string pattern 'hh:mm:ss'
 * @return {number | false}
 */
export const hmsStringToSeconds = (time: string): number => {
  const [hours, minutes, seconds] = time.split(':')

  if (!hours || !minutes || !seconds) return 0

  const hrs = parseInt(hours, 10)
  const mins = parseInt(minutes, 10)
  const secs = parseInt(seconds, 10)

  return hrs * 60 * 60 + mins * 60 + secs
}

/**
 * Converts ms to string formatted in hh:mm:ss
 * @param {number} duration number of ms
 * @return {string} the formatted string
 */
export const msToHHMMSS = (duration: number): string => {
  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const hours = Math.floor(duration / (1000 * 60 * 60))

  const hoursStr = hours < 10 ? `00${hours}` : hours < 100 ? `0${hours}` : hours
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes
  const secondsStr = seconds < 10 ? `0${seconds}` : seconds

  return `${hoursStr}:${minutesStr}:${secondsStr}`
}
