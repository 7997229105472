import React, {
  FC,
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { getStreamStatus } from '@utils/stream'
import { FormikProps, FormikHelpers } from 'formik'
import { Author } from '@interfaces/Author'
import { IStream, StreamStatus } from '@interfaces/IStream'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import { announcementService } from '@services/index'
import { tr } from '@constants/other'
import RightAside from '@components/organisms/BuffPageAside'
import Container from '@components/atoms/Container'
import { BuffContext, IRouteParams } from '@services/providers/BuffProvider'
import AnnouncementForm, {
  getInitialValues as getAnnouncementInitialValues,
} from '@components/organisms/AnnouncementForm'
import {
  AnnouncementFormValues,
  CreateAnnouncement,
} from '@interfaces/Announcement'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import Alert from '@components/atoms/Alert'
import { IGame } from '@interfaces/games'
import SendBuffForm from '@components/organisms/SendBuffForm'
import TemplateBuffForm from '@components/organisms/TemplateBuffForm'
import Breadcrumb from '@components/atoms/Breadcrumb'
import BreadcrumbItem from '@components/atoms/Breadcrumb/BreadcrumbItem'
import { BuffActionTypes } from '@services/reducers/buffReducer'
import { useToast } from '@utils/hooks/useToast'
import { getStreamById, endStream } from '@services/requests/stream'
import {
  Template,
  AnnouncementTemplateCreate,
  AnnouncementTemplate,
} from '@interfaces/Template'
import { BuffFormContext } from '@services/providers/BuffFormProvider'
import { formatAnnouncementDataToSubmit } from '@utils/formatAnnouncementDataToSubmit'
import { LanguageCode } from '@interfaces/ILanguage'
import TemplateAnnouncementConfirmation from '@components/molecules/TemplateAnnouncementConfirmation'
import { TemplateTypeTabs } from '@components/organisms/TemplateList/types'
import { TemplateContext } from '@services/providers/TemplateProvider'
import { getAuthors } from '@services/requests/author'
import { createVOD } from '@services/requests/vod'
import { endGameById, useGetGame } from '@services/requests/games'
import { BuffPageActionMode } from '@interfaces/BuffPage'
import StreamTemplates from '@components/organisms/StreamTemplates'
import { queryClient } from '@utils/reactQuery/client'
import Button from '@components/atoms/Button'
import FormActionButtons from '@components/molecules/FormActionButtons'
import TemplateActionButtons from '@components/molecules/TemplateActionButtons'
import BuffPreviewWidget from '@components/molecules/BuffPreviewWidget'
import { ReactComponent as MegaphoneIcon } from '@assets/megaphone.svg'
import { useStreamConfig } from '@utils/hooks/useStreams'
import { Alignment } from '@components/molecules/ConfirmationModal/types'
import EndStreamModalContent from '@components/molecules/EndStreamModalContent'

export enum BuffPageActiveTab {
  BuffView = 'BUFF_VIEW',
  AnnouncementListView = 'ANNOUNCEMENT_LIST_VIEW',
  TemplateListView = 'TEMPLATE_LIST_VIEW',
}

const BuffPage: FC<React.PropsWithChildren<unknown>> = (): JSX.Element => {
  const {
    isUpdateActionMode,
    isTemplateType,
    resetBuffFormContext,
    setActionMode,
  } = useContext(BuffFormContext)
  const { dispatch } = useContext(BuffContext)
  const { addErrorToast, addToast } = useToast()
  const {
    setSelectedTemplate,
    setSelectedAnnouncementTemplate,
    selectedTemplate,
    selectedAnnouncementTemplate,
    currentTemplateIdSelected,
  } = useContext(TemplateContext)
  const { state } = useLocation()

  const [stream, setStream] = useState<IStream>()
  const [game, setGame] = useState<IGame>()
  const [announcementTemplatePayload, setAnnouncementTemplatePayload] =
    useState<AnnouncementTemplateCreate | AnnouncementTemplate | null>(null)
  const [showSetTemplateNameModal, setShowSetTemplateNameModal] =
    useState(false)
  const [showEndStreamModal, setShowEndStreamModal] = useState(false)
  const [showResetModal, setShowResetModal] = useState<boolean>(false)
  const [isGameEnded, setIsGameEnded] = useState(false)
  const { id: streamId = '', gameId = '' } = useParams<IRouteParams>()
  const { data: gameData } = useGetGame({
    gameId,
    options: { enabled: !!gameId },
  })

  const { data: streamConfig } = useStreamConfig(streamId ?? '', {
    enabled: !!streamId,
  })

  const [activeLeftTab, setActiveLeftTab] = useState<BuffPageActiveTab>(
    state?.initialTab ?? BuffPageActiveTab.BuffView
  )

  const tabs = [
    { text: tr({ id: 'generic.sendBuffs' }), disabled: gameData?.game?.ended },
    { text: tr({ id: 'generic.sendAnnouncements' }) },
    { text: tr({ id: 'template.templates' }), disabled: gameData?.game?.ended },
  ]
  const tabOrder = [
    BuffPageActiveTab.BuffView,
    BuffPageActiveTab.AnnouncementListView,
    BuffPageActiveTab.TemplateListView,
  ]
  const tabIndex = tabOrder.findIndex((val) => val === activeLeftTab)
  const setTabIndex = (index: number) => {
    const tabKey = tabOrder[index]
    setActiveLeftTab(tabKey)
  }

  const [templateTypeTabSelected, setTemplateTypeTabSelected] = useState(
    state?.initialTemplateTab ?? TemplateTypeTabs.Buffs
  )

  const [authors, setAuthors] = useState<Author[]>([])
  const announcementFormRef = useRef<FormikProps<AnnouncementFormValues>>(null)
  const navigate = useNavigate()

  const buffSectionStyle = {
    boxShadow: '0 5px 15px 0 rgba(9, 14, 37, 0.1)',
  }

  const templateName =
    selectedTemplate?.templateName ||
    selectedAnnouncementTemplate?.templateName ||
    ''

  const isBuffTabActive = activeLeftTab === BuffPageActiveTab.BuffView
  const showBuffFormLoader = isBuffTabActive
  const showBuffForm = isBuffTabActive && streamConfig !== undefined

  const isAnnouncementTabActive =
    activeLeftTab === BuffPageActiveTab.AnnouncementListView

  const showAnnouncementFormLoader = isAnnouncementTabActive
  const showAnnouncementForm = isAnnouncementTabActive

  const status = getStreamStatus(stream)
  const isStatusInactive = status === StreamStatus.inactive
  const isStreamNotStarted = isStatusInactive && !game?.ended

  const DEFAULT_PARTICIPATION_POINTS = 0

  const initialLanguages = useMemo(() => {
    return stream ? [stream?.languages[0]] : []
  }, [stream])

  const participationPoints = useMemo(() => {
    return game?.maxParticipationPoints || DEFAULT_PARTICIPATION_POINTS
  }, [game])

  const announcementInitialValues =
    getAnnouncementInitialValues(initialLanguages)

  const getStream = useCallback(
    async (id?: string | null) => {
      if (!id) return
      try {
        const stream = await getStreamById(id)
        setStream(stream)
        setGame(gameData?.game)
      } catch (error) {
        addErrorToast(error)
      }
    },
    [gameData?.game, addErrorToast]
  )

  useEffect(() => {
    if (activeLeftTab !== BuffPageActiveTab.TemplateListView) {
      setTemplateTypeTabSelected(TemplateTypeTabs.Buffs)
    }
  }, [activeLeftTab])

  useEffect(() => {
    if (!game) return
    game?.ended && setActiveLeftTab(BuffPageActiveTab.AnnouncementListView)
  }, [game])

  useEffect(() => {
    if (!stream) return
    StreamStatus.vod === getStreamStatus(stream) && navigate('./replay')
  }, [stream, navigate])

  useEffect(() => {
    getStream(streamId)
    setIsGameEnded(gameData?.game?.ended ?? false)
  }, [streamId, getStream, gameData?.game?.ended])

  useEffect(() => {
    const getAllAuthors = async () => {
      try {
        const { authors } = await getAuthors(30, '')
        setAuthors(authors ?? [])
      } catch (error) {
        addErrorToast(error)
      }
    }

    getAllAuthors()
  }, [addErrorToast])

  useEffect(() => {
    window.history.replaceState({}, document.title)
  })

  const handleCreateVODClick = async () => {
    try {
      await endStream(streamId)
      await createVOD(streamId)
      navigate('./replay')
    } catch (error) {
      addErrorToast(error)
    }
  }

  const handleEndStreamButtonClick = () => setShowEndStreamModal(true)
  const onCloseEndStreamModal = () => setShowEndStreamModal(false)
  const onSuccess = async () => {
    try {
      if (!gameId) return
      const { data } = await endGameById(gameId)
      setIsGameEnded(data?.game?.ended ?? false)
      getStream(streamId)
    } catch (error) {
      addErrorToast(error)
    } finally {
      setShowEndStreamModal(false)
    }
  }

  const showAnnouncementTemplateModal = async (
    values: CreateAnnouncement,
    streamId: string,
    defaultTemplateName?: string
  ) => {
    try {
      const template: AnnouncementTemplateCreate = {
        ...values,
        templateName: defaultTemplateName || templateName,
        streamId,
      }
      setAnnouncementTemplatePayload(template)
      setShowSetTemplateNameModal(true)
    } catch (error) {
      addErrorToast(error)
    }
  }

  const handleAnnouncementSubmit = async (
    values: AnnouncementFormValues,
    helpers: FormikHelpers<AnnouncementFormValues>,
    languages?: LanguageCode[]
  ) => {
    if (!values || !stream) return
    if (!languages) return

    const formBody = formatAnnouncementDataToSubmit(values, stream.languages)

    // Update/Create announcement template mode
    if (isTemplateType) {
      showAnnouncementTemplateModal(formBody, streamId)
      return
    }

    try {
      await announcementService.send({
        gameId,
        announcement: formBody,
      })
      addToast({
        msg: tr({ id: 'announcements.announcementSent' }),
        type: 'success',
      })
      helpers.resetForm({
        values: getAnnouncementInitialValues(languages),
      })
      queryClient.invalidateQueries(['game-content', gameId])
    } catch (error) {
      addErrorToast(error)
    } finally {
      dispatch({
        type: BuffActionTypes.SetIsFormSubmitting,
        payload: false,
      })
    }
  }

  const handleDoubleClick = (template: Template | AnnouncementTemplate) => {
    setActionMode(BuffPageActionMode.Load)

    if (
      templateTypeTabSelected === TemplateTypeTabs.Buffs ||
      templateTypeTabSelected === TemplateTypeTabs.GlobalBuffs
    ) {
      setActiveLeftTab(BuffPageActiveTab.BuffView)
      setSelectedTemplate(template as Template)
    } else {
      setActiveLeftTab(BuffPageActiveTab.AnnouncementListView)
      setSelectedAnnouncementTemplate(template as AnnouncementTemplate)
    }
  }

  const handleReturnCallback = () => {
    setTimeout(() => {
      setTemplateTypeTabSelected(templateTypeTabSelected)
    }, 10)
  }

  const displayTemplateBuffForm =
    isUpdateActionMode &&
    (templateTypeTabSelected === TemplateTypeTabs.Buffs ||
      templateTypeTabSelected === TemplateTypeTabs.GlobalBuffs)

  const displayTemplateAnnouncementForm =
    isUpdateActionMode &&
    (templateTypeTabSelected === TemplateTypeTabs.Announcements ||
      templateTypeTabSelected === TemplateTypeTabs.GlobalAnnouncements)

  const displayStreamTemplates = !isUpdateActionMode

  // TODO: Should we create a loading state view/skeleton?
  if (!stream) return <></>

  return (
    <>
      <Container data-testid="buff-page">
        <div className="flex flex-col w-full ml-2 mt-2">
          <div className="flex w-full items-center justify-between">
            <div className="flex">
              <Breadcrumb>
                <BreadcrumbItem href={`/streams/${streamId}/${gameId}`}>
                  {tr({ id: 'stream.stream' })} - {stream?.title}
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  {tr({ id: 'generic.sendBuffs' })}
                </BreadcrumbItem>
              </Breadcrumb>
              {isStreamNotStarted && (
                <div className="flex items-center">
                  <Alert
                    className="ml-3 py-1.5"
                    text={tr({ id: 'stream.mustStartStream' })}
                    action={tr({ id: 'stream.startNow' })}
                    actionLink={`/streams/${streamId}/${gameId}`}
                    actionClassName="font-bold underline"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full justify-between mr-8 mt-4">
          <div className="flex w-full justify-between items-center mb-3">
            <div className="flex w-full justify-between">
              <div className="flex">
                <h2 className="h3 font-bold mb-0 ml-2 mr-2.5">
                  {tr({ id: 'generic.sendingBuffs' })} {stream?.title}
                </h2>
                {isGameEnded && (
                  <Alert
                    className="py-1.5"
                    text={tr({ id: 'stream.hasEnded' })}
                  />
                )}
              </div>
              <div className="flex gap-2">
                <Button
                  type="button"
                  variant="secondary"
                  className="hover:text-inherit"
                  href={`/streams/${streamId}/${gameId}/leaderboard-list`}
                  startElement={
                    <MegaphoneIcon className="-ml-2 mr-1 w-4 h-4" />
                  }
                >
                  {tr({ id: 'leaderboard.leaderboards' })}
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  className="relative"
                  onClick={() => {
                    setShowResetModal(true)
                  }}
                  startElement={
                    <img
                      className="-ml-2 mr-1"
                      src="/assets/images/reset.png"
                    />
                  }
                >
                  {tr({ id: 'generic.resetBuff' })}
                </Button>
                {isGameEnded && (
                  <Button
                    onClick={handleCreateVODClick}
                    variant="secondary"
                    data-testid="buff-page__button--create-vod"
                    startElement={
                      <img
                        className="-ml-4 mr-1"
                        src="/assets/images/button-play-buff-white.png"
                      />
                    }
                  >
                    {tr({ id: 'stream.createVOD' })}
                  </Button>
                )}
                {!isStatusInactive && !isGameEnded && (
                  <Button
                    onClick={handleEndStreamButtonClick}
                    variant="danger"
                    data-testid="buff-page__button--end-stream"
                    startElement={
                      <img
                        className="-ml-4 mr-1"
                        src="/assets/images/button-stop-buff-white.png"
                      />
                    }
                  >
                    {tr({ id: 'stream.stopStream' })}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="grow">
            <div
              style={buffSectionStyle}
              className="mt-1 mb-2 px-4 py-3 bg-white"
            >
              <Tabs
                selectedIndex={tabIndex}
                onTabChange={setTabIndex}
                tabs={tabs}
              >
                <TabPanel>
                  {showBuffForm && (
                    <>
                      <SendBuffForm
                        stream={stream}
                        streamConfig={streamConfig}
                        authors={authors}
                        participationPoints={participationPoints}
                      />
                    </>
                  )}
                  {showBuffFormLoader && (
                    <>
                      <Skeleton height={169} className="my-3" />
                      <Skeleton height={365} className="my-3" />
                    </>
                  )}
                </TabPanel>
                <TabPanel>
                  {showAnnouncementForm && (
                    <>
                      <AnnouncementForm
                        ref={announcementFormRef}
                        authors={authors}
                        theme={streamConfig?.config.theme}
                        languages={stream?.languages}
                        initialValues={announcementInitialValues}
                        onSubmit={handleAnnouncementSubmit}
                      />
                      <TemplateAnnouncementConfirmation
                        mode="create"
                        payload={announcementTemplatePayload}
                        show={showSetTemplateNameModal}
                        onClose={() => setShowSetTemplateNameModal(false)}
                      />
                    </>
                  )}

                  {showAnnouncementFormLoader && (
                    <>
                      <Skeleton height={124} className="my-3" />
                      <Skeleton height={184} className="my-3" />
                    </>
                  )}
                </TabPanel>
                <TabPanel>
                  {displayStreamTemplates && (
                    <StreamTemplates
                      templatesActiveTab={templateTypeTabSelected}
                      setTemplateTypeActiveTab={setTemplateTypeTabSelected}
                      onDoubleClick={handleDoubleClick}
                    />
                  )}
                  {displayTemplateBuffForm && (
                    <TemplateBuffForm
                      stream={stream}
                      theme={streamConfig?.config.theme}
                      authors={authors}
                      availableLanguages={stream.languages}
                    />
                  )}
                  {displayTemplateAnnouncementForm && (
                    <>
                      <AnnouncementForm
                        theme={streamConfig?.config.theme}
                        ref={announcementFormRef}
                        authors={authors}
                        languages={stream?.languages}
                        initialValues={announcementInitialValues}
                        onSubmit={handleAnnouncementSubmit}
                        returnCallback={handleReturnCallback}
                      />
                      <TemplateAnnouncementConfirmation
                        mode="update"
                        id={currentTemplateIdSelected}
                        payload={announcementTemplatePayload}
                        show={showSetTemplateNameModal}
                        onSuccessCallback={() => {
                          resetBuffFormContext()
                          setTemplateTypeTabSelected(templateTypeTabSelected)
                        }}
                        onClose={() => setShowSetTemplateNameModal(false)}
                      />
                    </>
                  )}
                </TabPanel>
              </Tabs>
            </div>
          </div>
          <div className="flex flex-col grow max-w-[800px] pl-4">
            <div style={buffSectionStyle}>
              <BuffPreviewWidget theme={streamConfig?.config.theme} />
            </div>
            {activeLeftTab !== BuffPageActiveTab.TemplateListView && (
              <div className="mt-2">
                <FormActionButtons
                  mainActiveTab={activeLeftTab}
                  isStreamNotStarted={isStatusInactive}
                />
              </div>
            )}
            {activeLeftTab === BuffPageActiveTab.TemplateListView && (
              <div className="mt-2">
                <TemplateActionButtons
                  templatesActiveTab={templateTypeTabSelected}
                  setMainActiveTab={setActiveLeftTab}
                />
              </div>
            )}
            <div
              style={buffSectionStyle}
              className="relative p-3 my-2 bg-white rounded-md"
            >
              <RightAside setMainActiveTab={setActiveLeftTab} />
            </div>
          </div>
        </div>
      </Container>

      <ConfirmationModal
        onClose={onCloseEndStreamModal}
        onSuccess={onSuccess}
        title={tr({ id: 'stream.endThisStream' })}
        successBtnText={tr({ id: 'stream.endThisStreamBtnConfirmation' })}
        subtext={tr({ id: 'stream.endThisStreamSubtext' })}
        show={showEndStreamModal}
        data-testid="end-stream-modal"
        alignment={Alignment.END}
      >
        {showEndStreamModal && (
          <EndStreamModalContent streamId={streamId} gameId={gameId} />
        )}
      </ConfirmationModal>
      <ConfirmationModal
        show={showResetModal}
        successBtnText={tr({ id: 'qaForm.startAgain' })}
        onClose={() => setShowResetModal(false)}
        onSuccess={() => {
          window.location.reload()
        }}
        subtext={tr({ id: 'stream.deleteStreamConfirmationText' })}
        title={tr({ id: 'stream.deleteStreamConfirmationTitle' })}
      />
    </>
  )
}

export default BuffPage
