import React, {
  FC,
  useState,
  ReactElement,
  useRef,
  useMemo,
  useCallback,
  ChangeEvent,
  useEffect,
} from 'react'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { Tab } from '@headlessui/react'
import { useQuery } from 'react-query'
import { Formik, Form, FormikProps } from 'formik'
import {
  Orientation,
  Platform,
  Position,
  PositionConfig,
} from '@interfaces/IStreamConfig'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'
import SelectInput from '@components/atoms/SelectInput'
import TextInput from '@components/atoms/TextInput'
import Switch from '@components/atoms/Switch'
import { tr } from '@constants/other'
import { useClientConfig } from '@services/requests/client-config'

import {
  IPositioningProps,
  PlatformTab,
  PlatformTabItem,
  PositioningTabs,
} from './types'
import { positioningSchema } from './schema'

const LEFT = 'Left'
const RIGHT = 'Right'
const TOP = 'Top'
const BOTTOM = 'Bottom'

const PERCENTAGE_UNIT = '%'

const platforms: PlatformTab = {
  [PositioningTabs.WebSdk]: {
    type: Platform.WEB,
    title: 'Web SDK',
    contentTestId: 'positioning__content--web-sdk',
    tabTestId: 'positioning__tab--web-sdk',
  },
  [PositioningTabs.Android]: {
    type: Platform.ANDROID,
    title: 'Android',
    contentTestId: 'positioning__content--android',
    tabTestId: 'positioning__tab--android',
  },

  [PositioningTabs.Ios]: {
    type: Platform.IOS,
    title: 'iOS',
    contentTestId: 'positioning__content--ios',
    tabTestId: 'positioning__tab--ios',
  },

  [PositioningTabs.Twitch]: {
    type: Platform.TWITCH,
    title: 'Twitch',
    contentTestId: 'positioning__content--twitch',
    tabTestId: 'positioning__tab--twitch',
  },
  [PositioningTabs.TizenTV]: {
    type: Platform.TizenTV,
    title: 'Tizen/WebOS',
    contentTestId: 'positioning__content--tizentv',
    tabTestId: 'positioning__tab--tizentv',
  },
  [PositioningTabs.AndroidTV]: {
    type: Platform.AndroidTV,
    title: 'Android TV',
    contentTestId: 'positioning__content--androidtv',
    tabTestId: 'positioning__tab--androidtv',
  },
}

const positionXOptions = [
  {
    key: Position.LEFT,
    value: LEFT,
  },
  {
    key: Position.RIGHT,
    value: RIGHT,
  },
]

const positionYOptions = [
  {
    key: Position.TOP,
    value: TOP,
  },
  {
    key: Position.BOTTOM,
    value: BOTTOM,
  },
]

const Positioning: FC<React.PropsWithChildren<IPositioningProps>> = ({
  positioningConfig,
  onSubmit,
  onRefetch,
}) => {
  const formRef = useRef<FormikProps<PositionConfig>>(null)
  const { getPlatforms } = useClientConfig()
  const { data: configPlatforms } = useQuery(['platforms'], getPlatforms)
  const [activePlatforms, setActivePlatforms] = useState<PlatformTabItem[]>([])
  const [platformTabSelected, setPlatformTabSelected] =
    useState<PositioningTabs>(PositioningTabs.WebSdk)

  const isTVPositioning =
    platformTabSelected === PositioningTabs.AndroidTV ||
    platformTabSelected === PositioningTabs.TizenTV

  const getPlatformValues = useCallback(() => {
    if (!positioningConfig?.length) return
    return positioningConfig.find((config) => {
      return config.platform === platforms[platformTabSelected].type
    })
  }, [platformTabSelected, positioningConfig])

  const initialValues = useMemo(() => {
    return getPlatformValues()
  }, [getPlatformValues])

  /**
   * Sets the current selected tab
   * @param {number} position
   * @return {void}
   */
  const handleTabChange = (position: number): void => {
    setPlatformTabSelected(position)
  }

  /**
   * Handles the form updates when onBlur
   */
  const updateValuesOnBlur = async () => {
    if (!formRef?.current?.values) return

    const formValues: Partial<PositionConfig> = formRef?.current?.values
    if (isTVPositioning) {
      delete formValues?.orientationPortrait
    }

    onSubmit(formValues)
  }

  useEffect(() => {
    if (!configPlatforms) return

    const currentPlatforms = Object.values(platforms).filter(
      (platform) => configPlatforms.platforms.indexOf(platform.type) > -1
    ) as PlatformTabItem[]
    setActivePlatforms(currentPlatforms)
  }, [configPlatforms])

  /**
   * Renders `%`
   * @return {ReactElement}
   */
  const renderUnits = (): ReactElement => {
    return <div className="ml-2 mb-2.5">{PERCENTAGE_UNIT}</div>
  }

  /**
   * Renders the positioning form content
   * @return {ReactElement}
   */
  const renderPositioningContent = (): ReactElement => {
    // TODO: We can implement a loading skeleton here
    if (!initialValues) return <></>

    const schema = positioningSchema(isTVPositioning)

    // Formik onSubmit is mandatory even tho we don't need it in this case
    return (
      <Formik
        onSubmit={() => {}}
        initialValues={initialValues}
        validationSchema={schema}
        innerRef={formRef}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          setFieldValue,
        }) => {
          return (
            <Form id="positioning-form">
              <div className="space-y-1">
                {/* Draggable Leaderboard */}
                <div className="flex items-start">
                  <div className="flex mr-8 w-60">
                    <label
                      className="flex mb-0 mr-2 items-center !font-normal"
                      htmlFor="draggableLeaderboard"
                    >
                      {tr({ id: 'appearance.draggableLeaderboard' })}
                    </label>
                    <Tooltip placement="right">
                      <TooltipTrigger>
                        <div className="flex items-center gap-x-2 cursor-pointer">
                          <InfoCircleIcon className="w-4 h-4" />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <span>
                          {tr({
                            id: 'appearance.draggableLeaderboardDesc',
                          })}
                        </span>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  <Switch
                    textClassname="text-sm uppercase"
                    textPosition="right"
                    name="draggableLeaderboard"
                    id="draggableLeaderboard"
                    text={
                      values.draggableLeaderboard
                        ? tr({ id: 'generic.enabled' })
                        : tr({ id: 'generic.disabled' })
                    }
                    isChecked={values.draggableLeaderboard}
                    onToggle={async (e: ChangeEvent<HTMLInputElement>) => {
                      await setFieldValue(
                        'draggableLeaderboard',
                        e.target.checked
                      )
                      updateValuesOnBlur()
                    }}
                  />
                </div>
                {/* Landscape Positioning */}
                {!isTVPositioning && (
                  <>
                    <h6 className="font-bold mb-4 mt-3">
                      Landscape Positioning
                    </h6>
                    {/* Leaderboard position */}
                    <div>
                      <p className="mb-1">
                        {tr({ id: 'appearance.leaderboardPosition' })}
                      </p>
                      <div className="flex items-center justify-between w-1/2 space-x-4">
                        <SelectInput
                          containerClasses="w-1/2"
                          id="orientationLandscape.leaderboard.vAlign"
                          name="orientationLandscape.leaderboard.vAlign"
                          value={
                            values[Orientation.orientationLandscape]
                              ?.leaderboard?.vAlign
                          }
                          options={positionYOptions}
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e)
                            if (isValid) updateValuesOnBlur()
                          }}
                          error={Boolean(
                            errors[Orientation.orientationLandscape]
                              ?.leaderboard?.vAlign
                          )}
                          errorLabel={
                            errors[Orientation.orientationLandscape]
                              ?.leaderboard?.vAlign
                          }
                          touched={
                            touched[Orientation.orientationLandscape]
                              ?.leaderboard?.vAlign
                          }
                        />
                        <SelectInput
                          containerClasses="w-1/2"
                          id="orientationLandscape.leaderboard.hAlign"
                          name="orientationLandscape.leaderboard.hAlign"
                          value={
                            values[Orientation.orientationLandscape]
                              ?.leaderboard?.hAlign
                          }
                          options={positionXOptions}
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e)
                            if (isValid) updateValuesOnBlur()
                          }}
                          error={Boolean(
                            errors[Orientation.orientationLandscape]
                              ?.leaderboard?.hAlign
                          )}
                          errorLabel={
                            errors[Orientation.orientationLandscape]
                              ?.leaderboard?.hAlign
                          }
                          touched={
                            touched[Orientation.orientationLandscape]
                              ?.leaderboard?.hAlign
                          }
                        />
                      </div>
                    </div>
                    {/* Leaderboard offsets */}
                    <div>
                      <div className="inline-flex space-x-4 w-1/2">
                        {/* Vertical offset */}
                        <div className="flex flex-col w-1/2">
                          <h2 className="text-base m-0">
                            {tr({ id: 'appearance.verticalOffset' })}
                          </h2>
                          <span className="text-[0.688rem] mb-2">
                            {tr({
                              id: 'appearance.minimumHorizontalPercentage',
                            })}
                          </span>
                          <div className="inline-flex items-baseline">
                            <div className="w-3/6 ">
                              <TextInput
                                id="orientationLandscape.leaderboard.vOffset"
                                name="orientationLandscape.leaderboard.vOffset"
                                type="number"
                                min={0}
                                value={
                                  values[Orientation.orientationLandscape]
                                    ?.leaderboard?.vOffset
                                }
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e)
                                  if (isValid) updateValuesOnBlur()
                                }}
                                error={Boolean(
                                  errors[Orientation.orientationLandscape]
                                    ?.leaderboard?.vOffset
                                )}
                                errorLabel={
                                  errors[Orientation.orientationLandscape]
                                    ?.leaderboard?.vOffset
                                }
                                touched={
                                  touched[Orientation.orientationLandscape]
                                    ?.leaderboard?.vOffset
                                }
                              />
                            </div>
                            {renderUnits()}
                          </div>
                        </div>

                        {/* Horizontal offset */}
                        <div className="flex flex-col w-1/2">
                          <h2 className="text-base m-0">
                            {tr({ id: 'appearance.horizontalOffset' })}
                          </h2>
                          <span className="text-[0.688rem] mb-2">
                            {tr({
                              id: 'appearance.minimumHorizontalPercentage',
                            })}
                          </span>
                          <div className="inline-flex items-baseline">
                            <div className="w-3/6">
                              <TextInput
                                id="orientationLandscape.leaderboard.hOffset"
                                name="orientationLandscape.leaderboard.hOffset"
                                type="number"
                                min={0}
                                value={
                                  values[Orientation.orientationLandscape]
                                    ?.leaderboard?.hOffset
                                }
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e)
                                  if (isValid) updateValuesOnBlur()
                                }}
                                error={Boolean(
                                  errors[Orientation.orientationLandscape]
                                    ?.leaderboard?.hOffset
                                )}
                                errorLabel={
                                  errors[Orientation.orientationLandscape]
                                    ?.leaderboard?.hOffset
                                }
                                touched={
                                  touched[Orientation.orientationLandscape]
                                    ?.leaderboard?.hOffset
                                }
                              />
                            </div>
                            {renderUnits()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Buff position */}
                <div>
                  <p className="mb-1">
                    {tr({ id: 'appearance.buffPosition' })}
                  </p>
                  <div className="flex items-center justify-between w-1/2 space-x-4">
                    <SelectInput
                      containerClasses="w-1/2"
                      id="orientationLandscape.voteable.vAlign"
                      name="orientationLandscape.voteable.vAlign"
                      value={
                        values[Orientation.orientationLandscape]?.voteable
                          ?.vAlign
                      }
                      options={positionYOptions}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e)
                        if (isValid) updateValuesOnBlur()
                      }}
                      error={Boolean(
                        errors[Orientation.orientationLandscape]?.voteable
                          ?.vAlign
                      )}
                      errorLabel={
                        errors[Orientation.orientationLandscape]?.voteable
                          ?.vAlign
                      }
                      touched={
                        touched[Orientation.orientationLandscape]?.voteable
                          ?.vAlign
                      }
                    />
                    <SelectInput
                      containerClasses="w-1/2"
                      id="orientationLandscape.voteable.hAlign"
                      name="orientationLandscape.voteable.hAlign"
                      value={
                        values[Orientation.orientationLandscape]?.voteable
                          ?.hAlign
                      }
                      options={positionXOptions}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e)
                        if (isValid) updateValuesOnBlur()
                      }}
                      error={Boolean(
                        errors[Orientation.orientationLandscape]?.voteable
                          ?.hAlign
                      )}
                      errorLabel={
                        errors[Orientation.orientationLandscape]?.voteable
                          ?.hAlign
                      }
                      touched={
                        touched[Orientation.orientationLandscape]?.voteable
                          ?.hAlign
                      }
                    />
                  </div>
                </div>
                {/* Buff offsets */}
                <div>
                  <div className="inline-flex space-x-4 w-1/2">
                    {/* Vertical offset */}
                    <div className="flex flex-col w-1/2">
                      <h2 className="text-base m-0">
                        {tr({ id: 'appearance.verticalOffset' })}
                      </h2>
                      <span className="text-[0.688rem] mb-2">
                        {tr({ id: 'appearance.minimumHorizontalPercentage' })}
                      </span>
                      <div className="inline-flex items-baseline">
                        <div className="w-3/6 ">
                          <TextInput
                            id="orientationLandscape.voteable.vOffset"
                            name="orientationLandscape.voteable.vOffset"
                            type="number"
                            min={0}
                            value={
                              values[Orientation.orientationLandscape]?.voteable
                                ?.vOffset
                            }
                            onChange={handleChange}
                            onBlur={(e) => {
                              handleBlur(e)
                              if (isValid) updateValuesOnBlur()
                            }}
                            error={Boolean(
                              errors[Orientation.orientationLandscape]?.voteable
                                ?.vOffset
                            )}
                            errorLabel={
                              errors[Orientation.orientationLandscape]?.voteable
                                ?.vOffset
                            }
                            touched={
                              touched[Orientation.orientationLandscape]
                                ?.voteable?.vOffset
                            }
                          />
                        </div>
                        {renderUnits()}
                      </div>
                    </div>

                    {/* Horizontal offset */}
                    <div className="flex flex-col w-1/2">
                      <h2 className="text-base m-0">
                        {tr({ id: 'appearance.horizontalOffset' })}
                      </h2>
                      <span className="text-[0.688rem] mb-2">
                        {tr({ id: 'appearance.minimumHorizontalPercentage' })}
                      </span>
                      <div className="inline-flex items-baseline">
                        <div className="w-3/6">
                          <TextInput
                            id="orientationLandscape.voteable.hOffset"
                            name="orientationLandscape.voteable.hOffset"
                            type="number"
                            min={0}
                            value={
                              values[Orientation.orientationLandscape]?.voteable
                                ?.hOffset
                            }
                            onChange={handleChange}
                            onBlur={(e) => {
                              handleBlur(e)
                              if (isValid) updateValuesOnBlur()
                            }}
                            error={Boolean(
                              errors[Orientation.orientationLandscape]?.voteable
                                ?.hOffset
                            )}
                            errorLabel={
                              errors[Orientation.orientationLandscape]?.voteable
                                ?.hOffset
                            }
                            touched={
                              touched[Orientation.orientationLandscape]
                                ?.voteable?.hOffset
                            }
                          />
                        </div>
                        {renderUnits()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Portrait Positioning */}
              {!isTVPositioning && (
                <div className="space-y-1 mt-10">
                  <h6 className="font-bold mb-3">Portrait Positioning</h6>
                  {/* Leaderboard position */}
                  <div>
                    <p className="mb-1">
                      {tr({ id: 'appearance.leaderboardPosition' })}
                    </p>
                    <div className="flex items-center justify-between w-1/2 space-x-4">
                      <SelectInput
                        containerClasses="w-1/2"
                        id="orientationPortrait.leaderboard.vAlign"
                        name="orientationPortrait.leaderboard.vAlign"
                        value={
                          values[Orientation.orientationPortrait]?.leaderboard
                            ?.vAlign
                        }
                        options={positionYOptions}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e)
                          if (isValid) updateValuesOnBlur()
                        }}
                        error={Boolean(
                          errors[Orientation.orientationPortrait]?.leaderboard
                            ?.vAlign
                        )}
                        errorLabel={
                          errors[Orientation.orientationPortrait]?.leaderboard
                            ?.vAlign
                        }
                        touched={
                          touched[Orientation.orientationPortrait]?.leaderboard
                            ?.vAlign
                        }
                      />
                      <SelectInput
                        containerClasses="w-1/2"
                        id="orientationPortrait.leaderboard.hAlign"
                        name="orientationPortrait.leaderboard.hAlign"
                        value={
                          values[Orientation.orientationPortrait]?.leaderboard
                            ?.hAlign
                        }
                        options={positionXOptions}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e)
                          if (isValid) updateValuesOnBlur()
                        }}
                        error={Boolean(
                          errors[Orientation.orientationPortrait]?.leaderboard
                            ?.hAlign
                        )}
                        errorLabel={
                          errors[Orientation.orientationPortrait]?.leaderboard
                            ?.hAlign
                        }
                        touched={
                          touched[Orientation.orientationPortrait]?.leaderboard
                            ?.hAlign
                        }
                      />
                    </div>
                  </div>
                  {/* Leaderboard offsets */}
                  <div>
                    <div className="inline-flex space-x-4 w-1/2">
                      {/* Vertical offset */}
                      <div className="flex flex-col w-1/2">
                        <h2 className="text-base m-0">
                          {tr({ id: 'appearance.verticalOffset' })}
                        </h2>
                        <span className="text-[0.688rem] mb-2">
                          {tr({ id: 'appearance.minimumHorizontalPercentage' })}
                        </span>
                        <div className="inline-flex items-baseline">
                          <div className="w-3/6 ">
                            <TextInput
                              id="orientationPortrait.leaderboard.vOffset"
                              name="orientationPortrait.leaderboard.vOffset"
                              type="number"
                              min={0}
                              value={
                                values[Orientation.orientationPortrait]
                                  ?.leaderboard?.vOffset
                              }
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e)
                                if (isValid) updateValuesOnBlur()
                              }}
                              error={Boolean(
                                errors[Orientation.orientationPortrait]
                                  ?.leaderboard?.vOffset
                              )}
                              errorLabel={
                                errors[Orientation.orientationPortrait]
                                  ?.leaderboard?.vOffset
                              }
                              touched={
                                touched[Orientation.orientationPortrait]
                                  ?.leaderboard?.vOffset
                              }
                            />
                          </div>
                          {renderUnits()}
                        </div>
                      </div>

                      {/* Horizontal offset */}
                      <div className="flex flex-col w-1/2">
                        <h2 className="text-base m-0">
                          {tr({ id: 'appearance.horizontalOffset' })}
                        </h2>
                        <span className="text-[0.688rem] mb-2">
                          {tr({ id: 'appearance.minimumHorizontalPercentage' })}
                        </span>
                        <div className="inline-flex items-baseline">
                          <div className="w-3/6">
                            <TextInput
                              id="orientationPortrait.leaderboard.hOffset"
                              name="orientationPortrait.leaderboard.hOffset"
                              type="number"
                              min={0}
                              value={
                                values[Orientation.orientationPortrait]
                                  ?.leaderboard?.hOffset
                              }
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e)
                                if (isValid) updateValuesOnBlur()
                              }}
                              error={Boolean(
                                errors[Orientation.orientationPortrait]
                                  ?.leaderboard?.hOffset
                              )}
                              errorLabel={
                                errors[Orientation.orientationPortrait]
                                  ?.leaderboard?.hOffset
                              }
                              touched={
                                touched[Orientation.orientationPortrait]
                                  ?.leaderboard?.hOffset
                              }
                            />
                          </div>
                          {renderUnits()}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Buff position */}
                  <div>
                    <p className="mb-1">
                      {tr({ id: 'appearance.buffPosition' })}
                    </p>
                    <div className="flex items-center justify-between w-1/2 space-x-4">
                      <SelectInput
                        containerClasses="w-1/2"
                        id="orientationPortrait.voteable.vAlign"
                        name="orientationPortrait.voteable.vAlign"
                        value={
                          values[Orientation.orientationPortrait]?.voteable
                            ?.vAlign
                        }
                        options={positionYOptions}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e)
                          if (isValid) updateValuesOnBlur()
                        }}
                        error={Boolean(
                          errors[Orientation.orientationPortrait]?.voteable
                            ?.vAlign
                        )}
                        errorLabel={
                          errors[Orientation.orientationPortrait]?.voteable
                            ?.vAlign
                        }
                        touched={
                          touched[Orientation.orientationPortrait]?.voteable
                            ?.vAlign
                        }
                      />
                      <SelectInput
                        containerClasses="w-1/2"
                        id="orientationPortrait.voteable.hAlign"
                        name="orientationPortrait.voteable.hAlign"
                        value={
                          values[Orientation.orientationPortrait]?.voteable
                            ?.hAlign
                        }
                        options={positionXOptions}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e)
                          if (isValid) updateValuesOnBlur()
                        }}
                        error={Boolean(
                          errors[Orientation.orientationPortrait]?.voteable
                            ?.hAlign
                        )}
                        errorLabel={
                          errors[Orientation.orientationPortrait]?.voteable
                            ?.hAlign
                        }
                        touched={
                          touched[Orientation.orientationPortrait]?.voteable
                            ?.hAlign
                        }
                      />
                    </div>
                  </div>
                  {/* Buff offsets */}
                  <div>
                    <div className="inline-flex space-x-4 w-1/2">
                      {/* Vertical offset */}
                      <div className="flex flex-col w-1/2">
                        <h2 className="text-base m-0">
                          {tr({ id: 'appearance.verticalOffset' })}
                        </h2>
                        <span className="text-[0.688rem] mb-2">
                          {tr({ id: 'appearance.minimumHorizontalPercentage' })}
                        </span>
                        <div className="inline-flex items-baseline">
                          <div className="w-3/6 ">
                            <TextInput
                              id="orientationPortrait.voteable.vOffset"
                              name="orientationPortrait.voteable.vOffset"
                              type="number"
                              min={0}
                              value={
                                values[Orientation.orientationPortrait]
                                  ?.voteable?.vOffset
                              }
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e)
                                if (isValid) updateValuesOnBlur()
                              }}
                              error={Boolean(
                                errors[Orientation.orientationPortrait]
                                  ?.voteable?.vOffset
                              )}
                              errorLabel={
                                errors[Orientation.orientationPortrait]
                                  ?.voteable?.vOffset
                              }
                              touched={
                                touched[Orientation.orientationPortrait]
                                  ?.voteable?.vOffset
                              }
                            />
                          </div>
                          {renderUnits()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    )
  }

  if (!activePlatforms) return <></>

  return (
    <div
      className="p-4 rounded-lg"
      style={{ backgroundColor: 'var(--white-background)' }}
      data-testid="positioning"
    >
      <Tab.Group onChange={handleTabChange}>
        {/* Positioning Header */}
        <div className="flex justify-between mb-2">
          <h1 className="m-0 text-[1.125rem] font-bold">
            {tr({ id: 'appearance.positioning' })}
          </h1>
          <Tab.List className="flex items-center space-x-1 bg-white rounded-3xl px-2">
            {activePlatforms.map((platform) => (
              <Tab
                key={platform.tabTestId}
                data-testid={platform.tabTestId}
                className={({ selected }) =>
                  `whitespace-nowrap border-none py-1 my-1 w-full text-sm font-medium text-[#5c5c5c] rounded-3xl px-2.5 ${
                    selected ? 'bg-[#0090fe] text-white' : 'bg-transparent'
                  }`
                }
              >
                {platform.title}
              </Tab>
            ))}
          </Tab.List>
        </div>
        {/* Content */}
        <Tab.Panels>
          {activePlatforms.map((platform: PlatformTabItem, idx) => (
            <Tab.Panel key={idx} data-testid={platform.contentTestId}>
              {renderPositioningContent()}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default Positioning
