import { LanguageCode } from './ILanguage'
import ISelectedLanguage from './ISelectedLanguage'
import { NewConfig, PositionConfig } from './IStreamConfig'
import {
  LocalisedAnnouncementContent,
  LocalisedAuthorContent,
  LocalisedSponsorContent,
  LocalisedVoteableContent,
} from './localisation'

interface VideoDetails {
  source: string
}

export interface Vod {
  id: string
  leaderboardId: string
  name: string
  providerId: string
  languages: LanguageCode[]
  videoDetails: VideoDetails
  maxBonusTimePoints: number
  published: boolean
  config: NewConfig
  positionConfig: PositionConfig[]
}

export interface LocalisableVodContent {
  author: LocalisedAuthorContent
  sponsor: LocalisedSponsorContent
  content: LocalisedVoteableContent | LocalisedAnnouncementContent
}

export interface VodContent {
  id: string
  showAt: number
  showFor: number
  languages: LanguageCode[]
  contentType: 'ANNOUNCEMENT' | 'VOTEABLE'
  localisations: Record<LanguageCode, LocalisableVodContent>
}

export interface GetVodResponse {
  vods: Vod[]
  nextPage: string
}

export enum VodStatus {
  published = 'published',
  editing = 'editing',
}

export interface VodFormValues {
  name: string
  providerId: string
  maxBonusTimePoints: number
  languages: ISelectedLanguage[]
}
