import React, { useRef, useState } from 'react'
import { ReactComponent as CopySVG } from '@assets/copy.svg'
import { CopyIdProps } from './types'
import { tr } from '@constants/other'

const CopyId = ({ id = '' }: CopyIdProps) => {
  const [isCopying, setIsCopying] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleSucessCopy = () => {
    setIsCopying(true)
    setTimeout(() => {
      setIsCopying(false)
    }, 2000)
  }

  const copyIdFallback = () => {
    if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy') &&
      inputRef?.current
    ) {
      const originalValue = inputRef.current.value
      inputRef.current.value = inputRef.current.value.replace(/\s/g, '')
      inputRef.current.select()
      try {
        document.execCommand('copy')
        inputRef.current.value = originalValue
        handleSucessCopy()
      } catch (err) {
        console.warn('Copy to clipboard failed.', err)
      }
    }
  }

  const copyId = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        // @ts-ignore
        name: 'clipboard-write',
      })

      if (permissionStatus.state !== 'granted') {
        copyIdFallback()
        return
      }
    } catch {
      copyIdFallback()
      return
    }

    try {
      await navigator.clipboard.writeText(id)
      handleSucessCopy()
    } catch (err) {
      console.warn('Copy to clipboard failed.', err)
    }
  }

  const handleClick = () => !isCopying && copyId()

  return (
    <div className="flex items-center pb-3 pt-2 px-2 justify-between">
      <div className="flex flex-1 items-baseline text-[0.75rem] overflow-hidden pr-2">
        {tr({ id: 'generic.id' })}:
        <input
          readOnly
          value={id}
          type="text"
          ref={inputRef}
          className="bg-transparent ml-1 w-full text-[0.625rem] text-white outline-none border-none opacity-70 leading-none overflow-hidden whitespace-nowrap overflow-ellipsis"
        />
      </div>
      <button
        data-testid="copy-id-button"
        onClick={handleClick}
        className="grid place-content-center text-white bg-[#F4F4F412] h-6 rounded-lg border-none flex-shrink-0"
      >
        {isCopying ? (
          <span className="text-[0.625rem] whitespace-nowrap font-bold">
            {tr({ id: 'buffHistory.idCopied' })}
          </span>
        ) : (
          <CopySVG className="w-4 h-3" />
        )}
      </button>
    </div>
  )
}

export default CopyId
