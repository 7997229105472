import React, { useState } from 'react'
import LeaderboardList from '@components/organisms/LeaderboardList'
import { moderateUser, unModerateUser } from '@services/requests/auth'
import { queryClient } from '@utils/reactQuery/client'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import TooltipPill from '@components/molecules/TooltipPill'
import Button from '@components/atoms/Button'
import { tr } from '@constants/other'
import { ReactComponent as InfoCircleIcon } from '@assets/info_circle.svg'
import { downloadBlob } from '@utils/downloadBlob'
import { generateLeaderboardCSV } from '@utils/generateCSV'
import { useLive } from '@services/requests/live'
import { Standing } from '@interfaces/Leaderboard'
import { ViewLeaderboardProps } from './types'

const ViewLeaderboard = ({ id, streamId, queryKey }: ViewLeaderboardProps) => {
  const { useGetStreamLeaderboardById, useGetStreamLeaderboardStandingsById } =
    useLive()
  const { data: meta } = useGetStreamLeaderboardById(streamId, id)
  const { data: standings } = useGetStreamLeaderboardStandingsById(streamId, id)
  const [isModerating, setIsModerating] = useState<boolean>(false)
  const [userToModerate, setUserToModerate] = useState<Standing>()
  const handleUserModerationClick = (user: Standing) => {
    setUserToModerate(user)
  }

  const handleModalClose = () => {
    setUserToModerate(undefined)
    setIsModerating(false)
  }

  const handleModalSuccess = async () => {
    if (!userToModerate) return
    setIsModerating(true)

    try {
      if (userToModerate.moderated) {
        await unModerateUser(userToModerate.id)
      } else {
        await moderateUser(userToModerate.id)
      }

      queryClient.invalidateQueries(queryKey ?? [meta?.id])
    } catch (error) {
      console.error(error)
    }

    setIsModerating(false)
    setUserToModerate(undefined)
  }

  const handleRefetch = () => {
    queryClient.invalidateQueries(queryKey ?? [meta?.id])
  }

  const handleCSVExport = () => {
    if (!standings) return

    const filename = `leaderboard-${meta?.id}.csv`

    const csvContent = generateLeaderboardCSV(standings)

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    downloadBlob(blob, filename)
  }

  const modalTitle = userToModerate?.moderated
    ? 'Remove moderation and unlock user'
    : 'Moderate name and lock user'

  const modalSubText = userToModerate?.moderated
    ? 'This action will allow the user to change their name.'
    : "This action will update the user's name and prevent them from editing it."

  const modalSuccessText = userToModerate?.moderated
    ? 'Remove moderation'
    : 'Moderate'

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <h2 className="text-[24px] mb-0">{meta?.name}</h2>
          <div className="flex items-center gap-2">
            <TooltipPill
              className="bg-transparent"
              showTooltip
              text={<InfoCircleIcon className="h-5 w-5 text-[#717A85]" />}
            >
              {tr({ id: 'leaderboard.exportTopN' }, { value: 100 })}
            </TooltipPill>
            <Button
              disabled={!standings}
              className="flex-shrink-0"
              variant="secondary"
              type="button"
              onClick={handleCSVExport}
            >
              {tr({ id: 'leaderboard.downloadLeaderboard' })}
            </Button>
            <Button
              disabled={!standings}
              className="flex-shrink-0"
              variant="secondary"
              type="button"
              onClick={handleRefetch}
            >
              {tr({ id: 'generic.refresh' })}
            </Button>
          </div>
        </div>
        <LeaderboardList
          leaderboard={standings || null}
          displayInModal={false}
          onModerationClick={handleUserModerationClick}
        />
      </div>
      <ConfirmationModal
        title={modalTitle}
        show={Boolean(userToModerate)}
        onClose={handleModalClose}
        onSuccess={handleModalSuccess}
        subtext={modalSubText}
        successBtnText={modalSuccessText}
        disabled={isModerating}
      />
    </>
  )
}

export default ViewLeaderboard
