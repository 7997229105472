import React from 'react'
import { DraggableDotsProps } from './types'

const DraggableDots = ({
  'data-testid': testId = 'draggable-dots',
  className = '',
}: DraggableDotsProps) => {
  return (
    <div
      data-testid={testId}
      className={`grid grid-cols-2 gap-0.5 mr-3 ${className}`}
    >
      {[...Array(12)].map((_, i) => {
        return (
          <div key={i} className="w-[3px] h-[3px] bg-[#959DA5] rounded"></div>
        )
      })}
    </div>
  )
}

export default DraggableDots
