import React, {
  FC,
  useState,
  useRef,
  useContext,
  Fragment,
  MouseEvent,
  useMemo,
} from 'react'
import { useParams } from 'react-router-dom'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { useToast } from '@utils/hooks/useToast'
import { getMetadataFromBuffType } from '@utils/buffTypeMappings'
import { BuffContext } from '@services/providers/BuffProvider'
import ResolvePredictionForm from '@components/organisms/ResolveBuffForm'
import BuffAnswerStats from '@components/molecules/BuffAnswerStats'
import { tr, BuffType } from '@constants/other'
import {
  mapVodVoteableStatus,
  mapVoteableStatus,
  mapBuffStatusForPill,
} from '@utils/statusMapping'
import { CreateVoteableAnswer, Voteable } from '@interfaces/Voteable'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import { LanguageCode } from '@interfaces/ILanguage'
import getLocalisations from '@utils/getLocalisation'
import starRating from '@assets/star-rating.svg'
import CopyId from '@components/molecules/CopyId'
import TooltipPill from '@components/molecules/TooltipPill'
import { ReactComponent as WorldSVG } from '@assets/world.svg'
import { ReactComponent as ReloadSVG } from '@assets/retry.svg'
import { getLanguagesMetadata } from '@utils/language'
import { TemplateContext } from '@services/providers/TemplateProvider'
import { AnnouncementTemplate, Template } from '@interfaces/Template'
import { BuffFormContext } from '@services/providers/BuffFormProvider'
import { BuffPageActionMode } from '@interfaces/BuffPage'
import { BuffPageActiveTab } from '@pages/BuffPage'
import { ReactComponent as InfoCircleIcon } from '@assets/info_circle.svg'
import { ReactComponent as EyeOpenIcon } from '@assets/eye_open.svg'
import { ReactComponent as EyeCloseIcon } from '@assets/eye_close.svg'
import { VodContext } from '@services/providers/VodProvider'
import { useGetEngagement } from '@services/requests/engagement'
import { transformRawEngagementToEngagement } from '@utils/buff'
import { VodVoteable } from '@interfaces/Vod'
import { Announcement } from '@interfaces/Announcement'
import { useUpdateVoteable } from '@services/requests/vod'
import {
  IResolveVoteableParams,
  resendVoteableLastState,
  resolveVoteable,
} from '@services/requests/voteable'
import {
  getBuffId,
  isAnnouncement,
  isVodVoteable,
  isVoteable,
} from '@utils/voteables'
import createDefaultAnswer from '@utils/createDefaultAnswer'
import { cloneDeep } from 'lodash'
import { parseLocalisationObjectToArray } from '@utils/localisation'
import { ReactComponent as TimerSVG } from '@assets/timer.svg'
import { format, utcToZonedTime } from 'date-fns-tz'
import Button from '@components/atoms/Button'
import { useMutation } from 'react-query'
import { BuffStatus } from '@interfaces/buff'

const tableConfig = { columnSizes: ['10%', '30%', 'auto', '10%', '10%'] }

interface BuffHistoryItemProps {
  buff: Voteable | Announcement | VodVoteable
  showResolve?: boolean
  showReplayCheckbox?: boolean
  showOverlay?: boolean
  userTime?: number
  setMainActiveTab?: (tab: BuffPageActiveTab) => void
}

enum OverlayType {
  'info',
  'resolve',
}

const ReloadIcon = (
  <div className="relative">
    <ReloadSVG className="w-4 h-4" />
  </div>
)

const formatInTimeZone = (date: string, fmt: string, tz: string) =>
  format(utcToZonedTime(date, tz), fmt, { timeZone: tz })

const BuffPublishedTime = ({ buff }: { buff: Voteable | Announcement }) => {
  if (!buff.opensAt) return null
  return (
    <TooltipPill
      className="flex-shrink-0"
      showTooltip
      text={
        <div className="flex flex-row items-center">
          <TimerSVG />
          <span className="ml-1">
            {formatInTimeZone(buff.opensAt, 'HH:mm:ss', 'UTC')}
          </span>
        </div>
      }
    >
      <div className="flex flex-col items-center text-sm">
        <span>
          {tr({
            id: `buff.status.published`,
          })}
        </span>
        <span className="ml-1">
          {formatInTimeZone(buff.opensAt, 'dd MMM yyyy, HH:mm:ss', 'UTC')} UTC
        </span>
      </div>
    </TooltipPill>
  )
}

const BuffHistoryItem: FC<React.PropsWithChildren<BuffHistoryItemProps>> = ({
  buff,
  showResolve = false,
  showReplayCheckbox = false,
  showOverlay = true,
  userTime,
  setMainActiveTab,
}) => {
  const { refetchBuffs } = useContext(BuffContext)
  const { currentVodId } = useContext(VodContext)
  const { selectedVodVoteable, setSelectedVodVoteable } = useContext(VodContext)
  const { addErrorToast, addToast } = useToast()
  const { setSelectedTemplate, setSelectedAnnouncementTemplate } =
    useContext(TemplateContext)
  const { setActionMode } = useContext(BuffFormContext)
  const [show, setShow] = useState(false)
  const [overlayType, setOverlayType] = useState<OverlayType>(OverlayType.info)
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false)
  const target = useRef(null)
  const { gameId = '' } = useParams<{ gameId: string }>()

  const { mutateAsync: mutateResendVoteableLastState, isLoading: isResending } =
    useMutation(resendVoteableLastState)

  const updateVoteable = useUpdateVoteable(currentVodId, getBuffId(buff))

  const { data: engagementResponse } = useGetEngagement({
    gameId,
    voteableId: getBuffId(buff),
    options: { enabled: show && !isAnnouncement(buff) }, // use `enable` option to conditional run the api request
  })

  // Caches the transformation result from transformRawEngagementToEngagement
  const engagement = useMemo(() => {
    if (!engagementResponse?.engagement) return
    return transformRawEngagementToEngagement(engagementResponse.engagement)
  }, [engagementResponse])

  const { name, id } = !isAnnouncement(buff)
    ? getMetadataFromBuffType({
        lifecycle: buff.lifecycle,
        answerInterface: buff.answerInterface,
      })
    : { name: tr({ id: 'category.announcement' }), id: BuffType.Announcement }

  const containerStyles = {
    borderRadius: '5px',
    borderLeft: `6px solid var(--buff-type-${id})`,
    backgroundColor: 'white',
    boxShadow: '0 3px 4px 0 rgba(21, 30, 45, 0.08)',
  }

  const topStyles = {
    fontSize: '8px',
    fontWeight: 600,
    color: 'black',
  }

  const bottomStyles = {
    fontSize: '12px',
    color: 'black',
  }

  const selectedStyles = {
    border: '2px solid var(--primary)',
    borderRadius: '7px',
    margin: '2px 0',
  }

  const unselectedStyles = {
    border: '2px solid transparent',
    borderRadius: '7px',
    margin: '2px 0',
  }

  const disabledOverlayStyles = {
    backgroundColor: '#eeeeee',
    opacity: 0.5,
    width: '100%',
    height: '100%',
  }

  // const loadBuff = async () => {
  //   let refetchFullBuff = false

  //   buffs.forEach((b) => {
  //     if (b?.id === fullBuff?.id) {
  //       if (checkIfBuffChanged(b)) {
  //         refetchFullBuff = true
  //       }
  //     }
  //   })

  //   if (!fullBuff.id || refetchFullBuff) {
  //     try {
  //       const { data } = await buffService.getOne(buff.id)
  //       setFullBuff(data.data)
  //       if (showReplayCheckbox) setSelectedBuff(data.data)
  //     } catch (error) {
  //       addErrorToast(error)
  //     }
  //   } else {
  //     if (showReplayCheckbox) setSelectedBuff(fullBuff)
  //   }
  // }

  const onVisibleClick = () => {
    if (!updateVoteable) return
    updateVoteable.mutate(!buff?.visible)
  }

  const transformBuffForReload = (buff: Template) => {
    const languages = buff.localisations
    const defaultAnswer1 = createDefaultAnswer(languages)
    const defaultAnswer2 = createDefaultAnswer(languages)
    const defaultAnswers = [
      {
        ...defaultAnswer1,
        localisations: parseLocalisationObjectToArray(
          defaultAnswer1.localisations
        ),
      },
      {
        ...defaultAnswer2,
        localisations: parseLocalisationObjectToArray(
          defaultAnswer2.localisations
        ),
      },
    ]
    const answers = id === BuffType.StarRating ? defaultAnswers : buff.answers
    const cloneBuff = cloneDeep(buff)
    cloneBuff.answers = answers as unknown as CreateVoteableAnswer[]
    return cloneBuff
  }

  const handleClick = (
    evt: MouseEvent<HTMLButtonElement>,
    buff: Voteable | Announcement | VodVoteable
  ) => {
    evt.stopPropagation()
    setActionMode(BuffPageActionMode.Load)

    if (isVoteable(buff)) {
      setSelectedTemplate(transformBuffForReload(buff as unknown as Template))
      setMainActiveTab && setMainActiveTab(BuffPageActiveTab.BuffView)
    }

    if (isAnnouncement(buff)) {
      setSelectedAnnouncementTemplate(
        cloneDeep(buff) as unknown as AnnouncementTemplate
      )
      setMainActiveTab &&
        setMainActiveTab(BuffPageActiveTab.AnnouncementListView)
    }
  }

  const handleResolveBuffFormSubmit = async (
    payload: IResolveVoteableParams
  ) => {
    setIsFormSubmitting(true)

    try {
      await resolveVoteable(payload)
      refetchBuffs()
    } catch (error) {
      console.error(error)
      addErrorToast(error)
    }
  }

  const handleResendVoteableLastState = async () => {
    if (isVoteable(buff)) {
      try {
        await mutateResendVoteableLastState({
          gameId: buff.gameId,
          voteableId: buff.voteableId,
        })
        setShow(false)
        addToast({
          msg: tr({ id: 'buff.resendSuccess' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
      } catch (error) {
        console.error(error)
        addErrorToast(error)
      }
    }
  }

  const isSelected =
    (getBuffId(buff) && selectedVodVoteable?.id === getBuffId(buff)) || false

  const isDisabled = showReplayCheckbox

  const text = getLocalisations({
    localisations: isAnnouncement(buff)
      ? buff?.content?.localisations
      : buff?.question?.localisations,
    languages: [LanguageCode.en],
  })?.text

  const buffStatus =
    buff &&
    (isVoteable(buff)
      ? mapVoteableStatus(buff, userTime)
      : isVodVoteable(buff)
      ? mapVodVoteableStatus(buff, userTime)
      : undefined)

  const buffInfo = buffStatus && mapBuffStatusForPill(buffStatus)

  const showResendVoteableLastStateButton =
    isVoteable(buff) && process.env.ENABLE_BUFF_RESEND === 'true'
  const disableResendVoteableLastStateButton =
    isResending ||
    buffStatus === BuffStatus.Published ||
    buffStatus === BuffStatus.Scheduled

  const localisations = getLanguagesMetadata(buff?.localisations)

  const renderTooltipContent = () => {
    return (
      <TooltipContent>
        <div
          style={{
            width: '338px',
            background: 'var(--dark-navy)',
            borderRadius: '5px',
          }}
        >
          <div className="flex justify-between w-full">
            <div className="w-full pr-5">
              {overlayType === OverlayType.resolve ? (
                <h2 className="text-left">{tr({ id: 'buff.resolve' })}</h2>
              ) : (
                <div className="p-2 text-left">
                  <h6 className="sb-break-words">{text}</h6>
                </div>
              )}
            </div>
            <Button
              variant="neutral"
              shape="circle"
              className="absolute right-0 top-1"
              onClick={() => {
                setShow(!show)
              }}
            >
              <img src="/assets/icon-close.png" alt="Close tooltip" />
            </Button>
          </div>
          <div className="flex flex-col justify-start">
            {overlayType === OverlayType.resolve && (
              <div className="w-full flex justify-start">
                <p>{tr({ id: 'buff.predictionQuestion' })}</p>
              </div>
            )}
            {overlayType === OverlayType.resolve ? (
              <>
                <div className="w-full">
                  {!isAnnouncement(buff) && buff?.answers && (
                    <ResolvePredictionForm
                      buff={buff as Voteable}
                      engagement={engagement}
                      onSubmit={handleResolveBuffFormSubmit}
                    />
                  )}
                </div>
              </>
            ) : (
              <div>
                <table className="min-w-full table-fixed mb-2.5">
                  <colgroup>
                    {tableConfig.columnSizes.map((colSize, i) => (
                      <col key={i} span={1} style={{ width: colSize }} />
                    ))}
                  </colgroup>
                  <tbody>
                    {!isAnnouncement(buff) &&
                      buff?.answers &&
                      buff?.answers.map((localisedAnswer) => {
                        const answer = getLocalisations({
                          localisations: localisedAnswer?.localisations,
                          languages: [LanguageCode.en],
                        })
                        const answerEngagement =
                          engagement?.perAnswer?.[localisedAnswer?.id]

                        const imageUrl =
                          id === BuffType.StarRating
                            ? starRating
                            : answer?.imageUrl ?? ''

                        return (
                          <Fragment key={localisedAnswer.id}>
                            <BuffAnswerStats
                              showPhotoUrl
                              photoUrl={imageUrl}
                              title={answer?.text ?? ''}
                              correct={localisedAnswer?.correct}
                              percentage={answerEngagement?.percentage ?? 0}
                              totalCount={answerEngagement?.votesCounted ?? 0}
                            />
                          </Fragment>
                        )
                      })}
                  </tbody>
                </table>
                {!isAnnouncement(buff) && (
                  <div>{engagement?.votesCounted} total votes</div>
                )}
                {buff &&
                  (isVoteable(buff) || isAnnouncement(buff)) &&
                  buff.opensAt && (
                    <p className="mt-1 mb-0 text-[#D1D5DA]">
                      {formatInTimeZone(
                        buff.opensAt,
                        'dd MMM yyyy, HH:mm:ss',
                        'UTC'
                      )}{' '}
                      UTC
                    </p>
                  )}
                <CopyId id={getBuffId(buff)} />

                {showResendVoteableLastStateButton && (
                  <Button
                    className="w-full"
                    type="button"
                    disabled={disableResendVoteableLastStateButton}
                    onClick={handleResendVoteableLastState}
                  >
                    {tr({ id: 'buff.resendResult' })}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </TooltipContent>
    )
  }

  const renderComponent = () => {
    return (
      <div
        onClick={() => {
          // Check if buff is a VodVoteable buff
          // TODO: (Tech debt) Create 2 helper functions to automatically infer the voteable type (isVoteable(buff) | isVodVoteable(buff))
          if (setSelectedVodVoteable && getBuffId(buff)) {
            setSelectedVodVoteable(buff as VodVoteable)
          }
        }}
        style={
          isSelected
            ? { ...selectedStyles, position: 'relative' }
            : { ...unselectedStyles, position: 'relative' }
        }
      >
        <div style={containerStyles} className="flex items-center pr-2">
          <div
            data-testid="item-container"
            className="flex flex-col px-2 w-full"
          >
            <div
              data-testid="top-wrapper"
              className="flex items-center justify-between"
            >
              <div
                data-testid="buff-type"
                className="flex items-center text-black text-[12px]"
              >
                <Tooltip
                  open={show && overlayType === OverlayType.info}
                  onOpenChange={() => setShow(!show)}
                  placement="bottom"
                >
                  <TooltipTrigger
                    onClick={(e) => {
                      setOverlayType(OverlayType.info)
                      setShow(!show)
                      e.stopPropagation()
                    }}
                  >
                    <span className="border-none bg-white flex items-center">
                      <InfoCircleIcon className="h-4 w-4" />
                    </span>
                  </TooltipTrigger>
                  {renderTooltipContent()}
                </Tooltip>

                <span className="ml-1">{name ?? ''}</span>
              </div>
              <div
                className="inline-flex gap-2 mt-1"
                data-testid="buff-status"
                style={topStyles}
              >
                {buff &&
                  (isVoteable(buff) || isAnnouncement(buff)) &&
                  buff.opensAt && <BuffPublishedTime buff={buff} />}

                {buff?.sponsor && (
                  <TooltipPill showTooltip text="S">
                    Sponsored
                  </TooltipPill>
                )}

                <TooltipPill
                  showTooltip={buff?.localisations?.length > 1}
                  text={
                    buff?.localisations?.length > 1 ? (
                      <WorldSVG />
                    ) : (
                      localisations?.[0]?.iso6391.toUpperCase()
                    )
                  }
                >
                  <ul className="m-0 ml-2 px-2 pt-2 pb-2">
                    {localisations?.map((l, i) => (
                      <li key={`${l}-${i}`}>{l.name}</li>
                    ))}
                  </ul>
                </TooltipPill>

                {!isAnnouncement(buff) && buffInfo && (
                  <TooltipPill
                    className={`px-1.5 flex-shrink-0 ${buffInfo.bg} ${buffInfo.color}`}
                    text={
                      <div className="flex items-center gap-1">
                        <span className="inline-flex flex-shrink-0 rounded-full w-2 h-2 bg-current"></span>
                        <span>{buffInfo.text}</span>
                      </div>
                    }
                  />
                )}
              </div>
            </div>
            <div
              data-testid="bottom-wrapper"
              className="flex items-center py-1"
            >
              {/* {showReplayCheckbox && buff?.playAt ? (
              <div
                data-testid="buff-id"
                style={bottomStyles}
                className="mr-2 flex-shrink-0"
              >
                {msToHHMMSS(buff?.playAt * 1000)}
              </div>
            ) : showReplayCheckbox && !buff?.playAt ? (
              <div
                data-testid="buff-id"
                style={bottomStyles}
                className="mr-2 flex-shrink-0"
              >
                {`--:--:--`}
              </div>
            ) : (
              <div
                data-testid="buff-id"
                style={bottomStyles}
                className="mr-2 flex-shrink-0"
              >
              </div>
            )} */}

              <div
                data-testid="buff-question"
                className="text-left overflow-hidden sb-break-words line-clamp-3"
                style={{
                  ...bottomStyles,
                }}
              >
                {text}
              </div>
            </div>
          </div>
          {!isDisabled && (
            <TooltipPill
              showTooltip
              className="h-9 w-9 rounded-sm bg-[#fff] hover:bg-[#EBEBEB]"
              text={ReloadIcon}
              onClick={(e) => handleClick(e, buff)}
            >
              Reload Buff
            </TooltipPill>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="flex">
      {showReplayCheckbox && (
        <div
          className="relative ml-4 flex items-center justify-center"
          style={{ margin: '0px 15px 20px 15px' }}
        >
          <button
            type="button"
            onClick={onVisibleClick}
            className="form-check-input outline-none border bg-white mt-3 w-8 h-8 flex items-center justify-center rounded hover:!border-[#717A85] transition"
          >
            {buff?.visible ? <EyeOpenIcon /> : <EyeCloseIcon />}
          </button>
        </div>
      )}
      <span
        className="cursor-pointer"
        style={{ padding: 0, textDecoration: 'none', width: '100%' }}
        ref={target}
      >
        {renderComponent()}
      </span>
      {showResolve && (
        <Tooltip
          open={show && overlayType === OverlayType.resolve}
          onOpenChange={() => setShow(!show)}
          placement="bottom"
        >
          <TooltipTrigger
            onClick={(e) => {
              setOverlayType(OverlayType.resolve)
              setShow(!show)
            }}
          >
            <Button
              variant="primary"
              buttonType="link"
              className="ml-1"
              size="no-size"
              ref={target}
              disabled={isFormSubmitting}
            >
              {tr({ id: 'generic.resolve' })}
            </Button>
          </TooltipTrigger>
          {renderTooltipContent()}
        </Tooltip>
      )}
    </div>
  )
}

export default React.memo(BuffHistoryItem)
