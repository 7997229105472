import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { formatBytes, isFileTypeSupported } from '@utils/asset'
import { ReactComponent as ImageLineSVG } from '@assets/imageLine.svg'
import { ReactComponent as CheckMarkIcon } from '@assets/checkmark.svg'
import { ReactComponent as CloseIcon } from '@assets/close_circle.svg'
import { ReactComponent as ReloadIcon } from '@assets/reload_circle.svg'
import { AssetClass, UploadableAsset } from '@interfaces/Assets'
import { tr } from '@constants/other'
import { AssetContext } from '@services/providers/AssetProvider'
import { AssetActionTypes } from '@services/reducers/assetReducer'
import Image from '@components/atoms/Image'
import { ReactComponent as PlaceholderSVG } from '@assets/placeholder.svg'
import { ReactComponent as MusicNoteSVG } from '@assets/music-note-filled.svg'

interface UploadingAssetProps {
  asset: UploadableAsset
  onRetry: (id: string) => void
}

const UploadingAsset: FC<React.PropsWithChildren<UploadingAssetProps>> = ({
  asset,
  onRetry,
}) => {
  const [image, setImage] = useState('')
  const { dispatch } = useContext(AssetContext)
  const isImage = useMemo(
    () => isFileTypeSupported(asset.image, asset.accept),
    [asset]
  )

  // Gets the image url for the preview
  useEffect(() => {
    if (!isImage) return

    const reader = new FileReader()
    const handleAssetLoaded = () => {
      setImage(reader.result as string)
    }

    reader.addEventListener('load', handleAssetLoaded)
    reader.readAsDataURL(asset.image)

    return () => {
      reader.removeEventListener('load', handleAssetLoaded)
    }
  }, [asset, isImage])

  const handleDelete = () => {
    dispatch({ type: AssetActionTypes.Delete, payload: asset.id })
  }

  const handleRetry = () => {
    onRetry(asset.id)
  }

  return (
    <div className="flex items-center bg-white w-full p-6 my-2">
      {/* Image */}
      <div>
        {isImage ? (
          asset?.meta?.class === AssetClass.BuffSound ? (
            <MusicNoteSVG className="rounded w-[68px] h-[76px] text-[#A1A1A1]" />
          ) : (
            <Image
              alt="asset"
              className="rounded w-[68px] h-[76px]"
              src={image}
              fallbackComponent={
                <PlaceholderSVG className="rounded w-[68px] h-[76px]" />
              }
            />
          )
        ) : (
          <div className="w-6 h-6">
            <ImageLineSVG />
          </div>
        )}
      </div>
      {/* Name - progress - status */}
      <div className="flex flex-col ml-6 w-full">
        <div className="flex w-full justify-between items-end">
          <span className="text-[#717A85]">{asset?.image?.name}</span>

          {/* Blue checkmark that appears when upload completed */}
          {asset?.status == 'success' && (
            <div className="w-6 h-6">
              <CheckMarkIcon className="text-[#007BFF]" />
            </div>
          )}

          {/* Buttons that appear when on error */}
          {asset?.status == 'error' && (
            <div className="flex items-center">
              <ReloadIcon
                onClick={handleRetry}
                className="cursor-pointer w-5 h-5 text-[#959DA5] hover:text-[#F59E0B] mr-2"
              />
              <CloseIcon
                onClick={handleDelete}
                className="cursor-pointer w-5 h-5 text-[#959DA5] hover:text-[#DC2626]"
              />
            </div>
          )}
        </div>

        {/* Progress bar */}
        <div
          style={{
            width: asset.status == 'error' ? '100%' : `${asset?.progress}%`,
          }}
          className={`mt-1.5 mb-px h-1 rounded-lg ${
            asset.status == 'error' ? 'bg-[#DC2626]' : 'bg-[#007BFF]'
          }`}
        />

        {/* Status */}
        <span className="text-[#959DA5] text-sm">
          {asset?.status == 'success'
            ? tr({
                id: 'assetManager.completed',
              })
            : asset?.status == 'error'
            ? tr({
                id: 'assetManager.failed',
              })
            : `${formatBytes(asset?.sizeLoaded)} / ${formatBytes(
                asset?.sizeTotal
              )}`}
        </span>
      </div>
    </div>
  )
}
export default UploadingAsset
