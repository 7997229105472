import ILocalMessage from '@interfaces/ILocalMessage'
import enGB from './en-GB'

export const localMessages: ILocalMessage = {
  'en-US': enGB,
  'en-GB': enGB,
  en: enGB,
  pt: enGB,
  'pt-PT': enGB,
  el: enGB,
}

export const flattenMessages = (
  nestedMessages: Record<string, unknown> = {},
  prefix: string = ''
) => {
  return Object.keys(nestedMessages).reduce(
    (messages: Record<string, unknown>, key: string) => {
      const value = nestedMessages[key]
      const prefixedKey: string = prefix ? `${prefix}.${key}` : key

      if (typeof value === 'string') {
        // @ts-ignore
        messages[prefixedKey] = value
      } else {
        Object.assign(
          messages,
          flattenMessages(value as Record<string, unknown>, prefixedKey)
        )
      }

      return messages
    },
    {}
  )
}

export const getLocale = (
  messageList: ILocalMessage,
  defaultLocale: string
): string => {
  let locale = defaultLocale

  if (window && window.navigator) {
    if (navigator.languages && navigator.languages[0]) {
      locale = navigator.languages[0]
    }
  }

  if (messageList[locale]) {
    return locale
  } else {
    if (navigator.language) {
      if (messageList[navigator.language]) {
        return navigator.language
      } else {
        return defaultLocale
      }
    } else {
      return defaultLocale
    }
  }
}
