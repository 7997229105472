import React, { FC, useEffect, useState, useCallback, useRef } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { IStream } from '@interfaces/IStream'
import { tr } from '@constants/other'
import { NewStreamRef } from '@components/organisms/StreamDetails'
import StreamSidebar from '@components/organisms/StreamSidebar'
import { getStreamById } from '@services/requests/stream'
import { useToast } from '@utils/hooks/useToast'
import ContentWithSidePanelLayout from '@components/layouts/ContentWithSidePanelLayout'
import SidebarAction from '@components/SidebarAction'
import EmptyState from '@components/molecules/EmptyState'
import { useDebounceValue } from '@utils/hooks/useDebounceValue'
import { useInfiniteStreams } from '@utils/hooks/useStreams'
import { IRouteParams } from '@interfaces/RouteParams'

const StreamsPage: FC<React.PropsWithChildren<unknown>> = (): JSX.Element => {
  const { addErrorToast } = useToast()
  const [selectedStream, setSelectedStream] = useState<IStream>()

  const [search, setSearch] = useState<string>('')
  const { id: streamId = '' } = useParams<IRouteParams>()

  const newStreamsRef = useRef<NewStreamRef>(null)

  const parsedSearch = search.length < 3 ? undefined : search

  const debouncedSearch = useDebounceValue(parsedSearch)

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteStreams(
    30,
    debouncedSearch
  )
  const displayEmptyState = !isLoading && !data?.pages.length

  const getStream = useCallback(
    async (id: string) => {
      try {
        const stream = await getStreamById(id)
        setSelectedStream(stream)
      } catch (error) {
        console.error(error)
        addErrorToast(error)
      }
    },
    [addErrorToast]
  )

  useEffect(() => {
    if (streamId) getStream(streamId)
  }, [streamId, getStream])

  return (
    <ContentWithSidePanelLayout
      sidePanelTitle={tr({ id: 'generic.streams' })}
      sidePanelAction={
        <SidebarAction path={'streams'} sidebarRef={newStreamsRef} />
      }
      sidePanelContent={
        <div className="flex flex-1 flex-col">
          <StreamSidebar
            paginatedData={data?.pages}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
            search={search}
            setSearch={setSearch}
            selectedId={streamId ? selectedStream?.id : undefined}
            setSelectedStream={setSelectedStream}
          />
        </div>
      }
    >
      {displayEmptyState && (
        <EmptyState
          title={tr({ id: 'stream.emptyStateTitle' })}
          data-testid="streams-page__empty-state"
        />
      )}
      <Outlet />
    </ContentWithSidePanelLayout>
  )
}

export default StreamsPage
