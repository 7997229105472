import { IStream, StreamStatus } from '@interfaces/IStream'
import { PillColor } from '@constants/ui'
import { VodStatus } from '@interfaces/VodV2'

export const getStreamStatus = (stream?: IStream): StreamStatus | undefined => {
  if (!stream) return
  const isLive = !!stream.liveAt && !stream.endedAt
  const isDisabled = !stream.liveAt && !stream.endedAt

  // TODO: integrate with game to figure out if voting is closed or not
  return isLive
    ? StreamStatus.live
    : isDisabled
    ? StreamStatus.inactive
    : StreamStatus.vod
}

export const getStreamStatusPillColor = (status?: StreamStatus | VodStatus) => {
  switch (status) {
    case StreamStatus.vod:
    case VodStatus.editing:
      return PillColor.Orange
    case StreamStatus.live:
    case VodStatus.published:
      return PillColor.Red
    case StreamStatus.ending:
      return PillColor.DarkGray
    default:
      return PillColor.Gray
  }
}
