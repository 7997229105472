import React, { FC, useCallback, useContext } from 'react'
import AssetListItem from '@components/organisms/AssetManager/AssetListItem'
import Button from '@components/atoms/Button'
import Modal from '@components/molecules/Modal'
import IImage from '@interfaces/Image'
import { tr } from '@constants/other'
import { AssetListProps } from './types'
import {
  AssetContext,
  AssetManagerView,
} from '@services/providers/AssetProvider'
import { AssetActionTypes } from '@services/reducers/assetReducer'
import { ReactComponent as WarningSVG } from '@assets/warning.svg'

const AssetList: FC<React.PropsWithChildren<AssetListProps>> = ({
  paginatedAssets,
  onClose,
  onSelect,
  triggerRef,
  showAttachBtn = true,
  onAttachAsset,
  onPlaySound,
  showPlaySoundBtn = false,
  selectedAssets,
  type,
  maxAttachableSize = 1,
  maxAttachableSizeErrorMessage,
  openDeleteModal,
}) => {
  const { dispatch } = useContext(AssetContext)

  const handleAttaching = useCallback(
    (asset: IImage) => {
      onAttachAsset && onAttachAsset(asset)
    },
    [onAttachAsset]
  )

  return (
    <>
      {/* Modal Assets List */}
      <Modal.Body className="!overflow-y-auto">
        <div
          data-testid="asset-list__wrapper"
          className="p-3 w-full default-scroll overflow-y-scroll scroll-auto max-h-[calc(100vh-300px)] bg-gray rounded border border-[#E1E4E8]"
        >
          <div className="flex flex-wrap mx-auto max-w-[660px]">
            {paginatedAssets?.pages.map((page) => {
              return page.assets.map((asset) => {
                const isSelected = selectedAssets?.find(
                  (image) => image.id === asset.id
                )
                return (
                  <div key={asset.id}>
                    <AssetListItem
                      type={type}
                      asset={asset}
                      onSelect={onSelect}
                      isSelected={!!isSelected}
                      onAttachAsset={handleAttaching}
                      showCheckbox={Boolean(selectedAssets?.length)}
                    />
                  </div>
                )
              })
            })}
            <div ref={triggerRef} className="h-1" />
          </div>
        </div>
      </Modal.Body>

      {/* Modal Footer */}
      <div className="flex justify-between !pt-4">
        {/* Footer - Left side - Delete btn */}
        <div className="flex items-center gap-x-3">
          <Button
            variant="danger"
            onClick={() => openDeleteModal(true)}
            disabled={Boolean(!selectedAssets?.length)}
          >
            {tr({ id: 'generic.delete' })}
          </Button>

          {!!maxAttachableSizeErrorMessage &&
            selectedAssets?.length > maxAttachableSize && (
              <div className="flex items-center gap-x-1 text-danger">
                <WarningSVG className="w-4 h-4" />
                <span>{maxAttachableSizeErrorMessage}</span>
              </div>
            )}
        </div>

        {/* Footer - Right side - Manage and attach btns */}
        <div>
          <Button
            variant="secondary"
            className="mr-2"
            onClick={() => {
              dispatch({
                type: AssetActionTypes.ChangeView,
                payload: AssetManagerView.ManageAssets,
              })
            }}
            disabled={Boolean(!selectedAssets?.length)}
          >
            {tr({ id: 'generic.manage' })}
          </Button>
          {showAttachBtn && (
            <Button
              onClick={() => {
                onAttachAsset(selectedAssets)
                onClose()
              }}
              disabled={selectedAssets?.length > maxAttachableSize}
            >
              {tr({ id: 'generic.attach' })}
            </Button>
          )}
          {showPlaySoundBtn && (
            <Button
              onClick={() => {
                onPlaySound(selectedAssets?.[0])
                onClose()
              }}
              disabled={selectedAssets?.length > maxAttachableSize}
            >
              {tr({ id: 'assetManager.playSound' })}
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default AssetList
