import React, { useState, useMemo, useEffect } from 'react'
import {
  Formik,
  Form,
  FieldArray,
  FormikProps,
  useFormikContext,
  FormikHelpers,
} from 'formik'
import { get, cloneDeep, merge } from 'lodash'
import DurationInput from '@components/molecules/DurationInput'
import AutoPatchTouched from '@components/atoms/AutoPatchTouched'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import SelectInput from '@components/atoms/SelectInput'
import { Voteable } from '@interfaces/Voteable'
import { hmsStringToSeconds, msToHHMMSS } from '@utils/time'
import TextInput from '@components/atoms/TextInput'
import SelectAuthorInput from '@components/atoms/SelectAuthorInput'
import AssetManager from '@components/organisms/AssetManager'
import TimeInput from '@components/molecules/TimeInput'
import BuffFormAnswer from '@components/organisms/BuffFormAnswer'
import SponsorFormSection from '@components/molecules/SponsorFormSection'
import { PreviewProviderDataSource } from '@services/providers/PreviewProvider'
import { ColorPickerType } from '@components/molecules/ColorPicker'
import ImageFieldButton from '@components/atoms/ImageFieldButton'
import { CreateVoteableForm, VoteableLayout } from '@interfaces/Voteable'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import LanguageTabContent from '@components/molecules/LanguageTabContent'
import MonetizeTwitch from '@components/atoms/MonetizeTwitch'
import preventFormSubmitOnEnter from '@utils/preventFormSubmitOnEnter'
import { LanguageCode } from '@interfaces/ILanguage'
import { getErrorFields, getLangsFromObjectPath } from '@utils/form'
import { AssetClass } from '@interfaces/Assets'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'
import deepOmit from '@utils/deepOmit'
import { ReactComponent as Chevron } from '@assets/chevron.svg'
import { tr } from '@constants/other'
import { formatBuffDataToSubmit } from '@utils/formatBuffDataToSubmit'
import {
  BUFF_EXPIRY_DURATION_MIN_GAP,
  BUFF_EXPIRY_DURATION_MIN_GAP_TIME_SYNC,
  BUFF_MAX_DURATION,
  BUFF_MIN_DURATION,
} from '@constants/form'
import { BuffType } from '@constants/other'
import createDefaultAnswer from '@utils/createDefaultAnswer'
import { getBlankInitialValues } from '@utils/buffForm/shared'
import ReorderAnswers from '@components/molecules/ReorderAnswers'
import FormattedInput from '@components/molecules/FormattedInput'
import { AcceptImageTypeExceptGift } from '@utils/asset'
import { BuffFormProps } from './types'
import {
  DEFAULT_DURATION,
  MAX_ITEMS,
  buffTypesArray,
  buffLayoutOptions,
  standardOnlyBuffLayoutOptions,
  TWITCH_BITS_ENABLED_BUFFS,
  VERTICAL_LAYOUT_ENABLED_BUFFS,
} from './helpers'

interface BuffFormCustomSettingsErrorProps {
  onShowCustomSettings: () => void
}

const BuffFormCustomSettingsError = ({
  onShowCustomSettings,
}: BuffFormCustomSettingsErrorProps) => {
  const { errors } = useFormikContext<CreateVoteableForm>()
  const { expiresIn, schedule } = errors
  useEffect(() => {
    if (expiresIn || schedule) onShowCustomSettings()
  }, [expiresIn, schedule, onShowCustomSettings])
  return null
}

const BuffForm = ({
  authors,
  initialValues,
  buffTypes = buffTypesArray,
  displayBuffViewDropdown = true,
  displayParticipationPoints = false,
  displayPoints = true,
  isTimeSyncStream = false,
  availableLanguages,
  type = 'live',
  onSubmit,
  theme,
  getSchema,
  onReset,
}: BuffFormProps) => {
  const [activeLanguage, setActiveLanguage] = useState<LanguageCode>(
    initialValues.localisations?.[0] ?? availableLanguages[0]
  )
  const [selectedLanguages, setSelectedLanguages] = useState<LanguageCode[]>(
    initialValues.localisations ?? [availableLanguages[0]]
  )

  const [showResetModal, setShowResetModal] = useState<boolean>(false)

  const [assetManagerType, setAssetManagerType] = useState<AssetClass>()
  const [lastSelectedAnswer, setLastSelectedAnswer] = useState<number>()

  const schema = getSchema(selectedLanguages)

  const [showCustomSettings, setShowCustomSettings] = useState<boolean>(
    type === 'vod'
  )

  const chevronRotation = showCustomSettings
    ? { transform: 'rotate(180deg)' }
    : {}

  const textInputWritingMode =
    activeLanguage === LanguageCode.ar ? 'rtl' : undefined

  const authorsDropdownOptions = useMemo(() => {
    if (!authors) return []
    return authors.map((author) => {
      return {
        key: author.id,
        value: author.displayName,
        data: author,
      }
    })
  }, [authors])

  const displayDurationKey =
    initialValues.voteDurationSeconds !== undefined
      ? 'voteDurationSeconds'
      : 'displayDurationSeconds'

  // We need to set selected languages when initialValues change so it reflect changes on form languages tab
  useEffect(() => {
    if (initialValues.localisations) {
      setSelectedLanguages(initialValues.localisations)
    }
  }, [initialValues?.localisations])

  /**
   * Returned function handles updating the duration fields in the form
   *
   * @param {FormikProps} props Formik props
   * @return {Function} Function that handles duration updates
   */
  const handleDurationUpdate = ({
    values,
    setFieldValue,
  }: FormikProps<CreateVoteableForm>) => {
    /**
     * Handles updating duration value in the form when it changes
     * and will enforce a 10s gap between duration & expiry
     */

    return (displayDurationSeconds: number) => {
      setFieldValue(displayDurationKey, displayDurationSeconds)

      const expiresIn = hmsStringToSeconds(values?.expiresIn ?? '')

      const gap = isTimeSyncStream
        ? BUFF_EXPIRY_DURATION_MIN_GAP_TIME_SYNC
        : BUFF_EXPIRY_DURATION_MIN_GAP

      const minExpiry = displayDurationSeconds + gap

      if (expiresIn < minExpiry) {
        const newExpiresIn = minExpiry * 1000
        const expiresInString = msToHHMMSS(newExpiresIn)

        setFieldValue('expiresIn', expiresInString)
      }
    }
  }

  /**
   * Returned function handles resetting the duration fields in the form
   *
   * @param {CreateVoteableForm} values Form values
   * @param {Function} setFieldValue Form value setter
   */
  const handleExpireReset = (
    values: CreateVoteableForm,
    setFieldValue: (field: string, value: string) => void
  ) => {
    /**
     * Handles updating expiry value in the form
     * Will enforce a gap between duration & expiry
     * Expire will be set to duration + gap
     */

    const gap = isTimeSyncStream
      ? BUFF_EXPIRY_DURATION_MIN_GAP_TIME_SYNC
      : BUFF_EXPIRY_DURATION_MIN_GAP

    const minExpiry = (values?.[displayDurationKey] ?? DEFAULT_DURATION) + gap

    const newExpiresIn = minExpiry * 1000
    const expiresInString = msToHHMMSS(newExpiresIn)

    setFieldValue('expiresIn', expiresInString)
  }

  const getResetForm = (helpers: FormikHelpers<CreateVoteableForm>) => {
    return (blankValues: CreateVoteableForm) => {
      helpers.resetForm({ values: blankValues })
      const firstLang = availableLanguages[0]
      setActiveLanguage(firstLang)
      setSelectedLanguages([firstLang])
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, helpers) => {
        const resetForm = getResetForm(helpers)
        onSubmit?.(values, helpers, selectedLanguages, resetForm)
      }}
    >
      {(props) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          setFieldTouched,
          handleBlur,
          setValues,
        } = props

        const showAnswers =
          values?.type.toString() !== BuffType.StarRating.toString()

        const buffType = parseInt(values.type.toString())
        const showTwitchBits =
          process.env.BITS === 'true' &&
          TWITCH_BITS_ENABLED_BUFFS.includes(buffType)

        const hasMaxAnswerCount = values?.answers?.length === MAX_ITEMS

        const addIconButtonStyle = {
          backgroundColor: !hasMaxAnswerCount
            ? 'var(--primary)'
            : 'var(--white-secondary)',
          width: '50px',
          border: 'solid 1px transparent',
          borderRadius: '5px',
        }

        const buffAllLayoutOptions = VERTICAL_LAYOUT_ENABLED_BUFFS.includes(
          Number(values.type)
        )
          ? buffLayoutOptions
          : standardOnlyBuffLayoutOptions

        const showAnswerError = typeof errors?.answers === 'string'

        const authorImageKey = `author.[${activeLanguage}].imageUrl`
        const authorTextKey = `author.[${activeLanguage}].text`

        const questionKey = `question.[${activeLanguage}]`

        const sponsorFormMargin =
          parseInt(values.type.toString()) === BuffType.StarRating
            ? '-mt-4'
            : ''

        // TODO: Fix
        // Not 100% correct type but is close enough
        const previewValues = formatBuffDataToSubmit(values, false) as Voteable

        const tabErrors: Partial<Record<LanguageCode, boolean>> = {}

        const errorFields = getErrorFields(errors, touched)
        const errorLangs = [...new Set(errorFields.map(getLangsFromObjectPath))]

        errorLangs.forEach((lang) => {
          const language = lang as LanguageCode
          tabErrors[language] = true
        })

        return (
          <Form onKeyDown={preventFormSubmitOnEnter} id="buff-form">
            <AutoPatchTouched />
            <div
              data-testid="buff-details"
              className="py-3 my-3 rounded flex flex-col"
              style={{ backgroundColor: 'var(--white-background)' }}
            >
              <div className="w-100">
                <div className="px-2 flex flex-col">
                  <fieldset className="flex justify-between !m-0 !p-0">
                    <div className="flex-1 mr-2">
                      {/* Buff Author Template */}
                      <SelectAuthorInput
                        id="authorTemplate"
                        label={tr({ id: 'qaForm.authorTemplate' })}
                        data-testid="buff-form__author"
                        style={{ border: '1px solid lightgrey' }}
                        className={'w-full p-1 rounded'}
                        value={values?.authorTemplate?.key}
                        options={authorsDropdownOptions}
                        onBlur={handleBlur}
                        onChange={(authorTemplate) => {
                          if (!authorTemplate) return
                          setFieldValue('authorTemplate', authorTemplate)
                          selectedLanguages.forEach((lang) => {
                            setFieldValue(`author.[${lang}]`, {
                              text: authorTemplate?.value?.trim() || '',
                              imageUrl:
                                authorTemplate?.data?.profilePicture || '',
                            })
                          })
                        }}
                      />
                    </div>
                    <div className="flex-1 mr-2">
                      <SelectInput
                        id="type"
                        label={tr({ id: 'qaForm.type' })}
                        style={{ border: '1px solid lightgrey' }}
                        className={'w-full p-1 rounded'}
                        value={values.type}
                        options={buffTypes}
                        unSelectedContent={tr({ id: 'generic.selectOne' })}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const type = parseInt(e?.target?.value)
                          handleChange(e)
                          if (!TWITCH_BITS_ENABLED_BUFFS.includes(type)) {
                            setFieldValue('enableTwitchBits', false)
                          }

                          if (!VERTICAL_LAYOUT_ENABLED_BUFFS.includes(type)) {
                            setFieldValue(
                              'layout',
                              VoteableLayout.LAYOUT_HORIZONTAL
                            )
                          }
                        }}
                        error={Boolean(errors.type)}
                        errorLabel={errors.type}
                        data-testid="buff-form__buff-type"
                      />
                    </div>
                    {displayBuffViewDropdown && (
                      <div className="flex-1 mr-2">
                        <SelectInput
                          id="layout"
                          label={tr({ id: 'qaForm.view' })}
                          style={{ border: '1px solid lightgrey' }}
                          className={'w-full p-1 rounded'}
                          value={values.layout}
                          options={buffAllLayoutOptions}
                          unSelectedContent={tr({ id: 'generic.selectOne' })}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(errors.layout)}
                          errorLabel={errors.layout}
                          data-testid="buff-form__buff-layout"
                        />
                      </div>
                    )}
                  </fieldset>

                  <fieldset
                    className={`flex !m-0 !p-0 gap-x-4 ${
                      type === 'vod' ? 'order-last' : ''
                    }`}
                  >
                    <div>
                      {type === 'vod' ? (
                        <FormattedInput
                          id={displayDurationKey}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>{tr({ id: 'qaForm.votingDuration' })}</span>
                              <Tooltip placement="right">
                                <TooltipTrigger>
                                  <div className="flex items-center gap-x-2 cursor-pointer">
                                    <InfoCircleIcon className="w-4 h-4" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <span>
                                    {tr({ id: 'qaForm.votingDurationDesc' })}
                                  </span>
                                </TooltipContent>
                              </Tooltip>
                            </div>
                          }
                          showButtons={true}
                          className={'w-100 px-2 py-1 rounded'}
                          value={
                            values?.[displayDurationKey] ?? DEFAULT_DURATION
                          }
                          min={BUFF_MIN_DURATION}
                          max={BUFF_MAX_DURATION}
                          pattern="##:##"
                          onBlur={handleBlur}
                          onChange={handleDurationUpdate(props)}
                          onReset={() =>
                            setFieldValue(displayDurationKey, DEFAULT_DURATION)
                          }
                          error={Boolean(errors[displayDurationKey])}
                          errorLabel={errors[displayDurationKey]}
                          touched={touched[displayDurationKey]}
                        />
                      ) : (
                        <DurationInput
                          id={displayDurationKey}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>{tr({ id: 'qaForm.votingDuration' })}</span>
                              <Tooltip placement="right">
                                <TooltipTrigger>
                                  <div className="flex items-center gap-x-2 cursor-pointer">
                                    <InfoCircleIcon className="w-4 h-4" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <span>
                                    {tr({ id: 'qaForm.votingDurationDesc' })}
                                  </span>
                                </TooltipContent>
                              </Tooltip>
                            </div>
                          }
                          showButtons={true}
                          className={'w-100 px-2 py-1 rounded'}
                          value={
                            values?.[displayDurationKey] ?? DEFAULT_DURATION
                          }
                          min={BUFF_MIN_DURATION}
                          max={BUFF_MAX_DURATION}
                          onBlur={handleBlur}
                          onChange={handleDurationUpdate(props)}
                          handleReset={() =>
                            setFieldValue(displayDurationKey, DEFAULT_DURATION)
                          }
                          error={Boolean(errors[displayDurationKey])}
                          errorLabel={errors[displayDurationKey]}
                          touched={touched[displayDurationKey]}
                        />
                      )}
                    </div>

                    {!displayParticipationPoints && (
                      <div>
                        <DurationInput
                          id="resultsDurationSeconds"
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>{tr({ id: 'qaForm.resultDuration' })}</span>
                              <Tooltip placement="right">
                                <TooltipTrigger>
                                  <div className="flex items-center gap-x-2 cursor-pointer">
                                    <InfoCircleIcon className="w-4 h-4" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <span>
                                    {tr({ id: 'qaForm.resultDurationDesc' })}
                                  </span>
                                </TooltipContent>
                              </Tooltip>
                            </div>
                          }
                          showButtons={true}
                          className={'w-full px-2 py-1 rounded'}
                          value={
                            values?.['resultsDurationSeconds'] ??
                            DEFAULT_DURATION
                          }
                          min={BUFF_MIN_DURATION}
                          max={BUFF_MAX_DURATION}
                          onBlur={handleBlur}
                          onChange={(duration) =>
                            setFieldValue('resultsDurationSeconds', duration)
                          }
                          handleReset={() =>
                            setFieldValue(
                              'resultsDurationSeconds',
                              DEFAULT_DURATION
                            )
                          }
                          error={Boolean(errors['resultsDurationSeconds'])}
                          errorLabel={errors['resultsDurationSeconds']}
                          touched={touched['resultsDurationSeconds']}
                        />
                      </div>
                    )}

                    {displayParticipationPoints && (
                      <div>
                        <TextInput
                          containerClasses="w-[250px]"
                          id="participationPoints"
                          label={tr({ id: 'welcomeBuff.welcomeBuffPoints' })}
                          type="number"
                          min={0}
                          value={values.participationPoints}
                          data-testid="buff-participation-points"
                          onChange={(e) => {
                            setFieldValue(
                              'participationPoints',
                              parseInt(e.currentTarget.value, 10)
                            )
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                    )}
                  </fieldset>

                  {type !== 'vod' && (
                    <div
                      className="text-primary mb-3 mt-1 font-weight-bold inline-flex"
                      style={{ fontSize: 14, cursor: 'pointer' }}
                      onClick={() => setShowCustomSettings((val) => !val)}
                    >
                      {tr({ id: 'qaForm.customSettings' })}
                      <Chevron
                        className="ml-1 w-6 h-6"
                        style={chevronRotation}
                      />
                    </div>
                  )}

                  {showCustomSettings && (
                    <fieldset className="flex items-center justify-between !m-0 !p-0">
                      {type === 'vod' ? (
                        <div className="flex flex-col w-full xl:w-[65%]">
                          <div className="flex-1">
                            <TimeInput
                              id="publishTime"
                              label={
                                <div className="flex items-center gap-x-1">
                                  <span>
                                    {tr({ id: 'qaForm.publishTime' })}
                                  </span>
                                  <Tooltip placement="right">
                                    <TooltipTrigger>
                                      <div className="flex items-center gap-x-2 cursor-pointer">
                                        <InfoCircleIcon className="w-4 h-4" />
                                      </div>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      <span>
                                        {tr({
                                          id: 'qaForm.publishTimeDesc',
                                        })}
                                      </span>
                                    </TooltipContent>
                                  </Tooltip>
                                </div>
                              }
                              showButtons={true}
                              className={'w-100 px-2 py-1 rounded'}
                              value={values.expiresIn}
                              onBlur={handleBlur}
                              onChange={(val) =>
                                setFieldValue('expiresIn', val)
                              }
                              onReset={() =>
                                handleExpireReset(values, setFieldValue)
                              }
                              error={Boolean(errors.expiresIn)}
                              errorLabel={errors.expiresIn}
                              touched={touched.expiresIn}
                            />
                          </div>
                          <div className="flex-1">
                            <TimeInput
                              id="expiryTime"
                              label={
                                <div className="flex items-center gap-x-1">
                                  <span>{tr({ id: 'qaForm.expiryTime' })}</span>
                                  <Tooltip placement="right">
                                    <TooltipTrigger>
                                      <div className="flex items-center gap-x-2 cursor-pointer">
                                        <InfoCircleIcon className="w-4 h-4" />
                                      </div>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      <span>
                                        {tr({
                                          id: 'qaForm.expiryTimeDesc',
                                        })}
                                      </span>
                                    </TooltipContent>
                                  </Tooltip>
                                </div>
                              }
                              showButtons={true}
                              className={'w-100 px-2 py-1 rounded'}
                              value={values.expiresIn}
                              onBlur={handleBlur}
                              onChange={(val) =>
                                setFieldValue('expiresIn', val)
                              }
                              onReset={() =>
                                handleExpireReset(values, setFieldValue)
                              }
                              error={Boolean(errors.expiresIn)}
                              errorLabel={errors.expiresIn}
                              touched={touched.expiresIn}
                            />
                          </div>
                          <div className="flex-1">
                            <TimeInput
                              id="resolveTime"
                              label={
                                <div className="flex items-center gap-x-1">
                                  <span>
                                    {tr({ id: 'qaForm.resolveTime' })}
                                  </span>
                                  <Tooltip placement="right">
                                    <TooltipTrigger>
                                      <div className="flex items-center gap-x-2 cursor-pointer">
                                        <InfoCircleIcon className="w-4 h-4" />
                                      </div>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      <span>
                                        {tr({
                                          id: 'qaForm.resolveTimeDesc',
                                        })}
                                      </span>
                                    </TooltipContent>
                                  </Tooltip>
                                </div>
                              }
                              showButtons={true}
                              className={'w-100 px-2 py-1 rounded'}
                              value={values.expiresIn}
                              onBlur={handleBlur}
                              onChange={(val) =>
                                setFieldValue('expiresIn', val)
                              }
                              onReset={() =>
                                handleExpireReset(values, setFieldValue)
                              }
                              error={Boolean(errors.expiresIn)}
                              errorLabel={errors.expiresIn}
                              touched={touched.expiresIn}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="flex-1 mr-4">
                            <TimeInput
                              id="expiresIn"
                              label={tr({ id: 'qaForm.expiresIn' })}
                              showButtons={true}
                              className={'w-100 px-2 py-1 rounded'}
                              value={values.expiresIn}
                              onBlur={handleBlur}
                              onChange={(val) =>
                                setFieldValue('expiresIn', val)
                              }
                              onReset={() =>
                                handleExpireReset(values, setFieldValue)
                              }
                              error={Boolean(errors.expiresIn)}
                              errorLabel={errors.expiresIn}
                              touched={touched.expiresIn}
                            />
                          </div>

                          <div className="flex-1 mr-4">
                            <TimeInput
                              id="schedule"
                              label={tr({ id: 'qaForm.scheduleSend' })}
                              showButtons={true}
                              value={values.schedule}
                              onBlur={handleBlur}
                              onChange={(val) => setFieldValue('schedule', val)}
                              onReset={() =>
                                setFieldValue('schedule', '000:00:00')
                              }
                              error={Boolean(errors.schedule)}
                              errorLabel={errors.schedule}
                              touched={touched.schedule}
                            />
                          </div>
                          {showTwitchBits && (
                            <div className="flex-1 mr-2">
                              <MonetizeTwitch
                                value={values.enableTwitchBits}
                                onToggle={(e) => {
                                  e?.preventDefault()
                                  setFieldValue(
                                    'enableTwitchBits',
                                    !values.enableTwitchBits
                                  )
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </fieldset>
                  )}
                </div>
              </div>
            </div>
            <LanguageTabContent
              activeLanguage={activeLanguage}
              selectedLanguages={selectedLanguages}
              availableLanguages={availableLanguages}
              errors={tabErrors}
              onLanguageChange={(lang) => setActiveLanguage(lang)}
              onSelectedLanguagesChange={(langs) => {
                const removedLangs = selectedLanguages.filter(
                  (lang) => !langs.includes(lang)
                )
                const addedLangs = langs.filter(
                  (lang) => !selectedLanguages.includes(lang)
                )
                let newValues = cloneDeep(values)
                newValues.localisations = langs

                if (removedLangs.length !== 0) {
                  newValues = deepOmit(newValues, removedLangs)
                }

                if (addedLangs.length !== 0) {
                  const { question, sponsor, showSponsor, answers, author } =
                    getBlankInitialValues({
                      languages: addedLangs,
                      answerCount: values.answers.length,
                    })

                  const answersWithoutIds = answers.map(({ id, ...answer }) => {
                    return answer
                  })

                  const valuesToMerge = {
                    author,
                    question,
                    sponsor,
                    showSponsor,
                    answers: answersWithoutIds,
                  }

                  newValues = merge(newValues, valuesToMerge)
                }

                setValues(newValues)
                setSelectedLanguages(langs)
              }}
            >
              <div
                className="rounded-tr rounded-br rounded-bl bg-blue-100"
                style={{ backgroundColor: 'var(--white-background)' }}
              >
                <fieldset
                  style={{ borderBottom: '1px solid #E1E4E8' }}
                  className="flex items-center !m-0 !mb-4 !pb-3"
                >
                  <div>
                    <ImageFieldButton
                      id={authorImageKey}
                      className="mr-3"
                      altText={tr({ id: 'buff.answerImg' })}
                      image={get(values, authorImageKey)}
                      onImageRemove={() => setFieldValue(authorImageKey, '')}
                      tooltip={
                        <div className="flex items-center justify-between flex-col w-full py-2">
                          <p className="font-medium mb-0">
                            {tr({
                              id: 'generic.minImageSize',
                            })}
                          </p>
                          <p className="mb-0 text-sm text-[#F3F4F5]">
                            {tr(
                              {
                                id: 'generic.ratio',
                              },
                              { x: 4, y: 5 }
                            )}
                          </p>
                          <p className="mb-0 text-sm text-[#F3F4F5]">
                            400 x 500 px
                          </p>
                        </div>
                      }
                      onClick={() => {
                        setAssetManagerType(AssetClass.Author)
                      }}
                      onBlur={handleBlur}
                      error={Boolean(get(errors, authorImageKey))}
                      touched={Boolean(get(touched, authorImageKey))}
                    />
                  </div>
                  <div className="mt-2" style={{ width: '258px' }}>
                    <TextInput
                      id={authorTextKey}
                      label={tr({ id: 'author.authorName' })}
                      placeholder={tr({ id: 'author.authorNameText' })}
                      value={get(values, authorTextKey)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data-testid="buff-author__text"
                      error={Boolean(get(errors, authorTextKey))}
                      errorLabel={get(errors, authorTextKey)}
                      touched={Boolean(get(touched, authorTextKey))}
                      dir={textInputWritingMode}
                    />
                  </div>
                </fieldset>
                <fieldset>
                  <TextInput
                    id={questionKey}
                    label={tr({ id: 'qaForm.questionLabel' })}
                    placeholder={tr({ id: 'signIn.questionPlaceholder' })}
                    value={get(values, questionKey)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(get(errors, questionKey))}
                    errorLabel={get(errors, questionKey)}
                    touched={Boolean(get(touched, questionKey))}
                    data-testid="buff-form__question"
                    dir={textInputWritingMode}
                  />
                </fieldset>

                <fieldset>
                  {showAnswers && (
                    <ReorderAnswers
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  )}
                  <FieldArray
                    name="answers"
                    render={(arrayHelpers) => {
                      const handleAddClone = () => {
                        if (!selectedLanguages) return
                        arrayHelpers.push(
                          createDefaultAnswer(selectedLanguages)
                        )
                      }

                      return (
                        <div>
                          {showAnswers && (
                            <div id="buff-answers">
                              {values?.answers?.map((answer, i) => {
                                const key = `answers.[${i}]`
                                const answerErrors = get(errors, key)
                                const answerTouched = get(touched, key)
                                const reactKey = answer.id ?? i

                                return (
                                  <BuffFormAnswer
                                    answer={answer}
                                    showBits={values.enableTwitchBits}
                                    displayPoints={displayPoints}
                                    dir={textInputWritingMode}
                                    key={`${reactKey}`}
                                    name={key}
                                    errors={answerErrors}
                                    touched={answerTouched}
                                    lang={activeLanguage}
                                    onBlur={handleBlur}
                                    type={parseInt(
                                      values.type as unknown as string,
                                      10
                                    )}
                                    onAddPhoto={() => {
                                      setLastSelectedAnswer(i)
                                      setAssetManagerType(AssetClass.AnswerTile)
                                    }}
                                    onImageRemove={() => {
                                      const contentValue = cloneDeep(
                                        values.answers[i].localisations
                                      )
                                      if (
                                        contentValue?.[activeLanguage] != null
                                      ) {
                                        // TODO: come back to this
                                        // @ts-ignore
                                        contentValue[activeLanguage].imageUrl =
                                          ''
                                      }
                                      setFieldValue(
                                        `answers[${i}].localisations`,
                                        contentValue
                                      )
                                    }}
                                    onChange={(answer) => {
                                      setFieldValue(key, answer)
                                    }}
                                    onRemove={() => {
                                      arrayHelpers.remove(i)
                                    }}
                                    onCopyPoint={(value) => {
                                      values.answers.forEach((answer, i) => {
                                        const newAnswer = cloneDeep(answer)
                                        newAnswer.points = value || 0
                                        setFieldValue(
                                          `answers.[${i}]`,
                                          newAnswer
                                        )
                                      })
                                    }}
                                    onAllColourChange={(type, color) => {
                                      values.answers.forEach((answer, i) => {
                                        const newAnswer = cloneDeep(answer)
                                        const colorKey =
                                          type === ColorPickerType.BACKGROUND
                                            ? 'backgroundColor'
                                            : 'textColor'

                                        const langs = Object.keys(
                                          newAnswer.localisations
                                        ) as LanguageCode[]

                                        langs.forEach((lang) => {
                                          if (
                                            newAnswer?.localisations?.[lang] !=
                                            null
                                          ) {
                                            // TODO: fix
                                            // @ts-ignore
                                            newAnswer.localisations[lang][
                                              colorKey
                                            ] = color
                                          }
                                        })

                                        setFieldValue(
                                          `answers.[${i}]`,
                                          newAnswer
                                        )
                                      })
                                    }}
                                  />
                                )
                              })}

                              {showAnswerError && (
                                <p className="text-red font-italic !text-xs ml-4 mt-4">
                                  {errors.answers as string}
                                </p>
                              )}
                            </div>
                          )}

                          <div className="flex items-center justify-between p-3">
                            <div className="inline-flex items-center">
                              {showAnswers && (
                                <>
                                  <button
                                    type="button"
                                    data-testid="clone-component-add"
                                    className="pt-2"
                                    style={addIconButtonStyle}
                                    onClick={handleAddClone}
                                    disabled={hasMaxAnswerCount}
                                  >
                                    <i
                                      className=""
                                      style={{
                                        width: '24px',
                                        height: '24px',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                        display: 'inline-block',
                                        backgroundImage:
                                          'url(/assets/icon-plus.png)',
                                        margin: 'auto',
                                      }}
                                    />
                                  </button>
                                  <span className="ml-3">
                                    {tr({
                                      id: 'qaForm.addAnswer',
                                    })}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  />
                </fieldset>
                <SponsorFormSection
                  className={sponsorFormMargin}
                  activeLanguage={activeLanguage}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                  values={values}
                  errors={errors}
                  textInputWritingMode={textInputWritingMode}
                  onAddSponsorImageClick={() => {
                    setAssetManagerType(AssetClass.VoteableSponsor)
                  }}
                  title={tr({
                    id: 'qaForm.sponsor.buffSponsorship',
                  })}
                />
              </div>
            </LanguageTabContent>
            <BuffFormCustomSettingsError
              onShowCustomSettings={() => setShowCustomSettings(true)}
            />

            <ConfirmationModal
              show={showResetModal}
              successBtnText={tr({ id: 'qaForm.startAgain' })}
              onClose={() => setShowResetModal(false)}
              onSuccess={() => {
                const resetForm = getResetForm(props)
                onReset && onReset(values, resetForm)
                setShowResetModal(false)
              }}
              subtext={tr({ id: 'stream.deleteStreamConfirmationText' })}
              title={tr({ id: 'stream.deleteStreamConfirmationTitle' })}
            />
            {assetManagerType !== undefined && (
              <AssetManager
                title={
                  assetManagerType === AssetClass.AnswerTile
                    ? tr({ id: 'assetManager.assetModalListTitle' })
                    : assetManagerType === AssetClass.VoteableSponsor
                    ? tr({ id: 'assetManager.assetSponsorModalListTitle' })
                    : tr({ id: 'assetManager.assetAuthorModalListTitle' })
                }
                isOpen={true}
                type={assetManagerType}
                accept={
                  assetManagerType === AssetClass.Author
                    ? AcceptImageTypeExceptGift
                    : 'image/*'
                }
                onSelect={([image]) => {
                  const imageUrl = image.publicURL + image.fileName
                  if (
                    assetManagerType === AssetClass.AnswerTile &&
                    lastSelectedAnswer !== undefined
                  ) {
                    const index = lastSelectedAnswer
                    const contentValue = cloneDeep(
                      values.answers[index].localisations
                    )

                    if (!contentValue[activeLanguage]) {
                      contentValue[activeLanguage] = createDefaultAnswer([
                        activeLanguage,
                      ])?.localisations?.[activeLanguage]
                    }

                    if (contentValue?.[activeLanguage] != null) {
                      // TODO: fix
                      // @ts-ignore
                      contentValue[activeLanguage].imageUrl = imageUrl
                    }

                    setFieldValue(
                      `answers[${index}].localisations`,
                      contentValue
                    )
                  }

                  if (assetManagerType === AssetClass.VoteableSponsor) {
                    setFieldValue(
                      `sponsor[${activeLanguage}].imageUrl`,
                      imageUrl
                    )
                  }
                  if (
                    assetManagerType === AssetClass.Author &&
                    lastSelectedAnswer === undefined
                  ) {
                    setFieldValue(
                      `author[${activeLanguage}].imageUrl`,
                      image.publicURL + image.fileName
                    )
                  }
                  setLastSelectedAnswer(undefined)
                }}
                onClose={() => {
                  setAssetManagerType(undefined)
                }}
              />
            )}

            <PreviewProviderDataSource
              theme={theme}
              activeLanguage={activeLanguage}
              buff={previewValues}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default BuffForm
