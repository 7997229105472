import React, { FC, MouseEvent } from 'react'
import { ReactComponent as CheckBox } from '@assets/checkmark.svg'
import useSingleAndDoubleClick from '@utils/hooks/useSingleAndDoubleClick'
import { tr } from '@constants/other'
import Image from '@components/atoms/Image'
import { ReactComponent as PlaceholderSVG } from '@assets/placeholder.svg'
import { ReactComponent as MusicNoteSVG } from '@assets/music-note-filled.svg'
import { AssetListItemProps } from './types'
import { AssetClass } from '@interfaces/Assets'

const AssetListItem: FC<React.PropsWithChildren<AssetListItemProps>> = ({
  asset,
  onSelect,
  isSelected,
  showCheckbox,
  type,
  onAttachAsset,
}) => {
  const selectImage = () => onSelect(asset)

  const handleCheckboxClick = (e: MouseEvent<HTMLButtonElement>) => {
    onSelect(asset, true)
    e.stopPropagation()
  }

  const attachAsset = () => {
    onSelect(asset)
    setTimeout(() => {
      onAttachAsset(asset)
    }, 100)
  }

  const handleClick = useSingleAndDoubleClick(selectImage, attachAsset)

  const mainContainerStyles = {
    border: isSelected ? '1px solid var(--primary)' : '1px solid #E1E4E8',
    boxShadow: isSelected
      ? '0px 0px 0px 2px rgb(0 123 255 / 50%), 0px -1px 4px rgb(25 28 31 / 4%), 0px 1px 2px rgb(25 28 31 / 6%), 0px 3px 6px rgb(25 28 31 / 6%)'
      : '0px -1px 4px rgba(25, 28, 31, 0.04), 0px 1px 2px rgba(25, 28, 31, 0.06), 0px 3px 6px rgba(25, 28, 31, 0.06)',
  }

  const nameContainerStyles = {
    width: '120px',
    backgroundColor: 'var(--white)',
    fontSize: '.75rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }

  const checkboxBgColor = isSelected && 'bg-primary'

  const assetTitle = asset?.title
  const assetImageUrl = asset?.publicURL + asset?.fileName

  return (
    <div
      onClick={handleClick}
      data-testid="asset-item-container"
      className="flex flex-col relative bg-white rounded mx-1 my-2 cursor-pointer"
      style={mainContainerStyles}
    >
      {showCheckbox && (
        <button
          type="button"
          onClick={handleCheckboxClick}
          className={`flex text-white absolute left-1 top-1 justify-center items-center h-6 w-6 border-[1px] border-solid !border-[#959DA5] p-0 rounded ${checkboxBgColor}`}
        >
          {isSelected ? <CheckBox width="70%" /> : <div />}
        </button>
      )}

      {type === AssetClass.BuffSound ? (
        <div className="flex items-center justify-center rounded-t border-solid border-t-0 border-r-0 border-l-0 border-b border-[#E1E4E8] text-[#A1A1A1] w-[120px] h-[106px]">
          <MusicNoteSVG className="text-[#A1A1A1] w-12 h-12" />
        </div>
      ) : (
        <Image
          alt={tr({ id: 'assetManager.assetItem' })}
          className="rounded-t border-solid border-t-0 border-r-0 border-l-0 border-b border-[#E1E4E8] w-[120px] h-[106px]"
          src={assetImageUrl}
          fallbackComponent={
            <PlaceholderSVG className="rounded-t border-solid border-t-0 border-r-0 border-l-0 border-b border-[#E1E4E8] w-[120px] h-[106px]" />
          }
        />
      )}

      {assetTitle && (
        <div
          data-testid="asset-item-name"
          className="px-2 py-1 rounded-b text-center"
          style={{ ...nameContainerStyles, whiteSpace: 'nowrap' }}
        >
          {assetTitle}
        </div>
      )}
    </div>
  )
}

export default React.memo(AssetListItem)
