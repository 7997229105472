import { object, string, AnySchema } from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { tr } from '@constants/other'
import { LanguageCode } from '@interfaces/ILanguage'
import { getBannerFormSectionSchema } from '@components/molecules/BannerFormSection/schema'
import bitSchema from '@components/molecules/VipLeaderboardModal/schema'

/**
 * Gets the validation schema for the form
 * @param {LanguageCode[]} languages An array of languages selected by the user
 * @param {boolean} isVIP Whether the form is for a VIP leaderboard
 * @return {any} Schema
 */
export const getSchema = (languages: LanguageCode[], isVIP: boolean) => {
  const contentSchema: Partial<Record<string, AnySchema>> = ({} = {})

  languages.forEach((lang) => {
    const content = {
      title: string().required(tr({ id: 'validation.required' })),
      banner: getBannerFormSectionSchema(lang),
    }

    contentSchema[lang] = object().shape(content)
  })

  return object().shape({
    content: object().shape(contentSchema as ObjectShape),
    name: string().required(tr({ id: 'validation.required' })),
  })
  // .concat(isVIP ? bitSchema : object())
}
