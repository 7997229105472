import React, { forwardRef, PropsWithChildren } from 'react'
import { Size } from '@interfaces/Size'
import { ContainerProps } from './types'

const classBySize: Partial<Record<Size, string>> = {
  sm: 'sm:max-w-[610px]',
  md: 'md:max-w-[720px]',
  lg: 'lg:max-w-[960px]',
  xl: 'xl:max-w-[1200px]',
  xxl: 'xxl:max-w-[1440px]',
}

const Container = forwardRef<HTMLDivElement, PropsWithChildren<ContainerProps>>(
  (
    { className = '', size, 'data-testid': testId = 'container', children },
    ref
  ) => {
    const index = Object.keys(classBySize).findIndex((s) => s === size)
    const classes =
      index != -1 && Object.values(classBySize).splice(index).join(' ')

    return (
      <div
        data-testid={testId}
        className={`mx-auto w-full px-[15px] ${classes} ${className}`}
        ref={ref}
      >
        {children}
      </div>
    )
  }
)

Container.displayName = 'Container'

export default Container
