import React, { createContext, FC, useState } from 'react'
import { VodVoteable } from '@interfaces/Vod'

interface VodContextProps {
  selectedVodVoteable: VodVoteable | null
  setSelectedVodVoteable: (vodVoteable: VodVoteable) => void
  currentVodId: string | null
  setCurrentVodId: (vodId: string | null) => void
}

export const VodContext = createContext<VodContextProps>({} as VodContextProps)

export const VodProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [currentVodId, setCurrentVodId] = useState<string | null>(null)
  const [selectedVodVoteable, setSelectedVodVoteable] =
    useState<VodVoteable | null>(null)

  return (
    <VodContext.Provider
      value={{
        selectedVodVoteable,
        setSelectedVodVoteable,
        currentVodId,
        setCurrentVodId,
      }}
    >
      {children}
    </VodContext.Provider>
  )
}
