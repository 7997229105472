import { AssetClass, GetAssetsResponse } from '@interfaces/Assets'
import { client } from '@services/requests/client'
import { getPaginatedUrl } from '@utils/url'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'

export const uploadAsset = async (
  assets: FormData,
  options: AxiosRequestConfig
): Promise<AxiosResponse> => {
  const response = await client.post(`/assets`, assets, options)
  return response
}

interface GetAssetsParams {
  pageSize: number
  pageParam: string
  filter: string
  search?: string
}

export const getAssets = async (
  params: GetAssetsParams
): Promise<GetAssetsResponse> => {
  const url = '/assets'

  let paginatedUrl = getPaginatedUrl(url, params?.pageSize, params?.pageParam)

  if (params?.filter) {
    paginatedUrl += `&filter=class:${params?.filter}`
  }

  if (params?.search) {
    paginatedUrl += `&query=${encodeURIComponent(params?.search)}`
  }

  const response = await client.get(paginatedUrl)
  return {
    assets: response.data.assets,
    nextPage: response.data.pagination.next,
  }
}

interface UseGetAssets {
  limit?: number
  filter: AssetClass
  search?: string
}

export const useGetAssets = (params: UseGetAssets) => {
  return useInfiniteQuery(
    `assets-${params.filter || 'all'}`,
    ({ pageParam = '' }) =>
      getAssets({
        ...params,
        pageParam,
        pageSize: params?.limit || 30,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.nextPage?.length) return undefined
        return lastPage?.nextPage
      },
    }
  )
}

/*****************************************
 * DELETE requests
 ******************************************/

export const useDeteleAssets = (id: string, type: AssetClass) => {
  const queryClient = useQueryClient()

  return useMutation(() => client.delete(`/assets/${id}`), {
    onMutate: () => {
      queryClient.cancelQueries(`assets-${type || 'all'}`)
    },
    onSettled: () => {
      queryClient.invalidateQueries(`assets-${type || 'all'}`)
    },
  })
}

interface UseDeleteMultipleAssetsPayload {
  ids: string[]
}

export const useDeteleMultipleAssets = (type: AssetClass) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ ids }: UseDeleteMultipleAssetsPayload) =>
      client.delete('/assets', {
        data: { ids },
      }),
    {
      onMutate: () => {
        queryClient.cancelQueries(`assets-${type || 'all'}`)
      },
      onSettled: () => {
        queryClient.invalidateQueries(`assets-${type || 'all'}`)
      },
    }
  )
}

/*****************************************
 * PATCH requests
 ******************************************/
interface UseUpdateAssetMetadataPayload {
  id: string
  payload: {
    title: string
    description: string
  }
}

export const useUpdateAssetMetadata = (type: AssetClass) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, payload }: UseUpdateAssetMetadataPayload) =>
      client.patch(`/assets/${id}`, payload),
    {
      onMutate: () => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        queryClient.cancelQueries(`assets-${type || 'all'}`)
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(`assets-${type || 'all'}`)
      },
    }
  )
}
