import { useInfiniteQuery } from 'react-query'

import { getAuthors } from '@services/requests/author'

/**
 * @param {number} limit Number of items to return per page
 * @param {string} query query param used when requesting streams
 * @return {Author[]}
 */
export default function useAuthors(limit: number = 30, query?: string) {
  const key = query ? `authors?${query}` : 'authors'
  return useInfiniteQuery(
    key,
    ({ pageParam = '' }) => getAuthors(limit, pageParam, query),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.nextPage?.length) return undefined

        return lastPage?.nextPage
      },
    }
  )
}
