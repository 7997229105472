import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'
import Button from '@components/atoms/Button'
import { tr } from '@constants/other'
import SelectInput from '@components/atoms/SelectInput'
import LanguagePicker from '@components/molecules/LanguagePicker'
import { useToast } from '@utils/hooks/useToast'
import useLanguageStore from '@services/stores/useLanguageStore'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'

import { ClientLanguagesProps, LanguagesFormValues } from './types'

const ClientLanguages = (_: ClientLanguagesProps) => {
  const { addToast, addErrorToast } = useToast()
  const { allLanguages, clientLanguages, updateClientLanguages, isFetching } =
    useLanguageStore()

  const initialValues = useMemo(() => {
    return {
      default: clientLanguages.default,
      enabled: allLanguages?.map((language) => {
        return {
          ...language,
          selected: clientLanguages.enabled.includes(language.iso6391),
        }
      }),
    }
  }, [clientLanguages, allLanguages])

  const defaultLanguageOptions = useMemo(() => {
    if (!allLanguages?.length) return []
    return allLanguages.map((language) => ({
      key: language.iso6391,
      value: language.name,
    }))
  }, [allLanguages])

  const handleSubmit = async (values: LanguagesFormValues) => {
    try {
      await updateClientLanguages(values)
      addToast({
        msg: tr({ id: 'configuration.localeSettingsUpdated' }),
        type: 'success',
        image: <Tick className="mr-3 w-8 h-8" />,
      })
    } catch (error) {
      addErrorToast(error)
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => {
          const isTouched = Object.values(touched).some((t) => t)

          return (
            <Form>
              <fieldset
                className="p-4 rounded-lg mb-4 bg-white-background"
                data-testid="languages"
              >
                <SelectInput
                  containerClasses="max-w-[300px]"
                  id="default"
                  name="default"
                  value={values.default}
                  options={defaultLanguageOptions}
                  onChange={(e) => {
                    if (!values.enabled?.length) return
                    handleChange(e)
                    const languagesToChange = [...values.enabled]
                    const index = languagesToChange.findIndex(
                      (l) => l.iso6391 === e.target.value
                    )
                    languagesToChange[index].selected = true
                    setFieldValue('enabled', languagesToChange)
                    setFieldTouched('default', true)
                  }}
                  onBlur={handleBlur}
                  error={Boolean(errors.default)}
                  errorLabel={errors.default}
                  touched={touched.default}
                  label={tr({ id: 'configuration.defaultLanguage' })}
                />

                <hr className="mt-4 mb-3" />

                <div className="flex flex-col">
                  <p className="m-0 mb-1">
                    {tr({
                      id: 'configuration.supportedLanguages',
                    })}
                  </p>
                  <LanguagePicker
                    value={values.enabled}
                    disabled={false}
                    nonSelectableLanguages={[values.default]}
                    onChange={(langs) => {
                      handleBlur({ target: { name: 'enabled' } })
                      setFieldValue('enabled', langs)
                    }}
                  />
                </div>
              </fieldset>

              <div className="flex justify-end">
                <Button type="submit" disabled={!isTouched || isFetching}>
                  {tr({
                    id: 'generic.update',
                  })}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ClientLanguages
