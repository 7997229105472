import { LanguageCode } from './ILanguage'

export enum AssetClass {
  AnswerTile = 'answer-tile',
  VoteableSponsor = 'voteable-sponsor',
  LeaderboardSponsor = 'leaderboard-sponsor',
  AnnouncementTile = 'announcement-tile',
  Author = 'author',
  ProfilePicture = 'profile-picture',
  BuffSound = 'buff-sound',
  Reactions = 'reaction-image',
  Moderator = 'chat-moderator-image',
}

export enum AssetComponentMode {
  Modal = 'modal',
  List = 'list',
}

interface AssetDimensions {
  width: number
  height: number
}

export interface Asset {
  altText: Partial<Record<LanguageCode, string>>
  class: AssetClass
  createdAt: string
  description: string
  dimensions: AssetDimensions
  fileName: string
  id: string
  mimeType: string
  publicURL: string
  size: number
  title: string
  updatedAt: string
}

export interface UploadableAssetMetadata {
  filename: string
  title: string
  class?: AssetClass
  description: string
  altText: string
}

export interface UploadableAsset {
  id: string
  image: File
  meta: UploadableAssetMetadata
  progress: number
  status: 'error' | 'success' | 'pending' | 'uploading'
  sizeError: boolean
  sizeLoaded: number
  sizeTotal: number
  cancelRequest: any
  accept?: string
}

export interface GetAssetsResponse {
  assets: Asset[]
  nextPage: string
}
