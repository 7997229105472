import React, { FC } from 'react'
import { FieldArray, useFormikContext } from 'formik'
import TextInput from '@components/atoms/TextInput'
import { get } from 'lodash'
import Button from '@components/atoms/Button'
import { ReactComponent as AddCross } from '@assets/add-new-cross.svg'
import { ReactComponent as TrashSVG } from '@assets/trash.svg'
import { MultiInputFormValues, MultiInputProps } from './types'

const MultiInput = <T extends MultiInputFormValues>({
  name,
  className = '',
  label,
  labelClasses = '',
  inputClasses = '',
  placeholder,
  max = 5,
  'data-testid': testId = 'multi-input',
}: MultiInputProps<T>) => {
  const { values, touched, errors, handleBlur, handleChange } =
    useFormikContext<MultiInputFormValues>()

  const arrayValues = values[name]

  return (
    <div className={className}>
      {label && (
        <label
          className={`form-check-label pb-1 ${labelClasses}`}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <div>
            {arrayValues &&
              arrayValues.length > 0 &&
              arrayValues.map((value, index) => {
                const key = `${name}.${index}`
                const isTouched = get(touched, key)
                const errorLabel = get(errors, key) as string

                return (
                  <div
                    className="flex w-full my-1"
                    key={key}
                    data-testid={testId}
                  >
                    <TextInput
                      placeholder={placeholder}
                      style={{ border: '1px solid lightgrey' }}
                      className={'w-full px-2 py-1 rounded'}
                      containerClasses="w-[75%]"
                      inputContainerClasses={`!min-h-0 ${inputClasses}`}
                      error={Boolean(errorLabel)}
                      errorLabel={errorLabel}
                      touched={isTouched}
                      data-testid={`${testId}-${index}`}
                      name={key}
                      id={key}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={value}
                    />
                    <div className="inline-flex mt-1">
                      <Button
                        data-testid={`${testId}-${index}-remove-button`}
                        size="mini"
                        variant="neutral"
                        type="button"
                        disabled={arrayValues.length === 1}
                        onClick={() => {
                          arrayHelpers.remove(index)
                        }}
                      >
                        <TrashSVG className="text-danger w-4 h-4" />
                      </Button>
                      {index === arrayValues.length - 1 && (
                        <Button
                          data-testid={`${testId}-add-button`}
                          size="mini"
                          variant="neutral"
                          type="button"
                          disabled={
                            arrayValues.length === max ||
                            !value?.trim() ||
                            Boolean(errorLabel)
                          }
                          onClick={() => {
                            arrayHelpers.insert(index + 1, '')
                          }}
                        >
                          <AddCross className="w-5 h-5 fill-black" />
                        </Button>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        )}
      />
    </div>
  )
}

export default MultiInput
