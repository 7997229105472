import React, {
  ChangeEvent,
  forwardRef,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import clsx from 'clsx'
import Button, { ButtonVariant } from '@components/atoms/Button'
import {
  AssetContext,
  AssetManagerView,
} from '@services/providers/AssetProvider'
import { AssetActionTypes } from '@services/reducers/assetReducer'
import TextInput from '@components/atoms/TextInput'
import { tr } from '@constants/other'
import { debounce } from 'lodash'
import { AssetManagerHeaderProps } from './types'

const AssetManagerHeader = forwardRef<
  HTMLInputElement,
  AssetManagerHeaderProps
>(({ title, onClick, onSearch, className, withFilters = false }, ref) => {
  const { state, dispatch } = useContext(AssetContext)

  const [search, setSearch] = useState('')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const debounceSearch = useCallback(
    debounce((text) => onSearch?.(text), 500),
    []
  )

  useEffect(() => {
    const searchText = search?.trim()?.length >= 3 ? search : ''
    debounceSearch(searchText)
  }, [search])

  return (
    <div className={clsx('flex justify-between', className)}>
      <span className="text-[24px]">{title}</span>
      {(state?.view == AssetManagerView.AssetList || withFilters) && (
        <div className="flex items-center">
          <div className="-mb-2 mx-2">
            <TextInput
              inputRef={ref as MutableRefObject<HTMLInputElement>}
              key={`search`}
              autoFocus
              placeholder={tr({ id: 'generic.search' })}
              value={search}
              onChange={handleChange}
              customInputStyle={{
                backgroundImage: `url("/assets/svg/search.svg")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '20px 20px',
                backgroundPosition: '2% center',
                paddingLeft: 25,
              }}
            />
          </div>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={() => {
              onClick && onClick()
              dispatch({
                type: AssetActionTypes.ChangeView,
                payload: AssetManagerView.UploadAssets,
              })
            }}
          >
            Upload
          </Button>
        </div>
      )}
    </div>
  )
})

AssetManagerHeader.displayName = 'AssetManagerHeader'

export default AssetManagerHeader
