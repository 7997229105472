import {
  Standing,
  Standings,
  StandingWithMetadata,
} from '@interfaces/ILeaderboard'
import { fetchUsersBatch } from '@services/requests/fetchUsersBatch'

const prepareLeaderboardData = async (
  standings: Standings
): Promise<StandingWithMetadata[]> => {
  if (standings.length === 0) {
    return []
  }
  const userIds = standings.map((standing) => standing.entityId)

  // TODO: Is BE going to enforce the order and data for the usersBatch? If so then we don't need the hash+order
  // Come back to this once everything -leaderboard related- is done by BE.
  const userIdsHash: Record<string, Standing> = standings.reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr.entityId]: { ...curr },
      }
    },
    {}
  )
  const usersMetadata = await fetchUsersBatch(userIds)

  usersMetadata.forEach((userMeta) => {
    userIdsHash[userMeta.id] = {
      ...userIdsHash[userMeta.id],
      ...userMeta,
    }
  })

  const orderedUsers = Object.keys(userIdsHash)
    .map((user) => userIdsHash[user])
    .sort((rankingA, rankingB) => {
      if (
        rankingA.standing.rank === undefined &&
        rankingB.standing.rank === undefined
      ) {
        return rankingB.standing.totalPoints - rankingA.standing.totalPoints
      }

      if (rankingA.standing.rank === undefined) return 1
      if (rankingB.standing.rank === undefined) return -1
      return rankingA.standing.rank - rankingB.standing.rank
    })

  return orderedUsers as StandingWithMetadata[]
}

export default prepareLeaderboardData
