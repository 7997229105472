import React, { useEffect, FC } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'

import HomePage from '@pages/HomePage'
import SignInPage from '@pages/SignInPage'
import NotFoundPage from '@pages/NotFoundPage'
import StreamsPage from '@pages/StreamsPage'
import AuthorsPage from '@pages/AuthorsPage'
import BuffPage from '@pages/BuffPage'
import StreamManagement from '@pages/StreamManagement'
import StreamReplayPage from '@pages/StreamReplayPage'
import ClientConfig from '@pages/ClientConfig'
import NewTemplate from '@pages/NewTemplate'
import Metrics from '@pages/Metrics'
import GlobalTemplates from '@pages/StreamManagement/GlobalTemplates'
import TeamMembers from '@pages/TeamMembers'
import VideoOnDemand from '@pages/VideoOnDemand'
import VodEdit from '@pages/VodEdit'
import VodNew from '@pages/VodNew'
import VodImport from '@pages/VodImport'
import { ToastProvider } from '@services/providers/ToastProvider'
import { BuffProvider } from '@services/providers/BuffProvider'
import { WelcomeBuffProvider } from '@services/providers/WelcomeBuffProvider'
import { AssetProvider } from '@services/providers/AssetProvider'
import { ErrorProvider } from '@services/providers/ErrorProvider'
import { LanguageProvider } from '@services/providers/LanguageProvider'
import { TemplateProvider } from '@services/providers/TemplateProvider'
import { BuffFormProvider } from '@services/providers/BuffFormProvider'
import { PreviewProvider } from '@services/providers/PreviewProvider'
import { PlatformProvider } from '@services/providers/PlatformProvider'
import { VodProvider } from '@services/providers/VodProvider'
import ErrorModalWrapper from '@components/organisms/ErrorModalWrapper'
import { BUFFUP_DASHBOARD_TOKEN, BUFFUP_REFRESH_TOKEN } from '@constants/other'
import OnlineLayout from '@components/layouts/OnlineLayout'
import OnlineLayoutNonScrollable from '@components/layouts/OnlineLayoutNonScrollable'
import StreamConfig from '@components/organisms/StreamDetails/StreamConfig/StreamConfig'
import StreamDetails from '@components/organisms/StreamDetails'
import StreamLeaderboardDetails from '@components/organisms/StreamLeaderboardDetails'
import ReactionsMetrics from '@components/organisms/ReactionsMetrics'

const ProjectRoutes: FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const expiredRefreshToken = () => {
      localStorage.removeItem(BUFFUP_DASHBOARD_TOKEN)
      localStorage.removeItem(BUFFUP_REFRESH_TOKEN)
      navigate('/signin')
    }
    window.addEventListener('expiredRefreshToken', expiredRefreshToken)
    return () => {
      window.removeEventListener('expiredRefreshToken', expiredRefreshToken)
    }
  }, [navigate])

  const StreamsRender = (
    <OnlineLayout>
      <PlatformProvider>
        <LanguageProvider>
          <WelcomeBuffProvider>
            <StreamsPage />
          </WelcomeBuffProvider>
        </LanguageProvider>
      </PlatformProvider>
    </OnlineLayout>
  )

  const GlobalTemplatesRender = (
    <OnlineLayout>
      <PreviewProvider>
        <BuffProvider>
          <LanguageProvider>
            <TemplateProvider>
              <BuffFormProvider>
                <GlobalTemplates />
              </BuffFormProvider>
            </TemplateProvider>
          </LanguageProvider>
        </BuffProvider>
      </PreviewProvider>
    </OnlineLayout>
  )

  const PlayRender = (
    <OnlineLayout>
      <BuffProvider>
        <PreviewProvider>
          <LanguageProvider>
            <TemplateProvider>
              <BuffFormProvider>
                <BuffPage />
              </BuffFormProvider>
            </TemplateProvider>
          </LanguageProvider>
        </PreviewProvider>
      </BuffProvider>
    </OnlineLayout>
  )

  const StreamManagementRender = (
    <OnlineLayout>
      <LanguageProvider>
        <PreviewProvider>
          <TemplateProvider>
            <BuffFormProvider>
              <WelcomeBuffProvider>
                <StreamManagement />
              </WelcomeBuffProvider>
            </BuffFormProvider>
          </TemplateProvider>
        </PreviewProvider>
      </LanguageProvider>
    </OnlineLayout>
  )

  const ClientConfigRender = (
    <OnlineLayout>
      <PreviewProvider>
        <ClientConfig />
      </PreviewProvider>
    </OnlineLayout>
  )

  const AuthorRender = (
    <OnlineLayout>
      <AssetProvider>
        <AuthorsPage />
      </AssetProvider>
    </OnlineLayout>
  )

  const VodRender = (
    <OnlineLayout>
      <LanguageProvider>
        <VideoOnDemand />
      </LanguageProvider>
    </OnlineLayout>
  )

  const VodEditRender = (
    <OnlineLayout>
      <PreviewProvider>
        <LanguageProvider>
          <VodEdit />
        </LanguageProvider>
      </PreviewProvider>
    </OnlineLayout>
  )

  const VodNewRender = (
    <OnlineLayout>
      <PreviewProvider>
        <LanguageProvider>
          <BuffFormProvider>
            <VodNew />
          </BuffFormProvider>
        </LanguageProvider>
      </PreviewProvider>
    </OnlineLayout>
  )

  const VodImportRender = (
    <OnlineLayout>
      <PreviewProvider>
        <LanguageProvider>
          <TemplateProvider>
            <VodImport />
          </TemplateProvider>
        </LanguageProvider>
      </PreviewProvider>
    </OnlineLayout>
  )

  return (
    <ToastProvider>
      <ErrorProvider>
        <ErrorModalWrapper />

        <Routes>
          {/* *** AUTH ***  */}
          <Route path="/signin" element={<SignInPage />} />
          {/* *** HOME *** */}
          <Route
            path="/"
            element={
              <OnlineLayout>
                <HomePage />
              </OnlineLayout>
            }
          />

          {/* *** PLAY *** */}
          <Route path="/streams/:id/:gameId/play" element={PlayRender} />
          <Route
            path="/streams/:id/:gameId/play/templates/*"
            element={
              <OnlineLayout>
                <PreviewProvider>
                  <BuffProvider>
                    <LanguageProvider>
                      <TemplateProvider>
                        <BuffFormProvider>
                          <NewTemplate />
                        </BuffFormProvider>
                      </TemplateProvider>
                    </LanguageProvider>
                  </BuffProvider>
                </PreviewProvider>
              </OnlineLayout>
            }
          />
          <Route
            path="/streams/:id/:gameId/replay"
            element={
              <OnlineLayout>
                <VodProvider>
                  <StreamReplayPage />
                </VodProvider>
              </OnlineLayout>
            }
          />

          {/* *** STREAMS *** */}
          <Route path="streams" element={StreamsRender}>
            <Route path="new" element={<StreamDetails />}>
              <Route path="/streams/new" element={<StreamConfig />} />
            </Route>
            <Route path=":id/:gameId" element={<StreamDetails />}>
              <Route
                path="/streams/:id/:gameId/"
                element={<Navigate to="stream-details" />}
              />
              <Route path="stream-details" element={<StreamConfig />} />
              <Route path="appearance" element={<StreamConfig />} />
              <Route path="advanced-settings" element={<StreamConfig />} />
              <Route path="leaderboard-list" element={<StreamConfig />} />
              <Route path="reactions" element={<StreamConfig />} />
              <Route path="metrics" element={<StreamConfig />} />
              <Route path="global-chat" element={<StreamConfig />}>
                <Route path="chat" element={<StreamConfig />} />
                <Route path="chat-metrics" element={<StreamConfig />} />
                <Route path="settings" element={<StreamConfig />} />
              </Route>
              <Route path="reactions/metrics" element={<ReactionsMetrics />} />
              <Route path="leaderboard" element={<StreamLeaderboardDetails />}>
                <Route path="new" element={<StreamLeaderboardDetails />} />
                <Route
                  path=":leaderboardId"
                  element={<StreamLeaderboardDetails />}
                >
                  <Route
                    path="standings"
                    element={<StreamLeaderboardDetails />}
                  />
                  <Route
                    path="stream-list"
                    element={<StreamLeaderboardDetails />}
                  />
                  <Route
                    path="settings"
                    element={<StreamLeaderboardDetails />}
                  />
                  <Route
                    path="monetisation"
                    element={<StreamLeaderboardDetails />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* *** VOD *** */}

          {/* TODO: put this route inside VodRender and make VodRender dummy component. Currently we can't put this route
           inside VodRender because VodRender contains components that are not needed in /edit */}
          <Route path="/vod/:id/edit/*" element={VodEditRender} />
          <Route path="/vod/:id/import/*" element={VodImportRender} />
          <Route path="/vod/:id/buff/new" element={VodNewRender} />
          <Route path="/vod/:id/announcements/new" element={VodNewRender} />
          <Route path="/vod/:id/buff/:entityId" element={VodNewRender} />
          <Route
            path="/vod/:id/announcements/:entityId"
            element={VodNewRender}
          />
          <Route path="/vod/*" element={VodRender}>
            <Route path="new" element={VodRender} />
            <Route path=":id/*" element={VodRender} />
          </Route>

          {/* *** AUTHORS *** */}
          <Route path="authors" element={AuthorRender}>
            <Route path="new" element={AuthorRender} />
            <Route path=":id" element={AuthorRender} />
          </Route>

          {/* *** STREAM MANAGEMENT *** */}
          <Route
            path="/stream-management/global-templates/buff/new"
            element={GlobalTemplatesRender}
          />
          <Route
            path="/stream-management/global-templates/announcements/new"
            element={GlobalTemplatesRender}
          />
          <Route
            path="/stream-management/global-templates/buff/:id"
            element={GlobalTemplatesRender}
          />
          <Route
            path="/stream-management/global-templates/announcements/:id"
            element={GlobalTemplatesRender}
          />

          <Route path="/stream-management/*" element={StreamManagementRender} />

          <Route path="/client-config/*" element={ClientConfigRender} />

          {/* *** METRICS *** */}
          <Route
            path="/metrics"
            element={
              <OnlineLayoutNonScrollable>
                <Metrics />
              </OnlineLayoutNonScrollable>
            }
          />
          {/* *** TEAM MEMBERS *** */}
          <Route
            path="/team-members"
            element={
              <OnlineLayout>
                <TeamMembers />
              </OnlineLayout>
            }
          />
          <Route path="/404" element={<NotFoundPage />} />
        </Routes>
      </ErrorProvider>
    </ToastProvider>
  )
}

export default ProjectRoutes
