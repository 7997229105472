import React, { FC, useRef } from 'react'
import { Formik, Form, FormikProps } from 'formik'
import SelectInput from '@components/atoms/SelectInput'
import { tr } from '@constants/other'
import { StreamConfigThemeFormValues } from '@interfaces/IStreamConfig'
import AutoSubmitForm from '@components/atoms/AutoSubmitForm'
import { useTheme } from '@utils/hooks/useTheme'
import { IThemeSelectionProps } from './types'

const ThemeSelection: FC<React.PropsWithChildren<IThemeSelectionProps>> = ({
  onSubmit,
  config,
}) => {
  const formRef = useRef<FormikProps<StreamConfigThemeFormValues>>(null)
  const { themeOptions, theme } = useTheme(config?.theme)

  const handleSubmit = async (values: StreamConfigThemeFormValues) => {
    onSubmit(values)
  }

  return (
    <div
      className="p-4 rounded-lg"
      style={{ backgroundColor: 'var(--white-background)' }}
      data-testid="theme-selection"
    >
      {!!config && (
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            theme,
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange }) => {
            return (
              <Form>
                <fieldset
                  className="rounded-lg"
                  style={{ backgroundColor: 'var(--white-background)' }}
                  data-testid="theme-settings"
                >
                  <legend className="m-0 text-[1.125rem] font-bold float-left">
                    {tr({ id: 'appearance.themeSelection' })}
                  </legend>

                  <div className="clear-both pt-4">
                    <div className="flex items-center">
                      <SelectInput
                        label={tr({ id: 'appearance.activeTheme' })}
                        value={values.theme}
                        id="theme"
                        name="theme"
                        data-testid="theme-select"
                        onChange={handleChange}
                        options={themeOptions}
                      />
                    </div>
                  </div>
                </fieldset>
                <AutoSubmitForm />
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

export default ThemeSelection
