import React, { useContext, useMemo, useState } from 'react'
import { TemplateContext } from '@services/providers/TemplateProvider'
import { BuffFormContext } from '@services/providers/BuffFormProvider'
import BuffForm, {
  BuffFormProps,
  buffTypesArray,
} from '@components/organisms/BuffForm'
import { getTemplateInitialValues } from '@utils/buffForm/template'
import { getBlankInitialValues } from '@utils/buffForm/shared'
import { useToast } from '@utils/hooks/useToast'
import { formatBuffDataToSubmit } from '@utils/formatBuffDataToSubmit'
import { Template, TemplateCreate } from '@interfaces/Template'
import { createVoteable } from '@services/requests/voteable'
import TemplateBuffConfirmation from '@components/molecules/TemplateBuffConfirmation'
import { BuffType, tr } from '@constants/other'
import { queryClient } from '@utils/reactQuery/client'
import { getSchemaWithoutLang } from '@utils/buffForm/schema'
import { SendBuffFormProps } from './types'

/**
 * Buff form to send buffs and create new templates
 * @param {SendBuffFormProps} args
 * @return {JSX.Element}
 */
const SendBuffForm = ({
  authors,
  streamConfig,
  stream,
  participationPoints,
}: SendBuffFormProps) => {
  const [templatePayload, setTemplatePayload] = useState<
    Template | TemplateCreate | null
  >(null)
  const { selectedTemplate, setSelectedTemplate } = useContext(TemplateContext)
  const { setIsSubmitting, isTemplateType } = useContext(BuffFormContext)
  const { languages, gameIds } = stream
  const { timeSync, theme } = streamConfig.config
  const allowGamePoints = streamConfig?.config?.allowGamePoints
  const { addToast, addErrorToast } = useToast()

  const firstGameId = gameIds[0]
  const firstLang = languages[0]

  const emptyInitialValues = getBlankInitialValues({
    languages: [firstLang],
    isTimeSyncStream: timeSync,
    buffType: allowGamePoints ? BuffType.Prediction : BuffType.Poll,
  })

  const initialValues = useMemo(() => {
    return getTemplateInitialValues({
      template: selectedTemplate ?? undefined,
      isTimeSyncStream: timeSync,
      languages: [firstLang],
      buffType: allowGamePoints ? BuffType.Prediction : BuffType.Poll,
    })
  }, [selectedTemplate, timeSync, firstLang, allowGamePoints])

  const getSchema = getSchemaWithoutLang({
    isTemplate: isTemplateType,
    isTimeSyncStream: timeSync,
  })

  const handleSubmit: BuffFormProps['onSubmit'] = async (
    values,
    helpers,
    languages,
    resetForm
  ) => {
    // TODO: Possibly remove last buff sent toast??

    const partPoints = allowGamePoints ? participationPoints : 0

    const formattedValues = formatBuffDataToSubmit(
      values,
      false,
      languages,
      true,
      partPoints,
      timeSync,
      isTemplateType
    )

    if (isTemplateType) {
      setTemplatePayload({
        ...formattedValues,
        templateName: '',
        streamId: stream.id,
      })

      return
    }

    setIsSubmitting(true)

    try {
      await createVoteable({ voteable: formattedValues, gameId: firstGameId })
      resetForm(emptyInitialValues)
      queryClient.invalidateQueries(['game-content', firstGameId])

      // Ensures the form resets correctly if loaded from template before
      setSelectedTemplate(null)

      addToast({ msg: tr({ id: 'buff.buffSent' }), type: 'success' })
    } catch (error) {
      addErrorToast(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const buffTypes = !allowGamePoints
    ? buffTypesArray.filter(
        (buffType) =>
          buffType.key !== BuffType.Prediction && buffType.key !== BuffType.Quiz
      )
    : buffTypesArray

  const displayPoints = !!allowGamePoints

  return (
    <>
      <BuffForm
        buffTypes={buffTypes}
        initialValues={initialValues}
        authors={authors}
        isTimeSyncStream={timeSync}
        availableLanguages={languages}
        displayPoints={displayPoints}
        onSubmit={handleSubmit}
        getSchema={getSchema}
        theme={theme}
        onReset={(values, resetForm) => {
          resetForm(emptyInitialValues)
        }}
      />

      {/* Can only create new templates from SendBuffForm */}
      <TemplateBuffConfirmation
        mode={'create'}
        payload={templatePayload}
        show={Boolean(templatePayload)}
        onClose={() => setTemplatePayload(null)}
        onSuccessCallback={() => {
          addToast({
            msg: tr({ id: 'template.saveTemplateConfirmation' }),
            type: 'success',
          })
        }}
      />
    </>
  )
}

export default SendBuffForm
