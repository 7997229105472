import * as Yup from 'yup'
import { tr } from '@constants/other'
import ISelectedLanguage from '@interfaces/ISelectedLanguage'

// TODO: move min and max points to game validation
export const Schema = Yup.object().shape({
  title: Yup.string()
    // .matches(/^[0-9a-zA-Z ]+$/, tr({ id: 'validation.streamRegex' }))
    .required(tr({ id: 'validation.streamNameReq' }))
    .min(5, tr({ id: 'validation.streamNameMin' })),
  providerId: Yup.string()
    .min(3, tr({ id: 'validation.providerIdMin' }))
    .matches(/^[A-Za-z0-9]+$/, tr({ id: 'validation.specialCharsNotAllowed' })),
  twitchChannelIds: Yup.array().of(
    Yup.string().min(3, tr({ id: 'validation.twitchChannelIdMin' }))
  ),
  languages: Yup.array()
    .of(
      Yup.object({
        id: Yup.number(),
        iso6391: Yup.string(),
        name: Yup.string(),
        selected: Yup.boolean(),
      })
    )
    .min(1)
    .test(
      'one-selected-language',
      tr({ id: 'stream.languageRequired' }),
      (value) => {
        return (value as ISelectedLanguage[])?.some((lang) => lang.selected)
      }
    )
    .required(),
})
