import React, { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IRouteParams } from '@interfaces/RouteParams'
import StreamLeaderboardDetailsTabs from './StreamLeaderboardDetailsTabs'
import SectionLayout from '@components/layouts/SectionLayout'
import { useLive } from '@services/requests/live'
import { tr } from '@constants/other'
import { useToast } from '@utils/hooks/useToast'
import { useMutation } from 'react-query'
import { queryClient } from '@utils/reactQuery/client'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import Button from '@components/atoms/Button'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { Alignment } from '@components/molecules/ConfirmationModal/types'
import TooltipPill from '@components/molecules/TooltipPill'

const showAnnounceWinners = process.env.LEADERBOARD_WINNERS === 'true'

const StreamLeaderboardDetails: FC<unknown> = () => {
  const { id: streamId, gameId, leaderboardId } = useParams<IRouteParams>()
  const { useGetStreamLeaderboardById, finaliseScores } = useLive()
  const { data } = useGetStreamLeaderboardById(streamId, leaderboardId)

  const { addToast, addErrorToast } = useToast()
  const [showWinnersModal, setShowWinnersModal] = useState(false)

  const { mutate: finaliseLeaderboardMutate } = useMutation(finaliseScores, {
    onSuccess: () => {
      addToast({
        msg: tr({ id: 'leaderboard.winners.leaderboardAnnounced' }),
        type: 'success',
        image: <Tick className="mr-3 w-8 h-8" />,
      })
      queryClient.invalidateQueries(['leaderboard', leaderboardId])
    },
    onError: (err) => {
      addErrorToast(err)
    },
  })

  const handleAnnounceWinners = () => {
    setShowWinnersModal(true)
  }

  const handleClose = () => {
    setShowWinnersModal(false)
  }

  const handleConfirmAnnounceWinners = () => {
    finaliseLeaderboardMutate({
      streamId: streamId ?? '',
      leaderboardId: leaderboardId ?? '',
    })
  }

  return (
    <SectionLayout
      containerClassname="mb-20"
      childrenClassName="pt-4"
      title={
        <div className="flex items-center gap-x-2">
          <span className="sb-break-words line-clamp-1">
            {data?.name ?? tr({ id: 'leaderboard.new' })}
          </span>
          {!!data?.finalised && (
            <TooltipPill
              data-testid="winners-announced-pill"
              className={`px-3 flex-shrink-0 bg-[#EFF8FF] text-[#175CD3]`}
              text={
                <span className="font-medium text-sm">
                  {tr({ id: 'leaderboard.winners.winnersAnnounced' })}
                </span>
              }
            />
          )}
        </div>
      }
      backLink={`/streams/${streamId}/${gameId}/leaderboard-list`}
      header={
        !!leaderboardId && data ? (
          <div>
            {showAnnounceWinners && (
              <Button
                type="button"
                variant={data?.finalised ? 'secondary' : 'primary'}
                className="py-3"
                onClick={handleAnnounceWinners}
                data-testid="show-winners-btn"
              >
                {tr({
                  id: data?.finalised
                    ? 'leaderboard.winners.reannounce'
                    : 'leaderboard.winners.confirm',
                })}{' '}
              </Button>
            )}
          </div>
        ) : null
      }
    >
      <StreamLeaderboardDetailsTabs />

      <ConfirmationModal
        onCancel={handleClose}
        onSuccess={handleConfirmAnnounceWinners}
        onClose={handleClose}
        title={`${tr({
          id: !data?.finalised
            ? 'leaderboard.winners.title'
            : 'leaderboard.winners.reannounceTitle',
        })}`}
        successBtnText={tr({
          id: !data?.finalised
            ? 'leaderboard.winners.confirm'
            : 'leaderboard.winners.reannounce',
        })}
        subtext={tr({
          id: !data?.finalised
            ? 'leaderboard.winners.text'
            : 'leaderboard.winners.reannounceText',
        })}
        show={showWinnersModal}
        data-testid="show-winners-modal"
        alignment={Alignment.END}
      />
    </SectionLayout>
  )
}

export default StreamLeaderboardDetails
