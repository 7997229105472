import React, { FC } from 'react'
import Sidebar from '@components/organisms/Sidebar'
import TopNav from '@components/layouts/TopNav'
import ScrollableContainer from '@components/atoms/ScrollableContainer'
import Container from '@components/atoms/Container'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'

const OnlineLayout: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Container className="flex flex-col min-h-full fixed">
      <Row>
        <Col className="px-0">
          <TopNav />
        </Col>
      </Row>
      <Row className="flex-1 relative">
        <Sidebar />
        <ScrollableContainer>
          <Col className="flex-1 h-full ml-[58px] w-auto">{children}</Col>
        </ScrollableContainer>
      </Row>
    </Container>
  )
}

export default OnlineLayout
