import React, { FC } from 'react'
import Modal from '@components/molecules/Modal'
import Button, { ButtonVariant } from '@components/atoms/Button'

import { BanModalProps } from './types'
import { tr } from '@constants/other'

const BanUserModal: FC<React.PropsWithChildren<BanModalProps>> = ({
  show,
  onClose,
  onCancel,
  onSuccess,
  'data-testid': testId = 'ban-user-modal',
}) => {
  const handleClick = () => {
    onSuccess && onSuccess()
    onClose && onClose()
  }

  const handleCancel = () => {
    onCancel && onCancel()
    onClose && onClose()
  }

  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      data-testid={testId}
      title={`${tr({ id: 'globalChat.banUser' })}?`}
      displayCloseButton={false}
    >
      <Modal.Body></Modal.Body>
      <div className={`flex justify-end !pt-4 gap-4`}>
        <Button
          variant={ButtonVariant.Secondary}
          type="button"
          data-testid={`${testId}__cancel`}
          onClick={handleCancel}
        >
          {tr({ id: 'generic.cancel' })}
        </Button>
        <Button
          variant={ButtonVariant.Primary}
          type="button"
          data-testid={`${testId}__confirm`}
          onClick={handleClick}
        >
          {tr({ id: 'globalChat.banUser' })}
        </Button>
      </div>
    </Modal>
  )
}

export default BanUserModal
