import * as Yup from 'yup'
import { tr } from '@constants/other'
import ISelectedLanguage from '@interfaces/ISelectedLanguage'

// TODO: move min and max points to game validation
export const Schema = Yup.object().shape({
  name: Yup.string()
    // .matches(/^[0-9a-zA-Z ]+$/, tr({ id: 'validation.streamRegex' }))
    .required(tr({ id: 'validation.vodNameReq' }))
    .min(5, tr({ id: 'validation.vodNameMinReq' })),
  providerId: Yup.string().min(3, tr({ id: 'validation.providerIdMin' })),
  languages: Yup.array()
    .of(
      Yup.object({
        id: Yup.number(),
        iso6391: Yup.string(),
        name: Yup.string(),
        selected: Yup.boolean(),
      })
    )
    .min(1)
    .test(
      'one-selected-language',
      tr({ id: 'validation.vodLanguagesReq' }),
      (value) => {
        return (value as ISelectedLanguage[])?.some((lang) => lang.selected)
      }
    )
    .required(),
})
