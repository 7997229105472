import axios from 'axios'

/**
 * Class to hold labeled errors
 */
export class NamedException {
  public id: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error: any

  public description?: string

  /**
   * @param {string} id Name of the error
   * @param {any} error The error
   * @param {string} description message to show to user
   */
  public constructor(id: string, error: unknown, description?: string) {
    this.id = id
    this.error = error
    this.description = description
  }
}

/**
 * Helper that wraps promises to error with useful ids
 * @param {string} id
 * @param {any} method The promise to wrap
 * @return {Promise}
 */
export const withNamedError = async <T>(
  id: string,
  method: () => Promise<T>
): Promise<T> => {
  try {
    return await method()
  } catch (error) {
    throw new NamedException(id, error)
  }
}

export const getErrorMessage = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status && error.response?.data?.message) {
      return `${error.response?.status} - ${error.response?.data?.message}`
    }
  }

  if (error instanceof NamedException) {
    return error.description ?? error.id
  }

  if (error instanceof Error) {
    return error.message
  }

  if (typeof error === 'string') {
    return error
  }

  return 'Unknown error'
}
