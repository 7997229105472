import React, { FC } from 'react'
import { tr } from '@constants/other'
import Button from '@components/atoms/Button'
import Modal from '@components/molecules/Modal'
import { DuplicateProviderIdModalProps } from './types'

const DuplicateProviderIdModal: FC<
  React.PropsWithChildren<DuplicateProviderIdModalProps>
> = (props) => {
  const {
    children,
    show,
    onClose,
    cancelBtnText = tr({ id: 'generic.close' }),
    title = tr({ id: 'stream.providerId' }),
    'data-testid': testId = 'duplicate-provider-modal',
  } = props

  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      data-testid={testId}
      title={title}
      displayCloseButton={false}
    >
      <Modal.Body>{children}</Modal.Body>
      <div className="flex !mt-4 justify-end">
        <Button
          className="inline-flex"
          variant="secondary"
          data-testid={`${testId}__cancel`}
          onClick={onClose}
        >
          {cancelBtnText}
        </Button>
      </div>
    </Modal>
  )
}

export default DuplicateProviderIdModal
