import React, { FC, useRef } from 'react'
import ContentWithSidePanelLayout from '@components/layouts/ContentWithSidePanelLayout'
import { tr } from '@constants/other'
import SidebarAction from '@components/SidebarAction/SidebarAction'
import VodSidebar from '@components/organisms/VodSidebar/VodSidebar'
import useVods from '@utils/hooks/useVods'
import EmptyState from '@components/molecules/EmptyState'
import { Route, Routes, useParams } from 'react-router-dom'
import VodDetails from '@components/organisms/VodDetails/VodDetails'
import { IRouteParams } from '@interfaces/RouteParams'
import { NewVodRef } from './types'

const VideoOnDemand: FC<React.PropsWithChildren<unknown>> = () => {
  const { data, fetchNextPage, hasNextPage, isLoading } = useVods()
  const newVodRef = useRef<NewVodRef>(null)
  const displayEmptyState = !isLoading && !data?.pages.length

  const params = useParams<IRouteParams>()
  const { id: vodId } = params

  return (
    <ContentWithSidePanelLayout
      sidePanelTitle={tr({ id: 'generic.vod' })}
      sidePanelAction={<SidebarAction path={'vod'} sidebarRef={newVodRef} />}
      sidePanelContent={
        <div className="flex flex-1 flex-col">
          <VodSidebar
            paginatedData={data?.pages}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
            selectedId={vodId}
          />
        </div>
      }
    >
      <Routes>
        <Route
          path="/"
          element={
            displayEmptyState && (
              <EmptyState
                title={tr({ id: 'vod.emptyStateTitle' })}
                data-testid="vod-page__empty-state"
              />
            )
          }
        />
        <Route path="new" element={<VodDetails />} />
        <Route path=":id/*" element={<VodDetails />} />
      </Routes>
    </ContentWithSidePanelLayout>
  )
}

export default VideoOnDemand
