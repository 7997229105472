import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IRouteParams } from '@interfaces/RouteParams'
import SectionLayout from '@components/layouts/SectionLayout'
import { useLive } from '@services/requests/live'
import { tr } from '@constants/other'
import EmojiStatsList from '@components/molecules/EmojiStatsList'
import EmptyStateWithIcon from '@components/molecules/EmptyStateWithIcon'
import Button from '@components/atoms/Button'
import { queryClient } from '@utils/reactQuery/client'
import TextInput from '@components/atoms/TextInput'
import { format } from 'date-fns'
import { ReactComponent as StatsIcon } from '@assets/stats.svg'
import schema from '@components/molecules/LiveReactions/schema'

const ReactionsMetrics: FC<unknown> = () => {
  const { id: streamId, gameId } = useParams<IRouteParams>()
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [dateFilter, setDateFilter] = useState({
    startDate: '',
    endDate: '',
  })
  const [errors, setErrors] = useState<Record<string, string> | undefined>({})

  const { useGetStreamReactionsList } = useLive()
  const { data: metrics, isLoading } = useGetStreamReactionsList(
    streamId ?? '',
    dateFilter.startDate,
    dateFilter.endDate
  )

  const sortedMetrics = useMemo(() => {
    if (!metrics) return []
    return metrics.sort((a, b) => b.count - a.count)
  }, [metrics])

  const refreshMetrics = () => {
    queryClient.invalidateQueries([
      'stream-reactions-sent',
      streamId,
      startDate,
      endDate,
    ])
  }

  const handleResetDateFilters = () => {
    setStartDate('')
    setEndDate('')
  }

  useEffect(() => {
    ;(async () => {
      try {
        await schema.validate({ startDate, endDate }, { abortEarly: false })
        setDateFilter({ startDate, endDate })
        setErrors({})
      } catch (err) {
        const errors: Record<string, string> = {}
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message
        })
        setErrors(errors)
      }
    })()
  }, [startDate, endDate])

  const maxStartDate = format(new Date(), 'yyyy-MM-dd')

  return (
    <SectionLayout
      childrenClassName="!p-0"
      containerClassname="mb-10"
      title={tr({ id: 'generic.metrics' })}
      backLink={`/streams/${streamId}/${gameId}/reactions`}
      header={
        <Button type="button" variant="secondary" onClick={refreshMetrics}>
          {tr({ id: 'generic.refresh' })}
        </Button>
      }
    >
      <div data-testid="reactions-metrics">
        <div className="flex flex-row gap-x-4 p-4">
          <TextInput
            type="date"
            pattern="\d{4}-\d{2}-\d{2}"
            id="startDate"
            max={maxStartDate}
            label={<span>{tr({ id: 'generic.startDate' })}</span>}
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value)
            }}
            error={!!errors?.startDate}
            errorLabel={errors?.startDate}
            touched={!!startDate}
            placeholder="DD/MM/YYYY"
            inputContainerClasses={`w-[200px] ${
              !startDate ? 'text-[#959DA5]' : 'text-black'
            }`}
          />

          <TextInput
            type="date"
            pattern="\d{4}-\d{2}-\d{2}"
            id="endDate"
            max={maxStartDate}
            label={<span>{tr({ id: 'generic.endDate' })}</span>}
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value)
            }}
            placeholder="DD/MM/YYYY"
            inputContainerClasses={`w-[200px] ${
              !endDate ? 'text-[#959DA5]' : 'text-black'
            }`}
          />

          <Button
            className="mt-6"
            buttonType="link"
            size="medium"
            onClick={handleResetDateFilters}
          >
            {tr({ id: 'generic.clear' })}
          </Button>
        </div>
        <div className="flex flex-col">
          {!isLoading && !sortedMetrics?.length && (
            <EmptyStateWithIcon
              containerClasses="!h-[350px] mt-10"
              icon={<StatsIcon className="w-10 text-white" />}
              text={tr({ id: 'generic.emptyMetrics' })}
            />
          )}

          {!!sortedMetrics?.length && (
            <div className="p-4 bg-white flex flex-col rounded gap-y-2">
              <EmojiStatsList emojis={sortedMetrics} />
              <p className="m-0 text-[#586069] text-sm">
                *{tr({ id: 'liveReactions.metricDisclaimer' })}
              </p>
            </div>
          )}
        </div>
      </div>
    </SectionLayout>
  )
}

export default ReactionsMetrics
