import React, { FC, CSSProperties } from 'react'
import { TextInputProps } from './types'

const TextInput: FC<React.PropsWithChildren<TextInputProps>> = ({
  label,
  placeholder,
  type,
  error,
  errorLabel,
  touched,
  id,
  inputRef,
  customInputStyle,
  inputContainerClasses,
  containerClasses,
  labelClasses,
  inputPrefix,
  'data-testid': testId = 'text-input',
  ...rest
}): JSX.Element => {
  const errorColor = '#CB2431'
  const errorInputBg = '#FFEEF0'

  const inputStyles: CSSProperties = {
    width: '100%',
    minHeight: '36px',
    borderRadius: '5px',
    paddingLeft: '10px',
    color: error && touched ? errorColor : 'inherit',
    backgroundColor: error && touched ? errorInputBg : undefined,
    border:
      error && touched ? `solid 2px ${errorColor}` : 'solid 1px lightgray',
  }

  const errorStyles: CSSProperties = {
    color: errorColor,
    fontStyle: 'italic',
    fontSize: '12px',
    marginLeft: '6px',
    minHeight: '18px',
  }

  return (
    <div className={`${error ? 'has-error' : ''} ${containerClasses}`}>
      <div>
        {label && (
          <label
            className={`form-check-label pb-1 ${labelClasses}`}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        <div
          className={`flex flex-col input-wrapper ${inputContainerClasses}`}
          style={{ minHeight: type === 'checkbox' ? 'none' : '48px' }}
        >
          <div className="flex flex-row items-center relative">
            {!!inputPrefix && <>{inputPrefix}</>}
            <input
              ref={inputRef}
              type={type}
              className={`form-control`}
              style={{
                ...inputStyles,
                ...customInputStyle,
              }}
              placeholder={placeholder}
              id={id}
              data-testid={testId}
              {...rest}
            />
          </div>

          {error && touched && (
            <span style={errorStyles} data-testid={`${testId}__error`}>
              {errorLabel}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default TextInput
