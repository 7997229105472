import React, { FC } from 'react'
import { BuffType, tr } from '@constants/other'
import TextInput from '@components/atoms/TextInput'
import getLocalisations from '@utils/getLocalisation'
import { VoteableAnswer } from '@interfaces/Voteable'
import { LanguageCode } from '@interfaces/ILanguage'
import Image from '@components/atoms/Image'

interface AnswerPreviewProps {
  answer?: VoteableAnswer
  type: BuffType | undefined
  showCorrect: boolean
  activeLanguage: LanguageCode
}

const AnswerPreview: FC<React.PropsWithChildren<AnswerPreviewProps>> = ({
  answer,
  type,
  activeLanguage,
  showCorrect,
}) => {
  if (!answer) return <></>

  const answerContent = getLocalisations({
    localisations: answer.localisations,
    languages: [activeLanguage],
  })

  const imageUrl = answerContent?.imageUrl

  return (
    <div
      data-testid="buff-answer-container"
      className="bg-white p-3 mb-2 flex shadow-sm justify-start items-center rounded w-full"
    >
      <div className="flex items-center">
        <div className="buff-answer-image-btn p-0 border-0 mr-3 rounded">
          <div className="flex justify-center items-center rounded w-[68px] h-[60px] bg-secondary">
            <Image
              src={imageUrl}
              alt={tr({ id: 'buff.answerImg' })}
              className="object-cover w-full h-full"
              fallbackComponent={<img src="/assets/icon-image.png" />}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 mr-3" style={{ width: '258px' }}>
        <TextInput
          id={`${answer?.id}.title`}
          label={tr({ id: 'buff.buffAnswer' })}
          placeholder={answerContent?.text}
          disabled
        />
      </div>
      {type && type !== BuffType.Poll && type !== BuffType.EmojiSlider && (
        <div className="mt-2" style={{ width: '80px' }}>
          <TextInput
            label={tr({ id: 'buff.points' })}
            type={'number'}
            min={0}
            value={answer.points}
            disabled
          />
        </div>
      )}
      {showCorrect && (
        <div>
          <div className="flex items-start ml-5 buff-answer-checkbox">
            <TextInput
              type={'checkbox'}
              style={{ marginTop: '2px' }}
              className={'form-check-input light-mode'}
              checked={answer.correct}
              disabled
            />
            <label
              className="font-normal"
              style={{
                marginTop: '4px',
                marginRight: '0px',
                marginBottom: '0px',
                marginLeft: '12px',
              }}
              htmlFor="correct"
            >
              {tr({ id: 'buff.correct' })}
            </label>
          </div>
        </div>
      )}
    </div>
  )
}

export default AnswerPreview
