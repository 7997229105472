import { tr } from '../constants/other'

interface INavigationItem {
  to: string
  index: number
  name: string
  iconPath: string
  className?: string
  show: boolean
}

const getSidebarItems = (showConfiguration: boolean): INavigationItem[] => {
  const SIDEBAR_NAV: INavigationItem[] = [
    {
      to: '/',
      index: 1,
      name: tr({ id: 'generic.overview' }),
      iconPath: '/assets/svg/icon-home.svg',
      show: true,
    },
    {
      to: '/streams',
      index: 2,
      name: tr({ id: 'stream.manage' }),
      iconPath: '/assets/svg/icon-streams.svg',
      show: true,
    },
    {
      to: '/authors',
      index: 4,
      name: tr({ id: 'author.manage' }),
      iconPath: '/assets/svg/icon-author.svg',
      show: true,
    },
    {
      to: '/metrics',
      index: 6,
      name: 'Metrics',
      iconPath: '/assets/svg/icon-metrics.svg',
      show: true,
    },
    {
      to: '/stream-management',
      index: 7,
      name: tr({ id: 'stream.management.title' }),
      iconPath: '/assets/svg/settings.svg',
      show: true,
    },
    {
      to: '/client-config',
      index: 8,
      name: tr({ id: 'configuration.title' }),
      iconPath: '/assets/svg/config.svg',
      className: 'mt-auto',
      show: showConfiguration,
    },
  ]

  if (process.env.NEWVOD === 'true') {
    SIDEBAR_NAV.push({
      to: '/vod',
      index: 3,
      name: tr({ id: 'generic.vod' }),
      iconPath: '/assets/svg/icon-vod.svg',
      show: true,
    })
  }

  SIDEBAR_NAV.sort((a, b) => a.index - b.index)

  return SIDEBAR_NAV
}

export { getSidebarItems }
