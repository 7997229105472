import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import BuffForm from '@components/organisms/BuffForm'
import Alert from '@components/atoms/Alert'
import { IRouteParams } from '@interfaces/RouteParams'
import { getTemplateInitialValues } from '@utils/buffForm/template'
import { useToast } from '@utils/hooks/useToast'
import { tr } from '@constants/other'
import { getSchemaWithoutLang } from '@utils/buffForm/schema'
import { CreateVoteableForm } from '@interfaces/Voteable'
import { formatBuffDataToSubmit } from '@utils/formatBuffDataToSubmit'
import { TemplateCreate, Template } from '@interfaces/Template'
import TemplateBuffConfirmation from '@components/molecules/TemplateBuffConfirmation'
import { useGetVoteableTemplate } from '@services/requests/templates'
import { LanguageCode } from '@interfaces/ILanguage'
import { GlobalTemplateBuffFormProps } from './types'

const GlobalTemplateBuffForm = ({
  availableLanguages,
  authors,
  templateId,
}: GlobalTemplateBuffFormProps) => {
  const [templatePayload, setTemplatePayload] = useState<
    TemplateCreate | Template | null
  >(null)
  const { addToast } = useToast()
  const { id } = useParams<IRouteParams>()
  const { data: template, isLoading } = useGetVoteableTemplate({
    id: templateId ?? '',
    options: {
      enabled: Boolean(templateId),
    },
  })
  const navigate = useNavigate()
  const firstLang = availableLanguages[0]
  const initialValues = useMemo(() => {
    return getTemplateInitialValues({
      template,
      languages: [firstLang],
    })
  }, [template, firstLang])

  const getSchema = getSchemaWithoutLang({
    isTemplate: true,
  })

  const handleSubmit = (
    values: CreateVoteableForm,
    helpers: FormikHelpers<CreateVoteableForm>,
    languages: LanguageCode[]
  ) => {
    setTemplatePayload({
      ...formatBuffDataToSubmit(values, false, languages, true, 0, false, true),
      templateName: template?.templateName ?? '',
    })
  }

  const handleTemplateSaveSuccess = () => {
    navigate('/stream-management/global-templates/buff')
    addToast({
      msg: tr({ id: 'template.saveTemplateConfirmation' }),
      type: 'success',
    })
  }

  if (isLoading) {
    return null
  }

  return (
    <>
      {Boolean(template) && (
        <Alert
          className="mt-3"
          text={tr(
            { id: 'template.editGlobalTemplateAlertText' },
            { templateName: template?.templateName }
          )}
          action={tr({ id: 'welcomeBuff.returnToList' })}
          actionCallback={() => {
            navigate('/stream-management/global-templates/buff')
          }}
          actionClassName="font-bold underline"
        />
      )}
      <BuffForm
        initialValues={initialValues}
        authors={authors}
        isTimeSyncStream={false}
        availableLanguages={availableLanguages}
        getSchema={getSchema}
        onSubmit={handleSubmit}
        onReset={(values, resetForm) => {
          const blankInitialValues = getTemplateInitialValues({
            languages: [firstLang],
          })
          resetForm(blankInitialValues)
        }}
      />

      <TemplateBuffConfirmation
        id={id}
        mode={id ? 'update' : 'create'}
        payload={templatePayload}
        show={Boolean(templatePayload)}
        onClose={() => setTemplatePayload(null)}
        onSuccessCallback={handleTemplateSaveSuccess}
      />
    </>
  )
}

export default GlobalTemplateBuffForm
