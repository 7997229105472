import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { Channel, Message } from '@pubnub/chat'
import { Form, Formik, FormikProps } from 'formik'
import {
  Chat,
  EmojiPickerElementProps,
  MessageInput,
  MessageList,
} from '@pubnub/react-chat-components'
import { Menu, Tab } from '@headlessui/react'
import { tr } from '@constants/other'
import { IRouteParams } from '@interfaces/RouteParams'
import Switch from '@components/atoms/Switch'
import ChatMessageItem from './components/ChatMessageItem'
import useAutoSubmitForm from '@utils/hooks/useAutoSubmitForm'
import { StreamChatFormValues } from '@interfaces/Chat'
import Button from '@components/atoms/Button'
import { ReactComponent as SendIcon } from '@assets/send.svg'
import { ReactComponent as PinIcon } from '@assets/pin-filled.svg'
import { ReactComponent as UnpinIcon } from '@assets/unpin.svg'
import { ReactComponent as TrashIcon } from '@assets/delete.svg'
import { ReactComponent as MenuIcon } from '@assets/menu.svg'
import { ReactComponent as StarIcon } from '@assets/star.svg'
import { ReactComponent as StarFilledIcon } from '@assets/star-filled.svg'
import { ReactComponent as TickSVG } from '@assets/answer-tick.svg'
import { PubnubContext } from '@services/providers/PubnubProvider'
import {
  addMessageAction,
  removeMessageAction,
  softDeleteMessage,
  starMessage,
  unstarMessage,
} from '@services/requests/pubnub'
import { useChat } from '@services/requests/chat'
import { useToast } from '@utils/hooks/useToast'
import { StreamChatConfigProps } from './types'

const ENABLE_GLOBAL_CHAT = process.env.GLOBAL_CHAT === 'true'

export enum ChannelType {
  AllMessages,
  StarredMessages,
}

const channelTabs = {
  [ChannelType.AllMessages]: {
    type: ChannelType.AllMessages,
    title: tr({ id: 'globalChat.allMessages' }),
    contentTestId: 'channel__content--all',
    tabTestId: 'channel__tab--all',
  },
  [ChannelType.StarredMessages]: {
    type: ChannelType.StarredMessages,
    title: tr({ id: 'globalChat.starred' }),
    contentTestId: 'channel__content--starred',
    tabTestId: 'channel__tab--starred',
  },
}

const enabledText = tr({ id: 'generic.enabled' })
const disabledText = tr({ id: 'generic.disabled' })

const defaultInitialValues: StreamChatFormValues = {
  name: '',
  enabled: false,
  visible: true,
  slowMode: 0,
}

const PickerAdapter = (props: EmojiPickerElementProps) => {
  const handleEmoji = (e: { native: string }) => {
    if (props.onEmojiSelect) {
      props.onEmojiSelect({ native: e.native })
    }
  }

  return (
    <Picker
      data={data}
      onEmojiSelect={handleEmoji}
      previewPosition="none"
      skinTonePosition="none"
    />
  )
}

const _StreamChatConfig = (_: StreamChatConfigProps) => {
  const { id: streamId = '' } = useParams<IRouteParams>()
  const { addErrorToast, addToast } = useToast()
  const { useGetStreamChatSettings, updateChatSettings, useDeleteChatMessage } =
    useChat()
  const [channel, setChannel] = useState<Channel>()
  const [starredChannel, setStarredChannel] = useState<Channel>()
  const [channelType, setChannelType] = useState<ChannelType>(
    ChannelType.AllMessages
  )
  const [pinMessage, setPinMessage] = useState<Message>()
  const { pubnub, isInitializedChat, chat } = useContext(PubnubContext)
  const { data: streamChatSettings } = useGetStreamChatSettings(streamId)
  const { mutateAsync: deleteChatMessage } = useDeleteChatMessage()

  const formRef = useRef<FormikProps<StreamChatFormValues>>(null)
  const submit = useAutoSubmitForm<StreamChatFormValues>(formRef)

  const initialValues = useMemo(() => {
    if (streamChatSettings) {
      return streamChatSettings
    }
    return defaultInitialValues
  }, [streamChatSettings]) as StreamChatFormValues

  const handleSubmit = async (values: StreamChatFormValues) => {
    try {
      await updateChatSettings(streamId, values)
      addToast({
        msg: tr({ id: 'globalChat.settingsUpdated' }),
        type: 'success',
        image: <TickSVG className="mr-3 w-8 h-8" />,
      })
    } catch (error) {
      addErrorToast(error)
    }
  }

  const handlePinMessage = async (id?: string | number) => {
    if (!id || !pubnub) return
    try {
      const message = await channel?.getMessage(String(id))
      const starMessage = await starredChannel?.getMessage(String(id))

      if (message) {
        await channel?.pinMessage(message)
        setPinMessage(message)
      }

      if (starMessage && channel && starredChannel) {
        const connectedMessages = Object.keys(
          starMessage?.actions?.connected || {}
        )
        const message = await channel?.getMessage(connectedMessages[0])
        if (message) {
          await channel?.pinMessage(message)
          setPinMessage(message)
        }
      }
    } catch (error) {
      addErrorToast(error)
    }
  }

  const handleUnpinMessage = async () => {
    try {
      await channel?.unpinMessage()
      setPinMessage(undefined)
    } catch (error) {
      addErrorToast(error)
    }
  }

  const handleDeleteMessage = async (id?: string) => {
    if (!id) return
    try {
      await deleteChatMessage({ streamId, timetoken: id })

      if (pinMessage?.timetoken === id) {
        handleUnpinMessage()
      }
    } catch (error) {
      addErrorToast(error)
    }
  }

  const handleDeletePinMessage = async () => {
    if (!pinMessage) return
    const timetoken = pinMessage?.timetoken
    await handleUnpinMessage()
    await handleDeleteMessage(timetoken)
  }

  const handleTabChange = (position: number): void => {
    setChannelType(position)
  }

  const handleStarMessage = async (id?: string) => {
    if (!id || !pubnub) return
    try {
      const message = await channel?.getMessage(String(id))
      if (message && starredChannel && channel) {
        const newMessage = await pubnub.publish({
          channel: starredChannel.id,
          message: {
            text: message.content.text,
            sender: (message.content as any)?.sender,
          },
        })
        const newMessageTimetoken = newMessage.timetoken
        await starMessage(pubnub, channel?.id, id)
        await addMessageAction(pubnub, channel?.id, id, {
          type: 'connected',
          value: newMessageTimetoken,
        })
        await addMessageAction(
          pubnub,
          starredChannel?.id,
          String(newMessageTimetoken),
          {
            type: 'connected',
            value: message.timetoken,
          }
        )
        if (pinMessage?.timetoken === message.timetoken) {
          const newPinnedMessage = await channel?.getMessage(
            String(message.timetoken)
          )
          if (newPinnedMessage) {
            setPinMessage(newPinnedMessage)
          }
        }
      }
    } catch (error) {
      addErrorToast(error)
    }
  }

  const handleUnstarMessage = async (id?: string) => {
    if (!id || !pubnub || !channel || !starredChannel) return
    try {
      const message = await channel?.getMessage(String(id))
      const starMessage = await starredChannel?.getMessage(String(id))
      if (message) {
        const starredActionTimetoken =
          message?.actions?.starred?.data?.[0]?.actionTimetoken
        const connectedMessages = Object.keys(message?.actions?.connected || {})
        connectedMessages.forEach(async (messageKey) => {
          const action =
            message?.actions?.connected[messageKey]?.[0]?.actionTimetoken
          if (action) {
            await removeMessageAction(pubnub, channel.id, id, String(action))
            await softDeleteMessage(pubnub, starredChannel.id, messageKey)
          }
        })

        if (starredActionTimetoken) {
          await unstarMessage(
            pubnub,
            channel.id,
            message.timetoken,
            String(starredActionTimetoken)
          )
        }

        if (pinMessage?.timetoken === message.timetoken) {
          const newPinnedMessage = await channel?.getMessage(
            String(message.timetoken)
          )
          if (newPinnedMessage) {
            setPinMessage(newPinnedMessage)
          }
        }
      }

      if (starMessage) {
        const actionTimetoken =
          message?.actions?.starred?.data?.[0]?.actionTimetoken
        const connectedMessages = Object.keys(
          starMessage?.actions?.connected || {}
        )
        const action =
          starMessage?.actions?.connected[connectedMessages[0]]?.[0]
            ?.actionTimetoken

        if (action) {
          const connectedMessage = await channel?.getMessage(
            connectedMessages[0]
          )
          const connectedActions = Object.keys(
            connectedMessage?.actions?.connected || {}
          )
          const starredActionTimetoken =
            connectedMessage?.actions?.starred?.data?.[0]?.actionTimetoken
          if (starredActionTimetoken) {
            await unstarMessage(
              pubnub,
              channel.id,
              connectedMessage.timetoken,
              String(starredActionTimetoken)
            )
          }

          if (pinMessage?.timetoken === connectedMessage?.timetoken) {
            const newPinnedMessage = await channel?.getMessage(
              String(connectedMessage.timetoken)
            )
            if (newPinnedMessage) {
              setPinMessage(newPinnedMessage)
            }
          }

          connectedActions.forEach(async (messageKey) => {
            const action =
              connectedMessage?.actions?.connected[messageKey]?.[0]
                ?.actionTimetoken
            if (action) {
              await removeMessageAction(
                pubnub,
                channel.id,
                connectedMessage.timetoken,
                String(action)
              )
            }
          })
        }

        if (actionTimetoken) {
          await unstarMessage(
            pubnub,
            starredChannel.id,
            starMessage.timetoken,
            String(actionTimetoken)
          )
        }
        await softDeleteMessage(
          pubnub,
          starredChannel.id,
          starMessage.timetoken
        )
      }
    } catch (error) {
      addErrorToast(error)
    }
  }

  const allChannelName = streamChatSettings?.pubnub.chat
    ? streamChatSettings?.pubnub.chat
    : ''
  const starredChannelName = allChannelName ? `${allChannelName}.starred` : ''

  useEffect(() => {
    if (
      !streamChatSettings ||
      !ENABLE_GLOBAL_CHAT ||
      !pubnub ||
      !isInitializedChat ||
      !chat
    )
      return
    ;(async () => {
      try {
        let _allChannel = await chat.getChannel(allChannelName)
        let _starredChannel = await chat.getChannel(starredChannelName)

        if (!_allChannel) {
          _allChannel = await chat.createPublicConversation({
            channelId: allChannelName,
          })
        }

        if (!_starredChannel) {
          _starredChannel = await chat.createPublicConversation({
            channelId: starredChannelName,
          })
        }

        setChannel(_allChannel)
        setStarredChannel(_starredChannel)
        pubnub.subscribe({ channels: [allChannelName, starredChannelName] })
      } catch (err) {
        console.error(err)
      }
    })()

    return () => {
      pubnub.unsubscribe({ channels: [allChannelName, starredChannelName] })
    }
  }, [
    pubnub,
    streamId,
    isInitializedChat,
    streamChatSettings,
    allChannelName,
    starredChannelName,
    chat,
  ])

  useEffect(() => {
    if (channel) {
      channel
        ?.getPinnedMessage()
        .then((message) => {
          setPinMessage(message ?? undefined)
        })
        .catch()
    }
  }, [channel])

  const dropdownItems = [
    {
      onClick: () => handleUnpinMessage(),
      label: (
        <div className="flex items-center flex-row gap-x-2">
          <UnpinIcon className="w-5 h-5" />
          <span>
            {tr({
              id: 'globalChat.unpinMessage',
            })}
          </span>
        </div>
      ),
    },
    {
      onClick: () => handleDeletePinMessage(),
      label: (
        <div className="flex items-center flex-row gap-x-2">
          <TrashIcon className="w-5 h-5" />
          <span>{tr({ id: 'globalChat.deleteMessage' })}</span>
        </div>
      ),
    },
  ]

  const activeChannel =
    channelType === ChannelType.AllMessages
      ? allChannelName
      : starredChannelName

  return (
    <div
      className="flex flex-col gap-y-4 p-4 rounded-lg bg-white-background"
      data-testid="stream-chat-config"
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        validateOnBlur
        validateOnChange
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange }) => {
          return (
            <Form className="flex flex-col gap-y-4">
              <div data-testid="stream-chat-config-form">
                <fieldset>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex mr-4">
                        <label
                          className="flex mb-0 mr-2 items-center text-[1.125rem] font-bold"
                          htmlFor="enabled"
                        >
                          {tr({ id: 'globalChat.liveChat' })}
                        </label>
                      </div>
                      <Switch
                        textClassname="text-sm uppercase"
                        textPosition="right"
                        className="m-0"
                        name="enabled"
                        id="enabled"
                        text={!!values.enabled ? enabledText : disabledText}
                        isChecked={!!values.enabled}
                        onToggle={(e) => {
                          setChannelType(ChannelType.AllMessages)
                          handleChange(e)
                          submit()
                        }}
                      />
                    </div>
                  </div>
                </fieldset>

                {!!values.enabled &&
                  !!pubnub &&
                  isInitializedChat &&
                  channel &&
                  starredChannel && (
                    <Tab.Group onChange={handleTabChange}>
                      <Chat currentChannel={activeChannel}>
                        <div className="py-2">
                          <div className="w-full h-[1px] bg-[#D1D5DA] my-3" />
                          <div className="bg-white p-3 flex flex-col">
                            <div className="flex items-center justify-between mb-4">
                              <div>
                                <h3 className="font-semibold">
                                  {streamChatSettings?.name ??
                                    tr({ id: 'globalChat.liveChat' })}
                                </h3>
                                {/* <div className="mt-2 flex items-center gap-x-2 text-[#959DA5]">
                                <PersonIcon className="w-5 h-5" />
                                <span>1,412</span>
                              </div> */}
                              </div>
                              <div>
                                {/* Positioning Header */}
                                <div className="flex justify-between">
                                  <Tab.List className="flex items-center space-x-1 bg-white rounded-3xl px-2 border-solid border-[#E1E4E8] border-[1px] shadow-card">
                                    {Object.values(channelTabs).map(
                                      (platform) => (
                                        <Tab
                                          key={platform.tabTestId}
                                          data-testid={platform.tabTestId}
                                          className={({ selected }) =>
                                            `whitespace-nowrap py-1 my-1 w-full text-sm font-medium text-[#5c5c5c] rounded-3xl px-2.5 ${
                                              selected
                                                ? 'bg-[#0090fe] text-white'
                                                : 'bg-transparent'
                                            }`
                                          }
                                        >
                                          {platform.title}
                                        </Tab>
                                      )
                                    )}
                                  </Tab.List>
                                </div>
                              </div>
                            </div>
                            {!!pinMessage && (
                              <div className="bg-white border-[1px] border-solid border-[#E1E4E8] flex flex-col p-2 shadow-card rounded">
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center gap-x-2 text-[#007BFF]">
                                    <PinIcon className="w-5 h-5" />
                                    <span className="font-semibold text-sm">
                                      {tr({ id: 'globalChat.pinned' })}
                                    </span>
                                  </div>

                                  <div
                                    data-testid="message-item-actions"
                                    className="flex-shrink-0 flex gap-x-2 items-center"
                                  >
                                    <Button
                                      onClick={() =>
                                        pinMessage?.actions?.starred
                                          ? handleUnstarMessage(
                                              pinMessage?.timetoken
                                            )
                                          : handleStarMessage(
                                              pinMessage?.timetoken
                                            )
                                      }
                                      variant="neutral"
                                      size="mini"
                                      data-testid="message-item-star-button"
                                    >
                                      {!!pinMessage?.actions?.starred ? (
                                        <StarFilledIcon
                                          data-testid="star-filled-icon"
                                          className="h-5 w-5 text-[#959DA5]"
                                        />
                                      ) : (
                                        <StarIcon
                                          data-testid="star-icon"
                                          className="h-5 w-5 text-[#959DA5]"
                                        />
                                      )}
                                    </Button>
                                    <Menu as="div" className="relative h-5">
                                      <Menu.Button className="">
                                        <MenuIcon className="w-5 h-5 text-black" />
                                      </Menu.Button>
                                      <Menu.Items
                                        as="div"
                                        className="absolute right-0 z-10 bg-dark-background w-52 focus:outline-none rounded shadow-lg bg-white"
                                      >
                                        {dropdownItems.map((item, index) => (
                                          <Menu.Item
                                            key={index.toString()}
                                            as={'div'}
                                            className="!text-black hover:!text-white hover:!bg-[#007bff] hover:rounded-sm py-2 px-4 border-1 cursor-pointer"
                                            onClick={item.onClick}
                                          >
                                            {item.label}
                                          </Menu.Item>
                                        ))}
                                      </Menu.Items>
                                    </Menu>
                                  </div>
                                </div>
                                <div>
                                  <p
                                    data-testid="message-item-text"
                                    className="sb-break-words text-sm whitespace-pre-line"
                                  >
                                    {pinMessage.text}
                                  </p>
                                </div>
                              </div>
                            )}
                            <div className="p-2 bg-[#F3F4F5] rounded flex flex-col gap-y-4 w-full justify-between overflow-y-auto overflow-x-hidden">
                              <Tab.Panels>
                                {Object.values(channelTabs).map(
                                  (platform, idx) => (
                                    <Tab.Panel
                                      key={idx}
                                      data-testid={platform.contentTestId}
                                    >
                                      <MessageList
                                        messageRenderer={(props) => (
                                          <ChatMessageItem
                                            props={props}
                                            channelType={channelType}
                                            pinMessage={pinMessage}
                                            onPinMessage={handlePinMessage}
                                            onUnpinMessage={handleUnpinMessage}
                                            onDeleteMessage={
                                              handleDeleteMessage
                                            }
                                            onStarMessage={handleStarMessage}
                                            onUnstarMessage={
                                              handleUnstarMessage
                                            }
                                          />
                                        )}
                                        fetchMessages={50}
                                      />
                                      {platform.type ===
                                        ChannelType.AllMessages && (
                                        <MessageInput
                                          senderInfo
                                          placeholder={tr({
                                            id: 'globalChat.chatInputPlaceholder',
                                          })}
                                          emojiPicker={<PickerAdapter />}
                                          sendButton={
                                            <SendIcon className="w-5 h-5" />
                                          }
                                        />
                                      )}
                                    </Tab.Panel>
                                  )
                                )}
                              </Tab.Panels>
                            </div>
                          </div>
                        </div>
                      </Chat>
                    </Tab.Group>
                  )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

const StreamChatConfig = (props: StreamChatConfigProps) => {
  const { id: streamId = '' } = useParams<IRouteParams>()
  const { pubnub, chat, setStream } = useContext(PubnubContext)

  useEffect(() => {
    setStream(streamId)
  }, [streamId, setStream])

  return !!pubnub && !!chat ? (
    <_StreamChatConfig {...props} />
  ) : (
    <div data-testid="stream-chat-config" />
  )
}

export default StreamChatConfig
