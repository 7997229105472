import React, { FC } from 'react'
import Button from '@components/atoms/Button'
import { ContrastPillProps, ContrastPillVariant } from './types'

const ContrastPill = ({
  color,
  isActive = false,
  variant,
  onClick,
}: ContrastPillProps) => {
  const wrapperStyles = {
    backgroundColor: color,
    border: isActive ? `solid 3px` : 'solid 1px',
    borderColor: isActive ? 'var(--primary)' : 'var(--white-secondary)',
    width: isActive ? '28px' : '24px',
    height: isActive ? '28px' : '24px',
    borderRadius: isActive ? '15px' : '12px',
  }

  const isAddButton = variant === ContrastPillVariant.ADD

  if (isAddButton) {
    return (
      <Button
        shape="circle"
        className="p-0"
        size="mini"
        buttonType="link"
        onClick={onClick}
      >
        <div
          data-testid="contrast-pill-wrapper"
          className="flex justify-center items-center bg-white border-white-secondary text-white-secondary"
          style={{
            ...wrapperStyles,
            border: 'solid 3px',
          }}
        >
          +
        </div>
      </Button>
    )
  }

  return (
    <Button
      shape="circle"
      className="p-0"
      buttonType="link"
      size="mini"
      onClick={onClick}
    >
      <div
        data-testid="contrast-pill-wrapper"
        className="flex justify-center items-center"
        style={wrapperStyles}
      />
    </Button>
  )
}

export default ContrastPill
