import * as Yup from 'yup'
import { tr } from '@constants/other'
import { Position } from '@interfaces/IStreamConfig'

const chatOrientationSchema = {
  hOffset: Yup.number()
    .integer()
    .typeError(tr({ id: 'validation.required' }))
    .required(tr({ id: 'validation.required' }))
    .min(0, tr({ id: 'validation.chatHorizontalOffset.min' }))
    .max(60, tr({ id: 'validation.chatHorizontalOffset.max' })),
  // hOffset: Yup.number().integer().nullable(true),
  vOffset: Yup.number()
    .integer()
    .typeError(tr({ id: 'validation.required' }))
    .required(tr({ id: 'validation.required' }))
    .min(0, tr({ id: 'validation.chatVerticalOffset.min' }))
    .max(60, tr({ id: 'validation.chatVerticalOffset.max' })),
  hAlign: Yup.mixed<Position>().oneOf(Object.values(Position)),
  vAlign: Yup.mixed<Position>().oneOf(Object.values(Position)),
}

const chatPositionSchema = Yup.object().shape({
  orientationLandscape: Yup.object().shape({
    ...chatOrientationSchema,
  }),
  orientationPortrait: Yup.object().shape({
    ...chatOrientationSchema,
  }),
})

export const schema = Yup.object().shape({
  name: Yup.string(),
  position: Yup.array(chatPositionSchema),
})
