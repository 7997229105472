import { platformOptions, userGroupOptions } from '@constants/other'
import { Activity } from '@interfaces/Auth-v2'

const generateChartData = (
  data: Activity[],
  platform: string,
  userGroup: string
): { [ts: string]: any } => {
  const loggedIn = userGroup === 'loggedIn' ? true : false
  const filteredData = data.filter((series) => {
    return series.data.some((values) => {
      if (platform === 'all' && userGroup === 'all') return values
      if (platform === 'all' && userGroup !== 'all')
        return values.loggedIn === loggedIn
      if (platform !== 'all' && userGroup === 'all')
        return values.sdkPlatform === platform
      if (platform !== 'all' && userGroup !== 'all')
        return values.loggedIn === loggedIn && values.sdkPlatform === platform
    })
  })
  const response: { [ts: string]: any } = {}

  filteredData.forEach((series) => {
    response[series.timestamp] = {}
    response[series.timestamp].userGroups = {}
    userGroupOptions.forEach((ug) => {
      response[series.timestamp].userGroups[ug.key] = {}
      response[series.timestamp].userGroups[ug.key].platforms = {}
      response[series.timestamp].userGroups[ug.key].platforms.all = 0

      platformOptions.forEach((platform) => {
        response[series.timestamp].userGroups[ug.key].platforms[
          platform.key
        ] = 0
      })
    })
  })
  filteredData.forEach((series) => {
    series.data.forEach((values) => {
      const userGroup = !!values.loggedIn ? 'loggedIn' : 'anonymous'
      response[series.timestamp].userGroups['all'].platforms['all'] +=
        values.count
      response[series.timestamp].userGroups['all'].platforms[
        values.sdkPlatform
      ] += values.count
      response[series.timestamp].userGroups[userGroup].platforms['all'] +=
        values.count
      response[series.timestamp].userGroups[userGroup].platforms[
        values.sdkPlatform
      ] += values.count
    })
  })

  return response
}

export default generateChartData
