import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient, UseQueryOptions } from 'react-query'
import { QueryKey } from '@utils/reactQuery/types'
import { useFetch } from '@utils/reactQuery/useFetch'
import { usePost } from '@utils/reactQuery/usePost'
import { GetVodResponse, GetVodVoteablesResponse } from '@interfaces/Vod'
import { client } from './client'

type UseQueryType<T> = UseQueryOptions<T, Error, T, QueryKey>

/*****************************************
 * GET requests
 ******************************************/

interface GetVodVoteablesParams<T> {
  vodId: string
  options?: UseQueryType<T>
}

export const useGetVodVoteables = <T = GetVodVoteablesResponse>({
  vodId,
  options,
}: GetVodVoteablesParams<T>) =>
  useFetch<T>({
    url: `/vod/${vodId}/voteables`,
    config: options,
  })

interface GetVodsParams<T> {
  streamId?: string
  options?: UseQueryType<T>
}

export const useGetVods = <T = GetVodResponse>(params?: GetVodsParams<T>) => {
  let url = '/vods'

  if (params?.streamId) {
    url += `?filter=stream_id:${params.streamId}`
  }

  return useFetch<T>({ url, config: params?.options })
}

/*****************************************
 * POST requests
 ******************************************/

export const createVOD = async (id: string): Promise<AxiosResponse> => {
  const response = await client.post(`/vod`, { streamId: id })
  return response
}

export const usePostVodVoteableTimeshift = (vodId: string | null) => {
  return usePost(`/vod/${vodId}/voteables/timeshift`, { vodId })
}

/*****************************************
 * PATCH requests
 ******************************************/

export const useUpdateVoteable = (
  vodId: string | null,
  voteableId: string | null
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (visible: boolean) =>
      client.patch(`/vod/${vodId}/voteables/${voteableId}`, {
        visible: visible,
      }),
    {
      onMutate: () => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        queryClient.cancelQueries(`/vod/${vodId}/voteables`)
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(`/vod/${vodId}/voteables`)
      },
    }
  )
}
