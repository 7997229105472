import React, { FC } from 'react'
import { ReactComponent as WarningFilledSVG } from '@assets/warning-filled.svg'
import { Link } from 'react-router-dom'
import { AlertProps } from './types'

const Alert: FC<React.PropsWithChildren<AlertProps>> = ({
  text,
  action,
  className,
  actionLink,
  actionCallback,
  actionClassName = '',
  iconComponent,
  'data-testid': testId,
}) => {
  const textPadding = action ? 'pr-2' : ''
  return (
    <div
      className={`flex items-start bg-[#efede9] py-1 px-2 text-sm rounded-sm ${className}`}
      data-testid={testId}
    >
      {iconComponent ?? (
        <WarningFilledSVG className="w-5 h-5 mr-2 flex-shrink-0 text-[#CE8602]" />
      )}
      <p className={`m-0 flex-grow ${textPadding}`}>{text}</p>
      {actionLink ? (
        <Link className={actionClassName} to={actionLink}>
          {action}
        </Link>
      ) : actionCallback ? (
        <button
          className={`border-none ${actionClassName}`}
          onClick={actionCallback}
        >
          {action}
        </button>
      ) : (
        <>{action}</>
      )}
    </div>
  )
}

export default Alert
