import {
  AnnouncementTemplate,
  GetAnnouncementTemplatesResponse,
  GetVoteablesTemplatesResponse,
  Template,
} from '@interfaces/Template'
import React, { FC, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { TemplateTypeTabs } from '@components/organisms/TemplateList/types'
import { tr } from '@constants/other'
import { Menu } from '@headlessui/react'
import Tabs, { TabStyle } from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import {
  useAnnouncementTemplates,
  useVoteableTemplates,
} from '@services/requests/templates'
import TemplateSection from '@components/organisms/TemplateSection'
import { TemplateContext } from '@services/providers/TemplateProvider'

const GlobalTemplatesList: FC<React.PropsWithChildren<unknown>> = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const {
    selectedTemplate,
    selectedAnnouncementTemplate,
    setSelectedTemplate,
    setSelectedAnnouncementTemplate,
    resetSelectedTemplates,
  } = useContext(TemplateContext)

  const handleDoubleClick = (template: Template | AnnouncementTemplate) => {
    if (pathname?.includes('buff')) {
      navigate(`/stream-management/global-templates/buff/${template.id}`)
    } else {
      navigate(
        `/stream-management/global-templates/announcements/${template.id}`
      )
    }
  }

  const handleClick = (template: Template | AnnouncementTemplate) => {
    if (pathname?.includes('buff')) {
      setSelectedTemplate(template as unknown as Template)
    } else {
      setSelectedAnnouncementTemplate(
        template as unknown as AnnouncementTemplate
      )
    }
  }

  const handleTemplateCreate = (type: TemplateTypeTabs) => {
    if (type === TemplateTypeTabs.Buffs) {
      navigate('/stream-management/global-templates/buff/new')
    }

    if (type === TemplateTypeTabs.Announcements) {
      navigate('/stream-management/global-templates/announcements/new')
    }
  }

  const components = {
    buff: {
      title: tr({ id: 'buff.buff' }),
      component: (
        <>
          <TemplateSection<GetVoteablesTemplatesResponse>
            key="buffs"
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            query={useVoteableTemplates}
            templateType={TemplateTypeTabs.Buffs}
            selected={selectedTemplate ?? undefined}
            searchPlaceholder={tr({ id: 'template.searchBuffs' })}
          />
        </>
      ),
    },
    announcements: {
      title: tr({ id: 'announcements.announcement' }),
      component: (
        <>
          <TemplateSection<GetAnnouncementTemplatesResponse>
            key="announcements"
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            query={useAnnouncementTemplates}
            templateType={TemplateTypeTabs.Announcements}
            selected={selectedAnnouncementTemplate ?? undefined}
            searchPlaceholder={tr({ id: 'template.searchAnnouncements' })}
          />
        </>
      ),
    },
  }

  const lastSlug = pathname.split('/').pop() ?? ''

  const tabs = Object.entries(components).map(([slug, { title }]) => {
    return {
      text: title,
      href: `/stream-management/global-templates/${slug}`,
    }
  })

  const tabsIndex = Object.keys(components).indexOf(lastSlug)

  const dropdownItems = [
    {
      onClick: () => handleTemplateCreate(TemplateTypeTabs.Buffs),
      label: tr({ id: 'buff.buff' }),
    },
    {
      onClick: () => handleTemplateCreate(TemplateTypeTabs.Announcements),
      label: tr({ id: 'announcements.announcement' }),
    },
  ]

  const newTemplateButton = (
    <Menu as="div" className="relative" id="dropdown-new-template-button">
      <Menu.Button
        id="dropdown-new-template-button"
        className="sb-dropdown-toggle sb-btn !text-white !bg-primary"
      >
        {tr({ id: 'template.newTemplate' })}
      </Menu.Button>
      <Menu.Items
        as="div"
        className="absolute right-0 z-10 bg-dark-background w-48 focus:outline-none rounded shadow-lg bg-white"
      >
        {dropdownItems.map((item) => (
          <Menu.Item
            key={item.label}
            as={'div'}
            className="!text-[#7D7D7D] hover:!text-white hover:!bg-[#007bff] hover:rounded-sm py-2 px-4 border-1"
            onClick={item.onClick}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  )

  useEffect(() => {
    resetSelectedTemplates()
  }, [lastSlug, resetSelectedTemplates])

  return (
    <div className="flex flex-col min-h-[254px] bg-[#f1f4f8] mt-6 relative rounded-lg p-3">
      <Tabs
        tabs={tabs}
        selectedIndex={tabsIndex}
        headerChildren={newTemplateButton}
        tabStyle={TabStyle.buttons}
      >
        {Object.entries(components).map(([slug, { component }]) => {
          return <TabPanel key={slug}>{component}</TabPanel>
        })}
      </Tabs>
    </div>
  )
}

export default GlobalTemplatesList
