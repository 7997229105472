import React, { useLayoutEffect, useState, FC, ReactElement } from 'react'
import GettingStartedSection from '@components/organisms/GettingStartedSection'
import { tr } from '../constants/other'
import { gettingStartedTipsLocalStorageKey } from '../constants/ui'

const RELEASE_VERSION = process.env.REPO_TAG

const HomePage: FC<React.PropsWithChildren<unknown>> = (): ReactElement => {
  const [showTips, setShowTips] = useState<boolean>(true)

  useLayoutEffect(() => {
    const result = localStorage.getItem(gettingStartedTipsLocalStorageKey)
    if (result) {
      const showTips = Boolean(JSON.parse(result))
      setShowTips(showTips)
    }
  }, [])

  // const handleHideTipsClick = () => {
  //   localStorage.setItem(gettingStartedTipsLocalStorageKey, 'false')
  //   setShowTips(false)
  // }

  return (
    <div className="flex h-full justify-center">
      <div className="home-page p-5 m-5">
        {showTips && (
          <div className="pb-4 mb-2" data-testid="home-page__tips-section">
            <div className="flex justify-between items-end mb-3">
              <h2 className="mb-4">{tr({ id: 'generic.getStarted' })}</h2>
              {/* <p className="mb-2">
                {tr({ id: 'generic.alreadyPro' })}{' '}
                <span role="img" aria-label="pro">
                  💪
                </span>{' '}
                :{' '}
                <button
                  type="button"
                  style={{ color: 'var(--primary)', fontWeight: 600 }}
                  className="bg-transparent border-0"
                  onClick={handleHideTipsClick}
                  data-testid="home-page__hide-tips"
                >
                  {tr({ id: 'generic.hideTips' })}
                </button>
              </p> */}
            </div>
            <GettingStartedSection />
          </div>
        )}

        {/* <div>
          <div>
            <h3 className="h3 pl-1 mb-1">{tr({ id: 'generic.overview' })}</h3>
            <OverviewSection />
          </div>
        </div> */}
      </div>

      {!!RELEASE_VERSION && (
        <div className="absolute bottom-4 right-6 text-[#717A85] text-sm">
          {tr({ id: 'generic.releaseVersion' }, { value: RELEASE_VERSION })}
        </div>
      )}
    </div>
  )
}

export default HomePage
