import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query'
import { getStreams } from '@services/requests/stream'
import { GetStreamsResponse } from '@interfaces/IStream'
import { getStreamConfig } from '@services/requests/stream-config'
import { StreamConfig } from '@interfaces/IStreamConfig'

/**
 * @param {number} limit Number of items to return per page
 * @param {string} query query param used when requesting streams
 * @param {string} filter
 * @param {string} queryKey react query key to override default
 * @return {IStream}
 */
export const useInfiniteStreams = (
  limit: number = 30,
  query?: string,
  filter?: string,
  queryKey?: string
) => {
  const key = [queryKey ?? 'streams-infinite', query, filter]
  return useInfiniteQuery(
    key,
    ({ pageParam = '' }) => getStreams(limit, pageParam, query, filter),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.nextPage?.length) return undefined

        return lastPage?.nextPage
      },
    }
  )
}

/**
 * @param {number} limit Number of items to return per page
 * @param {string} query query param used when requesting streams
 * @param {string} filter
 * @param {object} queryOptions query options to be passed to useQuery
 * @return {IStream}
 */
export const useStreams = (
  limit: number = 30,
  query?: string,
  filter?: string,
  queryOptions?:
    | Omit<
        UseQueryOptions<
          GetStreamsResponse,
          unknown,
          GetStreamsResponse,
          (string | undefined)[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const key = ['streams', query, filter]
  return useQuery(key, () => getStreams(limit, '', query, filter), queryOptions)
}

/**
 * @param {string} id stream id
 * @param {object} queryOptions query options to be passed to useQuery
 * @return {StreamConfig}
 */
export const useStreamConfig = (
  id: string,
  queryOptions?:
    | Omit<
        UseQueryOptions<
          StreamConfig,
          unknown,
          StreamConfig,
          (string | undefined)[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const key = ['stream-config', id]
  return useQuery(key, () => getStreamConfig(id), queryOptions)
}
