import { string, object, AnySchema } from 'yup'
import { LanguageCode } from '@interfaces/ILanguage'
import { requiredIfPathPresent, isValidURL } from '@utils/validation'
import { tr } from '@constants/other'

export const getSponsorFormSectionSchema = (languages: LanguageCode[]) => {
  const sponsor: Partial<Record<LanguageCode, AnySchema>> = ({} = {})

  languages.forEach((lang) => {
    const schemaObject = {
      imageUrl: string().test(
        ...requiredIfPathPresent(
          tr({ id: 'validation.required' }),
          `showSponsor.[${lang}]`
        )
      ),
      linkTarget: string().test(
        ...isValidURL(tr({ id: 'sponsor.linkTargetError' }))
      ),
    }

    sponsor[lang] = object().shape(schemaObject)
  })

  return object().shape({ sponsor: object().shape(sponsor) })
}
