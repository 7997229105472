import React, { useMemo } from 'react'
import { tr } from '@constants/other'
import { Form, Formik } from 'formik'
import Button from '@components/atoms/Button'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'
import { useToast } from '@utils/hooks/useToast'
import { useMutation, useQuery } from 'react-query'
import { queryClient } from '@utils/reactQuery/client'
import { useClientConfig } from '@services/requests/client-config'
import Switch from '@components/atoms/Switch'
import { UIElements } from '@interfaces/ClientConfig'
import { Platform } from '@interfaces/IStreamConfig'
import { UiElementsConfigProps } from './types'

const defaultValues: UIElements = {
  features: {
    leaderboard: {
      enabled: false,
    },
    friends: {
      enabled: false,
    },
    chat: {
      enabled: false,
    },
    profile: {
      enabled: false,
    },
  },
  externalLinks: false,
}

const UiElementsConfig = (_: UiElementsConfigProps) => {
  const { getPlatforms, getUiElements, updateUiElements } = useClientConfig()
  const { addToast, addErrorToast } = useToast()

  const { data: sdk } = useQuery(['sdk'], getUiElements)
  const { data: platformsData } = useQuery(['platforms'], getPlatforms)

  const platforms = platformsData?.platforms
  const isPlatformTwitchOnly =
    platforms?.length === 1 && platforms[0] === Platform.TWITCH

  const { mutate: mutateUpdatePlatforms, isLoading } = useMutation(
    updateUiElements,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sdk'])
        addToast({
          msg: tr({ id: 'configuration.sdkSettingsUpdated' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
      },
      onError: (err) => {
        addErrorToast(err)
      },
    }
  )

  const initialValues: UIElements = useMemo(() => {
    if (!sdk) {
      return defaultValues
    }
    return sdk
  }, [sdk])

  const handleSubmit = (values: UIElements) => {
    let enabledFeatures = {}

    // Due to an uncommon behaviour on the API. The features section needs us to filter out the false values
    // but externalLinks can be false
    for (const [key, value] of Object.entries(values.features)) {
      if (value.enabled) {
        enabledFeatures = {
          ...enabledFeatures,
          [key]: value,
        }
      }
    }
    mutateUpdatePlatforms({
      externalLinks: values.externalLinks,
      features: values.features,
    } as UIElements)
  }

  const enabledText = tr({ id: 'generic.enabled' })
  const disabledText = tr({ id: 'generic.disabled' })

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange
        validateOnBlur
        validateOnMount
        enableReinitialize
      >
        {({ values, isValid, setFieldValue }) => {
          const isChatEnabled =
            values.features?.friends?.enabled && !isPlatformTwitchOnly

          return (
            <Form>
              <fieldset
                className="p-4 rounded-lg mb-4 bg-white-background"
                data-testid="tabs-section"
              >
                <h6 className="font-semibold">
                  {tr({ id: 'configuration.uiElements.showUiElements' })}
                </h6>

                <div className="flex flex-col gap-y-4 mt-4">
                  {/* Leaderboard Tab */}
                  <div className="flex items-start">
                    <div className="flex mr-8 w-60">
                      <label
                        className="flex mb-0 mr-2 items-center !font-normal"
                        htmlFor="showUi"
                      >
                        {tr({ id: 'configuration.leaderboard' })}
                      </label>
                      <Tooltip placement="right">
                        <TooltipTrigger>
                          <div className="flex items-center gap-x-2 cursor-pointer">
                            <InfoCircleIcon className="w-4 h-4" />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <span>
                            {tr({
                              id: 'configuration.uiElements.leaderboardTooltip',
                            })}
                          </span>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    <Switch
                      textClassname="text-sm uppercase"
                      textPosition="right"
                      name="features.leaderboard.enabled"
                      id="features.leaderboard.enabled"
                      text={
                        values.features?.leaderboard?.enabled
                          ? enabledText
                          : disabledText
                      }
                      isChecked={!!values.features?.leaderboard?.enabled}
                      onToggle={(e) =>
                        setFieldValue(
                          'features.leaderboard.enabled',
                          e.target.checked
                        )
                      }
                    />
                  </div>

                  {/* Friends Tab */}
                  <div className="flex items-start">
                    <div className="flex mr-8 w-60">
                      <label
                        className="flex mb-0 mr-2 items-center !font-normal"
                        htmlFor="showUi"
                      >
                        {tr({ id: 'configuration.friends' })}
                      </label>
                      <Tooltip placement="right">
                        <TooltipTrigger>
                          <div className="flex items-center gap-x-2 cursor-pointer">
                            <InfoCircleIcon className="w-4 h-4" />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <span>
                            {tr({
                              id: 'configuration.uiElements.friendsTooltip',
                            })}
                          </span>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    <Switch
                      textClassname="text-sm uppercase"
                      textPosition="right"
                      name="features.friends.enabled"
                      id="features.friends.enabled"
                      text={
                        values.features?.friends?.enabled
                          ? enabledText
                          : disabledText
                      }
                      isChecked={!!values.features?.friends?.enabled}
                      onToggle={(e) => {
                        const checked = e.target.checked
                        setFieldValue('features.friends.enabled', checked)
                        setFieldValue('features.chat.enabled', checked)
                      }}
                    />
                  </div>

                  {/* Chat Tab */}
                  <div className="flex items-start">
                    <div
                      className={`flex mr-8 w-60 ${
                        !isChatEnabled ? 'opacity-50' : ''
                      }`}
                    >
                      <label
                        className="flex mb-0 mr-2 items-center !font-normal"
                        htmlFor="showUi"
                      >
                        {tr({ id: 'configuration.chat' })}
                      </label>
                      <Tooltip placement="right">
                        <TooltipTrigger>
                          <div className="flex items-center gap-x-2 cursor-pointer">
                            <InfoCircleIcon className="w-4 h-4" />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <span>
                            {tr({
                              id: 'configuration.uiElements.chatTooltip',
                            })}
                          </span>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    {isChatEnabled ? (
                      <Switch
                        textClassname="text-sm uppercase"
                        textPosition="right"
                        name="features.chat.enabled"
                        id="features.chat.enabled"
                        text={
                          values.features?.chat?.enabled
                            ? enabledText
                            : disabledText
                        }
                        isChecked={!!values.features?.chat?.enabled}
                        onToggle={(e) =>
                          setFieldValue(
                            'features.chat.enabled',
                            e.target.checked
                          )
                        }
                      />
                    ) : (
                      <div className="h-9" />
                    )}
                  </div>

                  {/* Profile Tab */}
                  <div className="flex items-start">
                    <div className="flex mr-8 w-60">
                      <label
                        className="flex mb-0 mr-2 items-center !font-normal"
                        htmlFor="showUi"
                      >
                        {tr({ id: 'configuration.profile' })}
                      </label>
                      <Tooltip placement="right">
                        <TooltipTrigger>
                          <div className="flex items-center gap-x-2 cursor-pointer">
                            <InfoCircleIcon className="w-4 h-4" />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <span>
                            {tr({
                              id: 'configuration.uiElements.profileTooltip',
                            })}
                          </span>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    <Switch
                      textClassname="text-sm uppercase"
                      textPosition="right"
                      name="features.profile.enabled"
                      id="features.profile.enabled"
                      text={
                        values.features?.profile?.enabled
                          ? enabledText
                          : disabledText
                      }
                      isChecked={!!values.features?.profile?.enabled}
                      onToggle={(e) =>
                        setFieldValue(
                          'features.profile.enabled',
                          e.target.checked
                        )
                      }
                    />
                  </div>
                </div>
              </fieldset>

              <fieldset
                className="p-4 rounded-lg mb-4 bg-white-background"
                data-testid="deep-links-section"
              >
                <h6 className="font-semibold">
                  {tr({ id: 'configuration.uiElements.links' })}
                </h6>

                <div className="flex flex-col gap-y-4 mt-4">
                  {/* Deep Links */}
                  <div className="flex items-start">
                    <div className="flex mr-8 w-60">
                      <label
                        className="flex mb-0 mr-2 items-center !font-normal"
                        htmlFor="externalLinks"
                      >
                        {tr({
                          id: 'configuration.uiElements.openLinksExternally',
                        })}
                      </label>
                      <Tooltip placement="right">
                        <TooltipTrigger>
                          <div className="flex items-center gap-x-2 cursor-pointer">
                            <InfoCircleIcon className="w-4 h-4" />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <span>
                            {tr({
                              id: 'configuration.uiElements.linksTooltip',
                            })}
                          </span>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    <Switch
                      textClassname="text-sm uppercase"
                      textPosition="right"
                      name="externalLinks"
                      id="externalLinks"
                      text={values.externalLinks ? enabledText : disabledText}
                      isChecked={!!values.externalLinks}
                      onToggle={(e) =>
                        setFieldValue('externalLinks', e.target.checked)
                      }
                    />
                  </div>
                </div>
              </fieldset>

              <div className="flex justify-end">
                <Button type="submit" disabled={isLoading || !isValid}>
                  {tr({
                    id: 'generic.update',
                  })}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default UiElementsConfig
