import React, { FC, useEffect, useRef, useContext } from 'react'
import { tr, BUFFUP_REFRESH_TOKEN } from '@constants/other'
import { ErrorContext } from '@services/providers/ErrorProvider'
import { ReactComponent as GoogleLogo } from '@assets/google-logo.svg'
import { ReactComponent as FacebookLogo } from '@assets/facebook-logo.svg'
import { OIDCProvider } from '@interfaces/auth'
import { refreshJwtToken } from '@services/requests/auth'
import { ChooseAuthProps } from './types'

const ChooseAuth: FC<React.PropsWithChildren<ChooseAuthProps>> = ({
  onContinue,
  onOIDCSuccess,
}) => {
  const removeWindowMessageListener = useRef<any>(null)
  const { setModalError } = useContext(ErrorContext)

  const handleWithEmail = () => {
    onContinue()
  }

  const handleWithOIDC = (provider: OIDCProvider) => {
    const oidcURL = `${process.env.REACT_APP_API_HOST}/auth/oidc/login?providerName=${provider}&scope=dashboard`
    const oidcWindow = window.open(
      oidcURL,
      undefined,
      'popup, width=550, height=650'
    )

    const removeHandler = () =>
      window.removeEventListener('message', messageHandler, false)

    const messageHandler = async (event: MessageEvent) => {
      if (event.origin !== process.env.REACT_APP_API_HOST) return

      let refreshToken = ''
      let errorDetails
      try {
        const json = JSON.parse(event.data)
        errorDetails = json
      } catch (error) {
        refreshToken = event.data
      }

      oidcWindow?.close()

      if (errorDetails) {
        setModalError({
          title: 'Unauthorized Access',
          description:
            'Your account does not have access to this page.\nPlease switch or upgrade your account.',
        })
        return
      }

      localStorage.setItem(BUFFUP_REFRESH_TOKEN, refreshToken)
      try {
        const token = await refreshJwtToken()
        onOIDCSuccess(token)
        removeHandler()
        removeWindowMessageListener.current = null
      } catch (err) {
        if (err?.response?.data?.error?.code === 403) {
          return
        }
      }
    }

    window.addEventListener('message', messageHandler, false)

    removeWindowMessageListener.current = removeHandler
  }

  useEffect(() => {
    return () => {
      removeWindowMessageListener.current &&
        removeWindowMessageListener.current()
    }
  }, [])

  const oicdButtonClasses =
    'inline-flex items-center flex-shrink-0 w-4/5 px-3 py-2.5 font-semibold rounded leading-none border-none mb-4'

  return (
    <div
      data-testid="choose-auth"
      className="flex flex-col items-center min-h-[75px]"
    >
      <h4 className="mb-4">{tr({ id: 'signIn.title' })}</h4>
      <h5 className="mb-4">{tr({ id: 'signIn.logInOrSign' })}</h5>
      <div className="flex flex-col flex-1 items-center justify-center w-full">
        <button
          data-testid="choose-auth__google-button"
          onClick={() => handleWithOIDC(OIDCProvider.GOOGLE)}
          className={`${oicdButtonClasses} bg-white`}
        >
          <GoogleLogo className="w-4 h-4" />
          <span className="text-[#757575] pl-1">
            {tr({ id: 'signIn.continueWithGoogle' })}
          </span>
        </button>
        {process.env.FB_LOGIN === 'true' && (
          <button
            data-testid="choose-auth__facebook-button"
            onClick={() => handleWithOIDC(OIDCProvider.FACEBOOK)}
            className={`${oicdButtonClasses} bg-[#1877F2]`}
          >
            <FacebookLogo className="w-4 h-4" />
            <span className="text-white pl-1">
              {tr({ id: 'signIn.continueWithFacebook' })}
            </span>
          </button>
        )}
        <button
          data-testid="choose-auth__email-button"
          onClick={handleWithEmail}
          className="text-sm font-bold text-base-800 border-none bg-transparent"
        >
          {tr({ id: 'signIn.continueWithEmail' })}
        </button>
      </div>
    </div>
  )
}

export default ChooseAuth
