import { string, object } from 'yup'
import { requiredIfPathPresent, isValidURL } from '@utils/validation'
import { tr } from '@constants/other'

export const getBannerFormSectionSchema = (lang: string) => {
  const schemaObject = {
    imageUrl: string().test(
      ...requiredIfPathPresent(
        tr({ id: 'validation.required' }),
        `content.${lang}.showBanner`
      )
    ),
    linkTarget: string().test(
      ...isValidURL(tr({ id: 'sponsor.linkTargetError' }))
    ),
  }

  return object().shape(schemaObject)
}
