export enum ImageFieldSize {
  SQUARE = 'square',
  LEADERBOARD_SPONSOR = 'leaderboard-sponsor',
}

export const imageFieldSizeMap = {
  [ImageFieldSize.SQUARE]: {
    size: '420 x 420 px',
    ratio: {
      x: 1,
      y: 1,
    },
  },
  [ImageFieldSize.LEADERBOARD_SPONSOR]: {
    size: '512 x 160 px',
    ratio: {
      x: 16,
      y: 5,
    },
  },
} as const

export interface ImageFieldWithButtonsProps {
  /**
   * Url of the image to display
   */
  url?: string

  /**
   * Error message to display
   */
  error?: string

  /**
   * If the field has been touched. Needs to be true to display the error
   */
  touched?: boolean

  /**
   * Size of the image
   * @default ImageFieldSize.SQUARE
   */
  imageSize?: ImageFieldSize

  /**
   * Handler called when add button is clicked
   */
  onAdd?: () => void

  /**
   * Handled called when remove button is clicked
   */
  onRemove?: () => void

  /**
   * Selection ID for test purposes
   */
  'data-testid'?: string
}
