import { object, string, number } from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { LanguageCode } from '@interfaces/ILanguage'
import { requiredIfPathPresent, validateURL } from '@utils/validation'
import { tr } from '@constants/other'
import {
  RequiredStringLocalisedValidation,
  StringLocalisedValidation,
} from '@interfaces/Validations'
import { getSponsorFormSectionSchema } from '@components/molecules/SponsorFormSection/schema'
import { BUFF_MAX_DURATION, BUFF_MIN_DURATION } from '@constants/form'

export const getSchema = (languages: LanguageCode[]) => {
  const titleText: RequiredStringLocalisedValidation = {}
  const text: RequiredStringLocalisedValidation = {}
  const buttonText: StringLocalisedValidation = {}
  const linkUrl: StringLocalisedValidation = {}
  const author: ObjectShape = {}

  languages.forEach((langStr) => {
    const lang = langStr as LanguageCode
    author[lang] = object().shape({
      text: string().required(tr({ id: 'validation.required' })),
      imageUrl: string().required(tr({ id: 'validation.required' })),
    })
    titleText[lang] = string()
      .required(tr({ id: 'validation.required' }))
      .max(50, tr({ id: 'validation.announcementTitleMax' }))
    text[lang] = string().required(tr({ id: 'validation.required' }))
    buttonText[lang] = string().test(
      ...requiredIfPathPresent(
        'Button text is required if setting a url',
        `linkUrl.[${lang}]`
      )
    )
    linkUrl[lang] = string()
      .test(
        'url-validation',
        tr({ id: 'validation.announcementButtonLink' }),
        (value?: string) => {
          if (!value) return true
          const sportBuffLinkRegex =
            /((sportbuff):\/\/)([a-zA-Z0-9#?!@$%^&*-.]{1,})$/

          if (sportBuffLinkRegex.test(value)) {
            return true
          }
          return validateURL(value)
        }
      )
      .test(
        ...requiredIfPathPresent(
          'Url is required if setting button text',
          `buttonText.[${lang}]`
        )
      )
  })

  const schema = object()
    .shape({
      author: object().shape(author),
      duration: number()
        .typeError(tr({ id: 'validation.required' }))
        .required(tr({ id: 'validation.required' }))
        .min(BUFF_MIN_DURATION, tr({ id: 'validation.minDuration' }))
        .max(BUFF_MAX_DURATION, tr({ id: 'validation.maxDuration' })),
      titleText: object().shape(titleText),
      text: object().shape(text),
      buttonText: object().shape(buttonText),
      linkUrl: object().shape(linkUrl),
    })
    .concat(getSponsorFormSectionSchema(languages))

  return schema
}
