import React, { FC, useState, MouseEvent, useContext, useEffect } from 'react'
import { WelcomeBuffContext } from '@services/providers/WelcomeBuffProvider'
import { tr } from '@constants/other'
import { useToast } from '@utils/hooks/useToast'
import { TemplateContext } from '@services/providers/TemplateProvider'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import Button, { ButtonVariant } from '@components/atoms/Button'
import { deleteWelcomeBuff } from '@services/requests/onboarding'
import { PreviewContext } from '@services/providers/PreviewProvider'
import { StreamManagementActiveTab } from './StreamManagement'
import BuffPreviewWidget from '@components/molecules/BuffPreviewWidget'

export enum SelectedAsideTab {
  Preview = 'PREVIEW_BUFF',
}

interface IAsideProps {
  onClick?: () => void
  onEditTemplate: (id: string) => void
  onDeleteTemplate: () => void
  btnText: string
  deleteCallback?: () => void
  submitCallback?: () => void
  displayWelcomeBuffForm?: boolean
  activeTab: StreamManagementActiveTab
}

const Aside: FC<React.PropsWithChildren<IAsideProps>> = ({
  btnText,
  displayWelcomeBuffForm,
  activeTab: mainActiveTab,
  onClick,
  deleteCallback,
  onEditTemplate,
  onDeleteTemplate,
}) => {
  const [showTemplateDelete, setShowTemplateDelete] = useState<boolean>(false)
  const { setBuff } = useContext(PreviewContext)

  const { addErrorToast } = useToast()
  const { selectedWelcomeBuff, refetchWelcomeBuffs } =
    useContext(WelcomeBuffContext)
  const {
    currentTemplateIdSelected,
    selectedTemplate,
    selectedAnnouncementTemplate,
  } = useContext(TemplateContext)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    try {
      onClick && onClick()
    } catch (error) {
      addErrorToast(error)
    }
  }

  const handleDeleteWelcomeBuff = async () => {
    if (!selectedWelcomeBuff) return
    try {
      await deleteWelcomeBuff(selectedWelcomeBuff.welcomeBuffId)
    } catch (error) {
      addErrorToast(error)
    } finally {
      refetchWelcomeBuffs()
      deleteCallback && deleteCallback()
    }
  }

  useEffect(() => {
    setBuff(null)
  }, [mainActiveTab, setBuff])

  return (
    <>
      <BuffPreviewWidget />
      <div className="flex flex-col mt-2">
        <div className="flex items-center gap-2 my-2">
          {mainActiveTab === StreamManagementActiveTab.GlobalTemplates &&
            (!!selectedTemplate || !!selectedAnnouncementTemplate) && (
              <>
                <Button
                  size="small"
                  variant="secondary"
                  className="w-full"
                  onClick={() => onEditTemplate(currentTemplateIdSelected)}
                >
                  {tr({ id: 'template.edit' })}
                </Button>
                <Button
                  size="small"
                  variant="danger"
                  className="w-full"
                  onClick={() => setShowTemplateDelete(true)}
                >
                  {tr({ id: 'template.delete' })}
                </Button>
              </>
            )}
          {mainActiveTab === StreamManagementActiveTab.WelcomeBuff && (
            <>
              {(displayWelcomeBuffForm || selectedWelcomeBuff) &&
                (onClick ? (
                  <Button size="small" onClick={handleClick} className="w-full">
                    {btnText}
                  </Button>
                ) : (
                  <Button
                    size="small"
                    form="buff-form"
                    type="submit"
                    className="w-full"
                  >
                    {btnText}
                  </Button>
                ))}

              {!!selectedWelcomeBuff && (
                <Button
                  size="small"
                  variant="danger"
                  className="w-full"
                  onClick={handleDeleteWelcomeBuff}
                >
                  {tr({ id: 'welcomeBuff.deleteWelcomeBuff' })}
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      <ConfirmationModal
        show={showTemplateDelete}
        onClose={() => setShowTemplateDelete(false)}
        onSuccess={onDeleteTemplate}
        subtext={tr({ id: 'template.confirmDelete' })}
        successBtnText={tr({ id: 'template.delete' })}
        variant={ButtonVariant.Danger}
      />
    </>
  )
}

export default Aside
