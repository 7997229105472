import React, { useState } from 'react'
import { tr } from '@constants/other'
import { ClientChatFormValues } from '@interfaces/Chat'
import { ReactComponent as TickSVG } from '@assets/answer-tick.svg'
import TabPanel from '@components/molecules/TabPanel'
import Tabs from '@components/molecules/Tabs'
import { ClientChatConfigProps } from './types'
import { useChat } from '@services/requests/chat'
import { useToast } from '@utils/hooks/useToast'
import TextModerationForm from './TextModerationForm'
import BannedUsersForm from './BannedUsersForm'

export enum TabKeys {
  ModeratorProfile,
  TextModeration,
  BannedUsers,
}

const ClientChatConfig = (_: ClientChatConfigProps) => {
  const [activeTab, setActiveTab] = useState<number>(TabKeys.ModeratorProfile)

  const { useUpdateModeration } = useChat()
  const { addErrorToast, addToast } = useToast()
  const { mutateAsync: mutateChatModeration } = useUpdateModeration()

  const handleBannedUsersSubmit = async (values: ClientChatFormValues) => {}
  const handleModerationSubmit = async (values: ClientChatFormValues) => {
    try {
      const { wordList } = values
      const wordArr = wordList?.split('\n') ?? []
      if (!wordArr.length) return
      await mutateChatModeration({ ...values, wordList: wordArr })
      addToast({
        msg: tr({ id: 'globalChat.settingsUpdated' }),
        type: 'success',
        image: <TickSVG className="mr-3 w-8 h-8" />,
      })
    } catch (error) {
      addErrorToast(error)
    }
  }

  const tabs = [
    {
      text: tr({ id: 'globalChat.textModeration' }),
    },
    {
      text: tr({ id: 'globalChat.bannedUsers' }),
    },
  ]

  const tabOrder = [TabKeys.TextModeration, TabKeys.BannedUsers]
  const tabIndex = tabOrder.indexOf(activeTab)

  const setTabIndex = (index: number) => {
    const tabKey = tabOrder[index]
    setActiveTab(tabKey)
  }

  const panelClassName = '!mt-4'

  return (
    <div className="flex flex-col gap-y-4" data-testid="client-chat-config">
      <Tabs
        renderBorder={false}
        tabs={tabs}
        selectedIndex={tabIndex}
        onTabChange={setTabIndex}
      >
        <TabPanel className={panelClassName}>
          <TextModerationForm onSubmit={handleModerationSubmit} />
        </TabPanel>
        <TabPanel className={panelClassName}>
          <BannedUsersForm onSubmit={handleBannedUsersSubmit} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default ClientChatConfig
