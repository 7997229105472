import React, { FC, useMemo, useState } from 'react'
import { Form, Formik } from 'formik'

import { useChat } from '@services/requests/chat'
import { tr } from '@constants/other'
import Button, { ButtonSize, ButtonVariant } from '@components/atoms/Button'
import { ReactComponent as PersonIcon } from '@assets/person.svg'
import { BannedUser, ClientChatFormValues } from '@interfaces/Chat'
import { DataTable } from '@components/molecules/DataTable'
import { queryClient } from '@utils/reactQuery/client'
import { useToast } from '@utils/hooks/useToast'
import { ColumnDef } from '@tanstack/react-table'

interface Props {
  onSubmit: (values: ClientChatFormValues) => Promise<void>
}

const defaultInitialValues: ClientChatFormValues = {
  wordList: '',
  action: 'none',
  maskingCharacter: '*',
  moderateUrls: false,
}

const BannedUsersForm: FC<Props> = ({ onSubmit }) => {
  const [search, setSearch] = useState<string>('')
  const { useGetModeration, useGetBannedUsers, useUnbanUser } = useChat()
  const { addErrorToast } = useToast()
  const { data: moderationData } = useGetModeration()
  const { data: users } = useGetBannedUsers()
  const { mutateAsync: unbanUser } = useUnbanUser()

  const initialValues = useMemo(() => {
    return {
      ...defaultInitialValues,
      wordList: moderationData?.wordList?.join('\n') ?? '',
    }
  }, [moderationData?.wordList]) as ClientChatFormValues

  const columns = useMemo<ColumnDef<BannedUser>[]>(() => {
    const handleUnban = async (user: BannedUser) => {
      try {
        await unbanUser(user.banID)
        queryClient.invalidateQueries('banned-users')
      } catch (error) {
        addErrorToast(error)
      }
    }

    return [
      {
        accessorKey: 'name',
        header: 'Name',
        meta: {
          headerClassname: '!p-2 font-normal',
          rowClassname: '!bg-white border-t border-solid border-[#E1E4E8]',
          className: 'rounded',
        },
        cell: (info) => {
          const { user } = info.row.original as BannedUser

          return (
            <div className="flex items-center gap-x-2">
              <div className="w-10 h-10 rounded-full flex items-center justify-center  outline-none">
                {user.profileImage ? (
                  <img
                    src={user.profileImage}
                    alt={user.profileImageAltText}
                    className="w-full h-full object-cover rounded-full"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-[#D1D6DE] rounded-full border-[1px] border-solid border-[#A3ACB5]">
                    <PersonIcon className="w-5 h-5 text-[#323A46]" />
                  </div>
                )}
              </div>
              <p className="text-base">{user.displayName}</p>
            </div>
          )
        },
      },
      {
        accessorKey: 'actions',
        header: '',
        cell: (info) => {
          const user = info.row.original

          return (
            <div className="flex justify-end">
              <Button
                variant={ButtonVariant.Secondary}
                size={ButtonSize.Small}
                onClick={() => handleUnban(user)}
              >
                {tr({ id: 'globalChat.unban' })}
              </Button>
            </div>
          )
        },
      },
    ]
  }, [addErrorToast, unbanUser])

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      validateOnChange
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, handleChange }) => {
        return (
          <Form className="flex flex-col gap-y-4">
            <div className="p-4 rounded-lg bg-white-background">
              <fieldset className="flex flex-col gap-y-4">
                <div className="mb-4">
                  <div className="mb-3 flex items-center justify-between">
                    <h1 className="m-0 text-xl font-bold">
                      {tr({ id: 'globalChat.bannedUsers' })}
                    </h1>
                    {/* <TextInput
                            inputContainerClasses="!min-h-0"
                            key={`search`}
                            autoFocus
                            placeholder={tr({ id: 'generic.search' })}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            customInputStyle={{
                              backgroundImage: `url("/assets/svg/search.svg")`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: '20px 20px',
                              backgroundPosition: '2% center',
                              paddingLeft: 25,
                            }}
                          /> */}
                  </div>

                  <p className="text-[#586069] text-sm">
                    {tr({ id: 'globalChat.bannedUsersDescription' })}
                  </p>
                </div>
              </fieldset>
              <DataTable
                classes={{
                  container: '!p-0',
                  table:
                    'rounded border-[1px] border-solid border-[#E1E4E8] !bg-white !border-collapse',
                  header:
                    '!bg-[#F8F9FA] !p-2 !border-none !shadow-none text-[#717A85] text-sm font-semibold rounded',
                  row: 'rounded',
                }}
                tableData={users ?? []}
                columns={columns}
              />
            </div>

            {/* <Button
                    className="w-fit"
                    type="submit"
                    style={{
                      display: tabIndex === 0 ? 'block' : 'hidden',
                    }}
                  >
                    {tr({ id: 'generic.update' })}
                  </Button> */}
          </Form>
        )
      }}
    </Formik>
  )
}

export default BannedUsersForm
