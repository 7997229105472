import { UseQueryOptions } from 'react-query'
import { QueryKey } from '@utils/reactQuery/types'
import { useFetch } from '@utils/reactQuery/useFetch'
import { getPaginatedUrl } from '@utils/url'
import { GetGameContentResponse, IGame, IGameResponse } from '@interfaces/games'
import { SortBy, SortDirection } from '@interfaces/Sorting'
import { ApiResponse } from '@constants/other'
import { client } from './client'

interface GetGameParams<T> {
  gameId: string
  options?: UseQueryOptions<T, Error, T, QueryKey>
}

interface GameContentParams {
  gameId: string
  pageSize?: number
  nextParam?: string
}

export const useGetGame = <T = { game: IGame }>({
  gameId,
  options,
}: GetGameParams<T>) =>
  useFetch({
    url: `/games/${gameId}`,
    config: options,
  })

export const endGameById = async (
  gameId: string
): Promise<ApiResponse<IGameResponse>> => {
  const response = await client.post(`/games/${gameId}/end`)

  return response
}

export const getGameContent = async (
  p: GameContentParams
): Promise<GetGameContentResponse> => {
  const url = getPaginatedUrl(
    `/games/${p.gameId}/content`,
    p.pageSize ?? 50,
    p.nextParam ?? ''
  )

  return client.get(url).then((res) => ({
    content: res.data.content,
    nextPage: res.data.pagination.next,
  }))
}
