import React from 'react'
import Button from '@components/atoms/Button'
import Image from '@components/atoms/Image'
import { tr } from '@constants/other'
import { ReactComponent as PlaceholderSVG } from '@assets/placeholder.svg'
import {
  ImageFieldWithButtonsProps,
  ImageFieldSize,
  imageFieldSizeMap,
} from './types'

const ImageFieldWithButtons = ({
  url,
  error,
  touched,
  onAdd,
  onRemove,
  imageSize = ImageFieldSize.SQUARE,
}: ImageFieldWithButtonsProps) => {
  const imageDetails = imageFieldSizeMap[imageSize]

  const paddingPercentage = (imageDetails.ratio.y / imageDetails.ratio.x) * 100

  const hasError = Boolean(error)
  const isTouched = Boolean(touched)

  const displayImageDimensions = !(
    imageSize === ImageFieldSize.LEADERBOARD_SPONSOR &&
    hasError &&
    isTouched
  )

  const borderColor =
    Boolean(error) && Boolean(touched) ? 'border-red' : 'border-[#717A85]'

  return (
    <div className="w-[250px]">
      <button
        type="button"
        className={`rounded border-[1px] border-dashed ${borderColor} relative w-full`}
        onClick={onAdd}
        style={{
          padding: '0',
          paddingBottom: `${paddingPercentage}%`,
        }}
      >
        <div className="absolute inset-0">
          {Boolean(url) ? (
            <div
              style={{
                backgroundImage: `url(/assets/image_placeholder.png)`,
              }}
              className="relative bg-cover no-repeat w-full h-full"
            >
              <Image
                alt={tr({
                  id: 'qaForm.sponsor.buffImageAlt',
                })}
                className="absolute inset-0 w-full h-full rounded object-cover"
                src={url}
                fallbackComponent={
                  <PlaceholderSVG className="absolute inset-0 w-full h-full rounded object-cover" />
                }
              />
            </div>
          ) : (
            <div className="flex text-center items-center w-full h-full bg-white text-[12px] justify-center rounded">
              <div className="flex flex-col items-center">
                {hasError && isTouched && (
                  <>
                    <div className="m-2 text-white text-[1.3em] rounded-xl w-[24px] h-[24px] bg-red">
                      !
                    </div>
                    <div
                      className="mb-2 italic text-red"
                      data-testid="image-field-with-buttons__photo-error"
                    >
                      {error}
                    </div>
                  </>
                )}
                {displayImageDimensions && (
                  <>
                    <p className="font-bold">
                      {tr({
                        id: 'generic.minImageSize',
                      })}
                    </p>
                    <p className="mb-0 font-semibold text-[#586069]">
                      {tr(
                        {
                          id: 'generic.ratio',
                        },
                        { x: imageDetails.ratio.x, y: imageDetails.ratio.y }
                      )}
                    </p>
                    <p className="mb-0 text-[#586069]">{imageDetails.size}</p>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </button>
      <div className="flex justify-between mt-3 mb-4 w-[250px]">
        <Button
          className="w-50 mr-2"
          size="small"
          type="button"
          onClick={onAdd}
        >
          {url ? tr({ id: 'generic.replace' }) : tr({ id: 'generic.add' })}
        </Button>
        <Button
          className="w-50"
          data-testid="image-field-with-buttons__delete-photo"
          type="button"
          size="small"
          variant="secondary"
          onClick={onRemove}
          disabled={!Boolean(url)}
        >
          {tr({ id: 'generic.delete' })}
        </Button>
      </div>
    </div>
  )
}

export default ImageFieldWithButtons
