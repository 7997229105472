import React, {
  createContext,
  FC,
  useReducer,
  Dispatch,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useParams } from 'react-router-dom'
import { useInfiniteScroll } from '@utils/hooks/useInfiniteScroll'
import useGameContent from '@utils/hooks/useGameContent'
import { Voteable } from '@interfaces/Voteable'
import { Announcement } from '@interfaces/Announcement'
import { GetGameContentResponse } from '@interfaces/games'
import { InfiniteData } from 'react-query'
import {
  buffReducer,
  BuffActions,
  BuffActionTypes,
} from '../reducers/buffReducer'

export enum BuffView {
  Buff = 'Buff',
  Templates = 'Templates',
  Pinned = 'Pinned',
}

export type BuffState = {
  unresolvedBuffs: Voteable[]
  view: BuffView
  templateName: string
  isTemplate: boolean
  isFormSubmitting: boolean
}

export interface BuffContextProps {
  state: BuffState
  dispatch: Dispatch<BuffActions>
  selectedBuff: Voteable | null
  setSelectedBuff: (buff: Voteable) => void
  voteablesTriggerRef: (node?: Element | null) => void
  refetchBuffs: () => void
  voteablesData?: InfiniteData<GetGameContentResponse> | undefined
  isFetchingBuffs?: boolean
}

export const initialState: BuffState = {
  unresolvedBuffs: [],
  view: BuffView.Buff,
  templateName: '',
  isTemplate: false,
  isFormSubmitting: false,
}

export const BuffContext = createContext<BuffContextProps>(
  {} as BuffContextProps
)

export type IRouteParams = {
  id: string
  gameId: string
}

const { Provider } = BuffContext

export const BuffProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(buffReducer, initialState)
  const [selectedBuff, setSelectedBuff] = useState<Voteable | null>(null)

  // TODO: Hook up to errors
  const onError = useCallback((error: any) => {
    console.error(error)
  }, [])

  const { id: streamId = '', gameId = '' } = useParams()

  const { data, fetchNextPage, hasNextPage, refetch, isLoading } =
    useGameContent(gameId, 50)
  const { triggerRef: voteablesTriggerRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: fetchNextPage,
  })

  useEffect(() => {
    if (!streamId) return

    const allBuffs: (Voteable | Announcement)[] = []
    data?.pages?.forEach((page) => {
      page?.content?.forEach((content) => {
        allBuffs.push(content.entity)
      })
    })

    dispatch({
      type: BuffActionTypes.Update,
      payload: { buffs: allBuffs },
    })
  }, [data, streamId])

  return (
    <Provider
      value={{
        state,
        dispatch,
        selectedBuff,
        setSelectedBuff,
        refetchBuffs: refetch,
        voteablesTriggerRef,
        voteablesData: data,
        isFetchingBuffs: isLoading,
      }}
    >
      {children}
    </Provider>
  )
}
