import React, { FC, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Menu, Tab } from '@headlessui/react'
import { tr } from '@constants/other'
import {
  AnnouncementTemplate,
  GetAnnouncementTemplatesResponse,
  GetVoteablesTemplatesResponse,
  Template,
} from '@interfaces/Template'
import { TemplateContext } from '@services/providers/TemplateProvider'
import { TemplateTypeTabs } from '@components/organisms/TemplateList/types'
import {
  useAnnouncementTemplates,
  useVoteableTemplates,
} from '@services/requests/templates'
import TemplateSection from '@components/organisms/TemplateSection'
import { StreamTemplatesProps } from './types'
import { IRouteParams } from '@interfaces/RouteParams'

const templateTypes = {
  [TemplateTypeTabs.Buffs]: {
    type: TemplateTypeTabs.Buffs,
    title: 'Buff',
  },
  [TemplateTypeTabs.GlobalBuffs]: {
    type: TemplateTypeTabs.GlobalBuffs,
    title: 'Global Buff',
  },
  [TemplateTypeTabs.Announcements]: {
    type: TemplateTypeTabs.Announcements,
    title: 'Announcement',
  },
  [TemplateTypeTabs.GlobalAnnouncements]: {
    type: TemplateTypeTabs.GlobalAnnouncements,
    title: 'Global Announcement',
  },
}

const StreamTemplates: FC<React.PropsWithChildren<StreamTemplatesProps>> = ({
  templatesActiveTab,
  setTemplateTypeActiveTab,
  onDoubleClick,
}) => {
  const navigate = useNavigate()
  const { id: streamId, gameId } = useParams<IRouteParams>()
  const {
    resetSelectedTemplates,
    setSelectedTemplate,
    setSelectedAnnouncementTemplate,
    selectedTemplate,
    selectedAnnouncementTemplate,
  } = useContext(TemplateContext)

  useEffect(() => {
    resetSelectedTemplates()
  }, [templatesActiveTab, resetSelectedTemplates])

  const handleTabChange = (type: TemplateTypeTabs): void => {
    setTemplateTypeActiveTab(type)
  }

  const handleClick = (template: Template | AnnouncementTemplate) => {
    if (
      templatesActiveTab === TemplateTypeTabs.Buffs ||
      templatesActiveTab === TemplateTypeTabs.GlobalBuffs
    ) {
      setSelectedTemplate(template as unknown as Template)
    } else {
      setSelectedAnnouncementTemplate(
        template as unknown as AnnouncementTemplate
      )
    }
  }

  const handleTemplateCreate = (type: TemplateTypeTabs) => {
    if (type === TemplateTypeTabs.Buffs) {
      navigate(`/streams/${streamId}/${gameId}/play/templates/buff`)
    }

    if (type === TemplateTypeTabs.Announcements) {
      navigate(`/streams/${streamId}/${gameId}/play/templates/announcements`)
    }
  }

  const dropdownItems = [
    {
      onClick: () => handleTemplateCreate(TemplateTypeTabs.Buffs),
      label: tr({ id: 'buff.buff' }),
    },
    {
      onClick: () => handleTemplateCreate(TemplateTypeTabs.Announcements),
      label: tr({ id: 'announcements.announcement' }),
    },
  ]

  const panelClasses =
    'overflow-y-auto -mr-2 default-scroll p-1 pr-2 overflow-x-hidden'

  return (
    <div
      data-testid="template-list-wrapper"
      className="flex flex-col justify-center w-full rounded-sm bg-gray p-3 mt-3"
    >
      <Tab.Group selectedIndex={templatesActiveTab} onChange={handleTabChange}>
        <div className="flex justify-between items-center">
          <Tab.List className="flex w-full items-center justify-between space-x-1">
            <div className="flex items-center space-x-1">
              {Object.values(templateTypes).map((templateType) => (
                <Tab
                  key={templateType.type}
                  className={({ selected }) =>
                    `whitespace-nowrap border-none py-1 my-1 w-full text-sm font-medium rounded-md px-1 ${
                      selected
                        ? 'bg-[#CCD3FF] text-[#4C5687] !font-semibold'
                        : 'bg-transparent text-[#7D7D7D]'
                    }`
                  }
                >
                  {templateType.title}
                </Tab>
              ))}
            </div>
            <Menu
              as="div"
              className="relative"
              id="dropdown-new-template-button"
            >
              <Menu.Button
                id="dropdown-new-template-button"
                className="sb-dropdown-toggle sb-btn !text-white !bg-primary"
              >
                {tr({ id: 'template.newTemplate' })}
              </Menu.Button>
              <Menu.Items
                as="div"
                className="absolute right-0 z-10 bg-dark-background w-48 focus:outline-none rounded shadow-lg bg-white"
              >
                {dropdownItems.map((item) => (
                  <Menu.Item
                    key={item.label}
                    as={'div'}
                    className="!text-[#7D7D7D] hover:!text-white hover:!bg-[#007bff] hover:rounded-sm py-2 px-4 border-1"
                    onClick={item.onClick}
                  >
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>
          </Tab.List>
        </div>

        {/* Content */}
        <Tab.Panels>
          <Tab.Panel key={0} className={panelClasses}>
            <TemplateSection<GetVoteablesTemplatesResponse>
              key="buffs"
              onClick={handleClick}
              onDoubleClick={onDoubleClick}
              query={useVoteableTemplates}
              templateType={TemplateTypeTabs.Buffs}
              selected={selectedTemplate ?? undefined}
              searchPlaceholder={tr({ id: 'template.searchBuffs' })}
              streamFilter={streamId}
              showSearchAll
            />
          </Tab.Panel>
          <Tab.Panel key={2} className={panelClasses}>
            <TemplateSection<GetVoteablesTemplatesResponse>
              key="global-buffs"
              onClick={handleClick}
              onDoubleClick={onDoubleClick}
              query={useVoteableTemplates}
              templateType={TemplateTypeTabs.GlobalBuffs}
              selected={selectedTemplate ?? undefined}
              searchPlaceholder={tr({ id: 'template.searchGlobalBuffs' })}
              showSearchAll
            />
          </Tab.Panel>
          <Tab.Panel key={1} className={panelClasses}>
            <TemplateSection<GetAnnouncementTemplatesResponse>
              key="announcements"
              onClick={handleClick}
              onDoubleClick={onDoubleClick}
              query={useAnnouncementTemplates}
              templateType={TemplateTypeTabs.Announcements}
              selected={selectedAnnouncementTemplate ?? undefined}
              searchPlaceholder={tr({ id: 'template.searchAnnouncements' })}
              streamFilter={streamId}
              showSearchAll
            />
          </Tab.Panel>
          <Tab.Panel key={4} className={panelClasses}>
            <TemplateSection<GetAnnouncementTemplatesResponse>
              key="global-announcements"
              onClick={handleClick}
              onDoubleClick={onDoubleClick}
              query={useAnnouncementTemplates}
              templateType={TemplateTypeTabs.GlobalAnnouncements}
              selected={selectedAnnouncementTemplate ?? undefined}
              searchPlaceholder={tr({
                id: 'template.searchGlobalAnnouncements',
              })}
              showSearchAll
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default StreamTemplates
