export default {
  generic: {
    authors: 'Authors',
    streams: 'Streams',
    select: 'Please select...',
    close: 'Close',
    resolve: 'Resolve',
    cancel: 'Cancel',
    invite: 'Invite',
    sure: 'Are you sure?',
    reset: 'Reset',
    resetBuff: 'Reset Buff',
    search: 'Search',
    all: 'All',
    filterBy: 'Filter by',
    selected: 'Selected',
    loading: 'Loading...',
    SBLogo: 'Sport Buff logo',
    new: 'New',
    yes: 'Yes',
    no: 'No',
    add: 'Add',
    save: 'Save',
    edit: 'Edit',
    expired: 'Expired',
    replace: 'Replace',
    refresh: 'Refresh',
    sendingBuffs: 'Sending Buffs',
    sendBuff: 'Send Buff',
    sendBuffs: 'Send Buffs',
    prepareBuffs: 'Prepare Buffs',
    sendAnnouncement: 'Send Announcement',
    sendAnnouncements: 'Send Announcements',
    buffPreview: 'Buff Preview',
    preview: 'Preview',
    unresolved: 'Unresolved',
    delete: 'Delete',
    manage: 'Manage',
    attach: 'Attach',
    upload: 'Upload',
    update: 'Update',
    create: 'Create',
    days: 'Days',
    seconds: 'Seconds',
    minutes: 'Minutes',
    hours: 'Hours',
    somethingWentWrong: 'Something went wrong',
    email: 'Email',
    emailAddress: 'Email address',
    password: 'Password',
    recommendedSize: 'Recommended size:',
    rectangle: '76px x 100px (rectangle)',
    square: '100px x 100px (square)',
    pinned: 'Pinned',
    set: 'Set',
    enterContent: 'Enter content...',
    selectOne: 'Select one',
    selectAll: 'Select all',
    submit: 'Submit',
    getStarted: 'Get started with these steps: ',
    alreadyPro: 'I’m a pro already',
    hideTips: 'Hide These Tips',
    overview: 'Overview',
    vod: 'VOD',
    goBack: 'Go Back',
    goToIcon: 'Go to icon',
    copy: 'Copy',
    copyID: 'Copy ID',
    openWebPreview: 'Open web preview',
    icon: 'Icon',
    clear: 'Clear',
    tags: 'Tags',
    bgColor: 'Background Color',
    fgColor: 'Foreground Color',
    enabled: 'Enabled',
    disabled: 'Disabled',
    optional: 'Optional',
    id: 'ID',
    continue: 'Continue',
    min: 'Min',
    max: 'Max',
    firstName: 'Name',
    lastName: 'Surname',
    role: 'Role',
    minImageSize: 'Minimum Recommended Size:',
    ratio: 'Aspect ratio {x}:{y}',
    resendInvite: 'Resend invite',
    you: 'You',
    releaseVersion: 'Release {value}',
    settings: 'Settings',
    totalReactions: 'Total reactions:',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    showAll: 'Show all',
    applyChanges: 'Apply changes',
    landscape: 'Landscape',
    portrait: 'Portrait',
    web: 'Web',
    metrics: 'Metrics',
    emptyMetrics: 'No metrics to show',
    startDate: 'Start Date',
    endDate: 'End Date',
  },
  user: {
    roles: {
      'account-owner': 'Account Owner',
      'admin-user': 'Admin',
      'content-user': 'Content User',
    },
  },
  signIn: {
    title: 'Content Dashboard',
    emailPlaceholder: 'your@email.com',
    passwordPlaceholder: 'Enter password',
    login: 'Login',
    questionPlaceholder: 'Question',
    submit: 'Login',
    continueWithEmail: 'Continue with email',
    continueWithGoogle: 'Continue with Google',
    continueWithFacebook: 'Continue with Facebook',
    sendingPasscode: 'Sending your passcode...',
    logInOrSign: 'Log in or sign up',
    enterPasscode: 'Enter your passcode',
    sentOTP: 'We’ve sent your one-time passcode to {value}',
    checkingPasscode: 'Checking the passcode...',
    emailSent: 'Email sent',
    resendPasscode: 'Resend passcode',
  },
  signOut: {
    icon: 'Sign out icon',
    logout: 'Log out',
  },
  home: {
    icon: 'Home icon',
  },
  category: {
    prediction: 'Prediction',
    poll: 'Poll',
    quiz: 'Quiz',
    emojiSlider: 'Slider',
    starRating: 'Star rating',
    announcement: 'Announcement',
    vote: 'Vote',
  },
  manager: {
    lastName: 'Last Name',
    firstName: 'First Name',
  },
  // priority: {
  //     critical: 'Critical',
  //     high: 'High',
  //     normal: 'Normal',
  //     low: 'Low',
  // },
  // delay: {
  //     noDelay: 'No Delay',
  //     sec5: '5 sec',
  //     sec10: '10 sec',
  //     sec15: '15 sec',
  //     sec30: '30 src',
  //     christmas: 'At Christmas',
  // },
  author: {
    authorImage: 'Author Image',
    authorName: 'Author name',
    authorNameText: 'Author name text...',
    newAuthor: 'Create New Author',
    displayName: 'Display name*',
    image: 'Author image',
    editAuthor: 'Edit Author',
    updated: 'Author updated successfully!',
    created: 'Author created successfully!',
    deleted: 'Author deleted successfully!',
    authorUpdate: 'Update Author',
    authorCreate: 'Add Author',
    authorDelete: 'Delete Author',
    details: 'Author Details:',
    authorUpdateDesc: 'Update author details here',
    authorCreateDesc:
      'Add a new author here to start sending Buffs from this account',
    icon: 'Authors icon',
    manage: 'Authors',
    imageRequired: 'Author image required',
    confirmDelete:
      'Deleting this author will delete it for your entire team and cannot be undone. Do you wish to continue?',
    emptyStateTitle: 'Get started by adding a new author',
  },
  stream: {
    noStreams: 'No streams found',
    searchList: 'Search by Stream Name or ID',
    createVOD: 'Create VOD',
    hasEnded: 'This stream has ended, but you can still send announcements.',
    endThisStream: 'End this stream?',
    endThisStreamAlert:
      'There are unresolved Buffs in the Buff history. Please review before ending the stream.',
    endThisStreamBtnConfirmation: 'End Stream',
    endThisStreamSubtext: 'If you stop the stream:',
    endThisStreamBulletPoint1: 'The leaderboard will be locked/finalised',
    endThisStreamBulletPoint2: 'You will not be able to send Buffs with points',
    endThisStreamBulletPoint3: 'Only announcements can be sent',
    endStreamActiveLeaderboard:
      'Leaderboard winners have not been sent for one or more leaderboards in this stream. Winner announcements cannot be sent after a stream has ended.',
    showWinnersAnnouncement: 'Show stream winners announcement',
    stopStream: 'Stop stream',
    startStream: 'Start stream',
    startNow: 'Start Now',
    stream: 'Stream',
    addStream: 'Add Stream',
    updateStream: 'Update Stream',
    deleteStream: 'Delete Stream',
    updated: 'Stream updated successfully!',
    created: 'Stream created successfully!',
    deleted: 'Stream deleted successfully!',
    started: 'Stream started successfully!',
    stopped: 'Stream stopped successfully!',
    hasNowEnded: 'Stream has now ended',
    newStreamAdd: 'Create New Stream',
    newStream: 'New Stream',
    newStreamDesc:
      'Create a new stream integration for your viewers to join, play and win!',
    updateStreamDesc: 'Send Buffs and manage historic streams',
    streamTitle: 'Stream Name',
    providerId: 'External ID',
    providerIdDesc: 'The unique ID you have on your own system.',
    usedProviderIdMessage:
      'This provider ID is already being used on another stream. You must first remove it from stream {value} before continuing.',
    usedTwitchChannelIdMessage:
      'The Twitch Channel ID entered cannot be saved as it is already in use on another stream.',
    sport: 'Sport',
    expiry: 'Expiry',
    bonusTimePoints: 'Max Bonus Time Points',
    bonusTimePointsDesc:
      'Answering Buffs before the timer runs out will earn Bonus Time Points.<br/>The quicker a user answers, the more points they will earn.',
    twitchChannelId: 'Twitch channel id',
    title: 'Title',
    startDate: 'Start Date',
    image: 'Stream Image',
    editStream: 'Edit Stream {value}',
    supportedLanguages: 'Supported Languages',
    details: 'Stream Details:',
    detailsTab: 'Stream Details',
    leaderboard: 'Stream Leaderboard',
    icon: 'Streams icon',
    manage: 'Streams',
    languageRequired: 'Please select at least one language for this stream',
    confirmDelete:
      'Deleting this stream will delete it for your entire team and cannot be undone. Do you wish to continue?',
    emptyStateTitle: 'Get started by adding a new stream',
    deleteStreamConfirmationText:
      'This will delete all of the content currently entered. <br/><br/> Do you wish to continue? This cannot be reversed.',
    deleteStreamConfirmationTitle: 'Are you sure?',
    mustStartStream: 'You must start the stream before you can send Buffs',
    saveTemplateBeforeStreamStarts:
      'You can only save templates before the stream starts',
    export: {
      title: 'Export Leaderboard',
      descriptionText1: 'Downloads as CSV file format.',
      descriptionText2: 'How many rows do you want?',
      download: 'Download CSV',
    },
    appearance: 'Appearance',
    advancedSettings: 'Advanced Settings',
    management: {
      title: 'Settings',
      description:
        'These are settings that will apply to every stream you have',
      welcomeBuff: 'Welcome Buff',
      streamDefaults: 'Stream Defaults',
      globalTemplates: 'Global Templates',
      editWelcomeBuff: 'Edit Welcome Buff',
      saveWelcomeBuff: 'Save Welcome Buff',
      addWelcomeBuff: 'Add Welcome Buff',
      welcomeBuffLoadingText:
        'There are currently no Welcome Buffs set. Please add one now:',
    },
    metrics: 'Metrics',
    metricsTotalActivity: 'Unique viewers',
    metricsPeak: 'Peak concurrency',
    userGroups: 'User groups',
    platform: 'Platform',
  },
  liveReactions: {
    reactions: 'Reactions',
    reactionSettings: 'Reaction settings',
    animationDirection: 'Animation direction',
    showReactions: 'Show reactions',
    selectReactions: 'Select reactions',
    maxSelectReactionsTooltip: 'You can add up to 30 reactions.',
    updatedReactions: 'Reactions updated.',
    metricDisclaimer: 'This chart displays data using rounded percentages.',
    removeReaction: 'Remove reaction',
    removeReactionDesc:
      'This will remove the reaction from the reaction panel if a stream is live.',
  },
  leaderboard: {
    leaderboard: 'Leaderboard',
    view: 'View leaderboard',
    monetisation: 'Monetisation',
    monetisationOnDesc:
      'When on, payment items will be active and available for purchase.',
    currency: 'Currency',
    monetisationOptions: {
      TWITCH_BITS: 'Twitch Bits',
      STRIPE_USD: 'USD - US Dollar',
    },
    selectCurrency: 'Select a currency',
    leaderboardPrice: 'Leaderboard price',
    leaderboardPaymentItems: 'Leaderboard payment items',
    leaderboardAwardSubtitle:
      'For a leaderboard to award points, it is necessary to choose at least one stream.',
    addPaymentItem: 'Add payment item',
    noPaymentItems: 'No payment items yet',
    vipLeaderboard: 'VIP Leaderboard',
    edit: 'Edit Leaderboard',
    createVipLeaderboard: 'Create new VIP leaderboard',
    createVipLeaderboardDescription:
      'This leaderboard should only be used on a Twitch stream.',
    vipPrice: 'VIP leaderboard price',
    newJoinerPoints: 'New joiner points',
    newJoinerPointsDescription:
      'This is a one-time point award for new users who join the leaderboard.',
    expiryDate: 'Expiry date',
    expiryTime: 'Expiry time',
    expiryDateDescription:
      'Payment items will expire at the date and time set. Once expired, a payment item cannot be used to join the leaderboard.',
    editExpiryDate: 'Edit expiry date',
    updateExpiryDate: 'Update expiry date',
    expiryInfo:
      'When a payment item expires, the payment method will be disabled in the leaderboard.',
    expirePaymentItems: 'Expire payment items',
    expirePaymentItemsDesc:
      'Expiring all payment items will prevent any new users from joining the leaderboard. Users who have already purchased this leaderboard will remain.',
    paymentItemUpdated: 'Payment item updated.',
    leaderboards: 'Leaderboards',
    autogenerated: 'Auto-generated',
    custom: 'Custom',
    resetLeaderboard: 'Reset Leaderboard',
    downloadLeaderboard: 'Export CSV',
    createdLeaderboard: 'Leaderboard created.',
    updatedLeaderboard: 'Leaderboard updated.',
    deletedLeaderboard: 'Leaderboard deleted.',
    reorderedLeaderboard: 'Leaderboard’s order updated',
    deletedPaymentItem: 'Payment item deleted.',
    addedPaymentItem: 'Payment item created.',
    deletePaymentItem: 'Delete payment item',
    paymentItemsExpired: 'Payment items are expired.',
    paymentWillExpire: 'This payment item will expire on {value} UTC',
    deletePaymentItemDescription:
      'This payment item will be removed from the list. Users who have already purchased will still be granted access to the leaderboard.',
    notAvailable: 'No leaderboard available',
    rank: 'Rank',
    name: 'Name',
    email: 'Email',
    opaqueId: 'Opaque ID',
    points: 'Points',
    percentageText: 'Top {percentage}%',
    resetLeaderboardCopy:
      '<b>This action is irreversible</b>. All players will start from zero points again. This will also be reflected in the Global (all time) leaderboard. <br/><br/> Are you sure you want to reset?',
    confirmReset: 'Reset',
    noData: 'New data will be displayed once you have sent your next buffs.',
    leaderboardUpdated: 'Leaderboard updated',
    monetised: 'Monetised',
    noStreamAttached: 'No stream attached',
    sponsor: {
      sponsoredImage: 'Sponsored Image',
      addSponsorship: 'Add Sponsorship to Leaderboard',
      text: 'Customise label',
      textPlaceholder: 'Example',
      linkTarget: 'OnClick URL',
      sponsorImage: 'Sponsor Image',
      sponsorImageAlt: 'Sponsor Leaderboard Image',
      sizeRecommendation: 'Size recommendation',
      redirectLink: 'Sponsor redirect link',
    },
    new: 'New leaderboard',
    createNew: 'Create New Leaderboard',
    streamsList: 'Streams Leaderboard',
    addStream: 'Add stream',
    next: 'Next',
    noStreamsTitle: 'No streams attached',
    noStreamsText: 'Please attach at least one stream to create a leaderboard',
    leaderboardSettings: 'Leaderboard Settings',
    leaderboardName: 'Leaderboard Name',
    leaderboardTitle: 'Leaderboard Title',
    leaderboardNameSubtitle: 'For you and your team to view',
    leaderboardTitleSubtitle: 'This will be visible to your fans',
    deleteLeaderboard: 'Delete Leaderboard',
    exportTopN: 'This will export top {value} results',
    visibleToAll: 'This will be visible to users',
    visibleToDashboard: 'This will be visible only in the dashboard',
    enterName: 'Enter name',
    enterTitle: 'Enter title',
    winners: {
      confirm: 'Announce winners',
      reannounce: 'Reannounce winners',
      title: 'Announce winners?',
      text: 'This will send a winners announcement to show the top 3 placed players on the leaderboard. Once finalised, the leaderboard is locked and will stop earning points. This cannot be undone.',
      reannounceTitle: 'Reannounce winners?',
      reannounceText:
        'This will resend the winners announcement for this leaderboard. ',
      leaderboardAnnounced: 'Leaderboard winners announced.',
      winnersAnnounced: 'Winners announced',
    },
  },
  template: {
    templates: 'Templates',
    confirmDelete:
      'Deleting this template will delete it for your entire team and cannot be undone. Do you wish to continue?',
    confirmDeleteButton: 'Delete template',
    templateSaved: 'Template saved',
    noTemplates: 'No template found',
    name: 'Template name',
    edit: 'Edit template',
    load: 'Load template',
    save: 'Save template',
    saveAs: 'Save as template',
    delete: 'Delete template',
    editTemplateAlertText:
      'You are editing {templateName}. All edits will be updated on the stream template list.',
    editGlobalTemplateAlertText:
      'You are editing {templateName}. All edits will be updated on the global template list.',
    newTemplate: 'New Template',
    buffTemplate: 'Buff Template',
    announcementTemplate: 'Announcement Template',
    buffTemplates: 'Buff Templates',
    announcementTemplates: 'Announcement Templates',
    createGlobalTemplate: 'Create Global Template',
    searchBuffs: 'Search Buffs',
    searchGlobalBuffs: 'Search Global Buffs',
    searchAnnouncements: 'Search Announcements',
    searchGlobalAnnouncements: 'Search Global Announcements',
    searchAllBuffs: 'Search all Buffs',
    searchAllAnnouncements: 'Search all Announcements',
    saveTemplateConfirmation: 'Template saved',
  },
  announcements: {
    announcement: 'Announcement',
    announcementSent: 'Announcement sent',
    title: 'Announcement Title',
    text: 'Announcement Text',
    titlePlaceholder: 'Title',
    textPlaceholder: 'Text',
    buttonText: 'Button Text',
    linkUrl: 'Url',
  },
  vod: {
    newVod: 'New VOD',
    newVodAdd: 'Create VOD',
    editVod: 'Edit VOD',
    publishVod: 'Publish VOD',
    editYourBuffs: 'Edit your VOD buffs here',
    createAnnouncement: 'Create Announcement',
    createBuff: 'Create Buff',
    updateAnnouncement: 'Update Announcement',
    updateBuff: 'Update Buff',
    createBuffs: 'Create Buffs',
    importBuff: 'Import Buff',
    importBuffs: 'Import Buffs',
    importVideo: 'Drag and drop or click to upload video',
    videoPreview: 'Video preview',
    maxVideoSize: 'MP4, MOV or AVI, Maximum upload file size: 4GB',
    previewDescription:
      'A Buff can be previewed with or without video content. You can upload a video to help to match Buffs to the action or add a VOD duration when no video is available.',
    noBuffsAdded: 'No Buffs added',
    noBuffsAddedDescription:
      'Click on the “Create Buff” or “Import Buff” button',
    vodDuration: 'VOD duration',
    detailsTab: 'VOD Details',
    appearanceTab: 'Appearance',
    advancedSettingsTab: 'Advanced Settings',
    leaderboardTab: 'Leaderboard',
    leaderboardSettingsTab: 'Leaderboard Settings',
    emptyStateTitle: 'Get started by adding a new VOD',
    offset: "Time's Offset",
    openedAt: 'Opens in second',
    closedAt: 'Closes in second',
    resolvedAt: 'Resolves in second',
    adjustTimings: 'Adjust Timings',
    linkTimings: 'Link timings',
    timingError: 'Timing Update failed',
    vodName: 'VOD Name',
    viewBuffs: 'View Buffs',
    createVOD: 'Create VOD',
    updateVOD: 'Update VOD',
    deleteVOD: 'Delete VOD',
    deleteStreamConfirmationTitle: 'Are you sure?',
    deleteVODContentTitle: 'Delete Buff from VOD?',
    deleteVODContentSubtitle:
      'This Buff will be deleted immediately. You can’t undo this action.',
    deleteVODContentConfirm: 'Delete Buff',
    confirmDelete:
      'Deleting this VOD will delete it for your entire team and cannot be undone. Do you wish to continue?',
  },
  qaForm: {
    name: 'Name',
    authorName: 'Author Name',
    authorTemplate: 'Author template',
    author: 'Author',
    scheduleSend: 'Schedule Send',
    expiresIn: 'Expires In',
    language: 'Language',
    answers: 'Answers',
    addAnswer: 'Add Buff answer',
    startDate: 'Start Date',
    startsAt: 'Starts At',
    startAgain: 'Start Again',
    question: 'Title',
    questionLabel: 'Buff Question',
    type: 'Buff type',
    view: 'Buff view',
    duration: 'Duration',
    votingDuration: 'Voting duration',
    resultDuration: 'Result duration',
    votingDurationDesc:
      'This sets how long a Buff is shown on screen, while the voting is open.',
    resultDurationDesc:
      'This sets how long a Buff result is shown on screen, once the voting has closed.',
    bgColor: 'BG Color',
    fgColor: 'FG Color',
    customSettings: 'Custom settings',
    arrowUp: 'arrow up',
    arrowDown: 'arrow down',
    adjustTimings: 'Adjust Timings',
    updateTimings: 'Update Timings',
    newPublishTime: 'New publish time',
    newExpiryTime: 'New expiry time',
    newResolvedTime: 'New resolved time',
    publishTime: 'Publish time',
    expiryTime: 'Expiry time',
    resolveTime: 'Resolve time',
    publishTimeDesc:
      'This is the time the published state of a Buff will show on screen',
    announcementPublishTimeDesc:
      'This is the time the Announcement will show on screen',
    expiryTimeDesc:
      'This is the time the closed state of a Buff will show on screen',
    resolveTimeDesc:
      'This is the time the resolved state of a Buff will show on screen',
    addAllLanguages: 'add all',
    monetize: {
      twitchMonetize: 'Monetize with Twitch',
      twitchMonetizeTooltip: 'This feature is only enabled for Twitch streams',
    },
    sponsor: {
      title: 'Add sponsored content',
      sponsoredImage: 'Sponsored Image',
      buffSponsor: 'Sponsor Buff',
      buffSponsorship: 'Add Sponsorship to this Buff',
      buffImageAlt: 'Sponsor buff image',
      onClickLabel: 'OnClick URL',
      text: 'Sponsor',
      imageAltText: 'Sponsor image',
    },
  },
  buffHistory: {
    buffHistory: 'Buff History',
    buffHistoryReplay: 'Select Buffs for VOD replay',
    idCopied: 'ID copied!',
  },
  assetManager: {
    assetItem: 'Asset item',
    assetIcon: 'Asset icon',
    noAssets: 'No assets available',
    noAssetsAvatarTitle: 'No avatar available',
    noAssetsSoundTitle: 'No sound available',
    noAssetsAvatarSubtitle:
      'Please click the “Upload” button to add new avatars',
    noAssetsSoundSubtitle: 'Please click the “Upload” button to add new sounds',
    uploadNew: 'Please click the “Upload” button to add new assets',
    noSearchAssets: 'No asset found',
    recommendedSize: 'Recommended image size:',
    clickUpload: 'Click to upload',
    drag: 'Drag and drop or click to upload',
    uploading: 'Uploading images...',
    images: 'images',
    items: 'items',
    description: 'Description',
    imageName: 'Image name',
    soundName: 'Sound name',
    failed: 'Failed',
    completed: 'Completed',
    uploadFailed: 'Upload failed',
    toUpload: 'to upload',
    maxUploadSize: 'JPG or PNG, Maximum upload file size: 4MB',
    maxUploadSizeSound: 'Supported file: MP3, Maximum upload file size: 4MB',
    uploadAssets: 'Upload assets',
    uploadedCount: '{filesUploadedCount} out {filesToUploadCount} uploaded',
    completedCount: '{filesCompletedCount} out {filesToUploadCount} complete',
    failedCount: '({filesFailedCount} failed)',
    singleMaxSize:
      'The file cannot be uploaded as it exceeds the maximum upload size.',
    deleteAsset: 'Delete asset?',
    confirmDelete:
      'Deleting this asset will delete it for your entire team and cannot be undone',
    assetAuthorModalListTitle: 'Choose author image',
    assetModeratorModalListTitle: 'Choose moderator image',
    assetReactionsModalListTitle: 'Choose reaction',
    assetAnnouncementModalListTitle: 'Choose announcement image',
    assetProfilePictureModalListTitle: 'Manage avatars',
    assetSoundsModalListTitle: 'Manage sounds',
    assetSponsorModalListTitle: 'Choose sponsor image',
    assetModalListTitle: 'Choose answer image',
    assetModalManageTitle: 'Manage assets',
    assetModalUploadTitle: 'Upload assets',
    deleteProfilePictureTitle: 'Delete avatar?',
    deleteProfilePictureSubtitle:
      'Deleting this avatar will delete it for your entire team and cannot be undone',
    deleteSoundTitle: 'Delete sound?',
    deleteSoundSubtitle:
      'Deleting this sound will delete it for your entire team and cannot be undone',
    deleteReactionTitle: 'Remove reaction?',
    deleteReactionSubtitle:
      'This will remove the reaction from the reaction panel if a stream is live.',
    maxAttachableReactionsErrorMessage: 'Maximum limit is 30 reactions.',
    cancelUploads: 'Cancel uploads',
    continueUploads: 'Continue uploads',
    cancelRemainingUploadsModalTitle: 'Cancel remaining uploads?',
    cancelRemainingUploadsModalText:
      'Your uploads are not complete. Would you like to cancel all remaining uploads',
    playSound: 'Play sound',
    retryFailed: 'Retry all failed',
  },
  buff: {
    buff: 'Buff',
    details: 'Buff Details: ',
    overrideText: 'Override text',
    buffAnswer: 'Buff answer',
    buffSent: 'Buff sent',
    twitchBits: 'Bits',
    answerInput: 'Answer input text ...',
    answerImg: 'Answer image',
    points: 'Points',
    correct: 'Correct',
    correctImg: 'Correct image',
    randomise: 'Randomise',
    resendResult: 'Resend results',
    resendSuccess: 'Results resent',
    type: {
      null: 'N/A',
      1: 'Prediction',
      2: 'Quiz',
      3: 'Poll',
      4: 'Slider',
      5: 'Announcement',
      6: 'Star',
      7: 'Welcome Buff',
      8: 'Vote',
    },
    status: {
      published: 'Published',
      expired: 'Expired',
      resolved: 'Resolved',
    },
    unresolved: 'Unresolved Buffs',
    resolve: 'Resolve Buff',
    predictionQuestion: 'Which prediction is right?',
    assetModalTitle: 'Choose answer image',
  },
  replay: {
    editBuffs: 'Edit Buffs',
  },
  advancedSettings: {
    showUi: 'Sport Buff UI',
    showUiDesc: 'Shows all Buff UI elements',
    visibility: 'Visibility of element',
    autoHideMenu: 'Automatically hide menu',
    hideFanPictures: 'Hide fan profile pictures',
    allowEditProfile: 'Allow users to edit their profile',
    buffDuration: 'Duration of resolved Buffs displaying',
    sounds: 'Sounds',
    playSounds: 'Play sounds when Buffs appear',
    selectSound: 'Select sound',
    timeSync: 'Time synchronisation',
    buffSync: 'Buff synchronisation',
    buffSyncWarning:
      'Warning: Your Application(s) need to send the video playback timestamps to our SDK for the Buff Synchronization option to work.',
    appDelay: 'Second Screen App Delay for Linear Broadcast',
    buffConfig: 'Buff config',
    liveVoting: 'Live voting',
    liveVotingDesc: 'Shows live stats of user voting on a Buff.',
    gamePoints: 'Game points',
    gamePointsDesc:
      'Hides points on Buffs and disables Leaderboard and Friends tabs.',
  },
  appearance: {
    positioning: 'Positioning:',
    draggableLeaderboard: 'Draggable leaderboard',
    draggableLeaderboardDesc:
      'Allows users to reposition the leaderboard by dragging and dropping',
    leaderboardPosition: 'Leaderboard Position',
    buffPosition: 'Buff Position',
    verticalOffset: 'Vertical Offset',
    horizontalOffset: 'Horizontal Offset',
    onboarding: 'Onboarding',
    welcomeBuff: 'Welcome Buff',
    welcomeBuffNone: 'None Selected',
    manageWelcomeBuff: 'Manage your Welcome Buffs',
    minimumHorizontalPercentage: 'Minimum 0%-60%',
    themeSelection: 'Theme Selection',
    activeTheme: 'Active Theme',
  },
  welcomeBuff: {
    saveWelcomeBuff: 'Save Welcome Buff',
    deleteWelcomeBuff: 'Delete Welcome Buff',
    confirmationTitle: 'Save this template',
    returnToList: 'Return to list',
    welcomeBuffPoints: 'Welcome Buff Points',
    editWelcomeBuffAlertText:
      'You are editing {welcomeBuffName}, all changes to this Welcome Buff will be visible for your users.',
    confirmation: 'Welcome buff saved',
  },
  notFound: {
    notFound: 'Not Found',
    notFoundMessage: 'Cannot find the page your are looking for',
  },

  errors: {
    // MANAGER
    1: 'Manager not found',
    // CLIENT
    1000: 'Client not found',
    // STREAMS
    2000: 'Stream not found',
    2001: 'Please check your Stream start and end date.',
    // BUFFS
    3000: 'Buff not found',
    // ASSETS
    4000: 'Asset not found',
    // Generic
    5000: 'Error while decoding the data',
    5001: 'Error while validating credentials',
    5002: 'Error while getting data from context',
    5003: 'Not authorized',
    5004: 'Forbidden',
    5005: 'Not found',
    5006: 'Could not create resource',
    5007: 'Could not update resource',
    5008: 'Bad payload',
    5009: 'Something went wrong',

    unauthorized: 'Unauthorized',
    unauthorizedMessage: 'Your login details are incorrect',
    wrongCredentials: 'Wrong credentials!',
    invalidPasscode: 'Your passcode is invalid or has expired.',
  },
  validation: {
    firstName: 'First name is required',
    lastName: 'Last name is required',
    displayName: 'A display name is required',
    profilePicture: 'A profile picture is required',
    email: 'Enter valid email address',
    streamRegex: 'Stream name only allows letters and numbers',
    streamNameReq: 'Stream name is required',
    streamNameMin: 'Stream name must be at least 5 chars long',
    providerIdMin: 'Provider id must be at least 3 chars long',
    twitchChannelIdMin: 'Twitch channel id must be at least 3 chars long',
    sport: 'Sport is required',
    maxParticipationPoints: 'Max bonus time points cannot be negative',
    maxParticipationPointsEqualOrMore:
      'Max participation points must be equal or bigger than Min participation points',
    minDays: 'Days cannot be smaller than 0',
    minHours: 'Hours cannot be smaller than 0',
    maxHours: 'Hours cannot be greater than 23',
    minMinutes: 'Minutes cannot be smaller than 0',
    maxMinutes: 'Minutes cannot be greater than 59',
    author: 'Author is required',
    buffType: 'Buff type is required',
    duration: 'Duration is required',
    expire: 'Expire is required',
    required: "Please don't leave this blank",
    validUrl: 'Please enter a valid URL.',
    min1: 'Min points: 1',
    min1Bits: 'Minimum amount is 1 Bits.',
    mustBeNumber: 'Must be a number',
    bits: 'Enter a number between 25-10,000',
    minDuration: 'Minimum duration is 6 seconds',
    maxDuration: 'Maximum duration is 300 seconds',
    announcementTitleMax: 'Title must be at most 50 chars long',
    vodNameReq: 'VOD name is required',
    vodNameMinReq: 'VOD name must be at least 5 chars long',
    vodLanguagesReq: 'Please select at least one language',
    specialCharsNotAllowed: 'Special characters are not allowed',
    leaderboardDateTime: 'Date or time cannot be in the past.',
    startDateAfterEndDate: 'Start date cannot be after end date',
    answers: {
      min: 'This Buff should have at least 2 answers',
      max: "This Buff can't have more than 5 answers",
      correct:
        'Please make sure you have selected at least one correct answer. All correct answers must have points',
    },
    minTemplateName: 'Must have a minimum of 3 characters.',
    newPublishHHMMSSFormat:
      'Please enter new publish time in the format HHH:MM:SS',
    expiry: 'New expiry time must be equal or less than new resolved time',
    expiryHHMMSSFormat: 'Please enter expiry in the format HHH:MM:SS',
    expiryDurationGap:
      'There must be a 20 second gap between expiry and duration',
    expiryDurationGapTimeSync:
      'There must be a 60 second gap between expiry and duration',
    notLessThanZero: "Publish time can't be less than 0",
    publishExpiryGap: "Expiry time can't be less than publish time",
    resolvedExpiryGap: "Resolved time can't be less than expiry time",
    announcementButtonLink: 'This URL is not valid',
    leaderboardHorizontalOffset: {
      min: 'Leaderboard horizontal offset cannot be smaller than 0',
      max: 'Leaderboard horizontal offset cannot be bigger than 60',
    },
    leaderboardVerticalOffset: {
      min: 'Leaderboard vertical offset cannot be smaller than 0',
      max: 'Leaderboard vertical offset cannot be bigger than 60',
    },
    voteableHorizontalOffset: {
      min: 'Voteable horizontal offset cannot be smaller than 0',
      max: 'Voteable horizontal offset cannot be bigger than 60',
    },
    voteableVerticalOffset: {
      min: 'Voteable vertical offset cannot be smaller than 0',
      max: 'Voteable vertical offset cannot be bigger than 60',
    },
    chatHorizontalOffset: {
      min: 'Chat horizontal offset cannot be smaller than 0',
      max: 'Chat horizontal offset cannot be bigger than 60',
    },
    chatVerticalOffset: {
      min: 'Chat vertical offset cannot be smaller than 0',
      max: 'Chat vertical offset cannot be bigger than 60',
    },
  },
  toast: {
    close: 'Close toast',
    logo: 'Toast logo',
    signOut: 'Sign out icon',
  },
  gettingStarted: {
    authorTitle: 'Create an author',
    authorSubtitle:
      'Start by creating an author to send Buffs to your audience',
    streamTitle: 'Create your first stream',
    streamSubtitle:
      'Get your stream tokens and enter your details. Hitting start activates the stream',
    buffTitle: 'Create your first Buff',
    buffSubtitle:
      'Use a template or add an author, a Buff type to create your questions & answers',
  },
  globalLeaderboard: {
    title: 'Global Leaderboard',
    tabTitle:
      'This leaderboard combines results from all of the streams you have played',
  },
  streamLeaderboards: {
    manageLeaderboard: 'Manage Leaderboard',
    createLeaderboard: 'Create a new leaderboard',
  },
  sponsor: {
    linkTargetError: 'This URL is not valid',
  },
  teamMembers: {
    teamMembers: 'Team members',
    members: 'Members',
    pendingInvites: 'Pending invites',
    teamMembersDescription:
      'View your team members and account permissions here',
    inviteTeamMember: 'Invite team member',
    manageTeam: 'Manage team',
    manageTeamDescription:
      'Manage your team members and account permissions here',
    editMember: 'Edit member',
    memberUpdated: 'Member updated',
    inviteNewMember: 'Invite a new team member',
    inviteMemberDescription:
      'Invite colleagues to collaborate on this dashboard.',
    table: {
      name: 'Name',
      email: 'Email',
      dateAdded: 'Date added',
      expiryDate: 'Expiry date',
      role: 'Role',
    },
    enterName: 'Enter name',
    enterSurname: 'Enter surname',
    selectRole: 'Select role',
    inviteSent: 'Invite sent',
    inviteResent: 'Invite resent',
    inviteRemoved: 'Invite removed',
    inviteExpired: 'Invite expired',
    noPendingInvites: 'No pending invites',
    leaveTeam: 'Leave team',
    leaveTeamDescription: 'You will no longer have access to the dashboard.',
    removeTeamMember: 'Remove team member',
    removeTeamMemberDescription:
      "'<strong>'{value}'</strong>' will no longer have access to the dashboard.",
  },
  globalChat: {
    chat: 'Chat',
    liveChat: 'Live chat',
    name: 'Chat name',
    settings: 'Settings',
    slowMode: 'Slow mode',
    ban: 'Ban',
    unban: 'Unban',
    bannedUsers: 'Banned users',
    bannedUsersDescription:
      'Chatters who have been banned can be reviewed here. You can choose to keep them banned or unban them.',
    chatPanelVisibility: 'Chat panel is visible when a user joins a stream',
    textModeration: 'Text moderation',
    textModerationDescription:
      'The following list of words will be hidden from view when written in the chat.',
    profanityDetection: 'When Profanity is detected',
    setMasking: 'Set a masking character',
    wordList: 'Word List',
    wordListPlaceholder: 'Add words',
    useDefaultWords: 'Use default words',
    chatSettings: 'Chat settings',
    moderatorProfile: 'Moderator profile',
    moderatorInfo: 'Moderator info',
    moderatorName: 'Moderator name',
    moderateUrls: "Don't allow URLs in messages",
    pinMessage: 'Pin message',
    pinned: 'Pinned',
    unpinMessage: 'Unpin message',
    banUser: 'Ban user',
    unbanUser: 'Unban user',
    deleteMessage: 'Delete message',
    chatInputPlaceholder: 'Enter a message',
    pinMessageConfirmation: 'Pin message?',
    pinMessageDescription:
      'Pinning a new message will replace the current one.',
    messageRemoved: 'Message removed by moderator',
    failedToRemoveMessage: 'Failed to delete the message',
    failedToStarMessage: 'Failed to star the message',
    failedToUnstarMessage: 'Failed to unstar the message',
    allMessages: 'All messages',
    starred: 'Starred',
    chatPanelPosition: 'Chat Panel Position',
    settingsUpdated: 'Chat settings updated successfully',
    wordListDesc:
      'All words and phrases should be separated with a new line character',
    profanityDesc:
      'You can choose to hide the individual word or the whole sentence, when profanity is detected',
    maskingDesc:
      'Set the character type to mask words with, when profanity is detected',
    totalUsers: 'Total users in chat',
    totalMessages: 'Total messages sent',
    averageMessages: 'Avg messages sent per user',
  },
  configuration: {
    title: 'Configuration',
    description: 'These are global level configuration settings',
    language: 'Language',
    tvSettings: 'TV Settings',
    avatars: 'Avatars',
    platforms: 'Platforms',
    sounds: 'Sounds',
    signup: 'Sign Up',
    sdk: 'SDK',
    qrCodeUrl: 'QR code URL',
    qrCodeUrlDescription:
      'Deep link URL to redirect users without the correct app installed.',
    qrCodeText: 'QR code text',
    text1Placeholder: '1st sentence',
    text2Placeholder: '2nd sentence',
    text3Placeholder: '3rd sentence',
    tvSettingsUpdated: 'TV settings updated',
    signupSettingsUpdated: 'Sign up settings updated',
    platformSettingsUpdated: 'Platform settings updated',
    localeSettingsUpdated: 'Language settings updated',
    sdkSettingsUpdated: 'SDK settings updated',
    defaultLanguage: 'Default language',
    supportedLanguages: 'Supported Languages',
    supportedPlatforms: 'Supported Platforms',
    leaderboard: 'Leaderboard',
    friends: 'Friends',
    profile: 'Profile',
    settings: 'Settings',
    chat: 'Chat',
    buff: 'Buff',
    signUp: {
      termsBanner: 'T&C and Privacy Policy',
      termsUrl: 'Terms and Conditions URL',
      policyUrl: 'Privacy Policy URL',
      signUpBanner: 'Update sign up messages',
      reactions: 'Reactions',
      buffTooltip: 'Buff Sign Up Message',
      leaderboardTooltip: 'Leaderboard Sign Up Message',
      friendsTooltip: 'Friends Sign Up Message',
      profileTooltip: 'Profile Sign Up Message',
      settingsTooltip: 'Settings Sign Up Message',
      reactionsTooltip: 'Reactions Sign Up Message',
      termsTooltip:
        'Please provide the URL of the Terms and Conditions page you wish to use',
      policyTooltip:
        'Please provide the URL of the Privacy Policy page you wish to use',
    },
    uiElements: {
      showUiElements: 'Show UI Elements',
      links: 'Links',
      openLinksExternally: 'Open links externally',
      leaderboardTooltip: 'Shows the Leaderboard tab.',
      friendsTooltip:
        'Shows the Friends tab. Disabling this will auto disable Chat.',
      profileTooltip: 'Shows the Profile tab.',
      chatTooltip: 'Enables Chat within the Friends tab.',
      linksTooltip:
        'Opens Sport Buff links (Announcement & Sponsored) inside the application.',
    },
  },
}
