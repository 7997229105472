import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Form, Formik, FormikProps } from 'formik'
import SelectInput from '@components/atoms/SelectInput'
import { MAX_REACTIONS_SELECTION, tr } from '@constants/other'
import Switch from '@components/atoms/Switch'
import {
  LiveReactionAsset,
  LiveReactionsFormValues,
  ReactionsPosition,
} from '@interfaces/Reactions'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'
import { ReactComponent as PlusIcon } from '@assets/icon-plus.svg'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import { ReactComponent as ReorderSVG } from '@assets/reorder.svg'
import { ReactComponent as TrashSVG } from '@assets/delete.svg'
import { ReactComponent as StatsIcon } from '@assets/stats.svg'
import { ReactComponent as PlaceholderSVG } from '@assets/placeholder.svg'
import { useLive } from '@services/requests/live'
import { IRouteParams } from '@interfaces/RouteParams'
import { useParams, Link } from 'react-router-dom'
import { queryClient } from '@utils/reactQuery/client'
import { useMutation } from 'react-query'
import { useToast } from '@utils/hooks/useToast'
import useAutoSubmitForm from '@utils/hooks/useAutoSubmitForm'
import Button, { ButtonVariant } from '@components/atoms/Button'
import AssetManager from '@components/organisms/AssetManager'
import EmptyStateWithIcon from '@components/molecules/EmptyStateWithIcon'
import EmojiStatsList from '@components/molecules/EmojiStatsList'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { Alignment } from '@components/molecules/ConfirmationModal/types'
import { AssetClass } from '@interfaces/Assets'
import Image from '@components/atoms/Image'
import TextInput from '@components/atoms/TextInput'
import { format } from 'date-fns'
import ReorderReactions from './ReorderReactions'
import schema from './schema'
import { LiveReactionsProps } from './types'

const enabledText = tr({ id: 'generic.enabled' })
const disabledText = tr({ id: 'generic.disabled' })

const directionOptions = [
  {
    key: ReactionsPosition.Vertical,
    value: tr({ id: 'generic.vertical' }),
  },
  {
    key: ReactionsPosition.Horizontal,
    value: tr({ id: 'generic.horizontal' }),
  },
]

const defaultInitialValues: LiveReactionsFormValues = {
  enabled: false,
  direction: ReactionsPosition.Vertical,
  availableReactions: [],
}

const LiveReactions = (_: LiveReactionsProps) => {
  const { id: streamId = '' } = useParams<IRouteParams>()
  const {
    useGetStreamReactions,
    useGetStreamReactionsList,
    updateStreamReactions,
  } = useLive()
  const { addToast, addErrorToast } = useToast()
  const formRef = useRef<FormikProps<LiveReactionsFormValues>>(null)
  const submit = useAutoSubmitForm<LiveReactionsFormValues>(formRef)
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [dateFilter, setDateFilter] = useState({
    startDate: '',
    endDate: '',
  })
  const [errors, setErrors] = useState<Record<string, string> | undefined>({})

  const { data: reactions } = useGetStreamReactions(streamId)
  const { data: metrics, isLoading } = useGetStreamReactionsList(
    streamId,
    dateFilter.startDate,
    dateFilter.endDate
  )
  const [reactionToDelete, setReactionToDelete] = useState<LiveReactionAsset>()

  const filteredMetrics = useMemo(() => {
    if (!metrics) return []
    return metrics.sort((a, b) => b.count - a.count).slice(0, 10)
  }, [metrics])

  const [isAssetManagerOpen, setIsAssetManagerOpen] = useState(false)

  const { mutate: mutateUpdateReactions } = useMutation(updateStreamReactions, {
    onSuccess: () => {
      addToast({
        msg: tr({ id: 'liveReactions.updatedReactions' }),
        type: 'success',
        image: <Tick className="mr-3 w-8 h-8" />,
      })
    },
    onError: (err) => {
      addErrorToast(err)
    },
    onSettled: () => {
      queryClient.invalidateQueries(['stream-reactions', streamId])
    },
  })

  const handleSubmit = async (values: LiveReactionsFormValues) => {
    mutateUpdateReactions({ streamId, request: values })
  }

  const handleDeleteReaction = async (reaction: LiveReactionAsset) => {
    setReactionToDelete(reaction)
  }

  const refreshMetrics = () => {
    queryClient.invalidateQueries([
      'stream-reactions-sent',
      streamId,
      startDate,
      endDate,
    ])
  }

  const handleResetDateFilters = () => {
    setStartDate('')
    setEndDate('')
  }

  const initialValues = useMemo(() => {
    if (!reactions) return defaultInitialValues
    return {
      ...reactions,
      availableReactions: reactions.availableReactions || [],
    }
  }, [reactions]) as LiveReactionsFormValues

  const maxStartDate = format(new Date(), 'yyyy-MM-dd')

  useEffect(() => {
    ;(async () => {
      try {
        await schema.validate({ startDate, endDate }, { abortEarly: false })
        setDateFilter({ startDate, endDate })
        setErrors({})
      } catch (err) {
        const errors: Record<string, string> = {}
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message
        })
        setErrors(errors)
      }
    })()
  }, [startDate, endDate])

  return (
    <div className="flex flex-col gap-y-4" data-testid="live-reactions">
      <Formik
        innerRef={formRef}
        enableReinitialize
        validateOnBlur
        validateOnChange
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue }) => {
          const handleReactionRemove = () => {
            const newReactions = values.availableReactions.filter(
              (r) => r.id !== reactionToDelete?.id
            )
            setFieldValue('availableReactions', newReactions)
            setReactionToDelete(undefined)
            submit()
          }

          return (
            <Form className="flex flex-col gap-y-4">
              <div
                className="p-4 rounded-lg bg-white-background"
                data-testid="live-reactions-form"
              >
                <fieldset>
                  <h1 className="m-0 text-xl font-bold">
                    {tr({ id: 'liveReactions.reactionSettings' })}
                  </h1>

                  <div className="flex flex-col gap-y-4 mt-3">
                    <div className="flex items-start">
                      <div className="flex mr-8 w-60">
                        <label
                          className="flex mb-0 mr-2 items-center"
                          htmlFor="showUi"
                        >
                          {tr({ id: 'liveReactions.showReactions' })}
                        </label>
                      </div>
                      <Switch
                        textClassname="text-sm uppercase"
                        textPosition="right"
                        name="enabled"
                        id="enabled"
                        text={values.enabled ? enabledText : disabledText}
                        isChecked={values.enabled}
                        onToggle={(e) => {
                          handleChange(e)
                          submit()
                        }}
                      />
                    </div>

                    <div className="clear-both">
                      <div className="flex items-center">
                        <SelectInput
                          label={
                            <span className="font-semibold">
                              {tr({ id: 'liveReactions.animationDirection' })}
                            </span>
                          }
                          value={values.direction}
                          id="direction"
                          name="direction"
                          data-testid="direction-select"
                          className="!m-0"
                          containerClasses="!m-0 w-60"
                          onChange={(e) => {
                            handleChange(e)
                            submit()
                          }}
                          options={directionOptions}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div
                className="p-4 rounded-lg bg-white-background"
                data-testid="live-reactions-selection"
              >
                <div className="flex items-center gap-x-2 mb-3">
                  <h1 className="m-0 text-xl font-bold">
                    {tr({ id: 'liveReactions.selectReactions' })}
                  </h1>
                  <Tooltip placement="right">
                    <TooltipTrigger>
                      <div className="flex items-center gap-x-2 cursor-pointer">
                        <InfoCircleIcon className="w-4 h-4" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <span>
                        {tr({
                          id: 'liveReactions.maxSelectReactionsTooltip',
                        })}
                      </span>
                    </TooltipContent>
                  </Tooltip>
                </div>

                <ReorderReactions
                  values={values}
                  onChange={(reactions) => {
                    setFieldValue('availableReactions', reactions)
                    submit()
                  }}
                />

                <div
                  id="reactions-list"
                  className="grid grid-cols-[repeat(auto-fill,68px)] gap-4"
                >
                  {!!values?.availableReactions?.length &&
                    values?.availableReactions?.map((reaction, index) => (
                      <div
                        key={reaction?.id || index.toString()}
                        className="w-[68px] h-[60px] bg-white rounded flex items-center justify-center relative group"
                      >
                        <div className="bg-black/50 absolute inset-0 hidden rounded group-hover:!flex" />
                        <div className="reaction-handle cursor-pointer absolute top-1 right-1 hidden group-hover:!flex">
                          <ReorderSVG className="w-5 h-7 text-white" />
                        </div>
                        <button
                          type="button"
                          onClick={() => handleDeleteReaction(reaction)}
                          className="hidden group-hover:flex items-center justify-center border-none absolute bottom-0 left-0 ml-1 mb-1 rounded-full w-6 h-6 bg-white"
                        >
                          <span className="flex items-center justify-center">
                            <TrashSVG className="w-4 h-4 text-danger" />
                          </span>
                        </button>

                        <Image
                          className="w-full h-full object-fit rounded"
                          src={reaction?.imageUrl}
                          fallbackComponent={
                            <PlaceholderSVG className="w-[60px] h-[46px]" />
                          }
                        />
                      </div>
                    ))}

                  {!!values?.availableReactions &&
                    values?.availableReactions?.length <
                      MAX_REACTIONS_SELECTION && (
                      <Tooltip placement="bottom">
                        <TooltipTrigger>
                          <button
                            onClick={() => setIsAssetManagerOpen(true)}
                            type="button"
                            className="w-[68px] h-[60px] bg-[#D8E1EC] outline-none border-none rounded flex items-center justify-center locked"
                          >
                            <PlusIcon className="w-6 h-6" />
                          </button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <div className="flex flex-col items-center w-full p-1 text-sm">
                            <span className="font-medium">
                              {tr({ id: 'generic.recommendedSize' })}
                            </span>
                            <span>
                              {tr({ id: 'generic.ratio' }, { x: 1, y: 1 })}
                            </span>
                            <span>128 x 128 px</span>
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    )}
                </div>
              </div>

              {isAssetManagerOpen && (
                <AssetManager
                  accept="image/*"
                  pageSize={100}
                  maxAttachableSize={30 - values.availableReactions.length}
                  title={tr({
                    id: 'assetManager.assetReactionsModalListTitle',
                  })}
                  deleteBtnTitle={tr({
                    id: 'assetManager.deleteReactionTitle',
                  })}
                  deleteBtnSubtitle={tr({
                    id: 'assetManager.deleteReactionSubtitle',
                  })}
                  type={AssetClass.Reactions}
                  isOpen={isAssetManagerOpen}
                  maxAttachableSizeErrorMessage={tr({
                    id: 'assetManager.maxAttachableReactionsErrorMessage',
                  })}
                  onClose={() => setIsAssetManagerOpen(false)}
                  onSelect={(images) => {
                    const attachedImages = images.map((image) => ({
                      imageUrl: image.publicURL + image.fileName,
                      id: image.id,
                    }))

                    // filter images that availableReactions already has
                    const filteredAttachedImages = attachedImages.filter(
                      (img) => {
                        return !values.availableReactions.find(
                          (reaction) => reaction.id === img.id
                        )
                      }
                    )

                    if (!filteredAttachedImages.length) return

                    const newReactions = [
                      ...values.availableReactions,
                      ...filteredAttachedImages,
                    ]
                    setFieldValue('availableReactions', newReactions)
                    submit()
                  }}
                />
              )}

              <ConfirmationModal
                onClose={() => setReactionToDelete(undefined)}
                onSuccess={handleReactionRemove}
                title={tr({ id: 'liveReactions.removeReaction' })}
                successBtnText={tr({
                  id: 'liveReactions.removeReaction',
                })}
                subtext={tr({ id: 'liveReactions.removeReactionDesc' })}
                show={!!reactionToDelete}
                data-testid="delete-reaction-modal"
                variant={ButtonVariant.Danger}
                alignment={Alignment.END}
              />
            </Form>
          )
        }}
      </Formik>

      <div
        className="p-4 rounded-lg bg-white-background"
        data-testid="live-reactions-form"
      >
        <div className="flex flex-col">
          <div className="flex items-center justify-between">
            <h1 className="m-0 text-xl font-bold">
              {tr({ id: 'stream.metrics' })}
            </h1>

            <div className="flex items-center gap-x-3">
              <Button
                type="button"
                variant="secondary"
                onClick={refreshMetrics}
              >
                {tr({ id: 'generic.refresh' })}
              </Button>
              {!!filteredMetrics.length && (
                <Link to={'metrics'}>{tr({ id: 'generic.showAll' })}</Link>
              )}
            </div>
          </div>

          <div className="flex flex-row gap-x-4 mt-4">
            <TextInput
              type="date"
              pattern="\d{4}-\d{2}-\d{2}"
              id="startDate"
              max={maxStartDate}
              label={<span>{tr({ id: 'generic.startDate' })}</span>}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value)
              }}
              error={!!errors?.startDate}
              errorLabel={errors?.startDate}
              touched={!!startDate}
              placeholder="DD/MM/YYYY"
              inputContainerClasses={`w-[200px] ${
                !startDate ? 'text-[#959DA5]' : 'text-black'
              }`}
            />

            <TextInput
              type="date"
              pattern="\d{4}-\d{2}-\d{2}"
              id="endDate"
              max={maxStartDate}
              label={<span>{tr({ id: 'generic.endDate' })}</span>}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
              placeholder="DD/MM/YYYY"
              inputContainerClasses={`w-[200px] ${
                !endDate ? 'text-[#959DA5]' : 'text-black'
              }`}
            />

            <Button
              className="mt-6"
              buttonType="link"
              size="medium"
              onClick={handleResetDateFilters}
            >
              {tr({ id: 'generic.clear' })}
            </Button>
          </div>

          {!isLoading && !filteredMetrics.length && (
            <EmptyStateWithIcon
              containerClasses="!h-[350px] mt-10"
              icon={<StatsIcon className="w-10 text-white" />}
              text={tr({ id: 'generic.emptyMetrics' })}
            />
          )}

          {!!filteredMetrics.length && (
            <div className="p-4 bg-white flex flex-col rounded mt-4 gap-y-2">
              <EmojiStatsList emojis={filteredMetrics} />
              <p className="m-0 text-[#586069] text-sm">
                *{tr({ id: 'liveReactions.metricDisclaimer' })}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LiveReactions
