import { BuffType, tr } from '@constants/other'
import { v4 } from 'uuid'
import {
  LocalisedFormValue,
  LocalisedSponsorFormValue,
  LocalisedAuthorFormValue,
} from '@interfaces/localisation'
import createDefaultAnswer from '@utils/createDefaultAnswer'
import {
  DEFAULT_EXPIRY_TIME,
  DEFAULT_EXPIRY_TIME_TIME_SYNC,
} from '@constants/form'
import { VoteableLayout, CreateVoteableForm } from '@interfaces/Voteable'

export const DEFAULT_DURATION = 15
export const DEFAULT_SCHEDULE_SEND = '000:00:00'
export const MAX_ITEMS = 5

export const buffTypesArray = [
  {
    key: BuffType.Prediction,
    value: tr({ id: 'category.prediction' }),
  },
  {
    key: BuffType.Quiz,
    value: tr({ id: 'category.quiz' }),
  },
  {
    key: BuffType.Poll,
    value: tr({ id: 'category.poll' }),
  },
  {
    key: BuffType.EmojiSlider,
    value: tr({ id: 'category.emojiSlider' }),
  },
  {
    key: BuffType.StarRating,
    value: tr({ id: 'category.starRating' }),
  },
  {
    key: BuffType.Vote,
    value: tr({ id: 'category.vote' }),
  },
]

export const welcomeBuffTypesArray = [
  {
    key: BuffType.Poll,
    value: tr({ id: 'category.poll' }),
  },
  {
    key: BuffType.EmojiSlider,
    value: tr({ id: 'category.emojiSlider' }),
  },
  {
    key: BuffType.StarRating,
    value: tr({ id: 'category.starRating' }),
  },
]

export const standardOnlyBuffLayoutOptions = [
  {
    key: VoteableLayout.LAYOUT_HORIZONTAL,
    value: 'Standard',
  },
]

export const buffLayoutOptions = [
  {
    key: VoteableLayout.LAYOUT_HORIZONTAL,
    value: 'Standard',
  },
  {
    key: VoteableLayout.LAYOUT_VERTICAL,
    value: 'Grid',
  },
]

export const TWITCH_BITS_ENABLED_BUFFS = [
  BuffType.Poll,
  BuffType.Prediction,
  BuffType.Quiz,
]

export const VERTICAL_LAYOUT_ENABLED_BUFFS = [
  BuffType.Poll,
  BuffType.Prediction,
  BuffType.Quiz,
  BuffType.Vote,
]

const idSeed = new Array(MAX_ITEMS).fill(null).map(() => {
  return v4()
})
