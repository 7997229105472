import {
  VoteableLifecycle,
  VoteableAnswerInterface,
  VoteableUi,
} from '@interfaces/Voteable'
import { BuffType } from '@constants/other'

export const getUiFromBuffType = (type: BuffType): VoteableUi => {
  switch (type.toString()) {
    case BuffType.Poll.toString():
      return {
        lifecycle: VoteableLifecycle.VOTEABLE_LIFECYCLE_POLL,
        answerInterface: VoteableAnswerInterface.ANSWER_INTERFACE_PICK_ONE,
      }
    case BuffType.Quiz.toString():
      return {
        lifecycle: VoteableLifecycle.VOTEABLE_LIFECYCLE_QUIZ,
        answerInterface: VoteableAnswerInterface.ANSWER_INTERFACE_PICK_ONE,
      }
    case BuffType.EmojiSlider.toString():
      return {
        lifecycle: VoteableLifecycle.VOTEABLE_LIFECYCLE_POLL,
        answerInterface:
          VoteableAnswerInterface.ANSWER_INTERFACE_DISCRETE_SLIDER,
      }
    case BuffType.StarRating.toString():
      return {
        lifecycle: VoteableLifecycle.VOTEABLE_LIFECYCLE_POLL,
        answerInterface: VoteableAnswerInterface.ANSWER_INTERFACE_STAR_SELECT,
      }
    case BuffType.Vote.toString():
      return {
        lifecycle: VoteableLifecycle.VOTEABLE_LIFECYCLE_POPULAR_VOTE,
        answerInterface: VoteableAnswerInterface.ANSWER_INTERFACE_PICK_ONE,
      }
    case BuffType.Prediction.toString():
    default:
      return {
        lifecycle: VoteableLifecycle.VOTEABLE_LIFECYCLE_PREDICTION,
        answerInterface: VoteableAnswerInterface.ANSWER_INTERFACE_PICK_ONE,
      }
  }
}
