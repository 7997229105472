import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import { tr } from '@constants/other'
import StreamLeaderboardsList from '@components/molecules/StreamLeaderboardsList'
import { useLive } from '@services/requests/live'
import Button from '@components/atoms/Button/Button'
import { Leaderboard } from '@interfaces/Leaderboard'
import { useToast } from '@utils/hooks/useToast'
import { queryClient } from '@utils/reactQuery/client'
import { StreamLeaderboardsProps } from './types'

const StreamLeaderboards: FC<
  React.PropsWithChildren<StreamLeaderboardsProps>
> = ({ streamId, gameId, activeLeaderboardId }) => {
  const navigate = useNavigate()
  const { useGetStreamLeaderboards, reorderLeaderboards } = useLive()
  const { data: leaderboards } = useGetStreamLeaderboards({ streamId })
  const { addToast, addErrorToast } = useToast()

  const { mutate: reorderedLeaderboardsMutate } = useMutation(
    reorderLeaderboards,
    {
      onSuccess: () => {
        addToast({
          msg: tr({ id: 'leaderboard.reorderedLeaderboard' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
        queryClient.invalidateQueries(['leaderboards', streamId])
      },
      onError: (err) => {
        addErrorToast(err)
      },
    }
  )

  const handleItemClick = (leaderboardId: string) => {
    navigate(`/streams/${streamId}/${gameId}/leaderboard/${leaderboardId}`)
  }

  const handleReorder = (reorderedLeaderboards: Leaderboard[]) => {
    const leaderboardIds = reorderedLeaderboards.map((x) => x.id)
    reorderedLeaderboardsMutate({ streamId, leaderboardIds })
  }

  return (
    <div>
      <div className="flex flex-col p-4 bg-white-background">
        <header className="flex items-center justify-between">
          <div className="m-0 text-[1.125rem] font-bold">
            {tr({ id: 'leaderboard.leaderboards' })}
          </div>
          <div className="flex items-center">
            <Button
              className="!text-black"
              href={`/streams/${streamId}/${gameId}/leaderboard/new`}
              variant="secondary"
              startElement={
                <img
                  className="w-4 h-4 mr-1 text-black"
                  src={'/assets/svg/icon-add-plus.svg'}
                />
              }
            >
              {tr({ id: 'leaderboard.new' })}
            </Button>
          </div>
        </header>
        <section>
          <StreamLeaderboardsList
            leaderboards={leaderboards}
            streamId={streamId}
            gameId={gameId}
            onItemClick={handleItemClick}
            onReorder={handleReorder}
          />
        </section>
      </div>
    </div>
  )
}

export default StreamLeaderboards
