import { StreamConfig, StreamConfigUpdate } from '@interfaces/IStreamConfig'
import { client } from '@services/requests/client'

type StreamConfigPayload = Partial<StreamConfigUpdate>

export interface StreamConfigParams {
  streamId?: string
  payload?: StreamConfigPayload
}

export const getStreamConfigDefault = async (): Promise<StreamConfig> => {
  const response = await client.get('/stream-config/stream-config-default', {
    headers: {
      Accept: 'version=2.0.0',
    },
  })
  return response.data
}

export const getStreamConfig = async (
  streamId: string
): Promise<StreamConfig> => {
  const response = await client.get(`/stream-config/${streamId}`, {
    headers: {
      Accept: 'version=2.0.0',
    },
  })
  return response.data
}

export const updateStreamConfigDefault = async ({
  payload,
}: StreamConfigParams): Promise<StreamConfig> => {
  const response = await client.patch(
    `/stream-config/stream-config-default`,
    payload,
    {
      headers: {
        Accept: 'version=2.0.0',
      },
    }
  )
  return response.data
}

export const updateStreamConfig = async ({
  streamId,
  payload,
}: StreamConfigParams): Promise<StreamConfig> => {
  const response = await client.patch(`/stream-config/${streamId}`, payload, {
    headers: {
      Accept: 'version=2.0.0',
    },
  })
  return response.data
}
