import React, {
  FC,
  SelectHTMLAttributes,
  CSSProperties,
  ReactNode,
} from 'react'

export interface IOptions {
  key: string | number
  value: string
  hidden?: boolean
  selected?: boolean
}

interface IProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string | ReactNode
  touched?: boolean
  error?: boolean
  errorLabel?: string | undefined
  options: IOptions[]
  unSelectedContent?: string
  containerClasses?: string
}

const SelectInput: FC<React.PropsWithChildren<IProps>> = ({
  options,
  unSelectedContent,
  label,
  error,
  errorLabel,
  name,
  value,
  touched,
  className = '',
  containerClasses: containerClassesFromProp = '',
  onChange,
  ...rest
}) => {
  const selectStyles = {
    minHeight: '36px',
    borderRadius: '5px',
    paddingX: '10px',
    border: error && touched ? 'solid 2px var(--red)' : '',
  }

  const errorStyles: CSSProperties = {
    color: 'var(--red)',
    fontStyle: 'italic',
    fontSize: '12px',
    marginLeft: '6px',
    minHeight: '18px',
    display: 'block',
  }

  const containerClasses = `form-group ${
    error && 'has-error'
  } ${containerClassesFromProp}`
  const selectClasses = `form-control ${className}`

  return (
    <div className={containerClasses}>
      <div>
        {label && (
          <label className="form-check-label pb-1" htmlFor={name}>
            {label}
          </label>
        )}
        <div>
          <select
            className={selectClasses}
            value={value}
            onChange={onChange}
            style={selectStyles}
            {...rest}
          >
            {options.map((o: IOptions) => {
              const { key, value, ...restOptions } = o
              return (
                <option key={key} value={key} {...restOptions}>
                  {value}
                </option>
              )
            })}
          </select>
          {error && touched && <span style={errorStyles}>{errorLabel}</span>}
        </div>
      </div>
    </div>
  )
}

export default SelectInput
