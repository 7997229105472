import React from 'react'
import Button from '@components/atoms/Button'
import BuffPreviewWidget from '../BuffPreviewWidget'
import { tr } from '@constants/other'
import { Theme } from '@interfaces/IStreamConfig'

const TemplateAside = ({ theme }: { theme?: Theme }) => {
  return (
    <>
      <BuffPreviewWidget theme={theme} />
      <div className="flex w-full justify-end py-2">
        <Button
          form="buff-form"
          size="small"
          className="w-full"
          type="submit"
          data-testid="buff-page-aside__submit-form"
        >
          {tr({ id: 'template.save' })}
        </Button>
      </div>
    </>
  )
}

export default TemplateAside
