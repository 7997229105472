import React, { useMemo, useState } from 'react'
import { tr } from '@constants/other'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { Form, Formik } from 'formik'
import { ReactComponent as QuestionCircleIcon } from '@assets/question-circle.svg'
import { ReactComponent as CloudIcon } from '@assets/cloud.svg'
import Button from '@components/atoms/Button/Button'
import FileInput from '@components/atoms/FileInput'
import TimeInput from '@components/molecules/TimeInput'
import { VodPreviewProps } from './types'
import { Theme } from '@interfaces/IStreamConfig'
import { LanguageCode } from '@interfaces/ILanguage'
import {
  BuffPreviewProviderRenderer,
  PreviewProviderDataSource,
} from '@services/providers/PreviewProvider'
import { Voteable } from '@interfaces/Voteable'

interface FormValues {
  duration: string
}

const VodPreview = ({ selected }: VodPreviewProps) => {
  const [isDragging, setIsDragging] = useState(false)

  // TODO: Transform buff here when we got real api data for preview
  const mappedBuff = useMemo(() => {
    return selected
  }, [selected])

  const initialValues = { duration: '000:00:00' }

  const handleSubmit = (values: FormValues) => {}

  const handleDragEnter = () => {
    setIsDragging(true)
  }

  const handleDragExit = () => {
    setIsDragging(false)
  }

  const handleAddFile = (files: File[]) => {}

  const bgColor = isDragging ? 'bg-[#E8F2FF]' : 'bg-white'
  const cloudFillColor = isDragging ? 'fill-[#007BFF]' : 'fill-[#717A85]'

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(props) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          setFieldTouched,
          handleBlur,
          setValues,
        } = props
        return (
          <Form>
            <div className="flex flex-col gap-y-6">
              <div className="flex items-center gap-x-1 ">
                <h4 className="font-semibold m-0">
                  {tr({ id: 'generic.preview' })}
                </h4>
                <Tooltip placement="bottom">
                  <TooltipTrigger>
                    <QuestionCircleIcon className="w-6 h-6 text-[#717A85] cursor-pointer" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <span>
                      {tr({
                        id: 'vod.previewDescription',
                      })}
                    </span>
                  </TooltipContent>
                </Tooltip>
              </div>

              {!!mappedBuff && (
                <BuffPreviewProviderRenderer className="h-[325px]" />
              )}

              <div className="w-full h-[1px] bg-[#E1E4E8]" />

              <div className="bg-gray rounded p-3 flex flex-col gap-y-2">
                <p className="m-0 text-black font-semibold">
                  {tr({ id: 'vod.videoPreview' })}
                </p>
                <div
                  className={`group flex flex-col items-center p-4 w-full relative ${bgColor} hover:!bg-[#E8F2FF] border-[1px] border-dashed border-[#959DA5] rounded transition-colors`}
                >
                  <FileInput
                    onDragEnter={handleDragEnter}
                    onDragExit={handleDragExit}
                    onAddFile={handleAddFile}
                    accept="video/mp4,video/x-m4v,video/*"
                  />
                  <div>
                    <CloudIcon
                      className={`${cloudFillColor} group-hover:fill-[#007BFF]`}
                    />
                  </div>
                  <p className="mt-2 mb3.5 text-black">
                    {tr({ id: 'vod.importVideo' })}
                  </p>
                  <p className="text-[#959DA5] text-sm mb-0">
                    {tr({
                      id: 'vod.maxVideoSize',
                    })}
                  </p>
                </div>
              </div>

              <div className="bg-gray rounded p-3 !text-black">
                <TimeInput
                  id="duration"
                  labelClasses="w-full"
                  label={
                    <div className="flex items-center justify-between">
                      <p className="m-0 text-black font-semibold">
                        {tr({ id: 'vod.vodDuration' })}
                      </p>

                      <Button
                        buttonType="link"
                        size="mini"
                        variant="primary"
                        onClick={() => setFieldValue('duration', '000:00:00')}
                      >
                        {tr({ id: 'generic.reset' })}
                      </Button>
                    </div>
                  }
                  showButtons={false}
                  value={values.duration}
                  onBlur={handleBlur}
                  onChange={(val) => setFieldValue('duration', val)}
                  onReset={() => setFieldValue('duration', '000:00:00')}
                  error={Boolean(errors.duration)}
                  errorLabel={errors.duration}
                  touched={touched.duration}
                />
              </div>

              <Button
                className="w-fit ml-auto"
                variant="secondary"
                type="submit"
              >
                {tr({
                  id: 'generic.submit',
                })}
              </Button>
            </div>

            {!!mappedBuff && (
              <PreviewProviderDataSource
                buff={mappedBuff as unknown as Voteable}
                theme={Theme.LIGHT}
                activeLanguage={selected?.languages[0] ?? LanguageCode.en}
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default VodPreview
