import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import BuffHistoryItem from '@components/organisms/BuffHistoryItem'
import { getBuffId } from '@utils/voteables'
import { BuffHistoryListProps } from './types'

const BuffHistoryList: FC<React.PropsWithChildren<BuffHistoryListProps>> = ({
  buffs,
  showResolve = false,
  mode,
  setMainActiveTab,
}) => {
  const [userTime, setUserTime] = useState<number>(new Date().getTime())

  const intervalRef = useRef<number>()

  useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      setUserTime(new Date().getTime())
    }, 2000)

    return () => {
      intervalRef.current && clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <div data-testid="history-list-container" className="flex flex-col w-full">
      {buffs?.map((buff) => {
        return (
          <Fragment key={getBuffId(buff)}>
            <BuffHistoryItem
              userTime={userTime}
              setMainActiveTab={setMainActiveTab}
              buff={buff}
              showResolve={showResolve}
              showReplayCheckbox={mode === 'replay'}
            />
          </Fragment>
        )
      })}
    </div>
  )
}

export default BuffHistoryList
