import React, { FC } from 'react'
import { tr } from '@constants/other'
import Button from '@components/atoms/Button'
import Modal from '@components/molecules/Modal'
import { Form, Formik, FormikHelpers } from 'formik'
import TextInput from '@components/atoms/TextInput'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'
import SelectInput from '@components/atoms/SelectInput'
import { useQueryClient } from 'react-query'
import { useUpdateUser, useUpdateUserRole } from '@services/requests/users'
import { useInviteUser } from '@services/requests/auth'
import { useToast } from '@utils/hooks/useToast'
import schema from './schema'
import { InviteUser, InviteUserModalProps } from './types'

const defaultInitialValues: InviteUser = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
}

const InviteUserModal: FC<React.PropsWithChildren<InviteUserModalProps>> = (
  props
) => {
  const {
    show,
    title,
    subtitle,
    actionButtonTitle,
    inputOptions,
    onClose,
    onSuccess,
    'data-testid': testId = 'invite-user-modal',
    initialValues = defaultInitialValues,
  } = props

  const queryClient = useQueryClient()
  const { mutateAsync: mutateInvite } = useInviteUser()
  const { mutateAsync: mutateUser } = useUpdateUser()
  const { mutateAsync: mutateUserRole } = useUpdateUserRole()
  const { addErrorToast } = useToast()

  const handleSubmit = async (
    values: InviteUser,
    { setSubmitting }: FormikHelpers<InviteUser>
  ) => {
    try {
      if (!initialValues?.email) {
        await mutateInvite(values)
      } else {
        await mutateUser(values)
        await mutateUserRole(values)
      }
      onSuccess()
    } catch (err) {
      addErrorToast(err)
    } finally {
      setSubmitting(false)
      queryClient.invalidateQueries(['users/privileged'])
    }
  }

  return (
    <Modal
      isOpen={show}
      data-testid={testId}
      title={title}
      displayCloseButton={false}
    >
      <Modal.Body className="px-1 overflow-x-hidden !max-h-[calc(100vh-100px)]">
        {!!subtitle && <p className="text-[#717A85]">{subtitle}</p>}

        <Formik
          initialValues={initialValues}
          validateOnChange
          validateOnBlur
          validateOnMount
          validationSchema={schema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              values,
              errors,
              touched,
              isValid,
              isSubmitting,
              handleChange,
              handleBlur,
            } = props

            return (
              <Form className="flex flex-col gap-y-2">
                <Row>
                  <Col>
                    <TextInput
                      id="firstName"
                      placeholder={tr({ id: 'teamMembers.enterName' })}
                      label={
                        <span>
                          <strong>{tr({ id: 'generic.firstName' })}</strong>
                          <span className="ml-1 text-[#959DA5]">
                            ({tr({ id: 'generic.optional' })})
                          </span>
                        </span>
                      }
                      value={values.firstName}
                      error={Boolean(errors.firstName)}
                      errorLabel={errors.firstName}
                      touched={touched.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      id="lastName"
                      placeholder={tr({ id: 'teamMembers.enterSurname' })}
                      label={
                        <span>
                          <strong>{tr({ id: 'generic.lastName' })}</strong>
                          <span className="ml-1 text-[#959DA5]">
                            ({tr({ id: 'generic.optional' })})
                          </span>
                        </span>
                      }
                      value={values.lastName}
                      error={Boolean(errors.lastName)}
                      errorLabel={errors.lastName}
                      touched={touched.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      data-testid={`${testId}_email_input`}
                      id="email"
                      type="email"
                      label={<strong>{tr({ id: 'generic.email' })}</strong>}
                      value={values.email}
                      error={Boolean(errors.email)}
                      errorLabel={errors.email}
                      touched={touched.email}
                      placeholder="example@mail.com"
                      disabled={!!inputOptions?.email?.disabled}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SelectInput
                      data-testid={`${testId}_role_input`}
                      label={<strong>{tr({ id: 'generic.role' })}</strong>}
                      value={values.role}
                      id="role"
                      name="role"
                      error={Boolean(errors.role)}
                      errorLabel={errors.role}
                      touched={touched.role}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={inputOptions?.role?.options}
                      disabled={!!inputOptions?.role?.disabled}
                    />
                  </Col>
                </Row>

                <div className="flex gap-4 !mt-4 justify-end">
                  <Button
                    disabled={isSubmitting}
                    className="inline-flex"
                    variant="secondary"
                    data-testid={`${testId}__cancel`}
                    onClick={onClose}
                  >
                    {tr({ id: 'generic.cancel' })}
                  </Button>
                  <Button
                    disabled={!isValid || isSubmitting}
                    className="inline-flex"
                    variant="primary"
                    data-testid={`${testId}__invite`}
                    type="submit"
                  >
                    {actionButtonTitle}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default InviteUserModal
