import React, { FC } from 'react'
import { tr } from '@constants/other'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { MonetizeTwitchProps } from './types'

const MonetizeTwitch: FC<React.PropsWithChildren<MonetizeTwitchProps>> = ({
  onToggle,
  value,
  className,
}) => {
  return (
    <Tooltip placement="top">
      <TooltipTrigger>
        <div
          data-testid="monetize-twitch"
          className={`flex flex-row p-2 items-center bg-white rounded-sm w-fit ${className}`}
          style={{ width: 'fit-content' }}
        >
          <button
            data-testid="monetize-twitch__button"
            onClick={onToggle}
            type="button"
            className="flex items-center h-6 w-6 border-none p-0"
          >
            {value ? (
              <img src="/assets/svg/icon-check.svg" alt="" />
            ) : (
              <div className="h-6 w-6 border rounded" />
            )}
          </button>
          <span className="ml-2">
            {tr({ id: 'qaForm.monetize.twitchMonetize' })}
          </span>
          <img
            src="/assets/twitch-logo.png"
            className="ml-1.5 object-contain"
            width={16}
            height={18}
          />
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <span>{tr({ id: 'qaForm.monetize.twitchMonetizeTooltip' })}</span>
      </TooltipContent>
    </Tooltip>
  )
}

export default MonetizeTwitch
