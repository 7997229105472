import { client } from '@services/requests/client'
import { getFilterUrl, getPaginatedUrl, getQueryUrl } from '@utils/url'
import {
  AnnouncementTemplate,
  AnnouncementTemplateCreate,
  GetAnnouncementTemplatesResponse,
  GetVoteablesTemplatesResponse,
  Template,
  TemplateCreate,
} from '@interfaces/Template'
import { useFetch } from '@utils/reactQuery/useFetch'
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { QueryKey } from '@utils/reactQuery/types'
import { SortBy, SortDirection } from '@interfaces/Sorting'

interface GetVoteableTemplateParams<T> {
  id: string
  options?: UseQueryOptions<T, Error, T, QueryKey>
}

export const useGetVoteableTemplate = <T = Template>({
  id,
  options,
}: GetVoteableTemplateParams<T>) =>
  useFetch({
    url: `templates/voteables/${id}`,
    config: options,
  })

export const useGetAnnouncementTemplate = <T = AnnouncementTemplate>({
  id,
  options,
}: GetVoteableTemplateParams<T>) =>
  useFetch({
    url: `templates/announcements/${id}`,
    config: options,
  })

export const useCreateVoteableTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (data: TemplateCreate) => client.post(`/templates/voteables`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`announcement-templates`)
      },
    }
  )
}

export const useUpdateVoteableTemplate = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (data: Template) => {
      delete data?.participationPoints
      delete data?.closesAt
      delete data?.opensAt
      delete data?.resolvesAt
      // @ts-ignore
      delete data?.currentTime

      return client.patch(`/templates/voteables/${data.id}`, data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`templates/voteables/${id}`)
      },
    }
  )
}

export const useCreateAnnouncementTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (data: AnnouncementTemplateCreate) =>
      client.post(`/templates/announcements`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`announcement-templates`)
      },
    }
  )
}

export const useUpdateAnnouncementsTemplate = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (data: AnnouncementTemplate) => {
      delete data?.streamId
      delete data?.opensAt
      delete data?.currentTime

      return client.patch(`/templates/announcements/${data.id}`, data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`templates/announcements/${id}`)
      },
    }
  )
}

export const getVoteableTemplates = async (
  pageSize: number = 50,
  nextParam: string = '',
  query?: string,
  filter?: string
): Promise<GetVoteablesTemplatesResponse> => {
  const urlWithQuery = getQueryUrl('/templates/voteables', query)
  const urlWithFilter = getFilterUrl(urlWithQuery, filter)
  const urlWithPagination = getPaginatedUrl(
    urlWithFilter,
    pageSize,
    nextParam,
    true,
    SortDirection.Desc,
    SortBy.createdAt
  )

  const response = await client.get(urlWithPagination)

  const templates = response.data.templates
  const nextPage = response.data.pagination.next

  return {
    templates,
    nextPage,
  }
}

export const getAnnouncementTemplates = async (
  pageSize: number = 50,
  nextParam: string = '',
  query?: string,
  filter?: string
): Promise<GetAnnouncementTemplatesResponse> => {
  const urlWithQuery = getQueryUrl('/templates/announcements', query)
  const urlWithFilter = getFilterUrl(urlWithQuery, filter)
  const urlWithPagination = getPaginatedUrl(
    urlWithFilter,
    pageSize,
    nextParam,
    true,
    SortDirection.Desc,
    SortBy.createdAt
  )

  const response = await client.get(urlWithPagination)

  const templates = response.data.templates
  const nextPage = response.data.pagination.next

  return {
    templates,
    nextPage,
  }
}

export const useVoteableTemplates = (
  limit: number = 30,
  query?: string,
  filter?: string
) => {
  const key = 'voteable-templates'
  return useInfiniteQuery(
    [key, query, filter],
    ({ pageParam = '' }) =>
      getVoteableTemplates(limit, pageParam, query, filter),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.nextPage?.length) return undefined

        return lastPage?.nextPage
      },
    }
  )
}

export const useAnnouncementTemplates = (
  limit: number = 30,
  query?: string,
  filter?: string
) => {
  const key = 'announcement-templates'
  return useInfiniteQuery(
    [key, query, filter],
    ({ pageParam = '' }) =>
      getAnnouncementTemplates(limit, pageParam, query, filter),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.nextPage?.length) return undefined

        return lastPage?.nextPage
      },
    }
  )
}

export const useDeleteVoteableTemplate = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    () => {
      return client.delete(`/templates/voteables/${id}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`voteable-templates`)
      },
    }
  )
}

export const useDeleteAnnouncementTemplate = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    () => {
      return client.delete(`/templates/announcements/${id}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`announcement-templates`)
      },
    }
  )
}
