import React, { FC, useContext, useEffect } from 'react'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import { tr } from '@constants/other'
import {
  PreviewContext,
  QRPreviewProviderRenderer,
} from '@services/providers/PreviewProvider'

const Aside: FC<React.PropsWithChildren> = () => {
  const { setQR } = useContext(PreviewContext)

  useEffect(() => {
    return () => setQR(null)
  }, [setQR])

  return (
    <>
      <Tabs
        renderBorder={false}
        selectedIndex={0}
        tabs={[{ text: tr({ id: 'generic.buffPreview' }) }]}
      >
        <TabPanel>
          <div className="flex flex-col mt-2 overflow-hidden">
            <QRPreviewProviderRenderer />
          </div>
        </TabPanel>
      </Tabs>
    </>
  )
}

export default Aside
