import { useState, useRef, useCallback, useEffect } from 'react'
import { useInfiniteScroll } from './useInfiniteScroll'

export interface IUseInfiniteScrollPaginationProps<T> {
  /**
   * Handler that will be called when there is an error with fetchData
   */
  onError: (error: unknown) => void

  /**
   * Function used to fetch list data
   */
  fetchData: (page: number) => Promise<{ items: T[]; next?: string }>
}

export interface IUseInfiniteScrollPaginationPropsReturn<T> {
  /**
   * Ref function to be added to a trigger element in a list
   */
  triggerRef: (node?: Element | null) => void

  /**
   * The items loaded from the api request
   */
  items: T[]

  /**
   * If an api request is loading
   */
  isLoading: boolean

  /**
   * Function that can be used to refetch and replace the data
   */
  refetch: () => void

  /**
   * Function that removes an items from the array
   */
  removeItem: (item: T) => void
}

/**
 * Handles infinite scroll pagination when requesting from a
 *
 * @param {IUseInfiniteScrollPaginationProps} props
 * @return {IUseInfiniteScrollPaginationPropsReturn}
 */
export const useInfiniteScrollPagination = <T,>({
  onError,
  fetchData,
}: IUseInfiniteScrollPaginationProps<T>): IUseInfiniteScrollPaginationPropsReturn<T> => {
  const [page, setPage] = useState<number>(1)
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)
  const [items, setItems] = useState<T[]>([])
  const [refetch, setRefetch] = useState<boolean>(false)
  const refetchRef = useRef<boolean>(false)
  const [loading, setLoading] = useState(false)
  const isLoading = useRef<boolean>(false)

  // TODO: Re-enable infinite scrolling when migration is done
  const onLoadMore = useCallback(() => {
    if (refetchRef.current) return
    setPage((page) => page + 1)
  }, [])

  // TODO: Talk about useInfiniteScroll
  const { triggerRef } = useInfiniteScroll({
    isLoading: isLoading.current,
    hasNextPage,
    onLoadMore,
  })

  const loadData = useCallback(
    async (_page: number, replace: boolean = false) => {
      isLoading.current = true
      setLoading(true)

      try {
        const { items, next } = await fetchData(_page)

        if (!items) {
          setItems([])
          return
        }

        isLoading.current = false
        setLoading(false)
        const hasNextPage = next !== undefined
        const shouldReplace = page === _page || replace

        setItems((existingItems) => {
          return shouldReplace ? items : [...existingItems, ...items]
        })
        setHasNextPage(hasNextPage)
      } catch (error) {
        isLoading.current = false
        setLoading(false)
        onError(error)
        setHasNextPage(false)
      }
    },
    // TODO: No need to refactor since we're deprecating this component
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchData, onError]
  )

  const refetchHandler = useCallback(() => {
    setRefetch(true)
  }, [])

  const removeItem = useCallback((item: T) => {
    setItems((items) => {
      return items.filter((obj) => obj !== item)
    })
  }, [])

  useEffect(() => {
    if (refetchRef.current) return
    loadData(page, false)
  }, [page, loadData])

  useEffect(() => {
    ;(async () => {
      if (refetch) {
        refetchRef.current = true
        setHasNextPage(true)
        setPage(1)
        setRefetch(false)
        await loadData(1, true)
        refetchRef.current = false
      }
    })()
  }, [refetch, loadData])

  return {
    items,
    triggerRef,
    isLoading: loading,
    removeItem,
    refetch: refetchHandler,
  }
}
