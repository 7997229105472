import * as FormData from 'form-data'
import { vClientIdSchema, vImageTypeSchema } from '@constants/form'
import { ApiResponse, HttpMethod, ImageType } from '@constants/other'
import IImage from '@interfaces/Image'
import IResponse from '@interfaces/IResponse'
import { SortDirection } from '@interfaces/Sorting'
import ApiService from './ApiService'

export interface IFindImagesParams {
  type: ImageType
  page?: number
  params?: ISearchParams
}

export interface IUploadImageParams {
  type: ImageType
  formData: FormData
}

export interface IImageService {
  findImages: (
    p: IFindImagesParams
  ) => Promise<ApiResponse<IResponse<IImage[]>>>

  uploadImages: (p: IUploadImageParams) => Promise<ApiResponse<IImage>>

  trashImage: (groupId: string) => Promise<void>
}

export interface ISearchParams {
  limit?: number
  offset?: number
  by?: string
  order?: SortDirection
}

/**
 * Image service
 */
export default class ImageService
  extends ApiService<IImage>
  implements IImageService
{
  /**
   * @param {IFindImagesParams} p
   * @return {Promise<ApiResponse<IResponse<IImage[]>>>}
   */
  async findImages(
    p: IFindImagesParams
  ): Promise<ApiResponse<IResponse<IImage[]>>> {
    let page = 1

    if (p.page) {
      page = p.page
    }

    await vImageTypeSchema.validateAsync(p.type)
    this.checkToRefreshToken(this.getToken())
    const url = `/client-images?type=${p.type}&page=${page}${
      p.params?.limit ? `&size=${p.params?.limit}` : ''
    }`

    return this.instance.request({
      method: HttpMethod.Get,
      url,
    })
  }

  /**
   * @param {IUploadImageParams} p
   * @return {Promise<ApiResponse<IImage>>}
   */
  async uploadImages(p: IUploadImageParams): Promise<ApiResponse<IImage>> {
    await vClientIdSchema.validateAsync(p.type)
    this.checkToRefreshToken(this.getToken())
    const url = `/client-images`

    return this.instanceUpload.post(url, p.formData)
  }

  /**
   * @param {string} groupId
   * @return {Promise<void>}
   */
  trashImage(groupId: string): Promise<void> {
    this.checkToRefreshToken(this.getToken())

    return this.instance.request({
      method: HttpMethod.Delete,
      url: `/client-images/${groupId}`,
    })
  }
}
