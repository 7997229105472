import { useQuery } from 'react-query'
import { Activity, ActivityData } from '@interfaces/Auth-v2'
import useClient, { Service } from '@utils/hooks/useClient'

export const useAuth = () => {
  const { client } = useClient(Service.AUTH)

  const getActivityByStreamId = async (
    streamId: string,
    startTime?: string,
    endTime?: string
  ) => {
    let url = `/activity?streamId=${streamId}`

    if (startTime) {
      const date = new Date(startTime)
      url += `&startTime=${date.toISOString()}`
    }

    if (endTime) {
      const date = new Date(endTime)
      url += `&endTime=${date.toISOString()}`
    }

    const { data } = await client.get<Activity[]>(url)

    return data
  }

  const getActivitySummaryByStreamId = async (streamId: string) => {
    const { data } = await client.get<ActivityData[]>(
      `/activity/summary?streamId=${streamId}`
    )

    return data
  }

  const useGetActivityByStreamId = (
    streamId: string,
    startTime?: string,
    endTime?: string
  ) => {
    return useQuery(
      ['activity', streamId, startTime, endTime],
      () => getActivityByStreamId(streamId, startTime, endTime),
      { enabled: !!streamId }
    )
  }

  const useGetActivitySummaryByStreamId = (streamId: string) => {
    return useQuery(
      ['activity-summary', streamId],
      () => getActivitySummaryByStreamId(streamId),
      { enabled: !!streamId }
    )
  }

  return {
    useGetActivityByStreamId,
    useGetActivitySummaryByStreamId,
  }
}
