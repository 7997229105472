import { object, string } from 'yup'
import { isAfter, parseISO } from 'date-fns'
import { tr } from '@constants/other'

const schema = object().shape({
  startDate: string().test(
    'is-after',
    tr({ id: 'validation.startDateAfterEndDate' }),
    (value, context) => {
      const endDate = context?.parent?.endDate
      if (!value) return true
      const today = new Date()
      const date = parseISO(value)
      if (!endDate) {
        return !isAfter(date, today)
      }

      const newEndDate = parseISO(endDate)
      return !isAfter(date, newEndDate)
    }
  ),
  endDate: string().notRequired(),
})

export default schema
