import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { AUTHOR_LOCAL_STORAGE_KEY } from '@constants/form'
import { tr } from '@constants/other'
import clearCredentials from '@utils/clearCredentials'
import { Menu } from '@headlessui/react'
import Button, { ButtonVariant } from '@components/atoms/Button'

const navContainer = {
  backgroundColor: 'var(--dark-background)',
  borderBottom: 'black',
  padding: '12px 12px 11px 26px',
}

interface TopNavProps {
  hideSettings?: boolean
}

const TopNav: FC<TopNavProps> = ({ hideSettings = false }) => {
  const navigate = useNavigate()

  const handleSignout = async () => {
    localStorage.removeItem(AUTHOR_LOCAL_STORAGE_KEY)
    clearCredentials()
    navigate('/signin')
  }

  const links = [
    {
      onClick: () => navigate('/team-members'),
      label: tr({ id: 'teamMembers.teamMembers' }),
    },
    { onClick: handleSignout, label: tr({ id: 'signOut.logout' }) },
  ]

  return (
    <div className="top-nav" style={navContainer}>
      <div
        className="flex justify-between items-center"
        style={{ height: '37px' }}
      >
        <div data-testid="logo" className="flex items-center">
          <div className="bg-white rounded" style={{ maxHeight: '26px' }}>
            <img
              width={26}
              height={26}
              alt="logo"
              style={{ verticalAlign: 'inherit' }}
              src={'/assets/images/logo-sportBuff.png'}
            />
          </div>

          <span
            data-testid="logo-title"
            className="text-nowrap ml-3 font-bold"
            style={{ color: 'white' }}
          >
            Sport Buff
          </span>
        </div>
        {!hideSettings && (
          <Menu as="div" className="relative">
            <Menu.Button
              as="div"
              className="flex items-center content-none bg-transparent"
            >
              <img alt="user" src="/assets/svg/button-profile.svg" />
              <div className="sb-btn">
                <img alt="arrow button" src="/assets/svg/icon-arrow-down.svg" />
              </div>
            </Menu.Button>
            <Menu.Items
              as="div"
              className="absolute right-0 z-10 bg-dark-background w-48 focus:outline-none rounded-md shadow-lg"
            >
              {links.map((link) => (
                <Menu.Item
                  key={link.label}
                  as={'div'}
                  className="topnav-menu-dropdown-item text-white rounded-md"
                >
                  <Button
                    variant={ButtonVariant.Neutral}
                    onClick={link.onClick}
                    className={`topnav-menu-dropdown-item text-white`}
                  >
                    {link.label}
                  </Button>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
        )}
      </div>
    </div>
  )
}

export default TopNav
