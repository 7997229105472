import React, { FC, useContext, useEffect, useState } from 'react'
import Col from '@components/atoms/Col'
import { Formik, Form, FormikHelpers } from 'formik'
import { tr } from '@constants/other'
import { ILanguage, LanguageCode } from '@interfaces/ILanguage'
import ISelectedLanguage from '@interfaces/ISelectedLanguage'
import TextInput from '@components/atoms/TextInput'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { LanguageContext } from '@services/providers/LanguageProvider'
import LanguagePickerInput from '@components/molecules/LanguagePickerInput'
import Button, { ButtonVariant } from '@components/atoms/Button'
import { ReactComponent as InfoCircleIcon } from '@assets/info_2.svg'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import { Vod, VodFormValues } from '@interfaces/VodV2'
import { Schema } from './schema'

interface IProps {
  title?: string
  providerId?: string
  bonusTimePoints?: number
  currentLanguages?: LanguageCode[] | null
  vod?: Vod
  handleSubmit: (
    values: VodFormValues,
    helpers: FormikHelpers<VodFormValues>
  ) => void
  handleDelete?: (id: string) => void
}

const VodForm: FC<React.PropsWithChildren<IProps>> = ({
  title,
  providerId,
  bonusTimePoints = 50,
  currentLanguages,
  vod,
  handleSubmit,
  handleDelete,
}) => {
  const [languages, setLanguages] = useState<ISelectedLanguage[]>([])
  const [showDelete, setShowDelete] = useState<boolean>(false)

  const { languages: contextLanguages } = useContext(LanguageContext)
  const disabledLanguagePicker = Boolean(!!vod)

  useEffect(() => {
    if (!contextLanguages) return

    const languages: ISelectedLanguage[] = []

    contextLanguages.forEach((language: ILanguage) => {
      if (
        currentLanguages?.find(
          (lang: LanguageCode) => lang === language.iso6391
        )
      ) {
        languages.push({ ...language, selected: true })
      } else {
        languages.push({ ...language, selected: false })
      }
    })
    setLanguages(languages.sort((a, b) => a.id - b.id))
  }, [contextLanguages, currentLanguages])

  const onSubmit = (
    values: VodFormValues,
    helpers: FormikHelpers<VodFormValues>
  ) => {
    handleSubmit(values, helpers)
  }

  return (
    <>
      <Formik
        initialValues={{
          name: title ?? '',
          providerId: providerId ?? '',
          maxBonusTimePoints: bonusTimePoints ?? 0,
          languages: languages,
        }}
        enableReinitialize
        validationSchema={Schema}
        validateOnBlur
        validateOnMount
        onSubmit={(
          values: VodFormValues,
          helpers: FormikHelpers<VodFormValues>
        ) => {
          onSubmit(values, helpers)
        }}
      >
        {({
          values,
          errors,
          touched,
          isValid,

          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            <div
              data-testid="vod-details"
              className="py-3 px-7 rounded flex flex-col border border-t bg-white-background"
            >
              <div className="w-full">
                <h3 className="font-bold mb-3">
                  {tr({ id: 'vod.detailsTab' })}
                </h3>
                <div className="flex">
                  <Col sm={4} className="pl-0 min-w-[296px]">
                    <TextInput
                      id="name"
                      label={tr({ id: 'qaForm.name' })}
                      className={'w-full px-2 py-1 rounded'}
                      placeholder={tr({ id: 'vod.vodName' })}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.name)}
                      errorLabel={errors.name}
                      touched={touched.name}
                      data-testid="vod-form__name"
                    />
                  </Col>
                  <Col sm={4}>
                    <TextInput
                      id="providerId"
                      label={
                        <div className="flex items-center gap-x-2 cursor-pointer">
                          <span>{tr({ id: 'stream.providerId' })}</span>
                          <Tooltip placement="right">
                            <TooltipTrigger>
                              <InfoCircleIcon className="w-4 h-4" />
                            </TooltipTrigger>
                            <TooltipContent>
                              <span>
                                {tr({
                                  id: 'stream.providerIdDesc',
                                })}
                              </span>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      }
                      style={{ border: '1px solid lightgrey' }}
                      className={'w-full px-2 py-1 rounded'}
                      placeholder={tr({ id: 'generic.id' })}
                      value={values.providerId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.providerId)}
                      errorLabel={errors.providerId}
                      touched={touched.providerId}
                      data-testid="vod-form__provider-id"
                    />
                  </Col>
                </div>

                <div className="flex flex-col">
                  <LanguagePickerInput
                    id="languages"
                    value={values.languages}
                    error={Boolean(errors.languages)}
                    label={tr({
                      id: 'stream.supportedLanguages',
                    })}
                    touched={touched.languages}
                    disabled={disabledLanguagePicker}
                    onChange={(langs) => {
                      setFieldValue('languages', langs)
                    }}
                  />
                </div>
              </div>

              <div className="w-full">
                <h3 className="font-bold mt-3 mb-3">
                  {tr({ id: 'buff.details' })}
                </h3>
                <div>
                  <TextInput
                    id="maxBonusTimePoints"
                    label={
                      <div className="flex items-center gap-x-2 cursor-pointer">
                        <span>{tr({ id: 'stream.bonusTimePoints' })}</span>
                        <Tooltip placement="right">
                          <TooltipTrigger>
                            <InfoCircleIcon className="w-4 h-4" />
                          </TooltipTrigger>
                          <TooltipContent>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: tr({
                                  id: 'stream.bonusTimePointsDesc',
                                }),
                              }}
                            />
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    }
                    type={'number'}
                    min={50}
                    max={250}
                    style={{
                      border: '1px solid lightgrey',
                    }}
                    className={'px-2 py-1 rounded w-44'}
                    value={values.maxBonusTimePoints}
                    onChange={handleChange}
                    error={Boolean(errors.maxBonusTimePoints)}
                    errorLabel={errors.maxBonusTimePoints}
                    touched={touched.maxBonusTimePoints}
                  />
                </div>
              </div>
            </div>
            <div className="flex relative">
              <div
                className={`flex w-full absolute bottom-0 -mb-24 mx-auto ${
                  !!vod ? 'justify-between' : 'justify-end'
                }`}
              >
                {!!vod && (
                  <Button
                    variant="secondary"
                    className="delete-btn w-[11rem] -ml-9"
                    type="button"
                    onClick={() => setShowDelete(true)}
                    data-testid="vod-form__delete"
                  >
                    {tr({ id: 'vod.deleteVOD' })}
                  </Button>
                )}
                <Button
                  className="w-[11rem] ml-auto -mr-9"
                  variant="primary"
                  type="submit"
                  data-testid="vod-form__submit"
                  disabled={!isValid}
                >
                  {!!vod
                    ? tr({ id: 'vod.updateVOD' })
                    : tr({ id: 'vod.createVOD' })}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        show={showDelete}
        onClose={() => setShowDelete(false)}
        onSuccess={() => {
          if (handleDelete && !!vod) handleDelete(vod.id)
        }}
        title={tr({ id: 'vod.deleteStreamConfirmationTitle' })}
        successBtnText={tr({ id: 'vod.deleteVOD' })}
        variant={ButtonVariant.Danger}
        subtext={tr({ id: 'vod.confirmDelete' })}
      />
    </>
  )
}

export default VodForm
