import { LanguageCode } from './ILanguage'

export interface CreateStream {
  title: string
  languages: LanguageCode[]
  streamSources: StreamSourcesObj[]
}

export interface IStream {
  title: string
  id: string
  languages: LanguageCode[]
  streamSources: StreamSources
  gameIds: string[]
  leaderboardIds: string[]
  publishedAt?: string
  endedAt: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  liveAt: string
}
export interface IStreamData {
  stream: IStream
}

export interface GetStreamsResponse {
  streams: IStream[]
  nextPage: string
}

// export interface

export interface StreamSources {
  twitch: StreamSourcesObj[]
  web: StreamSourcesObj[]
}

export interface StreamSourcesObj {
  source: StreamSource
  sourceId: string
}

export enum StreamSource {
  WEB = 'web',
  TWITCH = 'twitch',
}

export enum StreamStatus {
  live = 'Live',
  vod = 'Vod',
  inactive = 'Inactive',
  ending = 'Ending',
}
