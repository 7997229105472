import { tr } from '@constants/other'
import React, { FC } from 'react'
import LanguagePicker from '../LanguagePicker'
import { LanguagePickerInputProps } from './types'

const LanguagePickerInput: FC<
  React.PropsWithChildren<LanguagePickerInputProps>
> = ({
  label,
  disabled,
  error,
  value,
  touched,
  onChange,
  'data-testid': testId = 'language-picker-input',
}): JSX.Element => {
  return (
    <div>
      <LanguagePicker
        label={label}
        data-testid={testId}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
      {error && touched && (
        <>
          <div
            className="flex justify-center items-center ml-3"
            style={{ width: '100%' }}
          >
            <div
              style={{
                backgroundColor: 'var(--red)',
                width: '24px',
                height: '24px',
                borderRadius: '14px',
                fontSize: '1.3em',
                lineHeight: '22px',
                color: 'white',
                textAlign: 'center',
              }}
              className="m-2"
            >
              !
            </div>
            <div
              style={{
                color: 'var(--red)',
                fontStyle: 'italic',
                fontSize: '0.8em',
              }}
            >
              {tr({ id: 'stream.languageRequired' })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default LanguagePickerInput
