import React from 'react'
import { tr } from '@constants/other'
import { AssetClass, AssetComponentMode } from '@interfaces/Assets'
import AssetManager from '../AssetManager'

const Sounds = () => {
  return (
    <div className="p-2">
      <AssetManager
        accept="audio/mpeg"
        title={tr({
          id: 'assetManager.assetSoundsModalListTitle',
        })}
        deleteBtnTitle={tr({
          id: 'assetManager.deleteSoundTitle',
        })}
        deleteBtnSubtitle={tr({
          id: 'assetManager.deleteSoundSubtitle',
        })}
        noAssetsTitle={tr({
          id: 'assetManager.noAssetsSoundTitle',
        })}
        noAssetsSubtitle={tr({
          id: 'assetManager.noAssetsSoundSubtitle',
        })}
        type={AssetClass.BuffSound}
        isOpen={false}
        mode={AssetComponentMode.List}
        showAttachBtn={false}
        onClose={() => {}}
        onSelect={() => null}
      />
    </div>
  )
}

export default Sounds
