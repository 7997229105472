import React, { FC } from 'react'
import StreamListItem from '@components/organisms/StreamListItem'
import { LeaderboardDetailsFormVariant } from '../LeaderboardDetailsForm'
import { StreamListProps } from './types'

const StreamList: FC<React.PropsWithChildren<StreamListProps>> = ({
  streams,
  onChange,
  selectedStreamsIds,
  triggerRef,
  disabled = false,
  variant,
}) => {
  return (
    <div
      className="pb-4 overflow-auto mt-4 pr-2 flex flex-col gap-y-4"
      style={{ height: '500px' }}
    >
      {streams.map((stream) => {
        const streamDisabled =
          selectedStreamsIds?.length === 1 &&
          selectedStreamsIds?.includes(stream.id) &&
          variant === LeaderboardDetailsFormVariant.EDIT
        return (
          <StreamListItem
            key={stream.id}
            stream={stream}
            onChange={(selected) => onChange && onChange(stream, selected)}
            selected={selectedStreamsIds?.includes(stream.id)}
            disabled={disabled || streamDisabled}
          />
        )
      })}
      <div className="h-1" ref={triggerRef} />
    </div>
  )
}

export default StreamList
