import React, { FC, useState } from 'react'
import LanguageItem from '@components/atoms/LanguageItem'
import ISelectedLanguage from '@interfaces/ISelectedLanguage'
import { tr } from '@constants/other'
import { useEffect } from 'react'
import { LanguageCode } from '@interfaces/ILanguage'

interface IProps {
  /**
   * Picker label
   */
  label?: string
  /**
   * A list of languages to display
   */
  value: ISelectedLanguage[]

  /**
   * A list of languages that are non-selectable
   */
  nonSelectableLanguages?: LanguageCode[]

  /**
   * Handler that will be called when a user selects languages from the list
   */
  onChange: (langs: ISelectedLanguage[]) => void

  /**
   * If the selection of languages should be disabled
   * @default false
   */
  disabled?: boolean
}

const selectionStyles = {
  textDecoration: 'underline',
  cursor: 'pointer',
  fontSize: 14,
  color: 'var(--primary)',
  border: 'none',
  padding: 0,
  background: 'transparent',
}

const LanguagePicker: FC<React.PropsWithChildren<IProps>> = ({
  label,
  value,
  onChange,
  disabled = false,
  nonSelectableLanguages = [],
}): JSX.Element => {
  const [languages, setLanguages] = useState<ISelectedLanguage[]>([])
  const selectedLength = languages.filter((l) => l.selected).length

  useEffect(() => {
    if (!value) return

    setLanguages(value)
  }, [value])

  const setLanguageAsSelected = (id: number, active: boolean) => {
    const newLanguages = [...languages]
    const index = newLanguages.findIndex((l) => l.id === id)
    newLanguages[index].selected = active
    onChange(newLanguages)
  }

  const updateAllLangsSelectedValue = (selectedValue: boolean) => {
    let newLanguages = [...languages]
    newLanguages = newLanguages.map((al) => {
      return { ...al, selected: selectedValue }
    })
    onChange(newLanguages)
  }

  return (
    <div
      data-testid="languages-wrapper"
      style={{ width: 810 }}
      className="flex flex-col"
    >
      {!!label && (
        <div
          data-testid="title-actions-wrapper"
          className="flex justify-between mb-2"
        >
          <div data-testid="title">{`${label} (${selectedLength}/${languages.length})`}</div>
          {selectedLength !== languages.length ? (
            <button
              type="button"
              className="mr-4"
              data-testid="actions"
              style={
                disabled
                  ? { ...selectionStyles, color: 'lightgray' }
                  : selectionStyles
              }
              onClick={() => updateAllLangsSelectedValue(true)}
              disabled={disabled}
            >
              {tr({ id: 'generic.selectAll' })}
            </button>
          ) : (
            <button
              type="button"
              data-testid="actions"
              style={selectionStyles}
              onClick={() => updateAllLangsSelectedValue(false)}
              disabled={disabled}
            >
              {tr({ id: 'generic.clear' })}
            </button>
          )}
        </div>
      )}

      <div
        data-testid="language-items-wrapper"
        className="flex flex-wrap justify-start"
      >
        {languages.map((l) => (
          <div
            key={l.id}
            className="flex justify-center items-center mr-3 mb-1"
          >
            <LanguageItem
              name={l.name}
              active={l?.selected}
              setActive={() => setLanguageAsSelected(l.id, !l.selected)}
              disabled={disabled || nonSelectableLanguages.includes(l.iso6391)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default LanguagePicker
