import React, { ChangeEvent, FC, SyntheticEvent, useState } from 'react'
import * as yup from 'yup'
import { tr } from '@constants/other'
import InfoText from '@components/atoms/InfoText'
import Button from '@components/atoms/Button'
import { ReactComponent as BackIcon } from '@assets/arrow_left.svg'
import { sendOTP } from '@services/requests/auth'
import { EmailLoginProps } from './types'

const schema = yup.object().shape({
  email: yup
    .string()
    .email(tr({ id: 'validation.email' }))
    .required(tr({ id: 'validation.email' })),
})

const EmailLogin: FC<React.PropsWithChildren<EmailLoginProps>> = ({
  onGoBack,
  onSuccess,
}) => {
  const [error, setError] = useState('')
  const [emailInputValue, setEmailInputValue] = useState('')
  const [loading, setLoading] = useState(false)

  const resetError = () => setError('')

  const handleEmailSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    const isValid = await schema.isValid({
      email: emailInputValue,
    })

    if (!isValid) return setError(tr({ id: 'validation.email' }))
    try {
      resetError()
      setLoading(true)
      await sendOTP(emailInputValue)
      onSuccess(emailInputValue)
    } catch (err) {
      const errMessage = tr({ id: 'errors.5009' })
      setError(errMessage)
    } finally {
      setLoading(false)
    }
  }

  const handleEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailInputValue(e.target.value)
  }

  const handleGoBack = () => {
    onGoBack()
  }

  const inputBorder = error ? 'border border-danger' : 'border-none'

  return (
    <div
      data-testid="email-login"
      className="flex flex-col items-center min-h-[75px]"
    >
      <div className="flex items-center justify-between mb-4 ">
        <button
          disabled={loading}
          data-testid="email-login__back-button"
          className="bg-transparent relative outline-none border-none h-8 w-8 flex-shrink-0"
          onClick={handleGoBack}
        >
          <BackIcon className="w-full" />
        </button>
        <h4 className="font-md m-0">
          {tr({ id: 'signIn.continueWithEmail' })}
        </h4>
        <div className="w-8" />
      </div>
      <div className="flex flex-col flex-1 w-full">
        <form
          noValidate
          className="flex flex-1 flex-col px-4"
          onSubmit={handleEmailSubmit}
        >
          <label htmlFor="email" className="sr-only">
            {tr({ id: 'generic.emailAddress' })}
          </label>
          <input
            data-testid="email-login__email-input"
            dir="ltr"
            id="email"
            type="email"
            name="email"
            className={`text-sm block w-full rounded p-2 text-black ${inputBorder}`}
            placeholder={tr({ id: 'generic.emailAddress' })}
            formNoValidate
            value={emailInputValue}
            disabled={loading}
            onFocus={resetError}
            onChange={handleEmailInput}
            aria-describedby={error ? 'email-error' : 'sign-up'}
          />
          <InfoText text={error} />
          <Button
            className="mt-4"
            variant="primary"
            type="submit"
            data-testid="email-login__submit-button"
            disabled={loading}
          >
            <span>
              {loading
                ? tr({ id: 'signIn.sendingPasscode' })
                : tr({ id: 'generic.continue' })}
            </span>
          </Button>
        </form>
      </div>
    </div>
  )
}

export default EmailLogin
