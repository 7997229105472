import { LanguageCode } from './ILanguage'
import {
  CreateVoteableAnswerForm,
  VoteableAnswerPaymentOption,
  VoteableLifecycle,
} from '@interfaces/Voteable'
import { LocalisedContent } from '@interfaces/localisation'

export interface LocalizedAnswer {
  localisation: LanguageCode
  text: string
  imageUrl: string
  imageGroupId: string
  imageAltText?: string
  backgroundColor: string
  textColor: string
}

export interface ICreateVoteableAnswer {
  key: string
  points: number
  correct: boolean
  localisations: Record<LanguageCode, ICreateVoteableAnswer>
  paymentOptions?: VoteableAnswerPaymentOption[]
}

export interface BuffFormAnswerLocalisedContent {
  text: string
  imageUrl: string
  imageGroupId: string
}

export interface BuffFormAnswer {
  /**
   * Unique key for the answer (should be a uuid)
   */
  key: string

  /**
   * translated content
   * key being 2 letter lang code
   */
  localisations: Record<LanguageCode, BuffFormAnswerLocalisedContent>

  /**
   * Number of points for selecting the answer
   */
  points: number

  /**
   * Boolean indicating if the answer is correct
   */
  correct: boolean
}

export interface BuffFormValues {
  /**
   * Id of selected author
   */
  authorId: string

  lifecycle: VoteableLifecycle

  currentTime?: number
  /**
   * Number of seconds buff should be displayed for
   */
  displayDurationSeconds?: number
  voteDurationSeconds?: number
  summaryDurationSeconds?: number
  resultsDurationSeconds?: number

  opensAt?: string
  expiresIn?: string
  schedule: string

  /**
   * Question of the voteable
   * key being 2 letter lang code
   */
  question: Record<LanguageCode, LocalisedContent>

  /**
   * Array of answers
   */
  answers: CreateVoteableAnswerForm[]
}

export enum BuffFormType {
  Prediction = 1,
  Quiz = 2,
  Poll = 3,
  EmojiSlider = 4,
  Announcement = 5,
  StarRating = 6,
  WelcomeBuff = 7,
  Vote = 8,
}
