import React from 'react'
import { format } from 'date-fns'
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from '@components/molecules/Tooltip'
import Pill from '@components/atoms/Pill'
import TextInput from '@components/atoms/TextInput'
import { getStreamStatus, getStreamStatusPillColor } from '@utils/stream'
import { StreamListItemProps } from './types'
import './StreamListItem.css'

const StreamListItem = ({
  stream,
  selected = false,
  onChange,
  disabled,
  'data-testid': testId = 'stream-list-item',
}: StreamListItemProps) => {
  const id = `${stream.id}.selected`
  const status = getStreamStatus(stream)
  const pillColor = getStreamStatusPillColor(status)

  return (
    <div
      data-testid={testId}
      className="bg-white p-3 items-center stream-list-item rounded"
    >
      <TextInput
        id={id}
        type="checkbox"
        style={{ position: 'static', width: '26px', margin: 0 }}
        className="form-check-input light-mode stream-list-item-input"
        checked={selected}
        onChange={(e) => {
          onChange && onChange(e.currentTarget.checked)
        }}
        data-testid={`${testId}__checkbox`}
        disabled={disabled}
      />
      <Tooltip placement="bottom">
        <TooltipTrigger>
          <label
            htmlFor={id}
            className="items-center pl-3 mb-0 flex-grow mr-2 cursor-pointer min-w-0 stream-list-item-label"
            data-testid={`${testId}__label`}
          >
            <Pill
              text={status ?? ''}
              color={pillColor}
              className="mr-3 flex-shrink-0"
            />
            <div className="items-center justify-between flex-grow min-w-0 stream-list-item-text">
              <p className="m-0 min-w-0 truncate pr-16">{stream.title}</p>
              <p className="m-0 flex-shrink-0 text-[12px] font-normal text-[#586069] pr-2">
                {format(new Date(stream.createdAt ?? ''), 'dd/MM/yyyy')}
              </p>
            </div>
          </label>
        </TooltipTrigger>
        <TooltipContent>
          <span>{stream.title}</span>
        </TooltipContent>
      </Tooltip>
    </div>
  )
}

export default StreamListItem
