import { parseLocalisationObjectToArray } from '@utils/localisation'
import {
  CreateVoteableAnswer,
  CreateVoteableAnswerForm,
} from '@interfaces/Voteable'
import { BuffType } from '@constants/other'
import { LanguageCode } from '@interfaces/ILanguage'
import sortLocalisableContent from './sortLocalisableContent'
import { LocalisedFormVoteableContent } from '@interfaces/localisation'

const buffTypesToExcludeColor = [BuffType.EmojiSlider]
const buffTypesToIncludeCorrectField = [BuffType.Quiz]
const buffTypesToIncludePointsField = [
  BuffType.Quiz,
  BuffType.EmojiSlider,
  BuffType.Prediction,
  BuffType.Vote,
]

const formatBuffAnswerToSubmit = (
  type: BuffType,
  answers?: CreateVoteableAnswerForm[],
  streamLanguages: LanguageCode[] = [],
  removeAnswerKey = true
): CreateVoteableAnswer[] => {
  if (type == BuffType.StarRating) {
    const answerArr = [] as CreateVoteableAnswer[]
    for (let i = 0; i < 5; i++) {
      const localisations = streamLanguages.map((lang) => {
        const text = i === 0 ? 'star' : 'stars'
        return {
          localisation: lang,
          text: `${i + 1} ${text}`,
        }
      })
      answerArr.push({
        localisations: localisations,
      })
    }
    return answerArr
  }

  return answers?.map(({ id, ...answer }: CreateVoteableAnswerForm) => {
    const localisations: Partial<
      Record<LanguageCode, LocalisedFormVoteableContent>
    > = {}

    if (answer.localisations) {
      Object.keys(answer.localisations).forEach((value) => {
        const language = value as unknown as LanguageCode
        if (answer.localisations[language]) {
          localisations[language] = {
            ...answer.localisations[language],
          } as LocalisedFormVoteableContent

          if (buffTypesToExcludeColor.includes(Number(type))) {
            localisations[language]!.textColor = undefined
            localisations[language]!.backgroundColor = undefined
          }
        }
      })
    }

    const obj: any = {
      ...answer,
      localisations: sortLocalisableContent(
        parseLocalisationObjectToArray(localisations),
        streamLanguages
      ),
    }

    if (!buffTypesToIncludeCorrectField.includes(Number(type))) {
      obj.correct = undefined
    }

    if (!buffTypesToIncludePointsField.includes(Number(type))) {
      obj.points = undefined
    }

    if (!removeAnswerKey) {
      obj.id = id
    }

    return obj
  }) as CreateVoteableAnswer[]
}

export default formatBuffAnswerToSubmit
