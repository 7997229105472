import React, { FC, useEffect, useState } from 'react'
import NameModalForm from '@components/organisms/NameModalForm'
import ConfirmationModal from '@components/molecules/ConfirmationModal'
import { Template, TemplateCreate } from '@interfaces/Template'
import { useToast } from '@utils/hooks/useToast'
import { tr } from '@constants/other'
import schema from './schema'
import { TemplateBuffConfirmationProps } from './types'
import {
  useCreateVoteableTemplate,
  useUpdateVoteableTemplate,
} from '@services/requests/templates'

const TemplateBuffConfirmation: FC<
  React.PropsWithChildren<TemplateBuffConfirmationProps>
> = ({ show, payload, mode, id, onSuccessCallback, onClose }) => {
  const [visible, setVisible] = useState(show)
  const [templateName, setTemplateName] = useState<string>('')
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const { addErrorToast } = useToast()

  const { mutateAsync: mutateCreate } = useCreateVoteableTemplate()
  const { mutateAsync: mutateUpdate } = useUpdateVoteableTemplate(id ?? '')

  const handleClose = () => {
    setVisible(false)
    onClose && onClose()
  }

  useEffect(() => {
    if (!payload) return
    setTemplateName(payload.templateName)
    setIsButtonDisabled(!payload.templateName ? true : false)
  }, [payload])

  const updateTemplate = async () => {
    if (!id) return
    try {
      const data = {
        ...(payload as Template),
        templateName: templateName,
        id,
      }

      await mutateUpdate(data)

      onSuccessCallback && onSuccessCallback()
    } catch (err) {
      addErrorToast(err)
    }
  }

  const createTemplate = async () => {
    try {
      const data = {
        ...payload,
        templateName: templateName,
      }

      await mutateCreate(data as TemplateCreate)

      onSuccessCallback && onSuccessCallback()
      handleClose()
    } catch (err) {
      addErrorToast(err)
    }
  }

  useEffect(() => {
    setVisible(show)
  }, [show, setVisible])

  return (
    <ConfirmationModal
      show={visible}
      disabled={isButtonDisabled}
      onClose={handleClose}
      onSuccess={mode === 'update' ? updateTemplate : createTemplate}
      data-testid="create-template-buff-confirmation"
      title={tr({ id: 'template.save' })}
      successBtnText={tr({
        id: 'template.save',
      })}
    >
      <NameModalForm
        nameKey="templateName"
        nameValue={templateName}
        validationSchema={schema}
        updateName={(name: string) => setTemplateName(name)}
        updateDisabled={(disableValue: boolean) =>
          setIsButtonDisabled(disableValue)
        }
      />
    </ConfirmationModal>
  )
}

export default TemplateBuffConfirmation
