import { useRef, useEffect } from 'react'

/**
 * A hook that gets the previous state or props value
 * @param {T} value
 * @return {T | undefined}
 */
export const usePreviousValue = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
