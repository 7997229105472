import { cloneDeep } from 'lodash'
import { LanguageCode } from '@interfaces/ILanguage'
import { CreateVoteableAnswerForm } from '@interfaces/Voteable'
import { LocalisedVoteableContent } from '@interfaces/localisation'
import { v4 } from 'uuid'

// Stops buff form re-initalising based on initial values changing with new ids
const preGeneratedIds = new Array(5).fill(null).map(() => {
  return v4()
})

const createDefaultAnswer = (
  languages: LanguageCode[],
  index?: number
): CreateVoteableAnswerForm => {
  const id = typeof index === 'number' ? preGeneratedIds[index] : v4()

  const DEFAULT_ANSWER: CreateVoteableAnswerForm = {
    id,
    localisations: {} as Record<LanguageCode, LocalisedVoteableContent>,
    correct: false,
    points: 0,
    paymentOptions: [],
  }
  const answer = cloneDeep(DEFAULT_ANSWER)

  languages?.forEach((lang) => {
    answer.localisations[lang] = {
      text: '',
      imageUrl: '',
      backgroundColor: '',
      textColor: '',
      localisation: lang,
    }
  })

  return answer
}

export default createDefaultAnswer
