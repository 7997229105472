import {
  BUFF_ANIMATION_GAP,
  BUFF_EXPIRY_DURATION_MIN_GAP,
  BUFF_EXPIRY_DURATION_MIN_GAP_TIME_SYNC,
  DEFAULT_EXPIRY_TIME,
  DEFAULT_EXPIRY_TIME_TIME_SYNC,
} from '@constants/form'
import { BuffType } from '@constants/other'
import { LanguageCode } from '@interfaces/ILanguage'
import { Template } from '@interfaces/Template'
import {
  LocalisedAuthorFormValue,
  LocalisedFormValue,
  LocalisedSponsorContent,
  LocalisedSponsorFormValue,
  LocalisedVoteableContent,
  LocalisedVoteableFormValue,
} from '@interfaces/localisation'
import {
  CreateVoteableAnswer,
  VoteableLayout,
  CreateVoteableForm,
} from '@interfaces/Voteable'
import { getMetadataFromBuffType } from '@utils/buffTypeMappings'
import { msToHHMMSS } from '@utils/time'
import { getBlankInitialValues } from '@utils/buffForm/shared'
import { cloneDeep, isEmpty } from 'lodash'
import { v4 } from 'uuid'

const DEFAULT_SCHEDULE_SEND = '000:00:00'

const generateDefaultSponsor = (languages: LanguageCode[]) => {
  const sponsor: LocalisedSponsorFormValue = {}
  languages.forEach((lang) => {
    const language = lang as LanguageCode
    sponsor[language] = {
      text: '',
      linkTarget: '',
      imageUrl: '',
      imageAltText: '',
      localisation: lang,
    }
  })
  return sponsor
}

interface GetTemplateInitialValuesArgs {
  template?: Template
  languages: LanguageCode[]
  isTimeSyncStream?: boolean
  buffType?: BuffType
}

export const getTemplateInitialValues = ({
  template,
  languages,
  isTimeSyncStream = false,
  buffType = BuffType.Prediction,
}: GetTemplateInitialValuesArgs): CreateVoteableForm => {
  const initialQuestionValue: LocalisedFormValue = {}
  const initialAnswerValue: LocalisedVoteableFormValue = {}
  const initialSponsorValue: LocalisedSponsorFormValue = {}
  const initialAuthorValue: LocalisedAuthorFormValue = {}
  const showSponsor: Partial<Record<LanguageCode, boolean>> = {}

  if (template === undefined) {
    return getBlankInitialValues({ languages, isTimeSyncStream, buffType })
  }

  template?.sponsor?.localisations?.forEach(
    (localisedSponsor: LocalisedSponsorContent) => {
      const { linkTarget, localisation } = localisedSponsor
      const imageUrl = localisedSponsor.imageUrl || localisedSponsor.imageURL
      initialSponsorValue[localisation] = {
        linkTarget,
        localisation,
        imageUrl,
        imageAltText: localisedSponsor.imageAltText,
        text: localisedSponsor.text,
      }

      const hasSponsor = Boolean(imageUrl) || Boolean(linkTarget)
      showSponsor[localisation] = hasSponsor
    }
  )

  template?.author?.localisations.forEach((localisedAuthor) => {
    initialAuthorValue[localisedAuthor.localisation] = {
      text: localisedAuthor.text,
      imageUrl: localisedAuthor.imageUrl || localisedAuthor.imageURL || '',
    }
  })

  template?.question?.localisations.forEach((localisedQuestion) => {
    initialQuestionValue[localisedQuestion.localisation] =
      localisedQuestion.text
  })

  const { id: templateBuffType } = getMetadataFromBuffType({
    lifecycle: template?.lifecycle,
    answerInterface: template?.answerInterface,
  })

  let expiresIn = null

  if (template.openDurationSeconds) {
    expiresIn = msToHHMMSS(template.openDurationSeconds * 1000)
  }

  if (
    template?.voteDurationSeconds &&
    template?.opensAt &&
    template?.closesAt &&
    !expiresIn
  ) {
    const opensAt = new Date(template.opensAt).getTime()
    const closesAt = new Date(template.closesAt).getTime()
    const expiresInMS = closesAt - opensAt - BUFF_ANIMATION_GAP * 1000
    const expiresInSeconds = expiresInMS / 1000
    const gap = isTimeSyncStream
      ? BUFF_EXPIRY_DURATION_MIN_GAP_TIME_SYNC
      : BUFF_EXPIRY_DURATION_MIN_GAP
    const minExpiry = template.voteDurationSeconds + gap
    expiresIn = msToHHMMSS(expiresInMS)

    if (expiresInSeconds < minExpiry) {
      const newExpiresIn = minExpiry * 1000
      expiresIn = msToHHMMSS(newExpiresIn)
    }
  }

  if (template?.voteDurationSeconds && !expiresIn) {
    const gap = isTimeSyncStream
      ? BUFF_EXPIRY_DURATION_MIN_GAP_TIME_SYNC
      : BUFF_EXPIRY_DURATION_MIN_GAP
    const minExpiry = template.voteDurationSeconds + gap
    const expiresInMS = minExpiry * 1000
    expiresIn = msToHHMMSS(expiresInMS)
  }

  const newExpiresIn =
    expiresIn ??
    (isTimeSyncStream ? DEFAULT_EXPIRY_TIME_TIME_SYNC : DEFAULT_EXPIRY_TIME)

  const initialValuesFromTemplate = {
    showSponsor,
    type: templateBuffType ?? BuffType.Prediction,
    layout: template?.layout ?? VoteableLayout.LAYOUT_HORIZONTAL,
    voteDurationSeconds: template.voteDurationSeconds,
    resultsDurationSeconds:
      template.resultsDurationSeconds || template.summaryDurationSeconds,
    expiresIn: newExpiresIn,
    schedule: DEFAULT_SCHEDULE_SEND,
    author: initialAuthorValue,
    question: initialQuestionValue,
    localisations: template?.localisations,
    sponsor: isEmpty(initialSponsorValue)
      ? generateDefaultSponsor(languages)
      : initialSponsorValue,
    answers: template.answers.map((answer: CreateVoteableAnswer) => {
      answer.localisations.forEach(
        (localisedAnswer: LocalisedVoteableContent) => {
          initialAnswerValue[localisedAnswer.localisation] = {
            text: localisedAnswer.text,
            backgroundColor: localisedAnswer.backgroundColor,
            imageUrl: localisedAnswer.imageUrl || localisedAnswer.imageURL,
            localisation: localisedAnswer.localisation,
            textColor: localisedAnswer.textColor,
          }
        }
      )

      return {
        correct: answer.correct,
        id: answer.id ?? v4(),
        points: answer.points,
        paymentOptions: answer?.paymentOptions,
        localisations: cloneDeep(initialAnswerValue),
      }
    }),
  }

  return initialValuesFromTemplate
}
