import React, { FC, useEffect, useState } from 'react'
import TextInput from '@components/atoms/TextInput'
import { ImageFieldSize } from '@components/molecules/ImageFieldWithButtons'
import LanguageTabContent from '@components/molecules/LanguageTabContent'
import { tr } from '@constants/other'
import { useFormikContext } from 'formik'
import { LanguageCode } from '@interfaces/ILanguage'
import AssetManager from '@components/organisms/AssetManager'
import { AssetClass } from '@interfaces/Assets'
import { cloneDeep, merge } from 'lodash'
import deepOmit from '@utils/deepOmit'
import BannerFormSection from '@components/molecules/BannerFormSection/BannerFormSection'
import { LeaderboardFormProps, LeaderboardFormValues } from '../types'

const LeaderboardForm: FC<LeaderboardFormProps> = ({
  languages: clientLanguages,
  initialValues,
}) => {
  const [isAssetManagerOpen, setIsAssetManagerOpen] = useState(false)
  const [activeLanguage, setActiveLanguage] = useState<LanguageCode>(
    clientLanguages?.default ?? LanguageCode.en
  )
  const [selectedLanguages, setSelectedLanguages] = useState<LanguageCode[]>([
    clientLanguages?.default ?? LanguageCode.en,
  ])

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = useFormikContext<LeaderboardFormValues>()

  useEffect(() => {
    if (!initialValues) return

    const activeLanguages = initialValues.content.languages
    const languages =
      activeLanguages?.length > 0
        ? activeLanguages
        : [clientLanguages?.default ?? LanguageCode.en]

    setSelectedLanguages(languages)
    setActiveLanguage(languages[0])

    setFieldValue('content.languages', languages)
  }, [clientLanguages?.default, initialValues, setFieldValue])

  const handleSelectedLanguagesChange = (langs: LanguageCode[]) => {
    setSelectedLanguages(langs)
    setFieldValue('content.languages', langs)

    const removedLanguages = selectedLanguages.filter((selectedLang) => {
      return !langs.includes(selectedLang)
    })

    const addedLangs = langs.filter((lang) => !selectedLanguages.includes(lang))

    removedLanguages.forEach((removedLang) => {
      setFieldValue(`content.${removedLang}.showBanner`, false)
    })

    let newValues = cloneDeep(values)
    newValues.content.languages = langs

    if (removedLanguages.length !== 0) {
      newValues = deepOmit(newValues, removedLanguages)
    }

    if (addedLangs.length !== 0) {
      const valuesToMerge = {
        content: {
          languages: newValues.content.languages,
        },
      }

      addedLangs.forEach((lang) => {
        // @ts-ignore
        valuesToMerge.content[lang] = {
          title: '',
          showBanner: false,
          banner: {
            imageAltText: '',
            imageUrl: '',
          },
        }
      })

      newValues = merge(newValues, valuesToMerge)
    }

    setValues(newValues)
  }

  const tabErrors: Partial<Record<LanguageCode, boolean>> = {}

  return (
    <div className="bg-white rounded">
      <div className="overflow-y-auto">
        <fieldset className="w-full p-4 mb-4 rounded-lg bg-white-background">
          <h3 className="mb-6 font-bold">
            {tr({ id: 'leaderboard.leaderboardSettings' })}
          </h3>
          <TextInput
            id="name"
            label={tr({ id: 'leaderboard.leaderboardName' })}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.name)}
            errorLabel={errors.name}
            touched={touched.name}
            placeholder={tr({ id: 'leaderboard.enterName' })}
          />
          <p className="text-[12px] m-0 text-[#586069] font-light">
            {tr({ id: 'leaderboard.visibleToDashboard' })}
          </p>
        </fieldset>

        <LanguageTabContent
          availableLanguages={clientLanguages.enabled}
          activeLanguage={activeLanguage}
          selectedLanguages={selectedLanguages}
          onSelectedLanguagesChange={handleSelectedLanguagesChange}
          onLanguageChange={(lang) => setActiveLanguage(lang)}
          errors={tabErrors}
        >
          <>
            <fieldset className="w-72">
              <TextInput
                id={`content.${activeLanguage}.title`}
                label={tr({ id: 'leaderboard.leaderboardTitle' })}
                // @ts-ignore
                value={values.content[activeLanguage]?.title}
                onChange={handleChange}
                onBlur={handleBlur}
                // @ts-ignore
                error={Boolean(errors?.content?.[activeLanguage]?.title)}
                // @ts-ignore
                errorLabel={errors?.content?.[activeLanguage]?.title}
                // @ts-ignore
                touched={touched?.content?.[activeLanguage]?.title}
                placeholder={tr({ id: 'leaderboard.enterTitle' })}
              />
              <p className="text-[12px] m-0 text-[#586069]">
                {tr({ id: 'leaderboard.visibleToAll' })}
              </p>
            </fieldset>

            <BannerFormSection<LeaderboardFormValues>
              sponsorKey={`content.${activeLanguage}`}
              values={values}
              errors={errors}
              activeLanguage={activeLanguage}
              touched={touched}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              onAddSponsorImageClick={() => setIsAssetManagerOpen(true)}
              imageSize={ImageFieldSize.LEADERBOARD_SPONSOR}
            />
          </>
        </LanguageTabContent>
      </div>
      {isAssetManagerOpen && (
        <AssetManager
          isOpen={isAssetManagerOpen}
          type={AssetClass.LeaderboardSponsor}
          onSelect={([image]) => {
            const imageUrl = image.publicURL + image.fileName
            setFieldValue(`content.${activeLanguage}.banner.imageUrl`, imageUrl)
          }}
          onClose={() => setIsAssetManagerOpen(false)}
        />
      )}
    </div>
  )
}

export default LeaderboardForm
