import { CreateVoteable, CreateVoteableForm } from '@interfaces/Voteable'
import { getUiFromBuffType } from './getUiFromBuffType'
import { BuffType } from '@constants/other'
import { LanguageCode } from '@interfaces/ILanguage'
import {
  BUFF_ANIMATION_GAP,
  BUFF_EXPIRY_DURATION_MIN_GAP,
  BUFF_EXPIRY_DURATION_MIN_GAP_TIME_SYNC,
  BUFF_RESOLVE_GAP_TO_EXPIRY,
} from '@constants/form'
import { LocalisedSponsorContent } from '@interfaces/localisation'
import { parseLocalisationObjectToArray } from '@utils/localisation'
import { hmsStringToSeconds } from './time'
import formatBuffAnswerToSubmit from './formatBuffAnswerToSubmit'
import calculateScheduledTimeDelay from './calculateScheduledTimeDelay'
import formatBuffQuestionToSubmit from './formatBuffQuestionToSubmit'
import formatBuffAuthorToSubmit from './formatBuffAuthorToSubmit'
// import formatBuffSponsorToSubmit from './formatBuffSponsorToSubmit'
import formatLocalisationsBasedOnAuthor from './formatLocalisationsBasedOnAuthor'

// 1s -> 1000ms
const SECONDS_TO_MS_MULTIPLIER = 1000

export const formatBuffDataToSubmit = (
  values: CreateVoteableForm,
  isWelcomeBuff: boolean,
  streamLanguages: LanguageCode[] = [],
  removeAnswerKey: boolean = true,
  participationPoints: number = 0,
  isTimeSyncStream: boolean = false,
  isTemplateBuff: boolean = false
): CreateVoteable => {
  const delay =
    calculateScheduledTimeDelay(values?.schedule ?? '') *
    SECONDS_TO_MS_MULTIPLIER
  const expiresIn = hmsStringToSeconds(values?.expiresIn ?? '')
  const currentTime = new Date()

  const opensAt = new Date(currentTime.getTime() + delay)
  const closesAt = new Date(
    opensAt.getTime() +
      expiresIn * SECONDS_TO_MS_MULTIPLIER +
      BUFF_ANIMATION_GAP * SECONDS_TO_MS_MULTIPLIER
  )
  const hasResolvesAt =
    Number(values?.type) === BuffType.Quiz ||
    Number(values?.type) === BuffType.Vote

  const gap = isTimeSyncStream
    ? BUFF_EXPIRY_DURATION_MIN_GAP_TIME_SYNC
    : BUFF_EXPIRY_DURATION_MIN_GAP

  const resolvesAt = hasResolvesAt
    ? new Date(
        closesAt.getTime() +
          BUFF_RESOLVE_GAP_TO_EXPIRY * SECONDS_TO_MS_MULTIPLIER
      )
    : null

  const buffType = values?.type ? Number(values.type) : BuffType.Prediction
  const ui = getUiFromBuffType(buffType)

  // Set as 0 for types that don't show an expired state
  const summaryDurationSeconds =
    buffType === BuffType.Quiz || buffType === BuffType.Vote
      ? 0
      : values.resultsDurationSeconds

  const resultsDurationSeconds =
    buffType === BuffType.Quiz ||
    buffType === BuffType.Vote ||
    buffType === BuffType.Prediction
      ? values.resultsDurationSeconds
      : 0

  let sponsor: CreateVoteable['sponsor'] | null = null
  const langsWithSponsor = Object.entries(values.showSponsor)
    .filter(([key, value]) => {
      return value === true
    })
    .map(([key]) => key) as LanguageCode[]

  if (langsWithSponsor.length !== 0) {
    const newSponsor: Partial<Record<LanguageCode, LocalisedSponsorContent>> =
      {}
    sponsor = {
      localisations: [],
    }

    langsWithSponsor.forEach((lang) => {
      if (!newSponsor[lang]) {
        newSponsor[lang] = {
          text: '',
          localisation: lang,
        }
      }

      const obj = newSponsor[lang]
      if (!obj) return

      obj.imageUrl =
        values.sponsor?.[lang]?.imageUrl || values.sponsor?.[lang]?.imageURL
      obj.linkTarget = values.sponsor?.[lang]?.linkTarget
    })

    sponsor.localisations =
      parseLocalisationObjectToArray<LocalisedSponsorContent>(newSponsor)
  }

  const body: CreateVoteable = {
    sponsor,
    question: formatBuffQuestionToSubmit(values?.question, streamLanguages),
    answers: formatBuffAnswerToSubmit(
      values.type,
      values.answers,
      streamLanguages,
      removeAnswerKey
    ),
    layout: values.layout,
    answerInterface: ui.answerInterface,
    participationPoints,
    author: formatBuffAuthorToSubmit(values.author, streamLanguages),
    localisations: formatLocalisationsBasedOnAuthor(
      values.author as Record<LanguageCode, any>
    ),
  }

  const voteableBody = {
    currentTime,
    summaryDurationSeconds,
    opensAt: opensAt.toISOString(),
    closesAt: closesAt.toISOString(),
    resolvesAt: resolvesAt ? resolvesAt.toISOString() : undefined,
    voteDurationSeconds: values.voteDurationSeconds,
    resultsDurationSeconds,
    lifecycle: ui.lifecycle,
  }

  if (isTemplateBuff) {
    Object.assign(voteableBody, {
      openDurationSeconds: expiresIn,
    })
  }

  if (isWelcomeBuff) {
    Object.assign(body, {
      displayDurationSeconds: values.displayDurationSeconds,
    })
  }

  if (!isWelcomeBuff) {
    Object.assign(body, voteableBody)
  }

  return body
}
