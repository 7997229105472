import { client } from '@services/requests/client'
import { BatchUser } from '@interfaces/IUser'

// TODO: Remove this and add it to a service when backend auth stuff is done
export const fetchUsersBatch = async (
  userIds: string[]
): Promise<BatchUser[]> => {
  const response = await client.get(
    `/users?ids=${userIds.join(',')}&profile=private`
  )

  const users = response.data
  return users
}
