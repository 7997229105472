import React, { FC, useContext, MouseEvent } from 'react'
import { BuffType, tr } from '@constants/other'
import { WelcomeBuffContext } from '@services/providers/WelcomeBuffProvider'
import useSingleAndDoubleClick from '@utils/hooks/useSingleAndDoubleClick'
import { getLanguagesMetadata } from '@utils/language'
import { CreateVoteableAnswer } from '@interfaces/Voteable'
import { LanguageCode } from '@interfaces/ILanguage'
import TooltipPill from '@components/molecules/TooltipPill'
import Image from '@components/atoms/Image'

interface ITemplateItemWelcomeBuffProps {
  type?: BuffType
  id: string
  languages: LanguageCode[]
  name: string
  answers: CreateVoteableAnswer[]
  question: string | null
  onClick: () => void
  onDoubleClick: () => void
}

const TemplateItemWelcomeBuff: FC<
  React.PropsWithChildren<ITemplateItemWelcomeBuffProps>
> = ({
  id,
  type = BuffType.WelcomeBuff,
  name,
  question,
  languages,
  answers,
  onClick,
  onDoubleClick,
}) => {
  const { selectedWelcomeBuff } = useContext(WelcomeBuffContext)
  const handleClick = useSingleAndDoubleClick(onClick, onDoubleClick)

  const typeStyles = {
    color: 'black',
    background: 'var(--white-secondary)',
    width: '115px',
    borderBottomRightRadius: '5px',
    fontSize: 12,
  }

  const headerStyles = {
    background: 'white',
    padding: 0,
    borderBottom: 0,
  }

  /**
   * Prevents text selection on double click
   * @see https://stackoverflow.com/a/43321596
   *
   * @param {MouseEvent} e Mouse event
   */
  const handleMouseDown = (e: MouseEvent) => {
    if (e.detail > 1) {
      e.preventDefault()
    }
  }

  const langIconsInfo = getLanguagesMetadata(languages)

  return (
    <>
      <div
        className="relative flex flex-col min-w-0 min-h-[94px] text-pretty bg-white bg-clip-border rounded"
        onMouseDown={handleMouseDown}
        onClick={handleClick}
        style={{
          boxShadow: '0',
          border: 'solid 2px',
          minHeight: '94px',
          borderColor:
            selectedWelcomeBuff?.welcomeBuffId === id
              ? 'var(--primary)'
              : 'var(--white-background)',
        }}
      >
        <div className="flex justify-between">
          <div className="flex space-x-2 items-center">
            <div className="bg-white p-0" style={headerStyles}>
              <div style={typeStyles} className="px-3 py-1">
                {tr({ id: `buff.type.${type}` })}
              </div>
            </div>
            <span className="italic text-[12px]">{name}</span>
          </div>
          <div className="space-x-1 mr-8 flex">
            {langIconsInfo?.map((l) => (
              <TooltipPill
                key={`${l?.iso6391}`}
                className="ml-1 mt-1"
                showTooltip
                text={`${l?.iso6391.toUpperCase()}`}
              >
                {l?.name}
              </TooltipPill>
            ))}
          </div>
        </div>
        <div
          className="min-h-[1px] flex-auto !p-5"
          style={{ cursor: 'default', userSelect: 'none' }}
        >
          <div className="flex justify-between">
            <span className="mr-4">{question || null}</span>
            <div className="flex space-x-2 last:mr-3">
              {answers.map((answer, i) => {
                const answerObj = answer?.localisations?.[0]
                return (
                  <div key={`img-${i}`}>
                    <div className="flex relative items-center justify-center bg-[#d8e1ec] rounded-md w-[68px] h-[60px] overflow-hidden">
                      <Image
                        className="absolute inset-0 w-full h-full rounded object-cover"
                        src={answerObj?.imageUrl}
                        fallbackComponent={
                          <img
                            className="rounded"
                            width={24}
                            height={24}
                            src={'/assets/icon-image.png'}
                            alt={tr({ id: 'assetManager.assetIcon' })}
                          />
                        }
                        alt="Answer image"
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TemplateItemWelcomeBuff
