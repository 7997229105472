import React, { SVGProps } from 'react'

const ImageIcon: React.FC<React.PropsWithChildren<SVGProps<SVGSVGElement>>> = ({
  className,
  fill,
  height,
  width,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      className={className}
      viewBox="0 0 24 24"
    >
      <defs>
        <filter id="eg04iqm36a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.847059 0 0 0 0 0.882353 0 0 0 0 0.925490 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g>
        <g>
          <g transform="translate(-405 -781) translate(365 59)">
            <g>
              <path
                d="M18 19c.404 0 .769-.244.924-.617.154-.374.069-.804-.217-1.09l-6.172-6.171c-1.952-1.952-5.118-1.952-7.07 0l-2.172 2.171c-.379.392-.374 1.016.012 1.402.386.386 1.01.391 1.402.012l2.172-2.171c1.187-1.134 3.055-1.134 4.242 0l6.172 6.171c.187.188.442.293.707.293zM16 10.5c1.933 0 3.5-1.567 3.5-3.5S17.933 3.5 16 3.5 12.5 5.067 12.5 7s1.567 3.5 3.5 3.5zm0-5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zM23 16c-.552 0-1 .448-1 1v2c0 1.657-1.343 3-3 3h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c2.76-.003 4.997-2.24 5-5v-2c0-.552-.448-1-1-1zM1 8c.552 0 1-.448 1-1V5c0-1.657 1.343-3 3-3h2c.552 0 1-.448 1-1s-.448-1-1-1H5C2.24.003.003 2.24 0 5v2c0 .552.448 1 1 1zM7 22H5c-1.657 0-3-1.343-3-3v-2c0-.552-.448-1-1-1s-1 .448-1 1v2c.003 2.76 2.24 4.997 5 5h2c.552 0 1-.448 1-1s-.448-1-1-1zM19 0h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c1.657 0 3 1.343 3 3v2c0 .552.448 1 1 1s1-.448 1-1V5c-.003-2.76-2.24-4.997-5-5z"
                transform="translate(40 722)"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ImageIcon
