import IClient from '@interfaces/IClient'
import ApiService from './ApiService'
import ImageService, { IImageService } from './ImageService'
import GameService, { IGameService } from './GameService'
import LeaderboardService, { ILeaderboardService } from './LeaderboardService'
import AnnouncementService, {
  IAnnouncementService,
} from './AnnouncementService'

export const clientService: ApiService<IClient> = new ApiService<IClient>(
  'clients'
)
export const announcementService: IAnnouncementService =
  new AnnouncementService('announcements')
export const imageService: IImageService = new ImageService('client-images', {
  baseUrl: process.env.REACT_APP_API_HOST,
})
export const gameService: IGameService = new GameService('games')
export const leaderboardService: ILeaderboardService = new LeaderboardService(
  'leaderboards'
)
