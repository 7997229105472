import { UseQueryOptions } from 'react-query'
import { QueryKey } from '@utils/reactQuery/types'
import { RawEngagement } from '@interfaces/Engagement'
import { useFetch } from '@utils/reactQuery/useFetch'

interface GetEngagementParams<T> {
  gameId: string
  voteableId: string
  options: UseQueryOptions<T, Error, T, QueryKey>
}

export const useGetEngagement = <T = { engagement: RawEngagement }>({
  gameId,
  voteableId,
  options,
}: GetEngagementParams<T>) =>
  useFetch<T>({
    url: `/games/${gameId}/voteables/${voteableId}/stats`,
    config: options,
  })
