import React, { FC, useState, useEffect, MouseEvent } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { getSidebarItems } from '@constants/navigations'
import { tr } from '@constants/other'
import { AUTHOR_LOCAL_STORAGE_KEY } from '@constants/form'
import { useGetUser, useGetUserRoles } from '@services/requests/users'
import { Role } from '@pages/TeamMembers/types'
import clearCredentials from '@utils/clearCredentials'

const linkStyle = {
  color: 'white',
}

export const Sidebar: FC<React.PropsWithChildren<unknown>> = () => {
  const { data: userData } = useGetUser()
  const { data: roleData } = useGetUserRoles(userData?.id, {
    enabled: !!userData?.id,
  })
  const [isCollapsed, setCollapsed] = useState<boolean>(true)
  const [activeIndex, setActiveIndex] = useState<number>(1)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const wrapperClass = `flex justify-between py-2 collapsible-item items-center ${
    isCollapsed ? 'collapsed px-3' : 'mx-3'
  }`
  const itemClass = `${isCollapsed ? 'sb-collapse px-2' : 'px-3'}`

  const showClientConfigItem =
    roleData?.roles.includes(Role.AccountOwner) ||
    roleData?.roles.includes(Role.Admin)

  const sidebarItems = getSidebarItems(!!showClientConfigItem)

  useEffect(() => {
    const path = pathname.split('/')[1]
    let hasActive = false
    for (const navItem of sidebarItems) {
      if (navItem.to === `/${path}`) {
        hasActive = true
        setActiveIndex(navItem.index)
        break
      }
    }

    if (!hasActive) {
      setActiveIndex(-1)
    }
  }, [pathname, sidebarItems])

  const handleSignout = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    clearCredentials()
    localStorage.removeItem(AUTHOR_LOCAL_STORAGE_KEY)
    navigate('/signin')
  }

  const navStyles = {
    maxWidth: isCollapsed ? '72px' : '240px',
    minWidth: isCollapsed ? '72px' : '240px',
  }

  return (
    <nav
      className="absolute top-0 right-0 left-0 z-20 border-top border-dark bg-dark-background h-full"
      style={{ ...navStyles }}
      onMouseOver={() => setCollapsed(false)}
      onMouseOut={() => setCollapsed(true)}
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-1 flex-col justify-start">
          {sidebarItems
            .filter((navItem) => !!navItem.show)
            .map((navItem) => (
              <div key={navItem.index} className={`py-2 ${navItem.className}`}>
                <Link
                  style={linkStyle}
                  to={navItem.to}
                  onClick={() => setActiveIndex(navItem.index)}
                >
                  <div
                    className={`${wrapperClass} ${
                      activeIndex === navItem.index ? 'active' : ''
                    }`}
                  >
                    <div className={itemClass}>{navItem.name}</div>
                    <div className="px-2">
                      <img
                        alt={tr({ id: 'home.icon' })}
                        src={navItem.iconPath}
                      />
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        <div className="sticky bottom-0 left-0 right-0 z-10">
          <div className={`py-2 w-full bg-[#000]`}>
            <button
              className="w-full"
              style={{ ...linkStyle, textDecoration: 'none' }}
              onClick={handleSignout}
            >
              <div className={`flex justify-center py-2`}>
                <div className={itemClass}>{tr({ id: 'signOut.logout' })}</div>
                <div className="pl-2">
                  <img
                    alt={tr({ id: 'signOut.icon' })}
                    src={'/assets/svg/icon-sign-out.svg'}
                  />
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default React.memo(Sidebar)
