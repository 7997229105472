import filter from 'lodash/filter'
import {
  AssetManagerView,
  AssetProviderState,
} from '@services/providers/AssetProvider'
import { UploadableAsset } from '@interfaces/Assets'

export enum AssetActionTypes {
  Get = 'GET_ASSETS',
  Update = 'UPDATE_ASSETS',
  UpdateAsset = 'UPDATE_ASSET',
  Delete = 'DELETE_ASSET',
  ChangeView = 'CHANGE_VIEW',
  UpdateProgress = 'UPDATE_PROGRESS',
  UpdateImage = 'UPDATE_IMAGE',
}

type ActionMap<M extends Record<string, any>> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

type UploadableProgress = UploadableAsset & {
  filename?: string
  status?: string
}

type AssetPayload = {
  [AssetActionTypes.ChangeView]: AssetManagerView
  [AssetActionTypes.Delete]: string
  [AssetActionTypes.Update]: UploadableAsset[]
  [AssetActionTypes.UpdateProgress]: Partial<UploadableProgress>
  [AssetActionTypes.UpdateImage]: {
    id: string
    asset: Partial<UploadableAsset>
  }
}

export type AssetActions =
  ActionMap<AssetPayload>[keyof ActionMap<AssetPayload>]

export const assetReducer = (
  state: AssetProviderState,
  action: AssetActions
): AssetProviderState => {
  switch (action.type) {
    case AssetActionTypes.UpdateProgress:
      const { filename, progress, sizeLoaded, sizeTotal, status } =
        action.payload || {}
      if (!filename) {
        return {
          ...state,
        }
      }

      const assetIdx = [...state.uploadableAssets].findIndex(
        (asset) => asset.meta.filename === filename
      )
      const prevAssetState = [...state.uploadableAssets].find(
        (asset) => asset.meta.filename === filename
      )

      if (!prevAssetState) {
        return {
          ...state,
        }
      }

      const assetToUpload = {
        ...prevAssetState,
        meta: {
          ...prevAssetState.meta,
        },
      }

      if (status) assetToUpload.status = status
      if (progress) assetToUpload.progress = progress
      if (sizeLoaded) assetToUpload.sizeLoaded = sizeLoaded
      if (sizeTotal) assetToUpload.sizeTotal = sizeTotal

      const assetsToUpload =
        assetIdx !== -1
          ? [
              ...state.uploadableAssets.slice(0, assetIdx),
              assetToUpload,
              ...state.uploadableAssets.slice(assetIdx + 1),
            ]
          : state.uploadableAssets

      return {
        ...state,
        uploadableAssets: assetsToUpload,
      }

    case AssetActionTypes.UpdateImage:
      const { id: assetId, asset } = action.payload || {}
      if (!assetId || !asset)
        return {
          ...state,
        }

      const index = [...state.uploadableAssets].findIndex(
        (asset) => asset.id === assetId
      )
      const assets =
        index !== -1
          ? [
              ...state.uploadableAssets.slice(0, index),
              asset,
              ...state.uploadableAssets.slice(index + 1),
            ]
          : state.uploadableAssets

      return {
        ...state,
        uploadableAssets: assets as UploadableAsset[],
      }

    case AssetActionTypes.Update:
      return {
        ...state,
        uploadableAssets: action.payload,
      }
    case AssetActionTypes.Delete:
      const newUploadableAssets = filter(
        [...state.uploadableAssets],
        (asset) => asset.id !== action.payload
      )

      return {
        ...state,
        uploadableAssets: newUploadableAssets,
      }
    case AssetActionTypes.ChangeView:
      return {
        ...state,
        view: action.payload,
      }

    default:
      return state
  }
}
