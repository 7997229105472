import React, { FC, useContext, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { getSchema } from '@components/organisms/LeaderboardDetailsForm/schema'
import { LanguageContext } from '@services/providers/LanguageProvider'
import { getInitialValues } from '@components/organisms/LeaderboardDetailsForm'
import { useLive } from '@services/requests/live'
import { tr } from '@constants/other'
import Button from '@components/atoms/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { IRouteParams } from '@interfaces/RouteParams'
import { useMutation } from 'react-query'
import { useToast } from '@utils/hooks/useToast'
import { ReactComponent as Tick } from '@assets/answer-tick.svg'
import { lazy } from 'yup'
import { queryClient } from '@utils/reactQuery/client'
import { cloneDeep } from 'lodash'
import AutoPatchTouched from '@components/atoms/AutoPatchTouched'
import LeaderboardForm from './Form'
import {
  LeaderboardFormTabProps,
  LeaderboardFormValues,
  LocalisedLeaderboardContent,
} from './types'

const LeaderboardFormTab: FC<LeaderboardFormTabProps> = () => {
  const { id: streamId, gameId, leaderboardId } = useParams<IRouteParams>()
  const { clientLanguages } = useContext(LanguageContext)
  const navigate = useNavigate()
  const {
    useGetStreamLeaderboardById,
    createLeaderboard,
    updateLeaderboard,
    deleteLeaderboard,
  } = useLive()
  const { data: leaderboard } = useGetStreamLeaderboardById(
    streamId,
    leaderboardId
  )

  const { addToast, addErrorToast } = useToast()

  const { mutate: mutateCreateLeaderboard, isLoading: isLoadingCreate } =
    useMutation(createLeaderboard, {
      onSuccess: (res) => {
        navigate(`/streams/${streamId}/${gameId}/leaderboard/${res?.id}`)
        addToast({
          msg: tr({ id: 'leaderboard.createdLeaderboard' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
      },
      onError: (err) => {
        addErrorToast(err)
      },
    })

  const { mutate: mutateUpdateLeaderboard, isLoading: isLoadingUpdate } =
    useMutation(updateLeaderboard, {
      onSuccess: () => {
        queryClient.invalidateQueries(['leaderboard', leaderboardId])
        addToast({
          msg: tr({ id: 'leaderboard.updatedLeaderboard' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
      },
      onError: (err) => {
        addErrorToast(err)
      },
    })

  const { mutate: mutateDeleteLeaderboard } = useMutation(
    () => deleteLeaderboard(leaderboardId, streamId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['leaderboards', streamId])
        navigate(`/streams/${streamId}/${gameId}/leaderboard-list`)
        addToast({
          msg: tr({ id: 'leaderboard.deletedLeaderboard' }),
          type: 'success',
          image: <Tick className="mr-3 w-8 h-8" />,
        })
      },
      onError: (err) => {
        addErrorToast(err)
      },
    }
  )

  const initialValues = useMemo(
    () =>
      getInitialValues(
        leaderboard?.content?.languages ?? [clientLanguages.default],
        leaderboard
      ),
    [leaderboard, clientLanguages]
  )

  const handleSubmit = (values: LeaderboardFormValues) => {
    const payload = cloneDeep(values)
    payload?.content?.languages?.forEach((lang) => {
      const { languages, ...rest } = payload?.content || {}
      const content = rest as LocalisedLeaderboardContent
      if (
        !content?.[lang as unknown as keyof LocalisedLeaderboardContent]
          ?.showBanner
      ) {
        // @ts-ignore
        delete content?.[lang]?.banner
      }

      if (
        !!content?.[lang as unknown as keyof LocalisedLeaderboardContent]
          ?.banner
      ) {
        content[lang as unknown as keyof LocalisedLeaderboardContent].banner = {
          ...content[lang as unknown as keyof LocalisedLeaderboardContent]
            .banner,
          imageAltText: 'image',
        }
      }

      // @ts-ignore
      delete payload?.content?.[lang]?.showBanner
    })
    if (!!leaderboardId) {
      mutateUpdateLeaderboard({ streamId, leaderboardId, ...payload })
    } else {
      mutateCreateLeaderboard({ streamId, ...payload })
    }
  }

  const handleDelete = () => {
    mutateDeleteLeaderboard()
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validateOnMount
        validationSchema={() =>
          lazy((values: LeaderboardFormValues) => {
            return getSchema(values.content?.languages, true)
          })
        }
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form className="flex flex-col gap-y-2">
              <AutoPatchTouched />
              <LeaderboardForm
                languages={clientLanguages}
                initialValues={initialValues}
              />
              <div className="flex mx-0 relative">
                <div
                  className={`flex w-full absolute -bottom-24 mx-auto  ${
                    leaderboardId ? 'justify-between' : 'justify-end'
                  }`}
                >
                  {leaderboardId && (
                    <Button
                      size="small"
                      variant="secondary"
                      className="delete-btn w-[11rem]"
                      type="button"
                      onClick={handleDelete}
                      data-testid="leaderboard-form__delete"
                    >
                      {tr({ id: 'generic.delete' })}
                    </Button>
                  )}
                  <Button
                    size="small"
                    className="w-[11rem]"
                    variant="primary"
                    type="submit"
                    data-testid="leaderboard-form__submit"
                    disabled={isLoadingUpdate || isLoadingCreate}
                  >
                    {leaderboardId
                      ? tr({ id: 'generic.update' })
                      : tr({ id: 'generic.create' })}
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default LeaderboardFormTab
