import React, { FC, MouseEvent, ChangeEvent } from 'react'
import TextInput from '@components/atoms/TextInput'
import { tr } from '@constants/other'
import Button from '@components/atoms/Button'
import { IDurationInput } from './types'

const DurationInput: FC<React.PropsWithChildren<IDurationInput>> = ({
  id,
  label,
  value,
  showButtons = false,
  showSeconds = true,
  min,
  max,
  error,
  onBlur,
  touched,
  errorLabel,
  hasReset = true,
  handleReset,
  buttons = [15, 30, 60],
  onChange,
  ...rest
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const duration = parseInt(e?.currentTarget?.value, 10)
    onChange && onChange(duration)
  }

  const addTime = (e: MouseEvent<HTMLButtonElement>) => {
    const timeToAddString = e?.currentTarget?.getAttribute('data-value')
    const timeToAdd = timeToAddString ? parseInt(timeToAddString, 10) : 0

    const duration = value + timeToAdd
    onChange && onChange(duration)
  }

  const inputStyles = {
    width: '100%',
    minHeight: '36px',
    borderRadius: '5px',
    paddingLeft: '10px',
    border: error && touched ? 'solid 2px var(--red)' : 'solid 1px lightgray',
  }

  const buttonsStyles = hasReset ? '' : 'justify-end mb-[12px]'

  const isButtonDisabled = (buttonValue: number) => {
    return max ? value + buttonValue > max : false
  }

  return (
    <>
      <div className="flex w-full">
        <TextInput
          style={inputStyles}
          type="number"
          id={id}
          label={label}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          step={showSeconds ? '1' : undefined}
          min={min}
          max={max}
          error={error}
          errorLabel={errorLabel}
          touched={touched}
          {...rest}
        />
        {showButtons && (
          <div className={`flex flex-col ${buttonsStyles}`}>
            {hasReset && (
              <div className="flex justify-end">
                <Button
                  className="lowercase"
                  buttonType="link"
                  size="mini"
                  onClick={handleReset}
                >
                  {tr({ id: 'generic.reset' })}
                </Button>
              </div>
            )}

            <div className="flex ml-2">
              {buttons.map((btn) => (
                <Button
                  size="small"
                  variant="secondary"
                  key={btn}
                  className="mr-2 time-input w-[36px] h-[36px]"
                  data-value={btn}
                  onClick={addTime}
                  disabled={isButtonDisabled(btn)}
                >
                  +{btn}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default DurationInput
