import React, { FC } from 'react'
import Sidebar from '@components/organisms/Sidebar'
import TopNav from '@components/layouts/TopNav'
import Container from '@components/atoms/Container'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'

const OnlineLayoutNonScrollable: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Container className="flex flex-col min-h-full fixed">
      <Row>
        <Col className="px-0">
          <TopNav />
        </Col>
      </Row>
      <Row className="relative flex-1">
        <Sidebar />
        <Col className="ml-[58px]">{children}</Col>
      </Row>
    </Container>
  )
}

export default OnlineLayoutNonScrollable
