import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import Tabs from '@components/molecules/Tabs'
import TabPanel from '@components/molecules/TabPanel'
import Container from '@components/atoms/Container'
import Row from '@components/atoms/Row'
import Col from '@components/atoms/Col'
import { tr } from '@constants/other'
import {
  Route,
  Routes,
  useLocation,
  useParams,
  useNavigate,
} from 'react-router-dom'
import { BuffPageActiveTab } from '@pages/BuffPage'
import AnnouncementForm, {
  getInitialValues as getAnnouncementInitialValues,
} from '@components/organisms/AnnouncementForm'
import TemplateAnnouncementConfirmation from '@components/molecules/TemplateAnnouncementConfirmation'
import { useToast } from '@utils/hooks/useToast'
import {
  AnnouncementTemplate,
  AnnouncementTemplateCreate,
} from '@interfaces/Template'
import { FormikHelpers, FormikProps } from 'formik'
import { Author } from '@interfaces/Author'
import {
  AnnouncementFormValues,
  CreateAnnouncement,
} from '@interfaces/Announcement'
import { getAuthors } from '@services/requests/author'
import { LanguageCode } from '@interfaces/ILanguage'
import { formatAnnouncementDataToSubmit } from '@utils/formatAnnouncementDataToSubmit'
import Breadcrumb from '@components/atoms/Breadcrumb'
import BreadcrumbItem from '@components/atoms/Breadcrumb/BreadcrumbItem'
import { IRouteParams } from '@interfaces/RouteParams'
import GlobalTemplateBuffForm from '@components/organisms/GlobalTemplateBuffForm'
import { useGetAnnouncementTemplate } from '@services/requests/templates'
import { LanguageContext } from '@services/providers/LanguageProvider'
import getAnnouncementTemplateInitialValues from '@utils/announcementForm/getAnnouncementTemplateInitialValues'
import TemplateAside from '@components/molecules/TemplateAside'

const GlobalTemplates: FC<React.PropsWithChildren<unknown>> = () => {
  const path = '/stream-management/global-templates'
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { id } = useParams<IRouteParams>()

  const { addErrorToast } = useToast()
  const { clientLanguages } = useContext(LanguageContext)

  const [announcementTemplatePayload, setAnnouncementTemplatePayload] =
    useState<AnnouncementTemplateCreate | AnnouncementTemplate | null>(null)
  const [showSetTemplateNameModal, setShowSetTemplateNameModal] =
    useState(false)

  const [activeLeftTab, setActiveLeftTab] = useState<BuffPageActiveTab>()

  const { data: announcementTemplate } = useGetAnnouncementTemplate({
    id: id ?? '',
    options: {
      enabled: !!id && activeLeftTab === BuffPageActiveTab.AnnouncementListView,
    },
  })

  // TODO: Pull in template title somehow?
  const title = 'Global Template' || announcementTemplate?.templateName

  const [authors, setAuthors] = useState<Author[]>([])
  const [announcementInitialValues, setAnnouncementInitialValues] =
    useState<AnnouncementFormValues>()
  const announcementFormRef = useRef<FormikProps<AnnouncementFormValues>>(null)

  const buffSectionStyle = {
    boxShadow: '0 5px 15px 0 rgba(9, 14, 37, 0.1)',
  }

  useEffect(() => {
    if (!id) {
      setAnnouncementInitialValues(getAnnouncementInitialValues([]))
    }
    if (!!id && !!announcementTemplate) {
      setAnnouncementInitialValues(
        getAnnouncementTemplateInitialValues(
          announcementTemplate,
          authors,
          announcementTemplate?.localisations
        )
      )
    }
  }, [id, announcementTemplate, authors])

  useEffect(() => {
    if (pathname?.includes('buff')) {
      setActiveLeftTab(BuffPageActiveTab.BuffView)
    }

    if (pathname?.includes('announcements')) {
      setActiveLeftTab(BuffPageActiveTab.AnnouncementListView)
    }
  }, [pathname])

  useEffect(() => {
    const getAllAuthors = async () => {
      try {
        const { authors } = await getAuthors(30, '')
        setAuthors(authors ?? [])
      } catch (error) {
        addErrorToast(error)
      }
    }

    getAllAuthors()
  }, [addErrorToast])

  const showAnnouncementTemplateModal = async (values: CreateAnnouncement) => {
    try {
      const template: AnnouncementTemplateCreate = {
        ...values,
        templateName: announcementTemplate?.templateName ?? '',
      }
      setAnnouncementTemplatePayload(template)
      setShowSetTemplateNameModal(true)
    } catch (error) {
      addErrorToast(error)
    }
  }

  const handleAnnouncementSubmit = async (
    values: AnnouncementFormValues,
    helpers: FormikHelpers<AnnouncementFormValues>,
    languages?: LanguageCode[]
  ) => {
    if (!values) return
    if (!languages) return

    const formBody = formatAnnouncementDataToSubmit(
      values,
      clientLanguages.enabled
    )
    showAnnouncementTemplateModal(formBody)
    return
  }

  const handleTemplateSaveSuccess = () => {
    if (pathname?.includes('buff')) {
      navigate('/stream-management/global-templates/buff')
    } else {
      navigate('/stream-management/global-templates/announcements')
    }
  }

  const components = {
    buff: {
      title: tr({ id: 'buff.buff' }),
      component: (
        <>
          <GlobalTemplateBuffForm
            authors={authors}
            availableLanguages={clientLanguages.enabled}
            templateId={id}
          />
        </>
      ),
    },
    announcements: {
      title: tr({ id: 'announcements.announcement' }),
      component: (
        <>
          <AnnouncementForm
            ref={announcementFormRef}
            authors={authors}
            languages={clientLanguages.enabled}
            initialValues={announcementInitialValues}
            template={announcementTemplate}
            returnCallback={() =>
              navigate('/stream-management/global-templates/announcements')
            }
            isGlobalTemplate
            onSubmit={handleAnnouncementSubmit}
          />
          <TemplateAnnouncementConfirmation
            id={id}
            mode={id ? 'update' : 'create'}
            payload={announcementTemplatePayload}
            show={showSetTemplateNameModal}
            onClose={() => setShowSetTemplateNameModal(false)}
            onSuccessCallback={handleTemplateSaveSuccess}
          />
        </>
      ),
    },
  }

  const tabs = Object.entries(components).map(([slug, { title }]) => {
    return {
      text: title,
      href: `${path}/${slug}/new`,
    }
  })

  const returnTab = pathname?.includes('buff') ? 'buff' : 'announcements'
  const tabsIndex = Object.keys(components).indexOf(returnTab)

  const containerClasses = 'mt-1 mb-2 px-4 py-3 bg-white'

  if (!clientLanguages.enabled?.length) return null

  if (
    activeLeftTab === BuffPageActiveTab.AnnouncementListView &&
    !announcementInitialValues
  ) {
    return null
  }

  if (
    !!id &&
    !announcementTemplate &&
    activeLeftTab === BuffPageActiveTab.AnnouncementListView
  ) {
    return null
  }

  return (
    <>
      <Container>
        <Row className="pl-2">
          <Col sm={8}>
            <div className="flex flex-col">
              <div className="flex h-6 items-center mt-3 mb-4">
                <Breadcrumb>
                  <BreadcrumbItem
                    href={`/stream-management/global-templates/${returnTab}`}
                  >
                    {tr({ id: 'stream.management.title' })}
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    {tr({ id: 'stream.management.globalTemplates' })}
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="flex justify-between items-center mb-3">
                <div className="flex">
                  <h2 className="h3 font-bold mb-0 mr-2.5">
                    {!!id ? title : tr({ id: 'template.createGlobalTemplate' })}
                  </h2>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pl-2">
          <Routes>
            <Route
              path={`/`}
              element={
                <Col sm={8}>
                  <div style={buffSectionStyle} className={containerClasses}>
                    <Tabs selectedIndex={tabsIndex} tabs={tabs}>
                      {Object.entries(components).map(
                        ([slug, { component }]) => {
                          return <TabPanel key={slug}>{component}</TabPanel>
                        }
                      )}
                    </Tabs>
                  </div>
                </Col>
              }
            />
          </Routes>
          <Col sm={4}>
            <TemplateAside />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default GlobalTemplates
