import { useInfiniteQuery } from 'react-query'
import { getGameContent } from '@services/requests/games'

/**
 * @param {string} gameId
 * @param {number} limit
 * @return {GameContent}
 */
export default function useGameContent(gameId: string, limit: number = 50) {
  return useInfiniteQuery(
    ['game-content', gameId],
    ({ pageParam = '' }) =>
      getGameContent({ gameId, pageSize: limit, nextParam: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.nextPage?.length) return undefined
        return lastPage?.nextPage
      },
      enabled: !!gameId,
    }
  )
}
