import axios, { AxiosInstance } from 'axios'

import { BUFFUP_DASHBOARD_TOKEN, BUFFUP_REFRESH_TOKEN } from '@constants/other'
import { refreshJwtToken } from '@services/requests/auth'

export enum Service {
  LIVE = 'live',
  VOD = 'vod',
  CONFIG = 'config',
  AUTH = 'auth',
}

let client = {} as AxiosInstance

/**
 * @param {Service} serviceName Name of the API service
 * @param {boolean} isMocked Determines if the service is mocked
 * @return {AxiosInstance}
 */
export default function useClient(serviceName: Service, isMocked?: boolean) {
  const token = localStorage.getItem(BUFFUP_DASHBOARD_TOKEN)
  const accountName = process.env.ACCOUNT_NAME
    ? process.env.ACCOUNT_NAME
    : 'sportbuff'
  let baseURL =
    ['develop', 'qa'].indexOf(accountName) > -1
      ? `https://${serviceName}.api.${accountName}.buffup.net`
      : `https://${serviceName}.api.prod.buffup.net`

  if (isMocked) {
    baseURL =
      'https://uje3dqmsk7i6qbbprtolkj3n6e0khrnm.lambda-url.eu-west-1.on.aws'
  }

  // Avoids duplicating an axios instance if it already exists
  if (client?.defaults?.baseURL === baseURL) return { client }

  client = axios.create({
    baseURL,
  })

  client.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    'X-Buffup-Broadcaster-ID': accountName,
  }

  client.interceptors.request.use(
    (request) => {
      const token = localStorage.getItem(BUFFUP_DASHBOARD_TOKEN)
      if (request?.headers && !!token) {
        request.headers.Authorization = `Bearer ${token}`
      }

      return request
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  client.interceptors.response.use(undefined, async (error) => {
    const config = error?.config

    if (error?.response?.status === 401) {
      config.sent = true

      if (error?.config?.headers?.bypass401) {
        localStorage.removeItem(BUFFUP_DASHBOARD_TOKEN)
        localStorage.removeItem(BUFFUP_REFRESH_TOKEN)
        window.dispatchEvent(new Event('expiredRefreshToken'))

        return Promise.reject(error)
      }

      const jwtToken = await refreshJwtToken()

      if (jwtToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${jwtToken}`,
        }
      }

      return client(config)
    }
    return Promise.reject(error)
  })

  return { client }
}
