import React, { FC } from 'react'
import { SkeletonColour } from './types'

interface IProps {
  /**
   * Colour of the skeleton
   */
  colour: SkeletonColour

  /**
   * Custom class name
   */
  className?: string
}

const Skeleton: FC<React.PropsWithChildren<IProps>> = ({
  className = '',
  colour,
}): JSX.Element => {
  const skeletonStyles = {
    background: colour,
  }

  return (
    <div style={skeletonStyles} className={className}>
      &nbsp;
    </div>
  )
}

export default Skeleton
