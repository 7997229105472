import React from 'react'
import Pill from '@components/atoms/Pill'
import { IStream, StreamStatus } from '@interfaces/IStream'
import { getStreamStatusPillColor } from '@utils/stream'

interface IProps {
  stream: IStream
  isActive: boolean
  status?: StreamStatus
}

const overflowEllipsis = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}

const StreamItem: React.FC<React.PropsWithChildren<IProps>> = ({
  stream,
  status,
  isActive,
}): JSX.Element => {
  const { title } = stream
  const activeStyles = {
    backgroundColor: isActive ? 'var(--primary)' : 'transparent',
    color: isActive ? 'white' : 'black',
  }

  const pillColor = getStreamStatusPillColor(status)

  return (
    <div
      data-testid="wrapper"
      className={`flex px-2 py-1 items-center justify-between rounded stream-item ${
        isActive && 'active'
      }`}
      style={activeStyles}
    >
      <div className="truncate overflow-hidden" data-testid="desc">
        {title}
      </div>
      <div className="right">
        <Pill text={status ?? ''} color={pillColor} />
      </div>
    </div>
  )
}

export default StreamItem
